import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../src/reducers/rootReducer';
import { TextField } from '@material-ui/core';
import { fromNullable } from 'fp-ts/lib/Option';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

export interface TaskOutcomeProps {
    outcome: string;
}
const TaskOutcome: React.FC<TaskOutcomeProps> = ({ outcome }) => {
    const label = useSelector((state: RootState) =>
        fromNullable(state.viewConfig)
            .mapNullable((vc) => vc.entities.TaskInstance)
            .mapNullable((ti) => ti.fields.outcome)
            .mapNullable((o) => o.label)
            .getOrElse('Selected Outcome'),
    );
    const translatedOutcome = useEvaluatedFormattedMessage(outcome ?? '');
    return (
        <div>
            <TextField
                margin="none"
                value={translatedOutcome || 'Completed'}
                disabled={true}
                id="selectedOutcome"
                label={label.charAt(0).toUpperCase() + label.slice(1)}
                InputLabelProps={{ disabled: false }}
            />
        </div>
    );
};

export default TaskOutcome;
