import React from 'react';
import { AttemptRequestState } from 'components/AttemptRequest';
import { CardActions, Button, CircularProgress, Link as _Link, withTheme, Theme } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import TextInput from 'fieldFactory/input/components/TextInput';
import { Link as RouterLink } from 'react-router-dom';
import { InputProps } from '@material-ui/core/Input';
import { PasswordResetButton } from 'auth/components/PasswordResetButton';
import { PasswordResetResponse } from './Page';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const Link: any = _Link;

export const PasswordResetStatus = withTheme(
    (props: { state: AttemptRequestState<PasswordResetResponse>; theme: Theme }) => {
        const { state, theme } = props;
        return state._tag === 'failure' ? (
            <span style={{ color: theme.palette.error.main }}>
                {state.body && typeof state.body === 'object' ? (
                    Object.values(state.body).map((msg: string, i) => (
                        <p key={i}>
                            <SafeHtmlAsReact html={msg} />
                        </p>
                    ))
                ) : (
                    <p>
                        Request failed
                        {state.status ? ` with status ${state.status}` : ''}: {state.message}
                    </p>
                )}
            </span>
        ) : state._tag === 'success' ? (
            <span>
                {state.data?.mfaQrUrl ? (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <p>Scan the QR Code below, to access your Authenticator code.</p>
                        <p>
                            Then{' '}
                            <Link aria-label="continue to login" component={RouterLink} to="/login">
                                continue to login
                            </Link>
                        </p>
                        <img alt="QRCode" src={state.data?.mfaQrUrl} />
                    </div>
                ) : (
                    <>
                        <Done /> Success!{' '}
                        <Link aria-label="Go to login" component={RouterLink} to="/login">
                            Go to login
                        </Link>{' '}
                    </>
                )}
            </span>
        ) : null;
    },
);

export const PasswordResetTextField = (props: {
    disabled?: boolean;
    handleChange: InputProps['onChange'];
    handleBlur: InputProps['onBlur'];
    value: string;
    name: string;
    error?: string;
    touched?: boolean;
    label: string;
    type?: 'password';
}) => {
    const { handleChange, handleBlur, value, disabled, name, error, touched, label, type } = props;
    return (
        <TextInput
            randomizeNameAsBrowserAutocompleteHack={false}
            disabled={disabled}
            noDebounce={true}
            resource=""
            source=""
            validate={() => null}
            label={label}
            type={type}
            input={{
                onChange: handleChange,
                onBlur: handleBlur,
                value,
            }}
            options={{
                name,
                margin: 'normal',
            }}
            meta={{
                error,
                touched,
            }}
        />
    );
};

export const renderActions = (state: AttemptRequestState) => (
    <CardActions>
        {state._tag !== 'success' ? (
            <Button
                variant="contained"
                color="primary"
                type="submit"
                aria-label="Submit"
                disabled={state._tag === 'pending'}
            >
                Submit
                {state._tag === 'pending' ? <CircularProgress style={{ height: 15, width: 15 }} /> : null}
            </Button>
        ) : null}
        {state._tag === 'failure' &&
        state.body &&
        Object.values(state.body).find(
            (m) => m && typeof m === 'string' && (m.includes('expired') || m.includes('not found')),
        ) ? (
            <PasswordResetButton
                renderButton={({ openDialog }) => (
                    <Button variant="outlined" color="primary" fullWidth={false} onClick={openDialog}>
                        Request Password Reset Key
                    </Button>
                )}
            />
        ) : null}
    </CardActions>
);
