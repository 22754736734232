import React from 'react';
import { NetworkUnavailable, ServerError } from 'remoteStatus/one/components/pages';

import DeferredSpinner from 'components/DeferredSpinner';
import ExperimentalReportEditor from './ExperimentalReportEditor';
import useReportDefinition from 'report2/hooks/useReport';

interface Report {
    reportName: string;
}
const Report = ({ reportName }) => {
    const [state, refresh] = useReportDefinition(reportName);
    return state.foldL(
        () => null,
        () => <DeferredSpinner />,
        (err) => {
            if (err.status) {
                return <ServerError code={err.status} message={err.message} />;
            }
            return <NetworkUnavailable retry={refresh} />;
        },
        (response) => {
            const reportDefinition = {
                ...response.reportDefinitionDTO,
                fields: response.params,
                config: response.reportDefinitionDTO.config && JSON.parse(response.reportDefinitionDTO.config),
            };
            return <ExperimentalReportEditor initialReportDefinition={reportDefinition} />;
        },
    );
};

export default Report;
