/**
 * Check if a mime type or file extension matches the set given in accept
 *
 * @param file the file to test matches
 * @param accept the mime types or file extensions to accept, ex .txt,.pdf,audio/*,video/*,image/png
 * @returns true if the file is accepted, false otherwise
 */
const acceptFile = (file: File, accept: string) => {
    const acceptTypes = accept.split(',').map((t) => t.trim());
    const acceptMimeType = acceptTypes.includes(file.type) || acceptTypes.includes(file.type.split('/')[0] + '/*');
    const acceptFileType = acceptTypes.some((type) => file.name.toLowerCase().endsWith(type.toLowerCase()));
    return acceptFileType || acceptMimeType;
};
export default acceptFile;
