import { Activity, RemoteDataWithPrevData } from 'bpm/reducer/types';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { ErrorDetails } from 'sagas/util/sagaFactory';

const initial = {
    status: 'initial',
} as const;

const createActivitiesSelector = <Props extends { processId: string }>() =>
    createSelector(
        (state: RootState) => state.bpm.processActivities.byId,
        (state: RootState, props: Props): RemoteDataWithPrevData<ErrorDetails | Error, string[]> =>
            state.bpm.processActivities.byProcess[props.processId] || initial,
        (byId, processData): RemoteDataWithPrevData<ErrorDetails | Error, Activity[]> => {
            const mapData = (data: string[]) => data.map((id) => byId[id]);
            if (processData.status === 'error' || processData.status === 'pending') {
                return {
                    ...processData,
                    prevData: processData.prevData && mapData(processData.prevData),
                };
            }
            if (processData.status === 'success') {
                return {
                    ...processData,
                    data: mapData(processData.data),
                };
            }
            return processData;
        },
    );
export default createActivitiesSelector;
