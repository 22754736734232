import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import {
    checkLastRequestTimeFailure,
    checkLastRequestTimeSuccess,
} from 'reauth-interval-and-retries/check-last-request/actions';

const successiveFailureCountReducer = (state: number = 0, action: RootAction) => {
    switch (action.type) {
        case getType(checkLastRequestTimeFailure):
            return state + 1;
        case getType(checkLastRequestTimeSuccess):
            return 0;
        default:
            return state;
    }
};
export default successiveFailureCountReducer;
