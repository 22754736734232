import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../reducers/taskFormType';

import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import ViewConfig from 'reducers/ViewConfigType';
import { getDefaultViewName } from 'bpm/components/ProcessDetail/getDefaultViewName';
/**
 * This contains a lot of logic that is duplicated (but better - cleaning up some things)
 * in ProcessPage.tsx
 *
 * This should be used in a refactoring of that file/component in the future, so this file is the single source
 * of truth for linked entity/linked view logic on process pages.
 *
 */

const createAppCaseSelector = <Props extends { processId: string }>() =>
    createSelector(
        (state: RootState) => state.admin.entities.AppCase,
        (state: RootState, props: Props) => props.processId,
        (appCaseEntities: RootState['admin']['entities'][string], processId): undefined | { id: string } =>
            Object.values(appCaseEntities ?? {}).find(
                (ac) =>
                    (
                        ac as {
                            processInstanceId?: string;
                        }
                    )?.processInstanceId === processId,
            ),
    );

const getTaskInstance = <Props extends { taskId: string }>(state: RootState, props: Props) =>
    state.admin.entities.TaskInstance?.[props.taskId];

const createTaskLinkedEntitySelector = () =>
    createSelector(
        getTaskInstance,
        (state: RootState) => state.admin.entities,
        (state: RootState) => state.viewConfig,
        (TaskInstance, entities, viewConfig) => {
            return traverseGetData(viewConfig, 'linkedEntity', TaskInstance, entities, false);
        },
    );

const taskFormImplementsCustomHide = (taskForm: TaskForm): boolean => {
    const alwaysHideLinkedEntity = Boolean(taskForm?.fields?.find((field) => field.id === 'hideLinkedEntity'));
    const hideLinkedEntityTemplatedValue = taskForm?.fields?.find(
        (field) => field.id === 'hideLinkedEntityExpression',
    )?.value;
    return (
        alwaysHideLinkedEntity ||
        (typeof hideLinkedEntityTemplatedValue === 'string'
            ? hideLinkedEntityTemplatedValue.trim().toLowerCase() === 'true'
            : Boolean(hideLinkedEntityTemplatedValue))
    );
};

const displayLinkedEntityForCurrentTaskForm = (
    TaskInstance: {
        id: string;
        entityType: string;
        title?: string;
        formKey?: string;
    },
    taskForms: {
        [taskKey: string]: TaskForm;
    },
) => {
    if (!TaskInstance || isEmpty(taskForms)) {
        return true;
    }
    const taskForm = Object.values(taskForms).filter((form) => form.key === TaskInstance.formKey)[0];
    return !taskFormImplementsCustomHide(taskForm);
};
const createOverrideLinkedEntitySelector = () => {
    const taskLinkedEntitySelector = createTaskLinkedEntitySelector();
    return createSelector(
        getTaskInstance,
        taskLinkedEntitySelector,
        (state: RootState) => state.taskForms,
        (TaskInstance, TaskLinkedEntity, taskForms) => {
            const displayLinkedEntity = displayLinkedEntityForCurrentTaskForm(TaskInstance, taskForms ?? {});
            const overrideLinkedEntity = TaskLinkedEntity.toUndefined();
            return {
                overrideLinkedEntity,
                overrideLinkedEntityDisplayed: displayLinkedEntity,
            };
        },
    );
};

const createGetLinkedEntityType = <Props extends { processId: string }>() => {
    const appCaseSelector = createAppCaseSelector<Props>();
    const overrideLinkedEntitySelector = createOverrideLinkedEntitySelector();
    return createSelector(appCaseSelector, overrideLinkedEntitySelector, (appCase, { overrideLinkedEntity }) => {
        return overrideLinkedEntity?.entityType ?? appCase?.['linkedEntityType'];
    });
};

export const createLinkedViewNameSelector = () => {
    const linkedEntityTypeSelector = createGetLinkedEntityType();
    type Props = { taskId: string; processId: string; overrideViewConfig?: ViewConfig };
    return createSelector(
        linkedEntityTypeSelector,
        (state: RootState, { taskId, overrideViewConfig }: Props) => overrideViewConfig ?? state.viewConfig,
        (state: RootState, { taskId }: Props) => state.taskForms[taskId]?.viewName,
        (linkedEntityType, viewConfig, taskFormViewName) => {
            if (!linkedEntityType) {
                return null;
            }
            const defaultViewName = getDefaultViewName(viewConfig, linkedEntityType);
            return viewConfig.views[taskFormViewName]?.entity === linkedEntityType ? taskFormViewName : defaultViewName;
        },
    );
};
