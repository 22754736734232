import * as get from './actions';
import { isActionOf } from 'typesafe-actions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Services } from 'sideEffect/services';
import { Epic, ofType } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap, mapTo, take } from 'rxjs/operators';
import crudGetOneFlow from 'sideEffect/crud/util/epics/CoreCrud/getOne';
import { getViewIndexAndAdditionalConfigFields } from 'components/generics/utils/viewConfigUtils';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { concat, of, race, Observable } from 'rxjs';
import { patch } from 'jsondiffpatch';
import produce from 'immer';
import getExpansionsSelector from './getExpansions';
import ViewConfig from 'reducers/ViewConfigType';
import { expansionQuery } from '../util/queryBuilderUtils';
import { viewConfigSelector } from 'util/hooks/useViewConfig';

const buildUrl = (
    params: get.GetOneParams,
    _viewConfig: ViewConfig,
    getExpansions: ReturnType<typeof getExpansionsSelector>,
) => {
    const viewConfig =
        params.override && params.override.patchViewConfig
            ? produce(_viewConfig, (draftVC) => patch(draftVC, params.override.patchViewConfig))
            : _viewConfig;

    if (params.view && typeof params.view === 'string') {
        const [viewName] = getViewIndexAndAdditionalConfigFields(params.view, viewConfig, 'ALWAYS_LINKEDENTITY');
        if (!viewConfig.views[viewName]) {
            throw new Error(`View ${
                params.view
            } [viewIndex: ${viewName}] was passed in action payload: ${JSON.stringify(params)}.
                However this view does not exist in the viewConfig.
            `);
        }
    }

    const url =
        (params.restUrl || viewConfig.entities[params.resource].restUrl) +
        '/' +
        params.id +
        (() => {
            if (params.view && typeof params.view === 'string') {
                return `?expand=${getExpansions(params.view, {
                    overrideViewConfig: viewConfig === _viewConfig ? undefined : viewConfig,
                })}`;
            } else if (params.view !== -1) {
                return expansionQuery('?', params.resource, viewConfig, params.appendExpansions);
            } else if (params.appendExpansions && params.appendExpansions.length > 0) {
                return `?expand=${Array.prototype.join.call(params.appendExpansions, ',')}`;
            }
            return '';
        })();
    return url;
};

const getOneFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(get.crudGetOne)),
        withLatestFrom(
            state$.pipe(map(getExpansionsSelector)),
            state$.pipe(map((state) => viewConfigSelector(state))),
            state$.pipe(map((state) => state.router.location as Location)),
        ),
        flatMap(([action, getExpansions, _viewConfig, location]) => {
            const viewConfig =
                action.payload.override && action.payload.override.patchViewConfig
                    ? produce(_viewConfig, (draftVC) => patch(draftVC, action.payload.override.patchViewConfig))
                    : _viewConfig;
            const { resource, cb, errorsCbs } = action.payload;

            const restUrl = buildUrl(action.payload, _viewConfig, getExpansions);
            const getAjaxStream = (startFetch: boolean) =>
                crudGetOneFlow(
                    {
                        restUrl,
                        monitorRequest: action.payload.monitorRequest,
                    },
                    {
                        service: services.crudGet,
                        failureAction: get.crudGetOneFailure,
                        successAction: get.crudGetOneSuccess,
                        successCb: cb,
                        errorsCbs,
                    },
                    {
                        resource,
                        viewConfig,
                        initialRequestPayload: action.payload,
                        restUrl,
                    },
                    startFetch,
                );
            if (action.cancelOnRouteChange) {
                const blocker$: Observable<ReturnType<typeof fetchEnd>> = action$.pipe(
                    ofType(LOCATION_CHANGE),
                    filter((a: LocationChangeAction) => {
                        return (
                            decodeURIComponent(a.payload.location.pathname) !== decodeURIComponent(location.pathname) ||
                            decodeURIComponent(a.payload.location.search) !== decodeURIComponent(location.search)
                        );
                    }),
                    take(1),
                    mapTo(fetchEnd()),
                );
                return concat(of(fetchStart()), race(getAjaxStream(false), blocker$));
            }
            return getAjaxStream(true);
        }),
    );
export default getOneFlow;
