import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import usePersonalizedReport from 'custom-reports/hooks/usePersonalizedReport';
import { PersonalizedReport, PersonalizedReportConfig } from 'custom-reports/types';
import useExpressionTesterOpen from 'expression-tester/hooks/useExpressionTesterOpen';
import React, { useMemo } from 'react';
import { RunReport } from 'report2/components/ExperimentalReportEditor';
import { Link } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Form, getOutputOptions, useReportFormFieldsAndValidation, wrappedFields } from 'report2/components/ReportForm';
import EditIcon from '@material-ui/icons/Edit';
import { ReportDefinitionParam } from 'report2/ReportDefinition';
import { decodeDiff, isDiffEncoding } from 'fieldFactory/input/components/RelativeDate/RelativeDate';
import { Modes, initialModeContext } from './initialModeContext';
import { getConflicts } from 'custom-reports/extend-report/Editor';
import {
    decodePeriodStartEnd,
    isPeriodStartEndEncoding,
} from 'fieldFactory/input/components/RelativeDate/PeriodStartEnd/utils';

interface PersonalizedReportProps {
    config: PersonalizedReport;
    onSubmit: (fileType: string, values: {}) => void;
    id: string;
}

const PersonalizedReportForm: React.FC<PersonalizedReportProps> = (props) => {
    const personalizedConfig = useMemo(
        () => JSON.parse(props.config.config) as PersonalizedReportConfig,
        [props.config],
    );

    const parentReportConfig = useMemo(
        () => props.config.parentReportConfig && JSON.parse(props.config.parentReportConfig),
        [props.config],
    );

    const { incompatibleWithParent } = useMemo(
        () =>
            getConflicts({
                reportDefinition: {
                    outputColumns: props.config.outputColumns,
                    params: props.config.params,
                },
                personalizedReportConfig: personalizedConfig,
            }),
        [personalizedConfig, props.config],
    );

    const [fields, validate] = useReportFormFieldsAndValidation({
        fields: props.config.params,
        config: parentReportConfig,
    });

    const relativeDateInitialModes: Modes = useMemo(() => {
        return Object.entries(personalizedConfig.params).reduce((prev, [source, config]) => {
            if (config?.defaultValue) {
                const newSource = fields.find((f) => {
                    const originalDef: ReportDefinitionParam = JSON.parse(f.props['data-originaldefinition']);
                    return originalDef.id === source;
                })?.props?.source;
                prev[newSource ?? source] = isDiffEncoding(config.defaultValue)
                    ? 'RelativeDate'
                    : isPeriodStartEndEncoding(config.defaultValue)
                    ? 'PeriodStartEnd'
                    : 'Date';
            }
            return prev;
        }, {});
    }, [personalizedConfig, fields]);

    const formInitialValues = useMemo(() => {
        return Object.entries(personalizedConfig.params).reduce((prev, [source, config]) => {
            if (config?.defaultValue) {
                const newSource = fields.find((f) => {
                    const originalDef: ReportDefinitionParam = JSON.parse(f.props['data-originaldefinition']);
                    return originalDef.id === source;
                })?.props?.source;
                prev[newSource ?? source] = isDiffEncoding(config.defaultValue)
                    ? decodeDiff(config.defaultValue)
                    : isPeriodStartEndEncoding(config.defaultValue)
                    ? decodePeriodStartEnd(config.defaultValue)
                    : config.defaultValue;
            }
            return prev;
        }, {});
    }, [personalizedConfig, fields]);

    const hideFields = props.config.params.reduce((prev, curr) => {
        const param = personalizedConfig.params[curr.name];
        if ((['hidden', 'toggle-to-show'] as (typeof param.visibility)[]).includes(param?.visibility)) {
            prev[curr.name] = true;
        }
        return prev;
    }, {} as { [source: string]: true });

    const hideAdvancedFields = props.config.params.reduce((prev, curr) => {
        if (personalizedConfig.params[curr.name]?.visibility !== 'toggle-to-show') {
            prev[curr.name] = true;
        }
        return prev;
    }, {} as { [source: string]: true });

    const advancedFields = wrappedFields(fields, hideAdvancedFields, (field) => null).filter(Boolean);
    const outputOptions = useMemo(() => getOutputOptions(parentReportConfig), [parentReportConfig]);
    const expressionTesterOpen = useExpressionTesterOpen() !== 'CLOSED';
    return (
        <initialModeContext.Provider value={relativeDateInitialModes}>
            <Card>
                <Grid justifyContent="space-between" style={{ display: 'flex', padding: '1em' }}>
                    <div>
                        <Typography variant="h5" component="h2">
                            {props.config.name}
                        </Typography>
                    </div>
                    <div style={{ marginRight: '-1em' }}>
                        <span style={{ marginRight: '1em' }}>
                            <Button component={Link} to="/reports" startIcon={<ArrowBack />}>
                                Back to all reports
                            </Button>
                        </span>
                        <span style={{ marginRight: '1em' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={`/edit-personalized-report/${props.id}`}
                                endIcon={<EditIcon />}
                            >
                                Edit Report
                            </Button>
                        </span>
                    </div>
                </Grid>
                <Typography variant="body1" component="p" style={{ marginLeft: '1em' }}>
                    {props.config.description}
                </Typography>
                <Form
                    initialValues={formInitialValues}
                    validate={validate}
                    outputOptions={outputOptions}
                    _submit={props.onSubmit}
                >
                    {({ OutputOptions, submitFailed, syncErrors }) => {
                        return (
                            <React.Fragment>
                                <div style={{ marginTop: '1.5em' }}>{wrappedFields(fields, hideFields)}</div>
                                {advancedFields.length > 0 ? (
                                    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="advanced-content"
                                                id="advanced-header"
                                            >
                                                <Typography>Additional options</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div style={{ display: 'block', width: '100%' }}>{advancedFields}</div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ) : null}

                                {incompatibleWithParent && (
                                    <Alert severity="error" style={{ marginTop: '1rem' }}>
                                        <AlertTitle>
                                            The parent report has been modified, such that the personalized report is no
                                            longer valid.
                                        </AlertTitle>
                                        To fix the issue, edit either the parent report, or the personalized report so
                                        both are compatible.
                                    </Alert>
                                )}
                                {submitFailed &&
                                syncErrors &&
                                Object.keys(syncErrors).some((errKey) => hideFields[errKey]) ? (
                                    <Alert severity="error">
                                        <AlertTitle>Validation failed</AlertTitle>
                                        The backing Report Definition has validations configured, which the provided
                                        parameters do not satisfy.
                                        <ul>
                                            {Object.entries(syncErrors)
                                                .filter(([k]) => hideFields[k])
                                                .map(([k, e], i) => {
                                                    return (
                                                        <li key={i + ':' + k + ':' + e}>
                                                            {k}: {e}
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </Alert>
                                ) : null}
                                {!incompatibleWithParent && (
                                    <>
                                        <div style={{ marginLeft: '1rem' }}>
                                            <Typography variant="h6" component="h3">
                                                Download Report
                                            </Typography>
                                            {OutputOptions}
                                        </div>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Form>
                {expressionTesterOpen ? <pre>{JSON.stringify(props.config, null, 1)}</pre> : null}
            </Card>
        </initialModeContext.Provider>
    );
};

const RunPersonalizedReport = (props: { id: string }) => {
    const personalizedReportState = usePersonalizedReport(props.id);
    if (personalizedReportState.status === 'success') {
        const personalizedConfig = JSON.parse(personalizedReportState.data.config) as PersonalizedReportConfig;
        return (
            <WithErrorBoundary>
                <RunReport
                    longRunning={Boolean(personalizedReportState.data.parentReportLongRunning)}
                    reportDefinitionId={'personalize/' + props.id}
                    name={personalizedReportState.data.name}
                >
                    {({ onSubmit }) => (
                        <PersonalizedReportForm
                            id={props.id}
                            config={personalizedReportState.data}
                            onSubmit={(fileType, values) => {
                                onSubmit(fileType, {
                                    columns: personalizedConfig.outputColumns,
                                    params: values,
                                    sortOrder: personalizedConfig.sort.map(([field, dir]) => ({
                                        column: field,
                                        direction: dir,
                                    })),
                                });
                            }}
                        />
                    )}
                </RunReport>
            </WithErrorBoundary>
        );
    }
    return null;
};

export default RunPersonalizedReport;
