import React, { FunctionComponent } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    CardActions,
    IconButton,
    useTheme,
    Typography,
} from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Popup from 'components/Popup';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import ArrowUp from '@material-ui/icons/ArrowUpward';

type TableConfig = FormFieldUnion[];
type RenderFieldEditor = (params: {
    initialValues: FormFieldUnion;
    onSubmit: (fieldData: FormFieldUnion) => void;
}) => JSX.Element;
export interface EditableTableEditorProps {
    tableConfig: TableConfig;
    disabled?: boolean;
    setTableConfig: (tableConfig: TableConfig) => void;
    renderFieldEditor: RenderFieldEditor;
}

export const EditableTableEditor: FunctionComponent<EditableTableEditorProps> = ({
    tableConfig,
    setTableConfig,
    disabled,
    renderFieldEditor,
}) => {
    const theme = useTheme();
    const getRenderDialogContent =
        (initialValues?: FormFieldUnion, i?: number) =>
        ({ closeDialog }) =>
            (
                <div style={{ margin: '1em', padding: '1em', minWidth: '70vw' }}>
                    <h2>{initialValues ? 'Edit' : 'Add'} Field as column</h2>
                    {renderFieldEditor({
                        onSubmit: (fieldData) => {
                            if (typeof i === 'number') {
                                const newData = [...tableConfig.slice(0, i), fieldData, ...tableConfig.slice(i + 1)];
                                setTableConfig(newData);
                                closeDialog();
                            } else {
                                setTableConfig([...tableConfig, fieldData]);
                                closeDialog();
                            }
                        },
                        initialValues,
                    })}
                </div>
            );
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Field id</TableCell>
                        <TableCell>Field label</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableConfig.map((f, i, arr) => {
                        return (
                            <TableRow>
                                <TableCell>{f.id}</TableCell>
                                <TableCell>{f.name}</TableCell>
                                <TableCell>
                                    <Popup
                                        renderDialogContent={getRenderDialogContent(f, i)}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    />
                                    &nbsp;
                                    {i !== arr.length - 1 && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const entryBelow = tableConfig[i + 1];
                                                if (entryBelow) {
                                                    // swap
                                                    const newData = [
                                                        ...tableConfig.slice(0, i),
                                                        entryBelow,
                                                        f,
                                                        ...tableConfig.slice(i + 2),
                                                    ];
                                                    setTableConfig(newData);
                                                }
                                                // shouldn't reach here.
                                            }}
                                        >
                                            <ArrowDown />
                                        </Button>
                                    )}
                                    &nbsp;
                                    {i !== 0 && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                const entryAbove = tableConfig[i - 1];
                                                if (entryAbove) {
                                                    // swap
                                                    const newData = [
                                                        ...tableConfig.slice(0, i - 1),
                                                        f,
                                                        entryAbove,
                                                        ...tableConfig.slice(i + 1),
                                                    ];
                                                    setTableConfig(newData);
                                                }
                                                // shouldn't reach here.
                                            }}
                                        >
                                            <ArrowUp />
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Popup
                                        renderDialogContent={({ closeDialog }) => (
                                            <>
                                                <div style={{ margin: '1em' }}>Delete field?</div>
                                                <CardActions>
                                                    <Button onClick={closeDialog}>Cancel</Button>
                                                    <Button
                                                        style={{ color: theme.palette.error.main }}
                                                        onClick={() => {
                                                            const newData = [
                                                                ...tableConfig.slice(0, i),
                                                                ...tableConfig.slice(i + 1),
                                                            ];
                                                            setTableConfig(newData);
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </CardActions>
                                            </>
                                        )}
                                        renderToggler={({ openDialog }) => (
                                            <IconButton
                                                style={{ color: theme.palette.error.main }}
                                                onClick={openDialog()}
                                            >
                                                <Delete />
                                            </IconButton>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Popup
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button
                        style={{ float: 'right' }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={openDialog()}
                    >
                        &nbsp;Add&nbsp;
                        <Add />
                    </Button>
                )}
            />
        </>
    );
};

export interface EditableTableEditorFieldProps {
    label: String;
    onChange: (tableConfig: TableConfig) => void;
    value: TableConfig;
    disabled?: boolean;
    renderFieldEditor: RenderFieldEditor;
}

const EditableTableEditorField: FunctionComponent<EditableTableEditorFieldProps> = ({
    value,
    disabled,
    label,
    onChange,
    renderFieldEditor,
}) => {
    return (
        <>
            <Typography variant="h6" component="div">
                {label}
            </Typography>
            <EditableTableEditor
                renderFieldEditor={renderFieldEditor}
                disabled={disabled}
                tableConfig={value || []}
                setTableConfig={onChange}
            />
        </>
    );
};
export default EditableTableEditorField;
