import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, withLatestFrom, concatMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as actions from './actions';
import { getStorage } from 'storage/storage';

const persistProfilesFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(Object.values(actions))),
        withLatestFrom(state$.pipe(map((state: RootState) => state.profiles))),
        concatMap(([action, profiles]) => {
            getStorage().setItem('casetivity-profiles', JSON.stringify(profiles));
            return EMPTY;
        }),
    );
};
export default persistProfilesFlow;
