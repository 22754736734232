import React from 'react';
import AddressPopoverVerify from './popoverAddress';
import { IAddressWidgetConfig, IFieldMapping1 } from './types';
import { EntityAddressVerificationField, FlowableAddressField } from '../../../translation/types';
import { BadConfig, getSource, isFieldFromFlowable } from './util';
import decodeAddressConfig from './util/decodeAddressConfig';
import ViewConfig from 'reducers/ViewConfigType';
import { createGetAllFieldPermissionsOnEntity } from 'components/WithEntityFieldPermissions';
import { fromNullable } from 'fp-ts/lib/Option';
import getDisplayAddressVerif from './getDisplayAddress';
import { IFieldMapping2 } from './types/runtime';
import { allowsCreate, allowsEdit } from 'components/generics/utils/viewConfigUtils';
import { FormType } from 'components/generics/form/formTypeContext';
import { getAddressValidation } from './getAddressValidation';

const getAddressFieldPermissions = createGetAllFieldPermissionsOnEntity();

const fieldsWithPermissionsThatMustBeEnabled: (keyof IFieldMapping1 | keyof IFieldMapping2)[] = [
    'casetivityStreet',
    'casetivityHouse',
    'casetivityNeighborhoodId',
    // 'casetivityStateId',
    'casetivityZip',
];
const shouldDisableBasedOnFieldPermissions = (
    fieldMapping: IAddressWidgetConfig['fieldMapping'],
    fieldPermissions: null | { [field: string]: number },
    formTypeContextValue: FormType = 'NONE',
): boolean => {
    const fieldsWeCareAbout = fieldsWithPermissionsThatMustBeEnabled
        .map((f) => fieldMapping[f])
        .map((f) =>
            f.endsWith('Id') ? f.slice(0, -'Id'.length) : f.endsWith('Code') ? f.slice(0, -'Code'.length) : f,
        );
    if (fieldPermissions) {
        return !!Object.entries(fieldPermissions).find(
            ([f, perm]) =>
                fieldsWeCareAbout.indexOf(f) !== -1 &&
                (formTypeContextValue === 'CREATE'
                    ? !allowsCreate(perm)
                    : formTypeContextValue === 'EDIT'
                    ? !allowsEdit(perm)
                    : perm < 2),
        );
    }
    return false;
};

const getAddressPopoverVerify = (
    propConfiguration,
    fieldDefinition: EntityAddressVerificationField | FlowableAddressField,
    liveProps,
    viewConfig: ViewConfig,
) => {
    try {
        const configStr: string | undefined = isFieldFromFlowable(fieldDefinition)
            ? fieldDefinition.params.address.configs
            : fieldDefinition.config;
        if (!configStr) {
            throw new Error('Address widget configuration is empty.');
        }

        const parsedConfig: IAddressWidgetConfig = decodeAddressConfig(configStr, 'THROW_ERROR');
        const showDisplayOnlyVersion = (formType: FormType = 'NONE') =>
            fieldDefinition._dataSource === 'Entity' &&
            fromNullable(getAddressFieldPermissions({ viewConfig }, { resource: 'Address' }))
                .map((fp) => shouldDisableBasedOnFieldPermissions(parsedConfig.fieldMapping, fp, formType))
                .getOrElse(false);
        if (showDisplayOnlyVersion()) {
            return getDisplayAddressVerif(propConfiguration, fieldDefinition, liveProps);
        }
        const source = getSource(fieldDefinition);
        return (
            <AddressPopoverVerify
                {...propConfiguration}
                source={source}
                showDisplayOnlyVersion={showDisplayOnlyVersion}
                validate={parsedConfig ? getAddressValidation(parsedConfig, source) : undefined}
                warn={parsedConfig ? [getAddressValidation(parsedConfig, source)] : undefined}
                key={`${propConfiguration.source}-${propConfiguration.label}`}
                addField={true}
                config={parsedConfig.fieldMapping}
                useAddressEntityValidations={parsedConfig.useAddressEntityValidations}
                fixedValues={parsedConfig.fixedValues}
                manualVerification={parsedConfig.manualVerificationAllowed}
                verificationRequired={parsedConfig.verificationRequired}
                showVerificationStatus={parsedConfig.showVerificationStatus || false}
                {...liveProps}
            />
        );
    } catch (e) {
        const props = {
            ...propConfiguration,
            key: `${propConfiguration.source}-${propConfiguration.label}`,
            addField: true,
            ...liveProps,
        } as any;
        return (
            <BadConfig
                {...props}
                data-originaldefinition={propConfiguration['data-originaldefinition']}
                title={'Bad Configuration (Address)'}
                e={e}
            />
        );
    }
};

export default getAddressPopoverVerify;
