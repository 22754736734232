import React, { useEffect, useMemo, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector, useStore } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { createSelector } from 'reselect';
import { getProcessId, getTaskId } from 'configureStore/util';
import {
    initializeLinkedEntityFormState,
    initializeTaskFormFormStates,
} from 'offline_app/offline_stateful_tasks/util/loadTaskStateFromIDB';
import {
    setCurrentlyWritingToOffline,
    unsetCurrentlyWritingToOffline,
} from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/actions';
import {
    setEntitySubmitsInTaskContext,
    unsetEntitySubmitsInTaskContext,
} from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/actions';
import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { Entry } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';
import { getDencryptTaskDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeTaskData';
import { getDecryptEntityDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeEntityData';
import isOffline from 'util/isOffline';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from 'offline_app/offline_stateful_tasks/ExpiredOfflineDataSubscribedComponentsRegistry';
import { offlineTaskToProfileIdbKeyVal } from 'IndexedDB/offlineTaskToProfile';
import useViewConfig from 'util/hooks/useViewConfig';
import loadProfile from 'auth/profiles/util/loadProfile';
import Alert from '@material-ui/lab/Alert';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import Popup from 'components/Popup';
import Delete from '@material-ui/icons/Delete';
import { unMarkTaskForOffline } from 'offline_app/offline_stateful_tasks/offlineTasks/actions';
import {
    setDownloadedListViews,
    unsetDownloadedListViews,
} from 'offline_app/offline_stateful_tasks/download/downloadedListViews/actions';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { crudGetOne } from 'sideEffect/crud/getOne/actions';
import useIsPristine from 'offline_app/hooks/useIsPristine';
import isTheInstalledApp from 'util/isTheInstalledApp';
import { setDownloadedRefOneViews } from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/actions';
import makeCancelable from 'util/makeCancelable';
import { RootAction } from 'actions/rootAction';
import createGetInitialFormValues from 'components/generics/form/getFormInitial/createGetInitialValues';
import { createLinkedViewNameSelector } from 'bpm/selectors/util';
import ViewConfig from 'reducers/ViewConfigType';
import { initialize } from 'redux-form';
import { usePrefetchLists } from 'components/generics/form/prefetchLists';
import { userAgent } from 'userAgent';
import Message from 'i18n/components/Message';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular as any,
        },
    }),
);

export const getOfflineSubmits = async (taskId: string) => {
    const keys = await offlineEntitySubmitsIdbKeyVal.keys();
    const data: Entry[] = [];
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value: Entry = await getDecryptEntityDataPromptController().promptDecodeEntityData(key, false);
        if (value.taskId === taskId) {
            data.push(value);
        }
    }
    return data;
};

const useCurrentTaskId = () => {
    const currentTaskIdSelector = useMemo(() => {
        return createSelector((state: RootState) => state.router.location.pathname, getTaskId);
    }, []);
    const currentTaskId = useSelector(currentTaskIdSelector);
    return currentTaskId;
};

const useCurrentProcessId = () => {
    const currentProcessIdSelector = useMemo(() => {
        return createSelector((state: RootState) => state.router.location.pathname, getProcessId);
    }, []);
    const currentProcessId = useSelector(currentProcessIdSelector);
    return currentProcessId;
};

export const useOfflineTaskOtherProfile = <T extends any = null>(currentTaskId: string, initialState: T = null) => {
    const [offlineTaskOtherProfile, setOfflineTaskOtherProfile] = useState<
        | {
              display: string;
              userId: string;
          }
        | T
    >(initialState);
    const viewConfig = useViewConfig();
    const currentUserId = viewConfig.user.id;
    useEffect(() => {
        if (process.env.NODE_ENV === 'test') {
            return;
        }
        if (!currentTaskId) {
            setOfflineTaskOtherProfile(null);
            return;
        }
        const cancelableAssignedProfile = makeCancelable(offlineTaskToProfileIdbKeyVal.get(currentTaskId));
        cancelableAssignedProfile.promise.then((assignedProfile) => {
            if (assignedProfile && assignedProfile.userId !== currentUserId) {
                setOfflineTaskOtherProfile(assignedProfile);
            } else {
                setOfflineTaskOtherProfile(null);
            }
        });
        return () => {
            cancelableAssignedProfile.cancel();
        };
    }, [currentTaskId, currentUserId]);
    return offlineTaskOtherProfile;
};

export const useHasOfflineWorkToApply = () => {
    const currentlyWriting = useSelector((state: RootState) => state.taskCurrentlyWritingToOffline);
    const currentTaskId = useCurrentTaskId();
    const taskIsOffline = useSelector((state: RootState) => Boolean(state.offlineTasks?.[currentTaskId]));
    const [taskHasOfflineWork, setTaskHasOfflineWork] = useState(isTheInstalledApp() ? taskIsOffline : false);

    useEffect(() => {
        if (!isTheInstalledApp()) {
            setTaskHasOfflineWork(false);
            return;
        }
        const setHasOfflineWork = (offlineTaskKeys: string[]) => {
            const hasOfflineWork = offlineTaskKeys.includes(currentTaskId);
            setTaskHasOfflineWork(hasOfflineWork);
        };

        const checkAndSetIfHasOffline = async () => {
            if (!currentTaskId) {
                return;
            }
            if (process.env.NODE_ENV === 'test') {
                // idb not available in jest tests
                return;
            }
            const keys = await idbKeyval.keys();
            setHasOfflineWork(keys);
        };
        const cancellableCheckAndSetIfHasOffline = makeCancelable(idbKeyval.keys());
        cancellableCheckAndSetIfHasOffline.promise.then(setHasOfflineWork);
        getExpiredOfflineDataSubscribedComponentsRegistry().registerCallback(checkAndSetIfHasOffline);
        return () => {
            cancellableCheckAndSetIfHasOffline.cancel();
            getExpiredOfflineDataSubscribedComponentsRegistry().unregisterCallback(checkAndSetIfHasOffline);
        };
    }, [currentTaskId]);

    return !isOffline() && taskIsOffline && currentTaskId && taskHasOfflineWork && currentlyWriting !== currentTaskId;
};
const OfflineWorkAlert = () => {
    const store = useStore<RootState, RootAction>();
    const viewConfig = useViewConfig();
    const currentTaskId = useCurrentTaskId();
    const taskIsClosed = useSelector((state: RootState) => Boolean(state.bpm.tasks.byId[currentTaskId]?.endDate));
    const initialAssigneeSelector = useMemo(() => {
        const getEntities = createGetEntities();
        return createSelector(getEntities, (entities) => {
            return traverseGetData(
                viewConfig,
                'assignee.login',
                {
                    id: currentTaskId,
                    entityType: 'TaskInstance',
                },
                entities,
            ).toUndefined() as string;
        });
    }, [currentTaskId, viewConfig]);
    const assigneeLogin = useSelector((state: RootState) => initialAssigneeSelector(state));
    const currentProcessId = useCurrentProcessId();
    const linkedViewNameSelector = useMemo(createLinkedViewNameSelector, []);
    const viewName = useSelector((state: RootState) =>
        linkedViewNameSelector(state, {
            taskId: currentTaskId,
            processId: currentProcessId,
            overrideViewConfig: viewConfig,
        }),
    );
    const classes = useStyles();
    const [pendingLoad, setPendingLoad] = useState(false);
    const prefetchLists = usePrefetchLists();
    const handleLoad = useMemo(() => {
        const load = async () => {
            // wait a quarter of a second with the spinner
            setPendingLoad(true);
            await new Promise((resolve) => setTimeout(resolve, 250));
            try {
                const decodedState = await getDencryptTaskDataPromptController().promptDecodeTaskData(currentTaskId);
                const patchLinkedEntityInitialValues =
                    decodedState.offlineMeta?.[currentTaskId]?.linkedEntity?.patchLinkedEntityInitialValues;
                const recordForm = decodedState.form?.['record-form'];
                if (recordForm) {
                    // clear out the active field - this messes with the merge triggering, because we don't do that while a field is active.
                    recordForm.active = undefined;
                    // patch initial values with the ones stored in offlineMeta so we can trigger merges with the original 'common ancestor' record.
                    if (patchLinkedEntityInitialValues && recordForm.initial) {
                        Object.assign(recordForm.initial, patchLinkedEntityInitialValues);
                        const linkedEntity =
                            decodedState.admin?.entities?.[patchLinkedEntityInitialValues.entityType]?.[
                                patchLinkedEntityInitialValues?.id
                            ];
                        if (linkedEntity) {
                            Object.assign(linkedEntity, patchLinkedEntityInitialValues);
                        }
                    }
                }

                const data = await getOfflineSubmits(currentTaskId);
                initializeTaskFormFormStates(store, decodedState);
                // This should make no difference in the online app -
                // only applying offlinedownloadedxviews in order for it not to get overwritten as null
                // when writing in the online app, because that value isn't present in state
                store.dispatch(setDownloadedListViews(decodedState.offlineDownloadedListViews));
                store.dispatch(setDownloadedRefOneViews(decodedState.offlineDownloadedRef1Views));

                store.dispatch(setCurrentlyWritingToOffline(currentTaskId, false));
                store.dispatch(setEntitySubmitsInTaskContext(currentTaskId, data));
                setImmediate(() => {
                    // set initial state
                    initializeLinkedEntityFormState(store, decodedState);

                    // wait and then fetch linked entity to trigger merge popup
                    const { entityType: linkedEntityType, id: linkedEntityId } = recordForm?.values ?? {};
                    if (linkedEntityId && linkedEntityType) {
                        setTimeout(() => {
                            prefetchLists(viewName, linkedEntityId, undefined, { perPage: 100 });
                            store.dispatch(
                                crudGetOne({
                                    id: linkedEntityId,
                                    resource: linkedEntityType,
                                    view: viewName,
                                    errorsCbs: {
                                        '*': () => {
                                            alert(
                                                'An error occurred. Try again when you have a stable network connection.',
                                            );
                                            store.dispatch(unsetEntitySubmitsInTaskContext());
                                            store.dispatch(unsetCurrentlyWritingToOffline());
                                        },
                                    },
                                    cb(id, data) {
                                        // could put this in the prefetchLists callback...
                                        const getNewInitialValues = createGetInitialFormValues<{
                                            viewName: string;
                                            overrideViewConfig?: ViewConfig;
                                            id: string;
                                        }>({
                                            getIdFromProps: ({ id }) => id,
                                        });
                                        // need to let store update with new data, and then force redux-form to re-initialize with that new data,
                                        // because unfortunately it's buggy and doesn't do that automatically.
                                        // and so without this, we lose any changes that have been made to the linked-entity after we downloaded it
                                        // to merge into our offline work when we apply it.
                                        setImmediate(() => {
                                            const newInitialValues = getNewInitialValues(store.getState(), {
                                                id,
                                                overrideViewConfig: viewConfig,
                                                viewName,
                                            });
                                            store.dispatch(
                                                initialize('record-form', newInitialValues, true, {
                                                    updateUnregisteredFields: true,
                                                }),
                                            );
                                        });
                                        setTimeout(() => {
                                            store.dispatch(setCurrentlyWritingToOffline(currentTaskId, true));
                                        }, 250);
                                    },
                                }),
                            );
                        }, 100);
                    }
                });
            } finally {
                setPendingLoad(false);
            }
        };
        return load;
    }, [store, currentTaskId, viewConfig, viewName, prefetchLists]);
    const hasOfflineWorkToApply = useHasOfflineWorkToApply();
    const profiles = useSelector((state: RootState) => state.profiles);

    const offlineTaskOtherProfile = useOfflineTaskOtherProfile(currentTaskId);

    const isPristine = useIsPristine(currentTaskId);
    if (!hasOfflineWorkToApply || !currentProcessId || isPristine === 'pending') {
        return null;
    }
    const deleteButton = (
        <Popup
            renderDialogContent={({ closeDialog }) => (
                <Card>
                    <CardHeader title="Discard offline work" />
                    <CardContent>Are you sure you would like to discard any offline changes?</CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                store.dispatch(unMarkTaskForOffline(currentTaskId));
                                store.dispatch(unsetDownloadedListViews());
                                closeDialog();
                            }}
                        >
                            <Typography color="error">Discard</Typography>&nbsp;
                            <Delete color="error" />
                        </Button>
                    </CardActions>
                </Card>
            )}
            renderToggler={({ openDialog }) => (
                <Button
                    style={{ margin: '0px 3px' }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    onClick={openDialog()}
                >
                    {isPristine ? 'Remove Offline Availability' : 'Discard'}
                </Button>
            )}
        />
    );
    const loadElem = (
        <>
            <Typography className={classes.heading}>
                {isPristine
                    ? 'This task is currently available for offline mode but has no changes. Would you like to remove offline availability or continue with the task still being available in offline mode?'
                    : 'You have offline work saved for this task. Would you like to load your changes?'}
            </Typography>
            <div style={{ marginTop: '5px' }}>
                {deleteButton}
                <Button
                    style={{ margin: '0px 3px' }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    onClick={handleLoad}
                    endIcon={pendingLoad ? <CircularProgress size="1em" /> : undefined}
                >
                    {isPristine ? 'Continue' : 'Load Offline Work'}
                </Button>
                {pendingLoad ? <span>&nbsp;Please wait...</span> : null}
                {/* in iOS, buttons on the page remain clickable. Lets prevent that with a pop-up. */}
                {userAgent.isIosStandalone() ? (
                    <Dialog open={pendingLoad}>
                        <DialogTitle>Loading</DialogTitle>
                        <DialogContent>
                            <div style={{ display: 'grid', placeItems: 'center', padding: '1em' }}>
                                <CircularProgress />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <Typography>
                                    <Message id="loading.pleaseWait" dm="Please wait..." />
                                </Typography>
                            </div>
                        </DialogContent>
                    </Dialog>
                ) : null}
            </div>
        </>
    );
    const switchProfileElem =
        profiles.state === 'no_profiles'
            ? null
            : (() => {
                  const profileDisplay = profiles.profiles.find(
                      (p) => p.userId === offlineTaskOtherProfile?.userId,
                  )?.name;
                  if (!profileDisplay && offlineTaskOtherProfile) {
                      // Could be an entirely differnet user (meaning, not an alternate profile.)
                      return (
                          <>
                              <Typography className={classes.heading}>
                                  You have offline work saved for this task as an alternate user:{' '}
                                  {offlineTaskOtherProfile.display}
                              </Typography>
                          </>
                      );
                  }
                  return (
                      <>
                          <Typography className={classes.heading}>
                              You have offline work saved for this task as {profileDisplay}
                          </Typography>
                          <div style={{ marginTop: '5px' }}>
                              <Button
                                  variant="outlined"
                                  onClick={() => {
                                      loadProfile(
                                          store,
                                          offlineTaskOtherProfile.userId,
                                          '/processes/' + currentProcessId + '/tasks/' + currentTaskId + '/start',
                                      );
                                  }}
                              >
                                  Switch to this user?
                              </Button>
                          </div>
                      </>
                  );
              })();
    return (
        <div style={{ position: 'sticky', marginBottom: '1em', top: 0, zIndex: 999 }}>
            {taskIsClosed ? (
                <Alert severity="warning">
                    You have offline work for this task, but it has already been completed. {deleteButton}
                </Alert>
            ) : viewConfig.user.login !== assigneeLogin && !offlineTaskOtherProfile ? (
                <Alert severity="warning">
                    You have offline work for this task, but it has been claimed by another user. If you would like to
                    overwrite their work, assign the task back to yourself to continue working.
                </Alert>
            ) : (
                <Alert severity="info">{offlineTaskOtherProfile ? switchProfileElem : loadElem}</Alert>
            )}
        </div>
    );
};

export default OfflineWorkAlert;
