import React, { useState, useCallback, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { initial, RemoteData, pending, success, failure } from '@devexperts/remote-data-ts';
import { AjaxError, ajax } from 'rxjs/ajax';
import { refreshJwt, getOptions, getUrl } from 'sideEffect/services';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';

interface TestCalcProps {
    entityType: string;
    entityId: string;
}
const TestCalc: React.SFC<TestCalcProps> = (props) => {
    const [text, setText] = useState('');
    const [state, setState] = useState<RemoteData<AjaxError, any>>(initial);

    const submit = useCallback(() => {
        setState(pending);
    }, []);
    useEffect(() => {
        if (state.isPending()) {
            const $ajax = refreshJwt(
                ajax({
                    ...getOptions(getUrl('api/test-calc'), 'POST', {
                        entityType: props.entityType,
                        entityId: props.entityId,
                        calcExpression: text,
                    }),
                    responseType: 'text',
                }),
            );

            const subscription = $ajax.subscribe(
                (res) => {
                    setState(success(res.response));
                },
                (error: AjaxError) => {
                    setState(failure(error));
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, setState, props.entityId, props.entityType, text]);

    return (
        <div style={{ padding: '1em' }}>
            <p>Backend Calcs can be tested below. A different set of functions will be available.</p>
            <label>
                <b>Test Calc (backend) Expression</b>
                <div style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
                    <AutocompleteSpelEditor rootEntity={props.entityType} value={text} onChange={setText} hideDocs />
                </div>
            </label>
            <Button variant="contained" color="primary" onClick={submit}>
                Submit
            </Button>
            {state.fold(
                null,
                <CircularProgress />,
                (error) => (
                    <div>
                        Failed:{' '}
                        {error.message === 'ajax error' ? 'Network error' : JSON.stringify(error.response, null, 2)}
                    </div>
                ),
                (success) => (
                    <div>Success: {success}</div>
                ),
            )}
        </div>
    );
};

export default TestCalc;
