import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { changeOnline } from './actions';

const connectivityStatus = (state: boolean = navigator.onLine, action: RootAction) => {
    switch (action.type) {
        case getType(changeOnline): {
            return action.payload.online;
        }
        default:
            return state;
    }
};
export default connectivityStatus;
