import React, { useMemo } from 'react';
import AttemptRequest from 'components/AttemptRequest';
import { ServerError, NetworkUnavailable } from 'remoteStatus/one/components/pages';
import DeferredSpinner from 'components/DeferredSpinner';
import { Helmet } from 'react-helmet';
import HtmlToReact from 'html-to-react';
import { fromPredicate } from 'fp-ts/lib/Option';
import { StaticRouter } from 'react-router-dom';
import SsgAppBarMobile from 'components/SsgAppBarMobile';
import createProcessingInstructions from '../createProcessingInstructions';
import { parse, stringify } from 'query-string';
import SafeHtmlAsReact from './SafeHtmlAsReact';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { getUrl } from 'sideEffect/services';
import buildHeaders from 'sideEffect/buildHeaders';

export const registerRoutesElement: {
    element?: React.FC<{}>;
} = {};

const htmlToReactParser = new HtmlToReact.Parser();

const isValidNode = function () {
    return true;
};

// Order matters. Instructions are processed in
// the order they're defined
const processingInstructions = createProcessingInstructions();

export const processWithReact = (html: string) =>
    htmlToReactParser.parseWithInstructions(html, isValidNode, processingInstructions);

const getLazyR = (textTemplateName: string) => () =>
    fetch(getUrl(`api/pages/${textTemplateName}`), {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            includeCredentials: true,
        }),
    });

interface TextTemplatePageProps {
    textTemplateName: string;
    padding?: boolean;
    mobileAppbar?: boolean;
    search?: string;
}
const TEMPLATE_PRE = 'template_';
const getFilteredQueryString = (templateParams?: boolean) => (search: string) => {
    if (!search) {
        return '';
    }
    const params = parse(search);
    const res = stringify(
        Object.fromEntries(
            Object.entries(params)
                .filter(([k]) => {
                    const isTemplateParam = k.startsWith(TEMPLATE_PRE);
                    if (templateParams) {
                        return isTemplateParam;
                    }
                    return !isTemplateParam;
                })
                .map(([k, v]) => [k.startsWith(TEMPLATE_PRE) ? k.slice(TEMPLATE_PRE.length) : k, v]),
        ),
    );
    if (res) {
        return '?' + res;
    }
    return '';
};
const TextTemplatePage: React.FC<TextTemplatePageProps> = (props) => {
    const { padding = true, mobileAppbar = true, search } = props;
    const templateRequestQueryString = useMemo(() => {
        return getFilteredQueryString(true)(search);
    }, [search]);
    const passThroughQueryString = useMemo(() => {
        return getFilteredQueryString(false)(search);
    }, [search]);
    return (
        <AttemptRequest<{
            text?: string;
            url?: string;
        }>
            key={props.textTemplateName}
            type="external"
            lazyRequest={getLazyR(props.textTemplateName + (templateRequestQueryString ?? ''))}
            requestOnMount={true}
            renderer={({ attemptAction }) =>
                (state) => {
                    if (state._tag === 'pending') {
                        return <DeferredSpinner />;
                    }
                    if (state._tag === 'success') {
                        const title = fromPredicate<string>(Boolean)(state.data.text)
                            .mapNullable((txt) => txt.split('\n')[0])
                            .map((fl) => fl.trim())
                            .chain((tfl) =>
                                fromPredicate<string>((fl: string) => fl.startsWith('<!--') && fl.endsWith('-->'))(
                                    tfl,
                                ).map((fl) => fl.slice('<!--'.length, '-->'.length * -1)),
                            )
                            .map((i) => i.trim())
                            .toNullable();

                        if (state.data.url) {
                            // no mobile appbar since the route itself will provide it.
                            return (
                                <StaticRouter location={state.data.url + (passThroughQueryString ?? '')}>
                                    {/* <Routes /> */}
                                    <registerRoutesElement.element />
                                </StaticRouter>
                            );
                        }
                        return (
                            <>
                                {mobileAppbar !== false && <SsgAppBarMobile title={null} />}
                                <div style={{ width: '100%' }}>
                                    <EvaluateFormattedMessage>
                                        {({ evaluateFormattedMessage }) => {
                                            return (
                                                title && (
                                                    <Helmet>
                                                        <title>{evaluateFormattedMessage(title)}</title>
                                                    </Helmet>
                                                )
                                            );
                                        }}
                                    </EvaluateFormattedMessage>
                                    <div style={{ padding: padding ? '1em' : undefined }}>
                                        {state.data?.text && <SafeHtmlAsReact html={state.data.text} />}
                                    </div>
                                </div>
                            </>
                        );
                    }
                    return (
                        <>
                            <SsgAppBarMobile title={null} />
                            {(() => {
                                if (state._tag === 'failure') {
                                    if (state.status) {
                                        return <ServerError code={state.status} message={state.message} />;
                                    }
                                    return <NetworkUnavailable retry={attemptAction} />;
                                }
                                if (state._tag === 'unsubmitted') {
                                    return null;
                                }
                            })()}
                        </>
                    );
                }}
        />
    );
};

export default TextTemplatePage;
