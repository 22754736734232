import { NOT_MATCH } from './constants';
export default (
    resource: string,
    id: string,
    matchId: string,
    cb: (...args: any[]) => void = () => null,
    errorCb: () => void,
) => ({
    type: NOT_MATCH,
    payload: {
        resource,
        id,
        matchId,
    },
    cb,
    errorCb,
});
