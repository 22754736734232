import ReactGA from 'react-ga';

let initialized = false;

function init(trackingId: string) {
    // Enable debug mode on the local development environment
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    ReactGA.initialize(trackingId, { debug: isDev });
    initialized = true;
}

function sendEvent(payload) {
    if (initialized) {
        ReactGA.event(payload);
    }
}

function sendPageview(path) {
    if (initialized) {
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

export default {
    init,
    sendEvent,
    sendPageview,
};
