import React, { useMemo } from 'react';
import { processWithReact } from '.';
import useTemplateMessagesAndPurifyHtml from 'templatePage/hooks/templateMessagesAndPurifyHtml';

interface SafeHtmlAsReactProps {
    html: string;
    containerStyle?: React.CSSProperties;
}
const SafeHtmlAsReact = React.memo(({ html, containerStyle }: SafeHtmlAsReactProps) => {
    const templatedHtml = useTemplateMessagesAndPurifyHtml(html);
    const contents = useMemo(() => {
        return processWithReact(templatedHtml);
    }, [templatedHtml]);

    return <div style={containerStyle}>{contents}</div>;
});

export default SafeHtmlAsReact;
