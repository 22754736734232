import { createCustomAction } from 'typesafe-actions';
import {
    LOAD_PROCESS_DEFINITIONS,
    LOAD_PROCESS_DEFINITIONS_LOADING,
    LOAD_PROCESS_DEFINITIONS_FAILURE,
    LOAD_PROCESS_DEFINITIONS_SUCCESS,
} from './constants';
import ProcessDefinition from 'bpm/types/processDefinition';

export const getProcessDefinitions = createCustomAction(LOAD_PROCESS_DEFINITIONS, (type) => {
    return (notifyOnSuccess: boolean = true) => ({ type, notifyOnSuccess });
});

export const getProcessDefinitionsLoading = createCustomAction(LOAD_PROCESS_DEFINITIONS_LOADING, (type) => {
    return () => ({ type });
});

export const getProcessDefinitionsSuccess = createCustomAction(LOAD_PROCESS_DEFINITIONS_SUCCESS, (type) => {
    return (processDefinitions: ProcessDefinition[]) => {
        return {
            type,
            payload: {
                // instead of 'data', so it doesn't get sent to Entities part of app.
                _data: {
                    result: processDefinitions.map((pd) => pd.key),
                    entities: {
                        ProcessDefinition: Object.assign(
                            {},
                            ...processDefinitions.map((data) => ({ [data.key]: data })),
                        ),
                    },
                },
            },
        };
    };
});

export const getProcessDefinitionsFailure = createCustomAction(LOAD_PROCESS_DEFINITIONS_FAILURE, (type) => {
    return (error: Error) => ({
        type,
        error,
    });
});
