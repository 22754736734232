import React from 'react';
import { Pagination as MuiRestListPagination } from 'mui-rest-list';
import { PaginationProps } from 'mui-rest-list/lib/src/components/Pagination';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

const Pagination: React.FC<PaginationProps> = (props) => {
    const maxExactTotalCount = useSelector((state: RootState) => state.basicInfo?.maxExactTotalCount);
    return <MuiRestListPagination {...props} maxExactTotalCount={maxExactTotalCount} />;
};

export default Pagination;
