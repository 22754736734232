import * as taskForm from '../actions';
import {
    RemoteError,
    RemoteSuccess,
    RemoteLoading,
    createLoading,
    createError,
    createSuccess,
} from 'remoteStatus/one/reducer';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';

export type TaskFormRemoteStatus = RemoteError | RemoteSuccess | RemoteLoading;
type TaskFormRemoteStatuses = {
    [taskId: string]: TaskFormRemoteStatus;
};
const taskFormRemoteStatus = (state: TaskFormRemoteStatuses = {}, action: RootAction): TaskFormRemoteStatuses => {
    switch (action.type) {
        case getType(taskForm.getTaskFormLoading): {
            const existing = state[action.payload.taskId] || 'initial';
            return {
                ...state,
                [action.payload.taskId]: createLoading(existing),
            };
        }
        case getType(taskForm.getTaskFormFailure): {
            return {
                ...state,
                [action.requestPayload.taskId]: createError(action.error),
            };
        }
        case getType(taskForm.getTaskFormSuccess): {
            return {
                ...state,
                [action.requestPayload.taskId]: createSuccess(),
            };
        }
        default:
            return state;
    }
};
export default taskFormRemoteStatus;
