import ViewConfig, { Entity } from 'reducers/ViewConfigType';
import merge from 'lodash/merge';

const setupDefaultViews = (viewConfig: ViewConfig): ViewConfig => {
    // use viewdefs: we overwrite default-views
    return {
        ...viewConfig,
        entities: Object.fromEntries(
            Object.entries(viewConfig.entities).map(([k, e]) => {
                let entity: Entity = {
                    ...e,
                    defaultViews: e.defaultViewDefs ? merge({}, e.defaultViews, e.defaultViewDefs) : e.defaultViews,
                };
                return [k, entity];
            }),
        ),
    };
};
export default setupDefaultViews;
