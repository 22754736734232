import React, { ReactElement, useMemo } from 'react';
import { RootState } from '../../../reducers/rootReducer';
import { createSelector } from 'reselect';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { Subtract } from 'utility-types';
import memoizeOne from 'memoize-one';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import createRecordFromPath from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData/createRecordFromPath';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import applyDeltaToEntities from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData/ApplyDeltaToEntities';
import flatten from 'flat';

const NOT_FOUND = '_NOT_FOUND________';

interface FieldSubscriptionProps {
    subscribeToEntireRecord?: boolean;
    source: string;
    defaultValue?: string | ReactElement<any>;
    resource: string;
    id?: string | number;
    record?: { id: string | number };
    delta?: {};
}

interface FieldSubscriberProps extends FieldSubscriptionProps {
    renderDisplayField: (params: { record: {}; value: any }) => React.ReactElement<{}> | null;
}

interface FieldSubscriberComponentProps extends FieldSubscriberProps {
    record: { id: string | number };
    value?: any;
}
const FieldSubscriberComponent: React.SFC<FieldSubscriberComponentProps> = ({ renderDisplayField, record, value }) => {
    return renderDisplayField({ record, value });
};

const getAppliedSource = (subscribeToEntireRecord?: boolean) => (source: string) =>
    subscribeToEntireRecord
        ? source.split('.').slice(0, -1).join('.') // path to record only
        : source;

const getMakeMapStateToProps =
    <FieldSubscriberProps extends FieldSubscriptionProps & { getValueAtLongestResolvablePath?: boolean }>(
        withDelta: boolean = false,
    ) =>
    () => {
        const empty = {};
        const flattenedDelta = createSelector(
            (state: RootState, props: FieldSubscriberComponentProps) => props.delta,
            (delta) => delta && flatten(delta),
        );
        const memoizedCreateRecordFromPath = memoizeOne(createRecordFromPath);
        const getEntitiesSelector = (() => {
            if (withDelta) {
                const getEntities = createGetEntities();
                return createSelector(
                    getEntities,
                    (state: RootState) => state.viewConfig,
                    flattenedDelta,
                    (state: RootState, props: FieldSubscriberProps) => props.id,
                    (state: RootState, props: FieldSubscriberProps) => props.resource,
                    (entities, viewConfig, delta, id, entityType) => {
                        return applyDeltaToEntities(viewConfig, entities, delta, { id, entityType }, false);
                    },
                );
            }
            return (state: RootState, props: FieldSubscriberProps) => state.admin.entities as any;
        })();
        const valueSelector = createSelector(
            (state: RootState, props: FieldSubscriberProps) => props.source,
            (state: RootState, props: FieldSubscriberProps) => props.id,
            (state: RootState, props: FieldSubscriberProps) => props.resource,
            (state: RootState, props: FieldSubscriberProps) => state.viewConfig,
            getEntitiesSelector,
            (state: RootState, props: FieldSubscriberProps) => props.subscribeToEntireRecord,
            (state: RootState, props: FieldSubscriberProps) => props.getValueAtLongestResolvablePath,
            (source, id, resource, viewConfig, entities, subscribeToEntireRecord, getLongestResolvablePath) => {
                // optimization here: only update for entities on our path.
                // for now we just recalculate calculate for all entities

                // it would be nice to deal with optionals past this point, but we need to be able to check equality
                // for caching results
                if (!id) {
                    return NOT_FOUND;
                }
                if (getLongestResolvablePath) {
                    const increasinglyLongerPathsToTry = source
                        .split('.')
                        .reduce(
                            (prev, curr, i, arr) => {
                                const untilNow = [...prev, curr];
                                prev.push(untilNow.filter(Boolean).join('.'));
                                return prev;
                            },
                            [''] as string[],
                        )
                        .reverse();
                    const [data, path] = increasinglyLongerPathsToTry.reduce(
                        (prev, path) => {
                            if (prev[0]) return prev;

                            const data = traverseGetData(
                                viewConfig,
                                getAppliedSource(subscribeToEntireRecord)(path),
                                { id, entityType: resource },
                                entities,
                            ).toNullable();
                            if (!data) return prev;
                            return [data, path];
                        },
                        [null, ''],
                    );
                    if (typeof data === 'undefined' || data === null) {
                        return NOT_FOUND;
                    }
                    return {
                        data,
                        path,
                    };
                }
                return traverseGetData(
                    viewConfig,
                    getAppliedSource(subscribeToEntireRecord)(source),
                    { id, entityType: resource },
                    entities,
                ).fold<any>(NOT_FOUND, (data) => ({
                    data,
                    path: source,
                }));
            },
        );
        const reconstructRecordSelector = createSelector(
            (state: RootState, props: FieldSubscriberProps) => props.source,
            valueSelector,
            (state: RootState, props: FieldSubscriberProps) => props.defaultValue,
            (state: RootState, props: FieldSubscriberProps) => props.subscribeToEntireRecord,
            (source, value, defaultValue, subscribeToEntireRecord): { record: {}; value?: any; path?: string } => {
                if (value === NOT_FOUND) {
                    if (defaultValue) {
                        return {
                            record: memoizedCreateRecordFromPath(source, defaultValue),
                            value,
                        };
                    }
                    return { record: empty };
                }
                const { path, data } = value;
                return {
                    record: memoizedCreateRecordFromPath(getAppliedSource(subscribeToEntireRecord)(source), data),
                    value: data,
                    path,
                };
            },
        );
        const mapStateToProps = (state: RootState, props: FieldSubscriberProps) => {
            return reconstructRecordSelector(state, props);
        };
        return mapStateToProps;
    };

const FieldSubscriber: React.SFC<Subtract<FieldSubscriberProps, Pick<FieldSubscriberProps, 'delta'>>> = compose(
    connect(getMakeMapStateToProps(false)),
)(FieldSubscriberComponent);

export default FieldSubscriber;

export const FieldSubscriberWithDelta: React.SFC<FieldSubscriberProps & { delta: {} }> = compose(
    connect(getMakeMapStateToProps(true)),
)(FieldSubscriberComponent);

export const useFieldSubscribeWithDelta = (
    props: FieldSubscriptionProps & { getValueAtLongestResolvablePath?: boolean },
) => {
    const selector = useMemo(() => getMakeMapStateToProps(true)(), []);
    return useSelector((state: RootState) => selector(state, props));
};

interface BaseComponentProps {
    source?: string;
    recource?: string;
    record?: {};
    id?: string | number;
    label?: string;
    addField?: boolean;
    sortable?: boolean;
    overrideRender?: (baseComponent: React.ReactElement<{}>, value: any) => React.ReactElement<{}> | null;
}

export const fieldSubscriberHoc =
    (BaseComponent: React.ComponentType<BaseComponentProps>) =>
    (props: Subtract<FieldSubscriberProps & BaseComponentProps, Pick<FieldSubscriberProps, 'renderDisplayField'>>) =>
        (
            <FieldSubscriber
                {...props}
                id={props.id || (props.record ? props.record.id : undefined)}
                renderDisplayField={({ record, value }) => {
                    const baseComponent = <BaseComponent {...props} record={record} />;
                    if (props.overrideRender) {
                        return props.overrideRender(baseComponent, value);
                    }
                    return baseComponent;
                }}
            />
        );
