import { constructOfflineListsAtCurrentLevel } from 'components/generics/form/prefetchLists';
import { DownloadedListViews } from 'offline_app/offline_stateful_tasks/download/downloadedListViews/data';
import ViewConfig from 'reducers/ViewConfigType';
import merge from 'lodash/merge';
import { DownloadedRef1Views } from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/data';
import constructOfflineRef1sAtCurrentLevel from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/constructOfflineRef1sAtCurrentLevel';
import { Store } from 'redux';
import { RootState } from 'reducers/rootReducer';
import { setDownloadedListViews } from 'offline_app/offline_stateful_tasks/download/downloadedListViews/actions';
import { setDownloadedRefOneViews } from 'offline_app/offline_stateful_tasks/download/downloadedRef1Views/actions';

const getOnlineRecordCreateFollowupActions = (
    store: Store,
    viewConfig: ViewConfig,
    id: string,
    showViewName: string,
    editViewName: string | -1,
) => {
    let offlineLists: DownloadedListViews = constructOfflineListsAtCurrentLevel(viewConfig, showViewName, id);
    let offlineRef1s: DownloadedRef1Views = constructOfflineRef1sAtCurrentLevel(viewConfig, showViewName, id);
    if (editViewName !== -1) {
        offlineLists = merge({}, offlineLists, constructOfflineListsAtCurrentLevel(viewConfig, editViewName, id));
        offlineRef1s = merge({}, offlineRef1s, constructOfflineRef1sAtCurrentLevel(viewConfig, editViewName, id));
    }
    const newOfflineDownloadedListViews = merge(
        {},
        (store.getState() as RootState).offlineDownloadedListViews ?? {},
        offlineLists,
    );

    const newOfflineDownloadedRef1Views = merge(
        {},
        (store.getState() as RootState).offlineDownloadedRef1Views ?? {},
        offlineRef1s,
    );
    return [
        setDownloadedRefOneViews(newOfflineDownloadedRef1Views),
        setDownloadedListViews(newOfflineDownloadedListViews),
    ] as const;
};
export default getOnlineRecordCreateFollowupActions;
