import * as React from 'react';
import { RootState } from '../../../../reducers/rootReducer';
import { formContext } from './FormContext';
import { Theme, useTheme } from '@material-ui/core';
import { asyncEventsInProgressContext } from '../asyncEventCountContext';
import { ButtonProps } from '@material-ui/core/Button';
import useKeyCachingEval from 'expressions/Provider/hooks/useKeyCachingEval';
import useEntities from 'util/hooks/useEntities';
import { _flowablePreprocessValues } from 'expressions/formValidation';

export type CreateCompleteButton = (
    label: string,
    outcome?: string,
    forceDisabled?: boolean,
    ButtonProps?: ButtonProps,
) => JSX.Element;

interface OutcomesProps {
    formDefinition: null | RootState['taskForms'][0];
    entityFormHasErrors?: boolean;
    taskLoaded?: boolean;
    taskOutcome?: string;
    createButton: CreateCompleteButton;
}

const Outcomes2: React.FunctionComponent<OutcomesProps> = React.memo((props) => {
    const { formDefinition, entityFormHasErrors, taskLoaded, taskOutcome, createButton } = props;
    const { fieldValues } = React.useContext(formContext);
    const expressions = React.useMemo(() => {
        return (formDefinition.outcomes || [])
            .filter(({ name }) => name && name.toLowerCase() !== '_save' && name.toLowerCase() !== '_cancel')
            .reduce(
                (prev, { name, configs }) => {
                    prev[name] = {};
                    if (configs) {
                        if (configs && configs.visibility) {
                            prev[name].visibility = [configs.visibility];
                        }
                        if (configs && configs.editable) {
                            prev[name].editable = [configs.editable];
                        }
                        if (configs && configs.display) {
                            prev[name].display = [configs.display];
                        }
                    }
                    return prev;
                },
                {} as {
                    [name: string]: {
                        editable?: string[];
                        visibility?: string[];
                        display?: string[];
                    };
                },
            );
    }, [formDefinition]);
    const evalAll = useKeyCachingEval(expressions);
    const entities = useEntities();
    const setupValues = React.useMemo(() => {
        return _flowablePreprocessValues({ ...fieldValues, entityFormHasErrors }, formDefinition.fields, entities);
    }, [formDefinition, entities, entityFormHasErrors, fieldValues]);
    const results = evalAll(setupValues);
    const theme = useTheme();
    return (
        <React.Fragment>
            {Object.entries(results)
                .filter(([name, conf]) => {
                    if (conf.visibility && conf.visibility.length > 0) {
                        return Boolean(conf.visibility[0]);
                    }
                    return true;
                })
                .map(([name, conf]) => {
                    return (
                        <span key={name}>
                            <asyncEventsInProgressContext.Consumer>
                                {({ eventCount }) => {
                                    const buttonLabel = (conf.display && conf.display[0]) || name;
                                    return (
                                        <span
                                            style={{
                                                border:
                                                    taskLoaded && taskOutcome === name
                                                        ? `1px solid ${(theme as Theme).palette.primary.dark}`
                                                        : undefined,
                                            }}
                                        >
                                            {createButton(
                                                buttonLabel,
                                                name,
                                                conf && // I think this is meant to be editable === false?
                                                    conf.editable &&
                                                    conf.editable.length > 0 &&
                                                    conf.editable[0] != null &&
                                                    conf.editable[0] !== '' &&
                                                    !conf.editable[0],
                                                {
                                                    color: 'primary',
                                                    style: { marginBottom: '5px', marginTop: '5px' },
                                                },
                                            )}
                                        </span>
                                    );
                                }}
                            </asyncEventsInProgressContext.Consumer>
                            &nbsp;&nbsp;
                        </span>
                    );
                })}
        </React.Fragment>
    );
});

export default Outcomes2;
