function focusNextSiblingElement(nextTo?: Element) {
    const focussableElements =
        'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    const curr = nextTo || document.activeElement;
    if (curr && curr.parentElement) {
        let focussable = Array.prototype.filter.call(
            curr.parentElement.querySelectorAll(focussableElements),
            function (element) {
                return element.offsetWidth > 0 || element.offsetHeight > 0 || element === curr;
            },
        );
        const index = focussable.indexOf(curr);
        const next = focussable[index + 1];
        next?.focus();
    }
}
export default focusNextSiblingElement;
