import { CONCEPT_EXPRESSIONS_LOCALSTORAGE_KEY } from './constants';
import { EntityFieldConceptExps } from './EntityFieldConceptExps';
import { fromPredicate } from 'fp-ts/lib/Option';
import { getStorage } from 'storage/storage';

const initialize = (): EntityFieldConceptExps => {
    return fromPredicate<string>(Boolean)(getStorage().getItem(CONCEPT_EXPRESSIONS_LOCALSTORAGE_KEY))
        .map((serializedConceptExpressions) => JSON.parse(serializedConceptExpressions))
        .getOrElse({});
};
export default initialize;
