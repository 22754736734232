import React from 'react';
import { Button, TextField } from '@material-ui/core';

const NameFilter = (props: {
    filter: { name: string };
    setFilter: (filter: { name: string }) => void;
    fetchData: () => void;
}) => {
    return (
        <div style={{ marginTop: '1em' }}>
            <form onSubmit={props.fetchData}>
                <TextField
                    fullWidth
                    InputProps={{
                        inputProps: {
                            'aria-label': 'Name',
                        },
                    }}
                    style={{ maxWidth: 350, marginLeft: '1rem' }}
                    label="Name"
                    onChange={(e) => props.setFilter({ name: e.target.value })}
                    value={props.filter.name}
                />
                <div
                    style={{
                        display: 'flex',
                        gap: '.5rem',
                        margin: '1rem',
                    }}
                >
                    <Button variant="contained" color="primary" type="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            props.setFilter({ name: '' });
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Clear
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default NameFilter;
