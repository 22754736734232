import { createCustomAction } from 'typesafe-actions';
import { GET_TASK_FORM, GET_TASK_FORM_FAILURE, GET_TASK_FORM_SUCCESS, GET_TASK_FORM_LOADING } from './constants';
import { AjaxError } from 'rxjs/ajax';
import { TaskForm } from 'reducers/taskFormType';

export const getTaskForm = createCustomAction(GET_TASK_FORM, (type) => {
    return (taskId: string) => ({
        type,
        payload: {
            taskId,
        },
    });
});

export const getTaskFormLoading = createCustomAction(GET_TASK_FORM_LOADING, (type) => {
    return (taskId: string) => ({
        type,
        payload: {
            taskId,
        },
    });
});

export const getTaskFormSuccess = createCustomAction(GET_TASK_FORM_SUCCESS, (type) => {
    return (taskId: string, taskForm: TaskForm) => ({
        type,
        requestPayload: {
            taskId,
        },
        payload: {
            taskForm,
        },
    });
});

export const getTaskFormFailure = createCustomAction(GET_TASK_FORM_FAILURE, (type) => {
    return (taskId: string, error: AjaxError) => ({
        type,
        error,
        requestPayload: {
            taskId,
        },
    });
});
