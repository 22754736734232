import React, { useMemo, FunctionComponent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import SelectRecord from 'layout-editor/build-layout/steps/components/SelectRecord';
import Edit from 'components/generics/genericEdit/Edit2';
import { expressionTesterOpenContext } from 'expression-tester/hooks/useExpressionTesterOpen';
import { Button } from '@material-ui/core';
import useDisplayName from 'components/generics/title/hooks/useDisplayName';
import {
    viewConfigurationContext,
    ViewConfigurationCtxt,
} from 'layout-editor/build-layout/hooks/useViewConfigurationState';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import ReorderableActions from 'layout-editor/build-layout/steps/components/ReorderableActions';
import Actions from 'components/generics/viewActions/ActionsWithOverrides/Component';
import getActionButtonDisplays from 'viewConfigCalculations/actionButtonDisplayExpressions/getActionButtonDisplays';
import { fromNullable, fromEither } from 'fp-ts/lib/Option';
import { getViewConfiguration } from 'components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Either';
import Toolbar from 'components/generics/form/Toolbar.aor';
import Show from 'components/generics/genericShow/Show2';
import Create from 'components/generics/genericCreate/Create2';
import NavigateNext from '@material-ui/icons/NavigateNext';

interface Step3CreateEditShowProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step3CreateEditShow: FunctionComponent<Step3CreateEditShowProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
    });
    const { register, unregister, watch } = methods;
    const view = watch() as View;
    React.useEffect(() => {
        register({ name: 'config' });
        return () => {
            unregister('config');
        };
    }, []); // eslint-disable-line

    const onSubmit = (data) => {
        _onSubmit({ type: 'write', payload: data });
    };
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[initialValues.name] = {
                ...initialValues,
                ...view,
            };
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    const config = methods.watch('config');

    // pass 'User' just to stop it from failing. We won't show anything in that case.
    const displayName = useDisplayName({ resource: initialValues.entity || 'User', type: 'SINGULAR' });
    const displayNamePlural = useDisplayName({ resource: initialValues.entity || 'User', type: 'PLURAL' });
    const overriddenActionButtonExp = useMemo(() => {
        /*
        Use whatever code we are using to calculate these given the 'loaded' viewconfig.
        */
        return getActionButtonDisplays(
            initialValues.name,
            initialValues.entity,
            overrideViewConfig,
            fromEither(
                // this should take viewName
                tryCatch(() => getViewConfiguration(overrideViewConfig, initialValues.name)).mapLeft((e) =>
                    console.log(e),
                ),
            ).chain(fromNullable),
        ).fold(undefined, (entrypoint) => entrypoint[initialValues.name]);
    }, [overrideViewConfig, initialValues.name, initialValues.entity]);

    const setValue = methods.setValue;
    const viewConfigurationContextValue: ViewConfigurationCtxt = useMemo(
        () => ({
            setViewConfiguration: (config) =>
                setValue('config', JSON.stringify(config), {
                    shouldDirty: true,
                }),
            viewConfiguration: config ? JSON.parse(config) : {},
        }),
        [config, setValue],
    );

    if (!initialValues.viewType || !initialValues.entity || !initialValues.name) {
        return <div>Please fill out step 1.</div>;
    }
    return (
        <FormProvider {...methods}>
            <div style={{ padding: '1em', margin: '1em' }}>
                <h2>Step 3: Add Expressions and other Configurations</h2>
                <br />
                <NavWarning />
                <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                    <viewConfigurationContext.Provider value={viewConfigurationContextValue}>
                        {initialValues.viewType === 'CREATE' ? (
                            <expressionTesterOpenContext.Provider value="OPEN_EXPRESSIONPANEL">
                                <Create toolbar={<Toolbar disabled={true} />} viewName={initialValues.name} />
                            </expressionTesterOpenContext.Provider>
                        ) : (
                            <SelectRecord
                                sessionStorageKey={`${initialValues.entity}:${initialValues.name}:TestRecord`}
                                resource={initialValues.entity}
                                renderSelection={(maybeId) => {
                                    return maybeId.fold(
                                        <div style={{ marginTop: '1em' }}>
                                            <p>
                                                Select a {displayName} above, so that you may use that record's values
                                                to test configurations on the view. You can switch between{' '}
                                                {displayNamePlural} at any point.
                                            </p>
                                        </div>,
                                        (id) => {
                                            const renderActions = (type: 'SHOW' | 'EDIT') => (
                                                <div>
                                                    <Actions
                                                        disableDefaultActions
                                                        hasShow={type === 'EDIT'}
                                                        hasDelete={type === 'EDIT'}
                                                        save={
                                                            type === 'EDIT'
                                                                ? () => alert('Save intercepted.')
                                                                : undefined
                                                        }
                                                        overrideActionButtonExp={overriddenActionButtonExp as any}
                                                        filter={(_item, _i) => false}
                                                        viewName={initialValues.name}
                                                        id={id}
                                                    />
                                                    <ReorderableActions
                                                        renderItem={(item) => {
                                                            return (
                                                                <Actions
                                                                    overrideActionButtonExp={
                                                                        overriddenActionButtonExp as any
                                                                    }
                                                                    filter={(_item, _i) => _item.key === item.key}
                                                                    viewName={initialValues.name}
                                                                    id={id}
                                                                    hasEdit={false}
                                                                    hasShow={false}
                                                                    hasDelete={false}
                                                                />
                                                            );
                                                        }}
                                                        changeEntityActionsConfig={(entityActionsConfig) => {
                                                            methods.setValue(
                                                                'config',
                                                                JSON.stringify({
                                                                    ...(config ? JSON.parse(config) : {}),
                                                                    entityActions: entityActionsConfig,
                                                                }),
                                                                {
                                                                    shouldDirty: true,
                                                                    shouldValidate: true,
                                                                },
                                                            );
                                                        }}
                                                        entityActionsConfig={
                                                            (config ? JSON.parse(config) : {}).entityActions
                                                        }
                                                    />
                                                </div>
                                            );
                                            return (
                                                <div style={{ marginTop: '1em' }}>
                                                    <p>
                                                        Use the 'Configurations' tab to edit the view's JSON
                                                        configuration. This is where you may add visibility expressions,
                                                        action buttons, and more.
                                                    </p>
                                                    <expressionTesterOpenContext.Provider value="OPEN_EXPRESSIONPANEL">
                                                        {initialValues.viewType === 'EDIT' ||
                                                        initialValues.viewType === 'COMPONENT' ? (
                                                            <Edit
                                                                disableFormSaveNotifierTrigger
                                                                toolbar={<Toolbar disabled={true} />}
                                                                actions={
                                                                    initialValues.viewType === 'COMPONENT' ? (
                                                                        <div />
                                                                    ) : (
                                                                        renderActions(initialValues.viewType)
                                                                    )
                                                                }
                                                                id={id}
                                                                resource={initialValues.entity}
                                                                viewName={initialValues.name}
                                                            />
                                                        ) : initialValues.viewType === 'SHOW' ? (
                                                            <Show
                                                                actions={renderActions(initialValues.viewType)}
                                                                id={id}
                                                                resource={initialValues.entity}
                                                                viewName={initialValues.name}
                                                            />
                                                        ) : null}
                                                    </expressionTesterOpenContext.Provider>
                                                </div>
                                            );
                                        },
                                    );
                                }}
                            />
                        )}
                    </viewConfigurationContext.Provider>
                </OverriddenViewConfigContext.Provider>
                <div style={{ marginTop: '1em', paddingTop: '1em', textAlign: 'right' }}>
                    <Button
                        id="step3-submit"
                        variant="contained"
                        color="primary"
                        onClick={methods.handleSubmit(onSubmit)}
                    >
                        Submit&nbsp;
                        <NavigateNext />
                    </Button>
                </div>
            </div>
        </FormProvider>
    );
};

export default Step3CreateEditShow;
