import { TaskForm } from 'reducers/taskFormType';
import ViewConfig from 'reducers/ViewConfigType';
import { crudGetList } from 'sideEffect/crud/getList/actions';
import { getConfiguredExpansions, getNeededInFilter } from './util';

const getRefManysForFormActions = <TF extends { fields: TaskForm['fields'] }>(
    taskForm: TF,
    viewConfig: ViewConfig,
    tableValue?: {}[],
): ReturnType<typeof crudGetList>[] => {
    const refOnes = Object.values(taskForm.fields).flatMap((f) => {
        if (f.type === 'table') {
            return getRefManysForFormActions(
                { fields: f.params.columnObj },
                viewConfig,
                typeof f.value === 'string' && f.value
                    ? JSON.parse(f.value)
                    : Array.isArray(f.value)
                    ? f.value
                    : undefined,
            );
        }
        if (f.type === 'entity-multi-select-chip' || f.type === 'multiple-entity-typeahead' || f.type === 'list-view') {
            const resource = f.type === 'list-view' ? viewConfig.views[f.params.viewName]?.entity : f.params.entity;
            if (!resource) {
                return [];
            }
            const neededInFilter = getNeededInFilter(f);
            const configuredExpansions = getConfiguredExpansions(f);
            const getListAction = (ids: string[]) =>
                crudGetList({
                    appendExpansions: [...neededInFilter, ...configuredExpansions],
                    view: null,
                    resource,
                    pagination: {
                        page: 1,
                        perPage: 500,
                    },
                    sort: {
                        field: 'id',
                        order: 'ASC',
                    },
                    filter: {
                        id__IN: ids,
                    },
                });
            if (f.value) {
                if (Array.isArray(f.value)) {
                    return [getListAction(f.value)];
                }
                if (typeof f.value === 'string') {
                    try {
                        const res = JSON.parse(f.value);
                        if (Array.isArray(res)) {
                            return [getListAction(res)];
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
                return [];
            } else if (tableValue) {
                return tableValue.flatMap((row) => {
                    const value = row[f.id];
                    if (value && Array.isArray(value)) {
                        return [getListAction(value)];
                    }
                    return [];
                });
            }
        }
        return [];
    });
    return refOnes;
};
export default getRefManysForFormActions;
