export interface ViewValidations {
    [viewName: string]: {
        expression: string;
        message: string;
        dataPaths: string[];
        expansionsRequired: string[];
        valuesetLiterals: string[];
        valuesetFieldsRequired: {
            [field: string]: string; // can get the valueset
        };
        __originalView?: string;
    }[];
}
const viewValidationsReducer = (previousState: ViewValidations = {}, { type, payload }): ViewValidations => {
    if (type === 'VIEW_VALIDATIONS_GENERATED') {
        return payload;
    }
    return previousState;
};
export default viewValidationsReducer;
