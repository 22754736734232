import { services } from 'sideEffect/services';
import { map } from 'rxjs/operators';
import makeAdhocList from 'mui-rest-list/lib/src/components/makeAdhocList';

const makeCasetivityAdhocList = <DataRow extends {}>() => {
    const { AdhocList, AdhocListColumn } = makeAdhocList<DataRow>();
    const getNoPageObservable = (url: string) => services.getJSONObservable<DataRow[]>(url);
    const getPagedObservable = (url: string) => {
        const $ajax = services.crudGet({ restUrl: url }).pipe(
            map((r) => {
                const { response, xhr } = r;
                const xTotalCount = xhr.getResponseHeader('x-total-count');
                if (isNaN(Number(xTotalCount))) {
                    throw new Error(`The X-Total-Count header is missing in the HTTP Response.
     If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?`);
                }
                return {
                    data: response as DataRow[],
                    total: parseInt(xTotalCount, 10),
                };
            }),
        );
        return $ajax;
    };
    return { AdhocList, AdhocListColumn, getNoPageObservable, getPagedObservable };
};
export default makeCasetivityAdhocList;
