import * as React from 'react';
import { Component } from 'react';
import { createSelector } from 'reselect';
import { RootState } from '../../reducers/rootReducer';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from '../../fieldFactory/popovers/PopoverCloseButton';
import {
    Card,
    CardHeader,
    Dialog,
    DialogContent,
    TextField,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core/';
import { connect } from 'react-redux';
import GenericEdit from '../../components/generics/genericEdit/index2';
import buildHeaders from 'sideEffect/buildHeaders';

interface EntityPerm {
    entityName: {
        entityPermissions: {
            min: number;
            max: number;
            roles: RoleObject[];
        };
        fieldPermissions: {
            min: number;
            max: number;
            roles: RoleObject[];
        };
    };
}

interface RoleObject {
    nameOfRole: number;
}

interface CustomRoleShowProps {
    listOfEntityNames: string[];
}
interface CustomRoleShowState {
    currentlySelectedEntity: string | null;
    data: {};
    allRoles: string[];
    currentMin: number | null;
    currentMax: number | null;
    open: boolean;
    drillDownId: number | null;
    basePath: string | null;
    resource: string | null;
}

const getAllRoleNames = (rolesArray: {}[]): string[] => rolesArray.map((roleObj) => Object.keys(roleObj)[0]);
const rootStyle = {
    width: '100%',
};

const tableStyle = {
    width: 'auto',
    display: 'inline-block',
    maxWidth: '99%',
    overflow: 'auto',
};

const overFlowStyle: {} = {
    overflowX: 'auto',
};

const config = require('../../config.js');

class CustomRoleShow extends Component<CustomRoleShowProps, CustomRoleShowState> {
    constructor(props: CustomRoleShowProps) {
        super(props);
        this.state = {
            currentlySelectedEntity: null,
            data: {},
            allRoles: [],
            currentMin: null,
            currentMax: null,
            open: false,
            drillDownId: null,
            basePath: null,
            resource: null,
        };
    }
    componentWillMount() {
        this.handleFetchData();
    }
    handleFetchData = () => {
        fetch(`${config.BACKEND_BASE_URL}api/security/setup`, {
            method: 'GET',
            credentials: 'same-origin',
            headers: buildHeaders({ includeCredentials: true }),
        }).then((response) => {
            if (response && response.status !== 404 && response.status !== 500) {
                response.json().then((data: EntityPerm[]) => {
                    if (data && data[0]) {
                        const allRoles = getAllRoleNames((Object.values(data[0])[0] as any).entityPermissions.roles);
                        allRoles.unshift('entity/field', 'min', 'max');

                        const objectData = Object.assign({}, ...data);
                        const currentlySelectedEntity = Object.keys(objectData)[0];

                        this.setState({ data: objectData, allRoles, currentlySelectedEntity });
                    }
                });
            }
        });
    };
    handleEntityChange = (key) => {
        this.setState({ currentlySelectedEntity: key });
    };
    getEntityPermissionMin = (entityName) => {
        return this.state.data[entityName].entityPermissions.min || null;
    };
    getEntityPermissionMax = (entityName) => {
        return this.state.data[entityName].entityPermissions.max || null;
    };
    getFieldPermissionMin = (entityName, indexOfField) => {
        const fieldLevelObj = Object.values(this.state.data[entityName].fieldPermissions[indexOfField])[0] as any;

        return fieldLevelObj.min;
    };
    getFieldPermissionMax = (entityName, indexOfField) => {
        const fieldLevelObj = Object.values(this.state.data[entityName].fieldPermissions[indexOfField])[0] as any;

        return fieldLevelObj.max;
    };
    adjustForMinOrMaxEntity = (entityName, valueToAdjust) => {
        const entityMin = this.getEntityPermissionMin(entityName);
        const entityMax = this.getEntityPermissionMax(entityName);

        return this.adjustForMinOrMax(entityMin, entityMax, valueToAdjust);
    };
    adjustForMinOrMaxField = (entityName, valueToAdjust, indexOfField) => {
        const fieldMin = this.getFieldPermissionMin(entityName, indexOfField);
        const fieldMax = this.getFieldPermissionMax(entityName, indexOfField);

        return this.adjustForMinOrMax(fieldMin, fieldMax, valueToAdjust);
    };
    adjustForMinOrMax = (min, max, valueToAdjust) => {
        if (min && min > valueToAdjust) {
            return min;
        }
        if (max && max < valueToAdjust) {
            return max;
        }
        if (!valueToAdjust) {
            return 'unset';
        }
        return valueToAdjust;
    };

    generateEntityRows = (data, currentlySelectedEntity) => {
        return (
            <TableRow key={currentlySelectedEntity} onClick={() => this.onRowClick(0)}>
                <TableCell style={{ fontWeight: 'bold' }} key={currentlySelectedEntity}>
                    {'Entity: '.concat(currentlySelectedEntity)}
                </TableCell>

                {Object.keys(data[currentlySelectedEntity].entityPermissions).map((perm) => {
                    if (Array.isArray(data[currentlySelectedEntity].entityPermissions[perm])) {
                        // Handle role array
                        return data[currentlySelectedEntity].entityPermissions[perm].map((rolePairs) =>
                            Object.values(rolePairs).map((valueOfRole) => (
                                <TableCell
                                    key={perm}
                                    style={
                                        this.adjustForMinOrMaxEntity(currentlySelectedEntity, valueOfRole) !==
                                        (valueOfRole || 'unset')
                                            ? { color: 'red' }
                                            : undefined
                                    }
                                >
                                    {this.adjustForMinOrMaxEntity(currentlySelectedEntity, valueOfRole)}
                                </TableCell>
                            )),
                        );
                    } else {
                        return (
                            <TableCell key={perm}>
                                {
                                    data[currentlySelectedEntity].entityPermissions[perm] || 'unset' // Handle min max
                                }
                            </TableCell>
                        );
                    }
                })}
            </TableRow>
        );
    };
    isId = (perm) => {
        return perm === 'id';
    };
    generateFieldRows = (data, currentlySelectedEntity) => {
        return data[currentlySelectedEntity].fieldPermissions.map((fieldValuePair, index) =>
            Object.keys(fieldValuePair).map((fieldPermKey, i) => (
                <TableRow key={currentlySelectedEntity} onClick={() => this.onRowClick(i + 1)}>
                    <TableCell key={currentlySelectedEntity}>{'Field: '.concat(fieldPermKey)}</TableCell>

                    {Object.values(data[currentlySelectedEntity].fieldPermissions[index]).map((fieldEntry) => {
                        return Object.keys(fieldEntry).map((perm) => {
                            if (Array.isArray(fieldEntry[perm])) {
                                // Handle roles array
                                return fieldEntry[perm].map((rolePair) =>
                                    Object.values(rolePair).map((valueOfRole) => (
                                        <TableCell
                                            key={perm}
                                            style={
                                                this.adjustForMinOrMaxField(
                                                    currentlySelectedEntity,
                                                    valueOfRole,
                                                    index,
                                                ) !== (valueOfRole || 'unset')
                                                    ? { color: 'red' }
                                                    : undefined
                                            }
                                        >
                                            {this.adjustForMinOrMaxField(currentlySelectedEntity, valueOfRole, index)}
                                        </TableCell>
                                    )),
                                );
                            } else {
                                // Handle min max
                                const returnBasedOnId =
                                    perm !== 'id' ? (
                                        <TableCell key={perm}>{fieldEntry[perm] || 'unset'}</TableCell>
                                    ) : null;
                                return returnBasedOnId;
                            }
                        });
                    })}
                </TableRow>
            )),
        );
    };
    generateTableBodyArray = (
        data,
        currentlySelectedEntity, // To be used to create multiple bodies for table
    ) => [
        this.generateEntityRows(data, currentlySelectedEntity),
        this.generateFieldRows(data, currentlySelectedEntity),
    ];

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    closeAndReload = () => {
        this.handleFetchData();
        this.handleClose();
    };
    isFieldOrEntity = (rowId) => (rowId === 0 ? 'Permission' : 'FieldPerm');
    getBasePath = (rowId) => (rowId === 0 ? '/Permission' : '/FieldPerm');
    getIdForDrillDown = (row) => {
        if (row === 0 && this.state.data && this.state.currentlySelectedEntity) {
            return this.state.data[this.state.currentlySelectedEntity as string].id;
        }
        if (this.state.data && this.state.currentlySelectedEntity) {
            const adjustedIndex = row - 1;
            const fieldPermLevelObj =
                this.state.data[this.state.currentlySelectedEntity as string].fieldPermissions[adjustedIndex];

            const id = Object.values(fieldPermLevelObj).map((fieldObjValue: { id: number }) => fieldObjValue.id);

            return id[0];
        }
        return 0;
    };
    onRowClick = (row: number) => {
        const drillDownId = this.getIdForDrillDown(row);
        const resource = this.isFieldOrEntity(row);
        const basePath = this.getBasePath(row);

        this.setState({ drillDownId, resource, basePath });
        this.handleOpen();
    };
    render() {
        const { listOfEntityNames } = this.props;
        const data = this.state.data;

        if (Object.keys(data).length < 1 || !this.state.currentlySelectedEntity) {
            return <span>Loading...</span>;
        }

        return (
            <div style={rootStyle}>
                <Card>
                    <CardHeader title={'Security Configuration'} />
                    <TextField
                        id="select-entity"
                        select={true}
                        label="Select Entity"
                        value={this.state.currentlySelectedEntity}
                        style={{ marginLeft: '30px' }}
                        onChange={(e) => {
                            this.handleEntityChange(e.target.value);
                        }}
                    >
                        {listOfEntityNames.map((key, i) => {
                            return (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Card>
                <br />
                <Card style={overFlowStyle}>
                    <Table style={tableStyle} key={this.state.currentlySelectedEntity}>
                        <TableHead>
                            <TableRow>
                                {this.state.allRoles.map((role) => {
                                    return <TableCell key={role}>{role}</TableCell>;
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[this.state.currentlySelectedEntity].map((currentlySelectedEntity) => {
                                const rows = this.generateTableBodyArray(data, currentlySelectedEntity);
                                return rows.map((row) => row);
                            })}
                        </TableBody>
                    </Table>
                </Card>

                {this.state.drillDownId && this.state.resource && this.state.basePath ? (
                    <Dialog
                        TransitionProps={
                            {
                                // https://github.com/dequelabs/axe-core/issues/146
                                role: 'presentation',
                            } as any
                        }
                        maxWidth={false}
                        fullWidth={true}
                        open={this.state.open}
                        onClose={this.handleClose}
                    >
                        <DialogContent style={{ padding: 0 }}>
                            <GenericEdit
                                viewName={`${this.state.resource}Edit`}
                                formId={`popover-edit-form-${this.state.resource}-${this.state.drillDownId}`}
                                id={`${this.state.drillDownId}`}
                                resource={this.state.resource as string}
                                onSaveCb={this.closeAndReload}
                                toolbar={
                                    <Toolbar>
                                        <CloseButton handleClose={this.handleClose} />
                                        <SaveButton />
                                    </Toolbar>
                                }
                            />
                        </DialogContent>
                    </Dialog>
                ) : null}
            </div>
        );
    }
}

const makeMapStateToProps = () => {
    const entityNamesSelector = createSelector(
        (state: RootState, props) => state.viewConfig.entities,
        (listOfEntityNames) => (listOfEntityNames ? Object.keys(listOfEntityNames).sort() : []),
    );
    return (state: RootState, ownProps) => ({
        viewConfig: state.viewConfig,
        listOfEntityNames: entityNamesSelector(state, ownProps),
    });
};

const enhancedRoleShow = connect(makeMapStateToProps, {})(CustomRoleShow);

export default enhancedRoleShow;
