import React, { useEffect } from 'react';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import useRemoteData from 'util/hooks/useRemoteData';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import CheckIcon from '@material-ui/icons/Check';
import WarnIcon from '@material-ui/icons/Warning';

const EvaluateAsScriptButton: React.FC<{
    processId: string;
    expression: string;
    onEval: (result: unknown) => void;
    evalOnMount?: boolean;
}> = ({ processId, expression, onEval, evalOnMount = true }) => {
    const { state, fold, setSuccess, setError, setPending } = useRemoteData<any, string>();
    useEffect(() => {
        if (state.status === 'pending') {
            const $ajax = services.runScript(processId, expression);
            const subscription = $ajax.subscribe(
                (res) => {
                    setSuccess(res.response);
                    onEval(res.response?.result);
                },
                (error: AjaxError) => {
                    setError(error.response?.description ?? error.response?.message);
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state.status, expression, processId, setSuccess, setError, onEval]);

    useEffect(() => {
        if (evalOnMount) {
            setPending();
        }
    }, [evalOnMount, setPending, processId]);

    const disabled = fold(
        () => false,
        () => true,
        () => false,
        () => false,
    );
    const icon = fold(
        () => null,
        () => (
            <>
                &nbsp;
                <CircularProgress size={25} thickness={2} />
            </>
        ),
        () => (
            <>
                &nbsp;
                <CheckIcon color="primary" />
            </>
        ),
        (err) => (
            <>
                &nbsp;
                <Tooltip title={err}>
                    <WarnIcon color="error" />
                </Tooltip>
            </>
        ),
    );
    return (
        <Button disabled={disabled} onClick={setPending}>
            Evaluate{icon}
        </Button>
    );
};

export default EvaluateAsScriptButton;
