import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import Popup from 'components/Popup';
import EditExpression from './EditSingleExpression';

interface EditHasXButtonConfigsProps {
    viewName: string;
    viewDefConfig: EntityViewConfig;
    setViewDefConfig: (conf: EntityViewConfig) => void;
}
const EditHasXButtonConfigs: React.FunctionComponent<EditHasXButtonConfigsProps> = (props) => {
    const renderRow = (configKey: keyof EntityViewConfig, buttonLabel: string) => {
        return (
            <TableRow>
                <TableCell>Hide {buttonLabel} Button</TableCell>
                <TableCell>{props.viewDefConfig[configKey] || <i>none</i>}</TableCell>
                <TableCell>
                    <Popup
                        renderDialogContent={({ closeDialog }) => {
                            return (
                                <div style={{ margin: '1em', padding: '1em' }}>
                                    <EditExpression
                                        viewName={props.viewName}
                                        initialValues={
                                            props.viewDefConfig[configKey] && {
                                                expression: `${props.viewDefConfig[configKey]}`,
                                            }
                                        }
                                        onSubmit={(data) => {
                                            props.setViewDefConfig({
                                                ...props.viewDefConfig,
                                                [configKey]: data.expression || undefined,
                                            });
                                            closeDialog();
                                        }}
                                    />
                                </div>
                            );
                        }}
                        renderToggler={({ openDialog }) => (
                            <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                                Edit
                            </Button>
                        )}
                    />
                </TableCell>
            </TableRow>
        );
    };
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Purpose</TableCell>
                    <TableCell>Expression</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {renderRow('hideCreateButton', 'Create')}
                {renderRow('hideEditButton', 'Edit')}
                {renderRow('hideDeleteButton', 'Delete')}
                {renderRow('hideBackTo', '"Back to..."')}
                {renderRow('hidePopupNavigationArrow', 'Popup Navigation Arrow')}
            </TableBody>
        </Table>
    );
};

export default EditHasXButtonConfigs;
