import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';

/*
    When we need expansions:
    1. roles.name <- YES
    2. roles <- NO (IN/EQUALS/CONTAINS, etc. needs to map onto primatives)
    3. rolesIds <- yes

    So basically:
    1. ends with 'Ids'
    2. contains a '.'
*/
const getExpansionsFromFilter = (filterString: string): string[] => {
    const filter = getFilterFromFilterString(filterString);
    return Object.keys(filter).flatMap((f) => {
        const [fieldPath] = f.split('__');
        if (fieldPath.endsWith('Ids') || fieldPath.includes('.')) {
            return [fieldPath];
        }
        return [];
    });
};
export default getExpansionsFromFilter;
