import { createCustomAction } from 'typesafe-actions';
import {
    GET_PRINT_TEMPLATE_BY_NAME,
    GET_PRINT_TEMPLATE_BY_NAME_SUCCESS,
    GET_PRINT_TEMPLATE_BY_NAME_FAILURE,
} from './constants';
import { PrintTemplate } from '../definitions';

export const getPrintTemplateByName = createCustomAction(GET_PRINT_TEMPLATE_BY_NAME, (type) => {
    return (name: string) => ({
        type,
        payload: {
            name,
        },
    });
});

export const getPrintTemplateByNameSuccess = createCustomAction(GET_PRINT_TEMPLATE_BY_NAME_SUCCESS, (type) => {
    return (data: PrintTemplate[], requestPayload: ReturnType<typeof getPrintTemplateByName>['payload']) => ({
        type,
        payload: data,
        requestPayload,
    });
});

export const getPrintTemplateByNameFailure = createCustomAction(GET_PRINT_TEMPLATE_BY_NAME_FAILURE, (type) => {
    return (error: Error, requestPayload: ReturnType<typeof getPrintTemplateByName>['payload']) => ({
        type,
        error,
        requestPayload,
    });
});
