import { Store } from 'redux';
import deleteExpiredTasks from './deleteExpiredTasks';
import throttle from 'lodash/throttle';
import { getExpiredOfflineDataSubscribedComponentsRegistry } from 'offline_app/offline_stateful_tasks/ExpiredOfflineDataSubscribedComponentsRegistry';
import { loadOfflineTasksFromIDB } from 'offline_app/offline_stateful_tasks/components/LoadOfflineTasksFromIDB';
import { setOfflineTasks } from 'offline_app/offline_stateful_tasks/offlineTasks/actions';

/*
    Expired Tasks deletion and polling
*/
export const startExpirationHandlers = (store: Store) => {
    const SECONDS = 1000;
    const MINUTES = 60 * SECONDS;

    /*
        When tasks are deleted, we need to update React components which registered their interest in updated when one is deleted.
    */
    const deleteExpiredTasksAndUpdateRegisteredComponents = async () => {
        let someDeleted = await deleteExpiredTasks();
        if (someDeleted) {
            const offlineTasks = await loadOfflineTasksFromIDB();
            store.dispatch(setOfflineTasks(offlineTasks));
            getExpiredOfflineDataSubscribedComponentsRegistry().dispatchSomeOfflineTasksExpired();
        }
    };
    // only run once per minute.
    const throttledDeleteExpiredOfflineData = throttle(deleteExpiredTasksAndUpdateRegisteredComponents, 1 * MINUTES);
    // run check and delete on page load.
    throttledDeleteExpiredOfflineData();
    // run check and delete every 5 minutes
    setInterval(throttledDeleteExpiredOfflineData, 5 * MINUTES);
    // run check and delete immediately when returning to page
    function handleVisibilityChange() {
        let isVisible = !document.hidden;
        if (isVisible) {
            throttledDeleteExpiredOfflineData();
        }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
};
