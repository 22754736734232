// string methods with matching signatures in both javascript and java

export const methodCallsAllowed = [
    'trim',
    'size',
    'toLowerCase',
    'toUpperCase',
    'substring',
    'startsWith',
    'split',
    'replaceAll',
    'replace',
    'matches',
    'lastIndexOf',
    'endsWith',
    'concat',
    'charAt',
];
