import { combineReducers } from 'redux';
import tasks, * as fromTasks from './tasks';
import processInstances, * as fromProcessInstances from './processInstances';
import lastSaveReducer from 'bpm/components/TaskDetail/TaskForm/TaskForm/AutoSave/lastSaveReducer';
import taskPermissions from 'bpm/taskPotentialUsers/reducer';
import processDefinitions from 'bpm/processDefinitions/reducer';
import potentialUsers from 'bpm/potentialUsers/reducer';
import startForms from 'bpm/start-form/get-start-form/reducer';
import { ProcessActivities } from './types';
import currentProcessSearch, { CurrentProcessSearch } from './currentProcessSearch';
import currentProcessTaskSearch, { CurrentProcessTaskSearch } from './currentProcessTaskSearch';
import taskDrawer from './taskDrawer';
import processActivities from './processActivities';

export interface BPMState {
    tasks: fromTasks.TasksState;
    processInstances: fromProcessInstances.ProcessInstances;
    processDefinitions: ReturnType<typeof processDefinitions>;
    taskDrawer: boolean;
    processActivities: ProcessActivities;
    currentProcessSearch: CurrentProcessSearch;
    currentProcessTaskSearch: CurrentProcessTaskSearch;
    potentialUsers: ReturnType<typeof potentialUsers>;
    taskPermissions: ReturnType<typeof taskPermissions>;
    startForms: ReturnType<typeof startForms>;
    lastFormSave: ReturnType<typeof lastSaveReducer>;
}
const bpm = combineReducers<BPMState>({
    taskPermissions,
    processDefinitions,
    processInstances,
    tasks,
    taskDrawer,
    processActivities,
    currentProcessSearch,
    currentProcessTaskSearch,
    potentialUsers,
    startForms,
    lastFormSave: lastSaveReducer,
});
export default bpm;
