import React from 'react';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';

export interface TableRowContext {
    // current row
    row: number;
    // current table
    tableId: string;
    // current table path (all parent tables, including current table)
    tableIdPath: string[];
    // row index path for all parent tables, including 'current row'
    tableRowIndexes: number[];
    columns: FormFieldUnion[];
    // e.g. tableId[2].tableId2[0]
    rowGetExp: string;
}
export const getTableRowContext = (c: TableRowContext, fc: ReturnType<typeof evaluateContext2>) => {
    const paths = c.tableIdPath.map((e, i) => {
        return [e, c.tableRowIndexes[i]] as const;
    });
    const currentTableRowContext = paths.reduce((prev, [tblId, tblRowIx]) => {
        return prev.tableRowContexts?.[tblId]?.[tblRowIx];
    }, fc);
    // sometimes returns undefined
    // https://strategicsolutionsgroup.atlassian.net/browse/FISH-2971
    return currentTableRowContext;
};
export const tableRowContext = React.createContext<TableRowContext | null>(null);
