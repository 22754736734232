import React, { useEffect, useRef, useState } from 'react';
import { IconButton, useTheme } from '@material-ui/core';
import Warning from '@material-ui/icons/ErrorOutline';
import scrollToFieldRegistry from './ScrollToFieldRegistry';
const ScrollToFieldFlag = ({ formId, source }: { formId: string; source: string }) => {
    const [show, setShow] = useState(false);
    const fieldId = formId + ':' + source;
    const buttonRef = useRef<HTMLButtonElement>();
    useEffect(() => {
        const cb = () => {
            setShow(true);
            setImmediate(() => {
                buttonRef.current?.scrollIntoView();
                buttonRef.current?.focus();
            });
        };
        scrollToFieldRegistry.subscribe(fieldId, cb);
        return () => {
            scrollToFieldRegistry.unsubscribe(fieldId, cb);
        };
    }, [fieldId]);
    // if we want to hide them on blur
    // const handleBlur = useCallback(() => setShow(false), []);
    const theme = useTheme();
    if (!show) {
        return null;
    }
    return (
        <span style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', left: '-1.2em', top: '-1.2em' }}>
                <IconButton
                    style={{ color: theme.palette.info.dark }}
                    size="small"
                    ref={buttonRef} /* onBlur={handleBlur}*/
                >
                    <Warning fontSize="medium" />
                </IconButton>
            </div>
        </span>
    );
};

export default ScrollToFieldFlag;
