import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import NestedMenuItem from './NestedMenuItem';
import ViewConfig from 'reducers/ViewConfigType';
import MenuRoot from './MenuRoot';
import { Button, ButtonProps } from '@material-ui/core';
import { RootAction } from 'actions/rootAction';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import getHandleMenuClick from './util/getHandleMenuClick';

const generateMenu = ({
    viewConfig,
    dispatch,
    ButtonProps,
    applyTranslations,
}: {
    viewConfig: ViewConfig;
    dispatch: (action: RootAction) => void;
    ButtonProps?: Partial<ButtonProps>;
    applyTranslations: (text: string) => string;
}) => {
    const generateMenuElements = (
        menus: ViewConfig['menus'],
        isRoot = true,
        mainMenuOpen = false,
        callBeforeNav?: () => void,
        onTab?: () => void,
    ) => {
        return menus?.map((m, i) => {
            const translatedLabel = applyTranslations(m.label);
            const onClick = getHandleMenuClick(viewConfig, dispatch)(m, callBeforeNav);
            if (!m.children) {
                const key = m.name + ':' + i;
                if (isRoot) {
                    return (
                        <Button id={m.name} onClick={onClick} key={key} color="inherit">
                            {translatedLabel}
                        </Button>
                    );
                }
                return (
                    <MenuItem key={key} onClick={onClick}>
                        {translatedLabel}
                    </MenuItem>
                );
            }
            if (!isRoot) {
                return (
                    <NestedMenuItem
                        onTab={onTab}
                        left="auto"
                        key={m.name + ':' + i}
                        label={translatedLabel}
                        mainMenuOpen={mainMenuOpen}
                        onClick={onClick}
                    >
                        {generateMenuElements(m.children, false, mainMenuOpen, callBeforeNav)}
                    </NestedMenuItem>
                );
            }
            return (
                <MenuRoot
                    key={m.name + ':' + i}
                    renderTrigger={({ getTriggerProps }) => (
                        <Button
                            {...getTriggerProps({
                                color: 'inherit',
                                id: m.name + '-dropdown',
                                ...ButtonProps,
                            })}
                        >
                            {translatedLabel} <ArrowDropDown color="inherit" />
                        </Button>
                    )}
                >
                    {({ handleMenuClose, rootMenuOpen, getMenuProps, Menu, focusTrigger }) => {
                        return (
                            <Menu {...getMenuProps({})}>
                                {generateMenuElements(m.children, false, rootMenuOpen, handleMenuClose, focusTrigger)}
                            </Menu>
                        );
                    }}
                </MenuRoot>
            );
        });
    };
    return generateMenuElements;
};
export default generateMenu;
