import * as list from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic, ofType } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap, take } from 'rxjs/operators';
import crudListFlow from 'sideEffect/crud/util/epics/CoreCrud/getList';
import { concat, of, race } from 'rxjs';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import buildUrl from './buildUrl';
import { patch } from 'jsondiffpatch';
import produce from 'immer';
import { viewConfigSelector } from 'util/hooks/useViewConfig';

const crudGetListFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    const s$ = action$.pipe(
        filter(isActionOf(list.crudGetList)),
        withLatestFrom(
            state$.pipe(map((state) => viewConfigSelector(state))),
            state$.pipe(map((state) => state.router.location)),
        ),
        flatMap(([action, _viewConfig, location]) => {
            const viewConfig =
                action.payload.override && action.payload.override.patchViewConfig
                    ? produce(_viewConfig, (draftVC) => patch(draftVC, action.payload.override.patchViewConfig))
                    : _viewConfig;
            const restUrl = buildUrl(action.payload, viewConfig);
            const { resource } = action.payload;
            const getAjaxStream = (startFetch: boolean) =>
                crudListFlow(
                    {
                        restUrl,
                    },
                    {
                        service: services.crudGet,
                        failureAction: list.crudGetListFailure,
                        successAction: list.crudGetListSuccess,
                        successCb: action.payload.cb,
                        errorsCbs: action.payload.errorsCbs || {},
                    },
                    {
                        resource,
                        viewConfig,
                        initialRequestPayload: action.payload,
                    },
                    startFetch,
                );
            if (action.cancelOnRouteChange) {
                const blocker$ = action$.pipe(
                    ofType(LOCATION_CHANGE),
                    filter(
                        (a: LocationChangeAction) =>
                            decodeURIComponent(a.payload.location.pathname) !== decodeURIComponent(location.pathname) ||
                            decodeURIComponent(a.payload.location.search) !== decodeURIComponent(location.search),
                    ),
                    take(1),
                    flatMap((a) => concat(of(list.crudGetListCancel(action.payload)), of(fetchEnd()))),
                );
                return concat(of(fetchStart()), race(getAjaxStream(false), blocker$));
            }
            return getAjaxStream(true);
        }),
    );
    return s$;
};
export default crudGetListFlow;
