import React from 'react';
// es path works in application build
import { withReduxForm as esWithReduxForm } from 'redux-form/es/ReduxFormContext';
// lib path works in tests
import { withReduxForm as libWithReduxForm } from 'redux-form/lib/ReduxFormContext';
import { InjectedFormProps /* registerField */ } from 'redux-form';

function areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
}

const withReduxForm = areWeTestingWithJest() ? libWithReduxForm : esWithReduxForm;

export interface InjectedProps {
    _reduxForm: InjectedFormProps & {
        registerField: (fieldName: string, type: 'Field' | 'FieldArray') => void;
        register: (fieldName: string, type: 'Field' | 'FieldArray', validations?: Function) => void;
        change: (fieldName: string, value: any) => void;
        form: string;
        touch: (fieldName: string) => void;
        initialValues: {};
    };
}
const withOurFormContext = <WrappedProps extends InjectedProps>(Component: React.ComponentType<WrappedProps>) => {
    return withReduxForm(Component);
};
export default withOurFormContext;
