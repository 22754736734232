import { TaskForm } from '../../../../reducers/taskFormType';

const adjustValues = (formDefinition: TaskForm, originalValues: {}) => {
    const values = Object.assign({}, originalValues); // copy values to prevent side effects

    Object.values(formDefinition.fields).forEach((field) => {
        if (field.type === 'expression') {
            values[field.id] = field.value;
        }
        if (field.type === 'upload') {
            if (values[field.id]) {
                if (Array.isArray(values[field.id])) {
                    if (values[field.id].length > 0) {
                        values[field.id] = values[field.id].map((f) => f.id).join();
                    } else {
                        values[field.id] = null;
                    }
                } else {
                    values[field.id] = values[field.id].id;
                }
            } else {
                values[field.id] = '';
            }
        } else if (typeof field.id === 'string' && field.id.endsWith('Ids') && !values[field.id]) {
            values[field.id] = []; // send an empty array if Ids was not sent
        } else if (typeof values[field.id] === 'undefined' || values[field.id] === null || values[field.id] === '') {
            values[field.id] = null; // send null for all other missing fields
        }
    });
    return values;
};

export default adjustValues;
