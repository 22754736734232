import React, { useRef, useEffect, useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import Countdown from 'react-countdown-now';
import { IdleTimerState } from 'idle-timer/hooks/useIdleTimerState';
import useIdleSync from 'idle-timer/hooks/useIdleSync';
import moment from 'moment';
import { Dialog, Card, CardContent, CardActions, Button, CardHeader } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';
import createRefreshTokenRequest from 'idle-timer/util/tokenReset';
import { disableFormsaveNotifierInc } from 'formSaveNotifier/actions';
import { useDispatch } from 'react-redux';

interface IdleTimerProps {
    timeToWarning: number;
    warningTime: number;
}

const CasetivityIdleTimer: React.SFC<IdleTimerProps> = (props) => {
    const reduxDispatch = useDispatch();
    const idleTimer = useRef<IdleTimer>();
    const [state, dispatch, syncController] = useIdleSync(idleTimer);
    const trigger: (action: IdleTimerState) => void = useCallback(
        (action) => {
            if (syncController.current) {
                (syncController.current.trigger as (action: IdleTimerState) => void)(action);
            }
        },
        [syncController],
    );
    let visibleRef = useRef(true);
    const resetActive = useCallback(() => {
        if (!visibleRef.current) {
            /* 
                In stage.mip, resetActive was being triggered before even the 'visibilitychange' event handler was being triggered.
                I think this may have been due to IframeResizer triggering events on visibility change before we handle it,
                although it's just a theory why it never came up before.

                If this gets called before the visibilitychange handler on window reopen, it messes up our auto-logout,
                since our 'last active time' is overwritten with the current time.

                So lets just check and not allow ACTIVE status to be set as long as the window is not visible.

                When we are syncing across tabs (not in sessionStorage mode, like in stage.mip) syncing is handled in useIdleSync,
                so this is fine.
            */
            return;
        }
        const action = { status: 'ACTIVE', activeDate: new Date() } as const;
        dispatch(action);
        trigger(action);
        idleTimer.current.reset();
    }, [dispatch, trigger, idleTimer]);
    const _logout = useLogout();
    const logout = useCallback(() => {
        reduxDispatch(disableFormsaveNotifierInc());
        // give a change for formsave notification to have an update pass.
        setImmediate(_logout);
    }, [_logout, reduxDispatch]);

    const onActive = useCallback(() => {
        if (state.status === 'IDLE') {
            const timeDifferenceFromWhenLastIdle = Math.abs(moment(state.date).diff(Date.now(), 'milliseconds'));
            if (timeDifferenceFromWhenLastIdle > props.warningTime) {
                logout();
            } else {
                resetActive();
            }
        } else {
            resetActive();
        }
    }, [state, logout, resetActive, props.warningTime]);

    const onIdle = (e) => {
        if (
            !visibleRef.current &&
            state.status === 'ACTIVE' &&
            Math.abs(moment(state.activeDate).diff(Date.now(), 'milliseconds')) >
                props.timeToWarning + props.warningTime
        ) {
            // if we haven't returned to visible mode, don't overwrite any ACTIVE states if we are past timeout limit.
            // we need to keep the state.activeDate preserved for when our visibilitychange handler triggers so it knows
            // to log out.
            return;
        }
        const action = { status: 'IDLE', date: new Date() } as const;
        dispatch(action);
        trigger(action);
    };

    useEffect(() => {
        function handleVisibilityChange() {
            visibleRef.current = !document.hidden;
            if (!document.hidden) {
                if (state.status === 'ACTIVE') {
                    const timeDiffFromLastActive = Math.abs(moment(state.activeDate).diff(Date.now(), 'milliseconds'));
                    if (timeDiffFromLastActive > props.warningTime + props.timeToWarning) {
                        // last global active state was too long ago.
                        logout();
                    } else {
                        resetActive();
                    }
                } else {
                    // state.status === 'IDLE'
                    // onActive handles check for whether to log out based on idle time.
                    onActive();
                }
            }
        }
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [state, onActive, resetActive, props.warningTime, props.timeToWarning, logout]);
    const manualContinue = () => {
        resetActive();
        fetch(createRefreshTokenRequest())
            .then((res) => {
                if (res.status === 401) {
                    alert('Authentication refresh failed. Logging out.');
                    logout();
                }
            })
            .catch((err) => {
                alert('Authentication refresh failed.');
            });
    };

    return (
        <>
            <IdleTimer
                ref={idleTimer}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={resetActive}
                throttle={250}
                timeout={props.timeToWarning}
                stopOnIdle={true}
                startOnMount={true}
            />
            {state.status === 'IDLE' && (
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={true}
                >
                    <Countdown
                        date={moment(state.date).add(props.warningTime, 'milliseconds').toDate()}
                        onComplete={logout}
                        renderer={({ total, completed }) => {
                            if (!completed) {
                                return (
                                    <Card>
                                        <CardHeader title="Timeout Warning" />
                                        <CardContent>
                                            Your session is about to expire in:{' '}
                                            {new Date(total).toISOString().substr(14, 5)}
                                        </CardContent>
                                        <CardActions>
                                            <Button color="primary" variant="contained" onClick={logout}>
                                                Log out
                                            </Button>
                                            <Button color="primary" variant="contained" onClick={manualContinue}>
                                                Continue
                                            </Button>
                                        </CardActions>
                                    </Card>
                                );
                            }
                            return null;
                        }}
                    />
                </Dialog>
            )}
        </>
    );
};
export default CasetivityIdleTimer;
