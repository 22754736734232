import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getValueSetCodesRequiredForEntity, getPossibleMatchName } from '../utils/viewConfigUtils';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import PossibleMatchView from '../genericPossibleMatchView/PossibleMatchList';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import useViewConfig from 'util/hooks/useViewConfig';
import ViewConfig from 'reducers/ViewConfigType';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

interface GenericPossibleMatchesProps {
    formId?: string;
    viewName: string;
    title?: null | string;
    match: { params: { id: string } };
    editInPopover?: boolean;
    onDataChange?: () => void;
    onIdChanged?: (id: string) => void;
}
const dispatchToProps = {
    loadValueSets: loadValueSetsAction,
    setAsTopView,
    unsetAsTopView,
};

class GenericPossibleMatchesComponent extends Component<
    GenericPossibleMatchesProps & { viewConfig: ViewConfig; resource: string } & typeof dispatchToProps
> {
    componentDidMount() {
        if (this.props.formId) {
            this.props.setAsTopView(this.props.formId);
        }
        this.props.loadValueSets(
            getValueSetCodesRequiredForEntity(this.props.viewConfig, this.props.viewName).map((valueSetCode) => ({
                valueSet: valueSetCode,
            })),
        );
    }

    componentWillUnmount() {
        if (this.props.formId) {
            this.props.unsetAsTopView(this.props.formId);
        }
    }
    render() {
        const { resource, viewConfig, title } = this.props;
        const displayName = typeof title === 'undefined' ? getPossibleMatchName(viewConfig, resource) : title;
        return <PossibleMatchView {...this.props} title={<EvaluatedFormattedMessage message={displayName} />} />;
    }
}
const enhance = compose(
    (BaseComponent) => (props) => {
        const viewConfig = useViewConfig();
        const resource = viewConfig.views[props.viewName].entity;
        return <BaseComponent {...props} viewConfig={viewConfig} resource={resource} />;
    },
    connect(null, dispatchToProps),
);

const GenericPossibleMatches: React.SFC<GenericPossibleMatchesProps> = enhance(GenericPossibleMatchesComponent);
GenericPossibleMatches.displayName = 'GenericPossibleMatches';
export default GenericPossibleMatches;
