import { useReducer, useCallback } from 'react';

type SubmissionStage =
    | 'TASK_SAVE_WARNING'
    | 'WORKING_ENTITY'
    | 'WORKING_TASKFORM'
    | 'WORKING_TASKFORM_NO_ENTITY'
    | 'TASKFORM_FAILED_NO_ENTITY'
    | 'ENTITY_FAILED'
    | 'TASKFORM_FAILED';

export type WorkingState = null | {
    stage: SubmissionStage;
    submissionType: 'save' | 'submit';
    taskFormResubmit?: {
        warningMessage: string;
        resubmit: () => void;
        onCancel: () => void;
    };
};

type WorkingActions =
    | {
          type: 'CLOSE';
      }
    | {
          type: 'UPDATE';
          payload: WorkingState;
      };
const workingReducer = (state: WorkingState, action: WorkingActions) => {
    switch (action.type) {
        case 'CLOSE': {
            return null;
        }
        case 'UPDATE': {
            return action.payload;
        }
    }
};
const useWorkingState = () => {
    const [workingState, dispatchWorkingState] = useReducer(workingReducer, null);
    const clearWorkingState = useCallback(() => {
        dispatchWorkingState({
            type: 'CLOSE',
        });
    }, [dispatchWorkingState]);
    const setWorkingState = useCallback(
        (state: WorkingState) => {
            dispatchWorkingState({
                type: 'UPDATE',
                payload: state,
            });
        },
        [dispatchWorkingState],
    );
    return { clearWorkingState, setWorkingState, workingState };
};
export default useWorkingState;
