import React from 'react';

import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';

import { SnackbarProvider } from 'notistack';

const snackbarProviderStyles = (theme: Theme) =>
    createStyles({
        success: { backgroundColor: theme.palette.primary.main },
    });
const SnackbarProviderComponent: React.SFC<WithStyles<typeof snackbarProviderStyles>> = (props) => (
    <SnackbarProvider
        classes={{
            variantSuccess: props.classes.success,
        }}
        preventDuplicate={true}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
    >
        {props.children as any}
    </SnackbarProvider>
);
const StyledSnackbarProvider = withStyles(snackbarProviderStyles)(SnackbarProviderComponent);
export default StyledSnackbarProvider;
