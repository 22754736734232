import React from 'react';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

interface HtmlWidgetProps {
    html: string;
}

const HtmlWidget = (props: HtmlWidgetProps) => {
    return <SafeHtmlAsReact html={props.html} />;
};

export default HtmlWidget;
