const convertTableErrorMessages = (errors: { [field: string]: string[] }): { [field: string]: string[] } => {
    return Object.keys(errors).reduce((res, key) => {
        const [baseId, ..._remainder] = key.split('_c_');
        const remainder = _remainder.join(',');

        return {
            ...res,
            [baseId]: [
                ...(res[baseId] || []),
                ...(remainder ? errors[key].map((msg) => `${remainder}:${msg}`) : errors[key]),
            ],
        };
    }, {});
};

export default convertTableErrorMessages;
