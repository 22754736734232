import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./'));
const LazyDiffPage = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component />
        </Suspense>
    );
};
export default LazyDiffPage;
