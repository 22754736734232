import ViewConfig from '../../../reducers/ViewConfigType';
import { isFieldViewField, isBooleanField, getAllFieldsFromView } from './viewConfigUtils';
import set from 'lodash/set';

const getBooleanFieldsPreloadedFalse = (
    viewConfig: ViewConfig,
    viewName: string,
): {
    [booleanField: string]: false;
} => {
    if (!viewConfig.views[viewName]) {
        console.error(`invalid viewName "${viewName}"`);
        return {};
    }
    return Object.assign(
        {},
        ...getAllFieldsFromView(viewConfig, viewName).flatMap((f) => {
            if (
                isFieldViewField(f) &&
                isBooleanField(viewConfig, f.entity, f.field, 'POP_LAST') &&
                f.widgetType !== 'NULLABLE_BOOLEAN'
            ) {
                const entry = {};
                set(entry, f.field, false);
                return [entry];
                // return [{ [f.field]: false }];
            }
            return [];
        }),
    );
};
export default getBooleanFieldsPreloadedFalse;
