import { pending, success, failure } from '@devexperts/remote-data-ts';
import { TaskForm } from 'reducers/taskFormType';

import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import * as actions from './actions';
import { AjaxError } from 'rxjs/ajax';
import { serialrd, serialize } from 'reducers/lists/list/serializeDeserialize';
import { getStartFormIndex } from './getStartFormIndex';

type StartForm = {
    [processDefinitionId: string]: serialrd<TaskForm, AjaxError>;
};
const startFormReducer = (state: StartForm = {}, action: RootAction): StartForm => {
    switch (action.type) {
        case getType(actions.getStartForm): {
            const index = getStartFormIndex(action.payload.processDefinitionId, action.payload.taskFormKey);
            if (state[index]?._tag === 'RemoteSuccess') {
                return state;
            }
            return {
                ...state,
                [index]: serialize(pending),
            };
        }
        case getType(actions.getStartFormSuccess): {
            const index = getStartFormIndex(
                action.requestPayload.processDefinitionId,
                action.requestPayload.taskFormKey,
            );
            return {
                ...state,
                [index]: serialize(success(action.payload.form)),
            };
        }
        case getType(actions.getStartFormFailure): {
            const index = getStartFormIndex(
                action.requestPayload.processDefinitionId,
                action.requestPayload.taskFormKey,
            );
            if (state[index]?._tag === 'RemoteSuccess') {
                return state;
            }
            return {
                ...state,
                [index]: serialize(failure(JSON.parse(JSON.stringify(action.error)))),
            };
        }
        default:
            return state;
    }
};

export default startFormReducer;
