import { validate, JSONSchema4 } from 'json-schema';

const filterPropertiesToSchemaDefined =
    <T extends {}>(schema: JSONSchema4) =>
    (object: T): T => {
        if (!object) {
            return object;
        }
        return Object.fromEntries(
            Object.entries(object).filter(([k, v]) => {
                const isBaseProperty = schema.properties[k] && validate(v, schema.properties[k])?.valid;
                // dependent properties unfortunately don't show up in 'schema.properties' so we have to search for them.
                const isDependentProperty = Object.entries(schema.dependencies).some(([depKey, dep]) => {
                    if (!Array.isArray(dep)) {
                        if (dep.properties) {
                            return (
                                dep.properties[k] &&
                                validate(v, dep.properties[k])?.valid &&
                                // the property we depend on should be present - I think that's always the case.
                                typeof object[depKey] !== 'undefined' &&
                                validate(object[depKey], dep.properties[depKey])?.valid
                            );
                        }
                        // Might need to support other types of nestings in the future.
                        const nestings = ['oneOf', 'allOf', 'anyOf'] as const;
                        return nestings.some((n) =>
                            dep[n]?.some((e) => {
                                return (
                                    e.properties[k] &&
                                    validate(v, e.properties[k])?.valid &&
                                    validate(object[depKey], e.properties[depKey])?.valid
                                );
                            }),
                        );
                    }
                    return false;
                });
                return isBaseProperty || isDependentProperty;
            }),
        ) as T;
    };

export default filterPropertiesToSchemaDefined;
