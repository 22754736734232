import { put, takeEvery } from 'redux-saga/effects';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import ViewConfig from '../reducers/ViewConfigType';
import { fromNullable } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import { EntityValidations } from '../reducers/entityValidationsReducer';
import getValidation from './util/getValidation';
import { getStorage } from 'storage/storage';

export default function* () {
    yield takeEvery(
        LOAD_VIEW_CONFIG_SUCCESS,
        function* ({
            payload: { viewConfig },
        }: {
            type: typeof LOAD_VIEW_CONFIG_SUCCESS;
            payload: { viewConfig: ViewConfig };
        }) {
            const validResourceConfigs = mapOption(Object.entries(viewConfig.entities), ([resource, entity]) =>
                getValidation(resource, resource, viewConfig, fromNullable(entity.validationExp)),
            );
            const entityValidations: EntityValidations = Object.assign({}, ...validResourceConfigs);
            getStorage().setItem('entityValidations', JSON.stringify(entityValidations));
            yield put({ type: 'ENTITY_VALIDATIONS_GENERATED', payload: entityValidations });
        },
    );
}
