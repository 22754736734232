import React, { useContext, useMemo, FunctionComponent, useCallback, useState } from 'react';
import { Widget } from 'dashboard2/dashboard-config/types';
import Popup from 'components/Popup';
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { RenderFieldAdder } from './GenericEditableGrid';
import { useForm, FormProvider } from 'react-hook-form';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import ControlledJSONEditor from 'react-hook-form-utils/ControlledJSONEditor';
import ControlledJSONSchemaForm from 'react-hook-form-utils/ControlledJsonSchemaForm';
import ExpandMore from '@material-ui/icons/ExpandMore';
import filterPropertiesToSchemaDefined from './util/filterPropertiesToSchemaDefined';
import { createPrecompiledValidator, ValidatorFunctions } from '@rjsf/validator-ajv8';
import uniq from 'lodash/uniq';
import LazyFullFeaturedSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import produce from 'immer';

const claimableTaskConfig = require('./schemas/claimableTaskConfig.json');
claimableTaskConfig['validator'] = require('./compiledSchemas/claimableTaskConfig.js');

const dashboardLinkConfig = require('./schemas/dashboardLinkConfig.json');
dashboardLinkConfig['validator'] = require('./compiledSchemas/dashboardLinkConfig.js');

const entitySearchConfig = require('./schemas/entitySearchConfig.json');
entitySearchConfig['validator'] = require('./compiledSchemas/entitySearchConfig.js');

const htmlConfig = require('./schemas/htmlConfig.json');
htmlConfig['validator'] = require('./compiledSchemas/htmlConfig');

const linkConfig = require('./schemas/linkConfig.json');
linkConfig['validator'] = require('./compiledSchemas/linkConfig');

const myActiveTasksConfig = require('./schemas/myActiveTasksConfig.json');
myActiveTasksConfig['validator'] = require('./compiledSchemas/myActiveTasksConfig');

const reportConfig = require('./schemas/reportConfig.json');
reportConfig['validator'] = require('./compiledSchemas/reportConfig');

const startProcessConfig = require('./schemas/startProcessConfig.json');
startProcessConfig['validator'] = require('./compiledSchemas/startProcessConfig');

const textTemplateConfig = require('./schemas/textTemplateConfig.json');
textTemplateConfig['validator'] = require('./compiledSchemas/textTemplateConfig');

const myReportsConfig = require('./schemas/myReportsConfig.json');
myReportsConfig['validator'] = require('./compiledSchemas/myReportsConfig');

const emptyConfig = require('./schemas/emptyConfig.json');
emptyConfig['validator'] = require('./compiledSchemas/emptyConfig');

const jsonschema_editors: {
    [key in Widget['type']]?: { schema: string; uiSchema: string; validator: any };
} = {
    ClaimableTasksWidget: claimableTaskConfig,
    DashboardLinkWidget: dashboardLinkConfig,
    EntitySearchWidget: entitySearchConfig,
    HtmlWidget: htmlConfig,
    LinkWidget: linkConfig,
    MyActiveTasksWidget: myActiveTasksConfig,
    ReportWidget: reportConfig,
    StartProcessWidget: startProcessConfig,
    TemplatePageWidget: textTemplateConfig,
    MyReportsWidget: myReportsConfig,
    OfflineTasksWidget: emptyConfig,
    MapWidget: emptyConfig,
    DemoCovidHistogram: emptyConfig,
};
uniq(Object.values(jsonschema_editors)).forEach((entry) => {
    entry['validator'] = createPrecompiledValidator(entry['validator'] as ValidatorFunctions, JSON.parse(entry.schema));
});

const renderHelp = (type: Widget['type']) => {
    const text = (() => {
        switch (type) {
            case 'DashboardLinkWidget': {
                return `
                {
                    toDashboard: string;
                }
                `;
            }
            case 'ClaimableTasksWidget': {
                return `
                {
                    processKey?: string[];
                    taskKey?: string[];
                    roles?: string[];
                    viewName?: string;
                    onlyUnassigned?: boolean;
                    onlyAssignedToUser?: boolean;
                    claimTaskOnSelect?: boolean;
                    filter?: string;
                }`;
            }
            case 'EntitySearchWidget': {
                return `
                {
                    navTo?: string;
                    entity: string;
                    viewName?: string;
                    filterConfig?: string;
                    showSearchFields?: boolean;
                }`;
            }
            case 'HtmlWidget': {
                return `
                {
                    html: string;
                }`;
            }
            case 'LinkWidget': {
                return `
                {
                    url: string;
                }`;
            }
            case 'MapWidget': {
                return `{}`;
            }
            case 'MyActiveTasksWidget': {
                return `
                {
                    useTaskListView?: boolean;
                    drillToMyTasks?: boolean;
                }`;
            }
            case 'MyReportsWidget': {
                return `
                {
                    reportName?: string[];
                    outputContentType?: string[];
                    viewName?: string;
                    running?: boolean;
                    longRunning?: boolean;
                    startTime?: string;
                }`;
            }
            case 'ReportWidget': {
                return `
                {
                    navTo?: string;
                    reportName: string;
                    parameters?: {} | string;
                    titleUrl?: string;
                    colors?: string[];
                    prefetchValuesets?: string[];
                } & {
                    type: 'line';
                }
              | {
                    type: 'pie';
                }
              | {
                    type: 'stackedBar';
                }
              | {
                    type: 'stackedBar';
                }
              | {
                    type: 'singleValue';
                }
              | {
                    wantColumnHeaders: boolean;
                    isFromFlowable: boolean;
                    type: 'linktable';
                    isTask?: boolean;
                    fields: string; // comma seperated e.g. "Enrollment,Enrollment Number"
                    noDrillDown?: boolean;
                    totalConfig?: {
                        isRowSum?: boolean;
                        isColumnSum?: boolean;
                        fieldsToSum?: string;
                        rowTotalsName?: string;
                        columnTotalsName?: string;
                    };
                }
              | {
                    type: 'bar';
                    wantColumnHeaders?: boolean;
                    isFromFlowable?: boolean;
                }`;
            }
            case 'StartProcessWidget': {
                return `
                {
                    processDefinitionKey: string;
                    startVariables?: {} | string;
                }`;
            }
            case 'TemplatePageWidget': {
                return `
                {
                    textTemplateName: string;
                }`;
            }
            default:
                return '';
        }
    })();
    return <pre>{text}</pre>;
};

interface EditWidgetProps {
    initialValues: Partial<Widget>;
    onSubmit: (values: Widget) => void;
    renderActions?: (props: { SaveButton: JSX.Element }) => JSX.Element;
}

const EditWidget: FunctionComponent<EditWidgetProps> = ({ initialValues: _initialValues, ...props }) => {
    /**
     * We save visibilityExpression in definition, however it applies to all widgets, so lets patch it in on submission only.
     * That way the jsonschema forms can control the entirety of the definition, and visibilityExpression can be handled separately.
     * To do it this way, we have to separate the two:
     */
    const initialVisibilityExpression = _initialValues?.definition?.visibilityExpression;
    const initialValues = useMemo(() => {
        return (
            _initialValues &&
            produce(_initialValues, (draft) => {
                const visibilityExpression = draft?.definition?.visibilityExpression;
                if (visibilityExpression) {
                    delete draft.definition['visibilityExpression'];
                }
                return draft;
            })
        );
    }, [_initialValues]);
    const methods = useForm<Widget>({
        defaultValues: initialValues,
        mode: 'onBlur',
    });
    const [visExp, setVisExp] = useState(initialVisibilityExpression ?? '');
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const widgetTypes: Widget['type'][] = useMemo((): Widget['type'][] => {
        return [
            'OfflineTasksWidget',
            'DashboardLinkWidget',
            'ClaimableTasksWidget',
            'EntitySearchWidget',
            'HtmlWidget',
            'LinkWidget',
            'MapWidget',
            'MyActiveTasksWidget',
            'MyReportsWidget',
            'ReportWidget',
            'StartProcessWidget',
            'TemplatePageWidget',
            'DemoCovidHistogram',
            'Quicksight',
        ];
    }, []);
    const type: Widget['type'] = methods.watch(
        'type',
        (initialValues && initialValues['type']) || widgetTypes[0],
    ) as Widget['type'];

    const SaveButton = (
        <Button variant="contained" color="primary" type="submit">
            Save
        </Button>
    );
    const filterValue = useCallback(
        (definition) => {
            if (!jsonschema_editors[type]?.schema) {
                return definition;
            }
            return filterPropertiesToSchemaDefined(JSON.parse(jsonschema_editors[type].schema))(definition);
        },
        [type],
    );

    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <h3>{initialValues ? 'Edit Widget' : 'Add Widget'}</h3>
            <form
                onSubmit={methods.handleSubmit((values) => {
                    const definition = filterValue(values?.definition ?? {});
                    props.onSubmit({
                        ...values,
                        definition: produce(definition, (draft) => {
                            draft.visibilityExpression = visExp?.trim() || undefined;
                        }) as any,
                    });
                })}
            >
                <FormProvider {...methods}>
                    <TextField
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        variant={fieldVariant}
                        margin="normal"
                        fullWidth
                        inputRef={methods.register}
                        label="id"
                        name="id"
                    />
                    <TextField
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        variant={fieldVariant}
                        margin="normal"
                        fullWidth
                        inputRef={methods.register}
                        label="Title"
                        name="title"
                    />
                    <select name="type" ref={methods.register({ required: true })}>
                        {widgetTypes.map((v, i) => {
                            return (
                                <option key={i} value={v}>
                                    {v}
                                </option>
                            );
                        })}
                    </select>
                    <div style={{ margin: '1rem 0rem' }}>
                        <label>
                            <b>Visibility Expression *</b>
                            <LazyFullFeaturedSpelEditor onChange={setVisExp} value={visExp} hideDocs />
                        </label>
                    </div>
                    {jsonschema_editors[type] ? (
                        <div>
                            <ControlledJSONSchemaForm
                                name="definition"
                                defaultValue={(() => {
                                    const value: any = initialValues ? initialValues.definition ?? {} : {};
                                    /**
                                     * Below is a fix for when processKey on ClaimableTasks is configured as a string,
                                     * when it should really be an array of strings.
                                     */
                                    if (value.processKey && typeof value.processKey === 'string') {
                                        return {
                                            ...value,
                                            processKey: [value.processKey],
                                        };
                                    }

                                    return value;
                                })()}
                                schema={jsonschema_editors[type].schema}
                                uiSchema={jsonschema_editors[type].uiSchema}
                                validator={jsonschema_editors[type].validator}
                            />
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Inspect or edit JSON definition</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ display: 'block' }}>
                                    <div>{renderHelp(type)}</div>
                                    <div>
                                        <ControlledJSONEditor
                                            name="definition"
                                            filterValue={filterValue}
                                            defaultValue={initialValues ? initialValues.definition : {}}
                                        />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ) : (
                        <>
                            {renderHelp(type)}
                            <ControlledJSONEditor
                                name="definition"
                                defaultValue={initialValues ? initialValues.definition : {}}
                            />
                        </>
                    )}

                    {props.renderActions?.({ SaveButton }) ?? SaveButton}
                </FormProvider>
            </form>
        </div>
    );
};

const renderFieldAdder: RenderFieldAdder<Widget> = (args) => {
    const { initialValues, renderToggler, addField } = args;
    return (
        <Popup
            ComponentProps={{
                fullWidth: true,
            }}
            renderToggler={renderToggler}
            renderDialogContent={({ closeDialog }) => {
                return (
                    <div
                        style={{
                            border: '1px solid black',
                        }}
                    >
                        <EditWidget
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                addField(values);
                                closeDialog();
                            }}
                            renderActions={({ SaveButton }) => (
                                <div
                                    style={{
                                        marginTop: '1em',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button onClick={closeDialog} variant="contained">
                                        Cancel
                                    </Button>
                                    {SaveButton}
                                </div>
                            )}
                        />
                    </div>
                );
            }}
        />
    );
};
export default renderFieldAdder;
