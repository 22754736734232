import React from 'react';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withPropsOnChange from 'recompose/withPropsOnChange';
import {
    getRefEntityName,
    isRefOneField,
    isFieldViewField,
} from '../../components/generics/utils/viewConfigUtils/index';
import { withFieldFactory } from '../../fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import ViewConfig, { FieldViewField } from '../../reducers/ViewConfigType';
import { LocalFilterPreFields, LocalFilterComponentProps } from '../../components/generics/genericList/LocalFilter';
import filterFixesFieldValue from '../../components/generics/genericList/filter/permanentFilterFixesValueOfField';
import { RootState } from '../../reducers/rootReducer';
import { Subtract } from 'utility-types';

interface ProcessListSpecificProps {
    processDefinitionKey?: string;
    processDefinitions: RootState['bpm']['processDefinitions']['byId'];
    renderWhenNoFields?: () => JSX.Element | null;
    renderContainer?: (arg: { BaseElement: JSX.Element }) => JSX.Element | null;
}

type ProcessFilterProps = Subtract<
    LocalFilterComponentProps & ProcessListSpecificProps,
    Pick<ProcessListSpecificProps, 'processDefinitions'> &
        Pick<LocalFilterComponentProps, 'clearValuesInForm' | 'fields' | 'viewConfig'>
>;
// interface ProcessFilterProps extends LocalFilterProps, ProcessListSpecificProps {}

const getProcessDefinitionSearchFields = (viewConfig: ViewConfig, processDefinitionKey?: string): FieldViewField[] => {
    const processConfig = processDefinitionKey && viewConfig.processes[processDefinitionKey];
    if (processConfig) {
        const searchView = processConfig.views.SEARCH;
        if (searchView) {
            return Object.values(searchView.searchFields).filter(isFieldViewField) || [];
        }
    }
    return [];
};

const ProcessListFilter: React.SFC<ProcessFilterProps> = compose(
    onlyUpdateForKeys([
        'referencedFromEntity',
        'formId',
        'setFilters',
        'submitFilters',
        'clearFilters',
        'permanentFilter',
        'processDefinitionKey',
    ]),
    withFieldFactory,
    connect(
        ({
            viewConfig,
            bpm: {
                processDefinitions: { byId },
            },
        }: RootState) => ({
            viewConfig,
            processDefinitions: byId,
        }),
    ),
    withPropsOnChange(
        ['fieldFactory', 'permanentFilter', 'referencedFromEntity', 'processDefinitionKey'],
        ({
            fieldFactory,
            permanentFilter,
            referencedFromEntity,
            processDefinitionKey,
            viewConfig,
        }: LocalFilterComponentProps &
            ProcessListSpecificProps & {
                fieldFactory: Function;
            }) => ({
            fields: fieldFactory({
                dataSource: DataSource.ENTITY,
                mode: Mode.INPUT,
                validate: false,
                connected: false,
                options: {},
            })({ replacePeriodsInFieldName: '_~_', alwaysOn: true, neverDisabled: true, isForSearch: true })(
                getProcessDefinitionSearchFields(viewConfig, processDefinitionKey)
                    .filter((f) => (permanentFilter ? !filterFixesFieldValue(permanentFilter)(f.field) : true))
                    .filter(
                        (f) =>
                            !referencedFromEntity ||
                            !isRefOneField(viewConfig, 'AppCase', f.field.split('.')[0]) ||
                            referencedFromEntity !== !!getRefEntityName(viewConfig, 'AppCase', f.field.split('.')[0]),
                    ),
            ),
        }),
    ),
)((props: ProcessListSpecificProps & LocalFilterComponentProps) =>
    (!props.fields || props.fields.length === 0) && props.renderWhenNoFields ? (
        props.renderWhenNoFields()
    ) : props.renderContainer ? (
        props.renderContainer({ BaseElement: <LocalFilterPreFields {...props} /> })
    ) : (
        <LocalFilterPreFields {...props} />
    ),
);
export default ProcessListFilter;
