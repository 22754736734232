import { SpelNode, SpelCompiledExpression } from '../../evaluate';
import { setoidString } from 'fp-ts/lib/Setoid';
import { array, uniq } from 'fp-ts/lib/Array';
import { compose } from 'fp-ts/lib/function';
import { SpelExpressionEvaluator } from 'spel2js';
import getIndexOfClosingParen from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getIndexOfClosingParen';
import { getValuesetReverseLookupUtilities } from 'expressions/contextUtils';
import splitByCommasAtCurrentLevelOnly from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/splitByCommasAtCurrentLevelOnly';

const getStringRep = (expression: string) => (ast: SpelNode) => {
    return expression.slice(ast.getStartPosition(), ast.getEndPosition());
};

type MethodNode = SpelNode & { _type: 'method' };
type FunctionNode = SpelNode & { _type: 'function' };
const isMethodNode = (node: SpelNode): node is MethodNode => node._type === 'method';
const isFunctionNode = (node: SpelNode): node is FunctionNode => node._type === 'function';

export const methodNamesWithValueSetCodeArgument: {
    [k in keyof ReturnType<typeof getValuesetReverseLookupUtilities>]: number;
} & { lookupConceptIdsFromValuesetGroup?: number } = {
    valuesetIsLoaded: 0,
    lookupConceptIdsFromValuesetGroup: 0,
    getConceptCodeFromDisplay: 0,
    getConceptFromCode: 0,
    getConceptFromDisplay: 0,
    getConceptIdFromCode: 0,
    getConceptIdFromDisplay: 0,
    isValidConcept: 0,
    isValidConceptFromCode: 0,
    isValidConceptFromDisplay: 0,
    isConceptInGroup: 0,
};
const isQuote = (char: string) => {
    return char === '"' || char === "'";
};
const isQuoted = (arg: string) => {
    return isQuote(arg[0]) && isQuote(arg[arg.length - 1]);
};
const valuesetCodesUsedInExpression = (expression: string) => {
    const getFuncArgs = (ast: MethodNode | FunctionNode) => {
        const methodName = getStringRep(expression)(ast);

        const expR = expression.slice(ast.getStartPosition());
        // below exp includes literals and other expressions
        const startOfArgs = expR.indexOf('(') + 1;
        const leftAfterInitialParens = expR.slice(startOfArgs);
        const args = splitByCommasAtCurrentLevelOnly(
            leftAfterInitialParens.slice(0, getIndexOfClosingParen(leftAfterInitialParens)),
        ).filter((t) => t);
        const codes = args
            .filter(
                (arg, i) =>
                    methodNamesWithValueSetCodeArgument[
                        methodName.startsWith('#') ? methodName.slice(1) : methodName
                    ] === i,
            )
            .flatMap((arg) => (isQuoted(arg) ? [arg.slice(1, -1)] : []));
        return codes.concat(
            args.flatMap((argumentExpression) =>
                valuesetCodesUsedInExpression(argumentExpression)(
                    (SpelExpressionEvaluator.compile(argumentExpression) as SpelCompiledExpression)._compiledExpression,
                ),
            ),
        );
    };
    const strUniq = uniq(setoidString);
    const getValuesetCodeLiterals = (rootNode: SpelNode) => {
        const getValuesetCodeLiteralsInner = (ast: SpelNode): string[] => {
            const children = ast.getChildren();
            if (children.length > 0) {
                return array.chain(children, getValuesetCodeLiteralsInner);
            }
            if (isMethodNode(ast) || isFunctionNode(ast)) {
                return getFuncArgs(ast);
            }
            return [];
        };
        return getValuesetCodeLiteralsInner(rootNode);
    };
    return compose(strUniq, getValuesetCodeLiterals);
};
export default valuesetCodesUsedInExpression;
