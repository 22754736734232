import React, { useContext } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { editViewInPlaceContext } from './editViewInPlaceContext';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';

export default function EditViewInPlaceSwitch() {
    const printMode = useSelector((state: RootState) => state.printMode);
    const { set, editing } = useContext(editViewInPlaceContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        set(Boolean(event.target.checked));
    };
    if (printMode) {
        return null;
    }

    return <FormControlLabel control={<Switch checked={editing} onChange={handleChange} />} label="Edit View" />;
}
