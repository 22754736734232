import * as list from './actions';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import preprocessFilter from 'clients/utils/preprocessFilter';
import createExpansion from 'clients/utils/createExpansionQuery/buildCommaSeperatedExpansions';
import {
    adjustSortFieldName,
    buildPaginationQueryString,
    queryParametersForSearch,
    specialCasesOnlyExpansionQuery,
    expansionQuery,
} from '../util/queryBuilderUtils';
import { fromPredicate } from 'fp-ts/lib/Option';
import { SearchField } from '@mkanai/casetivity-shared-js/lib/view-config/views';
import getOverrideSecondarySorts from './getOverrideSecondarySorts';

export const getSecondarySorts = (view?: View, stabilizerDirection: 'ASC' | 'DESC' = 'ASC') => {
    const overrideSecondarySorts = getOverrideSecondarySorts(view);

    const secondarySortsInOrder = fromPredicate<View>(Boolean)(view)
        .map((v) => Object.values(v.fields))
        .map((fields) =>
            fields
                .filter((f) => {
                    const so = (f as SearchField).sortOrder;
                    return so || so === 0;
                })
                .sort((a: SearchField, b: SearchField) => a.sortOrder - b.sortOrder)
                .map((sf: SearchField) => ({ field: sf.field, direction: sf.sortDir || 'ASC' })),
        )
        .map(([first, ...rest]) => rest) // we only want the secondary sorts, since primary sort is overwritten
        .chain(fromPredicate((l) => l.length > 0))
        .map((l) => [...l, { field: 'id', direction: stabilizerDirection }])
        .getOrElse([{ field: 'id', direction: stabilizerDirection }]);
    if (overrideSecondarySorts.isSome()) {
        return [...overrideSecondarySorts.value, ...secondarySortsInOrder];
    }
    return secondarySortsInOrder;
};
export const getSecondarySortString = (
    view?: View,
    stabilizerDirection: 'ASC' | 'DESC' = 'ASC',
    dontInclude: string[] = [],
    startWith: string = '',
) => {
    const secondarySorts = getSecondarySorts(view, stabilizerDirection).filter(
        (f) => dontInclude.indexOf(f.field) === -1,
    );
    const sorts = secondarySorts.reduce((prev, curr, i) => {
        return `${prev}sort=${curr.field}%2C${curr.direction}${i === secondarySorts.length - 1 ? '' : '&'}`;
    }, '');
    if (sorts) {
        return startWith + sorts;
    }
    return '';
};

const ID_ASC_SORT = 'sort=id%2CASC';
const buildUrl = (params: list.GetListParams, viewConfig: ViewConfig) => {
    const { page, perPage } = params.pagination;
    const overrideSecondarySorts = getOverrideSecondarySorts(params.view && viewConfig.views[params.view]);
    const overriddenSecondarySort = overrideSecondarySorts.mapNullable((s) => s[0]).toUndefined();
    // const { field = 'id', order = 'DESC' } = params.sort;
    // if there is no primary sort, the overridden secondarySort now becomes the primary sort.
    const field = params.sort?.field ?? overriddenSecondarySort?.field ?? 'id';
    const order = params.sort?.order ?? overriddenSecondarySort?.direction ?? 'DESC';

    const sortField = adjustSortFieldName(field, viewConfig, params.resource);

    const paginationQuery = buildPaginationQueryString({
        sortField,
        sortDir: order,
        page,
        perPage,
    });

    const tieBreakingSort =
        sortField && params.view
            ? getSecondarySortString(viewConfig.views[params.view], order, [sortField], '&')
            : sortField && sortField !== 'id'
            ? `&${ID_ASC_SORT}`
            : '';

    const restUrl = params.overrideApi || viewConfig.entities[params.resource].restUrl;

    const search = queryParametersForSearch(preprocessFilter(params.filter, viewConfig, params.view), true);

    let queryParams = `${search}${paginationQuery}${tieBreakingSort}`;
    if (queryParams.endsWith('&')) {
        queryParams = queryParams.slice(0, -1);
    }
    // queryParams should never end with & because expansionString always starts with &

    const expansionString = (() => {
        // always starts with &
        if (params.view) {
            const viewExpansion = createExpansion(params.view, viewConfig);
            const continuedExpansion = (() => {
                if (params.appendExpansions) {
                    return expansionQuery(
                        viewExpansion ? ',' : '',
                        params.resource,
                        viewConfig,
                        params.appendExpansions,
                        false,
                    );
                }
                return '';
            })();
            return '&expand=' + viewExpansion + continuedExpansion;
        }
        return specialCasesOnlyExpansionQuery('&', params.resource, params.appendExpansions);
    })();
    return `${restUrl}?${queryParams}${expansionString}`;
};
export default buildUrl;
