class ExpiredOfflineDataSubscribedComponentsRegistry {
    private subscriptions: (() => void)[] = [];
    public registerCallback(cb: () => void) {
        this.subscriptions.push(cb);
    }
    public unregisterCallback(cb: () => void) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
    public dispatchSomeOfflineTasksExpired() {
        this.subscriptions.forEach((cb) => {
            cb();
        });
    }
}
export const getExpiredOfflineDataSubscribedComponentsRegistry = (() => {
    let expiredOfflineDataSubscribedComponentsRegistry: ExpiredOfflineDataSubscribedComponentsRegistry;
    return () => {
        if (!expiredOfflineDataSubscribedComponentsRegistry) {
            expiredOfflineDataSubscribedComponentsRegistry = new ExpiredOfflineDataSubscribedComponentsRegistry();
        }
        return expiredOfflineDataSubscribedComponentsRegistry;
    };
})();
