import * as t from 'io-ts';

const fieldMapping = t.type({
    date: t.string,
    duration: t.string,
    attendeesIds: t.string,
});

const requiredFields = t.array(t.union([t.literal('date'), t.literal('duration'), t.literal('attendeesIds')]));

export type FieldMapping = t.TypeOf<typeof fieldMapping>;
export type RequiredFields = t.TypeOf<typeof requiredFields>;

const displayInputsOnBase = t.partial({
    date: t.boolean,
    duration: t.boolean,
    attendeesIds: t.boolean,
});

export const eventConfiguration = t.intersection([
    t.type({
        fieldMapping,
        requiredFields,
        displayInputsOnBase,
    }),
    t.partial({
        attendeesInModal: t.boolean,
    }),
]);

export type EventConfiguration = t.TypeOf<typeof eventConfiguration>;
