export interface ConceptIdsEncountered {
    [conceptId: string]: true;
}

export class ValuesetKeeperArounder {
    public conceptIdsEncountered: ConceptIdsEncountered = {};

    addAll(conceptIds: string[]) {
        conceptIds.forEach((conceptId) => this.add(conceptId));
    }

    add(conceptId: string) {
        this.conceptIdsEncountered[conceptId] = true;
    }

    encountered(conceptId: string) {
        return this.conceptIdsEncountered[conceptId];
    }
}
