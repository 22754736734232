import produce from 'immer';
import { DontOverwrite } from 'sideEffect/crud/util/epics/CoreCrud/shared';
import { getType } from 'typesafe-actions';
import { crudDeleteSuccess } from 'sideEffect/crud/delete/actions';

const customMappings = {
    AppCase: {
        // from [guaranteedfield] to [link] in case it's not as simple as stripping 'Id' off
        processInstanceId: ['processInstance', 'processInstanceId'],
    },
};
const alwaysKeepKeys = {
    ProcessInstance: {
        appCase: true,
    },
};

export const ensureReferencesAreNotErasedIfNotExpanded = (data: {}, entityName: string, initial = {}) => {
    return Object.keys(data).reduce((prev, key) => {
        const value = data[key];
        prev[key] = value;
        const otherFields = entityName && customMappings[entityName] ? customMappings[entityName][key] : null;
        if (otherFields) {
            const otherFieldsNotOnData = otherFields.filter((f) => !Object.prototype.hasOwnProperty.call(data, f));

            otherFieldsNotOnData.forEach((f) => {
                prev[f] = value;
            });
        }
        if (key.endsWith('Id') && !Object.prototype.hasOwnProperty.call(data, key.slice(0, -2))) {
            prev[`${key.slice(0, -2)}`] = value;
        }
        return prev;
    }, initial);
};

interface Records {
    [entityName: string]: {
        [id: string]: {};
    };
}
export const addRecords = (prevState: Records, newEntities: Records, dontOverwrite?: DontOverwrite): Records => {
    const newState = {};
    Object.keys(newEntities).forEach((entityName) => {
        const dataMap = newEntities[entityName];
        Object.keys(dataMap).forEach((id) => {
            const data = dataMap[id];

            if (!newState[entityName]) {
                newState[entityName] = {};
            }

            let newDataRecord = {
                entityType: entityName,
            };
            const combinedWithNewRecord = !prevState[entityName]?.[id]
                ? newDataRecord
                : Object.keys(prevState[entityName][id]).reduce((prev, field) => {
                      const isManyRelated = field.endsWith('Ids') || prevState[entityName][id][field + 'Ids'];

                      const includedInDontOverrideList =
                          dontOverwrite?.[entityName]?.[id] &&
                          (() => {
                              const entry = dontOverwrite[entityName][id];
                              if (entry[field]) {
                                  return true;
                              }
                              if (field.endsWith('Id')) {
                                  return entry[field.slice(0, -2)];
                              }
                              return entry[field + 'Id'];
                          })();

                      const inAlwaysKeep = alwaysKeepKeys[entityName]?.[field];
                      const keep = isManyRelated || includedInDontOverrideList || inAlwaysKeep;
                      if (keep) {
                          prev[field] = prevState[entityName][id][field];
                      }
                      return prev;
                  }, newDataRecord);

            newState[entityName][id] = ensureReferencesAreNotErasedIfNotExpanded(
                data,
                entityName,
                combinedWithNewRecord,
            );
        });
    });
    Object.keys(prevState).forEach((entityType) => {
        if (!newState[entityType]) {
            newState[entityType] = prevState[entityType];
        } else {
            Object.keys(prevState[entityType]).forEach((id) => {
                if (!newState[entityType][id]) {
                    newState[entityType][id] = prevState[entityType][id];
                }
            });
        }
    });
    return newState;
};

const entities = (state = { Concept: {} }, action) => {
    const payload = action.payload;
    if (payload && payload.data && payload.data.entities) {
        return addRecords(state, payload.data.entities, action.meta && action.meta.dontOverwrite);
    }
    if (action.type === getType(crudDeleteSuccess)) {
        const id = action.requestPayload.id;
        const resource = action.requestPayload.resource;
        if (!state[resource]?.[id]) {
            return state;
        }
        return produce(state, (draftState) => {
            delete draftState[resource][id];
            Object.entries(draftState).forEach(([resource, data]) => {
                Object.entries(data).forEach(([k, v]) => {
                    if (v === id) {
                        draftState[resource][k] = null;
                    }
                });
            });
        });
    }

    return state;
};
export default entities;
