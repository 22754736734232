import React from 'react';
import BaseErrorPage from './BaseErrorPage';
import CloudOff from '@material-ui/icons/CloudOff';
import Error from '@material-ui/icons/Error';

export const NetworkUnavailable = (props: { retry?: (e: any) => void }) => {
    const { retry } = props;
    return <BaseErrorPage Icon={CloudOff} headingText="Offline" retry={retry} />;
};

export const ServerError = (props: { code: number; message?: string }) => {
    const { code, message } = props;
    return (
        <BaseErrorPage
            Icon={Error}
            headingText={code ? `${code}` : 'Unknown Error'}
            subText={message === 'ajax error 404' ? 'Not Found' : message}
        />
    );
};
