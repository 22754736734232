import { fromNullable } from 'fp-ts/lib/Option';
import ViewConfig from '../../reducers/ViewConfigType';

type dataType = ViewConfig['entities'][0]['fields'][0]['dataType'];
/*
    In order to prevent revision entities from being stored and overwriting our current data,
    we will alter all ids in a response containing a 'RefInfo' to be <originalid>:<revision>
    This has to include references as well as id fields, so we need the viewConfig to figure out what fields to replace.

    We can assume most prior preprocessing has already been done.
*/

const isObject = (value): value is object => !!value && value.constructor === Object;
const isArray = <T>(value): value is Array<T> => !!value && value.constructor === Array;

// recurse through object tree and remove all @id keys

interface EntityWithId {
    id: number;
    entityType: string;
}

interface WildcardReference {
    id: number | string;
    schema: string;
}
type ExpressionType = Revision | EntityWithId | string | number | ExpressionArray | WildcardReference;
interface ExpressionArray extends Array<ExpressionType> {}

const isEntityWithId = (data: ExpressionType): data is EntityWithId =>
    isObject(data) && data['id'] && data['entityType'];

const isWildcardReference = (data: ExpressionType): data is WildcardReference =>
    isObject(data) && data['id'] && data['schema'] && Object.keys(data).length === 2;

/*
    how to find all ids through the viewconfig and a given entity b
    1. look up the b.entityType
    2. first id is b.id
    3. replace all references in b that are REFONE, REFONE_JOIN, [VALUESET ...]?, REFMANYMANY,
*/

interface Revision {
    id: string; // e.g. <entityId:revisionId>
    entity: EntityWithId;
    revInfo: {
        id: number;
        login: string;
        revision: number;
        timestamp: string;
    };
}
const isRevision = (data: ExpressionType): data is Revision =>
    isObject(data) &&
    data['revInfo'] &&
    data['entity'] &&
    // just in case we haven't stripped out a jsog market
    Object.keys(data).length <= 4;

const getIsOfDatatype =
    (dataTypes: dataType[]) => (viewConfig: ViewConfig) => (currEntityType: string, field: string) =>
        fromNullable(viewConfig.entities[currEntityType])
            .chain((e) => fromNullable(e.fields[field]))
            .map((f) => f.dataType)
            .fold(false, (dt) => dataTypes.indexOf(dt) !== -1);

const getIsId = getIsOfDatatype(['REFONE', 'REFONEJOIN']);
const getIsIds = getIsOfDatatype(['REFMANYMANY', 'REFMANY', 'REFMANYJOIN']);

const getSourceFromField = (f: string) => (f.endsWith('Id') ? f.slice(0, -2) : f.endsWith('Ids') ? f.slice(0, -3) : f);

// for now, lets assume we can figure out if we are looking at a revision at the top level.
const getApplyRevisionToAllIds = (viewConfig: ViewConfig) => {
    const isId = getIsId(viewConfig);
    const isIds = getIsIds(viewConfig);

    const getAppend = (toAppend: string | number) => (v: ExpressionType) => {
        // this oculd be an expansion!!
        if (isWildcardReference(v)) {
            return {
                ...v,
                id: `${v.id}:${toAppend}`,
            };
        }
        if (isRevision(v)) {
            // start a new revision recursion
            return applyRevisionNumberToAllIds(v);
        }
        if (isArray(v)) {
            return v.map(getAppend(toAppend));
        }
        if (isEntityWithId(v)) {
            // recuse on entity id replacement function
            return updateAllEntityIds(toAppend)(v);
        }
        if (typeof v === 'string' || typeof v === 'number') {
            return `${v}:${toAppend}`;
        }
        // throw a runtime exception
        throw new Error(`unexpected Expression type ${JSON.stringify(v)}`);
    };

    const updateAllEntityIds = (toAppend: string | number) => {
        const append = getAppend(toAppend);
        const updateData = (data: EntityWithId) =>
            Object.assign(
                {},
                ...Object.entries(data).map(([k, v]) =>
                    k === 'id' ||
                    isId(data.entityType, getSourceFromField(k)) ||
                    isIds(data.entityType, getSourceFromField(k))
                        ? { [k]: append(v) }
                        : { [k]: v },
                ),
            );
        return updateData;
    };

    // type EntityOrRevisionData = Revision | Revision[] | EntityWithId | EntityWithId[];
    const applyRevisionNumberToAllIds = (data) => {
        if (isArray(data)) {
            return data.map(applyRevisionNumberToAllIds);
        }
        if (isRevision(data)) {
            // lets recurse down the entity and replace all ids with the revision number
            const revisionNumber = data.revInfo.revision;
            const updateIds = updateAllEntityIds(revisionNumber);
            return {
                ...data,
                entity: updateIds(data.entity),
            };
        }
        return data;
    };
    return applyRevisionNumberToAllIds;
};

export default getApplyRevisionToAllIds;
