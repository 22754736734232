import { ActionType, getType } from 'typesafe-actions';
import * as notistack from './actions';
import { QueuedNotification } from './definitions.d';
import { getDefault } from 'reducers/basicInfoReducer';

interface NotistackState {
    notifications: QueuedNotification[];
}

const defaultState: NotistackState = {
    notifications: [],
};

export type NotistackAction = ActionType<typeof notistack>;

const storeNotifications = !getDefault().secure || process.env.NODE_ENV === 'development';

const notistackReducer = (state: NotistackState = defaultState, action: NotistackAction): NotistackState => {
    if (
        !storeNotifications &&
        (action.type === 'ENQUEUE_SNACKBAR' ? action.notification?.options?.ContentProps?.className !== 'prod' : true)
    ) {
        return state;
    }
    switch (action.type) {
        case getType(notistack.enqueueSnackbar):
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...action.notification,
                    },
                ],
            };

        case getType(notistack.removeSnackbar):
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.key !== action.key),
            };

        default:
            return state;
    }
};

export default notistackReducer;
