import { useContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';

import deepEql from 'deep-eql';
import { formContext } from '../../../FormContext';
import { submitAction } from '../../Form/Layout/SaveButton/Save/submitAction';
import useIdle from './useIdle';

export interface UseAutoSaveProps {
    on: boolean;
    taskId: string;
    formDefinition: TaskForm;
}
const fromLastSave = 15 * 1000;
const useAutoSave = (props: UseAutoSaveProps) => {
    const [, rerender] = useReducer((state: number) => state + 1, 1);
    const fc = useContext(formContext);
    const dispatch = useDispatch();
    const { on, taskId, formDefinition } = props;
    const lastSavedValues = useRef(null);
    const { date, taskId: lastSavedTaskId } = useSelector((state: RootState) => state.bpm.lastFormSave);
    const [isIdle, idleTimer] = useIdle({ timeout: 2000 });
    const lastSaveIsOld = !lastSavedTaskId || lastSavedTaskId !== taskId || !date || date + fromLastSave < Date.now();
    useEffect(() => {
        const saveConditions = on && fc.isDirty && isIdle && !deepEql(lastSavedValues.current, fc.fieldValues);
        if (saveConditions && lastSaveIsOld) {
            lastSavedValues.current = fc.fieldValues;
            idleTimer.pause();
            dispatch(
                submitAction(
                    {
                        overrideWarning: true,
                        values: {
                            ...fc.fieldValues,
                            submissionType: 'save',
                        },
                        fieldVisibility: Object.assign(
                            {},
                            ...Object.keys(fc.fieldValues).map((f) => ({
                                [f]: !fc.hiddenFields[f],
                            })),
                        ),
                    },
                    taskId,
                    formDefinition,
                    (response) => {
                        idleTimer.resume();
                    },
                    () => {
                        idleTimer.resume();
                    },
                    true,
                ),
            );
        } else if (saveConditions) {
            // if delay not yet met, ensure a follow up render where 'lastSaveIsOld' can be rechecked.
            // if it's still false, we hit here again, basically looping as long as we are due for a save.
            const to = setTimeout(rerender, 3 * 1000);
            return () => clearTimeout(to);
        }
    }, [
        on,
        isIdle,
        dispatch,
        fc.fieldValues,
        fc.hiddenFields,
        fc.isDirty,
        formDefinition,
        idleTimer,
        lastSaveIsOld,
        taskId,
    ]);
};

export default useAutoSave;
