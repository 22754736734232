import * as taskForm from '../actions';
import { TaskForm } from 'reducers/taskFormType';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';

type TaskForms = {
    [taskId: string]: TaskForm;
};
const taskFormsReducer = (previousState: TaskForms = {}, action: RootAction): TaskForms => {
    switch (action.type) {
        case getType(taskForm.getTaskFormSuccess): {
            const taskId = action.requestPayload.taskId;
            return { ...previousState, [taskId]: action.payload.taskForm };
        }
        default:
            return previousState;
    }
};
export default taskFormsReducer;
