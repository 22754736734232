import React from 'react';
import useViewConfig from 'util/hooks/useViewConfig';

const useViewConfigKey = () => {
    const [key, remount] = React.useReducer((state) => state + 1, 1);
    const viewConfig = useViewConfig();
    React.useEffect(() => {
        remount();
    }, [viewConfig]);
    return key;
};
export default useViewConfigKey;
