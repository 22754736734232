import { getAllFieldsFromView, isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import { isRefManyField } from '../utils/viewConfigUtils/getFieldProperties';
import produce from 'immer';
import set from 'lodash/set';
import unset from 'lodash/unset';
import ViewConfig from 'reducers/ViewConfigType';

const modifySubmissionValues = ({
    viewConfig,
    viewName,
    values,
    casetivityRemovedFields,
    hiddenFields,
    initialHiddenFields,
}: {
    viewConfig: ViewConfig;
    viewName: string;
    values: {};
    casetivityRemovedFields?: string[];
    hiddenFields: {
        [field: string]: true;
    };
    initialHiddenFields: {
        [field: string]: true;
    };
}) => {
    /**
     * At this point, it seems possible to look up multiselect fields with 'removeRelatedEntitiesIfHidden' set
     * (from the view), see if they are hidden in fc.hiddenFields, and set '[]' there in the submitted values.
     */
    const hiddenXManyfieldsToClear = getAllFieldsFromView(viewConfig, viewName).flatMap((f) => {
        if (!isFieldViewField(f)) {
            return [];
        }

        if (!hiddenFields[f.field] || initialHiddenFields?.[f.field]) {
            return [];
        }
        // ^ ensure changed from visible to hidden
        try {
            if (!isRefManyField(viewConfig, f.entity, f.field, 'POP_LAST') || !f.config) {
                return [];
            }
            if (JSON.parse(f.config)?.['removeRelatedEntitiesIfHidden']) {
                return [f.field];
            }
        } catch (e) {
            return [];
        }
        return [];
    });
    // we have to delete all casetivityRemovedFields, so we don't unset values by accident by submitting null.
    const finalValues = produce(values, (draft) => {
        casetivityRemovedFields?.forEach((ftr) => {
            unset(draft, ftr);
        });
        // add x-manys to 'clear out'
        hiddenXManyfieldsToClear.forEach((f) => {
            set(draft, f, []);
        });
        return draft;
    });
    return finalValues;
};
export default modifySubmissionValues;
