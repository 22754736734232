import React from 'react';
import { connect } from 'react-redux';
import { StartupEventCreator } from '../actions/StartupEvent';

class LoadBasicInfoComponent extends React.Component<{ loadBasicInfo: () => void }> {
    componentDidMount() {
        if (process.env.NODE_ENV === 'development' && false) {
            this.props.loadBasicInfo();
        }
    }
    render() {
        return this.props.children;
    }
}
const LoadBasicInfo = connect(null, {
    loadBasicInfo: StartupEventCreator.getBasicInfo,
})(LoadBasicInfoComponent);

export default LoadBasicInfo;
