import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getFooterConfigSelector } from 'util/applicationConfig';
import { layoutModeContext } from 'components/layouts/LayoutMode';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const useStyles = makeStyles((theme) => ({
    footer: (props: { sideDrawer: boolean }) => ({
        width: '100%',
        height: '100%',
        zIndex: props.sideDrawer ? theme.zIndex.drawer + 1 : 1,
    }),
}));

interface FooterProps {}

const AppFooter: React.FunctionComponent<FooterProps> = (props) => {
    const { mode } = useContext(layoutModeContext);
    const classes = useStyles({
        sideDrawer: mode === 'new',
    });
    const footerConfig = useSelector(getFooterConfigSelector);
    return footerConfig?.footerHtml ? (
        <footer className={classes.footer}>
            <SafeHtmlAsReact html={footerConfig.footerHtml} />
        </footer>
    ) : null;
};

export default AppFooter;
