import { SpelNode, SpelCompiledExpression } from '../evaluate';
import { setoidString } from 'fp-ts/lib/Setoid';
import { array, uniq } from 'fp-ts/lib/Array';
import { SpelExpressionEvaluator } from 'spel2js';
import getIndexOfClosingParen from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getIndexOfClosingParen';
import splitByCommasAtCurrentLevelOnly from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/splitByCommasAtCurrentLevelOnly';

const getStringRep = (expression: string) => (ast: SpelNode) =>
    expression.slice(ast.getStartPosition(), ast.getEndPosition());

type MethodNode = SpelNode & { _type: 'method' };
type FunctionNode = SpelNode & { _type: 'function' };
const isMethodNode = (node: SpelNode): node is MethodNode => node._type === 'method';
const isFunctionNode = (node: SpelNode): node is FunctionNode => node._type === 'function';
const strUniq = uniq(setoidString);

const getMethodAndFunctionNames =
    (expression: string) =>
    (rootNode: SpelNode): string[] => {
        const _getMethodAndFunctionNames = (ast: SpelNode): string[] => {
            const children = ast.getChildren();
            if (children.length > 0) {
                return array.chain(children, _getMethodAndFunctionNames);
            }
            if (isMethodNode(ast) || isFunctionNode(ast)) {
                const _methodName = getStringRep(expression)(ast);
                const methodName = _methodName.startsWith('#') ? _methodName.slice(1) : _methodName;

                const expR = expression.slice(ast.getStartPosition());
                // below exp includes literals and other expressions
                const startOfArgs = expR.indexOf('(') + 1;
                const leftAfterInitialParens = expR.slice(startOfArgs);
                const args = splitByCommasAtCurrentLevelOnly(
                    leftAfterInitialParens.slice(0, getIndexOfClosingParen(leftAfterInitialParens)),
                ).filter((t) => t);

                return [methodName].concat(
                    args.flatMap((argumentExpression) =>
                        getMethodAndFunctionNames(argumentExpression)(
                            (SpelExpressionEvaluator.compile(argumentExpression) as SpelCompiledExpression)
                                ._compiledExpression,
                        ),
                    ),
                );
            }
            return [];
        };
        return strUniq(_getMethodAndFunctionNames(rootNode));
    };
export default getMethodAndFunctionNames;
