import React from 'react';
import { CardActions, Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import PopoverCreateButton from '../../../fieldFactory/popovers/PopoverCreateButton';
import { allowsCreate } from '../utils/viewConfigUtils';
import { customEntityListActions } from '../overrides';
import { useHideCreateButton } from './ListActions';
import useMaybeGetSearchFromFilter from './hooks/useMaybeGetSearchFromFilter';
import MappingCreateButton, { getMapSearchToCreateExpression } from './MappingCreate';
import useViewConfig from 'util/hooks/useViewConfig';
import Add from '@material-ui/icons/Add';

const cardActionStyle: React.CSSProperties = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const PermissionedListActions = ({
    listViewName,
    resource,
    createViewName,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    refresh,
    exportList,
    accessLevel,
    parentEntityName,
    parentFieldInChild,
    parentId,
    injectCreateValues,
    onCreateCb,
    handleClose,
    hideCreate,
    formId,
    evaluatedAdhocSPELVariables,
}) => {
    const search = useMaybeGetSearchFromFilter(listViewName, filterValues);
    const hideCreateButton = useHideCreateButton(listViewName) || hideCreate;
    const customActions = customEntityListActions[resource];
    const viewConfig = useViewConfig();
    const mapSearchToCreateExpression = getMapSearchToCreateExpression(viewConfig, listViewName);
    const closeButton = handleClose ? (
        <IconButton aria-label="Close popover" onClick={handleClose}>
            <Close />
        </IconButton>
    ) : null;
    return customActions ? (
        <CardActions style={cardActionStyle}>
            {customActions.map((action, i) => (
                <Link to={action.url} key={`custom-link-${action.key}`}>
                    <Button color="primary">{action.label}</Button>
                </Link>
            ))}
            {closeButton}
        </CardActions>
    ) : (
        <CardActions style={cardActionStyle}>
            {(() => {
                if (!allowsCreate(accessLevel) || hideCreateButton) {
                    return null;
                }
                return (
                    <PopoverCreateButton
                        evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                        search={search}
                        resource={resource}
                        label="Create"
                        parentEntityName={parentEntityName}
                        parentFieldInChild={parentFieldInChild}
                        parentId={parentId}
                        onCreateCb={onCreateCb}
                        injectCreateValues={injectCreateValues}
                        viewName={createViewName}
                        // remount for every search, to reset internal state.
                        key={'list-backref-createbtn ' + search}
                        renderButton={
                            !mapSearchToCreateExpression
                                ? undefined
                                : ({ openWithSearch, ...props }) => {
                                      return (
                                          <MappingCreateButton
                                              onResult={(result) => {
                                                  const querystring = search ? search + '&' + result : '?' + result;
                                                  openWithSearch(querystring);
                                              }}
                                              entityType={resource}
                                              searchExpression={mapSearchToCreateExpression}
                                              formId={formId}
                                          >
                                              {({ onClick }) => (
                                                  <Button color="primary" onClick={onClick}>
                                                      Create&nbsp;
                                                      <Add />
                                                  </Button>
                                              )}
                                          </MappingCreateButton>
                                      );
                                  }
                        }
                    />
                );
            })()}
            {closeButton}
        </CardActions>
    );
};

PermissionedListActions.defaultProps = {
    accessLevel: 0,
    showFilter: true,
    basePath: '',
    filterValues: {},
    displayedFilters: {},
};

export default PermissionedListActions;
