import { Field } from '../../translation/types';
import { isEntityDataField, getConfigProperty } from '../../input/fieldUtils';
import { isFieldFromFlowable } from '../components/Address/util';
import { ValuesetFormField } from 'fieldFactory/translation/fromFlowable/types';

export const getDefaultCode = (fieldDefinition: Field) => {
    if (isEntityDataField(fieldDefinition)) {
        return getConfigProperty<{ defaultCode?: string }>('defaultCode')(fieldDefinition).getOrElse(undefined);
    }
    // no default value specified for fromFlowable
    return undefined;
};

interface GroupConf {
    group?: string;
}
export const getValuesetGroup = (fieldDefinition: Field) => {
    if (isEntityDataField(fieldDefinition)) {
        return getConfigProperty<GroupConf>('group')(fieldDefinition).toUndefined();
    }
    if (isFieldFromFlowable(fieldDefinition)) {
        return (fieldDefinition as any as ValuesetFormField).params?.group;
    }
    return undefined;
};
