import ITask from '../../../../../types/task';
import moment from 'moment';

/*
    GET /api/bpm/tasks
*/

/* API return type */
export interface TaskFromRestTask {
    id: string;
    assignee: {
        id: string;
    } | null;
    formKey: string;
    name: string;
    outcome?: string;
    priority: number;
    created: string | null;
    processInstanceId?: string;
    casetivityDueDate?: string | null;
    endDate?: string;
    description: string;
    taskDefinitionKey: string | null;
    linkedEntityId?: string;
    linkedEntityType?: string;
    linkedEntity?: {};
    taskPermissions: {
        canClaimTask: boolean;
        canAssignTask: boolean;
        potentialUsers: {
            id: string;
            login: string;
            title: string;
        }[];
    };
    readOnly: boolean;
    schedDate?: string;
    schedDateString?: string;
    schedDuration?: number;
    attendeesIds?: string[];
}

/*
    translation of our primary entity to canon type AFTER denormalization
    We are deliberately stripping values here,
    If a value is being used in the app, we want to know about it!
    (basically, don't use ...rest).
*/
const mapToAppType = ({
    id,
    assignee,
    formKey,
    name,
    outcome,
    priority,
    created,
    processInstanceId,
    casetivityDueDate,
    endDate,
    description,
    linkedEntityId,
    linkedEntityType,
    linkedEntity,
    taskPermissions,
    readOnly,
    schedDate,
    schedDateString,
    schedDuration,
    attendeesIds,
}: TaskFromRestTask): ITask => ({
    id,
    name,
    outcome,
    priority,
    assignee: assignee ? assignee.id : null,
    formKey,
    created: created || null,
    endDate: endDate || undefined,
    processInstanceId,
    dueDate: casetivityDueDate || undefined,
    description,
    linkedEntityId,
    linkedEntityType,
    linkedEntity,
    taskPermissions,
    readOnly,
    schedDateString,
    schedDate: schedDate || undefined,
    schedDuration,
    attendeesIds,
});

// For updating task
const mapToApiTypePut = ({ dueDate, ...rest }) => {
    return {
        ...rest,
        casetivityDueDate: dueDate ? moment(dueDate).hour(12).minutes(0).toISOString() : null,
    };
};

/*
    For creating new adhoc task
 */
const mapToApiType = ({
    assignee,
    name,
    comments,
    taskDefinitionKey,
    endDate,
    created,
    dueDate,
    schedDate,
    ...rest
}: Partial<ITask>): Partial<TaskFromRestTask> => ({
    assignee: assignee ? { id: assignee } : null,
    name: name ? name : 'New Task',
    taskDefinitionKey: taskDefinitionKey ? taskDefinitionKey : null,
    endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
    created: created ? moment(created).format('YYYY-MM-DD') : null,
    casetivityDueDate: dueDate ? moment(dueDate).hour(12).minutes(0).toISOString() : null,
    schedDate: schedDate && moment(schedDate).toISOString(false),
    ...rest,
});

export { mapToApiType, mapToAppType, mapToApiTypePut };
