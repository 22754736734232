import { put, takeEvery, call, CallEffect, PutEffect, ForkEffect, AllEffect, SelectEffect } from 'redux-saga/effects';
import { fetchJson } from '../../util/fetchUtils';
import { BACKEND_BASE_URL } from '../../config';
import buildFetchOptions from './buildFetchOptions';
import { Action } from 'redux';

type callback = (...args: any[]) => any;
type requestAction = {
    type: string;
    payload: string | number | {};
    cb?: callback;
    errorCb?: callback;
};
export enum HttpVerb {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}
export interface ErrorDetails {
    error?: string;
    status?: number;
    body?: string;
}
type createBodyType = (payloa: {} | string) => {};
export type configType = {
    actionType: string;
    verb: HttpVerb;
    cancelIf?: (payload?: {}) => IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>;
    url:
        | ((
              payload: {} | string,
          ) => string | IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>)
        | string;
    body?: {} | createBodyType;
    success: (
        payload: {},
        responseBody: {},
        headers?: Headers,
    ) => Action | Action[] | IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>;
    failure:
        | ((
              payload: {},
              errorDetails: ErrorDetails,
          ) => Action | IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>)
        | string;
    callCb?: (
        payload: {},
        responseBody: {},
        cb: callback,
    ) => void | IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>;
    callErrorCb?: (
        cb: callback,
    ) => void | IterableIterator<SelectEffect | CallEffect | AllEffect<any> | PutEffect<Action>>;
};

const failure = (createFailure, initialActionPayload, errorDetails: {}) => {
    if (typeof createFailure === 'string') {
        return {
            type: createFailure,
            payload: errorDetails,
        };
    }
    return createFailure(initialActionPayload, errorDetails);
};
const isAction = (maybeAction) => maybeAction && typeof maybeAction === 'object' && maybeAction.type;

const sagaFactory = ({
    actionType,
    verb,
    url,
    cancelIf,
    body: createBody,
    success: createSuccess,
    failure: createFailure,
    callCb,
    callErrorCb,
}: configType) => {
    function* handler({ payload, cb, errorCb }: requestAction): IterableIterator<PutEffect<Action> | CallEffect> {
        function* passErrorCbToCaller() {
            if (errorCb) {
                if (typeof callErrorCb === 'function') {
                    if (typeof errorCb === 'function') {
                        yield call(callErrorCb, errorCb);
                    } else {
                        throw Error('errorCb not a function');
                    }
                } else {
                    throw Error('callErrorCb not a function, for payload with callback');
                }
            }
        }
        try {
            if (cancelIf) {
                const shouldCancel = yield call(cancelIf, payload);
                if (shouldCancel) {
                    return;
                }
            }
            const options = buildFetchOptions();
            options.method = verb;

            // body
            if (typeof createBody === 'function') {
                options.body = JSON.stringify((createBody as createBodyType)(payload));
            } else if (createBody) {
                options.body = JSON.stringify(createBody);
            }

            // url
            const urlValue: string =
                typeof url === 'function'
                    ? `${BACKEND_BASE_URL}${yield call(url, payload)}`
                    : `${BACKEND_BASE_URL}${url}`;

            // fetch
            const _r = yield call(fetchJson, urlValue, options);
            const {
                status,
                body: bodyText,
                headers,
            } = _r as {
                status: number;
                body: any;
                headers: any;
            };
            if (status >= 200 && status < 300) {
                const body = bodyText ? JSON.parse(bodyText) : null; // sometimes responses have no body

                // execute callback
                if (cb) {
                    if (typeof callCb === 'function') {
                        if (typeof cb === 'function') {
                            yield call(callCb, payload, body, cb);
                        } else {
                            throw Error('cb not a function');
                        }
                    } else {
                        throw Error('callCb not a function, for payload with callback');
                    }
                }

                // emit success action(s)
                const success: any = yield call(createSuccess, payload, body, headers);
                if (success instanceof Array) {
                    for (const successAction of success) {
                        yield put(successAction);
                    }
                } else if (isAction(success)) {
                    yield put(success);
                } else {
                    yield success;
                }
            } else {
                const failureResult = failure(createFailure, payload, {
                    status,
                    body: bodyText,
                });
                if (isAction(failure)) {
                    yield put(failureResult);
                } else {
                    yield failureResult;
                }
                yield call(passErrorCbToCaller);
            }
        } catch (error) {
            try {
                const failureResult = failure(createFailure, payload, { error: error });
                if (isAction(failureResult)) {
                    yield put(failureResult);
                } else {
                    yield failureResult;
                }
                yield call(passErrorCbToCaller);
            } catch (e) {
                console.error('error trying to generate FAIL event');
            } finally {
                console.error(error);
            }
        }
    }

    return function* saga(): IterableIterator<ForkEffect> {
        yield takeEvery(actionType, function* (action: requestAction) {
            yield call(handler, action);
        });
    };
};

export default sagaFactory;
