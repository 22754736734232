import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { makeStyles, createStyles, Theme, Collapse, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            textAlign: 'center',
            backgroundColor: theme.palette.error.dark,
            color: 'white',
        },
        saving: {
            textAlign: 'center',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        success: {
            textAlign: 'center',
            backgroundColor: '#43A047',
            color: 'white',
        },
    }),
);
const AutoSavingBannerComponent: FunctionComponent<{
    status: 'initial' | 'pending' | 'success' | 'failed';
}> = (props) => {
    const { status } = props;
    const classes = useStyles(props);
    // normalizedSuccess: either already online, or turned online and banner can be removed
    // after wait
    // normalizedStatus: either
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (status === 'initial') {
            setShow(false);
        } else {
            setShow(true);
            const t = setTimeout(() => {
                setShow(false);
            }, 1000);
            return () => {
                clearTimeout(t);
            };
        }
    }, [status, setShow]);
    return (
        <div role="alert">
            <Collapse in={status === 'failed' && show}>
                <div className={classes.error}>
                    <Typography variant="h6">Failed to auto-save</Typography>
                </div>
            </Collapse>
            <Collapse in={status === 'pending' || (status === 'success' && show)}>
                <div className={classes.saving}>
                    <Typography variant="h6">{status === 'pending' ? 'Saving...' : 'Saved'}</Typography>
                </div>
            </Collapse>
        </div>
    );
};

const AutoSavingBanner = (props) => {
    const autoSaveStatusSelector = useMemo(() => {
        const initial = {
            type: 'initial',
        } as const;
        return createSelector(
            (state: RootState) => state.bpm.lastFormSave,
            (lastSave) => (lastSave.isAutoSave ? lastSave.status : initial),
        );
    }, []);
    const autoSaveStatus = useSelector(autoSaveStatusSelector);
    return (
        <div style={{ position: 'fixed', left: 0, right: 0, top: 0, zIndex: 5000 }}>
            <AutoSavingBannerComponent status={autoSaveStatus.type} />
        </div>
    );
};
export default AutoSavingBanner;
