import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { services } from 'sideEffect/services';
import { EMPTY } from 'rxjs';
import { storageController } from 'storage';
import { push as pushAction } from 'connected-react-router';
import { load as loadViewConfigAction } from 'viewConfig/actions';
import { closeProfilePrompt, profileSelected } from '../actions';
import { CircularProgress } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import { AjaxError } from 'rxjs/ajax';
import Check from '@material-ui/icons/Check';

type SelectProfilePromptState =
    | {
          type: 'initial';
      }
    | {
          type: 'pending';
          userId: string;
      }
    | {
          type: 'error';
          userId: string;
      };
const initial = { type: 'initial' } as const;

const SelectProfileList = () => {
    const [state, setState] = useState<SelectProfilePromptState>(initial);
    const profiles = useSelector((state: RootState) => state.profiles);
    const currentUserId = useSelector((state: RootState) => state.viewConfig?.user?.id);
    const dispatch = useDispatch();
    const selectProfile = (userId: string) => {
        if (userId === currentUserId) {
            dispatch(profileSelected(userId));
        } else {
            setState({ type: 'pending', userId });
        }
    };
    const prevState = useRef(state.type);
    useEffect(() => {
        if (prevState.current !== 'pending' && state.type === 'pending') {
            let curProfKey = storageController.getCurrentProfileToken();
            if (curProfKey) {
                // in order to impersonate another user, we need to use the token
                // we logged in with for the services.impersonateUser call
                storageController.clearCurrentProfileToken();
                // we will set this back on failure
            }
            const $impersonate = services.impersonateUser(state.userId);
            const subscription = $impersonate.subscribe(
                (res) => {
                    storageController.setCurrentProfileToken(res.response.id_token);
                    dispatch(profileSelected(state.userId));
                    dispatch(pushAction('/_temp'));
                    dispatch(loadViewConfigAction(false, '/'));
                },
                (error: AjaxError) => {
                    storageController.setCurrentProfileToken(curProfKey);
                    setState({ type: 'error', userId: state.userId });
                    return EMPTY;
                },
            );
            prevState.current = state.type;
            return () => {
                if (!subscription.closed) {
                    // don't lose the current profile token
                    if (!storageController.getCurrentProfileToken()) {
                        storageController.setCurrentProfileToken(curProfKey);
                    }
                    subscription.unsubscribe();
                }
            };
        }
        prevState.current = state.type;
    }, [state, dispatch]);

    if (profiles.state === 'no_profiles') {
        return null;
    }
    return (
        <List>
            {profiles.profiles.map((profile) => {
                const isCurr = profiles.currentUserId && profiles.currentUserId === profile.userId;
                return (
                    <ListItem
                        onClick={() => (isCurr ? dispatch(closeProfilePrompt()) : selectProfile(profile.userId))}
                        selected={isCurr}
                        key={profile.userId}
                        button
                    >
                        <ListItemText primary={profile.name} />
                        {(() => {
                            if (state.type === 'pending') {
                                return state.userId === profile.userId ? <CircularProgress /> : null;
                            }
                            if (state.type === 'error') {
                                return state.userId === profile.userId ? <Warning /> : null;
                            }
                            if (isCurr) {
                                return <Check />;
                            }
                            return null;
                        })()}
                    </ListItem>
                );
            })}
        </List>
    );
};
export default SelectProfileList;
