import React from 'react';
import { DefaultMessageId } from 'i18n/defaultMessagePaths';
import defaultMessages from 'i18n/defaultMessages';
import { FormattedMessage, useIntl } from 'react-intl';
import get from 'lodash/get';

type FieldWithPossiblyUndefined<T, Key> = GetFieldType<Exclude<T, undefined>, Key> | Extract<T, undefined>;

type GetIndexedField<T, K> = K extends keyof T
    ? T[K]
    : K extends `${number}`
    ? '0' extends keyof T // tuples have string keys, return undefined if K is not in tuple
        ? undefined
        : number extends keyof T
        ? T[number]
        : undefined
    : undefined;

type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
    ? Left extends keyof T
        ? FieldWithPossiblyUndefined<T[Left], Right>
        : Left extends `${infer FieldKey}[${infer IndexKey}]`
        ? FieldKey extends keyof T
            ? FieldWithPossiblyUndefined<
                  GetIndexedField<Exclude<T[FieldKey], undefined>, IndexKey> | Extract<T[FieldKey], undefined>,
                  Right
              >
            : undefined
        : undefined
    : P extends keyof T
    ? T[P]
    : P extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
        ? GetIndexedField<Exclude<T[FieldKey], undefined>, IndexKey> | Extract<T[FieldKey], undefined>
        : undefined
    : undefined;

//  We generate this TypeMap so typescript only needs to calculate this once.
// Otherwise the typescript server hangs and it gets annoying to use Message
type TypeMap = {
    [Id in DefaultMessageId]: GetFieldType<typeof defaultMessages, Id>;
};

const Message = <Id extends DefaultMessageId, DefaultMessage extends TypeMap[Id]>({
    id,
    defaultMessage,
    children,
    values,
}: {
    id: Id;
    dm: DefaultMessage;
    defaultMessage?: string;
    description?: string;
    children?: (message: string) => JSX.Element;
    values?:
        | {
              '0'?: any;
          }
        | {
              '0'?: any;
              '1'?: any;
          }
        | {
              '0'?: any;
              '1'?: any;
              '2'?: any;
          }
        | {
              '0'?: any;
              '1'?: any;
              '2'?: any;
              '3'?: any;
          };
}) => {
    const intl = useIntl();
    if (children) {
        return children(intl.formatMessage({ id, defaultMessage }, values));
    }
    return <FormattedMessage values={values} id={id} defaultMessage={defaultMessage ?? get(defaultMessages, id)} />;
};

export default Message;

// We enforce providing the default message, so we know what it's supposed to be in the code.
// (if we just saw the id it would be confusing.)
// usage:
// <Message defaultMessage="Warning" id="validate.warning" />
