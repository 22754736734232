const trimChars = (value: string, trimUntil = /[\040-\176]/) => {
    let firstIndex = -1,
        lastIndex = -1;
    if (typeof value === 'string') {
        let i = 0;
        while (i < value.length && !value.charAt(i).match(trimUntil)) {
            i++;
        }
        firstIndex = i;
        let j = value.length;
        while (j >= 0 && !value.charAt(j).match(trimUntil)) {
            j--;
        }
        lastIndex = j;
        value = firstIndex <= lastIndex ? value.substring(firstIndex, lastIndex + 1) : '';
    }
    return value;
};

export default trimChars;
