import { makeStyles, TableCell, TableHead, TableRow, Table as MuiTable, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';
import './styles.css';

export const useTableStyles = makeStyles((theme) => ({
    table: ({ cols }: { cols: number }) => ({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: `${'minmax(150px, 1fr) '.repeat(cols - 1)} minmax(75px, .5fr)`,
    }),
}));

const createHeaders = (headers) => {
    return headers.map(({ label, field }) => ({
        text: label,
        field,
        ref: useRef(),
    }));
};

/*
 * Resizing taken from the blog post here:
 * https://letsbuildui.dev/articles/resizable-tables-with-react-and-css-grid
 */
interface TableProps {
    headers: {
        label: string;
        field?: string;
    }[];
    minCellWidth: number;
    tableContent: React.ReactElement;
    renderBelow?: (props: { updateInternalTableHeight: () => void }) => JSX.Element;
    currentSort: [string, 'desc' | 'asc'];
    setSort: (sort: [string, 'desc' | 'asc']) => void;
}
const Table: React.FC<TableProps> = ({ headers, minCellWidth, tableContent, renderBelow, setSort, currentSort }) => {
    const [tableHeight, setTableHeight] = useState('auto');
    const [activeIndex, setActiveIndex] = useState(null);
    const tableElement = useRef(null);
    const classes = useTableStyles({ cols: headers.length });
    const columns = createHeaders(headers);

    const updateInternalTableHeight = useCallback(() => setTableHeight(tableElement.current.offsetHeight), []);

    useEffect(() => {
        updateInternalTableHeight();
    }, [updateInternalTableHeight]);

    const mouseDown = (index) => {
        setActiveIndex(index);
    };

    const mouseMove = useCallback(
        (e) => {
            const gridColumns = columns.map((col, i) => {
                if (i === activeIndex) {
                    const width = e.clientX - col.ref.current.offsetLeft;

                    if (width >= minCellWidth) {
                        return `${width}px`;
                    }
                }
                return `${col.ref.current.offsetWidth}px`;
            });

            tableElement.current.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
        },
        [activeIndex, columns, minCellWidth],
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(null);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', mouseUp);
        }

        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);

    return (
        <div>
            <div>
                <MuiTable
                    classes={{
                        root: classNames(classes.table, 'inline-table'),
                    }}
                    ref={tableElement}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map(({ ref, text, field }, i) => {
                                const active = currentSort && currentSort?.[0] === field;
                                const sortDirection = currentSort ? currentSort[1] : false;
                                return (
                                    <TableCell
                                        sortDirection={sortDirection}
                                        component="th"
                                        scope="head"
                                        ref={ref}
                                        key={text}
                                    >
                                        {!field ? (
                                            <>{text ?? <span className="casetivity-off-screen">Actions</span>}</>
                                        ) : (
                                            <TableSortLabel
                                                active={active}
                                                direction={currentSort?.[1]}
                                                onClick={
                                                    !field
                                                        ? undefined
                                                        : () => {
                                                              if (!currentSort) {
                                                                  setSort([field, 'asc']);
                                                                  return;
                                                              }
                                                              const [currentField, currentDirection] = currentSort;
                                                              if (currentField !== field) {
                                                                  setSort([field, 'asc']);
                                                                  return;
                                                              }
                                                              setSort([
                                                                  field,
                                                                  currentDirection === 'asc' ? 'desc' : 'asc',
                                                              ]);
                                                          }
                                                }
                                            >
                                                {text}
                                                {active ? (
                                                    <span className="casetivity-off-screen">
                                                        {sortDirection === 'desc'
                                                            ? 'sorted descending'
                                                            : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        )}
                                        <div
                                            style={{ height: tableHeight }}
                                            onMouseDown={() => mouseDown(i)}
                                            className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
                                        />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {tableContent}
                </MuiTable>
            </div>
            {renderBelow({ updateInternalTableHeight })}
        </div>
    );
};

export default Table;
