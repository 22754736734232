import { setPrimaryDash, setPrimaryDashSuccess, setPrimaryDashFailure } from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';

const setPrimaryDashboardFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(setPrimaryDash)),
        flatMap((action) =>
            services.setCurrentUserPrimaryDashboard(action.dashboardId).pipe(
                map((r) => {
                    const { status, response } = r;
                    if (status < 200 || status >= 300) {
                        const error: any = new Error(response.message);
                        error.status = status;
                        throw error;
                    }
                    return r;
                }),
                map(() => setPrimaryDashSuccess(action.dashboardId)),
                catchError((err) => of(setPrimaryDashFailure(action.dashboardId, err))),
            ),
        ),
    );
export default setPrimaryDashboardFlow;
