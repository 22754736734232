import { Button, Card, CardContent } from '@material-ui/core';
import { getProcessDefinitions } from 'bpm/processDefinitions/actions';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Route, Switch, Link } from 'react-router-dom';
import offlineRoutes from './offlineRoutes';
import Layout from './Layout';
import SavedStatesList from './offline_stateful_tasks/components/SavedStatesList';
import { ProcessTaskViewComponent } from 'routes/customRoutes';
import { createSelector } from 'reselect';
import { getDencryptTaskDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeTaskData';
import { getOfflineStartForms, getOfflineWelcomeMessageHtmlSelector } from 'util/applicationConfig';
import sessionSecretsController from './sessionSecretsController';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const OnlyRenderIfHasSessionSecrets: React.FC<{}> = ({ children }) => {
    const [showChildren, setShowChildren] = useState(Boolean(sessionSecretsController.get()));
    useEffect(() => {
        if (sessionSecretsController.get()) {
            setShowChildren(true);
        } else {
            const cb = () => {
                setShowChildren(true);
            };
            // subscribe to promptController to update when ready.
            getDencryptTaskDataPromptController().subscribeToPinSuccess(cb);
            return () => {
                getDencryptTaskDataPromptController().unsubscribe(cb);
            };
        }
    }, []);
    if (!showChildren) {
        return null;
    }
    return <>{children}</>;
};

const OfflineProcessTaskRoute = (props) => {
    return (
        <OnlyRenderIfHasSessionSecrets>
            <ProcessTaskViewComponent {...props} />
        </OnlyRenderIfHasSessionSecrets>
    );
};

const OfflineApp = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProcessDefinitions());
    }, []); // eslint-disable-line
    const processDefinitionsSelector = useMemo(() => {
        return createSelector(
            (state: RootState) => state.bpm.processDefinitions.allIds,
            (state: RootState) => state.bpm.processDefinitions.byId,
            (allIds, byId) => allIds.map((id) => byId[id]),
        );
    }, []);
    // in the future this can be keys of allowed forms, but for now we are just using this as a flag to hide start-forms when offline.
    const offlineStartForms = useMemo(() => {
        let basicInfo = JSON.parse((window as any).CASETIVITY_BASIC_INFO);
        return getOfflineStartForms(basicInfo);
    }, []);
    const processDefinitions = useSelector(processDefinitionsSelector);
    const processDefinitionsLoading = useSelector((state: RootState) => {
        return state.bpm.processDefinitions.isLoading;
    });
    const welcomeHtml = useSelector(getOfflineWelcomeMessageHtmlSelector);
    const welcomeHeading = welcomeHtml ? <SafeHtmlAsReact html={welcomeHtml} /> : <h2>Welcome to Offline Mode</h2>;
    return (
        <Layout>
            <div>
                <Switch>
                    <Route path="/processes/:pkey/tasks/:tkey/start/" component={OfflineProcessTaskRoute} />
                    <Route path="/_temp" component={() => <div />} />
                    {offlineRoutes.map((route, index) => (
                        <Route
                            key={index}
                            exact={route.props.exact}
                            path={route.props.path}
                            component={route.props.component}
                            render={route.props.render}
                            children={route.props.children}
                        />
                    ))}
                    <Route
                        route="/"
                        component={() => {
                            let startformsstuff = processDefinitionsLoading ? (
                                <div>Loading...</div>
                            ) : processDefinitions.length === 0 ? (
                                <CardContent>
                                    <h2>You are not connected to the internet</h2>
                                </CardContent>
                            ) : (
                                <CardContent>
                                    {welcomeHeading}
                                    <p>
                                        You may submit any of the start-forms below, and they will be submitted when
                                        your device reconnects to the internet, or when you next log-in using your
                                        current browser.
                                    </p>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-evenly',
                                        }}
                                    >
                                        {processDefinitions
                                            .filter((f) => f.hasStartForm)
                                            .map((pd) => (
                                                <Button
                                                    component={Link}
                                                    to={`/process-definition/${pd.key}/start-form`}
                                                    style={{ margin: '1em' }}
                                                    color="primary"
                                                    variant="contained"
                                                    key={pd.id}
                                                >
                                                    {pd.name}
                                                </Button>
                                            ))}
                                    </div>
                                </CardContent>
                            );
                            return (
                                <Card>
                                    {offlineStartForms === false ? (
                                        <CardContent style={{ paddingBottom: 0 }}>{welcomeHeading}</CardContent>
                                    ) : (
                                        startformsstuff
                                    )}
                                    <CardContent>
                                        <SavedStatesList />
                                    </CardContent>
                                </Card>
                            );
                        }}
                    />
                </Switch>
            </div>
        </Layout>
    );
};
export default OfflineApp;
