import { RootState } from '../../../../../reducers/rootReducer';

export const getAllValuesetFields = (
    visibilityConfig: RootState['entityVisibility'][0],
    editabilityConfig: RootState['entityEditability'][0],
    conceptsConfig: RootState['entityConceptExps'][0],
    viewItemFilterConfig: RootState['viewItemFilterExps'][0],
): {
    [field: string]: string;
} =>
    Object.assign(
        {},
        ...Object.values(visibilityConfig).flatMap((ca) => ca.map((c) => c.valuesetFieldsRequired)),
        ...Object.values(editabilityConfig).flatMap((ca) => ca.map((c) => c.valuesetFieldsRequired)),
        ...Object.values(conceptsConfig).map((c) => c.valuesetFieldsRequired),
        ...Object.values(viewItemFilterConfig).map((c) => c.valuesetFieldsRequired),
    );

export const getExpressions = (
    config: RootState['entityVisibility'][0] & RootState['entityEditability'][0],
): {
    [field: string]: string[];
} => Object.assign({}, ...Object.entries(config).map(([field, ca]) => ({ [field]: ca.map((c) => c.expression) })));
