interface SearchLoc {
    type: 'SEARCH';
    pathname: string;
    search: string;
}
interface ProcessLoc {
    type: 'PROCESS';
    processId: string;
    taskId: string | null;
}
type lastSearchOrProcessPage = SearchLoc | ProcessLoc | null;

const PROCESS_ROUTE_PREFIX = '/processes/';
const TSK = '/tasks/';

const isListUrl = (pathname) => !pathname.slice(1).includes('/') && pathname !== '/_temp';

export default (previousState: lastSearchOrProcessPage = null, { type, payload }): lastSearchOrProcessPage => {
    // any location with a query string in the url
    if (type === '@@router/LOCATION_CHANGE' && payload.location.pathname) {
        if (payload.location.search || isListUrl(payload.location.pathname)) {
            return {
                type: 'SEARCH',
                pathname: payload.location.pathname,
                search: payload.location.search,
            };
        } else if (payload.location.pathname.startsWith(PROCESS_ROUTE_PREFIX)) {
            const remainder = payload.location.pathname.slice(PROCESS_ROUTE_PREFIX.length);
            const [processId, taskId]: [string, string | null] = remainder.includes(TSK)
                ? [
                      remainder.slice(0, remainder.indexOf(TSK)),
                      remainder.slice(remainder.indexOf(TSK) + TSK.length, remainder.indexOf('/start')),
                  ]
                : [remainder, null];

            return {
                type: 'PROCESS',
                processId,
                taskId,
            };
        }
    }

    return previousState;
};
