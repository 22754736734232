import * as React from 'react';
import Actions, { OverrideablePermissionedActionsProps } from './Component';
import { customEntityShowActions, customEntityEditActions } from '../../overrides';

export type EditActionsProps = {
    hasDefaultShow?: boolean;
    hasDefaultDelete?: boolean;
} & OverrideablePermissionedActionsProps;

export const EditActions: React.FunctionComponent<EditActionsProps> = React.memo(
    ({ hasDefaultShow = true, hasDefaultDelete = true, ...props }: EditActionsProps) => (
        <Actions
            {...props}
            hardActionsConfig={customEntityEditActions}
            hasEdit={false}
            hasShow={!!(hasDefaultShow && props.hasShow)}
            hasDelete={!!(hasDefaultDelete && props.hasDelete)}
        />
    ),
);

export type ShowActionsProps = {
    hasDefaultEdit: boolean;
} & OverrideablePermissionedActionsProps;

export const ShowActions: React.FunctionComponent<ShowActionsProps> = React.memo(
    ({ hasDefaultEdit = true, ...props }) => (
        <Actions
            {...props}
            hardActionsConfig={customEntityShowActions}
            hasEdit={hasDefaultEdit && props.hasEdit}
            hasShow={false}
            hasDelete={false}
        />
    ),
);

export type DeleteActionsProps = {
    hasDefaultShow?: boolean;
    hasDefaultEdit?: boolean;
} & OverrideablePermissionedActionsProps;

export const DeleteActions: React.FunctionComponent<DeleteActionsProps> = React.memo(
    ({ hasDefaultShow = true, hasDefaultEdit = true, ...props }: DeleteActionsProps) => (
        <Actions
            {...props}
            hasDelete={false}
            hasShow={!!(hasDefaultShow && props.hasShow)}
            hasEdit={!!(hasDefaultEdit && props.hasEdit)}
        />
    ),
);
