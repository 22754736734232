import * as React from 'react';

interface ProcessPageRefresh {
    key: number;
    refresh: () => void;
}

export const processPageRefreshContext = React.createContext<ProcessPageRefresh>({
    key: 0,
    refresh: () => {
        console.error('ProcessPage refresh() called outside of refresh context');
    },
});
