import React, { useMemo } from 'react';
import get from 'lodash.get';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const TranslatedText: React.FC<{ value?: boolean | null; elStyle?: React.CSSProperties }> = (props) => {
    const { value } = props;
    const { translate } = useEvaluateFormattedMessage();
    const result = useMemo(() => {
        return translate({
            id: value ? 'primatives.true' : typeof value === 'boolean' ? 'primatives.false' : 'primatives.null',
            defaultMessage: JSON.stringify(value),
        });
    }, [value, translate]);
    return <span>{result}</span>;
};

interface TextFieldProps {
    addLabel?: boolean;
    label?: string;
    record?: {};
    source: string;
    translate?: boolean;
}
const NullableBooleanField: React.FC<TextFieldProps> = ({ source, record = {} }) => {
    const value = get(record, source);
    return <TranslatedText value={value} />;
};

NullableBooleanField.defaultProps = {
    addLabel: true,
};

export default NullableBooleanField;
