import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { formContext as entityShowFormContext } from 'components/generics/form/EntityFormContext/Show';
import { useContext } from 'react';
import formTypeContext from '../../formTypeContext';

const useCurrentFormContext = () => {
    const sfc = useContext(entityShowFormContext);
    const efc = useContext(entityFormContext);
    const currentFormTypeContext = useContext(formTypeContext);
    const fc = currentFormTypeContext === 'SHOW' ? sfc : efc;
    return fc;
};

export default useCurrentFormContext;
