import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import ExpressionEditor, { RowData } from './ExpressionEditor';
import fromEntries from 'util/fromentries';

/*
    Lets start by editing the visibilityExpressions
*/

interface ViewDefConfigEditorProps {
    viewName: string;
    json: EntityViewConfig;
    type: 'visibleField' | 'editableField' | 'conceptIdsForFields';
    onChangeJson: (config: EntityViewConfig) => void;
}
const ViewDefConfigEditor: FunctionComponent<ViewDefConfigEditorProps> = (props) => {
    const { json, type, onChangeJson } = props;
    const rowsOfInterest = useMemo(() => {
        return props.type === 'visibleField'
            ? props.json.visibleField
            : props.type === 'editableField'
            ? props.json.editableField
            : props.type === 'conceptIdsForFields'
            ? props.json.conceptIdsForFields && [props.json.conceptIdsForFields]
            : (() => {
                  throw new Error('uncaught type ' + props.type);
              })();
    }, [props.type, props.json]);
    const rowData: RowData[] = React.useMemo(() => {
        return (rowsOfInterest || []).flatMap((obj: { [widget: string]: string }) => {
            return Object.entries(
                Object.entries(obj).reduce((prev, curr) => {
                    let expression = curr[1];
                    if (expression.trim().startsWith('["')) {
                        expression = JSON.parse(expression)[0];
                    }
                    if (prev[expression]) {
                        prev[expression].push(curr[0]);
                    } else {
                        prev[expression] = [curr[0]];
                    }
                    return prev;
                }, {}),
            ).map(
                ([expression, widget]: [string, string[]]): RowData => ({
                    widget,
                    expression,
                    fieldsRequired: [],
                }),
            );
        });
    }, [rowsOfInterest]);
    const setRows = useCallback(
        (rowData: RowData[]) => {
            const mapping = rowData.reduce((prev, curr) => {
                return {
                    ...prev,
                    ...fromEntries(
                        curr.widget.map((w) =>
                            type === 'conceptIdsForFields'
                                ? ([w, curr.expression] as [string, string])
                                : ([w, JSON.stringify([curr.expression])] as [string, string]),
                        ),
                    ),
                };
            }, {});
            onChangeJson({
                ...json,
                [type]: type === 'conceptIdsForFields' ? mapping : [mapping],
            });
        },
        [json, type, onChangeJson],
    );
    return (
        <div>
            {/*
                concept expressions dont use Id/Ids for their targets
            */}
            <ExpressionEditor
                widgetMapsToSourceInsteadOfId={type === 'conceptIdsForFields'}
                appendIds={type !== 'conceptIdsForFields'}
                viewName={props.viewName}
                setRows={setRows}
                rows={rowData}
            />
        </div>
    );
};

export default ViewDefConfigEditor;
