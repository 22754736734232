import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import get from 'lodash.get';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { RootState } from '../../../../reducers/rootReducer';
import LinkToRecord from '../../../../components/LinkToRecord';
import EntityInspect from 'components/generics/hoc/EntityInspect';

interface ReferenceFieldProps {
    addLabel?: boolean;
    fetchOwnData?: boolean;
    record: {
        id?: string;
        entityType?: string;
        title?: string;
    };
    allowEmpty: boolean;
    elStyle?: {};
    linkType?: 'popover' | 'edit' | 'show' | 'editIfPermittedOtherwiseShow' | true;
    // as popover:
    openTo?: 'edit' | 'show';
    reference: string;
    source: string;
    basePath: string;
    editViewName?: string;
    showViewName?: string;
    renderField?: (args: {
        record?: {
            id: string;
            entityType: string;
            title?: string;
        };
        resource: string;
        allowEmpty: boolean;
        basePath: string;
        translateChoice: boolean;
    }) => JSX.Element | null;
    renderButton?: (props: { onClick: () => void }) => JSX.Element;
}
const mapStateToProps = (state: RootState, props: ReferenceFieldProps) => {
    return {
        /*
        We could use List view expansion in the future if we want... for now we just use unexpanded.
        This is because we are usually just getting the title
        */
        referenceRecord:
            state.admin.entities[props.reference] &&
            state.admin.entities[props.reference][get(props.record, props.source)],
    };
};

interface ReferenceFieldComponentProps extends ReferenceFieldProps, ReturnType<typeof mapStateToProps> {
    crudGetOne: typeof crudGetOneAction;
}
export class ReferenceField extends Component<ReferenceFieldComponentProps> {
    componentDidMount() {
        if (this.shouldFetchOwnData()) {
            this.fetchReference(this.props);
        }
    }
    shouldFetchOwnData = () => this.props.fetchOwnData;
    getRecordField =
        (field: string) =>
        (props: ReferenceFieldComponentProps = this.props) => {
            if (props.record) {
                return get(props.record, field);
            }
            return undefined;
        };
    componentWillReceiveProps(nextProps: ReferenceFieldComponentProps) {
        const getRecordId = this.getRecordField('id');
        if (this.shouldFetchOwnData() && getRecordId() !== getRecordId(nextProps)) {
            this.fetchReference(nextProps);
        }
    }

    fetchReference(props: ReferenceFieldComponentProps) {
        const linkedId = this.getRecordField(props.source)(props);
        if (linkedId !== null && typeof linkedId !== 'undefined') {
            this.props.crudGetOne({
                resource: props.reference,
                id: linkedId,
                view: null,
            });
        }
    }

    render() {
        const {
            reference,
            referenceRecord,
            basePath,
            allowEmpty = false,
            elStyle,
            linkType,
            source,
            editViewName,
            showViewName,
            openTo,
        } = this.props;

        if (!referenceRecord && !allowEmpty) {
            return <LinearProgress />;
        }
        const childElement = this.props.renderField?.({
            record: referenceRecord,
            resource: reference,
            allowEmpty,
            basePath,
            translateChoice: false,
        });
        if (linkType === 'popover') {
            return (
                <span
                    // prevent clicks in any popups from escaping in the case where we are inside a table row
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <EntityInspect
                        openTo={openTo}
                        reference={reference}
                        formId={`FromLink ${source} ${reference}`}
                        editViewName={editViewName}
                        showViewName={showViewName}
                        renderComponent={(args) =>
                            referenceRecord &&
                            (this.props.renderButton?.({
                                onClick: () => args.selectId(referenceRecord.id),
                            }) ?? (
                                <a // eslint-disable-line
                                    style={{ width: '100%' }}
                                    href="javascript:;" // eslint-disable-line
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        args.selectId(referenceRecord.id);
                                    }}
                                >
                                    {childElement}
                                </a>
                            ))
                        }
                    />
                </span>
            );
        }
        return (
            <LinkToRecord
                referenceResource={reference}
                referenceId={this.getRecordField(source)()}
                renderLinkToRecord={({ href, getHref }) => {
                    if (!href) {
                        return childElement;
                    }
                    const to =
                        linkType === 'edit' || linkType === true
                            ? getHref('EDIT')
                            : linkType === 'show'
                            ? getHref('SHOW')
                            : href;
                    return (
                        <Link
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={elStyle}
                            to={to}
                        >
                            {childElement}
                        </Link>
                    );
                }}
            />
        );
    }
}

const ConnectedReferenceField: React.ComponentType<ReferenceFieldProps> = connect(mapStateToProps, {
    crudGetOne: crudGetOneAction,
})(ReferenceField);

ConnectedReferenceField.defaultProps = {
    addLabel: true,
};

export default ConnectedReferenceField;
