import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { offlineTaskToEntityKeyVal } from 'IndexedDB/offlineTaskToEntityKeyMap';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
/**
 * Delete Entry from the various stores that reference it.
 * i.e. delete the entry directly, and then delete from taskId -> entitySubmit mapping.
 * @param key stringified date of submission - used as IDBKey for data.
 */
const deleteEntitySubmitEntry = async (key: string) => {
    await offlineEntitySubmitsIdbKeyVal.delete(key);
    const taskToEntitySubmitKeys = await offlineTaskToEntityKeyVal.keys();
    for (let i = 0; i < taskToEntitySubmitKeys.length; i++) {
        const taskId = taskToEntitySubmitKeys[i];
        const curr = await offlineTaskToEntityKeyVal.get(taskId);
        if (curr[key]) {
            let remaining = produce(curr, (draft) => {
                delete draft[key];
                return draft;
            });
            if (isEmpty(remaining)) {
                await offlineTaskToEntityKeyVal.delete(taskId);
            } else {
                await offlineTaskToEntityKeyVal.set(taskId, remaining);
            }
        }
    }
};
export default deleteEntitySubmitEntry;
