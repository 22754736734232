import ViewConfig, { View, ViewField } from '../reducers/ViewConfigType';
import { isExpressionViewField } from '../components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Option';
import { Lens } from 'monocle-ts';

type Fields = {
    [key: string]: ViewField;
};
type EntryTable = {
    EMPTY: ViewField[];
    [row: number]: ViewField[];
};
const getFieldsBy =
    (ix: 'row' | 'column') =>
    (fields: Fields): EntryTable => {
        return Object.values(fields).reduce<EntryTable>(
            (prev, curr) => {
                const valAtIx = curr[ix];
                const entryIx = typeof valAtIx === 'undefined' ? ('EMPTY' as 'EMPTY') : valAtIx;
                return {
                    ...prev,
                    [entryIx]: prev[entryIx] ? [...prev[entryIx], curr] : [curr],
                };
            },
            {
                EMPTY: [],
            },
        );
    };

const fieldIsLeftLabel = (field: ViewField): boolean => {
    if (isExpressionViewField(field) && field.config) {
        return field.config.indexOf('left-label') !== -1 && field.config.indexOf('class=') !== -1;
    }
    return false;
};

const stripUnusedFieldLabels = (fields: Fields): Fields => {
    const fieldsByRow = getFieldsBy('row')(fields);
    const res = Object.assign(
        {},
        ...Object.entries(fields).map(([k, viewField]) => {
            if (fieldIsLeftLabel(viewField) && viewField.row) {
                const fieldsInThisRow = fieldsByRow[viewField.row];
                const hasFieldToLabel = fieldsInThisRow.find(
                    (f) =>
                        f !== viewField && // not the field we are currently looking at.
                        Boolean(f.config) &&
                        tryCatch(() => JSON.parse(f.config!))
                            .map((c) => c.labelledBy)
                            .fold(false, (labelledBy) => !!labelledBy.match(/&r0c-/i)),
                );
                if (!hasFieldToLabel) {
                    return {};
                }
                return { [k]: viewField };
            }
            return { [k]: viewField };
        }),
    );
    return res;
};

export const stripUnusedItemsFromView = (view: View, tabs: 'ALLOW_EMPTY' | 'REMOVE_IF_EMPTY'): View => {
    return {
        ...view,
        fields: stripUnusedFieldLabels(view.fields),
        tabs:
            view.tabs &&
            Object.assign(
                {},
                ...Object.entries(view.tabs).map(([tk, tv]) => {
                    const fields = stripUnusedFieldLabels(tv.fields);
                    if (Object.keys(fields).length === 0 && tabs === 'REMOVE_IF_EMPTY') {
                        return undefined;
                    }
                    return {
                        [tk]: {
                            ...tv,
                            fields,
                        },
                    };
                }),
            ),
    };
};

const applyToViewConfig = (viewConfig: ViewConfig) => {
    const updateViews = Lens.fromProp<ViewConfig>()('views').modify((views) => {
        let newViews: typeof views = {};
        Object.entries(views).forEach(([k, v]) => {
            newViews[k] = stripUnusedItemsFromView(v, 'REMOVE_IF_EMPTY');
        });
        return newViews;
    });
    return updateViews(viewConfig);
};

export default applyToViewConfig;
