import React, { useMemo, FunctionComponent } from 'react';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import { ListViewEditor } from 'layout-editor/listview-builder/ListViewEditor';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import { reduxForm } from 'redux-form';
import EditableViewFormLayout from 'layout-editor/components/EditableViewFormLayout';

const Form = reduxForm({})((props) => <>{props.children}</>);

interface ControlledListLayoutEditorProps {
    view: View;
    onChange: (action: { type: 'replace' | 'write'; payload: Partial<View> }) => void;
}
const ControlledListLayoutEditor: FunctionComponent<ControlledListLayoutEditorProps> = (props) => {
    const { view, onChange } = props;

    const onSubmit = (data: Partial<View>) => {
        onChange({ type: 'write', payload: data });
    };
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[view.name] = {
                ...view,
                ...view,
            };
            return draft;
        });
    }, [viewConfig, view]);
    // Our EditableViewFormLayout looks for an overridden view-config to get 'fields' it edits and operates on,
    // so we have to fake it by providing a special overridenViewContext where fields is really our searchFields
    const overrideViewConfigForSearchLayout = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[view.name] = {
                ...view,
                ...view,
                fields: view.searchFields || view.searchFields || {},
            };
            return draft;
        });
    }, [viewConfig, view]);
    if (!view.viewType || !view.entity || !view.name) {
        return <div>View is missing essential data</div>;
    }
    if (view.viewType !== 'LIST') {
        return <div>Wrong View Type {view.viewType}</div>;
    }
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <NavWarning />
            <OverriddenViewConfigContext.Provider value={overrideViewConfigForSearchLayout}>
                <h3>Search fields:</h3>
                <Form form="listview-editor-search">
                    <EditableViewFormLayout
                        addFieldLabel="Add Search field"
                        hasTabs={false}
                        mode="EDIT"
                        includeSearchType={true}
                        entityType={view.entity}
                        view={{
                            // fake normal view
                            ...view,
                            searchFields: undefined,
                            fields: view.searchFields,
                        }}
                        onViewChange={({ view }) => {
                            onSubmit({
                                searchFields: view.fields as View['searchFields'],
                            });
                        }}
                    />
                </Form>
            </OverriddenViewConfigContext.Provider>
            <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                <h3>Column fields:</h3>
                <ListViewEditor
                    rootEntity={view.entity}
                    fields={view.fields}
                    setFields={(fields) =>
                        onSubmit({
                            fields,
                        })
                    }
                />
            </OverriddenViewConfigContext.Provider>
        </div>
    );
};

export default ControlledListLayoutEditor;
