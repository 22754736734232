import { useMemo } from 'react';
import { encodeMetadataKeyVal } from './BackrefMetadata/backrefMetadata';
import { ParentBackRefProperties } from './ParentBackRefProperties';

const getRecord = <P extends ParentBackRefProperties>(args: P): {} => {
    const { parentEntityIdValue, parentEntityName, parentField } = args;
    const r = {};
    if (parentEntityIdValue && parentEntityName && parentField) {
        if (!parentField.includes('.')) {
            r[parentField] = parentEntityIdValue;
        }
        // so backref info is accessable via getBackref()
        r[encodeMetadataKeyVal(parentField, parentEntityName)] = parentEntityIdValue;
        return r;
    }
    return r;
};

export const useRecord = <P extends ParentBackRefProperties>(p: P) => {
    const { parentEntityIdValue, parentEntityName, parentField } = p ?? {};
    return useMemo(() => {
        return getRecord({
            parentEntityIdValue,
            parentEntityName,
            parentField,
        });
    }, [parentEntityIdValue, parentEntityName, parentField]);
};
