import * as task from './actions';
import * as taskPotentialUsers from 'bpm/taskPotentialUsers/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { mapToAppType, TaskFromAppTasks } from 'bpm/dataAdapters/network/api/task-instances/entities/task';
import { taskEventCreator } from 'bpm/actions/taskEvent';

const fetchPotentialUsersFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(task.getTask)),
        flatMap((action) => {
            const obs: Observable<RootAction> = Observable.create((observer) => {
                observer.next(
                    crudGetOneAction({
                        id: action.payload,
                        resource: 'TaskInstance',
                        view: null,
                        cb: (responseId: string, responseData: any) => {
                            observer.next(task.getTaskSuccess(mapToAppType(responseData as TaskFromAppTasks)));
                            if (!responseData.endTime) {
                                observer.next(taskPotentialUsers.getTaskPotentialUsers(responseId));
                            }
                            if (action.claimIfUnassigned) {
                                if (!responseData.assignee) {
                                    observer.next(taskEventCreator.claimTask(responseId));
                                }
                            }
                            observer.complete();
                        },
                        errorsCbs: {
                            '*': () => {
                                observer.next(task.getTaskFailure(new Error('failed to fetch task')));
                                observer.complete();
                            },
                        },
                        appendExpansions: ['linkedEntity', 'taskAssignee', 'taskOwner', 'attendees'],
                    }),
                );
            });
            return obs;
        }),
    );
export default fetchPotentialUsersFlow;
