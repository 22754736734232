import ViewConfig from 'reducers/ViewConfigType';
import {
    getAllFieldEntriesFromView,
    isFieldViewField,
    getAdjustedFieldSource,
    getDataTypeForFieldExpr,
    isAddressVerification2Field,
    expandComponentFields,
} from 'components/generics/utils/viewConfigUtils';
import getAllFieldsWeWriteTo from 'fieldFactory/input/components/Address2/getAllFieldsWeWriteTo';

const mapFieldsToWidgets = (
    viewConfig: ViewConfig,
    viewName: string,
    adhocFieldsExpected: string[],
    printMode?: boolean,
) => {
    return expandComponentFields(
        viewConfig,
        getAllFieldEntriesFromView(viewConfig, viewName),
        viewConfig.views[viewName].entity,
        { rebaseExpressionsWithinFields: false, replaceXmanyWithMultiCard: printMode },
    )
        .expandedFieldsByRow.flat()
        .reduce(
            (prev, [viewFieldInstanceKey, vf]) => {
                // RefManys don't map onto fields in our form data, so we shouldn't include those.
                // We may have to find an alternative way of controlling visibilities for list data.
                if (isAddressVerification2Field(vf)) {
                    const source = viewFieldInstanceKey.endsWith('Code')
                        ? viewFieldInstanceKey
                        : viewFieldInstanceKey.endsWith('Id')
                        ? viewFieldInstanceKey.slice(0, -2) + 'Code'
                        : viewFieldInstanceKey + 'Code';
                    prev[source] = [source];
                    /**
                     * I would like to add fields configured in the widget as 'widgets', to prevent their values from being cleared out when the real widget is disabled.
                     */
                    getAllFieldsWeWriteTo(vf).forEach((f) => {
                        if (!prev[f]) {
                            prev[f] = [];
                        }
                        prev[f].push(source);
                    });

                    return prev;
                }
                if (
                    isFieldViewField(vf) &&
                    (getDataTypeForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST') !== 'REFMANY' ||
                        vf.widgetType === 'INLINE_MANY')
                ) {
                    const getAdjusted = getAdjustedFieldSource(viewConfig)(viewConfig.views[viewName]);
                    const backingField = getAdjusted(vf);
                    if (!prev[backingField]) {
                        prev[backingField] = [];
                    }

                    prev[backingField].push(vf.overrideId ? vf.overrideId : getAdjusted(vf, viewFieldInstanceKey));
                }
                return prev;
            },
            adhocFieldsExpected.reduce((prev, curr) => {
                prev[curr] = [curr]; // used to be = [];. Had to add current because fields NOT IN THE VIEW (fields from "getAdhocFieldsForView")
                // are not included as widgets in phase 2 above, and therefore were never getting added.
                return prev;
            }, {}),
        );
};

export default mapFieldsToWidgets;
