import { combineEpics } from 'redux-observable';
import allPotentialUsersEpic from 'bpm/potentialUsers/epic';
import crudCreateFlow from 'sideEffect/crud/create/epic';
import getOneFlow from 'sideEffect/crud/getOne/epic';
import deleteFlow from 'sideEffect/crud/delete/epic';
import updateFlow from 'sideEffect/crud/update/epic';
import crudGetListFlow from 'sideEffect/crud/getList/epic';
import getTaskEpic from 'bpm/task/epic';
import getTaskPotentialUsersEpic from 'bpm/taskPotentialUsers/epic';
import notifications from 'sideEffect/other/notificationEpic';
import loginFlow from 'auth/epic';
import viewConfigFlow, { reloadViewConfigFromLocalstorageFlow } from 'viewConfig/epic';
import processDefinitionsFlow from 'bpm/processDefinitions/epic';
import printTemplateFlow from 'printTemplate/epic';
import createTaskFlow from 'bpm/createTask/epic';
import getConceptAvailabilities from 'viewConfigCalculations/ConceptAvailabilityExpressions/epic';
import getActionButtonExps from 'viewConfigCalculations/actionButtonDisplayExpressions/epic';
import getProcessInstanceEpic from 'bpm/processInstance/epic';
import loadDashboardsEpic from 'dashboard2/dashboard-config/load-dashboards/epic';
import setPrimaryDashboardEpic from 'dashboard2/dashboard-config/user-primary-dashboard/set-primary-dashboard/epic';
import getPrimaryDashboardEpic from 'dashboard2/dashboard-config/user-primary-dashboard/get-primary-dashboard/epic';
import redirect401Flow from 'auth/redirectOn401epic';
import getStartFormFlow from 'bpm/start-form/get-start-form/epic';
import getPublicGlobalAlertsFlow from 'global-alerts/public/epic';
import getPrivateGlobalAlertsFlow from 'global-alerts/private/epic';
import impersonateUserFlow from 'impersonate/epic';
import getViewItemFiltersFlow from 'viewConfigCalculations/filterExpressions/epic';
import getTemplateExpressionsFlow from 'viewConfigCalculations/expressionTemplates/epic';
import fetchTaskFormFlow from 'bpm/task-form/epic';
import refreshTokenAfterNoRequestTimeFlow from 'reauth-interval-and-retries/check-last-request/epic';
import startProcessFlows from 'bpm/create-process-instance/epic';
import valuesetsNeededForFormEpic from 'workers/subsequentActionsCalculatedForForm/epic';
import persistProfilesFlow from 'auth/profiles/persistEpic';

export default combineEpics(
    persistProfilesFlow,
    getTemplateExpressionsFlow,
    getViewItemFiltersFlow,
    refreshTokenAfterNoRequestTimeFlow,
    fetchTaskFormFlow,
    impersonateUserFlow,
    reloadViewConfigFromLocalstorageFlow,
    printTemplateFlow,
    processDefinitionsFlow,
    loginFlow,
    viewConfigFlow,
    notifications,
    getTaskPotentialUsersEpic,
    getTaskEpic,
    allPotentialUsersEpic,
    crudCreateFlow,
    crudGetListFlow,
    getOneFlow,
    deleteFlow,
    updateFlow,
    createTaskFlow,
    getConceptAvailabilities,
    getActionButtonExps,
    getProcessInstanceEpic,
    loadDashboardsEpic,
    setPrimaryDashboardEpic,
    getPrimaryDashboardEpic,
    redirect401Flow,
    getStartFormFlow,
    getPublicGlobalAlertsFlow,
    getPrivateGlobalAlertsFlow,
    startProcessFlows,
    valuesetsNeededForFormEpic,
);
