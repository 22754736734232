import React, { useCallback, useState } from 'react';
import { IconButton, Card, CardContent, CardActions, Button, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Warning from '@material-ui/icons/Warning';
import Popup from 'components/Popup';
import { useDispatch } from 'react-redux';
import { crudUpdate } from 'sideEffect/crud/update/actions';
import Alert from '@material-ui/lab/Alert';
import { AjaxError } from 'rxjs/ajax';

type State =
    | {
          type: 'not_pending';
          error?: AjaxError;
      }
    | {
          type: 'pending';
      };
const RemoveChild = (props: {
    onRemove: () => void;
    record: { id: string; title: string; entityType: string };
    fieldToNull: string;
}) => {
    const [state, setState] = useState<State>({
        type: 'not_pending',
    });
    const fieldToNull = props.fieldToNull.endsWith('.id')
        ? props.fieldToNull.slice(0, -3) + 'Id'
        : props.fieldToNull.endsWith('Id')
        ? props.fieldToNull
        : `${props.fieldToNull}Id`;
    const { onRemove } = props;
    const { entityType: resource, id } = props.record;
    const dispatch = useDispatch();
    const handleDelete = useCallback(() => {
        setState({
            type: 'pending',
        });
        dispatch(
            crudUpdate({
                resource,
                data: {
                    id,
                    partialUpdate: true,
                    [fieldToNull]: null,
                },
                cb: () => {
                    setState({
                        type: 'not_pending',
                    });
                    setImmediate(() => {
                        onRemove();
                    });
                },
                errorsCbs: {
                    '*': (e) => {
                        setState({
                            type: 'not_pending',
                            error: e,
                        });
                    },
                },
            }),
        );
    }, [fieldToNull, id, resource, onRemove, dispatch]);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Popup<{
                id: string;
                title: string;
            }>
                renderDialogContent={({ optionalData, closeDialog }) => {
                    return (
                        <Card>
                            <CardContent>
                                Remove <b>{optionalData?.title}?</b>
                            </CardContent>
                            <CardActions>
                                <Button onClick={closeDialog}>Close</Button>
                                <Button
                                    disabled={state.type === 'pending'}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleDelete}
                                >
                                    Delete
                                    {state.type === 'pending' ? (
                                        <span>
                                            &nbsp;
                                            <CircularProgress style={{ width: 20, height: 20 }} />
                                        </span>
                                    ) : state.type === 'not_pending' && state.error ? (
                                        <span>
                                            &nbsp;
                                            <Warning color="error" />
                                        </span>
                                    ) : null}
                                </Button>
                            </CardActions>
                            {state.type === 'not_pending' && state.error ? (
                                <Alert severity="error">
                                    {state.error.status === 0 ? 'Network' : state.error.status} Error
                                </Alert>
                            ) : null}
                        </Card>
                    );

                    //<div>foo {optionalData?.id}</div>;
                }}
                renderToggler={({ openDialog }) => {
                    return (
                        <IconButton
                            aria-label={`Remove ${props.record.title || ''}`}
                            onClick={(e) => {
                                openDialog(props.record)();
                            }}
                        >
                            <Clear />
                        </IconButton>
                    );
                }}
            />
        </div>
    );
};

export default RemoveChild;
