import { saveAs } from 'file-saver';
const b64ToFile = (
    b64: string,
    filename: string,
    mime: string,
): { tag: 'file'; file: File } | { tag: 'blob'; blob: Blob; filename: string } => {
    let bstr = atob(b64),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    try {
        return { tag: 'file', file: new File([u8arr], filename, { type: mime }) };
    } catch (err) {
        // workaround for IE 11
        return { tag: 'blob', blob: new Blob([u8arr], { type: mime }), filename };
    }
};

export default b64ToFile;

export const saveFile = (file: { tag: 'file'; file: File } | { tag: 'blob'; blob: Blob; filename: string }) => {
    saveAs(file.tag === 'blob' ? file.blob : file.file, file.tag === 'blob' ? file.filename : file.file.name);
};
