import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { themeOverrideContext } from './ThemeOverrideProvider';
import * as colors from '@material-ui/core/colors';
import { FormControlLabel, Switch, Divider } from '@material-ui/core';
import CasetivitySelect from 'components/CasetivitySelect';
type Color = keyof typeof colors;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

interface EditThemePropertiesProps {}
const EditThemeProperties: React.FunctionComponent<EditThemePropertiesProps> = (props) => {
    const classes = useStyles();

    const { methods, darkMode, invertMenu, overrides, fieldVariant, forceLabelShrink, darknessOverrides } =
        React.useContext(themeOverrideContext);

    const handleColorChange = (event: React.ChangeEvent<{ name?: string; value: Color }>) => {
        const name = event.target.name as keyof typeof overrides;
        methods.set(name, event.target.value);
    };
    const handleDarknessChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        const name = event.target.name.split(':')[0] as keyof typeof darknessOverrides;
        methods.setDarkness(name, event.target.value);
    };
    const handleFieldVariantChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        methods.setFieldVariant(event.target.value);
    };
    return (
        <div style={{ margin: '1em' }}>
            <FormControlLabel
                control={<Switch checked={darkMode} onChange={methods.toggleDarkMode} name="darkMode" />}
                label="Dark Mode"
            />
            <FormControlLabel
                control={<Switch checked={invertMenu} onChange={methods.toggleInvertMenu} name="invertMenu" />}
                label="Invert menu colors"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={forceLabelShrink}
                        onChange={methods.toggleForceLabelShrink}
                        name="forceLabelShrink"
                    />
                }
                label="Shrunken label"
            />
            <FormControl variant={fieldVariant} className={classes.formControl}>
                <InputLabel shrink={forceLabelShrink} htmlFor={'fieldVariant'}>
                    Field Variant
                </InputLabel>
                <CasetivitySelect
                    native
                    value={fieldVariant || 'standard'}
                    onChange={handleFieldVariantChange}
                    label="Field Variant"
                    inputProps={{
                        name: 'fieldVariant',
                        id: 'fieldVariant',
                    }}
                >
                    {({ OptionComponent }) => [
                        <OptionComponent key="outlined" value="outlined">
                            Outlined
                        </OptionComponent>,
                        <OptionComponent key="standard" value="standard">
                            Standard
                        </OptionComponent>,
                        <OptionComponent key="filled" value="filled">
                            Filled
                        </OptionComponent>,
                    ]}
                </CasetivitySelect>
            </FormControl>
            <Divider style={{ margin: '2em' }} />
            {Object.keys(overrides).map((property) => {
                return (
                    <div key={property}>
                        {property && overrides[property] && (
                            <div
                                style={{
                                    marginTop: '1em',
                                    display: 'inline-block',
                                    height: 40,
                                    width: 40,
                                    backgroundColor: colors[overrides[property]][darknessOverrides[property] || 900],
                                }}
                            />
                        )}
                        <FormControl variant={fieldVariant} className={classes.formControl}>
                            <InputLabel shrink={forceLabelShrink} htmlFor={'select-' + property}>
                                {property}
                            </InputLabel>
                            <CasetivitySelect
                                variant={fieldVariant}
                                value={overrides[property]}
                                onChange={handleColorChange}
                                label={property}
                                inputProps={{
                                    name: property,
                                    id: 'select-' + property,
                                }}
                            >
                                {({ OptionComponent }) => [
                                    <option key="none" aria-label="None" value="" />,
                                    ...Object.keys(colors)
                                        .filter((c) => c !== 'common')
                                        .map((c) => {
                                            return (
                                                <OptionComponent key={c} value={c}>
                                                    {c}
                                                </OptionComponent>
                                            );
                                        }),
                                ]}
                            </CasetivitySelect>
                        </FormControl>
                        <FormControl variant={fieldVariant} className={classes.formControl}>
                            <InputLabel shrink={true} htmlFor={'select-darkness-' + property}>
                                {property} Darkness
                            </InputLabel>
                            <CasetivitySelect
                                variant={fieldVariant}
                                value={darknessOverrides[property]}
                                onChange={handleDarknessChange}
                                label={property}
                                inputProps={{
                                    name: property + ':darkness',
                                    id: 'select-darkness-' + property,
                                }}
                            >
                                {({ OptionComponent }) => [
                                    <OptionComponent key="none" aria-label="None" value="" />,
                                    ...Object.keys(colors.blue).map((c) => {
                                        return (
                                            <OptionComponent key={c} value={c}>
                                                {c}
                                            </OptionComponent>
                                        );
                                    }),
                                ]}
                            </CasetivitySelect>
                        </FormControl>
                    </div>
                );
            })}
        </div>
    );
};
export default EditThemeProperties;
