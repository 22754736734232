import React, { useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { ListboxComponent } from 'layout-editor/add-field/virtualize';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

type ViewTypeValue = 'EDIT' | 'SHOW' | 'CREATE' | 'LIST' | 'MATCH' | 'MERGE' | 'COMPONENT' | null;
interface ViewTypeSelectProps {
    defaultValue?: ViewTypeValue;
    fullWidth?: boolean;
    required?: boolean;
}

const ViewTypeSelect: React.SFC<ViewTypeSelectProps> = ({ fullWidth, defaultValue, required }) => {
    const { register, watch, unregister, setValue, errors, trigger } = useFormContext<{
        viewType: ViewTypeValue;
    }>();
    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    React.useEffect(() => {
        register({ name: 'viewType' }, required ? { required: 'A view type is required' } : undefined);
        return () => {
            unregister('viewType');
        };
    }, []); // eslint-disable-line

    const options: ViewTypeValue[] = useMemo(() => {
        return ['EDIT', 'SHOW', 'CREATE', 'LIST', 'MATCH', 'MERGE', 'COMPONENT'];
    }, []);
    const value = watch('viewType', defaultValue) as any as ViewTypeValue;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Autocomplete<ViewTypeValue>
            value={value}
            options={options}
            defaultValue={defaultValue}
            autoHighlight
            onChange={(e, value) => {
                handleChange(value, 'viewType');
                trigger();
            }}
            getOptionLabel={(option) => option}
            renderOption={(option) => option}
            style={!fullWidth ? { width: '193px' } : undefined}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={(params) => (
                <TextField
                    style={!fullWidth ? { width: '193px' } : undefined}
                    {...params}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    variant={fieldVariant}
                    label={'View Type' + (required ? ' *' : '')}
                    margin="normal"
                    fullWidth={fullWidth}
                    error={
                        Array.isArray(errors['viewType']) ? errors['viewType'].length > 0 : Boolean(errors['viewType'])
                    }
                    helperText={<ErrorMessage errors={errors} name="viewType" />}
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

export default ViewTypeSelect;
