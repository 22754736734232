import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import ViewConfig from 'reducers/ViewConfigType';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { getRefEntityName, isValidEntityFieldExpression } from 'components/generics/utils/viewConfigUtils';

export type Result = {
    valuesetFieldsRequired: {
        [field: string]: string;
    };
    expression: string;
    valuesetLiterals: string[];
    dataPaths: string[];
    expansionsRequired: string[];
};
export const parseTemplateString = (
    templateString: string,
    viewConfig: ViewConfig,
    rootEntity: string, // entity we are on, not the entity we are rebasing onto.
    rebaseOntoRootPath?: string,
): Result => {
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    // we will add in these at the end, since it's better for them to be accumulated in sets, and added at the end
    const res: Omit<Result, 'expression' | 'dataPaths' | 'expansionsRequired' | 'valuesetLiterals'> = {
        valuesetFieldsRequired: {},
    };
    const expansionsSet = new Set<string>();
    const dataPathsSet = new Set<string>();
    const valuesetLiteralsSet = new Set<string>();

    const parseForFields = (str: string) => {
        return parse(str).fold(str, ({ before, inner, after }) => {
            const impl = getImpl();
            let expr = inner;
            let compiled = impl.compileExpression(inner);
            if (compiled.type === 'parse_failure') {
                throw new Error(compiled.msg);
            }
            if (rebaseOntoRootPath) {
                const rebasedEntity = getRefEntityName(viewConfig, rootEntity, rebaseOntoRootPath, 'TRAVERSE_PATH');
                compiled = compiled.rebase
                    ? compiled.rebase((path) => {
                          // Verify path
                          if (isValidEntityFieldExpression(viewConfig, rebasedEntity, path)) {
                              return [rebaseOntoRootPath];
                          }
                          return [];
                      })
                    : compiled;
                expr = compiled.prettyPrint();
            }
            const expansionsRequired = compiled.getExpansionsWithAll();
            expansionsRequired.forEach((exp) => {
                expansionsSet.add(exp);
            });
            compiled.getPathsWithAll().forEach((path) => {
                dataPathsSet.add(path);
            });
            compiled.getValueSetLiterals().forEach((vsc) => {
                valuesetLiteralsSet.add(vsc);
            });
            const loadValuesetFieldsRequired = (expansionsRequired: string[]) => {
                setupValuesetFieldsRequired(
                    viewConfig,
                    rootEntity,
                    expr,
                )({ expansionsRequired }).fold(
                    (error) => {
                        console.error('Failed to get Valuesets needed', error);
                    },
                    ({ valuesetFieldsRequired }) => {
                        res.valuesetFieldsRequired = {
                            ...res.valuesetFieldsRequired,
                            ...valuesetFieldsRequired,
                        };
                    },
                );
            };
            loadValuesetFieldsRequired(expansionsRequired);

            return `${before ?? ''}$[${expr}]${after ? parseForFields(after) : ''}`;
        });
    };
    const expression = parseForFields(templateString);

    const result: Result = {
        ...res,
        expression,
        dataPaths: Array.from(dataPathsSet),
        expansionsRequired: Array.from(expansionsSet),
        valuesetLiterals: Array.from(valuesetLiteralsSet),
    };
    return result;
};
