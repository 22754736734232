import React, { Suspense } from 'react';
import type { MuiVirtualizedTableProps } from './VirtualizedTableComponent';
const VirtualizedTableComponent = React.lazy(() => import('./VirtualizedTableComponent'));

const VirtualizedTable = <RowData extends unknown>(props: MuiVirtualizedTableProps<RowData>) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <VirtualizedTableComponent {...props} />
        </Suspense>
    );
};
export default VirtualizedTable;
