import * as React from 'react';
import Add from '@material-ui/icons/Add';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import CreateTaskForm from './CreateTaskForm';
import useProcessIsOpen from '../hooks/useProcessIsOpen';
import { useGetProcessConf } from 'bpm/hooks/useGetProcessConfItem';

type PopoverCreateTaskProps = {
    processId: string | null;
};

const PopoverCreateTask: React.FunctionComponent<PopoverCreateTaskProps> = ({ processId }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);
    const processIsOpen = useProcessIsOpen(processId);
    const conf = useGetProcessConf(processId);
    const show = conf.isSome() ? !conf.value?.listActivitiesInline : true;
    if (!processIsOpen || !show) {
        return null;
    }
    return (
        <span>
            <Button variant="text" color="primary" onClick={handleOpen}>
                Create Task
                <Add />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <DialogContent>
                    <CreateTaskForm processId={processId} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </span>
    );
};

export default PopoverCreateTask;
