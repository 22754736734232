import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { isPlainObject } from 'lodash';

const parse = getNextBracketedExpression({
    bracketClosesOn: '}',
    bracketNaivelyOpensOn: '{',
    bracketOpensOn: '${',
});

export const templateValues = (obj: unknown, values: { [expression: string]: string }): unknown => {
    if (typeof obj === 'string') {
        return templateValuesIntoExpressions(obj, values);
    }
    if (isPlainObject(obj)) {
        return Object.entries(obj).reduce((prev, [key, val]) => {
            prev[key] = templateValues(val, values);
            return prev;
        }, {});
    }
    if (Array.isArray(obj)) {
        return obj.map((v) => templateValues(v, values));
    }
    return obj;
};

const templateValuesIntoExpressions = (template: string, values: { [expression: string]: string }) => {
    const evalString = (str: string) =>
        parse(str).fold(str, ({ before, inner, after }) => {
            return before + values[inner] + evalString(after);
        });
    return evalString(template);
};
