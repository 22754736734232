import * as WidgetTypes from '../../../components/generics/utils/widgetTypes';
import * as fieldTypes from '../../fieldTypes';
import * as FieldDataType from '../../../components/generics/utils/fieldDataTypes';
import {
    EntityDataField,
    EntityEventField,
    EntityAddressVerificationField,
    EntityRefManyField,
    EntityMultiCardField,
    EntityInlineManyField,
    EntityAddressVerification2Field,
} from '../types/index';
import { validDataTypeWidgetCombinations } from '../../../../src/viewConfigValidation/validWidgetCombinations';
import { searchTypes } from 'reducers/ViewConfigType';

const getTypeFromEntityField = (
    widgetType: (typeof WidgetTypes)[keyof typeof WidgetTypes],
    dataType: (typeof FieldDataType)[keyof typeof FieldDataType] | undefined,
    name: string = '',
    searchType: searchTypes = undefined,
    turnTextBoxToFloatOrIntBasedOnDataType: boolean = true,
):
    | EntityDataField['type']
    | EntityAddressVerificationField['type']
    | EntityAddressVerification2Field['type']
    | EntityRefManyField['type']
    | EntityInlineManyField['type']
    | EntityMultiCardField['type']
    | EntityEventField['type'] => {
    // skip on undefined dataType (happens in processConfig field)

    if (
        (!searchType || searchType !== 'NOT_EMPTY') &&
        dataType &&
        validDataTypeWidgetCombinations[dataType].indexOf(widgetType) === -1
    ) {
        throw new Error(`Field ${name} has an invalid dataType + widget combination.
        widgetType:${widgetType}, dataType: ${dataType}`);
    }
    const t = fieldTypes;
    switch (widgetType) {
        case WidgetTypes.COMPONENT:
            return t.COMPONENT;
        case WidgetTypes.INLINE_MANY:
            return t.INLINE_MANY;
        case WidgetTypes.JSONSCHEMA_FORM_BUILDER:
            return t.JSONSCHEMA_FORM_BUILDER;
        case WidgetTypes.JSONSCHEMA_FORM:
            return t.JSONSCHEMA_FORM;
        case WidgetTypes.MULTI_CARD:
            if (dataType === FieldDataType.REFMANYMANY) {
                return t.MANYMANY_MULTI_CARD;
            }
            return t.MULTI_CARD;
        case WidgetTypes.IMAGE:
            return t.IMAGE;
        case WidgetTypes.VALIDATION_EXP_EDITOR:
            return t.VALIDATION_EXPRESSION_EDITOR;
        case WidgetTypes.ADDRESS:
            return t.ADDRESS_VERIFICATION;
        case WidgetTypes.ADDRESS_2:
            return t.ADDRESS_VERIFICATION_2;
        case WidgetTypes.TEXTBOX:
            if (turnTextBoxToFloatOrIntBasedOnDataType) {
                if (dataType === FieldDataType.FLOAT) {
                    return t.FLOAT;
                }
                if (dataType === FieldDataType.INTEGER || dataType === FieldDataType.BIGDECIMAL) {
                    return t.INTEGER;
                }
            }
            return t.TEXT;
        case WidgetTypes.TOGGLE:
            return t.TOGGLE;
        case WidgetTypes.TEXTAREA:
            return t.MULTILINE_TEXT;
        case WidgetTypes.FLOAT: // no longer exists?
            return t.FLOAT;
        case WidgetTypes.INTEGER:
            return t.INTEGER;
        case WidgetTypes.CHECKBOX:
            if (dataType === FieldDataType.VALUESET_MANY) {
                return t.VALUESET_MULTICHECKBOX;
            } else if (dataType === FieldDataType.VALUESET) {
                return t.VALUESET_CHECKBOX;
            } else {
                return t.CHECKBOX;
            }
        case WidgetTypes.CALENDAR:
            if (dataType === 'INSTANT') {
                return t.UST_DAY;
            }
            return t.DATE;
        case WidgetTypes.CALENDARTIME:
            return t.DATETIME;
        case WidgetTypes.FILEUPLOAD:
            return t.FILE_UPLOAD;
        case WidgetTypes.EMAIL:
            return t.EMAIL;
        case WidgetTypes.SELECT: {
            if (dataType === FieldDataType.REFONE_JOIN) {
                return t.REFONE_JOIN_SELECT;
            } else if (dataType === FieldDataType.REFONE) {
                // if has parent backref - runtime decision
                return t.REFONE_SELECT;
            } else if (dataType === FieldDataType.VALUESET) {
                return t.VALUESET_SELECT;
            }
            throw new Error(`SELECT type must be either a REFONE or VALUESET.
                field: ${name}
                widgetType: ${widgetType}, dataType: ${dataType}`);
        }
        case WidgetTypes.MULTISELECT: {
            if (dataType === FieldDataType.VALUESET_MANY) {
                return t.VALUESET_MULTISELECT;
            } else if (dataType === FieldDataType.REFMANY) {
                return t.REFMANY_MULTISELECT;
            } else if (dataType === FieldDataType.REFMANY_JOIN) {
                return t.REFMANY_JOIN_MULTISELECT;
            } else if (dataType === FieldDataType.REFMANYMANY) {
                return t.REFMANY_MULTISELECT_IDSLIST;
            } else if (dataType === FieldDataType.VALUESET) {
                // this makes sense in the context of a 'in' search
                return t.VALUESET_MULTISELECT;
            }
            throw new Error(`MULTISELECT type must be either a REFMANY, REFMANYJOIN or VALUESETMANY.
                field: ${name}
                widgetType: ${widgetType}, dataType: ${dataType}`);
        }
        case WidgetTypes.ZONE_DATE:
            return t.ZONE_DATE;
        /*
        case WidgetTypes.RADIO: { // add radio for special case
            return t.RADIO;
        }
        Radio not implemented for entities
        */
        case WidgetTypes.FORCE_IDS_LIST: {
            // this option exists in order to coerce the idslist by changing WidgetTypes
            // (dataTypes is pulled from viewConfig - cannot be altered in a nice way)
            return t.REFMANY_MULTISELECT_IDSLIST;
        }
        case WidgetTypes.FORCE_ONEOF: {
            return t.ONEOF;
        }
        case WidgetTypes.CURRENCY: {
            return t.CURRENCY;
        }
        case WidgetTypes.EVENT: {
            return t.EVENT;
        }
        case WidgetTypes.ENTITY_CHIP: {
            return t.REFMANYMANY_CHIP;
        }
        case WidgetTypes.PERCENT: {
            return t.PERCENT;
        }
        case WidgetTypes.VALUESET_SUGGEST: {
            return t.VALUESET_SUGGEST;
        }
        case WidgetTypes.ENTITY_TYPEAHEAD: {
            return t.ENTITY_TYPEAHEAD;
        }
        case WidgetTypes.MULTIPLE_ENTITY_TYPEAHEAD: {
            return t.MULTIPLE_ENTITY_TYPEAHEAD;
        }
        case WidgetTypes.DMSDOC: {
            return t.DMS_DOCUMENT;
        }
        case WidgetTypes.WYSIWYG: {
            return t.WYSIWYG;
        }
        case WidgetTypes.NULLABLE_BOOLEAN: {
            return t.NULLABLE_BOOLEAN;
        }
        default: {
            throw new Error(`Matching field type not found...
                field: ${name}
                widgetType: ${widgetType}, dataType: ${dataType}`);
        }
    }
};

export default getTypeFromEntityField;
