import merge from 'lodash/merge';
import set from 'lodash/set';
import get from 'lodash/get';
import { Ast } from 'ts-spel/lib-esm/lib/Ast';

/**
 * Build out recursive object of paths
 *
 *
 *
 */
export type ExpansionRepresentation = {
    [key: string]: ExpansionRepresentation;
};

const getFieldPathsFromTsSpelAST = (node: Ast, treatSelectionAsAll = false) => {
    const innerGetFieldPathsFromTsSpelAST = (node: Ast, currentThis: string[] = []): ExpansionRepresentation => {
        // TODO handle #this and #root
        const traverseChild = (n) => {
            return innerGetFieldPathsFromTsSpelAST(n, currentThis);
        };
        switch (node.type) {
            case 'PropertyReference':
                return {
                    [node.propertyName]: {},
                };
            case 'VariableReference':
            case 'NumberLiteral':
            case 'NullLiteral':
            case 'BooleanLiteral':
            case 'StringLiteral':
                return {};
            case 'CompoundExpression':
                const [firstNode, ...rest] = node.expressionComponents;
                if (!firstNode) {
                    return {};
                }
                let rootPath: string[] = [];
                let restOfPropertiesAfterRoot = node.expressionComponents;
                if (firstNode.type === 'VariableReference' && firstNode.variableName === 'this') {
                    rootPath = currentThis;
                    restOfPropertiesAfterRoot = rest;
                }
                if (firstNode.type === 'VariableReference' && firstNode.variableName === 'root') {
                    rootPath = [];
                    restOfPropertiesAfterRoot = rest;
                }
                // trunk meaning chains of properties
                // remainingNodes meaning anything to the right of the root chain.
                const [trunkPath, offshootPaths] = restOfPropertiesAfterRoot.reduce(
                    (prev, curr) => {
                        const [_trunk, offshoots, knowWhereWeAre] = prev;
                        const localThis = rootPath.concat(_trunk);
                        if (!knowWhereWeAre) {
                            // there's no going back once we leave the knowable path off trunk.
                            if (curr.type === 'PropertyReference') {
                                // ignore properties since we are off the path where we know
                                return prev;
                            }
                            return [_trunk, merge(offshoots, innerGetFieldPathsFromTsSpelAST(curr, localThis)), false];
                        }
                        if (curr.type === 'PropertyReference') {
                            // we are still on the trunk
                            return [_trunk.concat(curr.propertyName), offshoots, true];
                        }
                        switch (curr.type) {
                            case 'SelectionAll':
                            case 'SelectionFirst':
                            case 'SelectionLast':
                                // with these, we actually can know where we are when we continue with properties forwards.
                                // but in some scenarios, we want to know there's an expansion on all of them.
                                return [
                                    treatSelectionAsAll ? _trunk.concat('_ALL_') : _trunk,
                                    merge(
                                        offshoots,
                                        innerGetFieldPathsFromTsSpelAST(
                                            curr,
                                            treatSelectionAsAll ? localThis.concat('_ALL_') : localThis,
                                        ),
                                    ),
                                    true,
                                ];
                            case 'Indexer': {
                                // with these, we actually can know where we are when we continue with properties forwards.
                                return [
                                    _trunk.concat('_ALL_'),
                                    merge(offshoots, innerGetFieldPathsFromTsSpelAST(curr, localThis)),
                                    true,
                                ];
                            }
                            default:
                                return [
                                    _trunk,
                                    merge(offshoots, innerGetFieldPathsFromTsSpelAST(curr, localThis)),
                                    false,
                                ];
                        }
                    },
                    [[], {}, true] as [string[], ExpansionRepresentation, boolean],
                );
                //  most of the logic is going to go in here.
                /**
                 * lets continue along properties,
                 * then acc anything inside of selection/index, etc.
                 */

                if (!get(offshootPaths, rootPath.concat(trunkPath))) {
                    set(offshootPaths, rootPath.concat(trunkPath), {});
                }
                return offshootPaths;
            case 'Elvis':
                return merge(traverseChild(node.expression), traverseChild(node.ifFalse));
            case 'MethodReference':
            case 'FunctionReference':
                return node.args.map((n) => traverseChild(n)).reduce((prev, curr) => merge(prev, curr), {});
            case 'Indexer':
                return traverseChild(node.index);
            case 'InlineList':
                return node.elements.map((n) => traverseChild(n)).reduce((prev, curr) => merge(prev, curr), {});
            case 'InlineMap':
                return Object.values(node.elements)
                    .map((n) => traverseChild(n))
                    .reduce((prev, curr) => merge(prev, curr), {});
            case 'Negative':
                return traverseChild(node.value);
            case 'OpAnd':
            case 'OpBetween':
            case 'OpDivide':
            case 'OpEQ':
            case 'OpGE':
            case 'OpGT':
            case 'OpLE':
            case 'OpLT':
            case 'OpMatches':
            case 'OpMinus':
            case 'OpModulus':
            case 'OpMultiply':
            case 'OpNE':
            case 'OpOr':
            case 'OpPlus':
                return merge(traverseChild(node.left), traverseChild(node.right));
            case 'OpNot':
            case 'Projection':
            case 'SelectionAll':
            case 'SelectionFirst':
            case 'SelectionLast':
                return traverseChild(node.expression);
            case 'OpPower':
                return merge(traverseChild(node.base), traverseChild(node.expression));
            case 'Ternary':
                return [traverseChild(node.expression), traverseChild(node.ifFalse), traverseChild(node.ifTrue)].reduce(
                    (prev, curr) => merge(prev, curr),
                    {},
                );
        }
    };
    return innerGetFieldPathsFromTsSpelAST(node);
};
export default getFieldPathsFromTsSpelAST;
