import { Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import useWidth from 'util/hooks/useWidth';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        card: {
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            padding: '.5em 1em',
            borderRadius: 0,
            borderLeft: '8px solid ' + theme.palette.secondary.main,
            borderRight: '8px solid ' + theme.palette.secondary.main,
            borderBottom: '1px solid grey',
        },
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular as any,
        },
    }),
);

const CurrentProfileBanner: React.FC<{}> = () => {
    const breakpoint = useWidth();
    const classes = useStyles();
    const profiles = useSelector((state: RootState) => state.profiles);
    const currProfileName = useMemo(() => {
        if (profiles.state === 'profile_selected') {
            return profiles.profiles.find((p) => p.userId === profiles.currentUserId)?.name;
        }
    }, [profiles]);
    if (!currProfileName || breakpoint === 'xs') {
        return null;
    }
    return (
        <section className={classes.root} aria-label="profile status">
            <Card key="impersonate-back-to-user" className={classes.card}>
                <Typography className={classes.heading}>Profile: {currProfileName}</Typography>
            </Card>
        </section>
    );
};
export default CurrentProfileBanner;
