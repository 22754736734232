import React from 'react';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { Checkbox, FormControl, FormHelperText } from '@material-ui/core';
import FormControlLabel from 'fieldFactory/input/components/mui/FormControlLabel';
import Done from '@material-ui/icons/Done';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import uniqueId from 'lodash/uniqueId';
import useWarningOrErrorUtils from 'fieldFactory/input/hooks/useWarningOrErrorUtils';

const hideIfNoInput = branch(
    (props) => !props.input, // input is not provided
    renderNothing,
);

const CheckBox = ({ input, label, meta, disabled, htmlId, unlinkVisualLabel, overrideAriaLabel }) => {
    const { helperText, muiErrorProp, textErrorClass } = useWarningOrErrorUtils(meta);
    const inputId = React.useRef(htmlId ?? uniqueId('checkboxInputId'));
    const ariaErrormessageRef = React.useRef(uniqueId('checkboxErrorMsg'));
    const InputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        id: inputId.current,
        'aria-label': overrideAriaLabel ?? label,
        'aria-invalid': Boolean(meta.touched && meta.error),
        'aria-describedby': muiErrorProp ? ariaErrormessageRef.current : undefined,
    };
    return (
        // don't make this full width! It just cases whitespace to be clickable.
        <FormControl margin="none" style={{ marginTop: '.5em ' }} error={muiErrorProp}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!input.value}
                        inputProps={InputProps}
                        onChange={(e, checked) => input.onBlur(checked)}
                        checkedIcon={<Done style={{ fontSize: '1.875em', width: 24, height: 24 }} />}
                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '0.875em', width: 24, height: 24 }} />}
                    />
                }
                label={label}
                htmlFor={unlinkVisualLabel ? null : inputId.current}
                disabled={disabled}
                style={{ fontSize: '0.875em' }}
            />

            {muiErrorProp && (
                <FormHelperText
                    classes={{ error: textErrorClass }}
                    aria-live="assertive"
                    id={InputProps['aria-describedby']}
                >
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default hideIfNoInput(CheckBox);
