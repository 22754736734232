import userPrimaryDashboard from './user-primary-dashboard/reducer';
import dashboards from './load-dashboards/reducer';
import currentDashboard from './current-dashboard/reducer';
import { combineReducers } from 'redux';

const dashboardReducer = combineReducers({
    userPrimaryDashboard,
    dashboards,
    currentDashboard,
});
export default dashboardReducer;
