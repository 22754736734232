import React from 'react';
import LayoutRoot from './LayoutRoot';
import useResourcesAndViews from './hooks/useResourcesAndViews';
import AdminRoutes from 'routes/AdminRoutes';
import customRoutes from 'routes/customRoutes';
import DashboardPage from 'dashboard2/components/DashboardPage';
import useDebugFeaturesAreEnabled from 'util/hooks/useDebugFeaturesAreEnabled';
import StyledSnackbarProvider from './SnackbarProvider';
import useHasTaskDrawer from './hooks/useHasTaskDrawer';
import Notifier from 'notistack/components/Notifier';
import TaskDrawerWrapper from 'bpm/components/TaskDrawer/TaskDrawer';
import NavWrapper from './NavWrapper';
import { registerRoutesElement } from 'templatePage/components';
import useOverrideHomepageComponent from './hooks/useOverrideHomepageElement';
import SelectProfilePrompt from 'auth/profiles/components/SelectProfilePrompt';

export const Routes: React.FC<{}> = (props) => {
    const { resources, nonDefaultResourceViews } = useResourcesAndViews();
    const Homepage = useOverrideHomepageComponent() ?? DashboardPage;
    return (
        <AdminRoutes
            customRoutes={customRoutes}
            resources={resources}
            nonDefaultResourceViews={nonDefaultResourceViews}
            dashboard={Homepage}
        />
    );
};
registerRoutesElement['element'] = Routes;
const Layout = (props) => {
    const adminRoutes = <Routes />;
    const debugMode = useDebugFeaturesAreEnabled();
    const hasTaskDrawer = useHasTaskDrawer();
    return (
        <LayoutRoot>
            <NavWrapper>
                <StyledSnackbarProvider>
                    <React.Fragment>
                        <SelectProfilePrompt />
                        {debugMode && <Notifier />}
                        <div>{hasTaskDrawer ? <TaskDrawerWrapper>{adminRoutes}</TaskDrawerWrapper> : adminRoutes}</div>
                    </React.Fragment>
                </StyledSnackbarProvider>
            </NavWrapper>
        </LayoutRoot>
    );
};

export default Layout;
