import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';

const emptyObj = {};

const getFilterFromFilterString = (filterString?: string): {} => {
    // we need to handle cases like:
    // firstName=$[personId ? (lookupEntityData('Person', personId, 'firstName') || '*') : '*']
    // where when we split by commas to get key value pairs, we have:
    // [
    //    firstName=$[personId ? (lookupEntityData('Person',
    //    personId,
    //    'firstName') || '*') : '*']
    // ]
    // which obviously isn't correct
    const parse = getNextBracketedExpression({
        bracketClosesOn: ']',
        bracketNaivelyOpensOn: '[',
        bracketOpensOn: '$[',
    });
    const COMMA_ESCAPE = '|||_comma_|||';
    const EQUALS_ESCAPE = '|||_equal_|||';
    const SEMICOLON_ESCAPE = '|||_semicolon_|||';
    const escape = (str: string) =>
        parse(str).fold(str, ({ before, inner, after }) => {
            const escapedInner = inner
                .split(',')
                .join(COMMA_ESCAPE)
                .split('=')
                .join(EQUALS_ESCAPE)
                .split(';')
                .join(SEMICOLON_ESCAPE);
            // console.log('escapedInner', escapedInner);
            return `${before}${escapedInner}${escape(after)}`;
        });
    if (filterString) {
        // console.log('escaped: ', escape(filterString as string));
        return Object.assign(
            {},
            ...escape(filterString as string)
                .split(',')
                .map((kvpair) => {
                    const [key, value] = kvpair.split('=');
                    return {
                        [key]: value
                            .split(new RegExp(';', 'g'))
                            .join(',')
                            .split(COMMA_ESCAPE)
                            .join(',')
                            .split(EQUALS_ESCAPE)
                            .join('=')
                            .split(SEMICOLON_ESCAPE)
                            .join(';'),
                    };
                }),
        );
    }
    return emptyObj;
};
export default getFilterFromFilterString;
