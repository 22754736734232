function areWeTestingWithJest() {
    return process.env.JEST_WORKER_ID !== undefined;
}
function wait<T>(ms: number, value?: T) {
    return new Promise<T>((resolve) => setTimeout(resolve, ms, value));
}
const fakeGetDB = <T = any>(dbName: string, version: number, objectStoreName: string) => {
    let dbInstance: {
        [key: string]: T;
    } = {};
    return {
        get: async (key: string) => {
            await wait(10);
            return dbInstance[key] ?? null;
        },
        keys: async () => {
            await wait(10);
            return Object.keys(dbInstance);
        },
        set: async (key: string, value: T) => {
            await wait(10);
            dbInstance[key] = value;
        },
        delete: async (key: string) => {
            await wait(10);
            delete dbInstance[key];
        },
        __deleteDbInstance() {
            dbInstance = {};
        },
    };
};

const _getDB = <T = any>(dbName: string, version: number, objectStoreName: string) => {
    let dbInstance;

    function getDB() {
        if (dbInstance) return dbInstance;

        dbInstance = new Promise((resolve, reject) => {
            const openreq = globalThis.indexedDB.open(dbName, version);

            openreq.onerror = () => {
                reject(openreq.error);
            };

            openreq.onupgradeneeded = () => {
                // First time setup: create an empty object store
                openreq.result.createObjectStore(objectStoreName);
            };

            openreq.onsuccess = () => {
                resolve(openreq.result);
            };
        });

        return dbInstance;
    }

    async function withStore(type, callback) {
        const db = await getDB();
        return new Promise<void>((resolve, reject) => {
            const transaction = db.transaction(objectStoreName, type);
            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);
            callback(transaction.objectStore(objectStoreName));
        });
    }

    return {
        async get(key: string): Promise<T> {
            let request;
            await withStore('readonly', (store) => {
                request = store.get(key);
            });
            return request.result;
        },
        async keys(): Promise<Array<string>> {
            let request;
            await withStore('readonly', (store) => {
                request = store.getAllKeys();
            });
            return request.result;
        },
        set(key: string, value: T): Promise<void> {
            return withStore('readwrite', (store) => {
                store.put(value, key);
            });
        },
        delete(key: string): Promise<void> {
            return withStore('readwrite', (store) => {
                store.delete(key);
            });
        },
        // added for resetting tests
        __deleteDbInstance() {
            dbInstance = null;
        },
    };
};

const getDB = areWeTestingWithJest() ? fakeGetDB : _getDB;
export default getDB;
