import * as t from 'io-ts';
import { right, left, Either } from 'fp-ts/lib/Either';
import { fromEither } from 'fp-ts/lib/EitherT';
import { array } from 'fp-ts/lib/Array';
import memoizeOne from 'memoize-one';
import runtimeValidatedJSONParse from '../../util/runtimeValidatedJSONParse';
import { curriedEvalExpression } from '../evaluate';
import { EvaluationOptions } from './definitions';

const formValidationConfig = t.array(
    t.intersection([
        t.type({
            expression: t.string,
            message: t.string,
        }),
        t.partial({
            level: t.string,
        }),
    ]),
);

type configType = { expression: string; message: string; level?: string };
export type formValidationConfigType = configType[];

/*
    returns an array of
    1. failures due to internal errors (we want to keep these to alert the user)    as Left(Error)
    2. messages (we want to display these)                                          as Right(string)
*/

const stripHashes = (expr) => expr.split('#').join('');
const stripHashesFromConfigExpression = (config: configType): configType => ({
    ...config,
    expression: stripHashes(config.expression),
});

export const processConfig =
    (options: EvaluationOptions = {}) =>
    (config: configType) => {
        let newConfig = { ...config };
        if (options.stripHashes !== false) {
            newConfig = stripHashesFromConfigExpression(newConfig);
        }
        return newConfig;
    };

// TODO lets get rid of all this.
export const evaluateFormValidationConfig = (
    fvc: formValidationConfigType,
    context: {},
    localVariablesAndMethods: {},
    options?: EvaluationOptions,
): Either<Error, string>[] =>
    fvc
        .map(processConfig(options))
        .flatMap(({ expression, message }) =>
            fromEither(array)(
                // 'options' are pretty much just used for stripHashes right now.
                // If we have 'false' then lets make sure we pass it below here, as well as above
                curriedEvalExpression(expression, options?.stripHashes ? {} : options)
                    .chain((f) => f(context, localVariablesAndMethods))
                    .map((result): string | null | false | 0 => result && message),
            ),
        )
        .flatMap((e) =>
            e.fold(
                (err) => [left<Error, string>(err)],
                (messageOrResult) => (messageOrResult ? [right<Error, string>(messageOrResult)] : []),
            ),
        );
export const getMemoizedEvaluateFormValidationConfig = () => memoizeOne(evaluateFormValidationConfig);

export const getValidationFromConfig = (json: string) =>
    runtimeValidatedJSONParse<formValidationConfigType>(formValidationConfig)(json);

export const getMemoizedGetValidationFromConfig = () => memoizeOne(getValidationFromConfig);
