// This will set up any missing Id fields with null, missing Ids fields with empty arrays, and missing string fields with empty strings.

import ViewConfig from 'reducers/ViewConfigType';
import { getAllFieldsFromView, isFieldViewField } from './viewConfigUtils';
import traversePath, { isOneReference } from './viewConfigUtils/traversePath';
import set from 'lodash/set';
// This will miss some data views might require, such as id, entityVersion, entityType, etc.
// This is just backup to ensure missing data is present in the form if initialization fails, e.g.
// { ...getEmptyInitializedViewData(viewConfig, viewName), ...initialData }

const getEmptyInitializedViewData = (viewConfig: ViewConfig, viewName: string) => {
    const view = viewConfig.views[viewName];
    if (!view) {
        throw new Error('view "' + viewName + '" not found.');
    }
    const fields = getAllFieldsFromView(viewConfig, viewName);
    let data = {};
    fields.forEach((f) => {
        if (isFieldViewField(f)) {
            const backingFieldInModelTraverse = traversePath(viewConfig)()(view.entity, f.field, false);
            if (backingFieldInModelTraverse._t === 'completed') {
                const { field } = backingFieldInModelTraverse;
                if (field.expensive) {
                    return;
                }
                if (field.dataType === 'ANYBLOB' || field.dataType === 'REFMANY') {
                    return;
                }
                if (isOneReference(field) || field.dataType === 'VALUESET') {
                    set(data, f.field + 'Id', null);
                } else if (field.dataType === 'REFMANYMANY' || field.dataType === 'VALUESETMANY') {
                    set(data, f.field + 'Ids', []);
                } else if (field.dataType === 'STRING' || field.dataType === 'TEXTBLOB') {
                    set(data, f.field, '');
                } else {
                    set(data, f.field, null);
                }
            }
        }
    });
    return data;
};
export default getEmptyInitializedViewData;
