/*
    The purpose of this is to traverse a field path, in an abstract way, so we can check any number of properties we want.
    TODO: This will be a hot path the more we use it, so it can be refactored for performance.
    TODO:
*/

import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import range from 'lodash/range';
import { EntityField } from 'reducers/ViewConfigType';

export function isString(data: undefined | string): data is string {
    return typeof data === 'string';
}
export const dataTypeIsManyReference = (dataType: EntityField['dataType']) => {
    const types: EntityField['dataType'][] = ['REFMANY', 'REFMANYJOIN', 'REFMANYMANY', 'VALUESETMANY'];
    return types.includes(dataType);
};
export function isManyReference(entityField: EntityField) {
    return dataTypeIsManyReference(entityField.dataType);
}
export const dataTypeIsOneReference = (dataType: EntityField['dataType']) => {
    const types: EntityField['dataType'][] = ['REFONE', 'REFONEJOIN', 'VALUESET'];
    return types.includes(dataType);
};
export function isOneReference(entityField: EntityField) {
    return dataTypeIsOneReference(entityField.dataType);
}
export function isValueSet(entityField: EntityField) {
    return entityField.dataType === 'VALUESET' || entityField.dataType === 'VALUESETMANY';
}
type Stopped = {
    _t: 'stopped';
    field: EntityField;
    atPath: string;
    pathBefore: string;
};
type Completed = {
    _t: 'completed';
    field: EntityField;
};
type Failed = {
    _t: 'failed';
    msg: string;
    pathBefore: string;
};
const traversePath =
    <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig) =>
    (
        processNode?: (arg: {
            isLast: boolean;
            isFirst: boolean;
            field: EntityField;
            isManyReference: boolean;
            isOneReference: boolean;
            isValueset: boolean;
        }) => void | 'stop' | 'continue', // if 'stop' returned, short-circuit the traversal
    ) => {
        function rfn(_entity: string, _fieldExpr: string, shouldThrow: false): Stopped | Completed | Failed;
        function rfn(_entity: string, _fieldExpr: string, shouldThrow: true): Stopped | Completed;
        function rfn(_entity: string, _fieldExpr: string, shouldThrow: boolean): Stopped | Completed | Failed {
            function handleError(msg: string, pathBefore: string): Failed {
                if (shouldThrow) {
                    throw new Error(msg);
                } else {
                    return {
                        _t: 'failed',
                        msg,
                        pathBefore,
                    };
                }
            }
            const accumulate = (
                viewConfig: ViewConfig,
                entity: string,
                fieldExpr: string,
                traversedPathSoFar: string,
            ): Stopped | Completed | Failed => {
                if (!viewConfig.entities[entity]) {
                    return handleError('Entity not found: ' + entity, traversedPathSoFar);
                }
                const viewFieldRefPath = fieldExpr.split('.');
                const isLast = viewFieldRefPath.length === 1;
                const field = viewFieldRefPath[0];
                const atPath = traversedPathSoFar ? traversedPathSoFar + '.' + field : field;
                const entityField = viewConfig.entities[entity].fields[field];
                if (!entityField) {
                    return handleError('Field not found: ' + entityField, traversedPathSoFar);
                }
                const nextEntity = entityField.relatedEntity;
                if (nextEntity) {
                    if (!viewConfig.entities[nextEntity]) {
                        const msg = `relatedEntity "${nextEntity}" on ${field}:${entity} not found`;
                        console.error(msg);
                        if (!isLast) {
                            // if we have more traversal to go, it's now broken
                            // (we are being soft with this right now if it's the last item, just because people sometimes put the wrong
                            // thing in the 'relatedEntity' field when configuring.)
                            return handleError(msg, atPath); // <- current field is marked as traversed, because we could look it up.
                        }
                    }
                }
                // accumulate here.
                const stop =
                    processNode?.({
                        isLast,
                        isFirst: fieldExpr === _fieldExpr,
                        isManyReference: isManyReference(entityField),
                        isOneReference: isOneReference(entityField),
                        isValueset: isValueSet(entityField),
                        field: entityField,
                    }) === 'stop';
                if (stop) {
                    return { _t: 'stopped', field: entityField, atPath, pathBefore: traversedPathSoFar };
                }

                if (isLast) {
                    // done accumulating.
                    return {
                        _t: 'completed',
                        field: entityField,
                    };
                } else {
                    if (isString(nextEntity)) {
                        return accumulate(
                            viewConfig,
                            nextEntity,
                            range(1, viewFieldRefPath.length)
                                .map((i) => viewFieldRefPath[i])
                                .join('.'),
                            atPath,
                        );
                    }
                    return handleError('No relatedEntity on field ' + field + ':' + entity, atPath);
                }
            };
            return accumulate(viewConfig, _entity, _fieldExpr, '');
        }
        return rfn;
    };
export default traversePath;
