import { decrypt } from 'encryption/encryption';
import { EncryptionInput } from 'encryption/encryptionUtils';
import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { Entry } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';
import validatePinAndReencryptAllDataWithNewSecrets from 'offline_app/offline_stateful_tasks/download/util/validatePinAndReencryptAllDataWithNewSecrets';
import sessionSecretsController from 'offline_app/sessionSecretsController';
import { getPromptController } from './promptController';
import { DencryptPromptController } from './promptDecodeBase';

const FAILED_RETRY_MSG = 'Could not decrypt data using PIN provided. Please retry.';
class DencryptEntityDataPromptController extends DencryptPromptController {
    public promptDecodeEntityData = async (entitySubmissionKey: string, runSubscriptions = true): Promise<Entry> => {
        // const _state = await offlineEntitySubmitsIdbKeyVal.get(entitySubmissionKey);
        let encryptionInput: EncryptionInput;
        let decodedEntry: Entry;
        let failureMessage: string;
        let cont: boolean;
        const continueToCheckAndInit = async () => {
            if (encryptionInput.type === 'pin') {
                let successSecrets = await validatePinAndReencryptAllDataWithNewSecrets(encryptionInput.pin);
                if (successSecrets) {
                    const _state = await offlineEntitySubmitsIdbKeyVal.get(entitySubmissionKey);
                    decodedEntry = await decrypt(_state, { type: 'secrets', secrets: successSecrets });
                    sessionSecretsController.set(successSecrets);
                    failureMessage = undefined;
                    // success
                    return false;
                } else {
                    failureMessage = FAILED_RETRY_MSG;
                }
            } else {
                // existing sessionsecrets
                try {
                    const _state = await offlineEntitySubmitsIdbKeyVal.get(entitySubmissionKey);
                    decodedEntry = await decrypt(_state, encryptionInput);
                    failureMessage = undefined;
                    // success
                    return false;
                } catch (e) {
                    console.error(e);
                }
            }
            failureMessage = FAILED_RETRY_MSG;
            if (sessionSecretsController.get()) {
                // we failed using existing secrets, so clear that out and force PIN entry.
                // It may be that the currently written data was encrypted in a different tab,
                // having different initialization vectors etc.
                sessionSecretsController.clear();
            }
            return true;
        };
        do {
            encryptionInput = await getPromptController().getPin(failureMessage);
            cont = await continueToCheckAndInit();
        } while (cont);
        if (runSubscriptions) {
            this.subscriptions.forEach((cb) => {
                cb();
            });
        }
        return decodedEntry;
    };
}

export const getDecryptEntityDataPromptController = (() => {
    let controller: DencryptEntityDataPromptController;
    return () => {
        if (!controller) {
            controller = new DencryptEntityDataPromptController();
        }
        return controller;
    };
})();
