import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { push as pushAction } from 'connected-react-router';
import memoizeOne from 'memoize-one';
import { getAccessLevelForEntity } from '../utils/viewConfigUtils';
import { customShowRedirects } from '../overrides';
import { RenderListArguments } from './List';

interface OverrideableRedirectProviderProps {
    onRowSelect: RenderListArguments['onRowSelect'];
    resource: RenderListArguments['resource'];
    data: RenderListArguments['data'];
    ids: RenderListArguments['ids'];
    render: (onRowsSelect: (indexes: (string | number)[]) => void) => JSX.Element;
}
interface OverrideableRedirectProviderComponentProps extends OverrideableRedirectProviderProps {
    allRedirects: string;
    push: (loc: string) => void;
}

class OverrideableRedirectProviderComponent extends React.Component<OverrideableRedirectProviderComponentProps> {
    _getRedirectList = memoizeOne((redirects) => redirects.split(';'));
    redirect = (indexes: number[]) => {
        const { resource, push, onRowSelect, data, ids, allRedirects } = this.props;
        if (onRowSelect) {
            onRowSelect(
                indexes.map((ix) => data[ids[ix]]),
                Object.assign({}, ...ids.map((id) => ({ [id]: data[id] }))),
            );
        } else if (typeof indexes[0] !== 'undefined') {
            push(
                allRedirects
                    ? this._getRedirectList(allRedirects)[indexes[0]]
                    : `/${resource}/${ids[indexes[0]]}${/* allowsEdit(accessLevel) ? '' : */ '/show'}`,
            );
        }
    };
    render() {
        return this.props.render(this.redirect);
    }
}

const makeMapStateToProps = () => {
    const allRedirectsSelector = createSelector(
        (state, props) => state.viewConfig,
        (state, props) => state.admin.entities,
        (state, props) => props.resource,
        (state, props) => !!props.onRowSelect,
        (state, props) => props.data,
        (state, props) => props.ids,
        (viewConfig, entities, resource, hasOnRowSelect, data, ids) => {
            const customRedirect = customShowRedirects[resource];
            if (customRedirect && !hasOnRowSelect) {
                const rowClickRedirect = customRedirect && customRedirect.find((r) => r._isRowClick);
                if (rowClickRedirect) {
                    return ids.map((id) => rowClickRedirect.redirectFormula(data[id], entities, viewConfig)).join(';');
                }
            }
            return null;
        },
    );
    const mapStateToProps = (state, props) => {
        const accessLevel = getAccessLevelForEntity(state.viewConfig, props.resource);
        return {
            accessLevel,
            allRedirects: allRedirectsSelector(state, props),
        };
    };
    return mapStateToProps;
};

const enhance = compose(connect(makeMapStateToProps, { push: pushAction }));

const OverrideableRedirectProvider: React.SFC<OverrideableRedirectProviderProps> = enhance(
    OverrideableRedirectProviderComponent,
);
export default OverrideableRedirectProvider;
