import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import { isManyReference } from 'components/generics/utils/viewConfigUtils/traversePath';

const setExpansionOnObject = <ViewConfig extends { entities: ViewConfigEntities }>(viewConfig: ViewConfig) => {
    const _setExpansions = (rootEntity: string, obj: {}, expansionPath: string, value: unknown) => {
        if (!expansionPath.includes('.')) {
            obj[expansionPath] = value;
        } else {
            const nextPath = expansionPath.slice(0, expansionPath.indexOf('.'));
            const fieldEntry = viewConfig.entities[rootEntity]?.fields[nextPath];
            if (fieldEntry && !obj[nextPath]) {
                if (isManyReference(fieldEntry)) {
                    obj[nextPath] = Array.isArray(value) ? value.map((v) => ({})) : [];
                } else {
                    obj[nextPath] = {};
                }
            }
            const rest = expansionPath.slice(expansionPath.indexOf('.') + 1);
            if (fieldEntry && Array.isArray(obj[nextPath]) && Array.isArray(value)) {
                obj[nextPath].forEach((e, i) => {
                    _setExpansions(fieldEntry.relatedEntity, e, rest, value[i]);
                });
            } else {
                _setExpansions(fieldEntry.relatedEntity, obj[nextPath], rest, value);
            }
        }
    };
    return _setExpansions;
};

const fieldValuesToObj =
    <ViewConfig extends { entities: ViewConfigEntities }>(
        representation: 'FLAT' | 'EXPANDED' = 'EXPANDED',
        viewConfig: ViewConfig,
        rootEntity: string,
    ) =>
    (fieldValues: [string, unknown][]) => {
        let res = {};
        fieldValues
            .sort(function ([a], [b]) {
                return b.length - a.length;
            })
            .forEach(([path, v]) => {
                if (representation === 'FLAT') {
                    res[path] = v;
                } else {
                    setExpansionOnObject(viewConfig)(rootEntity, res, path, v);
                }
            });
        return res;
    };
export default fieldValuesToObj;
