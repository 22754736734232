import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./index2'));
const LazyDiffPage2 = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component />
        </Suspense>
    );
};
export default LazyDiffPage2;
