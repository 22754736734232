import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import defaultFormContext from 'bpm/form-context-utils/defaultFormContext';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { useEvalTemplateInEntityForm } from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import React, { useContext, useMemo } from 'react';
import { getTemplateDataPaths } from 'util/getTemplateDataPaths';

interface FieldTitleProps {
    label: string;
    isRequired?: boolean;
    appendScreenreaderText?: string;
}

const eo = {};
export const useEvaluateLabel = (label: string) => {
    const taskFormContext = useContext(formContext);
    const evalTemplate = useEvaluateTemplate(label);

    const { expansionsRequired, dataPaths } = useMemo(() => getTemplateDataPaths(label), [label]);
    const entityTemplate = useEvalTemplateInEntityForm({
        templateString: label,
        context: {
            type: 'adhoc',
            expansionsRequired,
            dataPaths,
            valuesetFieldsRequired: eo,
        },
    });
    const taskTemplatedLabel = useMemo(() => {
        if (taskFormContext === defaultFormContext) {
            return null;
        }
        return evalTemplate(taskFormContext.fieldValues);
    }, [evalTemplate, taskFormContext]);

    return useEvaluatedFormattedMessage(taskTemplatedLabel ?? entityTemplate);
};

export const EvaluateLabel = (props: {
    label?: string;
    children: (props: { templatedLabel?: string }) => JSX.Element;
}) => {
    const templatedLabel = useEvaluateLabel(props.label);
    return props.children({ templatedLabel });
};

export const FieldTitle: React.FC<any> = React.memo(
    ({ label: _label, isRequired, appendScreenreaderText }: FieldTitleProps) => {
        const label = useEvaluateLabel(_label);
        if (!label) {
            return null;
        }
        const appendElem = appendScreenreaderText ? (
            <span className="casetivity-off-screen">{appendScreenreaderText}</span>
        ) : null;
        return label.endsWith('*') ? (
            <span>
                {label.slice(0, -1)}
                <abbr title="Required">*</abbr>
                {appendElem}
            </span>
        ) : isRequired ? (
            <span>
                {label} <abbr title="Required">*</abbr>
                {appendElem}
            </span>
        ) : (
            <span>
                {label} {appendElem}
            </span>
        );
    },
);

export default FieldTitle;
