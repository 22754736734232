import { maybeDecodeMetadataKeyVal } from 'components/generics/genericCreate/BackrefMetadata/backrefMetadata';
import { useMemo } from 'react';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';

const getBackrefProperty = (property: 'path' | 'entityType' | 'id') => (values) => {
    const [prop] = Object.entries((values ?? {}) as { [k: string]: string }).flatMap((t) => {
        const r = maybeDecodeMetadataKeyVal(t);
        if (r) {
            return [r];
        }
        return [];
    });
    return prop?.[property];
};

/**
 * 
 use this if we have form context
 (selectors if we have formId)
 */
export const useBackrefProperties = (formInitialValues: {}) => {
    const path = useMemo(() => getBackrefProperty('path')(formInitialValues), [formInitialValues]);
    const entityType = useMemo(
        () => path && getBackrefProperty('entityType')(formInitialValues),
        [path, formInitialValues],
    );
    const id = useMemo(() => path && getBackrefProperty('id')(formInitialValues), [path, formInitialValues]);
    return useMemo(() => {
        return {
            path,
            entityType,
            id,
        };
    }, [path, entityType, id]);
};

const createBackrefPropertiesSelectors = <P extends { formId?: string }>(property: 'path' | 'entityType' | 'id') => {
    return createSelector(
        (state: RootState, props: P) => state.form?.[props.formId || 'record-form']?.values,
        getBackrefProperty(property),
    );
};

const createBackrefSelector = <P extends { formId?: string }>() => {
    const pathSelector = createBackrefPropertiesSelectors<P>('path');
    const entityTypeSelector = createBackrefPropertiesSelectors<P>('entityType');
    const idSelector = createBackrefPropertiesSelectors<P>('id');
    return createSelector(pathSelector, entityTypeSelector, idSelector, (path, entityType, id) => ({
        path,
        entityType,
        id,
    }));
};
export default createBackrefSelector;
