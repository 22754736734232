import { EventConfiguration, eventConfiguration } from '../types/index';
import { ThrowReporter } from 'io-ts/lib/ThrowReporter';

function decodeEventConfig(config: string, onError: 'THROW_ERROR'): EventConfiguration;
function decodeEventConfig(config: string, onError: 'RETURN_NULL'): EventConfiguration | null;
function decodeEventConfig(config: string, onError: 'THROW_ERROR' | 'RETURN_NULL') {
    try {
        const parsedConfig: EventConfiguration = JSON.parse(config);
        const decodeResult = eventConfiguration.decode(parsedConfig);
        ThrowReporter.report(decodeResult); // throws error if decode fails. Should always be right, but refine anyway
        if (decodeResult.isRight()) {
            return decodeResult.value;
        }
        return null;
    } catch (e) {
        if (onError === 'THROW_ERROR') {
            throw e;
        } else {
            return null;
        }
    }
}
export default decodeEventConfig;
