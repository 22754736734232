import { createCustomAction } from 'typesafe-actions';
import { REMOVE_SNACKBAR, ENQUEUE_SNACKBAR } from './constants';
import { Notification, QueuedNotification } from './definitions.d';

export const enqueueSnackbar = createCustomAction(ENQUEUE_SNACKBAR, (type) => {
    return (notification: Notification) => ({
        type,
        notification: {
            key: new Date().getTime() + Math.random(),
            ...notification,
        } as QueuedNotification,
    });
});

export const removeSnackbar = createCustomAction(REMOVE_SNACKBAR, (type) => {
    return (key: number) => ({
        type,
        key,
    });
});
