import React from 'react';
import { IconButton } from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';

export const toggleBulkActionsButton = (
    toggleBulkActions: () => void,
    bulkActionsOpen: boolean,
    someDataSelected: boolean,
) => (
    <IconButton onClick={toggleBulkActions} aria-label={'toggle select'}>
        {!bulkActionsOpen && <NavigateBefore />}
        {someDataSelected ? <CheckBox /> : <CheckBoxOutlineBlank />}
        {bulkActionsOpen && <NavigateNext />}
    </IconButton>
);
