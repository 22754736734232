import React from 'react';
import { RemoteData, initial, pending, success, failure } from '@devexperts/remote-data-ts';
import { AjaxError } from 'rxjs/ajax';
import getContent, { arrayBufferToBase64 } from 'util/fetch';
import { CircularProgress, useTheme } from '@material-ui/core';

interface BlobImageProps {
    url: string;
    altText: string;
}

const BlobImage: React.FunctionComponent<BlobImageProps> = ({ url, altText }) => {
    const [b64, setB64] = React.useState<RemoteData<AjaxError, string>>(initial);
    const getImageB64: () => Promise<string> = React.useCallback(() => {
        return getContent(url).then(async (r) => {
            if (r.status === 404) {
                throw new Error('File not found.');
            } else if (r.status === 500) {
                throw new Error('Internal server error.');
            }
            const buffer = await r.arrayBuffer();
            return 'data:image/png;base64,' + arrayBufferToBase64(buffer);
        });
    }, [url]);

    React.useEffect(() => {
        setB64(pending);
        getImageB64()
            .then((s) => {
                setB64(success(s));
            })
            .catch((e) => {
                setB64(failure(e));
            });
    }, []); // eslint-disable-line
    const theme = useTheme();
    return b64.foldL(
        () => null,
        () => <CircularProgress />,
        (e) => <p style={{ color: theme.palette.error.dark }}>{e.message}</p>,
        (b64String) => <img style={{ height: 'auto', width: '100%' }} src={b64String} alt={altText ?? ''} />,
    );
};

export default BlobImage;
