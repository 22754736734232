/**
 * Basically tells us whether it's the installed PWA or not.
 */

import { userAgent } from 'userAgent';

/**
 * In macOS, there's no way for a fullscreen window to know that it is the installed app.
 * To workaround this, we put '?app=1' in our start_url in manifest.json
 * And set 'isApp' in sessionStorage when receiving that.
 * (We also set 'isApp' this when switching display mode in useIsInstalledApp, since the window doesn't reload, and is "shifted" to a pwa in that case)
 * Since sessionStorage is per-session, and we only set the flag when opening the PWA, this flag can tell us that we are the installed app,
 * as long as we never overwrite/clear it (e.g. on logout)
 */

const MAC_APP_KEY = 'IS_MAC_APP';
export const getIsMacApp = () => {
    return Boolean(sessionStorage.getItem(MAC_APP_KEY));
};
export const setIsMacApp = () => {
    sessionStorage.setItem(MAC_APP_KEY, 'true');
};
export const getPutBackIsMacApp = () => {
    const isMacApp = getIsMacApp();
    return () => {
        if (isMacApp) {
            setIsMacApp();
        }
    };
};

export function isStandalone() {
    return userAgent.isIosStandalone() || window.matchMedia?.(`(display-mode: standalone)`)?.matches;
}

export default function isTheInstalledApp() {
    return isStandalone() || window.matchMedia?.(`(display-mode: minimal-ui)`)?.matches || getIsMacApp();
}
