/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['19abf842'] = validate10;
const schema11 = {
    type: 'object',
    title: 'Start Process',
    properties: {
        processDefinitionKey: { title: 'Process Definition Key', type: 'string' },
        startVariables: { title: 'Start Variables', type: 'string', description: 'JSON start variables' },
    },
    dependencies: {},
    required: [],
    $id: '19abf842',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="19abf842" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.processDefinitionKey !== undefined) {
            let data0 = data.processDefinitionKey;
            if (typeof data0 !== 'string') {
                const err0 = {
                    instancePath: instancePath + '/processDefinitionKey',
                    schemaPath: '#/properties/processDefinitionKey/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.processDefinitionKey.type,
                    parentSchema: schema11.properties.processDefinitionKey,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
        if (data.startVariables !== undefined) {
            let data1 = data.startVariables;
            if (typeof data1 !== 'string') {
                const err1 = {
                    instancePath: instancePath + '/startVariables',
                    schemaPath: '#/properties/startVariables/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.startVariables.type,
                    parentSchema: schema11.properties.startVariables,
                    data: data1,
                };
                if (vErrors === null) {
                    vErrors = [err1];
                } else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
    } else {
        const err2 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err2];
        } else {
            vErrors.push(err2);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
