import { useReducer } from 'react';

type CustomErrorAction =
    | {
          type: 'SET_ERROR';
          errorText: string;
      }
    | {
          type: 'CLEAR_ERROR';
      };
type CustomErrorState = {
    errorText: null | string;
    key: number;
};
export const useCustomError = () => {
    const t = useReducer(
        (state: CustomErrorState, action: CustomErrorAction): CustomErrorState => {
            switch (action.type) {
                case 'SET_ERROR':
                    return { errorText: action.errorText, key: state.key + 1 };
                case 'CLEAR_ERROR':
                    return { errorText: null, key: 1 };
            }
        },
        { errorText: null, key: 1 },
    );
    return t;
};
