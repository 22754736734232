import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./DataModelExplorer'));

const LazyDatamodelExplorer = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component />
        </Suspense>
    );
};
export default LazyDatamodelExplorer;
