import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';

export const initialAssigneeValuesSelector = <T extends { taskId: string }>(
    state: RootState,
    props: T,
): {
    assignee?: string;
} => {
    const task = fromNullable(state.admin.entities.TaskInstance)
        .map((taskBag) => taskBag[props.taskId])
        .chain(fromNullable)
        .getOrElse({
            id: props.taskId,
            entityType: 'TaskInstance',
        });
    return {
        assignee: traverseGetData(state.viewConfig, 'assignee.login', task, state.admin.entities).getOrElse(undefined),
    };
};

export interface TaskLeftPanelProps {
    taskId: string;
    processId?: string;
    forceNoLinkedEntityArea?: boolean;
    relatedEntityResource?: string;
    relatedEntityId?: string;
    renderLinkedEntity?: (options: { actions?: JSX.Element | null }) => JSX.Element | null;
}
export const getIsAdminFromProcess = (state: RootState, processId: string) => {
    return fromNullable(state.bpm.processInstances.byId[processId])
        .chain((pi) => fromNullable(pi.businessKey))
        .chain((businessKey) => fromNullable(state.bpm.processDefinitions.byId[businessKey]))
        .fold(false, (pd) => !!pd.adminUser);
};

export const getDueDateEditableFromProcess = (state: RootState, processId: string) => {
    return fromNullable(state.bpm.processInstances.byId[processId])
        .chain((pi) => fromNullable(pi.businessKey))
        .chain((businessKey) => fromNullable(state.bpm.processDefinitions.byId[businessKey]))
        .fold(false, (pd) => !!pd.dueDateEditable);
};

export const getIsAdmin = (state: RootState, taskId: string) => {
    return fromNullable(taskId)
        .map((tId) => state.bpm.tasks.byId[tId])
        .chain(fromNullable)
        .chain((_task) => fromNullable(_task.processInstanceId))
        .fold(false, (pid) => getIsAdminFromProcess(state, pid));
};

export const mapStateToTaskDetailProps = (state: RootState, ownProps: TaskLeftPanelProps) => {
    const task = ownProps.taskId ? state.bpm.tasks.byId[ownProps.taskId] : undefined;
    return {
        task,
        // changed name from TaskAssignmentInitialValuesSelector
        initialAssignee: initialAssigneeValuesSelector(state, ownProps).assignee,
        isAdmin: getIsAdmin(state, ownProps.taskId),
        currentUser: state.viewConfig && state.viewConfig.user && state.viewConfig.user.login,
    };
};
