import React from 'react';
import 'arrive';
import sortBy from 'lodash/sortBy';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Clear from '@material-ui/icons/Clear';
import ContentAdd from '@material-ui/icons/Add';
import * as Option from 'fp-ts/lib/Option';
import * as Either from 'fp-ts/lib/Either';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { IconButton, Button, CircularProgress, Typography } from '@material-ui/core';
import Toolbar from 'components/generics/form/Toolbar.aor';
import SaveButton from 'components/generics/button/SaveButton';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import SearchSelectDialog from 'fieldFactory/popovers/SearchSelectDialog';
import GenericEdit from 'components/generics/genericEdit/index2';
import {
    getRelatedField,
    getPluralName,
    getDefaultListViewName,
    allowsEdit,
    allowsCreate,
} from 'components/generics/utils/viewConfigUtils/index';
import createGetListData from 'components/generics/utils/createGetListData';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { withRefreshContext } from 'components/generics/form/refreshContext';
import { registerComponent } from 'fieldFactory/input/components/Event/Event';
import getRenderer, { RenderListArgumentsWithBulkSelection } from 'components/generics/genericList/renderList';
import getChipRenderer from 'components/generics/genericList/renderChips';
import withPropsOnChange from 'recompose/withPropsOnChange';
import {
    getRefEntityLabel,
    getRefEntityName,
    getAccessLevelForEntity,
    getAsRefFields,
} from 'components/generics/utils/viewConfigUtils/index';
import applySortableOverrides from 'components/generics/fields/applySortableOverrides';
import { withFieldFactory } from 'fieldFactory/Broadcasts';
import pipe from 'fieldFactory/util/pipe';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import getNoResultsTextElement from 'components/generics/genericList/getNoResultsTextElement';
import { isArray } from 'util';
import { Dialog, DialogContent } from '@material-ui/core';
import EntitiesAreLoading from 'components/EntitiesAreLoading';
import uniqueId from 'lodash/uniqueId';
import Pagination from 'components/generics/genericList/Pagination';
import PaginatedData from './PaginatedDataController';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import getFields from 'components/generics/genericList/getFields';
import Show from 'components/generics/genericShow/Show2';
import { getHidePaginationIfNotNeededSelector, getUseNativeSelectSelector } from 'util/applicationConfig';
import getRenderMultiCardList from 'fieldFactory/display/components/MultiCard/renderMultiCardList';
import { EntityInputProps, EntityDisplayProps, RefProviderPropsStandalone, RefProviderProps } from './EntityInputProps';

const DEFAULT_PER_PAGE = 10;
const circularProgressStyle = { width: 18, height: 18 };

type Input = any;
type Meta = any;

type RefManyManyInputComponentProps = ComposedProps & {
    input: {
        value: string[];
        onBlur: Function;
    };
    fields: React.ReactElement<{}>[];
    refresh: () => void;
};

interface RefManyManyState {
    currentSort: {
        field: string;
        order: string;
    };
    ids: string[];
    data: ComposedProps['data'];
    openRecord: string | null;
    openSearch: string | null | true;
}

const RemoveFromList = (props) => (
    <IconButton
        id={props.id}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onClick(props.record);
        }}
        aria-label={'Remove from list'}
    >
        <Clear />
    </IconButton>
);
const eitherIsString = Either.fromPredicate(
    (f: any): f is string => typeof f === 'string',
    (f) => f,
);
const sortCaseInsensitive = (sortOnField) => (d) => {
    return sortBy(
        d,
        (o) =>
            Either.fromNullable('')(get(o, sortOnField))
                .chain<string>(eitherIsString)
                .map((f) => f.toLowerCase()).value,
    );
};
type Snapshot = {
    newIds?: string[];
    newData?: boolean;
} | null;
class RefManyManyInput extends React.Component<RefManyManyInputComponentProps, RefManyManyState> {
    private componentIdentifier = uniqueId('RefManyManyInput');
    static defaultProps = {
        commitChanges: true,
    };

    editToolbar: React.ReactElement<any>;
    _getInjectValuesOnCreate = memoizeOne((viewConfig, resource, record, source) => ({
        [`${getRelatedField(viewConfig, resource, source.slice(0, -3))}Ids`]: [record.id],
    }));
    _getSpecificIdsFilter = memoizeOne((relatedField, openSearch, filter) => ({
        [`${relatedField}Id`]: openSearch,
        ...filter,
    }));
    constructor(props: RefManyManyInputComponentProps) {
        super(props);
        const { data } = this.props;
        const initialField = 'id';
        const initialOrder = 'ASC';
        this.state = {
            currentSort: {
                field: initialField,
                order: initialOrder,
            },
            ids: this.getSortedIds(this.props, initialField, initialOrder),
            data,
            openRecord: null,
            openSearch: null,
        };
        this.editToolbar = (
            <Toolbar>
                <SaveButton />
                <CloseButton handleClose={this.handleEditClose} />
            </Toolbar>
        );
    }
    idsWeDontHaveDataFor = (ids: string[]) => {
        const { data } = this.props;
        return ids.filter((id) => !data[id]);
    };
    componentDidMount() {
        const {
            type,
            input: { value },
        } = this.props;
        // if no backing entity, we don't have views to prefetch the data we know we will need.
        // therefore we have to fetch records we have ids for on mount.
        if (type === 'Input(NoBackingEntity)' && value && isArray(value)) {
            this.idsWeDontHaveDataFor(value).forEach((id) => {
                this.fetchId(id);
            });
        }
    }

    getSnapshotBeforeUpdate(prevProps: RefManyManyInputComponentProps, prevState: RefManyManyState): Snapshot {
        let snapshot: Snapshot = null;
        if (isArray(prevProps.input.value) && isArray(this.props.input.value)) {
            snapshot = {
                newIds: this.newNextIds(prevProps.input.value, this.props.input.value),
            };
        }
        if (this.props.data !== prevProps.data) {
            if (snapshot) {
                snapshot.newData = true;
            } else {
                snapshot = { newData: true };
            }
        }
        return snapshot;
    }

    componentDidUpdate(
        prevProps: RefManyManyInputComponentProps,
        prevState: RefManyManyState,
        snapshot: ReturnType<typeof RefManyManyInput.prototype.getSnapshotBeforeUpdate>,
    ) {
        if (snapshot !== null) {
            if (snapshot.newIds) {
                if (this.props.type === 'Input(NoBackingEntity)') {
                    this.idsWeDontHaveDataFor(snapshot.newIds).forEach((newId) => {
                        this.fetchId(newId);
                    });
                }
            }
            if (snapshot.newData) {
                this.setState((state) => ({
                    ...state,
                    ids: this.getSortedIds(this.props, state.currentSort.field, state.currentSort.order),
                    data: this.props.data,
                }));
            }
        }
    }

    newNextIds = (prevIds: string[], nextIds: string[]): string[] => {
        return nextIds.flatMap((id) => {
            if (prevIds.indexOf(id) === -1) {
                return [id];
            }
            return [];
        });
    };
    fetchId = (id) => {
        const { crudGetOne, reference, expansions } = this.props;
        crudGetOne({
            resource: reference,
            id,
            view: -1,
            appendExpansions: expansions,
        });
    };

    maybeRefresh = () => {
        const { commitChanges, refresh } = this.props;
        if (commitChanges) {
            if (refresh) {
                refresh();
            } else {
                console.error(
                    `commitChanges is true, but no refresh function is provided.
                    Did you forget to set this up via context?`,
                );
            }
        }
    };

    getSortedIds = (props = this.props, field = this.state.currentSort.field, order = this.state.currentSort.order) => {
        const { data } = props;
        return Option.fromNullable(data)
            .map(sortCaseInsensitive(field.endsWith('Id') ? `${field.slice(0, -2)}.title` : field))
            .map((l: { id: string }[]) => (order === 'ASC' ? l.reverse() : l))
            .map((f) => f.map((d) => d.id))
            .getOrElse([]);
    };
    createCommitChangesErrMsg = (type: string) => {
        return `Misconfiguration. ${type} component used in 'commitChanges' mode`;
    };
    getFieldOpposite = () => {
        const props = this.props;
        if (props.type === 'Input(NoBackingEntity)') {
            throw new Error(this.createCommitChangesErrMsg(props.type));
        }
        const { viewConfig, resource, source } = props;
        return `${getRelatedField(viewConfig, resource, source.slice(0, -3))}Ids`;
    };
    addRelationShipToThis = (oldData: { id: string }, onSaveCb: (id: string, data: {}) => void) => {
        const props = this.props;
        if (props.type === 'Input(NoBackingEntity)') {
            throw new Error(this.createCommitChangesErrMsg(props.type));
        }
        const { crudUpdate, crudGetOne, reference, record } = props;
        // first we have to ensure we have the data we need
        crudGetOne({
            resource: reference,
            id: oldData.id,
            view: -1,
            cb: (id, newData) => {
                // new we use the data with the expansion to edit
                crudUpdate({
                    resource: reference,
                    data: {
                        id,
                        partialUpdate: true,
                        [this.getFieldOpposite()]: uniq([...(newData[this.getFieldOpposite()] || []), record.id]),
                    },
                    previousData: newData,
                    cb: onSaveCb,
                });
            },
            appendExpansions: [this.getFieldOpposite()],
        });
    };
    updateSort = (sortField) => {
        this.setState((state) => {
            const newOrder = state.currentSort.order === 'ASC' ? 'DESC' : 'ASC';
            return {
                ...state,
                currentSort: {
                    order: newOrder,
                    field: sortField,
                },
                ids: this.getSortedIds(this.props, sortField, newOrder),
            };
        });
    };
    handleEditOpen = (id) => {
        this.setState({ openRecord: id });
    };

    handleEditClose = () => {
        this.setState({ openRecord: null }, this.maybeRefresh);
        // we refresh here since if the relationship from the other side is removed in another one of these components,
        // and we close without saving the form, we must refresh the data to see the change
    };

    handleSearchOpen = (recordId: string | null | true = true) => {
        this.setState({ openSearch: recordId });
    };
    handleSearchOpenEmpty = () => this.handleSearchOpen();
    handleSearchClose = () => {
        this.setState({ openSearch: null });
    };

    removeFromList = (data) => {
        const props = this.props;
        const { source, commitChanges } = props;

        if (commitChanges && props.type === 'Input(FromEntity)') {
            const { crudUpdate, crudGetOne, resource, reference, record, ownerSide } = props;
            const saveSource = source.endsWith('Ids') ? source : `${source}Ids`;
            if (ownerSide) {
                crudGetOne({
                    id: record.id,
                    resource,
                    view: -1,
                    appendExpansions: [source],
                    cb: (id, newData) => {
                        crudUpdate({
                            resource,
                            data: {
                                id: record.id,
                                partialUpdate: true,
                                [source]: (newData[saveSource] || []).filter((id) => id !== data.id),
                            },
                            previousData: record,
                            cb: this.maybeRefresh,
                        });
                    },
                });
            } else {
                crudGetOne({
                    resource: reference,
                    id: data.id,
                    view: -1,
                    cb: (id, newData) => {
                        // new we use the data with the expansion to edit
                        crudUpdate({
                            resource: reference,
                            data: {
                                id,
                                partialUpdate: true,
                                [this.getFieldOpposite()]: (newData[this.getFieldOpposite()] || []).filter(
                                    (id) => id !== record.id,
                                ),
                            },
                            previousData: newData,
                            cb: this.maybeRefresh,
                        });
                    },
                    appendExpansions: [this.getFieldOpposite()],
                });
            }
        } else {
            this.setState(
                (state) => ({
                    ...state,
                    ids: state.ids.filter((id) => id !== data.id),
                    data: {
                        ...state.data,
                        [data.id]: undefined,
                    },
                }),
                this.props.input.onBlur(this.props.input.value.filter((id) => id !== data.id)),
            );
        }
    };
    closeSearchAndRefresh = (focusId?: string) =>
        this.setState(
            (state) => ({
                ...state,
                openSearch: null,
            }),
            () => {
                this.maybeRefresh();
                if (focusId) {
                    const rmButtonElementId = `#${this.getRemoveButtonIdByRecordId(focusId)}`;
                    /*
                        Cancel the event listener to focus on the added row's "remove" button on success, or after waiting 15 seconds, whichever comes first.
                    */
                    const cancelTooLateUnsubscribe = setTimeout(() => {
                        (document as any).unbindArrive(rmButtonElementId);
                    }, 15 * 1000);
                    /*
                        Focus on the newly added row
                    */
                    (document as any).arrive(rmButtonElementId, function () {
                        clearTimeout(cancelTooLateUnsubscribe);
                        setTimeout(() => {
                            this.focus();
                        }, 100);

                        (document as any).unbindArrive(rmButtonElementId);
                    });
                }
            },
        );

    addRelationshipOnOurSide = (data: { id: string }, onSaveCb: (id: string, data: {}) => void) => {
        const props = this.props;
        if (props.type !== 'Input(FromEntity)') {
            throw new Error('addRelationshipOnOurSide incorrectly called in mode: ' + props.type);
        }
        const { crudUpdate, crudGetOne, record, resource, source } = props;
        // new we use the data with the expansion to edit
        const saveSource = source.endsWith('Ids') ? source : `${source}Ids`;
        crudGetOne({
            id: record.id,
            resource: resource,
            view: -1,
            appendExpansions: [source],
            cb: (id, newData) => {
                crudUpdate({
                    resource,
                    data: {
                        id: id,
                        partialUpdate: true,
                        [saveSource]: uniq([...newData[saveSource], data.id]),
                    },
                    previousData: newData,
                    cb: onSaveCb,
                });
            },
        });
    };
    addToList = (data, update: 'ADD' | 'REMOVE') => {
        if (update === 'REMOVE') {
            this.removeFromList(data);
        } else {
            const props = this.props;
            if (props.commitChanges && props.type === 'Input(FromEntity)' && props.ownerSide) {
                this.addRelationshipOnOurSide(data, this.closeSearchAndRefresh);
            } else if (props.commitChanges) {
                this.addRelationShipToThis(data, this.closeSearchAndRefresh);
            } else {
                this.setState(
                    (state) => ({
                        ...state,
                        openSearch: null,
                        ids: uniq([...state.ids, data.id]),
                        data: {
                            ...state.data,
                            [data.id]: data,
                        },
                    }),
                    () => {
                        this.props.input.onBlur(uniq([...(this.props.input.value || []), data.id]));
                    },
                );
            }
        }
    };
    onEditSave = (id, data) => {
        this.setState((state) => ({ ...state, openRecord: null }), this.props.refresh);
    };

    getInjectValuesOnCreate = () => {
        const props = this.props;
        if (props.type === 'Input(NoBackingEntity)') {
            return {};
        }
        const { viewConfig, resource, source, record } = props;
        return this._getInjectValuesOnCreate(viewConfig, resource, record, source);
    };

    onRowSelect = ([data]) => data && this.handleEditOpen(data.id);

    getSearchFilter = () => {
        const props = this.props;
        const { openSearch } = this.state;
        if (props.specificIds) {
            if (props.type === 'Input(NoBackingEntity)') {
                throw new Error(
                    'specificIds mode only makes sense in the context of an entity' +
                        ' (specificIds point to a backreferenced entity)',
                );
            }
            const { relatedField, filter } = props;

            return this._getSpecificIdsFilter(relatedField, openSearch, filter);
        }
        return props.filter;
    };
    renderNoResultsElement = () => {
        const { viewConfig, reference, overrideRenderNoResultsText } = this.props;
        if (overrideRenderNoResultsText) {
            return overrideRenderNoResultsText;
        }
        const pluralReferenceName = getPluralName(viewConfig, reference);
        const text = pluralReferenceName ? `No ${pluralReferenceName}` : 'No Results';
        return getNoResultsTextElement(text);
    };
    getRemoveButtonIdByRecordId = (id: string) => {
        return `${this.props.source}removebutton${id}`;
    };
    getRenderListArgs = (ids: string[] = this.state.ids): RenderListArgumentsWithBulkSelection => {
        const { fields, reference, disabled = false, label, noClick } = this.props;
        return {
            data: this.state.data,
            ids,
            resource: reference,
            setSort: this.updateSort,
            isLoading: false,
            onRowSelect: this.onRowSelect,
            noClick,
            ariaProps: {
                'aria-label': label,
                'aria-hidden': ids.length === 0 ? true : undefined,
            },
            disableSorting: ids.length === 0 ? true : false,
            fields: fields as React.ReactElement<any>[],
            hasSomeVisibleSearchFields: false,
            renderAtRowEnd: disabled
                ? undefined
                : (r, rowRecord) => [
                      <RemoveFromList
                          id={this.getRemoveButtonIdByRecordId(rowRecord.id)}
                          key="delete"
                          onClick={() => this.removeFromList(rowRecord)}
                      />,
                  ],
        };
    };
    render() {
        const {
            source,
            reference,
            specificIds = false,
            disabled = false,
            renderer = 'LIST',
            type,
            label,
            match,
            viewName,
        } = this.props;

        const editAllowed = allowsEdit(getAccessLevelForEntity(this.props.viewConfig, reference));
        const createAllowed = allowsCreate(getAccessLevelForEntity(this.props.viewConfig, reference));
        const editViewName =
            this.props.editViewName || this.props.viewConfig.entities[reference]?.defaultViews?.EDIT?.name;
        const showViewName =
            this.props.showViewName || this.props.viewConfig.entities[reference]?.defaultViews?.SHOW?.name;
        return (
            <div>
                <Typography
                    variant="h6"
                    style={
                        {
                            /* fontWeight: 'bold' */
                        }
                    }
                    component="div"
                >
                    {label}
                </Typography>
                {renderer === 'CARD_DISPLAY' ? (
                    getRenderMultiCardList({
                        viewName,
                        hasEdit: false,
                        editViewName,
                        showViewName,
                        label,
                        hasCreate: false,
                    })(this.getRenderListArgs(this.state.ids))
                ) : renderer === 'CARD_EDITABLE' ? (
                    getRenderMultiCardList({
                        hasEdit: editAllowed,
                        viewName,
                        editViewName,
                        showViewName,
                        label,
                        hasCreate: createAllowed,
                    })(this.getRenderListArgs(this.state.ids))
                ) : renderer === 'LIST' ? (
                    <PaginatedData defaultPerPage={DEFAULT_PER_PAGE} allIds={this.state.ids}>
                        {({ page, perPage, setPage, setPerPage, currentPageIds }) => (
                            <React.Fragment>
                                {getRenderer({}, {})(this.getRenderListArgs(currentPageIds))}
                                {this.props.hidePaginationIfNotNeeded &&
                                this.state.ids?.length <= DEFAULT_PER_PAGE ? null : (
                                    <Pagination
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Rows per page',
                                            },
                                            native: this.props.nativeSelect,
                                        }}
                                        total={this.state.ids.length}
                                        perPage={perPage}
                                        page={page}
                                        setPage={setPage}
                                        setPerPage={setPerPage}
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </PaginatedData>
                ) : (
                    getChipRenderer(
                        {},
                        {},
                        {
                            disabled,
                            handleDelete: this.removeFromList,
                        },
                    )(this.getRenderListArgs(this.state.ids))
                )}
                {this.state.ids.length === 0 ? this.renderNoResultsElement() : null}
                {!disabled &&
                    type !== 'Display(FromEntity)' &&
                    (specificIds ? (
                        specificIds.map((id) => (
                            <EntitiesAreLoading
                                render={({ entitiesAreLoading }) => (
                                    <Button
                                        color="primary"
                                        disabled={entitiesAreLoading}
                                        onClick={() => this.handleSearchOpen(id)}
                                    >
                                        {id === (match && match.params && match.params.id)
                                            ? 'Add from current record'
                                            : id === (match && match.params && match.params.id2)
                                            ? 'Add from matching record'
                                            : `Add from ${id}`}
                                        &nbsp;
                                        <ContentAdd />
                                        {entitiesAreLoading ? <CircularProgress style={circularProgressStyle} /> : null}
                                    </Button>
                                )}
                            />
                        ))
                    ) : (
                        <EntitiesAreLoading
                            render={({ entitiesAreLoading }) => (
                                <Button
                                    tabIndex={0}
                                    color="primary"
                                    aria-label={`Add ${label}`}
                                    disabled={entitiesAreLoading}
                                    onClick={this.handleSearchOpenEmpty}
                                >
                                    Add&nbsp;
                                    <ContentAdd />
                                    {entitiesAreLoading ? <CircularProgress style={circularProgressStyle} /> : null}
                                </Button>
                            )}
                        />
                    ))}
                {/* EDIT */}
                <Dialog
                    TransitionProps={
                        {
                            // https://github.com/dequelabs/axe-core/issues/146
                            role: 'presentation',
                        } as any
                    }
                    open={!!this.state.openRecord}
                    onClose={this.handleEditClose}
                    maxWidth={false}
                    fullWidth={true}
                >
                    <DialogContent style={{ padding: 0, minWidth: '80vw' }}>
                        {this.state.openRecord &&
                            (this.props.openTo !== 'show' && editAllowed ? (
                                <GenericEdit
                                    evaluatedAdhocSPELVariables={this.props.evaluatedAdhocSPELVariables}
                                    viewName={editViewName}
                                    redirect={false}
                                    formId={`inspectionedit-from-${source}-to-${reference}-${this.state.openRecord}-realedit${this.componentIdentifier}`}
                                    id={this.state.openRecord}
                                    resource={reference}
                                    onSaveCb={this.onEditSave}
                                    toolbar={this.editToolbar}
                                />
                            ) : (
                                <Show
                                    evaluatedAdhocSPELVariables={this.props.evaluatedAdhocSPELVariables}
                                    viewName={showViewName}
                                    resource={reference}
                                    formId={`manymany-Show ${reference} ${source}-${this.state.openRecord}-${this.componentIdentifier}`}
                                    id={this.state.openRecord}
                                />
                            ))}
                    </DialogContent>
                </Dialog>
                {/* SEARCH */}
                <SearchSelectDialog
                    evaluatedAdhocSPELVariables={this.props.evaluatedAdhocSPELVariables}
                    editViewName={this.props.editViewName}
                    showViewName={this.props.showViewName}
                    createViewName={this.props.createViewName}
                    viewName={viewName}
                    injectCreateValues={
                        /* we have to know the name of the relationship back to our entity */
                        this.props.type === 'Input(FromEntity)' && this.props.ownerSide
                            ? undefined
                            : this.getInjectValuesOnCreate()
                    }
                    reference={reference}
                    values={this.state.ids}
                    isOpen={!!this.state.openSearch}
                    handleClose={this.handleSearchClose}
                    filter={this.getSearchFilter()}
                    setReference={this.addToList}
                    onCreateCb={({ id }) => {
                        if (
                            this.props.type === 'Input(NoBackingEntity)' ||
                            (this.props.type === 'Input(FromEntity)' && this.props.ownerSide)
                        ) {
                            this.addToList({ id }, 'ADD');
                        } else {
                            this.closeSearchAndRefresh(id);
                        }
                    }}
                />
            </div>
        );
    }
}

const adjustSourceIfNecessary = (source: string) => (!source.endsWith('Ids') ? `${source}Ids` : source);
const adjustedGet = (record: {}, source: string) => get(record, adjustSourceIfNecessary(source));

const makeMapStateToProps = () => {
    const getData = createGetListData(true);
    const getEntities = createGetEntities();
    const _mapStateToProps = (state: RootState, props: SubscribedComponentProps) => {
        const ids =
            props.type === 'Display(FromEntity)' ? adjustedGet(props.record || {}, props.source) : props.input.value;
        const res = {
            nativeSelect: getUseNativeSelectSelector(state),
            data: getData(state, {
                ids,
                viewName: props.viewName || getDefaultListViewName(state.viewConfig, props.reference),
                listFilter: props.listFilter,
            }) as {
                [key: string]: {
                    id: string;
                    title?: string;
                    subtitle?: string;
                };
            },
            disabled: props.type === 'Display(FromEntity)' || props.disabled || false,
            input:
                props.type === 'Display(FromEntity)'
                    ? {
                          value: adjustedGet(props.record || {}, props.source),
                      }
                    : props.input,
            source: adjustSourceIfNecessary(props.source),
        };
        if (props.type === 'Input(FromEntity)') {
            const record = props.record && (getEntities(state)[props.resource] || {})[props.record.id];
            return { ...res, record };
        }
        return res;
    };
    return _mapStateToProps;
};

/*
    Copied this in so there's no dependency on fieldFactoryProvider.
    TODO: copy these options into a seperate file
*/
const DataSource = {
    ENTITY: 'Entity',
    FLOWABLE: 'Flowable',
};
const Mode = {
    DISPLAY: 'Display',
    INPUT: 'Input',
};

// props inserted at this level.
interface InsertedProps {
    referenceAccessLevel: number;
    viewConfig: ViewConfig;
    source: string;
    hidePaginationIfNotNeeded?: boolean;
}
interface InsertedEntityProps extends InsertedProps {
    reference: string;
    label?: string;
    relatedField: string;
    ownerSide: boolean;
}
interface InsertedEntityInputProps extends InsertedEntityProps {
    type: EntityInputProps['type'];
}
interface InsertedEntityDisplayProps extends InsertedEntityProps {
    type: EntityDisplayProps['type'];
}
interface InsertedNotFromEntityProps extends InsertedProps {
    type: RefProviderPropsStandalone['type'];
}
type ConnectedProps = InsertedEntityInputProps | InsertedEntityDisplayProps | InsertedNotFromEntityProps;

const mapStateToProps = (state: RootState, props: RefProviderProps): ConnectedProps => {
    const { viewConfig } = state;
    const source = props.source.endsWith('Ids') ? props.source.slice(0, props.source.length - 3) : props.source;
    if (props.type === 'Input(FromEntity)' || props.type === 'Display(FromEntity)') {
        const reference = getRefEntityName(viewConfig, props.resource, source);
        const ownerSide = viewConfig.entities[props.resource].fields[source].ownerSide;
        const addedProps = {
            ownerSide,
            source,
            reference,
            label: props.label || getRefEntityLabel(viewConfig, props.resource, source),
            relatedField: getRelatedField(viewConfig, props.resource, source) as string,
            referenceAccessLevel: getAccessLevelForEntity(viewConfig, reference),
            viewConfig,
            hidePaginationIfNotNeeded: props.hidePaginationIfNotNeeded || getHidePaginationIfNotNeededSelector(state),
        };
        if (props.type === 'Input(FromEntity)') {
            return { type: props.type, ...addedProps };
        }
        return { type: props.type, ...addedProps };
    } else {
        return {
            type: props.type,
            source,
            referenceAccessLevel: getAccessLevelForEntity(viewConfig, props.reference),
            viewConfig,
            hidePaginationIfNotNeeded: props.hidePaginationIfNotNeeded || getHidePaginationIfNotNeededSelector(state),
        };
    }
};
interface SubscribedComponentEntityInputProps extends EntityInputProps, InsertedEntityInputProps {}
interface SubscribedComponentEntityDisplayProps extends EntityDisplayProps, InsertedEntityDisplayProps {}
interface SubscribedComponentNotFromEntityInputProps extends RefProviderPropsStandalone, InsertedNotFromEntityProps {}
type SubscribedComponentProps =
    | SubscribedComponentEntityDisplayProps
    | SubscribedComponentEntityInputProps
    | SubscribedComponentNotFromEntityInputProps;

const enhance = compose(
    connect(mapStateToProps),
    withFieldFactory,
    withPropsOnChange(['reference', 'fieldFactory'], (props: SubscribedComponentProps & { fieldFactory: any }) => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                hideCheckboxLabel: true,
            },
        };
        return {
            generateFields: props.fieldFactory(config)({
                record: props.type !== 'Input(NoBackingEntity)' ? props.record : undefined,
                resource: props.reference,
                basePath: `/${props.reference}`,
            }),
        };
    }),
    withPropsOnChange(
        ['type', 'reference', 'relatedField', 'viewConfig', 'source', 'viewName', 'generateFields'],
        (props: SubscribedComponentProps & { generateFields: any }) => {
            const { reference, viewConfig, generateFields, source, viewName: _viewName } = props;
            const getFieldsForReference = (refEntityName) => getAsRefFields(viewConfig, refEntityName);
            const overrideSorts = (fieldComponents) => fieldComponents.map(applySortableOverrides(reference));
            if (props.type !== 'Input(NoBackingEntity)') {
                const viewName = _viewName || viewConfig.entities[reference].defaultViews.LIST.name;
                return {
                    fields: pipe(
                        generateFields,
                        overrideSorts,
                    )(getFields(viewConfig, viewName, true, source.endsWith('Ids') ? source.slice(0, -3) : source)),
                };
            }
            return {
                fields: pipe(getFieldsForReference, generateFields, overrideSorts)(reference),
            };
        },
    ),
);

const dispatches = {
    crudUpdate: crudUpdateAction,
    crudGetOne: crudGetOneAction,
};
const InputVersion: React.FC<RefProviderProps> = compose(
    enhance,
    connect(makeMapStateToProps, dispatches),
    withRefreshContext,
)(RefManyManyInput);

type ComposedProps = SubscribedComponentProps & ReturnType<ReturnType<typeof makeMapStateToProps>> & typeof dispatches;

registerComponent('manymany', InputVersion);

export default InputVersion;
