import { getType } from 'typesafe-actions';
import * as viewConfig from 'viewConfig/actions';
import { RootAction } from 'actions/rootAction';
import ViewConfig from 'reducers/ViewConfigType';

export default (previousState: ViewConfig = {} as any, action: RootAction): ViewConfig => {
    switch (action.type) {
        case getType(viewConfig.loadSuccess):
            return action.payload.viewConfig;
        default:
            return previousState;
    }
};
