import * as React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import withPopoverLock from '../hoc/popoverLock';
import FieldMatrixProps from './interfaces/FieldMatrix';
import { Config, LiveFieldConfig } from 'fieldFactory/ConfigTypes';
import ThreeColumnView from './components/ThreeColumnView';
import injectDisplayFieldsForRecord from './hoc/injectDisplayFieldsForRecord';
import injectInputFieldsForMerge, { getFieldsFromMergeView } from './hoc/injectInputFieldsForMerge';
import { useSelector } from 'react-redux';
import useValidation from '../form/validate/useValidation';
import { createSelector } from 'reselect';
import useViewConfig from 'util/hooks/useViewConfig';
import set from 'lodash/set';
import get from 'lodash/get';
import { isFieldViewField, isRefManyField, isRefOneField } from '../utils/viewConfigUtils';

const GenericMergeView = compose(
    (BaseComponent) => (props: FieldMatrixProps) =>
        (
            <FieldFactorySubscriber>
                {(fieldFactory) => <BaseComponent {...props} fieldFactory={fieldFactory} />}
            </FieldFactorySubscriber>
        ),
    withPropsOnChange(
        ['fieldFactory'],
        (
            props: FieldMatrixProps & {
                fieldFactory: (
                    config: Config,
                ) => (lc: LiveFieldConfig) => (fieldDefs: {}[]) => React.ReactElement<{}>[];
            },
        ) => {
            const displayConfig = {
                dataSource: DataSource.ENTITY,
                mode: Mode.DISPLAY,
                validate: false,
                connected: false,
                options: {}, // not used at the moment
            };
            const inputConfig: Config = {
                dataSource: DataSource.ENTITY,
                mode: Mode.INPUT,
                validate: true,
                connected: true,
                options: {},
            };
            return {
                generateInputFields: props.fieldFactory(inputConfig),
                generateDisplayFields: props.fieldFactory(displayConfig),
            };
        },
    ),
    /* now that our field generators are mapped to "generateInputFields" and "generateDisplayFields", respectively */
    injectDisplayFieldsForRecord('record1', 'primaryRecordFields', 'Current Record'),
    injectDisplayFieldsForRecord('record2', 'secondaryRecordFields', 'Matching Record'),
    withPropsOnChange(
        ['generateInputFields', 'record', 'record1', 'record2', 'viewConfig', 'viewName'],
        injectInputFieldsForMerge,
    ),
    withPopoverLock,
    withProps((props) => ({
        initialValues: props.record,
    })),
    (BaseComponent) => {
        return class extends React.Component {
            render() {
                return <BaseComponent {...this.props} />;
            }
        };
    },
    (BaseComponent) => (props) => {
        const viewConfig = useViewConfig();
        const fieldsConfigured = React.useMemo(() => {
            return getFieldsFromMergeView(viewConfig.views[props.viewName], props.onlyFields)
                .map((f) => {
                    if (isFieldViewField(f)) {
                        if (isRefOneField(viewConfig, props.resource, f.field, 'TRAVERSE_PATH')) {
                            return f.field + 'Id';
                        }
                        if (isRefManyField(viewConfig, props.resource, f.field, 'TRAVERSE_PATH')) {
                            return f.field + 'Ids';
                        }
                    }
                    return f.field;
                })
                .reduce((prev, curr) => {
                    // set 'true' on all paths
                    // e.g.
                    // foo: true,
                    // foo.bar: true
                    // foo.baz: true
                    const paths = curr.split('.');
                    for (let currP = '', i = 0; i < paths.length; i++) {
                        currP += paths[i];
                        prev[currP] = true;
                    }
                    return prev;
                }, {} as { [field: string]: true });
        }, []); // eslint-disable-line

        /*
        We need to filter out values that aren't mapped onto fields, because a lot of them are 'floating' ids
        (i.e. we never fetched the record they correspond to) and if we run useValidation on it,
        it will try to denormalize using those and crash.

        All fields (from the view) with ids (references, valuesets) are guaranteed to expanded from our fetch code, so
        we strip all data to those, of which 'fields' is a superset
        */
        const getExpectedRegisteredValues = React.useCallback(
            (values: {}) => {
                let res = {};
                const sorted = Object.keys(fieldsConfigured).sort((a, b) => {
                    // sort shortest to longest, just in case (shouldn't matter if we are only setting nodes as data.)
                    // hopefully we aren't setting empty objects which can overwrite our data.
                    // e.g. foo: {} and foo.bar: 'hi', we want these to come in a particular order
                    return a.length > b.length ? 1 : a.length < b.length ? -1 : 0;
                });
                sorted.forEach((path) => {
                    set(res, path, get(values, path, null));
                });
                return res;
            },
            [fieldsConfigured],
        );

        const getValues = React.useMemo(
            () =>
                createSelector(getFormValues('mergeForm'), (allValues) => {
                    const expectedRegisteredValues = getExpectedRegisteredValues(allValues || {});
                    return expectedRegisteredValues;
                }),
            [getExpectedRegisteredValues],
        );
        const values = useSelector(getValues);
        const validation = useValidation({ type: 'error', values: values, resource: props.resource });
        const warning = useValidation({ type: 'warn', values: values, resource: props.resource });
        return <BaseComponent {...props} _validation={validation} _warn={warning} />;
    },
    reduxForm({
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        form: 'mergeForm',
        warn: (values, props) => {
            const { _warn } = props as any;
            return _warn();
        },
        validate: (values, props) => {
            const { _validation } = props as any;
            // we have to pass these to make sure we are still in-sync with our validation callback
            // (naming the prop "validation" and not having this doesn't work.)
            return _validation();
        },
    }),
)(ThreeColumnView);

export default GenericMergeView;
