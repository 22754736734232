import React, { useState, useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';

const SLEEP_TIME = 400;
const SLEEP_TIME_LONG = 1500;

const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const waitAndClickButtonWithId = async (id: string, sleepTime: number = SLEEP_TIME) => {
    const button = await sleep(sleepTime).then((v) => document.getElementById(id) as HTMLButtonElement);
    if (button) {
        button.click();
        return true;
    }
    return false;
};

const getRunMigration = () => {
    let cancel = false;
    const runMigration = (whenDone?: () => void) => {
        const listElem = document.getElementById('entityview-list-tomigrate');
        const { childNodes } = listElem;
        const forLoop = async () => {
            for (let index = 0; index < childNodes.length; index++) {
                if (cancel) {
                    return;
                }
                const { firstChild } = childNodes[index];
                await sleep(SLEEP_TIME).then((v) => (firstChild as HTMLButtonElement).click());
                let success = true;
                success = await waitAndClickButtonWithId('step1-submit', 200);
                success = success && (await waitAndClickButtonWithId('step2-submit', SLEEP_TIME_LONG));
                success = success && (await waitAndClickButtonWithId('step3-submit', 200));
                let lastStepSuccess =
                    success &&
                    ((await waitAndClickButtonWithId('step4-submit')) ||
                        (await waitAndClickButtonWithId('save-viewdef')));
                lastStepSuccess = lastStepSuccess && (await waitAndClickButtonWithId('save-viewdef'));
                if (lastStepSuccess) {
                    await sleep(500);
                }
                if (index === childNodes.length - 1) {
                    whenDone();
                }
            }
        };
        forLoop();
    };
    const cancelMigration = () => {
        cancel = true;
    };
    return [runMigration, cancelMigration] as const;
};
const Automigrate = (props) => {
    const [migrating, setMigrating] = useState(false);
    const startMigration = useCallback(() => {
        setMigrating(true);
    }, [setMigrating]);
    const pauseMigration = useCallback(() => {
        setMigrating(false);
    }, [setMigrating]);
    useEffect(() => {
        const [runMigration, cancelMigration] = getRunMigration();
        if (migrating) {
            runMigration(pauseMigration);
        }
        return () => {
            cancelMigration();
        };
    }, [migrating, pauseMigration]);
    return (
        <div>
            {!migrating ? (
                <Button onClick={startMigration}>Automigrate</Button>
            ) : (
                <Button onClick={pauseMigration}>Pause migration</Button>
            )}
        </div>
    );
};
export default Automigrate;
