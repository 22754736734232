import { ConceptFromApi, ConceptInApp, ValuesetFromApi, ValuesetInApp } from './domain';

export const getTransformConcept =
    (valueSetId: string, valueSetCode: string) =>
    (concept: ConceptFromApi): ConceptInApp => {
        return {
            ...concept,
            title: `${valueSetCode}:${concept.display}`,
            subtitle: concept.id,
            entityType: 'Concept',
            valueSetId,
            valueSet: valueSetId,
        };
    };

export const transformValueset = (valuesetFromApi: ValuesetFromApi): ValuesetInApp => {
    const { concepts, ...restFromApi } = valuesetFromApi;
    return {
        ...restFromApi,
        entityType: 'ValueSet',
        title: restFromApi.display,
        subtitle: restFromApi.id,
    };
};
