import { Address2Configuration } from './types';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { AddressVerification2Field } from 'reducers/ViewConfigType';

const getAllFieldsWeWriteTo = (viewField: AddressVerification2Field) => {
    const configStr = viewField.config;
    const parsedConfig: Address2Configuration = JSON.parse(configStr);
    const writeOnlyKeys: (keyof Address2Configuration)[] = [
        // write-only
        'longitude',
        'latitude',
        'censusBlock',
        'censusKey',
        'censusTract',
        'melissaAddressKey',
        'addressValidationDate',
        'zipPlus',
    ];

    const writeOnlyFields = writeOnlyKeys.map((key) => parsedConfig[key]).filter(Boolean);

    const resultCodes = parsedConfig.resultCodes;
    if (resultCodes) {
        const adjustedSource = !resultCodes.endsWith('Ids') ? resultCodes + 'Ids' : resultCodes;
        writeOnlyFields.push(adjustedSource);
    }
    const keys: (keyof Address2Configuration)[] = [
        // read/write fields
        'line1',
        'line2',
        'city',
        'state',
        'county',
        'zip',
    ];
    const fields = keys
        .map((key) => parsedConfig[key])
        .filter(Boolean)
        .flatMap((key) => {
            const compiled = getImpl().compileExpression(key);
            if (compiled.type === 'parse_failure') {
                console.error(compiled.msg);
                return [];
            }
            return compiled.getPathsWithAll().slice(0, 1);
        });
    return writeOnlyFields.concat(fields);
};

export default getAllFieldsWeWriteTo;
