import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RootState } from '../../src/reducers/rootReducer';

export interface EntitiesAreLoadingProps {
    render: (arg: { entitiesAreLoading: boolean }) => JSX.Element | null;
}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: EntitiesAreLoadingProps) => {
        return {
            entitiesAreLoading: state.admin.loading > 0,
        };
    };
    return mapStateToProps;
};

interface EntitiesAreLoadingComponentProps
    extends ReturnType<ReturnType<typeof makeMapStateToProps>>,
        EntitiesAreLoadingProps {}

class EntitiesAreLoadingComponent extends React.Component<EntitiesAreLoadingComponentProps> {
    render() {
        const { render, entitiesAreLoading } = this.props;
        return render({ entitiesAreLoading });
    }
}

const EntitiesAreLoading: React.SFC<EntitiesAreLoadingProps> = compose(connect(makeMapStateToProps))(
    EntitiesAreLoadingComponent,
);

export default EntitiesAreLoading;
