import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { asyncEventsInProgressContext } from '../TaskDetail/asyncEventCountContext';
import { getOnDrop } from '../../../fieldFactory/input/components/FileUpload/getOnDrop';
import { getMaxFileSizeNumber, getMaxFileSizeMultiplier } from 'fieldFactory/input/components/FileUpload/FileInput';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import acceptFile from 'fieldFactory/input/components/FileUpload/util/acceptFile';
import defaultAcceptError from 'fieldFactory/input/components/FileUpload/util/defaultAcceptError';

class ProcessFileInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    getOnDrop = (
        eventCbs /* : {
        beforeRequest?: () => void;
        afterRequest?: () => void;
    } */,
        taskId,
    ) => {
        const { enqueueSnackbar } = this.props;
        return getOnDrop(
            {
                ...eventCbs,
                onResponse: (statusCode) => {
                    const didFail = statusCode < 200 || statusCode >= 300;
                    enqueueSnackbar({
                        message: didFail ? 'Upload Failed' : 'Upload Successful',
                        options: {
                            variant: didFail ? 'error' : 'success',
                        },
                    });
                },
                onNetworkError: () => {
                    enqueueSnackbar({
                        message: 'Network error',
                        options: { variant: 'error' },
                    });
                },
                onSuccessWithData: (data) => {
                    this.props.onChange([data]);
                    this.props.onBlur();
                },
            },
            taskId,
        );
    };

    render() {
        const {
            value,
            disabled,
            showFile = true,
            taskId,
            inputRef,
            maxFileSize,
            inputProps,
            ariaInputProps,
            accept,
            acceptError,
        } = this.props;
        return (
            <div>
                <div style={{ marginTop: showFile ? undefined : '.75em' }}>
                    <EvaluateFormattedMessage>
                        {({ evaluateFormattedMessage, translate }) => (
                            <asyncEventsInProgressContext.Consumer>
                                {({ increment, decrement }) => {
                                    return (
                                        <input
                                            ref={inputRef}
                                            aria-label="file"
                                            {...inputProps}
                                            {...ariaInputProps}
                                            accept={accept}
                                            type="file"
                                            disabled={disabled}
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    let file = e.target.files[0];
                                                    if (!file) {
                                                        return;
                                                    }
                                                    if (file.size === 0) {
                                                        const message = translate({
                                                            id: 'file.failure.empty',
                                                            defaultMessage:
                                                                'File could not be uploaded because the file is empty.',
                                                        });
                                                        alert(message);
                                                        e.target.value = '';
                                                    } else if (
                                                        maxFileSize &&
                                                        file.size >
                                                            parseInt(getMaxFileSizeNumber(maxFileSize), 10) *
                                                                getMaxFileSizeMultiplier(maxFileSize)
                                                    ) {
                                                        const message = translate(
                                                            {
                                                                id: 'file.failure.size',
                                                                defaultMessage:
                                                                    'File is too large. Upload limit is {0}',
                                                            },
                                                            { 0: maxFileSize },
                                                        );
                                                        alert(message);
                                                        e.target.value = '';
                                                    } else if (accept && !acceptFile(file, accept)) {
                                                        if (acceptError) {
                                                            alert(evaluateFormattedMessage(acceptError));
                                                        } else {
                                                            alert(defaultAcceptError(file.type, accept));
                                                        }
                                                        e.target.value = '';
                                                    } else {
                                                        this.getOnDrop(
                                                            {
                                                                beforeRequest: increment,
                                                                afterRequest: decrement,
                                                            },
                                                            taskId,
                                                        )(e.target.files);
                                                    }
                                                }
                                            }}
                                        />
                                    );
                                }}
                            </asyncEventsInProgressContext.Consumer>
                        )}
                    </EvaluateFormattedMessage>
                </div>
                {showFile ? <div>{value.fileFileName || '<file name not returned>'}</div> : null}
            </div>
        );
    }
}

ProcessFileInput.defaultProps = {
    value: [],
};

const enhance = compose(
    connect(
        (state) => {
            return {
                maxFileSize: state.basicInfo && state.basicInfo.maxFileSize,
            };
        },
        {
            enqueueSnackbar: enqueueSnackbarAction,
        },
    ),
);

export default enhance(ProcessFileInput);
