import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchJson } from 'util/fetchUtils';
import buildFetchOptions from './util/buildFetchOptions';
import { API_URL } from '../config';
import { ExpressionEventType } from '../actions/expressionTestActions';

export function* submitExpression(actions) {
    try {
        const requestBody = JSON.stringify({
            entityType: actions.entityType,
            entityId: actions.entityId,
            calcExpression: actions.calcExpression,
        });

        const options = [];
        options.method = 'POST';
        options.body = requestBody;

        const completeOptions = { ...buildFetchOptions(), ...options };
        const { status, body } = yield call(fetchJson, `${API_URL}test-calc`, completeOptions);

        if (status === 200) {
            yield put({ type: ExpressionEventType.SubmitExpressionSuccess, body });
        }
    } catch (e) {
        yield put({
            type: ExpressionEventType.SubmitExpressionFailure,
            body: 'Expression not valid: \r'.concat(e.message),
        });
    }
}
export default function* expressionTesterSaga() {
    yield takeEvery(ExpressionEventType.SubmitExpression, function* (action) {
        yield call(submitExpression, action);
    });
}
