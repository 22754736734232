import React, { useEffect } from 'react';
import GlobalAlertsBannerComponent from 'global-alerts/components/BannerComponent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getPublicGlobalAlerts } from '../actions';
import { getFetchGlobalAlertsSelector } from 'util/applicationConfig';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';

const PublicGlobalAlerts = props => {
    const dispatch = useDispatch();
    const fetchGlobalAlerts = useSelector(getFetchGlobalAlertsSelector);
    useEffect(() => {
        if (fetchGlobalAlerts) {
            dispatch(getPublicGlobalAlerts());
        }
    }, [fetchGlobalAlerts, dispatch]);
    const publicAlerts = useSelector((state: RootState) => {
        return state.globalAlerts.publicAlerts;
    });
    return deserialize(publicAlerts).fold(
        null,
        null,
        err => null,
        data => (data ? <GlobalAlertsBannerComponent globalAlerts={data} /> : null),
    );
};
export default PublicGlobalAlerts;
