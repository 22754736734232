import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import debounce from 'debounce-fn';
import isEqual from 'react-fast-compare';
import { getHeaders } from 'sideEffect/services';
import { stringify } from 'query-string';

interface AxiosProps<Data> extends Pick<AxiosRequestConfig, 'url' | 'method' | 'params' | 'data'> {
    onSuccess?: (count: number) => void;
    children: (args: {
        data?: Data;
        loading: boolean;
        error: string | false;
        refetch: () => void;
        total?: number;
    }) => JSX.Element | null;
}
interface AxiosState<Data> {
    data: Data | undefined;
    loading: boolean;
    error: string | false;
    total?: number;
}
class Axios<Data> extends React.Component<AxiosProps<Data>, AxiosState<Data>> {
    state = {
        data: undefined,
        loading: false,
        error: false,
        total: undefined,
    } as const;

    cancelToken = null;

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate({ children: _, ...prevProps }) {
        const { children, ...props } = this.props;
        if (!isEqual(prevProps, props)) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        if (this.cancelToken) {
            this.cancelToken();
        }
    }

    makeNetworkRequest = debounce(
        () => {
            const { url, method = 'get', params, data, onSuccess } = this.props;

            axios({
                url,
                method,
                params,
                headers: getHeaders(),
                data,
                paramsSerializer: {
                    serialize: (params) => {
                        return stringify(params, { encode: false }).replace('#', '%23');
                    },
                },
                cancelToken: new axios.CancelToken((token) => {
                    this.cancelToken = token;
                }),
            })
                .then((res) => {
                    this.cancelToken = null;
                    const total = parseInt(res.headers['x-total-count'], 10);
                    if (onSuccess && res.data) {
                        onSuccess(res.data.length);
                    }
                    this.setState({
                        data: res.data,
                        loading: false,
                        error: false,
                        total,
                    });
                })
                .catch((e) => {
                    // Early return if request was cancelled
                    if (axios.isCancel(e)) {
                        return;
                    }
                    this.setState({ data: undefined, error: e.message, loading: false, total: undefined });
                    console.error(e);
                });
        },
        { wait: 500 },
    );

    fetchData = () => {
        if (this.cancelToken) {
            this.cancelToken();
        }

        this.setState({ error: false, loading: true });

        this.makeNetworkRequest();
    };

    render() {
        const { children } = this.props;
        const { data, loading, error, total } = this.state;

        return children({
            data,
            loading,
            error,
            refetch: this.fetchData,
            total,
        });
    }
}

export default Axios;
