export const GET_TASK = 'GET_TASK';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';

export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';

export const GET_SIDEBAR_TASKS = 'GET_SIDEBAR_TASKS';
export const GET_SIDEBAR_TASKS_SUCCESS = 'GET_SIDEBAR_TASKS_SUCCESS';
export const GET_SIDEBAR_TASKS_FAILURE = 'GET_SIDEBAR_TASKS_FAILURE';

/*
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const CREATE_TASK = 'CREATE_TASK';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const CLAIM_TASK = 'CLAIM_TASK';
export const CLAIM_TASK_SUCCESS = 'CLAIM_TASK_SUCCESS';
export const CLAIM_TASK_FAILURE = 'CLAIM_TASK_FAILURE';

export const ASSIGN_TASK = 'ASSIGN_TASK';
export const ASSIGN_TASK_SUCCESS = 'ASSIGN_TASK_SUCCESS';
export const ASSIGN_TASK_FAILURE = 'ASSIGN_TASK_FAILURE';

export const COMPLETE_TASK = 'COMPLETE_TASK';
export const COMPLETE_TASK_SUCCESS = 'COMPLETE_TASK_SUCCESS';
export const COMPLETE_TASK_FAILURE = 'COMPLETE_TASK_FAILURE';
*/
