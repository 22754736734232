import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./Component'));
const LazyComponent = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component />
        </Suspense>
    );
};
export default LazyComponent;
