import React, { useMemo, useState } from 'react';
import SearchSelectDialog from '../SearchSelectDialog';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import ReferenceDisplay, { Record } from './ReferenceDisplay';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/util/tableRowContext';
import CreateDialog from './CreateDialog';
import { getPathBackFromFieldPath, allowsCreate } from 'components/generics/utils/viewConfigUtils';
import EntityInspect from 'components/generics/hoc/EntityInspect';
import { Dialog, CircularProgress } from '@material-ui/core';
import { css } from 'emotion';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import getExpansionsSelector from 'sideEffect/crud/getOne/getExpansions';
import useViewConfig from 'util/hooks/useViewConfig';
import uniq from 'lodash/uniq';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';

type Input = any;
type Meta = any;

interface PopoverRefInputProps {
    viewName?: string;
    isHardDisabled?: boolean;
    editViewName?: string;
    showViewName?: string;
    input: Input;
    disabled?: boolean;
    label?: string | null;
    meta: Meta;
    record?: Record;
    reference: string;
    resource: string;
    source: string;
    isRequired?: boolean;
    options: { id?: string };
    filterString?: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    expansions?: string[];
    fetchOwnData?: boolean;
    noSearch?: boolean;
    createViewName?: string;
    overrideAriaLabel?: string;
    openTo?: 'edit' | 'show';
    disabledDisplayHtml?: string;
    fetchViewExpansions?: boolean;
}

const PopoverRefInput: React.FC<PopoverRefInputProps> = (props) => {
    const {
        input,
        disabled,
        label = 'Create',
        meta,
        record,
        source,
        reference,
        isRequired,
        options,
        resource,
        viewName,
        filterString,
        ariaInputProps,
        renderLabel,
        expansions,
        editViewName,
        createViewName,
        noSearch,
        showViewName,
        isHardDisabled,
        fetchOwnData,
        overrideAriaLabel,
        openTo,
        disabledDisplayHtml,
        fetchViewExpansions,
    } = props;
    const fc = useCurrentFormContext();
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const entityConfig = viewConfig.entities[reference];
    if (!entityConfig) {
        throw new Error(
            `Entity "${reference}" referenced by field "${source}" not found in viewConfig. The current user may lack permissions, or this may be a misconfiguration.`,
        );
    }
    const store = useStore();
    const [searchOpen, setSearchOpen] = useState<'open' | 'pending_close' | 'closed'>('closed');
    const [addOpen, setAddOpen] = useState(false);
    const hasCreate = allowsCreate(entityConfig.accessLevel);
    // input.value might point to an expired id, and the record moved.
    // selectedRecordId points to the real id.
    const selectedRecordId = useSelector(
        (state: RootState) => (input.value && state.admin.entities[reference]?.[input.value]?.id) ?? input.value,
    );

    const expansionsInView = useMemo(() => {
        /**
         * So we can update the data the view expressions need (mostly for create views)
         * when we select a different record
         */
        if (!fetchViewExpansions || !fc.viewName) {
            return [];
        }
        const expansionsOnView =
            getExpansionsSelector(store.getState())(fc.viewName, { overrideViewConfig: viewConfig })?.split(',') ?? [];
        const expansionsInView = expansionsOnView.flatMap((exp) => {
            const sourceStartPath = (source.endsWith('Id') ? source.slice(0, -2) : source) + '.';
            if (exp.startsWith(sourceStartPath)) {
                return [exp.slice(sourceStartPath.length)];
            }
            return [];
        });
        return expansionsInView;
    }, [fetchViewExpansions, fc.viewName, viewConfig, source, store]);

    const allExpansions = useMemo(
        () => uniq([...(expansions ?? []), ...expansionsInView]),
        [expansions, expansionsInView],
    );

    const setReference = (data: { id: string }, update: 'ADD' | 'REMOVE') => {
        if (update === 'REMOVE') {
            // Since we only store a single value, any 'REMOVE' means we clear it.
            input.onChange?.(null);
            input.onBlur(null);
            return;
        }
        if (allExpansions && allExpansions.length > 0) {
            setSearchOpen('pending_close');
            dispatch(
                crudGetOneAction({
                    id: data.id,
                    resource: reference,
                    appendExpansions: allExpansions,
                    cb: (id, data) => {
                        setImmediate(() => {
                            input.onChange?.(data.id, data);
                            input.onBlur(data.id, data);
                            setSearchOpen('closed');
                        });
                    },
                    view: -1,
                }),
            );
        } else {
            input.onChange?.(data.id, data);
            input.onBlur(data.id, data);
            setSearchOpen('closed');
        }
    };

    const handleOpen = () => {
        if (!disabled) {
            if (noSearch && !hasCreate) {
                return;
            }
            if (noSearch) {
                setAddOpen(true);
            } else {
                setSearchOpen('open');
            }
        }
    };

    return (
        <tableRowContext.Provider value={null}>
            <EntityInspect
                openTo={openTo}
                reference={reference}
                editViewName={editViewName}
                showViewName={showViewName}
                formId={`OuterIdSelect from ${viewName} ${source}`}
                renderComponent={(args) => (
                    <div>
                        <Dialog open={searchOpen === 'pending_close'}>
                            <div style={{ height: 90, width: 90, position: 'relative' }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        textAlign: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            </div>
                        </Dialog>
                        {disabled && disabledDisplayHtml ? (
                            <button
                                onClick={() => args.selectId(selectedRecordId)}
                                className={css`
                                    background-color: Transparent;
                                    background-repeat: no-repeat;
                                    border: none;
                                    cursor: pointer;
                                    overflow: hidden;
                                    outline: none;
                                `}
                            >
                                <SafeHtmlAsReact html={disabledDisplayHtml} />
                            </button>
                        ) : (
                            <ReferenceDisplay
                                overrideAriaLabel={overrideAriaLabel}
                                openAdd={() => {
                                    if (hasCreate) {
                                        setAddOpen(true);
                                    }
                                }}
                                noSearch={noSearch}
                                openSearch={handleOpen}
                                isOpen={searchOpen === 'open'}
                                openDetail={() => args.selectId(selectedRecordId)}
                                label={label && isRequired && !label.endsWith('*') ? label + ' *' : label}
                                disabled={disabled}
                                reference={reference}
                                input={input}
                                meta={meta}
                                record={record}
                                htmlId={(options || {}).id}
                                renderLabel={renderLabel}
                                ariaInputProps={ariaInputProps}
                                expansions={allExpansions}
                                isHardDisabled={isHardDisabled}
                                fetchOwnData={fetchOwnData}
                            />
                        )}
                        <SearchSelectDialog
                            filter={getFilterFromFilterString(filterString)}
                            formId={`vn:${viewName}-src:${source}`}
                            viewName={viewName}
                            createViewName={createViewName}
                            reference={reference}
                            values={[selectedRecordId]}
                            isOpen={searchOpen === 'open' || searchOpen === 'pending_close'}
                            handleClose={() => setSearchOpen('closed')}
                            setReference={setReference}
                            onCreateCb={(data) => setReference(data, 'ADD')}
                        />
                        {
                            // This Create (setting the backref) works (for now) only in the context of an entity.
                            record && resource && reference && reference !== resource && noSearch && hasCreate && (
                                <CreateDialog
                                    open={addOpen}
                                    setOpen={setAddOpen}
                                    resource={reference}
                                    viewName={createViewName}
                                    onCreateCb={(data) => setReference(data as { id: string }, 'ADD')}
                                    parentEntityName={resource}
                                    parentFieldInChild={`${getPathBackFromFieldPath(
                                        viewConfig,
                                        resource,
                                        source.endsWith('Id') ? source.slice(0, -2) : source,
                                    )}.id`}
                                    parentId={(record as any).id}
                                />
                            )
                        }
                    </div>
                )}
            />
        </tableRowContext.Provider>
    );
};

export default PopoverRefInput;
