import { Field } from '../../../translation/types';
import VideoWatched, { VideoWatchedProps } from './VideoWatched';
import { getHtmlId, simpleGetConfProperty } from '../../fieldUtils';
import { GenericProps, FormFieldItem } from '../../props';

const getVideoWatched = (fieldDefinition: Field, propConfiguration: GenericProps): FormFieldItem<VideoWatchedProps> => {
    type Config = { src: string; type: string };
    const src = simpleGetConfProperty<Config>('src', null)(fieldDefinition);
    const type = simpleGetConfProperty<Config>('type', undefined)(fieldDefinition);
    return {
        Component: VideoWatched,
        props: {
            ...propConfiguration,
            type,
            src,
            id: getHtmlId(fieldDefinition),
        },
    };
};
export default getVideoWatched;
