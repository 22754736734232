const StartupEventType = {
    GetBasicInfo: 'STARTUP_GET_INFO',
    LoadedBasicInfoSuccess: 'STARTUP_LOADED_BASIC_INFO_SUCCESS',
};

const StartupEventCreator = {
    getBasicInfo: () => ({
        type: StartupEventType.GetBasicInfo,
    }),
};

export { StartupEventCreator, StartupEventType };
