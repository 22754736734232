import React, { Suspense } from 'react';
import type { JsonSchemaFromBuilderProps } from './JsonSchemaFormBuilder';

const Component = React.lazy(() => import('./JsonSchemaFormBuilder'));

const LazyJsonSchemaFormBuilder = (props: JsonSchemaFromBuilderProps) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};

export default LazyJsonSchemaFormBuilder;
