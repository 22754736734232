import { select } from 'redux-saga/effects';
import { NOT_MATCH } from '../actions/constants';
import sagaFactory, { configType, HttpVerb } from './util/sagaFactory';

type payloadType = { resource: string; id: string; matchId: string };
const config: configType = {
    actionType: NOT_MATCH,
    verb: HttpVerb.POST,
    url: function* (payload: payloadType) {
        const viewConfig = yield select(
            (state: { viewConfig: { entities: { [resource: string]: { restUrl: string } } } }) => state.viewConfig,
        );
        return yield `${viewConfig.entities[payload.resource].restUrl}/notmatch`;
    } as any,
    body: (payload: payloadType) => ({
        entityId: payload.id,
        matchId: payload.matchId,
    }),
    success: (payload, responseBody) => ({ type: 'NOT_MATCH_SUCCESS', payload, responseBody }),
    failure: 'NOT_MATCH_FAILURE',
    callCb: (_1, _2, cb) => {
        cb();
    },
    callErrorCb: (cb) => {
        if (cb) {
            cb();
        }
    },
};

const saga = sagaFactory(config);
export default saga;
