import { SessionLocalSecrets } from 'encryption/encryptionUtils';

class SessionSecretsController {
    private KEY = 'sessionSecrets';
    set(secrets: SessionLocalSecrets) {
        sessionStorage.setItem(this.KEY, JSON.stringify(secrets));
    }
    get(): SessionLocalSecrets {
        let record = sessionStorage.getItem(this.KEY);
        if (record) {
            return JSON.parse(record);
        }
        return null;
    }
    clear() {
        sessionStorage.removeItem(this.KEY);
    }
}
const sessionSecretsController = new SessionSecretsController();
export default sessionSecretsController;
