import * as WidgetType from '../../components/generics/utils/widgetTypes';
import ViewConfig, { FieldViewField } from '../../reducers/ViewConfigType';
import { getViewIndexAndAdditionalConfigFields } from '../../components/generics/utils/viewConfigUtils';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';

const modifySearchKey = (key: string) => {
    var searchKeyComponents = key.split('__');
    if (searchKeyComponents.length === 2) {
        key = searchKeyComponents[0].split('_~_').join('.') + '__' + searchKeyComponents[1];
    }
    return key;
};

const getSearchFieldConfig = (field: string, searchFields: ViewConfig['views'][0]['searchFields']) => {
    return fromNullable(searchFields)
        .mapNullable((sf) => sf[field])
        .chain((f) => fromPredicate<string>(Boolean)(f.config))
        .chain((fc) => tryCatch<{ prependToSearch?: string; appendToSearch?: string }>(() => JSON.parse(fc)));
};

export const appendStarToTextFieldsInSearch = (
    fields: { [key: string]: {} },
    viewConfig: ViewConfig,
    viewName?: string | null,
) => {
    const [viewIndex, additionalSearchFields] = viewName
        ? getViewIndexAndAdditionalConfigFields(viewName, viewConfig, 'KEEP_LINKEDX_TYPE', 'WRITE')
        : [null, [] as FieldViewField[]];
    const viewSearchFields = viewIndex ? viewConfig.views[viewIndex].searchFields || {} : {};

    const textSearchFields = Object.keys(viewSearchFields || {})
        .filter((key) => {
            if (typeof viewSearchFields === 'undefined') {
                return false;
            }
            const f = viewSearchFields[key];
            if (typeof f === 'undefined') {
                return false;
            }

            return f.widgetType === WidgetType.TEXTBOX || f.widgetType === WidgetType.TEXTAREA;
        })
        .concat(
            additionalSearchFields.flatMap((f) =>
                f.widgetType === WidgetType.TEXTBOX || f.widgetType === WidgetType.TEXTAREA
                    ? [f.searchType ? `${f.field}__${f.searchType}` : f.field]
                    : [],
            ),
        );

    const newObj = Object.keys(fields).map((key) => {
        const modKey = modifySearchKey(key);
        let ind = textSearchFields.indexOf(modKey);
        if (ind === -1) {
            return { [key]: fields[key] };
        } else {
            const { prependToSearch = '', appendToSearch = '' } = getSearchFieldConfig(
                modKey,
                viewSearchFields,
            ).getOrElse({});
            const value = fields[key] ? prependToSearch + fields[key] + appendToSearch : fields[key];

            switch (modKey.split('__').pop()) {
                case 'EXACT_OR_NULL':
                case 'EXACT':
                    return { [key]: value };
                case 'CONTAINS_OR_NULL':
                case 'CONTAINS':
                    if (value) {
                        return { [key]: `*${value}*` };
                    }
                    return { [key]: '*' };
                case 'STARTS_WITH_OR_NULL':
                case 'STARTS_WITH':
                    return { [key]: `${value}*` };

                default:
                    return { [key]: `${value}` };
            }
        }
    });
    return Object.assign({}, ...newObj);
};
