import React, { Component } from 'react';
import SimpleList from './mobile/SimpleList';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { Subtract } from 'utility-types';
import PopoverEditButton from '../../../fieldFactory/popovers/PopoverEditButton';
import { getViewIndexAndAdditionalConfigFields } from '../utils/viewConfigUtils/index';
import LocalFilter from './LocalFilter';
import {
    getView,
    getPluralName,
    getAccessLevelForEntity,
    allowsEdit,
    getDefaultSort,
} from '../utils/viewConfigUtils/index';
import { loadValueSets as loadValueSetsAction } from 'valueSets/actions';
import CustomList, {
    ConnectedListProps,
    RenderActionsArguments,
    RenderListArguments,
    RenderFilterArguments,
} from './List';
import ShowButton from '../button/RedirectShowButton';
import Actions from './ListActions';
import applySortableOverrides from '../fields/applySortableOverrides';
import {
    setAsTopView as setAsTopViewAction,
    unsetAsTopView as unsetAsTopViewAction,
} from '../../../popoverStackManagement/actions';
import { FieldFactorySubscriber } from '../../../fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { customShowRedirects, inListDeletes } from '../overrides';
import { getAllPrefilters } from '../utils/viewConfigUtils/index';
import { withWidth } from '@material-ui/core';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import getRenderList from './renderList';
import Pagination from 'components/generics/genericList/Pagination';
import { tryCatch, fromPredicate } from 'fp-ts/lib/Option';

import ViewConfig, { View } from '../../../reducers/ViewConfigType';
import InlineDeleteButton from '../button/InlineDeleteButton';
import DeferredSpinner from 'components/DeferredSpinner';
import BranchSearchValuesetsLoading from './BranchSearchValuesetsLoading';
import { tableRowContext } from 'fieldFactory/input/components/EditableTable/util/tableRowContext';
import HtmlDisplayComponent from 'fieldFactory/display/components/HtmlDisplay';
import EntityInspect, { EntityInspectProps } from '../hoc/EntityInspect';
import memoizeOne from 'memoize-one';
import useViewConfig from 'util/hooks/useViewConfig';
import getFields from './getFields';
import { getUseNativeSelectSelector } from 'util/applicationConfig';
import { RootState } from 'reducers/rootReducer';
import { ListExpressionEditor } from 'layout-editor/UniversalViewWizard/steps/Step3List';
import getFormId from './filter/getFormId';
import { ListConfig } from 'layout-editor/listview-builder/ConfigEditor';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import Message from 'i18n/components/Message';

export const getRenderAtRowEnd =
    (settings: { displayPopoverEditButton: boolean; rowButtons?: JSX.Element | null }) =>
    (rargs: RenderListArguments, record: { id: string }) =>
        [
            ...(inListDeletes[rargs.resource]
                ? [
                      <Message id="processes.processDetails.unlinkCase" dm="Unlink Case">
                          {(m) => <InlineDeleteButton id={record.id} resource={rargs.resource} title={m} />}
                      </Message>,
                  ]
                : []),
            ...(customShowRedirects[rargs.resource]
                ? [<ShowButton record={record} resource={rargs.resource} key="showButton" />]
                : []),
            ...(settings.displayPopoverEditButton
                ? [<PopoverEditButton key="popoverEditButton" record={record} basePath={`/${rargs.resource}`} />]
                : []),
            ...(settings.rowButtons ? [settings.rowButtons] : []),
        ];

export type GenericListWithDefaultProps = {
    fetchKey?: any;
    printMode?: boolean;
    forceDatagridNotReady?: boolean;
    keyForPrev?: string;
    cancelRequestOnRouteChange?: boolean;
    resultHeadingText?: string | null;
    formId?: string | null;
    isPopover?: boolean;
    setAsTopView: (formId: string) => void;
    unsetAsTopView: (formId: string) => void;
    viewConfig: ViewConfig;
    viewName: string;
    resource: string;
    overrideApi?: string;
    noClick?: boolean;
    loadValueSets: typeof loadValueSetsAction;
    nativeSelect?: boolean;
    title?: JSX.Element | string | null;
    showFilters?: boolean;
    filter?: {};
    referencedFromEntity?: boolean;
    referencedByField?: string | null;
    actions?: React.ReactElement<{ accessLevel: number } & RenderActionsArguments> | null; // define this type
    displayRowEditButton?: boolean;
    rowButtons?: JSX.Element | null;
    showCheckBox?: boolean;
    width: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    renderFilter?: (
        args: RenderFilterArguments & {
            defaultRenderer: (args: RenderFilterArguments) => JSX.Element | null;
        },
    ) => JSX.Element | null;
    renderList?: (
        args: RenderListArguments & {
            defaultRenderer: (
                args: RenderListArguments & {
                    renderAtRowEnd?: ReturnType<typeof getRenderAtRowEnd>;
                    referencedByField?: string;
                },
            ) => JSX.Element | null;
        },
    ) => JSX.Element | null;
} & Pick<
    ConnectedListProps,
    | 'alwaysPreventInitialSearch'
    | 'noRecentlyVisited'
    | 'appendExpansions'
    | 'updateUrlFromFilter'
    | 'fakePush'
    | 'useCard'
    | 'multiSelectable'
    | 'fetchOnMount'
    | 'renderWhileNoPrevAndSpinnerDeferred'
    | 'sort'
    | 'customTitleElement'
    | 'canSelectRows'
    | 'embeddedInFormId'
    | 'createRedirectQueryString'
    | 'showCreate'
    | 'hasCreate'
    | 'location'
    | 'selectedData'
    | 'onRowSelect'
    | 'perPage'
    | 'fields'
    | 'showImmediately'
> &
    Partial<
        Pick<
            ConnectedListProps,
            'renderActions' | 'renderBelowActions' | 'renderPagination' | 'renderNoResults' | 'usePrevWhenLoading'
        >
    >;
interface DefaultProps {
    showFilters: boolean;
    showCheckBox: boolean;
    isPopover: boolean;
    displayRowEditButton: boolean;
}
export const GenericList: React.ComponentType<GenericListWithDefaultProps> = class GL extends Component<
    GenericListWithDefaultProps & DefaultProps
> {
    static defaultListRenderer = getRenderList({}, {});
    static displayName = 'GenericListComponent';
    static defaultProps = {
        showFilters: true,
        showCheckBox: false,
        displayRowEditButton: false,
        isPopover: false,
    };
    _getViewName = memoizeOne((viewConfig: ViewConfig, viewName: string) => {
        const [viewIndex] = getViewIndexAndAdditionalConfigFields(viewName, viewConfig, 'ALWAYS_LINKEDENTITY');
        return viewIndex;
    });
    getViewName = () => this._getViewName(this.props.viewConfig, this.props.viewName);
    componentDidMount() {
        const { formId, isPopover, setAsTopView } = this.props;
        if (formId && isPopover) {
            setAsTopView(formId);
        }
    }
    componentWillUnmount() {
        if (this.props.formId && this.props.isPopover) {
            this.props.unsetAsTopView(this.props.formId);
        }
    }
    getAccessLevel = () => {
        const { viewConfig, resource } = this.props;
        return getAccessLevelForEntity(viewConfig, resource);
    };
    getResultHeadingText = (hasSomeVisibleSearchFields: boolean) => {
        const { resultHeadingText, showFilters } = this.props;
        const text = resultHeadingText
            ? resultHeadingText
            : showFilters && hasSomeVisibleSearchFields
            ? 'Search Results'
            : undefined;
        return text && <h2 style={{ marginTop: '0.5em', marginBottom: '0.5em' }}>{text}</h2>;
    };
    defaultRenderList = (props: RenderListArguments & { renderAtRowEnd?: ReturnType<typeof getRenderAtRowEnd> }) => {
        const accessLevel = this.getAccessLevel();
        return this.props.width === 'xs' ? (
            <SimpleList
                {...props}
                multiSelectable={this.props.multiSelectable}
                primaryText={(record) => record.title}
                secondaryText={(record) => record.subtitle}
                resultHeadingText={this.getResultHeadingText(props.hasSomeVisibleSearchFields)}
            />
        ) : (
            GL.defaultListRenderer({
                ...props,
                ariaProps: {
                    ...props.ariaProps,
                    'aria-label': this.getTableAriaLabel(props),
                },
                resultHeadingText: props.listIsLoading ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>{this.getResultHeadingText(props.hasSomeVisibleSearchFields)}</div>
                        <div style={{ marginLeft: '.5em' }}>
                            <DeferredSpinner
                                renderSpinner={() => (
                                    <div style={{ height: 50, width: 50, overflow: 'hidden' }}>
                                        <div className="loader">Search In Progress...</div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                ) : (
                    this.getResultHeadingText(props.hasSomeVisibleSearchFields)
                ),
                renderAtRowEnd:
                    props.renderAtRowEnd ||
                    getRenderAtRowEnd({
                        displayPopoverEditButton: this.props.displayRowEditButton && allowsEdit(accessLevel),
                        rowButtons: this.props.rowButtons,
                    }),
            })
        );
    };
    defaultRenderFilter = ({
        filterValues,
        setFilters,
        submitFilters,
        clearFilters,
        formId,
        permanentFilter,
        displayHeader = true,
        hasSomeVisibleSearchFields,
    }: RenderFilterArguments) => {
        const { viewConfig, viewName, resource } = this.props;
        const styles = {
            paddingBottom: '1em',
        };
        const htmlText =
            viewConfig.views[viewName].config && JSON.parse(viewConfig.views[viewName].config).searchInstruction
                ? JSON.parse(viewConfig.views[viewName].config).searchInstruction
                : '';
        return this.props.showFilters ? (
            <div>
                {hasSomeVisibleSearchFields && displayHeader && <h2>Search Criteria</h2>}
                <HtmlDisplayComponent elStyle={styles} html={htmlText} />
                {hasSomeVisibleSearchFields && (
                    <div>
                        <LocalFilter
                            referencedFromEntity={this.props.referencedFromEntity}
                            viewConfig={viewConfig}
                            viewName={viewName}
                            formId={getFormId(resource, formId)}
                            resource={resource}
                            filterValues={filterValues}
                            setFilters={setFilters}
                            submitFilters={submitFilters}
                            clearFilters={clearFilters}
                            permanentFilter={permanentFilter}
                            showButtons={true} // i.e. has some editable search fields.
                            // if we have none, then we submit the search right away.
                        />
                        <div style={{ clear: 'both' }} />
                    </div>
                )}
            </div>
        ) : null;
    };
    renderFilter = (args: RenderFilterArguments) => {
        return this.props.renderFilter
            ? this.props.renderFilter({ ...args, defaultRenderer: this.defaultRenderFilter })
            : this.defaultRenderFilter(args);
    };
    getCustomTitleElementText: () => string | undefined = () => {
        const { customTitleElement } = this.props;
        if (customTitleElement) {
            if (typeof customTitleElement === 'string') {
                return customTitleElement;
            } else if (React.isValidElement(customTitleElement)) {
                const children = (customTitleElement.props as { children?: any }).children;
                if (typeof children === 'string' && !!children.trim()) {
                    return children;
                }
            }
        }
        return undefined;
    };
    getTableAriaLabel = (args: RenderListArguments) => {
        const ariaLabelKnownInternally =
            this.getCustomTitleElementText() ||
            (this.props.title && typeof this.props.title === 'string' && this.props.title.trim()
                ? this.props.title
                : getPluralName(this.props.viewConfig, this.props.resource));
        return (
            (args.ariaProps &&
                args.ariaProps['aria-label'] &&
                typeof args.ariaProps['aria-label'] === 'string' &&
                args.ariaProps['aria-label'].trim()) ||
            ariaLabelKnownInternally
        );
    };
    getRenderList = () => {
        const { renderList } = this.props;
        if (renderList) {
            return (args: RenderListArguments) =>
                renderList({
                    ...args,
                    ariaProps: {
                        ...args.ariaProps,
                        'aria-label': this.getTableAriaLabel(args),
                    },
                    defaultRenderer: this.defaultRenderList,
                    resultHeadingText: this.getResultHeadingText(args.hasSomeVisibleSearchFields),
                });
        }
        return this.defaultRenderList;
    };
    render() {
        const { viewName, resource, viewConfig, filter = {}, referencedFromEntity, printMode } = this.props;

        const view = getView(viewConfig, this.getViewName());
        const displayName = getPluralName(viewConfig, resource);
        const accessLevel = this.getAccessLevel();

        const listConfig = fromPredicate<View>(Boolean)(viewConfig.views[this.getViewName()])
            .chain((view) => fromPredicate<string>(Boolean)(view.config))
            .chain((c) => tryCatch(() => JSON.parse(c) as ListConfig));

        const alwaysPreventInitialSearch = referencedFromEntity
            ? false
            : listConfig
                  .mapNullable((c) => c.alwaysPreventInitialSearch)
                  .getOrElse(this.props.alwaysPreventInitialSearch);

        const title =
            typeof this.props.title !== 'undefined'
                ? this.props.title
                : listConfig.chain((c) => fromPredicate<string>(Boolean)(c.overrideTitle)).getOrElse(displayName);

        const prefilters = getAllPrefilters(viewConfig, resource, viewName, 'NON_DEFAULT');

        return (
            <BranchSearchValuesetsLoading viewName={viewName} fetchValuesets={true}>
                <CustomList
                    {...this.props}
                    alwaysPreventInitialSearch={alwaysPreventInitialSearch}
                    title={typeof title === 'string' ? <SafeHtmlAsReact html={title} /> : title}
                    filter={prefilters ? { ...filter, ...prefilters } : filter}
                    renderFilter={this.renderFilter}
                    renderActions={
                        printMode
                            ? () => null
                            : this.props.renderActions
                            ? this.props.renderActions
                            : (renderProps) =>
                                  typeof this.props.actions !== 'undefined' ? (
                                      this.props.actions &&
                                      React.cloneElement(this.props.actions, { ...renderProps, accessLevel })
                                  ) : (
                                      <Actions
                                          {...renderProps}
                                          formId={this.props.formId}
                                          accessLevel={accessLevel}
                                          roles={
                                              this.props.viewConfig &&
                                              this.props.viewConfig.user &&
                                              this.props.viewConfig.user.roles
                                          }
                                      />
                                  )
                    }
                    view={view}
                    sort={getDefaultSort(viewConfig, viewName)}
                    renderList={this.getRenderList()}
                    renderPagination={
                        this.props.renderPagination
                            ? this.props.renderPagination
                            : (props) => (
                                  <Pagination
                                      SelectProps={{
                                          inputProps: {
                                              'aria-label': 'Rows per page',
                                          },
                                          native: this.props.nativeSelect,
                                      }}
                                      {...props}
                                  />
                              )
                    }
                />
            </BranchSearchValuesetsLoading>
        );
    }
};

type ConnectedProps = Pick<
    GenericListWithDefaultProps,
    'loadValueSets' | 'viewConfig' | 'setAsTopView' | 'unsetAsTopView' | 'fields' | 'width'
>;

const mapStateToProps = (state: RootState) => {
    return {
        printMode: state.printMode,
        nativeSelect: getUseNativeSelectSelector(state),
    };
};
const enhance = compose(
    withWidth({
        initialWidth: 'md',
    }),
    (BaseComponent) => (props) => {
        const viewConfig = useViewConfig();
        return <BaseComponent {...props} viewConfig={viewConfig} />;
    },
    connect(mapStateToProps, {
        loadValueSets: loadValueSetsAction,
        setAsTopView: setAsTopViewAction,
        unsetAsTopView: unsetAsTopViewAction,
    }),
    branch(
        (props) => !props.viewConfig || Object.keys(props.viewConfig).length === 0, // if no viewConfig, render nothing.
        renderNothing,
    ),
    withPropsOnChange(['viewName', 'fieldFactory', 'referencedFromEntity', 'referencedByField'], (props) => {
        const { viewConfig, viewName, record, resource, basePath, match, referencedFromEntity, referencedByField } =
            props;

        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
                // defaultValue: 'None'
            },
        };
        return {
            fields: props
                .fieldFactory(config)({ record, resource, basePath, match, isForSearch: true })(
                    getFields(viewConfig, viewName, referencedFromEntity, referencedByField),
                )
                .map(applySortableOverrides(resource)),
        };
    }),
);

type GenericListProps = Subtract<GenericListWithDefaultProps, ConnectedProps>;

const GenericListContainer: React.FC<GenericListProps & { fieldFactory: Function }> = enhance(GenericList);
const GenericListWithFieldFactory: React.FC<GenericListProps> = (props) => (
    <ListExpressionEditor viewName={props.viewName}>
        {({ open }) => (
            <tableRowContext.Provider value={null}>
                <FieldFactorySubscriber>
                    {(fieldFactory) => <GenericListContainer {...props} fieldFactory={fieldFactory} />}
                </FieldFactorySubscriber>
            </tableRowContext.Provider>
        )}
    </ListExpressionEditor>
);

export default GenericListWithFieldFactory;

type GenericListWithPopoversProps = Subtract<
    EntityInspectProps,
    { renderComponent: EntityInspectProps['renderComponent'] }
> &
    GenericListProps;

const emptyObj = {};
export const GenericListWithPopovers: React.FC<GenericListWithPopoversProps> = (props) => (
    <tableRowContext.Provider value={null}>
        <EntityInspect
            {...props}
            renderComponent={({ formId, reference, keyForReload, onRowSelect, selectId, selectedId }) => (
                <div>
                    <GenericListWithFieldFactory
                        formId={formId}
                        resource={reference}
                        //                    reference={reference}
                        viewName={props.viewName}
                        // we don't want the record to be highlighted, but this turns off deselectOnClickaway
                        selectedData={emptyObj}
                        // shouldn't this just be 'key' ?
                        key={keyForReload}
                        onRowSelect={
                            customShowRedirects[reference] && customShowRedirects[reference].find((r) => r._isRowClick)
                                ? undefined
                                : onRowSelect
                        }
                        {...props}
                    />
                </div>
            )}
        />
    </tableRowContext.Provider>
);
