import { TASK_DRAWER_CLOSE, TASK_DRAWER_OPEN } from '../../actions/constants';

export default (state: boolean = false, action): boolean => {
    switch (action.type) {
        case TASK_DRAWER_OPEN:
            return true;
        case TASK_DRAWER_CLOSE:
            return false;
        default:
            return state;
    }
};
