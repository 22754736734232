import * as listActions from 'sideEffect/crud/getList/actions';
import { getFilterString } from '../../reducers/lists';
import { fromNullable } from 'fp-ts/lib/Option';
import { crudGetListSuccess } from 'sideEffect/crud/getList/actions';
import { ActionType, getType } from 'typesafe-actions';

export interface CurrentProcessTaskSearch {
    [processId: string]: string;
}

const isTaskListForProcessQuery = (action: ActionType<typeof crudGetListSuccess>) => {
    return (
        fromNullable(action)
            .mapNullable((a) => a.requestPayload)
            .mapNullable((rp) => rp.view)
            .getOrElse(undefined) === '_TASK_LIST_FOR_PROCESS'
    );
};

export default (prevState: CurrentProcessTaskSearch = {}, action): CurrentProcessTaskSearch => {
    if (getType(listActions.crudGetListSuccess) && isTaskListForProcessQuery(action)) {
        const processId = ((action.requestPayload || {}).filter || {})['processInstance.id'];
        return {
            ...prevState,
            [processId]: getFilterString(action),
        };
    }
    return prevState;
};
