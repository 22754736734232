import React, { FunctionComponent } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, IconButton } from '@material-ui/core';
import ControlledTextField from 'react-hook-form-utils/ControlledTextField';
import { TaskForm } from '../../../reducers/taskFormType';
import Popup from 'components/Popup';
import get from 'lodash/get';
import SpelExpressionForm from '../SpelExpressionForm';
import EditIcon from '@material-ui/icons/Edit';

interface EditOutcomeProps {
    initialValues?: TaskForm['outcomes'][0];
    onSubmit: (data: TaskForm['outcomes'][0]) => void;
    disabled?: boolean;
    taskForm: TaskForm;
}
const EditOutcome: FunctionComponent<EditOutcomeProps> = (props) => {
    const methods = useForm({
        defaultValues: props.initialValues,
        mode: props.disabled ? 'onSubmit' : 'onBlur',
    });

    const renderExpressionField = (name: string, label: string) => (
        <Controller
            name={name}
            control={methods.control}
            defaultValue={get(props.initialValues, name) ?? ''}
            render={({ onChange, value }) => {
                return (
                    <Popup
                        paperStyle={{
                            minWidth: 'min(600px, 90vw)',
                        }}
                        renderDialogContent={({ closeDialog }) => (
                            <div onSubmit={(e) => e.stopPropagation()} style={{ padding: '1em' }}>
                                <SpelExpressionForm
                                    optional
                                    taskForm={props.taskForm}
                                    initialExpression={value ?? ''}
                                    onSubmit={(params) => {
                                        const { expression } = params;
                                        onChange(expression);
                                        closeDialog();
                                    }}
                                    renderActions={({ SaveButton }) => (
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button variant="contained" onClick={closeDialog}>
                                                Cancel
                                            </Button>
                                            {SaveButton}
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <div>
                                <i>{label}</i>
                                <span style={{ marginLeft: '1em' }}>{value}</span>
                                <span style={{ marginLeft: '1em' }}>
                                    <IconButton aria-label={`Edit ${label} expression`} onClick={openDialog()}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                            </div>
                        )}
                    />
                );
            }}
        />
    );

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit((data) => {
                    props.onSubmit(data);
                })}
            >
                <ControlledTextField
                    disabled={props.disabled}
                    rules={{ required: 'All outcomes need names' }}
                    label="Name"
                    name="name"
                    defaultValue={props.initialValues?.['name']}
                />
                {renderExpressionField('configs.visibility', 'Visibility Expression')}
                {renderExpressionField('configs.editable', 'Editable Expression')}
                {renderExpressionField('configs.display', 'Display Expression')}
                {!props.disabled && (
                    <Button color="primary" variant="contained" type="submit">
                        Save
                    </Button>
                )}
            </form>
        </FormProvider>
    );
};
export default EditOutcome;
