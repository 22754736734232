import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../../components/AttemptRequest';
import * as config from '../../../../config';
import FormSaveNotifierStatus from 'formSaveNotifier/components/FormSaveNotifierStatus';
import buildHeaders from 'sideEffect/buildHeaders';

const getLazyR = (taskId: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/tasks/${taskId}/action/cancel`, {
        method: 'PUT',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
    });

export const cancelTaskButton = (taskId: string) => (onSuccessCb: () => void) =>
    (
        <FormSaveNotifierStatus>
            {({ disable, reenable }) => (
                <AttemptRequest
                    type="external"
                    renderer={({ attemptAction: _attemptAction }) => {
                        const attemptAction = () => {
                            disable();
                            _attemptAction();
                        };
                        return (state) =>
                            state._tag === 'failure' ? (
                                <div>
                                    Action failed.
                                    <a // eslint-disable-line
                                        href="javascript:;" // eslint-disable-line
                                        onClick={attemptAction}
                                    >
                                        try again?
                                    </a>
                                </div>
                            ) : state._tag === 'pending' ? (
                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    color="default"
                                    disabled={true}
                                >
                                    Save
                                    <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                                </Button>
                            ) : state._tag === 'unsubmitted' ? (
                                <Button
                                    style={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={attemptAction}
                                >
                                    Cancel Task
                                </Button>
                            ) : (
                                <span style={{ marginLeft: 'auto' }}>Success!</span>
                            );
                    }}
                    lazyRequest={getLazyR(taskId)}
                    onSuccess={() => {
                        onSuccessCb();
                        reenable();
                    }}
                    onErrorsCbs={{
                        '*': reenable,
                    }}
                />
            )}
        </FormSaveNotifierStatus>
    );
