import React from 'react';
import { CardContent, CardActions, CardHeader } from '@material-ui/core';
import { Dialog, Button } from '@material-ui/core';

interface RemoveOfflineDataDialogProps {
    open: boolean;
    remove: (removeData: boolean) => void;
}
const RemoveOfflineDataDialog: React.FunctionComponent<RemoveOfflineDataDialogProps> = ({ open, remove }) => {
    const [submitted, setSubmitted] = React.useState(false);
    return (
        <Dialog
            open={open}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <CardHeader title="Remove offline?" />
            <CardContent>
                Your data was saved successfully. Would you like to keep the task available offline?
            </CardContent>
            <CardActions>
                <Button
                    disabled={submitted}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitted(true);
                        remove(true);
                    }}
                >
                    Remove offline
                </Button>
                <Button
                    disabled={submitted}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSubmitted(true);
                        remove(false);
                    }}
                >
                    Keep available offline
                </Button>
            </CardActions>
        </Dialog>
    );
};

export default RemoveOfflineDataDialog;
