import React from 'react';
interface WithErrorBoundaryProps {
    notes?: string;
    collapsedNotes?: string;
}
interface WithErrorBoundaryState {
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}
class WithErrorBoundary extends React.Component<WithErrorBoundaryProps, WithErrorBoundaryState> {
    constructor(props: WithErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }
    reset = () => {
        this.setState({ error: null, errorInfo: null });
    };
    render() {
        if (this.state.errorInfo) {
            return (
                <div
                    style={{
                        background: `repeating-linear-gradient(
                        -55deg,
                        rgba(0,0,0,0.05),
                        rgba(0,0,0,0.05) 10px,
                        rgba(0,0,0,0) 10px,
                        rgba(0,0,0,0) 20px
                    )`,
                    }}
                >
                    <h3>Something went wrong.</h3>
                    {this.props.notes && <pre>{this.props.notes}</pre>}
                    <button onClick={this.reset}>Retry</button>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.props.collapsedNotes && <pre>{this.props.collapsedNotes}</pre>}
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}
export default WithErrorBoundary;
