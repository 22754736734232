/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['-2afcb9a5'] = validate10;
const schema11 = {
    type: 'object',
    title: 'Link',
    properties: { url: { title: 'URL', type: 'string' } },
    dependencies: {},
    required: [],
    $id: '-2afcb9a5',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="-2afcb9a5" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.url !== undefined) {
            let data0 = data.url;
            if (typeof data0 !== 'string') {
                const err0 = {
                    instancePath: instancePath + '/url',
                    schemaPath: '#/properties/url/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.url.type,
                    parentSchema: schema11.properties.url,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
