import React from 'react';
import useEvaluateInEntityContext from 'expressions/hooks/useEvaluateInEntityContext';

interface EvaluateInEntityContextProps {
    expression: string;
    defaultOnException: any;
    useLiveValues?: boolean;
    children: (arg: { expressionResult: any }) => JSX.Element;
}
const EvaluateInEntityContext: React.FC<EvaluateInEntityContextProps> = (props) => {
    const { defaultOnException } = props;
    const expressionResult = useEvaluateInEntityContext(props.expression, {
        throwOnException: false,
        defaultOnException,
        useLiveValues: props.useLiveValues,
    });
    return props.children({ expressionResult });
};

export default EvaluateInEntityContext;
