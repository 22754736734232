import * as React from 'react';
import { bindMenu } from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';

export const CascadingContext = React.createContext({
    parentPopupState: null,
    rootPopupState: null,
});

/**
 *
 * Examples
 *  of material-ui-popup-state
 */
/*
const useCascadingMenuStyles = makeStyles((theme) => ({
  submenu: {

  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {

  },
}))
export function CascadingMenuItem({ onClick, ...props }: any) {
  const { rootPopupState } = React.useContext(CascadingContext)
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick]
  )

  return <MenuItem {...props} onClick={handleClick} />
}

export function CascadingSubmenu({ title, popupId, ...props }: any) {
  const classes = useCascadingMenuStyles()
  const { parentPopupState } = React.useContext(CascadingContext)
  return (
    <PopupState
      popupId={popupId}
      variant="popover"
      parentPopupState={parentPopupState}
      disableAutoFocus
    >
      {(popupState) => (
        <React.Fragment>
          <MenuItem {...bindHover(popupState)}>
            <span className={classes.title}>{title}</span>
            <ChevronRight className={classes.moreArrow} />
          </MenuItem>
          <CascadingMenu
            {...props}
            classes={{ ...props.classes, paper: classes.submenu }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            popupState={popupState}
          />
        </React.Fragment>
      )}
    </PopupState>
  )
}
*/

export function CascadingMenu({ popupState, ...props }) {
    const { rootPopupState } = React.useContext(CascadingContext);
    const context = React.useMemo(
        () => ({
            rootPopupState: rootPopupState || popupState,
            parentPopupState: popupState,
        }),
        [rootPopupState, popupState],
    );

    return (
        <CascadingContext.Provider value={context}>
            <HoverMenu {...props} {...bindMenu(popupState)} />
        </CascadingContext.Provider>
    );
}
