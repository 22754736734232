import lists from '../reducers/lists';
import listIsLoading from '../reducers/lists/listLoading';

export default (r) => ({
    [r.name]: {
        // data: data(r.name)(undefined, { type: null }),
        lists: lists(r.name)(undefined, { type: null }),
        listIsLoading: listIsLoading(r.name)(undefined, { type: null }),
    },
});
