import React, { useEffect, useState, useCallback } from 'react';
import { createStyles, Theme, makeStyles, Dialog, Card, CardContent, Button, CardActions } from '@material-ui/core';
import HtmlDisplayComponent from 'fieldFactory/display/components/HtmlDisplay';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        root: {
            color: palette.error.main,
        },
    }),
);

export interface DisplaySubmissionErrorProps {
    errorKey: number;
    errorText?: string;
}

const DisplaySubmissionError: React.SFC<DisplaySubmissionErrorProps> = (props) => {
    const classes = useStyles(props);
    const { errorText, errorKey } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const closeDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    useEffect(() => {
        setDialogOpen(Boolean(errorText));
    }, [errorText, errorKey]);
    return (
        <div>
            <div role="alert" className={classes.root}>
                <HtmlDisplayComponent html={errorText} />
            </div>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
            >
                <Card>
                    <CardContent>
                        <Alert role={undefined} severity="error">
                            <AlertTitle id="submissionerror-title">Error</AlertTitle>
                            <div className={classes.root}>
                                <HtmlDisplayComponent html={errorText} />
                            </div>
                        </Alert>
                    </CardContent>
                    <CardActions>
                        <Button style={{ margin: '2px' }} variant="contained" color="primary" onClick={closeDialog}>
                            Close
                        </Button>
                    </CardActions>
                </Card>
            </Dialog>
        </div>
    );
};

export default DisplaySubmissionError;
