import { Button, ButtonProps } from '@material-ui/core';
import { processContext } from 'bpm/components/processContext';
import EvalExpressionInTaskContext from 'expressions/components/EvalExpressionInTaskFormContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../../../reducers/taskFormType';

import { WrappedFieldInputProps } from 'redux-form';
import { startFormContext } from 'bpm/start-form/components/startFormContext';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import startFormValidateContext from './startFormValidateContext';
import ErrorPopup from 'bpm/start-form/components/ValidationErrorPopup';

const StartFormContinueButton: React.FC<{
    formDefinition: TaskForm;
    onContinue: () => void;
    ButtonProps: ButtonProps;
}> = ({ formDefinition, onContinue, ButtonProps }) => {
    const { validate } = useContext(startFormValidateContext);
    const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);
    const handleClick = useCallback(() => {
        const errors = validate();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            setErrors(null);
            onContinue();
        }
    }, [validate, onContinue]);
    return (
        <>
            <Button onClick={handleClick} {...ButtonProps} />
            <ErrorPopup formErrors={errors} formDefinition={formDefinition} />
        </>
    );
};

// lets say that steps starts with 1.

interface WizardControlProps {
    input: WrappedFieldInputProps;
    steps: number;
    nextStepExpression?: string;
    nextButtonText?: string;
    backButtonText?: string;
    showInlinePageText?: boolean;
    validateBeforeContinue?: boolean;
}
const useFormDefinition = () => {
    const { taskId } = useContext(processContext);
    const taskForm = useSelector((state: RootState) => taskId && state.taskForms[taskId]);
    const startForm = useContext(startFormContext);
    return taskForm || startForm;
};
const WizardControl: React.FC<WizardControlProps> = ({
    steps,
    nextStepExpression,
    input,
    nextButtonText,
    backButtonText,
    showInlinePageText = false,
    validateBeforeContinue,
}) => {
    const isInStartForm = !Boolean(useContext(processContext).taskId);
    const { onBlur, value } = input;
    const isFirst = value === 1;
    const isLast = value === steps;

    const handleNext = useCallback(() => {
        onBlur(value + 1);
    }, [onBlur, value]);
    const handleBack = useCallback(() => {
        onBlur(value - 1);
    }, [onBlur, value]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [value]);

    const backText = useEvaluatedFormattedMessage(backButtonText ?? '') || 'Back';
    const nextText = useEvaluatedFormattedMessage(nextButtonText ?? '') || 'Next';

    const formDefinition = useFormDefinition();
    if (!formDefinition) {
        console.error('no formDefinition looked up by WizardControl');
        return null;
    }

    return (
        <EvalExpressionInTaskContext<{ nextStepAllowed: [string] }>
            formDefinition={formDefinition}
            expressions={{
                nextStepAllowed: [nextStepExpression],
            }}
        >
            {({ nextStepAllowed: [nextAllowed] }) => (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBack}
                        style={isFirst ? { visibility: 'hidden' } : undefined}
                    >
                        {backText}
                    </Button>
                    <span>
                        {showInlinePageText ? (
                            <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                Page {input.value} of {steps}&nbsp;&nbsp;&nbsp;
                            </span>
                        ) : null}
                        {isInStartForm && validateBeforeContinue ? (
                            <StartFormContinueButton
                                formDefinition={formDefinition}
                                onContinue={handleNext}
                                ButtonProps={{
                                    variant: 'contained',
                                    color: 'primary',
                                    disabled: !nextAllowed,
                                    style: isLast ? { visibility: 'hidden' } : undefined,
                                    children: nextText,
                                }}
                            />
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!nextAllowed}
                                onClick={handleNext}
                                style={isLast ? { visibility: 'hidden' } : undefined}
                            >
                                {nextText}
                            </Button>
                        )}
                    </span>
                </div>
            )}
        </EvalExpressionInTaskContext>
    );
};
export default WizardControl;
