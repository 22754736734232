import { GlobalAlert } from './definitions';
import { pending, success, failure, initial } from '@devexperts/remote-data-ts';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { getPublicGlobalAlertsSuccess, getPublicGlobalAlerts, getPublicGlobalAlertsFailure } from './public/actions';
import produce from 'immer';
import { combineReducers } from 'redux';
import {
    getPrivateGlobalAlerts,
    getPrivateGlobalAlertsSuccess,
    getPrivateGlobalAlertsFailure,
} from './private/actions';
import { deserialize, serialize, serialrd } from 'reducers/lists/list/serializeDeserialize';

interface GlobalAlertsById {
    [id: string]: GlobalAlert;
}

type PublicAlerts = serialrd<string[], unknown>;
type PrivateAlerts = serialrd<string[], unknown>;

const globalAlertsById = (state: GlobalAlertsById = {}, action: RootAction): GlobalAlertsById => {
    switch (action.type) {
        case getType(getPrivateGlobalAlertsSuccess):
        case getType(getPublicGlobalAlertsSuccess): {
            return produce(state, (draftState) => {
                const { globalAlerts = [] } = action.payload;
                (globalAlerts || []).forEach((globalAlert) => {
                    draftState[globalAlert.id] = globalAlert;
                });
                return draftState;
            });
        }
        default:
            return state;
    }
};

const publicAlerts = (state: PublicAlerts = serialize(initial), action: RootAction): PublicAlerts => {
    switch (action.type) {
        case getType(getPublicGlobalAlerts): {
            if (!deserialize(state).isSuccess()) {
                return serialize(pending);
            }
            return state;
        }
        case getType(getPublicGlobalAlertsSuccess): {
            const { globalAlerts = [] } = action.payload;
            return serialize(success((globalAlerts || []).map((globalAlert) => globalAlert.id)));
        }
        case getType(getPublicGlobalAlertsFailure): {
            if (!deserialize(state).isSuccess()) {
                return serialize(failure(action.error?.response));
            }
            return state;
        }
        default:
            return state;
    }
};

const privateAlerts = (state: PrivateAlerts = serialize(initial), action: RootAction): PrivateAlerts => {
    switch (action.type) {
        case getType(getPrivateGlobalAlerts): {
            if (!deserialize(state).isSuccess()) {
                return serialize(pending);
            }
            return state;
        }
        case getType(getPrivateGlobalAlertsSuccess): {
            const { globalAlerts = [] } = action.payload;
            return serialize(success((globalAlerts || []).map((globalAlert) => globalAlert.id)));
        }
        case getType(getPrivateGlobalAlertsFailure): {
            if (!deserialize(state).isSuccess()) {
                return serialize(failure(action.error?.response));
            }
            return state;
        }
        default:
            return state;
    }
};

const globalAlerts = combineReducers({
    globalAlertsById,
    publicAlerts,
    privateAlerts,
});
export default globalAlerts;
