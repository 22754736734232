import React from 'react';
import memoizeOne from 'memoize-one';

interface PaginationControls {
    page: number;
    setPage: (newPage: number) => void;
    perPage: number;
    setPerPage: (newPerPage: number) => void;
    currentPageIds: string[];
}
interface PaginatedDataProps {
    allIds: string[];
    defaultPerPage: number;
    children: (args: PaginationControls) => JSX.Element;
}
const PaginatedData: React.SFC<PaginatedDataProps> = (props) => {
    const getSetPerPage = React.useRef(
        memoizeOne(
            (
                setPage: PaginationControls['setPage'],
                setPerPage: PaginationControls['setPerPage'],
            ): PaginationControls['setPerPage'] => {
                return (newPerPage: number) => {
                    setPerPage(newPerPage);
                    setPage(0);
                };
            },
        ),
    );
    const [page, setPage] = React.useState(1);
    const [perPage, _setPerPage] = React.useState(props.defaultPerPage);
    const currentPageData = props.allIds.slice((page - 1) * perPage, (page - 1) * perPage + perPage);
    return props.children({
        currentPageIds: currentPageData,
        page,
        setPage,
        perPage,
        setPerPage: getSetPerPage.current(setPage, _setPerPage),
    });
};
export default PaginatedData;
