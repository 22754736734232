import React from 'react';
import { parse } from 'query-string';
import { Route, Link } from 'react-router-dom';
import ProcessStartPage from 'bpm/start-form/components';
import { Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

const offlineRoutes = [
    <Route
        key={1}
        path="/process-definition/:processKey/start-form"
        component={({ match, location }) => (
            <>
                <Button component={Link} to="/">
                    <ArrowBack />
                    &nbsp;Back
                </Button>
                <div style={{ height: '1em' }} />
                <ProcessStartPage
                    offlineMode
                    businessKey={match.params.processKey}
                    queryParams={parse(location.search)}
                />
            </>
        )}
    />,
];
export default offlineRoutes;
