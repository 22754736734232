import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme, Collapse, Typography } from '@material-ui/core';
import isOffline from 'util/isOffline';
import isTheInstalledApp from 'util/isTheInstalledApp';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            // textAlign: 'center',
            display: 'flex',
            // justifyContent: 'space-between',
            justifyContent: 'space-evenly',
            backgroundColor: theme.palette.error.dark,
            color: 'white',
            position: 'relative',
        },
        connected: {
            textAlign: 'center',
            backgroundColor: '#43A047',
            color: 'white',
        },
    }),
);

const Banner: React.SFC<{ isOnline: boolean }> = (props) => {
    const { isOnline } = props;
    const classes = useStyles(props);
    // normalizedSuccess: either already online, or turned online and banner can be removed
    // after wait
    const [normalizedSuccess, setNormalizedSuccess] = useState(isOnline);
    useEffect(() => {
        if (isOnline) {
            const t = setTimeout(() => {
                setNormalizedSuccess(true);
            }, 3000);
            return () => {
                clearTimeout(t);
            };
        }
        if (!isOnline) {
            setNormalizedSuccess(false);
        }
    }, [isOnline, setNormalizedSuccess]);
    const hasOfflineApp = (window as any).CASETIVITY_USE_SERVICE_WORKER;

    const renderReloadAnchor = (visible = true) =>
        !hasOfflineApp || isOffline() || !isTheInstalledApp() ? null : (
            <div aria-hidden={!visible}>
                <a
                    href="/?offline=1"
                    style={{
                        visibility: visible ? 'visible' : 'hidden',
                        color: 'white',
                        verticalAlign: '-webkit-baseline-middle',
                        height: '100%',
                    }}
                >
                    Switch to offline mode
                </a>
            </div>
        );
    return (
        <div role="alert">
            <Collapse in={!isOnline} mountOnEnter unmountOnExit>
                <div className={classes.error}>
                    {/* <div /> */}

                    {renderReloadAnchor(false)}
                    <Typography variant="h6">No Connection</Typography>
                    {renderReloadAnchor(true)}
                </div>
            </Collapse>
            <Collapse in={isOnline && !normalizedSuccess} mountOnEnter unmountOnExit>
                <div className={classes.connected}>
                    <Typography variant="h6">Back Online</Typography>
                </div>
            </Collapse>
        </div>
    );
};
export default Banner;
