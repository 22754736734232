import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReportDefinition } from 'report2/ReportDefinition';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import useRemoteData from 'util/hooks/useRemoteData';
import ReportEditor from './Editor';
import { PersonalizedReportConfig } from 'custom-reports/types';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { useStore } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Helmet from 'react-helmet';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';
import useCustomReportFieldError from './useCustomReportFieldError';
import getInitialConfig from './getInitialConfig';

interface ExtendReportProps {
    name: string;
}

const useReport = (name: string) => {
    const { state, fold, setSuccess, setError, setPending } = useRemoteData<ReportDefinition, string>();
    useEffect(() => {
        setPending();
        const $ajax = services.getReportDefinition(name);
        const subscription = $ajax.subscribe(
            (res) => {
                setSuccess(res);
            },
            (error: AjaxError) => {
                setError(error.message);
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [name, setError, setPending, setSuccess]);
    return state;
};

const CreateReport = (props: { fromDefinition: ReportDefinition }) => {
    const initialConfig = useMemo(() => getInitialConfig(props.fromDefinition), [props.fromDefinition]);
    const store = useStore();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const nameTouched = useRef(false);
    if (name !== '') {
        nameTouched.current = true;
    }
    const descriptionTouched = useRef(false);
    if (description !== '') {
        descriptionTouched.current = true;
    }
    const submit = (config: PersonalizedReportConfig) => {
        const $ajax = services.personalizedReports.create({
            name,
            description,
            parentReportId: props.fromDefinition.reportDefinitionDTO.id,
            config: JSON.stringify(config),
        });
        $ajax.subscribe(
            (res) => {
                const id = res.response['id'];
                store.dispatch(push(`/edit-personalized-report/${id}`));
            },
            (err) => {
                console.log({ err });
            },
        );
    };
    const nameError = useCustomReportFieldError('name', name);
    const descriptionError = useCustomReportFieldError('description', description);
    const hasError = Boolean(descriptionError || nameError);
    return (
        <div>
            <Helmet>
                <title>{`Personalize ${props.fromDefinition.reportDefinitionDTO.name}`}</title>
            </Helmet>
            <Card style={{ marginBottom: '1em' }}>
                <Grid container justifyContent="space-between" style={{ display: 'flex', padding: '1em' }}>
                    <div>
                        <Typography variant="h5">Create Personalized Report</Typography>
                    </div>
                    <div>
                        <Button component={Link} to="/reports?tab=standard" startIcon={<ArrowBack />}>
                            Back to all reports
                        </Button>
                    </div>
                </Grid>
                <Grid style={{ padding: '1em' }}>
                    <DebouncedField
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => setName(e.target.value)}
                        value={name}
                        label="Name"
                        InputProps={{
                            inputProps: {
                                'aria-label': 'Name',
                            },
                        }}
                        error={nameTouched.current && !!nameError}
                        helperText={
                            nameTouched.current && nameError && <EvaluatedFormattedMessage message={nameError} />
                        }
                    />
                </Grid>
                <Grid style={{ padding: '1em' }}>
                    <DebouncedField
                        multiline
                        fullWidth
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={(e) => setDescription(e.target.value)}
                        value={description}
                        label="Description"
                        InputProps={{
                            inputProps: {
                                'aria-label': 'Description',
                            },
                        }}
                        error={descriptionTouched.current && !!descriptionError}
                        helperText={
                            descriptionTouched.current &&
                            descriptionError && <EvaluatedFormattedMessage message={descriptionError} />
                        }
                    />
                </Grid>
            </Card>
            <ReportEditor
                disableSubmit={hasError}
                reportDefinition={props.fromDefinition}
                initialValue={initialConfig}
                onSubmit={(newConfig) => {
                    submit(newConfig);
                }}
                renderBelow={() => {
                    if (hasError) {
                        return (
                            <div style={{ margin: '1rem 0' }}>
                                <Alert severity="error">
                                    <AlertTitle>Fix before saving</AlertTitle>
                                    {descriptionError && (
                                        <p>
                                            Description: <EvaluatedFormattedMessage message={descriptionError} />
                                        </p>
                                    )}
                                    {nameError && (
                                        <p>
                                            Name: <EvaluatedFormattedMessage message={nameError} />
                                        </p>
                                    )}
                                </Alert>
                            </div>
                        );
                    }
                    return null;
                }}
            />
        </div>
    );
};
const ExtendReport = (props: ExtendReportProps) => {
    const reportState = useReport(props.name);

    return <div>{reportState.status === 'success' ? <CreateReport fromDefinition={reportState.data} /> : null}</div>;
};

export default ExtendReport;
