import React from 'react';

import Delete from '@material-ui/icons/Delete';
import AttemptRequest from 'components/AttemptRequest';
import Done from '@material-ui/icons/Done';
import buildHeaders from 'sideEffect/buildHeaders';
import { Button, CardActions, CardContent, CardHeader, CircularProgress, useTheme } from '@material-ui/core';
import Popup from 'components/Popup';

const config = require('../../config.js');

const getLazyR = (id: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/reports/personalize/${id}`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers: buildHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            includeCredentials: true,
        }),
    });

const DeletePersonalizedReportButton = (props: {
    id: string;
    onDelete: () => void;
    title?: string;
    renderTrigger?: (props: { onClick: () => void }) => JSX.Element;
}) => {
    const theme = useTheme();
    return (
        <Popup
            renderDialogContent={({ closeDialog }) => {
                return (
                    <AttemptRequest
                        type="external"
                        onSuccess={() => {
                            setTimeout(() => props.onDelete(), 1000);
                        }}
                        lazyRequest={getLazyR(props.id)}
                        renderer={({ attemptAction }) =>
                            (state) =>
                                (
                                    <React.Fragment>
                                        <CardHeader title={'Delete ' + (props.title ?? 'Personalized Report')} />
                                        <CardContent>
                                            <p>Are you sure you want to permanently delete this Personalized Report?</p>
                                        </CardContent>

                                        {state._tag === 'failure' ? (
                                            <CardContent
                                                style={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    color: theme.palette.error.dark,
                                                }}
                                            >
                                                {state.body && typeof state.body === 'object'
                                                    ? Object.values(state.body).map((msg, i) =>
                                                          typeof msg === 'string' ? (
                                                              <p key={i}>{msg}</p>
                                                          ) : (
                                                              <pre key={i}>{msg && JSON.stringify(msg, null, 4)}</pre>
                                                          ),
                                                      )
                                                    : 'There was a problem deleting.'}
                                            </CardContent>
                                        ) : state._tag === 'success' ? (
                                            <CardContent>
                                                <p>
                                                    <Done />
                                                    &nbsp;Report was deleted successfully.
                                                </p>
                                            </CardContent>
                                        ) : null}
                                        <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={closeDialog}
                                                aria-label="close"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    color: theme.palette.error.dark,
                                                }}
                                                variant="outlined"
                                                disabled={state._tag === 'pending'}
                                                onClick={attemptAction}
                                                endIcon={
                                                    state._tag === 'pending' ? (
                                                        <CircularProgress
                                                            style={{
                                                                height: 20,
                                                                width: 20,
                                                            }}
                                                        />
                                                    ) : undefined
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    </React.Fragment>
                                )}
                    />
                );
            }}
            renderToggler={({ openDialog }) =>
                props.renderTrigger?.({
                    onClick: openDialog(),
                }) ?? (
                    <Button
                        size="small"
                        style={{
                            color: theme.palette.error.dark,
                        }}
                        onClick={openDialog()}
                        variant="outlined"
                        endIcon={<Delete />}
                    >
                        Delete
                    </Button>
                )
            }
        />
    );
};

export default DeletePersonalizedReportButton;
