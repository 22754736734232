import { mapDataToExpression } from './mapDataToExpression';
const isObject = (value) => !!value && value.constructor === Object;
const isArray = (value) => !!value && value.constructor === Array;
// recurse through object tree and remove all @id keys

export type ExpressionType = {} | string | number | ExpressionArray;
interface ExpressionArray extends Array<ExpressionType> {}

function isString(data: undefined | string | number | {}): data is string {
    return typeof data === 'string';
}
const applyMappings = (
    value: ExpressionType,
    data: string | {} | (string | {})[],
    dataContext: string | string[] = 'record',
    failIfPropertyNotFound: boolean = true,
) => {
    if (isObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map((key) => ({
                [key]: applyMappings(value[key], data, dataContext, failIfPropertyNotFound),
            })),
        );
    }
    if (isArray(value) && value instanceof Array) {
        return value.map((o) => applyMappings(o, data, dataContext, failIfPropertyNotFound));
    }
    if (isString(value)) {
        return mapDataToExpression(value, data, dataContext, failIfPropertyNotFound);
    }
    return value;
};

export default applyMappings;
