import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { viewItemFilterExpressionsGenerated } from './actions';
import { ViewItemFilterExpressionsGeneratedType } from './ViewItemFilterExpressionsGeneratedType';

const filterExpressionsReducer = (
    previousState: ViewItemFilterExpressionsGeneratedType = {},
    action: RootAction,
): ViewItemFilterExpressionsGeneratedType => {
    switch (action.type) {
        case getType(viewItemFilterExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
export default filterExpressionsReducer;
