import { AppCase } from './appCase';
import IProcessInstance from '../../../../../types/processInstance';

export interface ProcessInstanceFromAppTasks {
    businessKey: string;
    id: string;
    processInstanceId: string;
    startTime: string; // yyyy-mm-ddThh:mm:ss.oooZ
    startUser: string;
    appCase?: AppCase;
    endTime?: string;
}

export const mapToAppType = ({
    id,
    businessKey,
    startTime,
    startUser,
    endTime,
}: ProcessInstanceFromAppTasks): IProcessInstance => ({
    id,
    businessKey,
    startTime: startTime && startTime.split('T')[0],
    startUser,
    endTime,
});

export const mapToApiType = () => new Error('unimplemented.');
