import React, { Suspense } from 'react';

const CodemirrorJSONEditor = React.lazy(() => import('./CodemirrorJSONEditor'));
const LazyCodemirrorJSONEditor = (props: { initialValue?: string; onChange?: (newValue: string) => void }) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <CodemirrorJSONEditor {...props} />
        </Suspense>
    );
};
export default LazyCodemirrorJSONEditor;
