import React, { useContext, useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { FormControl, InputLabel, Select, Card, Button } from '@material-ui/core';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import Automigrate from './Automigrate';
import needsManualMigration from './utils/needsManualMigration';
import { viewDefWizardContext } from 'layout-editor/viewdef-editor/viewDefWizardContext';

const MigrateEntityViews = (props) => {
    const viewConfig = useViewConfig();
    const [byManual, setByManual] = useState<'needsManual' | 'canAuto' | 'all'>('all');
    const handleChangeByManual = (event: React.ChangeEvent<{ value: unknown }>) => {
        setByManual(event.target.value as any);
    };
    const [viewType, setViewType] = useState('');
    const handleChangeViewType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setViewType(event.target.value as string);
    };
    const [selectedViewName, setSelectedViewName] = useState('');
    const ViewDefWizard = useContext(viewDefWizardContext);
    return (
        <div style={{ margin: '1em' }}>
            <SplitPane split="vertical">
                <Pane initialSize="20%">
                    <Card style={{ margin: '1em', padding: '1em' }}>
                        <div style={{ height: '1em' }} />
                        <FormControl fullWidth>
                            <InputLabel shrink={true} id="filter-by-manual-label">
                                Filter By Migration Possible
                            </InputLabel>
                            <Select
                                native
                                labelId="filter-by-manual-label"
                                id="filter-by-manual"
                                value={byManual}
                                onChange={handleChangeByManual}
                                fullWidth
                                style={{ minWidth: 100 }}
                            >
                                <option value="needsManual">Needs Manual Migration</option>
                                <option value="canAuto">Can Automigrate</option>
                                <option value="all">All</option>
                            </Select>
                        </FormControl>

                        <div style={{ height: '1em' }} />
                        <FormControl fullWidth>
                            <InputLabel shrink={true} id="filter-view-type-label">
                                Filter View Type
                            </InputLabel>
                            <Select
                                native
                                labelId="filter-view-type-label"
                                id="filter-view-type"
                                value={viewType}
                                onChange={handleChangeViewType}
                                fullWidth
                                style={{ minWidth: 100 }}
                            >
                                <option value="">None</option>
                                <option value="LIST">List</option>
                                <option value="SHOW">Show</option>
                                <option value="EDIT">Edit</option>
                                <option value="CREATE">Create</option>
                                <option value="MATCH">Match</option>
                                <option value="MERGE">Merge</option>
                            </Select>
                        </FormControl>
                        <div style={{ height: '1em' }} />
                        <ul id="entityview-list-tomigrate">
                            {Object.entries(viewConfig.views)
                                .sort(([a], [b]) => {
                                    if (a < b) {
                                        return -1;
                                    }
                                    if (a > b) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .flatMap(([vn, vd]) => {
                                    if (
                                        // filtered out
                                        (viewType !== '' && vd.viewType !== viewType) || // has a viewDef already generated
                                        (viewConfig.viewDefs && viewConfig.viewDefs[vn])
                                    ) {
                                        return [];
                                    }
                                    if (byManual === 'needsManual' || byManual === 'canAuto') {
                                        const needsManual =
                                            (viewType === 'EDIT' || viewType === 'CREATE' || viewType === 'SHOW') &&
                                            needsManualMigration(vd);
                                        if (
                                            (needsManual && byManual === 'canAuto') ||
                                            (!needsManual && byManual === 'needsManual')
                                        ) {
                                            return [];
                                        }
                                    }
                                    return [
                                        <li key={vn}>
                                            <Button onClick={() => setSelectedViewName(vn)} variant="text">
                                                {vn}
                                            </Button>
                                        </li>,
                                    ];
                                })}
                        </ul>
                    </Card>
                </Pane>
                <Card style={{ margin: '1em', padding: '1em' }}>
                    {!selectedViewName ? (
                        <em>Select a view</em>
                    ) : (
                        <div>
                            <h3>{selectedViewName}</h3>
                            <ViewDefWizard key={selectedViewName} initialValues={viewConfig.views[selectedViewName]} />
                        </div>
                    )}
                </Card>
            </SplitPane>
            <Card style={{ position: 'fixed', bottom: '2.5em', right: '2.5em' }}>
                <Automigrate />
            </Card>
        </div>
    );
};

export default MigrateEntityViews;
