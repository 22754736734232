import { useCallback, useState } from 'react';
import { View } from 'reducers/ViewConfigType';
import { services } from 'sideEffect/services';
import { AjaxError } from 'rxjs/ajax';
import produce from 'immer';

type SubmitViewState =
    | {
          type: 'SUCCESS';
      }
    | { type: 'NOT_SUBMITTING' }
    | { type: 'SUBMITTING' }
    | {
          type: 'ERROR';
          error: AjaxError;
      };

export const viewToViewDef = (_view: View) => {
    const view: any = produce(_view, (draftView: any) => {
        draftView.viewDefName = draftView.name;
        delete draftView.name;
        draftView.fields = _view.fields && Object.values(_view.fields);
        draftView.searchFields = _view.searchFields; //  && Object.values(_view.searchFields);
        if (draftView.tabs) {
            draftView.tabs = Object.entries(draftView.tabs).map(([name, tab]) => ({ ...(tab as {}), name }));
            draftView.tabs.forEach((tab) => {
                tab.fields = Object.values(tab.fields);
            });
        }
    });
    return view;
};
const useSubmitView = (_view: View, mode: 'PUT' | 'POST', previousViewName?: string) => {
    const view = viewToViewDef(_view);
    const [state, setState] = useState<SubmitViewState>({ type: 'NOT_SUBMITTING' });
    const submit = useCallback(() => {
        setState({ type: 'SUBMITTING' });
        const $ajax = mode === 'PUT' ? services.updateView(view, previousViewName) : services.createView(view);
        const subscription = $ajax.subscribe(
            (res) => {
                if (res.status >= 200 && res.status < 300) {
                    setState({ type: 'SUCCESS' });
                } else {
                    setState({ type: 'NOT_SUBMITTING' });
                }
            },
            (error: AjaxError) => {
                setState({ type: 'ERROR', error });
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [view, mode, previousViewName]);
    return [state, submit] as const;
};
export default useSubmitView;
