import { createCustomAction } from 'typesafe-actions';
import { GET_POTENTIAL_USERS, GET_POTENTIAL_USERS_FAILURE, GET_POTENTIAL_USERS_SUCCESS } from './constants';
import { PotentialUser } from './types';
import { ErrorDetails } from 'sagas/util/sagaFactory';

export const getPotentialUsers = createCustomAction(GET_POTENTIAL_USERS, (type) => {
    return () => ({ type });
});

export const getPotentialUsersSuccess = createCustomAction(GET_POTENTIAL_USERS_SUCCESS, (type) => {
    return (data: PotentialUser[]) => ({
        type,
        payload: data,
    });
});

export const getPotentialUsersFailure = createCustomAction(GET_POTENTIAL_USERS_FAILURE, (type) => {
    return (error: ErrorDetails) => ({
        type,
        error,
    });
});
