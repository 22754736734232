import React from 'react';
import { Width } from 'bpm/components/layout/withAdjustedWidth';
import { useOverrideTitle } from './configurations/overrideTitle';
import Title, { DisplayAboveWidth } from 'components/generics/title/Title';
import useViewConfig from 'util/hooks/useViewConfig';

const useTitleElement = ({
    displayAboveWidth,
    viewName,
    id,
}: {
    viewName: string;
    id: string;
    displayAboveWidth?: Width;
}) => {
    const resource = useViewConfig().views[viewName]?.entity;
    const { element: overrideTitleElement, EditTitleElem } = useOverrideTitle(viewName);
    const TitleElem = React.useMemo(() => {
        return overrideTitleElement ? (
            <DisplayAboveWidth displayAboveWidth={displayAboveWidth}>
                <h1>{overrideTitleElement}</h1>
            </DisplayAboveWidth>
        ) : (
            <Title component="h1" resource={resource} id={id} displayAboveWidth={displayAboveWidth} />
        );
    }, [resource, id, displayAboveWidth, overrideTitleElement]);
    return { TitleElem, EditTitleElem };
};
export default useTitleElement;
