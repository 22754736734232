import * as pd from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, catchError, map, flatMap, withLatestFrom, tap } from 'rxjs/operators';
import { of, concat, Observable } from 'rxjs';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import difference from 'lodash/difference';
import { syncedActionsController } from 'configureStore/syncedActionsController';
import { getStorageModeSelector } from 'util/applicationConfig';
const isSetEqual = (a1, a2) => a1.length === a2.length && [...difference(a1, a2), ...difference(a2, a1)].length === 0;

const loadProcessDefinitionsFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) => {
    return action$.pipe(
        filter(isActionOf(pd.getProcessDefinitions)),
        withLatestFrom(
            state$.pipe(map((state) => state.bpm.processDefinitions.isLoading)),
            state$.pipe(map((state) => state.bpm.processDefinitions.byId)),
            state$.pipe(map((state) => getStorageModeSelector(state))),
            state$.pipe(map((state) => state.profiles)),
            state$.pipe(map((state) => state.viewConfig?.user?.roles)),
        ),
        filter(([action, isAlreadyLoading, old, storageMode, profiles, roles]) => !isAlreadyLoading),
        flatMap(([action, _, old, storageMode, profiles, roles]) =>
            concat<RootAction>(
                of(pd.getProcessDefinitionsLoading()),
                services.getProcessDefinitions(roles).pipe(
                    tap((pdr) => {
                        if (storageMode !== 'sessionStorage' && profiles.state === 'no_profiles') {
                            // sync across tabs
                            (syncedActionsController.trigger as any)(pd.getProcessDefinitionsSuccess(pdr.data));
                        }
                    }),
                    flatMap((pdr) => {
                        const pds = pdr.data;
                        const oldIds = Object.values(old).map((o) => o.id);
                        const messageActions =
                            oldIds.length === 0 || !action.notifyOnSuccess
                                ? []
                                : isSetEqual(
                                      oldIds,
                                      pds.map((p) => p.id),
                                  )
                                ? [
                                      of(
                                          enqueueSnackbarAction({
                                              message: 'No new case definitions',
                                          }),
                                      ),
                                  ]
                                : [
                                      of(
                                          enqueueSnackbarAction({
                                              message: 'New case definitions found, updated',
                                          }),
                                      ),
                                  ];
                        return concat(of(pd.getProcessDefinitionsSuccess(pds)), ...messageActions);
                    }),
                    catchError((err): Observable<RootAction> => {
                        return concat(
                            of(pd.getProcessDefinitionsFailure(err)),
                            of(
                                enqueueSnackbarAction({
                                    message: 'Process definitions failed to load.',
                                    options: { variant: 'error' },
                                }),
                            ),
                        );
                    }),
                ),
            ),
        ),
    );
};
export default loadProcessDefinitionsFlow;
