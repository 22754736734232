import React from 'react';
import { ajax, AjaxRequest } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { API_URL } from 'config';
import buildHeaders from 'sideEffect/buildHeaders';

interface TestComponentProps {}
interface TestComponentState {
    url: string;
    headers: string;
    options: string;
}
class TestComponent extends React.Component<TestComponentProps, TestComponentState> {
    state: TestComponentState = {
        url: '',
        headers: '',
        options: '',
    };

    onHeadersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            headers: e.target.value,
        });
    };
    onOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            options: e.target.value,
        });
    };
    onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            url: e.target.value,
        });
    };
    submit = () => {
        let reqOps: AjaxRequest = {
            url: API_URL + this.state.url,
            method: 'GET',
            headers: buildHeaders({
                includeCredentials: true,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
            withCredentials: true,
            responseType: 'json',
        };
        try {
            const ops = JSON.parse(this.state.options);
            reqOps = { ...reqOps, ...ops };
        } catch (e) {}
        try {
            const headers = JSON.parse(this.state.headers);
            reqOps.headers = { ...reqOps.headers, ...headers };
        } catch (e) {}

        const req = ajax(reqOps).pipe(
            map((r) => {
                console.log(r);
                console.log(r.status);
                return r.response;
            }),
        );
        req.subscribe({
            next: console.log,
            error: console.log,
            complete: () => console.log('complete'),
        });
    };
    render() {
        return (
            <div>
                <label htmlFor="overrideHeaders">headers(JSON)</label>
                <input id="overrideHeaders" type="text" onChange={this.onHeadersChange} />
                <br />
                <label htmlFor="overrideOptions">options(JSON)</label>
                <input id="overrideOptions" type="text" onChange={this.onOptionsChange} />
                <br />
                <label htmlFor="urlIn">api/</label>
                <input id="urlIn" type="text" onChange={this.onUrlChange} />
                <button type="submit" onClick={this.submit}>
                    Submit
                </button>
            </div>
        );
    }
}

export default TestComponent;
