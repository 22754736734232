export interface TasksFilter {
    state?: string;
    assignee?: string;
    assignment?: string;
    sort?: string;
}
const taskEventType = {
    get: 'GET_TASK',
    getSuccess: 'GET_TASK_SUCCESS',
    getFailure: 'GET_TASK_FAILURE',

    getPotentialUsers: 'GET_TASK_POTENTIAL_USERS',
    getPotentialUsersSuccess: 'GET_TASK_POTENTIAL_USERS_SUCCESS',
    getPotentialUsersFailure: 'GET_TASK_POTENTIAL_USERS_FAILURE',

    getTasks: 'GET_TASKS',
    getTasksSuccess: 'GET_TASKS_SUCCESS',
    getTasksFailure: 'GET_TASKS_FAILURE',

    getSidebarTasks: 'GET_SIDEBAR_TASKS',
    getSidebarTasksSuccess: 'GET_SIDEBAR_TASKS_SUCCESS',
    getSidebarTasksFailure: 'GET_SIDEBAR_TASKS_FAILURE',

    update: 'UPDATE_TASK',
    updateSuccess: 'UPDATE_TASK_SUCCESS',
    updateFailure: 'UPDATE_TASK_FAILURE',

    claim: 'CLAIM_TASK',
    claimSuccess: 'CLAIM_TASK_SUCCESS',
    claimFailure: 'CLAIM_TASK_FAILURE',

    assign: 'ASSIGN_TASK',
    assignSuccess: 'ASSIGN_TASK_SUCCESS',
    assignFailure: 'ASSIGN_TASK_FAILURE',

    complete: 'COMPLETE_TASK',
    completeSuccess: 'COMPLETE_TASK_SUCCESS',
    completeFailure: 'COMPLETE_TASK_FAILURE',
};

const taskEventCreator = {
    getTask: (taskId) => ({
        type: taskEventType.get,
        payload: taskId,
    }),
    getTaskPotentialUsers: (taskId) => ({
        type: taskEventType.getPotentialUsers,
        payload: taskId,
    }),
    getTasks: (filter: TasksFilter) => ({
        type: taskEventType.getTasks,
        payload: filter,
    }),
    getSidebarTasks: (filter: TasksFilter) => ({
        type: taskEventType.getSidebarTasks,
        payload: filter,
    }),
    updateTask: (taskId, data) => ({
        type: taskEventType.update,
        payload: { taskId, data },
    }),
    claimTask: (taskId) => ({
        type: taskEventType.claim,
        payload: taskId,
    }),
    assignTask: (taskId, userId) => ({
        type: taskEventType.assign,
        payload: { taskId, userId },
    }),
    completeTask: (taskId) => ({
        type: taskEventType.complete,
        payload: { taskId },
    }),
};

export { taskEventType, taskEventCreator };
