import React from 'react';
import b64ToFile, { saveFile } from '../../util/b64ToFile';
import buildHeaders from 'sideEffect/buildHeaders';

const config = require('../../../config.js');

interface DownloadFileProps {
    url: string;
    fileName: string;
    contentType: string;
}
// this is duplicated in ProcessPage. Lets pull these out in the end.
function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => {
        binary += String.fromCharCode(b);
    });
    console.log('binary', binary);
    return window.btoa(binary);
}

const getContent = (url) =>
    fetch(`${config.BACKEND_BASE_URL}${url}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
        }),
    });
const processContent = (contentType) => (response) => {
    if (response && response.status !== 404 && response.status !== 500) {
        return response.arrayBuffer().then((buffer) => {
            const base64Flag = ''; // `data:${contentType};base64,`; No flag!
            const b64data = arrayBufferToBase64(buffer);
            return base64Flag + b64data;
        });
    }
    if (response && response.status === 404) {
        alert('File not found.');
    }
    if (response && response.status === 500) {
        alert('Internal server error.');
    }
    throw new Error(`Failed request for content: ${JSON.stringify(response)}`);
};

class DownloadFromLink extends React.Component<DownloadFileProps> {
    getFile = (url = this.props.url, fileName = this.props.fileName, contentType = this.props.contentType) => {
        getContent(url)
            .then(processContent(contentType))
            .then((b64) => b64ToFile(b64, fileName, contentType))
            .then(saveFile)
            .catch((e) => console.error(e));
    };

    render() {
        const { fileName } = this.props;
        return (
            <div>
                {fileName && (
                    <a // eslint-disable-line
                        href="javascript:;" // eslint-disable-line
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.getFile();
                        }}
                    >
                        {fileName}
                    </a>
                )}
            </div>
        );
    }
}

export default DownloadFromLink;
