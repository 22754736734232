import { useContext } from 'react';
import { WrappedFieldMetaProps } from 'redux-form';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import getFormHelperTextProps from 'fieldFactory/util/getFormHelperTextProps';
import useWarningOrErrorUtils from './useWarningOrErrorUtils';
import classnames from 'classnames';
import { FormHelperTextProps, InputLabelProps, InputProps, OutlinedInputProps } from '@material-ui/core';

const useTextFieldUtils = (meta: Partial<WrappedFieldMetaProps>) => {
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    const {
        textErrorClass,
        inputUnderlineClass,
        notchedOutlineClass,
        outlinedInputClass,
        helperText,
        muiErrorProp,
        selectClass,
    } = useWarningOrErrorUtils(meta);

    const InputPropsClasses: OutlinedInputProps['classes'] & InputProps['classes'] = {
        underline: inputUnderlineClass,
        notchedOutline: notchedOutlineClass,
        root: outlinedInputClass,
    };

    const formHelperTextClasses = {
        error: textErrorClass,
    };
    const createFormHelperTextProps: (props?: Partial<InputProps>) => Partial<FormHelperTextProps> = (inputProps) => ({
        ...getFormHelperTextProps(inputProps),
        classes: formHelperTextClasses,
    });

    const inputLabelClasses = {
        // only color label for outlined inputs
        error: fieldVariant === 'standard' ? undefined : textErrorClass,
    };
    const createInputLabelProps: (props?: Partial<InputLabelProps>) => Partial<InputLabelProps> = (props) => ({
        ...getInputLabelProps({
            shrink: true,
            ...props,
        }),
        classes: inputLabelClasses,
    });
    return {
        createInputLabelProps,
        createFormHelperTextProps,
        InputPropsClasses,
        muiErrorProp,
        helperText,
        fieldVariant,
        selectClass,
        classnames,
        inputLabelClasses,
        formHelperTextClasses,
        textErrorClass,
    };
};

export const TextFieldUtils: React.FC<{
    meta: WrappedFieldMetaProps;
    children: (props: ReturnType<typeof useTextFieldUtils>) => JSX.Element;
}> = ({ children, meta }) => {
    const utilProps = useTextFieldUtils(meta);
    return children(utilProps);
};

export default useTextFieldUtils;
