import { getOfflineSubmitEntries } from 'offline_app/offline_stateful_tasks/back_online/components/getOfflineSubmitEntries';
import replaceId from './replaceId';
import deleteEntitySubmitEntry from 'offline_app/offline_entity_submits/deleteEntitySubmitEntry';

const deleteEntryAndAllPendingChildren = async (taskId: string, temporaryId: string) => {
    // first, lets delete our record.
    await deleteEntitySubmitEntry(temporaryId);
    const offlineSubmits = await getOfflineSubmitEntries(taskId);

    const keys = Object.keys(offlineSubmits);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const e = offlineSubmits[key];
        // replaceId returns if the id is found, so we are just using it to perform search with an arbitrary replace string.
        const transformedTarget = replaceId(temporaryId, 'foo')(e.target);
        if (transformedTarget) {
            await deleteEntitySubmitEntry(key);
        }
    }
};

export default deleteEntryAndAllPendingChildren;
