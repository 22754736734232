export const LOAD_VALUE_SET = 'LOAD_VALUE_SET';

export const VALUE_SET_RECEIVED = 'VALUE_SET_RECEIVED';

export const VALUE_SET_NOT_RECEIVED = 'VALUE_SET_NOT_RECEIVED';

export const LOAD_VALUE_SET_GROUP = 'LOAD_VALUE_SET_GROUP';

export const VALUE_SET_GROUP_RECEIVED = 'VALUE_SET_GROUP_RECEIVED';

export const VALUE_SET_GROUP_NOT_RECEIVED = 'VALUE_SET_GROUP_NOT_RECEIVED';

// valueset multiple

export const LOAD_MULTIPLE_VALUE_SETS = 'LOAD_MULTIPLE_VALUE_SETS';

export const MULTIPLE_VALUE_SETS_RECEIVED = 'MULTIPLE_VALUE_SETS_RECEIVED';

export const MULTIPLE_VALUE_SETS_NOT_RECEIVED = 'MULTIPLE_VALUE_SETS_NOT_RECEIVED';

// concepts

export const LOAD_VALUE_SET_CONCEPTS = 'LOAD_VALUE_SET_CONCEPTS';

export const VALUE_SET_CONCEPTS_RECEIVED = 'VALUE_SET_CONCEPTS_RECEIVED';

export const VALUE_SET_CONCEPTS_NOT_RECEIVED = 'VALUE_SET_CONCEPTS_NOT_RECEIVED';
