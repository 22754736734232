import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Drawer } from '@material-ui/core';
import { setSidebarVisibility as setSidebarVisibilityAction } from 'actions/aor/uiActions';
import { RootState } from 'reducers/rootReducer';

interface SidebarProps {}
const dispatches = { setSidebarVisibility: setSidebarVisibilityAction };
type Dispatches = typeof dispatches;
const mapStateToProps = (state: RootState, props: SidebarProps) => ({
    open: state.admin.ui.sidebarOpen,
});

interface SidebarComponentProps extends SidebarProps, ReturnType<typeof mapStateToProps>, Dispatches {}
class SidebarComponent extends PureComponent<SidebarComponentProps> {
    handleClose = () => {
        this.props.setSidebarVisibility(false);
    };

    render() {
        const { open, children } = this.props;

        return (
            <Drawer open={open} onClose={this.handleClose}>
                {React.cloneElement(children as any, { onMenuTap: this.handleClose })}
            </Drawer>
        );
    }
}

const Sidebar: React.ComponentType<SidebarProps> = compose(connect(mapStateToProps, dispatches))(SidebarComponent);
export default Sidebar;
