import React from 'react';
import { Dialog, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import Themed from 'components/Themed';
import { translateAjaxError } from 'sideEffect/other/notificationEpic';
import { AjaxError } from 'rxjs/ajax';

interface ErrorDialogProps {
    alertError: AjaxError | null;
    clearAlert: () => void;
}
const ErrorDialog = (props: ErrorDialogProps) => {
    const { clearAlert, alertError } = props;
    return (
        <Dialog
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
            onClose={clearAlert}
            open={Boolean(alertError)}
        >
            <div>
                {alertError && (
                    <Themed>
                        {({ theme }) => (
                            <Card>
                                <CardHeader title="Submission Failed" />
                                <CardContent style={{ color: theme.palette.error.main }}>
                                    {translateAjaxError(alertError)}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        style={{ margin: '2px' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={clearAlert}
                                    >
                                        Close
                                    </Button>
                                </CardActions>
                            </Card>
                        )}
                    </Themed>
                )}
            </div>
        </Dialog>
    );
};
export default ErrorDialog;
