import React from 'react';
import get from 'lodash.get';
import pure from 'recompose/pure';

interface TextFieldProps {
    addLabel?: boolean;
    elStyle?: {};
    label?: string;
    record?: {};
    source: string;
}
const TextField: React.SFC<TextFieldProps> = ({ source, record = {}, elStyle }) => {
    const value = get(record, source);
    const n = parseFloat(value);
    const toDisplay = (n * 100).toFixed(2);
    return <span style={elStyle}>{toDisplay && `${toDisplay}%`}</span>;
};

const PureTextField = pure(TextField);

PureTextField.defaultProps = {
    addLabel: true,
};

export default PureTextField;
