import React from 'react';
import { Field } from 'redux-form';
import { Card, CardActions, Button, CircularProgress, CardContent } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import TextInput from 'fieldFactory/input/components/TextInput';
import Popup from 'components/Popup';
import WithTextState from './WithTextState';
import AttemptRequest, { AttemptRequestState } from 'components/AttemptRequest';
import * as config from 'config';
import Themed from 'components/Themed';
import { Control, Controller } from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types/form';
import buildHeaders from 'sideEffect/buildHeaders';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

const renderActionButtons =
    (args: { attemptAction: () => void; closeDialog: () => void; disallowSubmission: boolean }) =>
    <T extends any>(state: AttemptRequestState<T>) => {
        return (
            <CardActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={args.disallowSubmission || state._tag === 'pending'}
                    onClick={args.attemptAction}
                >
                    Send password reset email{' '}
                    {state._tag === 'pending' ? (
                        <CircularProgress
                            style={{
                                height: 20,
                                width: 20,
                            }}
                        />
                    ) : (
                        state._tag === 'success'
                    )}
                </Button>
                <Button onClick={args.closeDialog} aria-label="close">
                    Close
                </Button>
            </CardActions>
        );
    };

const getLazyR = (login: string) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/account/forgot-password/init`, {
        method: 'POST',
        body: JSON.stringify({
            login,
        }),
        credentials: 'same-origin',
        headers: buildHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            includeCredentials: false,
        }),
    });

interface PasswordResetButtonProps<T extends { username?: string }> {
    reduxFormFieldName?: string;
    hookFormFieldName?: FieldName<T>;
    hookFormDefaultValue?: string;
    hookFormControl?: Control<T>;
    renderButton: (args: { openDialog: () => void }) => JSX.Element;
}
export function PasswordResetButton<T extends { username?: string }>({
    hookFormDefaultValue,
    reduxFormFieldName,
    hookFormFieldName,
    hookFormControl,
    renderButton,
}: PasswordResetButtonProps<T>) {
    return (
        <Popup
            renderDialogContent={({ closeDialog }) => {
                const renderSubmitDialogContent = (initialValue: string) => (
                    <WithTextState initial={initialValue}>
                        {({ text, setText }) => (
                            <Card>
                                <CardContent>
                                    <TextInput
                                        source=""
                                        resource=""
                                        label="Login"
                                        ariaInputProps={{
                                            'aria-label': 'login',
                                        }}
                                        validate={() => null}
                                        input={{
                                            onChange: (e) => setText(e.target.value),
                                            value: text,
                                            onBlur: (e) => setText(e.target.value),
                                        }}
                                        meta={
                                            !text
                                                ? {
                                                      error: 'Required',
                                                      touched: true,
                                                  }
                                                : !text.match("^[_'.@A-Za-z0-9-]*$")
                                                ? {
                                                      error: 'Invalid Username',
                                                      touched: true,
                                                  }
                                                : {}
                                        }
                                    />
                                </CardContent>
                                <AttemptRequest
                                    type="external"
                                    key={text}
                                    lazyRequest={getLazyR(text.trim())}
                                    renderer={({ attemptAction }) =>
                                        (state) =>
                                            (
                                                <React.Fragment>
                                                    {state._tag === 'failure' ? (
                                                        <Themed>
                                                            {({ theme }) => (
                                                                <CardContent
                                                                    style={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                        color: theme.palette.error.dark,
                                                                    }}
                                                                >
                                                                    {state.body && typeof state.body === 'object'
                                                                        ? Object.values(state.body).map((msg, i) =>
                                                                              typeof msg === 'string' ? (
                                                                                  <p key={i}>
                                                                                      <SafeHtmlAsReact html={msg} />
                                                                                  </p>
                                                                              ) : (
                                                                                  <pre key={i}>
                                                                                      {msg &&
                                                                                          JSON.stringify(msg, null, 4)}
                                                                                  </pre>
                                                                              ),
                                                                          )
                                                                        : 'There was a problem: Failed to send the reset email.'}
                                                                </CardContent>
                                                            )}
                                                        </Themed>
                                                    ) : state._tag === 'success' ? (
                                                        <CardContent>
                                                            <Done /> Please check your email.
                                                        </CardContent>
                                                    ) : null}
                                                    {renderActionButtons({
                                                        attemptAction,
                                                        closeDialog,
                                                        disallowSubmission: !text || !text.match("^[_'.@A-Za-z0-9-]*$"),
                                                    })(state)}
                                                </React.Fragment>
                                            )}
                                />
                            </Card>
                        )}
                    </WithTextState>
                );
                return reduxFormFieldName ? (
                    <Field
                        name={reduxFormFieldName}
                        component={({ input }) => renderSubmitDialogContent(input.value)}
                    />
                ) : hookFormFieldName ? (
                    <Controller
                        defaultValue={hookFormDefaultValue}
                        control={hookFormControl}
                        name={hookFormFieldName}
                        render={({ value }) => renderSubmitDialogContent(value)}
                    />
                ) : (
                    renderSubmitDialogContent('')
                );
            }}
            renderToggler={({ openDialog }) =>
                renderButton({
                    openDialog: openDialog(),
                })
            }
        />
    );
}
