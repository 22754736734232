import React from 'react';
import { Dialog, Card } from '@material-ui/core';
import ProcessSelect from './SelectProcess';
import AttemptRequest from '../../components/AttemptRequest';
import getFoldFetchState from './foldOnRequestState';

type lazyRequest = (appCaseId: string | number, selectedData: {} | null) => () => Promise<any>;

export interface ProcessSelectS<T> {
    _tag: 'ProcessSelect';
    type: T;
}
export interface TaskActionConfirm<T> {
    _tag: 'TaskActionConfirm';
    type: T;
    appCaseId: number | string;
}
interface ProcessSelectDialogState<T> {
    dialogOpen: ProcessSelectS<T> | TaskActionConfirm<T> | false;
    dataKey: number;
}

interface ProcessSelectProps<T extends string | symbol> {
    disableCaseTypeChange?: boolean;
    initialSearch?: string;
    getLazyRequests: {
        [P in T]: lazyRequest;
    };
    onSuccess?: () => void;
    selectedData: {} | null;
    render: (arg: {
        dataKey: number;
        getOpenPSDialog: (taskAction: T) => () => void;
        closePSDialog: () => void;
    }) => JSX.Element;
    selectedDataStaysOnSuccess: boolean;
    itemName: 'task' | 'note';
}
class ProcessSelectDialog<T extends string | symbol> extends React.Component<
    ProcessSelectProps<T>,
    ProcessSelectDialogState<T>
> {
    constructor(props: ProcessSelectProps<T>) {
        super(props);
        this.state = {
            dialogOpen: false,
            dataKey: 0,
        };
    }
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    proceedToConfirmation = (selectedAppCaseId: string | number) => {
        this.setState((prev) => {
            const prevDialogOpen = prev.dialogOpen;
            if (!prevDialogOpen || prevDialogOpen._tag !== 'ProcessSelect') {
                // this is an exceptional case
                return {
                    ...prev,
                    dialogOpen: false,
                };
            }
            return {
                ...prev,
                dialogOpen: {
                    _tag: 'TaskActionConfirm',
                    type: prevDialogOpen.type,
                    appCaseId: selectedAppCaseId,
                },
            };
        });
    };
    backFromConfirmation = () => {
        this.setState((prev) => {
            const prevDialogOpen = prev.dialogOpen;
            if (!prevDialogOpen || prevDialogOpen._tag !== 'TaskActionConfirm') {
                return {
                    ...prev,
                    dialogOpen: false,
                };
            }
            return {
                ...prev,
                dialogOpen: {
                    _tag: 'ProcessSelect',
                    type: prevDialogOpen.type,
                },
            };
        });
    };
    getOpenProcessSelectDialog = (taskAction: T) => () => {
        this.setState({ dialogOpen: { _tag: 'ProcessSelect', type: taskAction } });
    };
    dataUpdated = () => {
        this.setState(
            (state) => ({
                ...state,
                dataKey: state.dataKey + 1,
            }),
            this.props.onSuccess,
        );
    };
    renderDialog = () => {
        const { selectedData, getLazyRequests, selectedDataStaysOnSuccess, initialSearch, itemName } = this.props;
        const { dialogOpen } = this.state;
        if (!selectedData) {
            return null;
        }
        return (
            <Dialog
                TransitionProps={
                    {
                        // https://github.com/dequelabs/axe-core/issues/146
                        role: 'presentation',
                    } as any
                }
                maxWidth={false}
                fullWidth={true}
                PaperProps={{
                    style: dialogOpen && dialogOpen._tag === 'ProcessSelect' ? { minWidth: '90%' } : {},
                }}
                onClose={this.closeDialog}
                open={!!dialogOpen}
            >
                <div>
                    {!dialogOpen ? null : dialogOpen._tag === 'ProcessSelect' ? (
                        <ProcessSelect
                            disableCaseTypeChange={this.props.disableCaseTypeChange}
                            initialSearch={initialSearch}
                            selectProcess={(id) => {
                                this.proceedToConfirmation(id);
                            }}
                        />
                    ) : (
                        <AttemptRequest
                            type="external"
                            lazyRequest={getLazyRequests[dialogOpen.type](dialogOpen.appCaseId, selectedData)}
                            renderer={({ attemptAction }) =>
                                (fetchState) => {
                                    const foldFetchState = getFoldFetchState(
                                        dialogOpen,
                                        this.rowsSelected(),
                                        this.backFromConfirmation,
                                        this.closeDialog,
                                        attemptAction,
                                        selectedDataStaysOnSuccess,
                                        itemName,
                                    );
                                    return <Card>{foldFetchState(fetchState)}</Card>;
                                }}
                            onSuccess={this.dataUpdated}
                        />
                    )}
                </div>
            </Dialog>
        );
    };
    rowsSelected = () => Object.keys(this.props.selectedData || {}).length;
    someDataSelected = () => this.rowsSelected() > 0;
    render() {
        return (
            <React.Fragment>
                {this.renderDialog()}
                {this.props.render({
                    dataKey: this.state.dataKey,
                    getOpenPSDialog: this.getOpenProcessSelectDialog,
                    closePSDialog: this.closeDialog,
                })}
            </React.Fragment>
        );
    }
}

export default ProcessSelectDialog;
