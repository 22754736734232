import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import ViewConfigNeedsRefreshBannerComponent from './Banner';

/*
    Current triggers to flag that viewConfig needs a refresh:
    1. update after submitting changes to viewDefs in editor.
*/
interface ViewConfigNeedsRefreshContext {
    value: boolean;
    setViewConfigNeedsRefresh: (value?: boolean) => void;
}
const defaultContext = {
    value: false,
    setViewConfigNeedsRefresh: () => {},
};
export const viewConfigNeedsRefreshContext = React.createContext<ViewConfigNeedsRefreshContext>(defaultContext);
const ViewConfigNeedsRefresh = (props) => {
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const printMode = useSelector((state: RootState) => state.viewConfig);
    const [value, setViewConfigNeedsRefresh] = React.useState(false);
    useEffect(() => {
        setViewConfigNeedsRefresh(false);
    }, [viewConfig, setViewConfigNeedsRefresh]);
    return (
        <viewConfigNeedsRefreshContext.Provider
            value={{
                value,
                setViewConfigNeedsRefresh,
            }}
        >
            {value && !printMode ? <ViewConfigNeedsRefreshBannerComponent /> : null}
            {props.children}
        </viewConfigNeedsRefreshContext.Provider>
    );
};
export default ViewConfigNeedsRefresh;
