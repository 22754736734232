import React from 'react';
import { GenericPossibleMatchView } from '../../../../components/generics';

const getPossibleMatchView = (params: {
    viewName: string;
    id: string;
    editInPopover: boolean;
    onDataChange: () => void;
    onIdChanged?: (id: string) => void;
}) => {
    return (
        <GenericPossibleMatchView
            onDataChange={params.onDataChange}
            onIdChanged={params.onIdChanged}
            editInPopover={params.editInPopover}
            viewName={params.viewName}
            match={{
                params: {
                    id: params.id,
                },
            }}
            title={null}
        />
    );
};
export default getPossibleMatchView;
