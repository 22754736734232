import React, { useCallback } from 'react';
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import useFormPersist from 'react-hook-form-persist';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import EvaluateAsScriptButton from './EvaluateAsScriptButton';
import flatten from 'flat';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

type Params = {
    [expression: string]: string | null;
};

export type ExpressionsToUsages = {
    [expression: string]: {
        fields?: {
            [path: string]: string;
        };
        outcomes?: {
            [path: string]: string;
        };
    };
};
interface ParamsFormProps {
    processId?: string;
    formKey: string;
    params: Params;
    submit: (params: Params) => void;
    expressionsToUsages?: ExpressionsToUsages;
}

const ParamRow: React.FC<{
    processId?: string;
    expression: string;
    usages: ExpressionsToUsages[string];
}> = ({ expression, usages, processId }) => {
    const { register, setValue } = useFormContext();
    const onEval = useCallback(
        (result) => {
            setValue(expression, result);
        },
        [setValue, expression],
    );
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {expression}
            </TableCell>
            <TableCell>
                {usages?.fields ? (
                    <div>
                        <b>Fields:</b>
                        <ul>
                            {Object.entries(usages.fields).map(([path, value]) => {
                                return (
                                    <li key={path}>
                                        <b>{path}</b>
                                        <br />
                                        {value}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : null}
                {usages?.outcomes ? (
                    <div>
                        <b>Outcomes:</b>
                        <ul>
                            {Object.entries(usages.outcomes).map(([path, value]) => {
                                return (
                                    <li key={path}>
                                        <b>{path}</b>
                                        <br />
                                        {value}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : null}
            </TableCell>
            <TableCell>
                <input name={expression} ref={register} />
            </TableCell>
            {processId && (
                <TableCell>
                    <EvaluateAsScriptButton expression={expression} processId={processId} onEval={onEval} />
                </TableCell>
            )}
        </TableRow>
    );
};
const ParamsForm: React.FC<ParamsFormProps> = ({ params, submit, formKey, expressionsToUsages, processId }) => {
    const formMethods = useForm({ defaultValues: params });
    const { watch, setValue } = formMethods;
    const isSubmitted = formMethods.formState.isSubmitted;
    const isDirty = Object.keys(formMethods.formState.dirtyFields).length > 0;
    useFormPersist(formKey, { watch, setValue });

    const classes = useStyles();

    const onSubmit = formMethods.handleSubmit((data) => {
        // because dots in paths result in nested data, (e.g. bpmUtils.someFunction
        // we need to flatten it back down.
        const flattenedData = flatten(data) as Params;
        submit(flattenedData);
    });
    return (
        <form onSubmit={onSubmit}>
            <FormProvider {...formMethods}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Expression</TableCell>
                                <TableCell>Usages</TableCell>
                                <TableCell>Evaluated as</TableCell>
                                {processId && <TableCell>Evaluate in Process Context</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(params).map((expression, i) => {
                                return (
                                    <ParamRow
                                        processId={processId}
                                        key={i}
                                        expression={expression}
                                        usages={expressionsToUsages?.[expression]}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </FormProvider>
            {(!isSubmitted || isDirty) && (
                <div style={{ width: '100%', textAlign: 'center', margin: '1em' }}>
                    <Button color="primary" variant="contained" type="submit">
                        Preview Form
                    </Button>
                </div>
            )}
        </form>
    );
};
export default ParamsForm;
