// for use in SPEL contexts.

import { IntlShape } from 'react-intl';

export const getLocale = (intl: IntlShape) => () => intl.locale ?? intl.defaultLocale;

const getMessage = (intl: IntlShape) => (messageId: string, values: {} | any[]) => {
    return intl.formatMessage(
        {
            id: messageId,
        },
        Array.isArray(values) ? Object.fromEntries(Object.entries(values).map(([k, v]) => [k + '', v])) : values,
    );
};
export default getMessage;
