import uniq from 'lodash/uniq';
import { crudGetOneSuccess } from 'sideEffect/crud/getOne/actions';
import { loadSuccess as loadViewConfigSuccess } from 'viewConfig/actions';
import { Concept } from 'fieldFactory/input/components/Concept';
import produce from 'immer';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { conceptsReceived, multipleValuesetsReceived, valuesetGroupReceived } from './actions';
import combineGroups from './util/combineGroups';
export interface ValueSets {
    [valueSetCode: string]: {
        id?: string;
        display?: string;
        invalid: boolean;
        conceptIds: string[];
        groups: {
            [groupName: string]: {
                invalid: boolean;
                ids: string[];
            };
        };
        allConceptsLoaded?: boolean;
    };
}

const valueSetsReducer = (previousState: ValueSets = {}, action: RootAction): ValueSets => {
    switch (action.type) {
        case getType(multipleValuesetsReceived): {
            const requested: {
                [valuesetCode: string]: { [group: string]: true } | true;
            } = action.valueSetsFetched.reduce((prev, curr) => {
                const prevEntry = typeof prev[curr.valueSet] === 'object' ? prev[curr.valueSet] : undefined;
                const newValueSetEntry = curr.group
                    ? {
                          ...prevEntry,
                          [curr.group]: true,
                      }
                    : true;
                return {
                    ...prev,
                    [curr.valueSet]: newValueSetEntry,
                };
            }, {});
            const newState: ValueSets = Object.assign(
                {},
                previousState,
                action.payload.response.reduce((accumulated, vs) => {
                    const prevVs: Partial<ValueSets[0]> = accumulated[vs.code] || {};
                    const prevInvalid = prevVs.invalid;
                    const requestedEntry = requested[vs.code];
                    const ids = uniq([
                        ...((vs.group && prevVs.groups?.[vs.group]?.ids) || []),
                        ...vs.concepts.map((c) => c.id),
                    ]);
                    return {
                        ...accumulated,
                        [vs.code]: {
                            ...prevVs,
                            id: prevVs.id || vs.id,
                            conceptIds:
                                requestedEntry === true || !prevVs.conceptIds
                                    ? vs.concepts.map((c) => c.id)
                                    : uniq([...prevVs.conceptIds, ...vs.concepts.map((c) => c.id)]),
                            groups: {
                                ...(prevVs.groups || {}),
                                ...combineGroups(
                                    ids,
                                    action.payload.data.entities.Concept,
                                    typeof requestedEntry === 'object' // if specific groups were requested
                                        ? requestedEntry // only mark the groups requested as 'valid' in the cache
                                        : '*', // otherwise the whole valueset was requested and all groups are valid
                                    prevVs.groups,
                                ),
                            },
                            allConceptsLoaded: requestedEntry === true || (!prevVs.invalid && prevVs.allConceptsLoaded),
                            invalid:
                                requestedEntry === true ? false : typeof prevInvalid === 'boolean' ? prevInvalid : true,
                        },
                    };
                }, previousState),
            );
            return newState;
        }
        case getType(conceptsReceived): {
            const { payload } = action;
            const prevVs = previousState[payload.valueSetCode];
            const prevGroups: ValueSets[0]['groups'] | undefined = prevVs && prevVs.groups;
            const newState: ValueSets = {
                ...previousState,
                [payload.valueSetCode]: {
                    ...(previousState[payload.valueSetCode] || {}),
                    ...payload.valueSet,
                    conceptIds: payload.data.result,
                    groups: {
                        ...prevGroups, // old overwritten by new if exists
                        ...combineGroups(payload.data.result, payload.data.entities.Concept, '*', prevGroups),
                    },
                    invalid: false,
                    allConceptsLoaded: true,
                },
            };
            return newState;
        }
        case getType(valuesetGroupReceived): {
            const { payload, requestPayload } = action;
            interface ValueSetGroupConcept {
                id: string;
                code: string;
                display: string;
                group: string;
            }
            interface ValueSetGroupResponse {
                id: string;
                code: string;
                display?: string;
                concepts: ValueSetGroupConcept[];
            }
            const response = payload.response as ValueSetGroupResponse;
            const prevValueSet = previousState[requestPayload.valueSetCode];
            const newState: ValueSets = {
                ...previousState,
                [requestPayload.valueSetCode]: {
                    ...(prevValueSet || {}),
                    invalid: prevValueSet ? prevValueSet.invalid : true,
                    conceptIds: prevValueSet ? prevValueSet.conceptIds : [],
                    groups: {
                        ...(prevValueSet && prevValueSet.groups),
                        [requestPayload.group]: {
                            invalid: false,
                            ids: response.concepts.map(({ id }) => id),
                        },
                    },
                },
            };
            return newState;
        }
        case getType(loadViewConfigSuccess): {
            const newState = Object.assign(
                {},
                ...Object.keys(previousState).map((key) => ({
                    [key]: {
                        ...previousState[key],
                        groups: Object.assign(
                            {},
                            ...Object.entries(previousState[key].groups || {}).map(([groupName, group]) => ({
                                [groupName]: { ...group, invalid: true },
                            })),
                        ),
                        invalid: true,
                    },
                })),
            );

            return newState;
        }
        case getType(crudGetOneSuccess): {
            if (action.payload?.data?.entities?.Concept) {
                const valuesetEntriesById = Object.entries(previousState).reduce(
                    (prev, [vscode, vs]) => {
                        prev[vs.id] = [vscode, vs];
                        return prev;
                    },
                    {} as {
                        [id: string]: [string, ValueSets[0]];
                    },
                );
                const newConceptsInExistingVs = (
                    Object.values(action.payload.data.entities.Concept) as Concept[]
                ).filter((concept: Concept) => {
                    const valuesetEntry = valuesetEntriesById[concept.valueSetId];
                    return valuesetEntry && !valuesetEntry[1].conceptIds.includes(concept.id);
                });
                if (newConceptsInExistingVs.length > 0) {
                    return produce(previousState, (draftState) => {
                        newConceptsInExistingVs.forEach((c) => {
                            const draftVs = draftState[valuesetEntriesById[c.valueSetId][0]];
                            draftVs.conceptIds.push(c.id);
                            if (c.group && draftVs.groups[c.group] && !draftVs.groups[c.group].ids.includes(c.id)) {
                                draftVs.groups[c.group].ids.push(c.id);
                            }
                        });
                        return draftState;
                    });
                }
                return previousState;
            }
            return previousState;
        }
        default:
            return previousState;
    }
};

export default valueSetsReducer;
