import React from 'react';
import { useSelector } from 'react-redux';
import { Button, CardContent, CardHeader, createStyles, makeStyles } from '@material-ui/core';
import { RootState } from 'reducers/rootReducer';
import Popup from 'components/Popup';
import Alert from '@material-ui/lab/Alert';
import ListOfOfflineTasks from 'offline_app/presentation/ListOfOfflineTasks';

const useStyles = makeStyles((theme) =>
    createStyles({
        message: {
            flex: 1,
        },
    }),
);
const SomeOfflineTasksBanner = () => {
    const classes = useStyles();
    const _numOfflineTasks = useSelector((state: RootState) =>
        !state.offlineTasks ? 0 : Object.keys(state.offlineTasks).length,
    );
    if (_numOfflineTasks === 0) {
        return null;
    }
    const isPlural = _numOfflineTasks > 1;
    const OfflineButton = (
        <button
            onClick={() => {
                window.location.href = window.location.pathname + '?offline=1';
            }}
            className="casetivity-linkbutton"
        >
            Go to offline mode
        </button>
    );
    return (
        <div>
            <Alert severity="info" classes={classes}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        You have {_numOfflineTasks} saved offline task{isPlural ? 's' : ''}. &nbsp;
                        <Popup
                            renderDialogContent={({ closeDialog }) => (
                                <div style={{ padding: '1em', minWidth: 'min(600px, 90vw)' }}>
                                    <CardHeader component="h1" title="My Offline Work" />
                                    <CardContent>{OfflineButton}</CardContent>
                                    <ListOfOfflineTasks onSelect={closeDialog} />
                                    <Button variant="contained" onClick={closeDialog}>
                                        Close
                                    </Button>
                                </div>
                            )}
                            renderToggler={({ openDialog }) => (
                                <button onClick={openDialog()} className="casetivity-linkbutton">
                                    View
                                </button>
                            )}
                        />
                    </div>
                    <div>{/* {OfflineButton} */}</div>
                </div>
            </Alert>
        </div>
    );
};

export default SomeOfflineTasksBanner;
