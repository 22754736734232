import React from 'react';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import getEmptyRequiredOptionFormFields from '../util/getEmptyRequiredOptionFormFields';
import setupFieldValuesFromTable from '../util/setupFieldValuesFromTable';
import {
    getEvaluator,
    errorsAsMessages,
    getEvaluatedValidations,
    flowablePreprocessValuesForEval,
} from 'expressions/formValidation';
import convertErrorMessagesToTableFormat from '../util/convertErrorMessagesToTableFormat';
import deepExtend from 'deep-extend';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import get from 'lodash/get';
import { ValueSets } from 'valueSets/reducer';
import { entityAndConceptLookupUtils } from 'expressions/expressionArrays';
import getTableFields from './util/getTableFields';
import mapTableColumnValidationsToDataRows from './util/mapTableColumnValidationToDataRows';

export interface ValidateArgs {
    outcome?: string;
    formDefinition: RootState['taskForms'][0];
    entities: {};
    valuesAfterExpressionsApplied: {};
    visibleAndEditableFields: string[];
    viewConfig: ViewConfig;
    fields: React.ReactElement<{
        source?: string;
        warn?: ((value: any, allValues: {}, props: {}) => string | undefined)[];
    }>[];
    valueSets: ValueSets;
    ignoreFieldLevel?: boolean;
    overrideValidationsToRunLive?: [fieldId: string, validationConfig: string][];
}

export const getValidations = ({
    formDefinition,
    visibleAndEditableFields,
}: {
    formDefinition: RootState['taskForms'][0];
    visibleAndEditableFields?: string[];
}): {
    validations: [fieldId: string, validationExpression: string][];
    allFields: FormFieldUnion[];
} => {
    const formFieldsObj = Object.fromEntries(formDefinition.fields?.map((f) => [f.id, f]) ?? []);
    const userEditableFields: FormFieldUnion[] =
        visibleAndEditableFields?.map((f) => formFieldsObj[f]) ?? Object.values(formFieldsObj);

    const tableFields = getTableFields(userEditableFields);

    const allFields = [...(formDefinition.fields || []), ...tableFields];

    const tableFieldsAndEditableFields = [...tableFields, ...userEditableFields];

    const validations = tableFieldsAndEditableFields.flatMap((f) => {
        const validation = f.params?.configs?.validation;
        if (!validation) {
            return [];
        }
        return [[f.id, validation] as [string, string]];
    });
    return {
        validations,
        allFields,
    };
};

const validate = ({
    outcome,
    formDefinition,
    entities,
    valuesAfterExpressionsApplied,
    visibleAndEditableFields,
    viewConfig,
    fields,
    valueSets,
    ignoreFieldLevel,
    overrideValidationsToRunLive,
}: ValidateArgs) => {
    const fieldErrors: { [f: string]: string[] } = !ignoreFieldLevel
        ? Object.assign(
              {},
              ...fields.map((f) => {
                  const { warn, source } = f.props;
                  if (warn && source) {
                      const errs = warn.flatMap((warnfn) => {
                          const res = warnfn(
                              get(valuesAfterExpressionsApplied, source),
                              valuesAfterExpressionsApplied,
                              f.props,
                          );
                          return res ? [res] : [];
                      });
                      return errs.length > 0
                          ? {
                                [source]: errs,
                            }
                          : {};
                  }
                  return {};
              }),
          )
        : {};

    const { validations: accumulatedValidations, allFields } = getValidations({
        formDefinition,
        visibleAndEditableFields,
    });
    const dropdownsRequired: { [f: string]: string[] } = !ignoreFieldLevel
        ? Object.assign(
              {},
              ...getEmptyRequiredOptionFormFields(formDefinition)(valuesAfterExpressionsApplied).map((fid) => ({
                  [fid]: 'Required',
              })),
          )
        : {};

    const formFieldsObj = Object.assign({}, ...(formDefinition.fields || []).map((f) => ({ [f.id]: f })));
    const userEditableFields: FormFieldUnion[] = visibleAndEditableFields.map((f) => formFieldsObj[f]);

    const tableFields = getTableFields(userEditableFields);

    const tableRowColumnValues = userEditableFields.flatMap((f) =>
        f.type === 'table' && f.params.columnObj
            ? [setupFieldValuesFromTable(f.id, valuesAfterExpressionsApplied[f.id] || [], f.params.columnObj)]
            : [],
    );

    const fieldIds = allFields.map((field) => field.id);
    const valuesWithOutcomes = {
        ...flowablePreprocessValuesForEval(
            Object.assign(
                {},
                entityAndConceptLookupUtils(entities, viewConfig, valueSets),
                valuesAfterExpressionsApplied,
                ...tableRowColumnValues,
                { outcome: outcome || null },
            ),
            allFields,
            entities,
            {
                dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
            },
            valueSets,
        ),
        _outcome: outcome,
    };

    // get an evaluator null initializing the fields in the form for the evaluation context
    const validator = getEvaluator(
        {
            stripHashes: false,
        },
        fieldIds,
    );

    // map fields onto [fid, validation]
    // but if it's a table field, map it onto
    // [" <tableid>_c_<fieldname>_<i> (repeated) ", "<validation but with i inserted "]
    const validations = (overrideValidationsToRunLive || accumulatedValidations).flatMap(
        mapTableColumnValidationsToDataRows(valuesAfterExpressionsApplied),
    );
    const resultTuples = getEvaluatedValidations(validator, valuesWithOutcomes)(validations);

    const errsAsMessages = errorsAsMessages(resultTuples, fieldIds);
    const tableFormattedErrors = convertErrorMessagesToTableFormat(errsAsMessages);
    const messagesIncludingUnexpectedErrors = deepExtend(
        // combines string arrays
        {},
        tableFormattedErrors,
        fieldErrors,
        dropdownsRequired,
    );
    return messagesIncludingUnexpectedErrors;
};

export default validate;
