import React, { Suspense } from 'react';
import type { JsonSchemaFormFieldProps } from './JsonSchemaFormConfigField';

const Component = React.lazy(() => import('./JsonSchemaFormConfigField'));

const LazyJsonSchemaFormConfigField = (props: JsonSchemaFormFieldProps) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};

export default LazyJsonSchemaFormConfigField;
