import { useState } from 'react';
const WithKey = (props: { children: (args: { key: number; incKey: () => void }) => JSX.Element | null }) => {
    const [key, setKey] = useState(1);
    return props.children({
        key,
        incKey: () => {
            setTimeout(() => setKey(key + 1), 0);
        },
    });
};
export default WithKey;
