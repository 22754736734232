export type SuccessfulPinCb = () => void;
export abstract class DencryptPromptController {
    protected subscriptions: SuccessfulPinCb[] = [];
    public subscribeToPinSuccess(cb: SuccessfulPinCb) {
        this.subscriptions.push(cb);
    }
    public unsubscribe(cb: SuccessfulPinCb) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
    /**
     * This is public so we can manually call this, if we previously called promptDecodeTaskData with runSubscriptions = false, to ensure we can run some setup before subscriptions get called.
     */
    public runSubscriptions() {
        this.subscriptions.forEach((cb) => {
            cb();
        });
    }
}
