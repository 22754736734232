import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

interface UseIdleProps {
    timeout: number;
}
const useIdle = ({ timeout = 2000 }: UseIdleProps) => {
    const [isIdle, setIsIdle] = useState(false);
    const handleOnIdle = (event) => {
        setIsIdle(true);
    };

    const handleOnActive = (event) => {
        setIsIdle(false);
    };

    const it = useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500,
    });

    return [isIdle, it] as const;
};
export default useIdle;
