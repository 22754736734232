import React, { useMemo, useContext, useCallback } from 'react';
import ViewConfig, { EntityField } from 'reducers/ViewConfigType';
import { useFormContext } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { fromPredicate } from 'fp-ts/lib/Either';
import { tryCatch } from 'fp-ts/lib/Option';
import { RootState } from 'reducers/rootReducer';
import {
    getAttrOfTraversedFieldExprIncludingLinkedX,
    getRefEntity,
    isRefOneField,
    isValueSetField,
} from 'components/generics/utils/viewConfigUtils';
import { useSelector } from 'react-redux';
import { ListboxComponent } from '../../virtualize';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import useViewConfig from 'util/hooks/useViewConfig';
import uniq from 'lodash/uniq';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';

interface FieldPathProps {
    defaultValue?: string;
    resource: string;
    depth: number;
    // e.g. occupations.primaryPhone
    allowPropertiesOnManys?: boolean;
    required?: boolean;
}

const filter = createFilterOptions<string>();

export const getFieldsForDepth = (depth: number, resource: string, viewConfig: ViewConfig): string[] => {
    if (depth === 0) {
        return [];
    }
    const fieldsAtCurrentLevel = Object.values(viewConfig.entities[resource].fields);
    if (depth === 1) {
        return fieldsAtCurrentLevel.map((f) => f.name);
    }
    return fieldsAtCurrentLevel
        .flatMap((field): string[] => {
            return fromPredicate(
                (f: EntityField) =>
                    isRefOneField(viewConfig, resource, f.name, 'TRAVERSE_PATH') ||
                    isValueSetField(viewConfig, resource, f.name, 'TRAVERSE_PATH'),
                (f) => [f.name],
            )(field).map((f) => {
                const relatedEntity = (() => {
                    if (f.relatedEntity) {
                        return f.relatedEntity;
                    }
                    if (f.valueSet) {
                        // really it would be nice if this was configured in f.relatedEntity
                        // but it's not always the case, so just adding this right now.
                        return 'Concept';
                    }
                    throw new Error('No relatedEntity found on field ' + JSON.stringify(f));
                })();
                const baseFields = [f.name];
                if (f.name === 'verificationStatus') {
                    baseFields.push('verificationStatusCode');
                }
                return [
                    ...baseFields,
                    ...getFieldsForDepth(depth - 1, relatedEntity, viewConfig).map((fname) => `${field.name}.${fname}`),
                ];
            }).value;
        })
        .sort((a, b) => {
            const numOfDotsInA = a.split('.').length - 1;
            const numOfDotsInB = b.split('.').length - 1;
            if (numOfDotsInA < numOfDotsInB) {
                return -1;
            }
            if (numOfDotsInB < numOfDotsInA) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            if (b < a) {
                return 1;
            }
            return 0;
        });
};

const getFieldsExtending = (
    depthMore: number,
    baseResource: string,
    currPath: string,
    viewConfig: ViewConfig,
    allowPropertiesOnManys = true,
): string[] => {
    try {
        const entityEntry = getRefEntity(viewConfig, baseResource, currPath, true);
        const dataType = getAttrOfTraversedFieldExprIncludingLinkedX<'dataType', ViewConfig>(
            viewConfig,
            baseResource,
            currPath,
            'dataType',
        );
        if (
            !allowPropertiesOnManys &&
            (dataType === 'REFMANY' || dataType === 'REFMANYMANY' || dataType === 'REFMANYJOIN')
        ) {
            return [];
        }
        return [currPath, ...getFieldsForDepth(depthMore, entityEntry.name, viewConfig).map((e) => currPath + '.' + e)];
    } catch {
        return [];
    }
};

export const createFieldPathsWithDepthSelector = () =>
    createSelector(
        (state: RootState, props: FieldPathProps) => props.depth,
        (state: RootState, props: FieldPathProps) => props.resource,
        (state: RootState, props: FieldPathProps) => state.viewConfig,
        getFieldsForDepth,
    );

export const useFilterOptions = (props: { resource: string; allowPropertiesOnManys?: boolean }) => {
    const viewConfig = useViewConfig();

    return useCallback(
        (options: string[], params: FilterOptionsState<string>) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
                if (params.inputValue.includes('linkedEntity') && !options.includes(params.inputValue)) {
                    const res1 = [params.inputValue];
                    return res1;
                }
                // filtered.push(params.inputValue);

                const isValid = tryCatch(() => getRefEntity(viewConfig, props.resource, params.inputValue, true)).fold(
                    false,
                    () => true,
                );
                const lowerCaseInputValue = params.inputValue.toLowerCase();
                const res2 = uniq(
                    [
                        ...filtered,
                        ...getFieldsExtending(
                            3,
                            props.resource,
                            params.inputValue.endsWith('.')
                                ? params.inputValue.slice(0, -1)
                                : params.inputValue.includes('.') && !isValid
                                ? params.inputValue.slice(0, params.inputValue.lastIndexOf('.'))
                                : params.inputValue,
                            viewConfig,
                            props.allowPropertiesOnManys,
                        ),
                    ].filter((f) => f.toLowerCase().startsWith(lowerCaseInputValue)),
                );
                return res2;
            }
            return filtered.filter((f) => f.startsWith(params.inputValue));
        },
        [props.resource, props.allowPropertiesOnManys, viewConfig],
    );
};

export const FieldPath: React.FunctionComponent<FieldPathProps> = (props) => {
    const { register, unregister, setValue } = useFormContext<{
        fieldPath: string;
    }>();
    const fieldPathsWithDepthSelector = useMemo(createFieldPathsWithDepthSelector, []);

    const initialFieldPaths = useSelector((state: RootState) => fieldPathsWithDepthSelector(state, props));
    const handleChange = (e, targetName) => {
        setValue(targetName ? targetName : e.target.name, (e && e.target && e.target.value) || e, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    React.useEffect(() => {
        register({ name: 'fieldPath' }, { required: props.required !== false ? 'A fieldPath is required' : undefined });
        return () => {
            unregister('fieldPath');
        };
    }, [props.required]); // eslint-disable-line

    const filterOptions = useFilterOptions({
        resource: props.resource,
        allowPropertiesOnManys: props.allowPropertiesOnManys,
    });
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <Autocomplete
            options={initialFieldPaths} // <- here are our options
            defaultValue={props.defaultValue}
            autoHighlight
            onChange={(e, value) => handleChange(value, 'fieldPath')}
            getOptionLabel={(option) => option}
            renderOption={(option) => option}
            // set below for Creatable mode
            selectOnFocus
            // TODO add back the below on Mui upgrade
            // clearOnBlur
            // handleHomeEndKeys
            filterOptions={filterOptions}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Field Path"
                    margin="normal"
                    fullWidth
                    inputProps={{
                        ...params.inputProps,
                        draggable: false,
                        autoComplete: 'disabled',
                    }}
                />
            )}
        />
    );
};

export default FieldPath;
