import { SET_PRIMARY_DASHBOARD, SET_PRIMARY_DASHBOARD_FAILURE, SET_PRIMARY_DASHBOARD_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';

export const setPrimaryDash = createCustomAction(SET_PRIMARY_DASHBOARD, (type) => {
    return (dashboardId: string) => ({ type, dashboardId });
});
export const setPrimaryDashSuccess = createCustomAction(SET_PRIMARY_DASHBOARD_SUCCESS, (type) => {
    return (dashboardId: string) => ({
        type,
        dashboardId,
    });
});
export const setPrimaryDashFailure = createCustomAction(SET_PRIMARY_DASHBOARD_FAILURE, (type) => {
    return (dashboardId: string, error: Error & { status?: number }) => ({
        type,
        dashboardId,
        error,
    });
});
