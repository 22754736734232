import React, { useContext } from 'react';
import casetivityViewContext, { CasetivityViewContext } from 'util/casetivityViewContext';
import { Subtract } from 'utility-types';

export default function getProviderAndHocWithViewContext<P extends { viewContext: CasetivityViewContext }>(
    BaseFormContextProvider: React.ComponentType<P>,
) {
    type FormContextProviderProps = Subtract<P, { viewContext: CasetivityViewContext }>;
    const FormContextProvider: React.SFC<FormContextProviderProps> = (props) => {
        const viewContext = useContext(casetivityViewContext);
        const BFCPProps: P = {
            viewContext,
            ...props,
        } as P;
        return <BaseFormContextProvider {...BFCPProps} />;
    };
    const formContextHoc =
        (overrideProps?: Partial<FormContextProviderProps>) =>
        <X extends FormContextProviderProps>(BaseComponent) =>
        (props: X) => {
            return (
                <FormContextProvider {...props} {...overrideProps}>
                    <BaseComponent {...props} />
                </FormContextProvider>
            );
        };

    return { formContextHoc, FormContextProvider };
}
