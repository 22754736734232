import React, { Suspense } from 'react';
import type { FunctionDocProps } from './FunctionDoc';

const Component = React.lazy(() => import('./FunctionDoc'));

const LazyFunctionDoc = (props: FunctionDocProps) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
        </Suspense>
    );
};
export default LazyFunctionDoc;
