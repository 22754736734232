import { isRefManyField, isRefManyManyField } from 'components/generics/utils/viewConfigUtils';
import * as React from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
const ThreeColumnRow = ({
    children: [child0, child1, child2],
    style = {},
}: {
    children: JSX.Element[];
    style?: React.CSSProperties;
}) => {
    const viewConfig = useViewConfig();
    const fieldIsTable = React.useCallback(
        (
            field: React.ReactElement<{
                'data-originaldefinition': string;
            }>,
        ) => {
            const definition: string = field.props['data-originaldefinition'];
            if (!definition) {
                return false;
            }
            try {
                const viewField = JSON.parse(definition);
                return (
                    isRefManyField(viewConfig, viewField.entity, viewField.field, 'POP_LAST') ||
                    isRefManyManyField(viewConfig, viewField.entity, viewField.field, 'POP_LAST')
                );
            } catch (e) {
                return false;
            }
        },
        [viewConfig],
    );

    const renderCell = React.useCallback(
        (field) => (
            <div style={{ width: '33%', float: 'left', overflowX: fieldIsTable(field) ? 'auto' : undefined }}>
                {' '}
                {field}
            </div>
        ),
        [fieldIsTable],
    );
    const cell0 = React.useMemo(() => renderCell(child0), [renderCell, child0]);
    const cell1 = React.useMemo(() => renderCell(child1), [renderCell, child1]);
    const cell2 = React.useMemo(() => renderCell(child2), [renderCell, child2]);
    return (
        <div style={{ borderBottom: '1px dashed lightgrey', paddingTop: '1em', paddingBottom: '1em', ...style }}>
            {cell0}
            {cell1}
            {cell2}
            <div style={{ clear: 'both' }} />
        </div>
    );
};
export default ThreeColumnRow;
