import ViewConfig from 'reducers/ViewConfigType';
import { tryCatch, Either } from 'fp-ts/lib/Either';
import { getAttrOfTraversedFieldExpr, getFieldSourceFromPath } from 'components/generics/utils/viewConfigUtils';
import maybeLogError from './logErrorIfPermissioned';
import { convertFieldsRequiredToUseableFields } from 'clients/utils/getFieldsRequiredForExpression';

interface ExpMetaInfo {
    expansionsRequired: string[];
}

const setupValuesetFieldsRequired =
    <T extends ExpMetaInfo>(viewConfig: ViewConfig, resource: string, expression: string) =>
    (r: T): Either<Error, T & { valuesetFieldsRequired: { [fieldSource: string]: string } }> =>
        tryCatch(
            () => {
                // below serves a dual purpose of throwing an error if the traversal can't happen.
                // This is how we strip out validations on fields we don't have permission for.
                return Object.assign(r, {
                    valuesetFieldsRequired: Object.assign(
                        {},
                        ...convertFieldsRequiredToUseableFields(r.expansionsRequired).map((fullname) => {
                            const [f, searchModifier] = fullname.split('__');
                            const fieldSource = getFieldSourceFromPath(viewConfig, resource, f);
                            // skip leading underscores, e.g. _isIos, _isOffline, etc
                            const vs =
                                !fieldSource.endsWith('.id') && !fieldSource.startsWith('_')
                                    ? // because 'id' is rarely added to configuration, the traversal will fail
                                      // skip it as a special case.
                                      getAttrOfTraversedFieldExpr<'valueSet'>(
                                          viewConfig,
                                          resource,
                                          fieldSource,
                                          'valueSet',
                                      )
                                    : undefined;
                            // we checked that the field is valid - now only include if it's a valueset-1 field
                            // (valueset manys are checked using functions in the context.
                            // e.g. #containsCode(racesIds, 'ASIAN'))
                            // in other words, we only need to setup *Code variables for valueset-1s so only inclode those here.
                            return vs && !f.endsWith('Ids')
                                ? { [fieldSource + (searchModifier ? `__${searchModifier}` : '')]: vs }
                                : {};
                        }),
                    ) as { [fieldSource: string]: string },
                });
            },
            (e: Error) => {
                maybeLogError(viewConfig)(
                    `a field in the validation expression "${expression}" for "${resource}" was not found on viewConfig traversal - removing validation.
        This could be due to a lack of permissions for the field.
        Error below:`,
                );
                maybeLogError(viewConfig)(e);
                return e;
            },
        );
export default setupValuesetFieldsRequired;
