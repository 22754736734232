import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { templateExpressionsGenerated } from './actions';
import { TemplateExpressionsGenerated } from './TemplateExpressionsGenerated';

const expressionTemplatesGeneratedReducer = (
    previousState: TemplateExpressionsGenerated = {},
    action: RootAction,
): TemplateExpressionsGenerated => {
    switch (action.type) {
        case getType(templateExpressionsGenerated):
            return action.payload;
        default:
            return previousState;
    }
};
export default expressionTemplatesGeneratedReducer;
