const removeEmptyStrings = (values: {}) => {
    return Object.assign(
        {},
        ...Object.entries(values).map(([key, value]) => {
            if (value === '') {
                return {};
            }
            return { [key]: value };
        }),
    );
};
export default removeEmptyStrings;
