import React, { useCallback, FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Button, FormGroup, FormControlLabel, Checkbox, IconButton, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import JsonDownload from 'expression-tester/util/JsonDownload';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import useViewConfig from 'util/hooks/useViewConfig';
import { mergeViewIntoViewConfig } from 'layout-editor/components/EditableViewFormEditor';
import { useDispatch, useSelector } from 'react-redux';
import { loadSuccess } from 'viewConfig/actions';
import useSubmitView from 'layout-editor/hooks/useSubmitView';
import ReactDiffViewer from 'react-diff-viewer';
import stableStringify from 'json-stable-stringify';
import { convert } from 'layout-editor/diff-viewsdefs-from-views/convertViewDefToView';
import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/Check';
import { fixViewFieldOrderings, fixViewDefFieldOrderings } from '../cleanFieldCoordinates';
import { RootState } from 'reducers/rootReducer';
import EditIcon from '@material-ui/icons/Edit';
import Popup from 'components/Popup';
import JSONEditorDemo from 'expression-tester/JsonEditorReact';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UndoIcon from '@material-ui/icons/Undo';
import FileCopy from '@material-ui/icons/FileCopy';
import TransferChangesPopup from '../transfer-changes/Popup';
import Alert from '@material-ui/lab/Alert';
import { load as loadViewConfig } from 'viewConfig/actions';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import {
    getAdjustedFieldSource,
    getAllFieldEntriesFromViewObj,
    isFieldViewField,
} from 'components/generics/utils/viewConfigUtils';

export const SubmitViewButton: FunctionComponent<
    {
        view: View;
        onSuccess?: () => void;
    } & (
        | {
              mode: 'PUT';
              previousViewName?: string;
          }
        | {
              mode: 'POST';
          }
    )
> = (props) => {
    const dispatch = useDispatch();
    const refreshViewConfig = useCallback(() => {
        dispatch(loadViewConfig());
    }, [dispatch]);
    const { view, onSuccess } = props;
    const [state, submit] = useSubmitView(view, props.mode, props.mode === 'PUT' ? props.previousViewName : undefined);
    const prevState = React.useRef(state);
    useEffect(() => {
        if (state.type === 'ERROR') {
            console.error(state.error);
        } else if (state.type === 'SUCCESS' && prevState.current?.type !== state.type) {
            if (onSuccess) {
                onSuccess();
            }
        }
        prevState.current = state;
    }, [state, onSuccess]);
    return (
        <React.Fragment>
            <Button
                id="save-viewdef"
                onClick={submit}
                disabled={state.type === 'SUBMITTING' || state.type === 'SUCCESS'}
                variant="contained"
                color="primary"
                endIcon={<Warning />}
            >
                Save to backend
            </Button>
            {state.type === 'ERROR' ? (
                (() => {
                    if (state.error.status === 409) {
                        return (
                            <span>
                                <Warning id="save-viewdef-failed" color="error" /> Prevented Overwrite - save not
                                persisted{' '}
                                <div style={{ marginTop: '.5em' }}>
                                    <Alert severity="error">
                                        Another user has been working on this viewDef, so to not overwrite their work,
                                        the save is not allowed.
                                        <button onClick={refreshViewConfig}>Refresh the ViewConfig</button> to view the
                                        diff to the left, and then{' '}
                                        <Link to="/view-definition-editor">start over from scratch</Link> to begin with
                                        the latest changes.
                                    </Alert>
                                </div>
                            </span>
                        );
                    }
                    //  TODO add warning for 409 error
                    return (
                        <Alert severity="error">
                            <AlertTitle>
                                Save Failed {state.error.status ? `with status ${state.error.status}` : ''}
                            </AlertTitle>
                            {state.error.response?.message && <p>{state.error.response?.message}</p>}
                            &nbsp;
                        </Alert>
                    );
                })()
            ) : state.type === 'SUCCESS' ? (
                <span>
                    <Check id="save-viewdef-success" color="primary" />
                    Success!&nbsp;
                    <div style={{ marginTop: '.5em' }}>
                        <Alert severity="warning">
                            It may take several minutes for changes to be processed. Wait some time, and manually
                            refresh the viewconfig.
                        </Alert>
                    </div>
                    {view.viewType === 'EDIT' ? (
                        <TransferChangesPopup
                            renderToggler={({ openDialog }) => (
                                <div style={{ marginTop: 5 }}>
                                    Would you like to transfer your changes to an existing SHOW or CREATE view?
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            marginTop: 5,
                                            marginBottom: 5,
                                            marginRight: '-1em',
                                        }}
                                    >
                                        <div style={{ marginRight: '1em' }}>
                                            <Button
                                                onClick={openDialog({
                                                    toViewType: 'SHOW',
                                                    view,
                                                })}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                SHOW
                                            </Button>
                                        </div>
                                        <div style={{ marginRight: '1em' }}>
                                            <Button
                                                onClick={openDialog({
                                                    toViewType: 'CREATE',
                                                    view,
                                                })}
                                                variant="outlined"
                                                color="primary"
                                            >
                                                CREATE
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    ) : null}
                </span>
            ) : null}
        </React.Fragment>
    );
};

const TestViewButton: FunctionComponent<{ view: View; overwriteViewDef: boolean }> = (props) => {
    const { view, overwriteViewDef } = props;
    // const viewConfig = useViewConfig();
    const viewConfig = useSelector((state: RootState) => state.viewConfig);
    const dispatch = useDispatch();
    const applyView = useCallback(() => {
        const newViewConfig = mergeViewIntoViewConfig(view, viewConfig, overwriteViewDef);
        dispatch(loadSuccess(newViewConfig));
    }, [view, viewConfig, dispatch, overwriteViewDef]);
    return (
        <Button color="primary" variant="contained" onClick={applyView}>
            {props.children}
        </Button>
    );
};

interface ResultProps {
    diffFrom?: 'none' | 'entityview if exists' | 'viewDef if exists';
    initialValues: Partial<View>;
}

type ResultJsonDiffProps =
    | ResultProps
    | {
          diffFrom?: 'provided';
          old: Partial<View>;
          initialValues: Partial<View> & { entityVersion?: number };
      };

const stringifyConvertConfigToObject = (view: Partial<View>) => {
    return stableStringify(
        {
            ...view,
            config: view.config && JSON.parse(view.config),
        },
        {
            space: ' ',
        },
    );
};
export const ResultJsonDiff: FunctionComponent<
    ResultJsonDiffProps & {
        prevViewName?: string;
    }
> = (props) => {
    const { initialValues } = props;
    const initialViewName = props.prevViewName ?? initialValues.name;
    const viewConfig = useViewConfig();
    const [shouldCorrectCoordinates, setShouldCorrectCoordinates] = useState(true);
    const initiallyDefaultView =
        viewConfig.entities[initialValues.entity]?.defaultViews?.[initialValues.viewType]?.name === initialViewName;
    const newInitialValues = useMemo(() => {
        return shouldCorrectCoordinates ? fixViewFieldOrderings(initialValues as View) : initialValues;
    }, [initialValues, shouldCorrectCoordinates]);
    const newValue = stableStringify(newInitialValues, {
        space: ' ',
    });
    const giveInitialDefaultViewValue = (view: {}) => ({ ...view, defaultView: initiallyDefaultView });

    const renderPage = (oldValue: string) => {
        // convert config to object because diffing is extremely slow on large config strings
        const newValue2 = stringifyConvertConfigToObject(newInitialValues);
        const ovParsed: any = oldValue ? JSON.parse(oldValue) : null;
        const oldValue2 = stringifyConvertConfigToObject(ovParsed);
        return (
            <div>
                <div style={{ display: 'flex', marginBottom: '1em', marginRight: '-1em' }}>
                    <div style={{ marginRight: '1em' }}>
                        <CopyToClipboard text={newValue}>
                            <Button size="small" endIcon={<FileCopy />}>
                                Copy Current
                            </Button>
                        </CopyToClipboard>
                    </div>
                    <div style={{ marginRight: '1em' }}>
                        <CopyToClipboard text={oldValue}>
                            <Button size="small" endIcon={<FileCopy />}>
                                Copy Original
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
                <ReactDiffViewer oldValue={oldValue2} newValue={newValue2} splitView={true} />
            </div>
        );
    };
    const resultJson =
        props.diffFrom === 'provided' ? (
            (() => {
                const oldValue = stableStringify(
                    giveInitialDefaultViewValue(
                        shouldCorrectCoordinates ? fixViewFieldOrderings(props.old as View) : props.old,
                    ),
                    {
                        space: ' ',
                    },
                );
                return renderPage(oldValue);
            })()
        ) : props.diffFrom === 'entityview if exists' && viewConfig.views[initialViewName] ? (
            (() => {
                const oldValue = stableStringify(
                    giveInitialDefaultViewValue(
                        shouldCorrectCoordinates
                            ? fixViewFieldOrderings(viewConfig.views[initialViewName])
                            : viewConfig.views[initialViewName],
                    ),
                    { space: ' ' },
                );
                return renderPage(oldValue);
            })()
        ) : props.diffFrom === 'viewDef if exists' && viewConfig.viewDefs[initialViewName] ? (
            (() => {
                const oldValue = stableStringify(
                    giveInitialDefaultViewValue(
                        convert(
                            shouldCorrectCoordinates
                                ? fixViewDefFieldOrderings(JSON.parse(viewConfig.viewDefs[initialViewName].definition))
                                : JSON.parse(viewConfig.viewDefs[initialViewName].definition),
                            viewConfig,
                        ),
                    ),
                    {
                        space: ' ',
                    },
                );
                return renderPage(oldValue);
            })()
        ) : (
            <pre>{JSON.stringify(initialValues, null, 1)}</pre>
        );
    return (
        <>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={shouldCorrectCoordinates}
                            onChange={() => {
                                setShouldCorrectCoordinates(!shouldCorrectCoordinates);
                            }}
                            name="checked"
                        />
                    }
                    label="Correct coordinates"
                />
            </FormGroup>
            {resultJson}
        </>
    );
};

type ModedProps =
    | {
          mode: 'PUT';
          prevViewName: string;
      }
    | {
          mode: 'POST';
      };
const Result: FunctionComponent<
    ResultJsonDiffProps & ModedProps & { onSuccess?: () => void; overrideViewConfig?: ViewConfig }
> = (props) => {
    const { initialValues, mode, diffFrom = 'none', onSuccess } = props;
    const _viewConfig = useViewConfig();
    const viewConfig = props.overrideViewConfig ?? _viewConfig;
    const _newInitialValues = React.useMemo(() => {
        // in general defaultView is ignorable since we care about the viewConfig value, but lets make sure it doesn't show up here and cause confusion.
        const { defaultView: _, ...rest } = fixViewFieldOrderings(initialValues as View) as View & {
            defaultView?: boolean;
            entityVersion?: number;
        };

        // let's also remove configs which point to non-existant fields.
        const newConfig = !rest.config
            ? rest.config
            : (() => {
                  const getAdjustedSource = getAdjustedFieldSource(viewConfig)(rest);
                  const allFields = getAllFieldEntriesFromViewObj(rest).reduce((prev, [k, viewField]) => {
                      const keys = isFieldViewField(viewField) ? [getAdjustedSource(viewField, k), k] : [k];
                      keys.forEach((key) => {
                          prev[key] = true;
                      });
                      return prev;
                  }, {} as { [fieldName: string]: true });
                  const evc = JSON.parse(rest.config) as EntityViewConfig;

                  if (evc.visibleField) {
                      evc.visibleField = evc.visibleField.map((visObj) =>
                          Object.fromEntries(
                              Object.entries(visObj).filter(([k, v]) => {
                                  return allFields[k];
                              }),
                          ),
                      );
                  }
                  if (evc.editableField) {
                      evc.editableField = evc.editableField.map((visObj) =>
                          Object.fromEntries(
                              Object.entries(visObj).filter(([k, v]) => {
                                  return allFields[k];
                              }),
                          ),
                      );
                  }
                  if (evc.conceptIdsForFields) {
                      evc.conceptIdsForFields = Object.fromEntries(
                          Object.entries(evc.conceptIdsForFields).filter(([k]) => allFields[k]),
                      );
                  }
                  return JSON.stringify(evc);
              })();
        return {
            ...rest,
            config: newConfig,
        };
        // return rest;
    }, [initialValues, viewConfig]);
    const initiallyDefaultView =
        viewConfig.entities[initialValues.entity]?.defaultViews?.[initialValues.viewType]?.name === initialValues.name;
    const [isDefaultView, setIsDefaultView] = useState(initiallyDefaultView);
    const [newInitialValues, setNewInitialValues] = React.useState(_newInitialValues);

    const viewSubmissionData =
        isDefaultView !== initiallyDefaultView
            ? ({ ...newInitialValues, defaultView: isDefaultView } as View)
            : newInitialValues;
    return (
        <SplitPane split="vertical">
            <Pane initialSize="50%">
                <div style={{ padding: '1em', margin: '1em', overflow: 'scroll' }}>
                    <h2>Result</h2>
                    <Popup
                        renderDialogContent={({ closeDialog }) => (
                            <div>
                                <div style={{ margin: '1em' }}>
                                    <JSONEditorDemo json={newInitialValues} onChangeJSON={setNewInitialValues} />
                                </div>
                                <div style={{ margin: '1em' }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={JSON.stringify(newInitialValues, null, 1)}
                                        onChange={(e) => {
                                            try {
                                                setNewInitialValues(JSON.parse(e.target.value));
                                            } catch (e) {
                                                console.error(e);
                                            }
                                        }}
                                    />
                                </div>
                                <Button onClick={closeDialog}>Close</Button>
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <Button variant="outlined" size="small" onClick={openDialog()} endIcon={<EditIcon />}>
                                Modify before saving
                            </Button>
                        )}
                    />

                    {stableStringify(_newInitialValues) !== stableStringify(newInitialValues) ? (
                        <IconButton
                            aria-label="Undo changes to JSON"
                            onClick={() => setNewInitialValues(_newInitialValues)}
                        >
                            <UndoIcon />
                        </IconButton>
                    ) : null}
                    <ResultJsonDiff
                        prevViewName={props.mode === 'PUT' ? props.prevViewName : undefined}
                        initialValues={(() => {
                            const { entityVersion, ...restInitialValues } = newInitialValues;
                            return { ...restInitialValues, defaultView: isDefaultView } as View;
                        })()}
                        // diffFrom={diffFrom}
                        {...(() => {
                            if (props.diffFrom === 'provided') {
                                return {
                                    diffFrom: props.diffFrom,
                                    old: props.old,
                                };
                            }
                            return {
                                diffFrom: props.diffFrom,
                            };
                        })()}
                    />
                </div>
            </Pane>
            <div>
                <div style={{ padding: '1em', margin: '1em' }}>
                    <h2>Great!</h2>
                    <h3>Your view is ready.</h3>
                    <p>
                        It's recommended you save you work. You can reupload to continue editing at any time. If you
                        would like to test the view in practice, click "Test" below. If you are sure you want to save
                        the view and have others use it, click "Save to Backend".
                    </p>
                    {initialValues.viewType !== 'COMPONENT' && (
                        <div>
                            <label>
                                Default {initialValues.viewType} View
                                <input
                                    type="checkbox"
                                    checked={isDefaultView}
                                    onChange={(e) => setIsDefaultView(e.target.checked)}
                                />
                            </label>
                        </div>
                    )}
                    <div style={{ display: 'flex', marginLeft: '-3px', marginRight: '-3px', flexWrap: 'wrap' }}>
                        <div style={{ margin: '3px' }}>
                            <JsonDownload json={newInitialValues as View} />
                        </div>
                        <div style={{ margin: '3px' }}>
                            {props.mode === 'PUT' ? (
                                <SubmitViewButton
                                    onSuccess={onSuccess}
                                    mode="PUT"
                                    previousViewName={props.prevViewName}
                                    key={isDefaultView + ''}
                                    view={viewSubmissionData}
                                />
                            ) : (
                                <SubmitViewButton
                                    onSuccess={onSuccess}
                                    mode="POST"
                                    key={isDefaultView + ''}
                                    view={viewSubmissionData}
                                />
                            )}
                        </div>
                        <div style={{ margin: '3px' }}>
                            <TestViewButton
                                overwriteViewDef={diffFrom === 'viewDef if exists'}
                                view={initialValues as View}
                            >
                                Test View
                            </TestViewButton>
                        </div>
                    </div>
                </div>
            </div>
        </SplitPane>
    );
};

export default Result;
