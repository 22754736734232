import buildHeaders from 'sideEffect/buildHeaders';
import { saveAs } from 'file-saver';
const config = require('../../config.js');

export const downloadConfigPack = (branch: string, abortRef: AbortController) => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/admin/configs/read-config`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/zip',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            branch: branch,
        }),
        signal: abortRef.signal,
    });
    return fetch(request)
        .then((res) => {
            if (!res.ok) {
                throw new Error('Request failed with status ' + res.status);
            }

            return res.blob();
        })
        .then((blob) => {
            saveAs(blob, `${branch === null ? 'main(default)ConfigPack' : branch}.zip`);
        });
};
