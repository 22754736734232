import { Ast } from 'ts-spel/lib-esm/lib/Ast';

const mapAst = (cb: (node: Ast, precededByIfCompound?: Ast[]) => Ast) => {
    const innerMapAst = (node: Ast, precedingCompound?: Ast[]): Ast => {
        // TODO handle #this and #root.foo just like in
        switch (node.type) {
            case 'PropertyReference':
                return cb(node, precedingCompound);
            case 'VariableReference':
            case 'NumberLiteral':
            case 'NullLiteral':
            case 'BooleanLiteral':
            case 'StringLiteral':
                return node;
            case 'CompoundExpression':
                const newNode = cb(node) as typeof node;
                return {
                    ...newNode,
                    expressionComponents: newNode.expressionComponents.map((node, i, arr) => {
                        if (node.type !== 'PropertyReference') {
                            return innerMapAst(node, arr.slice(0, i));
                        }
                        return node;
                    }),
                };
            case 'Elvis':
                return {
                    ...node,
                    expression: innerMapAst(node.expression),
                    ifFalse: innerMapAst(node.ifFalse),
                };
            case 'MethodReference':
            case 'FunctionReference':
                return {
                    ...node,
                    args: node.args.map((n) => innerMapAst(n)),
                };
            case 'Indexer':
                return {
                    ...node,
                    index: innerMapAst(node.index),
                };
            case 'InlineList':
                return {
                    ...node,
                    elements: node.elements.map((n) => innerMapAst(n)),
                };
            case 'InlineMap':
                return {
                    ...node,
                    elements: Object.fromEntries(Object.entries(node.elements).map(([k, v]) => [k, innerMapAst(v)])),
                };
            case 'Negative':
                return {
                    ...node,
                    value: innerMapAst(node.value),
                };
            case 'OpAnd':
            case 'OpBetween':
            case 'OpDivide':
            case 'OpEQ':
            case 'OpGE':
            case 'OpGT':
            case 'OpLE':
            case 'OpLT':
            case 'OpMatches':
            case 'OpMinus':
            case 'OpModulus':
            case 'OpMultiply':
            case 'OpNE':
            case 'OpOr':
            case 'OpPlus':
                return {
                    ...node,
                    left: innerMapAst(node.left),
                    right: innerMapAst(node.right),
                };
            case 'OpNot':
            case 'Projection':
            case 'SelectionAll':
            case 'SelectionFirst':
            case 'SelectionLast':
                return {
                    ...node,
                    expression: innerMapAst(node.expression),
                };
            case 'OpPower':
                return {
                    ...node,
                    base: innerMapAst(node.base),
                    expression: innerMapAst(node.expression),
                };
            case 'Ternary':
                return {
                    ...node,
                    expression: innerMapAst(node.expression),
                    ifFalse: innerMapAst(node.ifFalse),
                    ifTrue: innerMapAst(node.ifTrue),
                };
        }
    };
    return (node: Ast) => innerMapAst(node);
};
/**
 *
 * @param ast
 * @param mapPath if argument is something we should insert path on, return path to insert in array. Otherwise, return empty array.
 * @returns
 */
const rebaseProperties = (ast: Ast, mapPath: (pathOnRootToMaybeInsertBefore: string) => string[]) => {
    return mapAst((node) => {
        switch (node.type) {
            case 'PropertyReference': {
                const mappedPath = mapPath(node.propertyName);
                if (mappedPath.length === 0) {
                    return node;
                }
                return {
                    type: 'CompoundExpression',
                    expressionComponents: mappedPath.concat(node.propertyName).map((pn, i) => ({
                        type: 'PropertyReference',
                        propertyName: pn,
                        nullSafeNavigation: i !== 0,
                    })),
                };
            }
            case 'CompoundExpression': {
                const [head, ...rest] = node.expressionComponents;
                let newExpressionComponents = node.expressionComponents;
                if (head.type === 'PropertyReference') {
                    newExpressionComponents = mapPath(head.propertyName)
                        .map<Ast>((pn) => ({
                            type: 'PropertyReference',
                            propertyName: pn,
                            nullSafeNavigation: true,
                        }))
                        .concat(node.expressionComponents);
                }
                if (head.type === 'VariableReference' && head.variableName === 'root') {
                    const headProperty = rest?.[0];
                    if (headProperty?.type !== 'PropertyReference') {
                        return node;
                    }
                    newExpressionComponents = ([head] as Ast[])
                        .concat(
                            mapPath(headProperty.propertyName).map<Ast>((pn) => ({
                                type: 'PropertyReference',
                                propertyName: pn,
                                nullSafeNavigation: true,
                            })),
                        )
                        .concat(rest);
                }
                return {
                    ...node,
                    expressionComponents: newExpressionComponents,
                };
            }
        }
    })(ast);
};

export default rebaseProperties;
