import * as widgetTypes from './components/generics/utils/widgetTypes';

export const configValidationsByWidgetType: {
    -readonly [widgetType in keyof typeof widgetTypes]?: (config?: string) => string | null;
} = {};

export const addFieldItemConfigValidation = (
    widgetType: keyof typeof widgetTypes,
    validation: (config?: string) => string | null,
) => {
    configValidationsByWidgetType[widgetType] = validation;
};
