import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { isManyReference, isOneReference } from 'components/generics/utils/viewConfigUtils/traversePath';

const denormalizeWithoutRootFromPaths = <ViewConfig extends { entities: ViewConfigEntities }>(
    rootEntityType: string,
    values: {},
    viewConfig: ViewConfig,
    entities: {},
    paths: string[],
) => {
    let res = {};
    Object.entries(values).forEach(([k, v]) => {
        // if a field is a reference
        if ((!k.endsWith('Id') && values[k + 'Id']) || (!k.endsWith('Ids') && values[k + 'Ids'])) {
            // DROP IT - If there's a reference, lets use that data from a lookup.
            // We lose expansion data, and instead use the denormalized structure, which means
            // if you want that expansion, you have to apply that delta to the flattened entity structure
            // before passing it into this function as an argument.
            return;
        } else if (k.endsWith('Id')) {
            const referencePath = k.slice(0, -2);
            const entityField = viewConfig.entities[rootEntityType]?.fields[referencePath];
            if (entityField && isOneReference(entityField) && entityField.relatedEntity) {
                res[k] = v;
                if (v) {
                    const drilldownExpansions = paths
                        .filter((p) => p.startsWith(referencePath + '.'))
                        .map((p) => p.slice((referencePath + '.').length))
                        .filter(Boolean);
                    res[referencePath] = denormalizeEntitiesByPaths(
                        entities,
                        drilldownExpansions,
                        viewConfig,
                        entityField.relatedEntity,
                        v as string,
                    );
                }
            }
        } else if (k.endsWith('Ids')) {
            const referencePath = k.slice(0, -3);
            const entityField = viewConfig.entities[rootEntityType]?.fields[referencePath];
            if (entityField && isManyReference(entityField) && entityField.relatedEntity) {
                res[k] = v;
                if (v && Array.isArray(v)) {
                    // should always be an array
                    const drilldownExpansions = paths
                        .filter((p) => p.startsWith(referencePath + '.'))
                        .map((p) => p.slice((referencePath + '.').length))
                        .filter(Boolean);
                    res[referencePath] = v.map((id) =>
                        denormalizeEntitiesByPaths(
                            entities,
                            drilldownExpansions,
                            viewConfig,
                            entityField.relatedEntity,
                            id,
                        ),
                    );
                }
            }
        } else {
            res[k] = v;
        }
    });
    return res;
};

export default denormalizeWithoutRootFromPaths;
