import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    CardActions,
    IconButton,
    useTheme,
    Typography,
} from '@material-ui/core';
import { WrappedFieldInputProps } from 'redux-form';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Popup from 'components/Popup';
import { fromPredicate, tryCatch, fromNullable } from 'fp-ts/lib/Option';
import EditValidationExpression from './EditExpression';
import { RootState } from 'reducers/rootReducer';

type Validation = { message: string; expression: string; level?: 'E' | 'W' };
type Validations = Validation[];
export interface ValidationsEditorProps {
    validations: Validations;
    disabled?: boolean;
    setValidationExpression: (validations: Validations) => void;
    renderValidationEditor: (params: {
        initialValues: Validation;
        onSubmit: (validation: Validation) => void;
    }) => JSX.Element;
}

export const ValidationsEditor: FunctionComponent<ValidationsEditorProps> = ({
    validations = [],
    setValidationExpression,
    disabled,
    renderValidationEditor,
}) => {
    const theme = useTheme();
    const getRenderDialogContent =
        (
            initialValues?: {
                expression: string;
                message: string;
            },
            i?: number,
        ) =>
        ({ closeDialog }) =>
            (
                <div style={{ margin: '1em', padding: '1em', minWidth: '70vw' }}>
                    <h2>{initialValues ? 'Edit' : 'Add'} Validation</h2>
                    {renderValidationEditor({
                        onSubmit: ({ message, expression, level }) => {
                            if (typeof i === 'number') {
                                const newData = [
                                    ...validations.slice(0, i),
                                    { message, expression, level },
                                    ...validations.slice(i + 1),
                                ];
                                setValidationExpression(newData);
                                closeDialog();
                            } else {
                                setValidationExpression([...validations, { message, expression, level }]);
                                closeDialog();
                            }
                        },
                        initialValues,
                    })}
                </div>
            );
    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Validation Message</TableCell>
                        <TableCell>Validation Expression</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {validations.map((v, i) => {
                        return (
                            <TableRow>
                                <TableCell>{v.message}</TableCell>
                                <TableCell>{v.expression}</TableCell>
                                <TableCell>{v.level ?? 'E'}</TableCell>
                                <TableCell>
                                    <Popup
                                        renderDialogContent={getRenderDialogContent(v, i)}
                                        renderToggler={({ openDialog }) => (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={openDialog()}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    />
                                    &nbsp;
                                    <Popup
                                        renderDialogContent={({ closeDialog }) => (
                                            <>
                                                <div style={{ margin: '1em' }}>Delete expression?</div>
                                                <CardActions>
                                                    <Button onClick={closeDialog}>Cancel</Button>
                                                    <Button
                                                        style={{ color: theme.palette.error.main }}
                                                        onClick={() => {
                                                            const newData = [
                                                                ...validations.slice(0, i),
                                                                ...validations.slice(i + 1),
                                                            ];
                                                            setValidationExpression(newData);
                                                            closeDialog();
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </CardActions>
                                            </>
                                        )}
                                        renderToggler={({ openDialog }) => (
                                            <IconButton
                                                style={{ color: theme.palette.error.main }}
                                                onClick={openDialog()}
                                            >
                                                <Delete />
                                            </IconButton>
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <br />
            <Popup
                renderDialogContent={getRenderDialogContent()}
                renderToggler={({ openDialog }) => (
                    <Button
                        style={{ float: 'right' }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={openDialog()}
                    >
                        &nbsp;Add&nbsp;
                        <Add />
                    </Button>
                )}
            />
        </>
    );
};

export interface ValidationExpressionEditorFieldProps {
    label: String;
    input?: WrappedFieldInputProps;
    disabled?: boolean;
    meta: {
        touched: boolean;
        error: string;
    };
    record?: {
        entityType: string;
        id: string;
    };
    className?: string;
}

const ValidationsEditorField: FunctionComponent<ValidationExpressionEditorFieldProps> = ({
    input,
    disabled,
    label,
    record,
}) => {
    const { value, onBlur } = input;
    const { id, entityType } = record ?? {};
    const relatedResourceName = useSelector((state: RootState) =>
        fromNullable(state.admin.entities[entityType])
            .mapNullable((e) => e[id])
            .mapNullable((e) => (e as typeof e & { name?: string }).name)
            .toUndefined(),
    );
    const validations = useMemo(() => {
        return fromPredicate<string>(Boolean)(value)
            .chain((validationStr) => tryCatch<Validations>(() => JSON.parse(validationStr)))
            .getOrElse([]);
    }, [value]);
    const setValidations = useCallback(
        (validations: Validations) => {
            onBlur(JSON.stringify(validations));
        },
        [onBlur],
    );
    return (
        <>
            <Typography variant="h6" component="div">
                {label}
            </Typography>
            {relatedResourceName && (
                <ValidationsEditor
                    renderValidationEditor={({ initialValues, onSubmit }) => (
                        <EditValidationExpression
                            resource={relatedResourceName}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        />
                    )}
                    disabled={disabled}
                    validations={validations}
                    setValidationExpression={setValidations}
                />
            )}
        </>
    );
};
export default ValidationsEditorField;
