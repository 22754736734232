import fromEntries from 'util/fromentries';

const isObject = (value) => !!value && value.constructor === Object;
const recurseReplaceEmptyStringsWithNull = (value) => {
    if (value === '') {
        return null;
    }
    if (isObject(value)) {
        return fromEntries(
            Object.entries(value).map(([k, v]) => {
                return [k, recurseReplaceEmptyStringsWithNull(v)];
            }),
        );
    }
    if (Array.isArray(value)) {
        return value.map((o) => recurseReplaceEmptyStringsWithNull(o));
    }
    return value;
};
export default recurseReplaceEmptyStringsWithNull;
