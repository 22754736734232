import {
    required,
    maxLength,
    minLength,
    maxValue,
    minValue,
    regex,
    getValidationInFormContext,
} from 'fieldFactory/util/validate';
import * as fieldTypes from '../../fieldTypes';
import getInputTypeFromFlowableField from './getTypeFromFlowableField';
import { FormFieldUnion } from './types';

export default (fieldDefinition: FormFieldUnion) => {
    const fieldType = getInputTypeFromFlowableField(fieldDefinition.type);
    const requiredValue = fieldDefinition.required;
    const params = fieldDefinition.params || {};

    const maxSizeValue = params.maxLength;
    const minSizeValue = params.minLength;
    const regexPatternString = params.regexPattern;
    const regexMessage = params.regexMessage ? params.regexMessage : 'Invalid format';

    const validate = [];
    const requiredNonEmptyArray = (value, _, props) => (!value || value.length === 0 ? 'Required' : undefined);

    if (requiredValue) {
        if (
            fieldType === fieldTypes.LIST ||
            fieldType === fieldTypes.REFMANY_MULTISELECT_IDSLIST ||
            fieldType === fieldTypes.VALUESET_MULTISELECT
        ) {
            validate.push(requiredNonEmptyArray);
        } else if (fieldType === fieldTypes.AUTH_CODE) {
            validate.push((value, _, props) => {
                if (!value || String(value).length !== 6) {
                    return 'Required';
                }
                return false;
            });
        } else if (fieldType === fieldTypes.CHECKBOX) {
            // when a checkbox is required, it's to prevent the page submission until it's checked.
            validate.push((value, _, props) => !value && 'Required');
        } else if (fieldDefinition.type === 'dropdown' || fieldDefinition.type === 'radio-buttons') {
            if (fieldDefinition.hasEmptyValue && fieldDefinition.options && fieldDefinition.options[0]) {
                const nameOfEmptyValue = fieldDefinition.options[0].name;
                validate.push((value, _, props) => {
                    if (
                        !value ||
                        (typeof value === 'object' && value.name && value.name === nameOfEmptyValue) ||
                        (typeof value === 'string' && value === nameOfEmptyValue)
                    ) {
                        return 'Required';
                    }
                });
            } else {
                validate.push(required);
            }
        } else {
            validate.push(required);
        }
    }

    if (typeof maxSizeValue !== 'undefined' && maxSizeValue !== '') {
        if (fieldType === fieldTypes.TEXT || fieldType === fieldTypes.MULTILINE_TEXT) {
            validate.push(maxLength(maxSizeValue));
        }
        if (fieldType === fieldTypes.INTEGER) {
            validate.push(maxValue(maxSizeValue, undefined, 'INTEGER'));
        }
        if (fieldType === fieldTypes.FLOAT) {
            validate.push(maxValue(maxSizeValue, undefined, 'FLOAT'));
        }
    }
    if (typeof minSizeValue !== 'undefined' && minSizeValue !== '') {
        if (fieldType === fieldTypes.TEXT || fieldType === fieldTypes.MULTILINE_TEXT) {
            validate.push(minLength(minSizeValue));
        }
        if (fieldType === fieldTypes.INTEGER) {
            validate.push(minValue(minSizeValue, undefined, 'INTEGER'));
        }
        if (fieldType === fieldTypes.FLOAT) {
            validate.push(minValue(minSizeValue, undefined, 'FLOAT'));
        }
    }

    if (regexPatternString !== undefined) {
        const regexPattern = new RegExp(regexPatternString);
        validate.push(regex(regexPattern, `${regexMessage}. The pattern is ${decodeURIComponent(regexPatternString)}`));
    }

    return validate.map((validation) => getValidationInFormContext(validation, fieldDefinition.id));
};
