import { RootState } from 'reducers/rootReducer';
import { push as pushAction } from 'connected-react-router';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { flatMap, withLatestFrom, map } from 'rxjs/operators';
import { of, empty } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

const redirect401Flow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        withLatestFrom(
            state$.pipe(map((state: RootState) => state.router.location.pathname)),
            state$.pipe(map((state: RootState) => state.router.location.search)),
        ),
        flatMap(([{ error: _error, payload }, currPath, currSearch]: [any, string, string]) => {
            const error = (() => {
                if (_error && _error instanceof Error) {
                    return _error;
                }
                if (payload && payload instanceof Error) {
                    return payload;
                }
                return null;
            })();
            if (error && (error as AjaxError).status && (error as AjaxError).status === 401) {
                if (currPath === '/') {
                    return of(pushAction('/logout-redirect'));
                }
                if (currPath !== '/login' && currPath !== '/logout-redirect') {
                    const path = `/logout-redirect?redirectTo=${encodeURIComponent(currPath + currSearch)}`;
                    return of(pushAction(path));
                }
            }
            return empty();
        }),
    );
export default redirect401Flow;
