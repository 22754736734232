import React, { useCallback } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/InfoOutlined';
import { Card } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { load as loadViewConfig } from 'viewConfig/actions';

interface ViewConfigNeedsRefreshBannerComponentProps {}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        card: {
            backgroundColor: theme.palette.background.paper,
            padding: '.5em 1em',
            borderRadius: 0,
            borderLeft: '4px solid ' + theme.palette.secondary.main,
            borderBottom: '1px solid grey',
            display: 'flex',
        },
        heading: {
            marginRight: '1em',
            marginLeft: '1em',
            flex: 1,
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular as any,
        },
    }),
);
const ViewConfigNeedsRefreshBannerComponent = (props: ViewConfigNeedsRefreshBannerComponentProps) => {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const refreshViewConfig = useCallback(() => {
        dispatch(loadViewConfig());
    }, [dispatch]);
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div style={{ height: '100%', width: '.5em' }} />
                <Info />
                <Typography className={classes.heading}>
                    You are not up to date with your latest changes. &nbsp;
                    <button onClick={refreshViewConfig}>Refresh ViewConfig</button>
                </Typography>
            </Card>
        </div>
    );
};
export default ViewConfigNeedsRefreshBannerComponent;
