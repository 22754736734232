import { Action } from 'redux';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { fetchJson } from '../util/fetchUtils';
import { API_URL } from '../config';
import { FETCH_START, FETCH_END, FETCH_ERROR } from 'actions/aor/types';
import { VALUE_SET_NOT_RECEIVED } from '../actions/constants';
import buildFetchOptions from './util/buildFetchOptions';
import SkipWhileTrying from './util/SkipWhileTrying';
import { ValuesetFromApi } from 'valueSets/domain';
import { transformValueset } from 'valueSets/transform';
import { conceptsReceived, loadValueSet as loadValueSetAction } from 'valueSets/actions';
import { getType } from 'typesafe-actions';

const skipTrying = SkipWhileTrying(getType(loadValueSetAction), (action: { payload: string }) => action.payload);

export function* loadValueSet(action: { payload: string } & Action) {
    const valueSetCode = action.payload;
    const fetchValueSet = yield select(
        (state: { valueSets: { invalid: boolean } }): boolean =>
            (state.valueSets[valueSetCode] || { invalid: true }).invalid,
    );
    if (!fetchValueSet) {
        // don't fetch
        // console.log(`cache is valid or value set already exists so don't load valueSet ${action.payload}`);
        skipTrying.actionFinished(valueSetCode);
        return;
    } else {
        // console.log(`cache is invalid, loading valueSet ${action.payload}`);
    }
    yield put({ type: FETCH_START });
    try {
        const options = buildFetchOptions();

        // TODO: todo this currently returns and array.
        //  Not sure if we want a separate endpoint for the gets on value sets that are also not secured?
        const { status, body } = yield call(
            fetchJson,
            `${API_URL}value-set/${valueSetCode}?includeInActive=true`,
            options,
        );
        if (status === 200) {
            const valueSetFromApi: ValuesetFromApi = JSON.parse(body);
            const valueSet = transformValueset(valueSetFromApi);
            const conceptsReceivedAction = conceptsReceived(valueSet.id, valueSetCode, valueSet, valueSetFromApi);

            yield put(conceptsReceivedAction);
            yield put({ type: FETCH_END });
        } else {
            yield put({ type: VALUE_SET_NOT_RECEIVED, status });
            yield put({ type: FETCH_ERROR });
        }
    } catch (error) {
        console.error(error);
        yield put({ type: VALUE_SET_NOT_RECEIVED, error });
        yield put({ type: FETCH_ERROR, error });
    } finally {
        skipTrying.actionFinished(valueSetCode);
    }
}

export default function* getViewConfigOnLoginSaga() {
    yield takeEvery(skipTrying.takeAction, function* (action: { payload: string } & Action) {
        yield call(loadValueSet, action);
    });
}
