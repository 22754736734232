import React from 'react';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { Button } from '@material-ui/core';
import ContentSend from '@material-ui/icons/Send';
import { buttonStyle } from '../LinkWidget/Component';
import { RootState } from 'reducers/rootReducer';
import { startProcess } from 'bpm/create-process-instance/actions';

interface StartProcessWidgetProps {
    processDefinitionKey: string;
    startVariables?: {} | string;
    title: string;
}
const mapStateToProps = (state: RootState, ownProps: StartProcessWidgetProps) => ({
    processDefinition:
        state.bpm &&
        state.bpm.processDefinitions &&
        state.bpm.processDefinitions.byId &&
        state.bpm.processDefinitions.byId[ownProps.processDefinitionKey],
});

const dispatches = {
    createProcessInstance: startProcess,
    pushAction,
};
type Dispatches = typeof dispatches;

const handlers = {
    createAndRedirectOnSuccess:
        (props: StartProcessWidgetProps & ReturnType<typeof mapStateToProps> & Dispatches) => () => {
            if (!props.processDefinition) {
                return;
            }
            const values =
                typeof props.startVariables === 'string'
                    ? (() => {
                          if (!props.startVariables) {
                              return undefined;
                          }
                          try {
                              return JSON.parse(props.startVariables);
                          } catch (e) {
                              return undefined;
                          }
                      })()
                    : props.startVariables;
            props.createProcessInstance(
                {
                    processDefinitionId: props.processDefinition.id,
                    values,
                },
                () => null,
                {},
                true,
            );
        },
};
type Handlers = {
    [key in keyof typeof handlers]: ReturnType<(typeof handlers)[key]>;
};

const StartProcessWidgetComponent = (
    props: StartProcessWidgetProps & ReturnType<typeof mapStateToProps> & Handlers,
) => {
    const { title, createAndRedirectOnSuccess } = props;
    return (
        <Button variant="contained" style={buttonStyle} onClick={createAndRedirectOnSuccess} color="primary">
            {title}&nbsp;
            <ContentSend />
        </Button>
    );
};

const enhance = compose(connect(mapStateToProps, dispatches), withHandlers(handlers));

const StartProcessWidget: React.ComponentType<StartProcessWidgetProps> = enhance(StartProcessWidgetComponent);
export default StartProcessWidget;
