import { useStateMachine } from 'little-state-machine';
import viewWizardReducer, { ViewWizardState, ViewWizardAction } from '../viewWizardReducer';
const useViewWizardState = () => {
    const { state, action } = useStateMachine(viewWizardReducer);
    return {
        state: state as ViewWizardState,
        action: action as (action: ViewWizardAction) => void,
    };
};
export default useViewWizardState;
