import React, { useCallback } from 'react';
import { Button, CardContent } from '@material-ui/core';
import { ajax, AjaxError } from 'rxjs/ajax';
import useLocalStorageState, { createLocalStorageStateHook } from 'use-local-storage-state';
import { getBaseModelerUrlSelector } from 'util/applicationConfig';
import { useSelector } from 'react-redux';

const IS_AUTHORIZED_KEY = 'bpm-modeler-is-authorized';
const useLocalStorageStateHook = createLocalStorageStateHook<string>(IS_AUTHORIZED_KEY);

interface ModelerAuthSubmitProps {
    baseUrl: string;
    username: string;
    password: string;
    onSuccess: () => void;
}
const ModelerAuthSubmit: React.FC<ModelerAuthSubmitProps> = ({
    baseUrl,
    username: j_username,
    password: j_password,
    onSuccess,
}) => {
    const auth = useCallback(() => {
        const $ajax = ajax({
            url: baseUrl + '/idm/app/authentication',
            method: 'POST',
            withCredentials: true,
            body: {
                j_username,
                j_password,
                _spring_security_remember_me: true,
                submit: 'Login',
            },
        });
        const subscription = $ajax.subscribe(
            (res) => {
                onSuccess();
            },
            (error: AjaxError) => {
                console.log({
                    error,
                });
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [baseUrl, j_username, j_password, onSuccess]);
    return (
        <div>
            <Button variant="contained" color="primary" onClick={auth}>
                Login
            </Button>
        </div>
    );
};

interface AuthFormProps {
    onSuccess: (modelerUrl: string) => void;
}
export const AuthForm: React.FC<AuthFormProps> = ({ onSuccess }) => {
    const baseModelerUrl = useSelector(getBaseModelerUrlSelector);
    const [baseUrl = baseModelerUrl, setBaseUrl] = useLocalStorageStateHook();
    const [password, setPassword] = useLocalStorageState('modeler-password', '');
    const [username, setUsername] = useLocalStorageState('modeler-username', '');
    return (
        <div style={{ padding: '1em' }}>
            <CardContent>
                <label>
                    Base URL
                    <input type="text" value={baseUrl} onChange={(e) => setBaseUrl(e.target.value)} />
                </label>
                <br />
                <label>
                    username
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                </label>
                <br />
                <label>
                    password
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <br />
                {username && password && baseUrl && (
                    <ModelerAuthSubmit
                        username={username}
                        password={password}
                        baseUrl={baseUrl}
                        onSuccess={() => onSuccess(baseUrl)}
                    />
                )}
            </CardContent>
        </div>
    );
};

interface BpmModelerAuthControllerProps {
    children: (baseUrl: string) => JSX.Element;
}
const BpmModelerAuthController: React.FC<BpmModelerAuthControllerProps> = (props) => {
    const [isAuthorized, setIsAuthorized] = useLocalStorageState('modeler-hasCookie', false);
    const baseModelerUrl = useSelector(getBaseModelerUrlSelector);
    const [baseUrl = baseModelerUrl] = useLocalStorageStateHook();
    if (!isAuthorized) {
        return <AuthForm onSuccess={() => setIsAuthorized(true)} />;
    }
    if (!baseUrl) {
        return <div>No base url</div>;
    }
    return props.children(baseUrl);
};

export default BpmModelerAuthController;
