import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number; // currently selected index
    tabsName: string;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, tabsName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${tabsName}-tabpanel-${index}`}
            aria-labelledby={`${tabsName}-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export function KeepMountedTabPanel(props: TabPanelProps) {
    const { children, value, index, tabsName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${tabsName}-tabpanel-${index}`}
            aria-labelledby={`${tabsName}-tab-${index}`}
            style={{
                visibility: index === value ? 'visible' : 'hidden',
            }}
            {...other}
        >
            {children}
        </div>
    );
}

export function a11yProps(tabsName: string, index: number) {
    return {
        id: `${tabsName}-tab-${index}`,
        'aria-controls': `${tabsName}-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
}));

interface SimpleTabsProps<TabLabelUnion> {
    'aria-label': string;
    tabGroupName?: string;
    tabLabels: TabLabelUnion[];
    keepTabsMounted?: boolean;
    renderTab: (props: { label: TabLabelUnion }) => JSX.Element;
}
export const SimpleTabs = <TabLabelUnion extends string>(props: SimpleTabsProps<TabLabelUnion>) => {
    const { tabLabels, renderTab, 'aria-label': ariaLabel, tabGroupName = ariaLabel, keepTabsMounted = false } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    const TabPanelComponent = keepTabsMounted ? KeepMountedTabPanel : TabPanel;
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label={ariaLabel}>
                    {tabLabels.map((label, i) => (
                        <Tab label={label} {...a11yProps(tabGroupName, i)} />
                    ))}
                </Tabs>
            </AppBar>
            {tabLabels.map((label, i) => (
                <TabPanelComponent tabsName={tabGroupName} value={value} index={i}>
                    {renderTab({ label })}
                </TabPanelComponent>
            ))}
        </div>
    );
};
