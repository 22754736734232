import { select } from 'redux-saga/effects';
import { MERGE, MERGE_FAILURE, MERGE_SUCCESS } from '../actions/constants';
import sagaFactory, { configType, HttpVerb } from './util/sagaFactory';

type payloadType = { resource: string; id: string; matchId: string; result: {} };
const config: configType = {
    actionType: MERGE,
    verb: HttpVerb.POST,
    url: function* (payload: payloadType) {
        const viewConfig = yield select(
            (state: { viewConfig: { entities: { [resource: string]: { restUrl: string } } } }) => state.viewConfig,
        );
        return yield `${viewConfig.entities[payload.resource].restUrl}/merge`;
    } as any,
    body: (payload: payloadType) => ({
        entityId: payload.id,
        matchId: payload.matchId,
        result: payload.result,
    }),
    success: (payload, responseBody) => ({ type: MERGE_SUCCESS, payload, responseBody }),
    failure: MERGE_FAILURE,
    callCb: (_1, responseBody, cb) => {
        cb(responseBody);
    },
    callErrorCb: (cb) => {
        if (cb) {
            cb();
        }
    },
};

const saga = sagaFactory(config);
export default saga;
