import ViewConfig, { ComponentField } from '../../reducers/ViewConfigType';
import { fromEither, Option, fromNullable, some } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { parsingOrValidationErrMsg } from '../../expressions/formValidation';
import { parseConfig } from '../../expressions/entityViewConfig/parse';
import { getExpressionsFromConfig } from '../../expressions/expressionArrays/';
import setupValuesetFieldsRequired from '../../viewConfigCalculations/util/setupValuesetFieldsRequired';
import removeMethodHashes from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import {
    getRefEntityName,
    isValidEntityFieldExpression,
} from 'components/generics/utils/viewConfigUtils/getFieldProperties';
import {
    expandComponentFields,
    getAllFieldEntriesFromView,
    getViewConfiguration,
    getViewNameFromComponentField,
    isComponentField,
} from 'components/generics/utils/viewConfigUtils';
import getImpl from 'expressions/Provider/implementations/getImpl';

const makeGetExp =
    (validationType: keyof EntityViewConfig & ('visibleField' | 'editableField')) =>
    (
        viewName: string,
        resource: string,
        viewConfig: ViewConfig,
        viewConfiguration: Option<string>,
        rebaseOntoRootPath?: string,
    ): Option<{
        [fieldName: string]: {
            expression: string;
            fieldName: string;
            expansionsRequired: string[];
            dataPaths: string[];
            valuesetLiterals: string[];
            valuesetFieldsRequired: {
                [fieldSource: string]: string;
            };
        }[];
    }> => {
        const fieldEntriesForView = getAllFieldEntriesFromView(viewConfig, viewName);
        const componentFields: {
            [fieldIdentifier: string]: ComponentField;
        } = fieldEntriesForView.reduce((prev, [k, f]) => {
            if (!isComponentField(f)) {
                return prev;
            }
            prev[k] = f;
            return prev;
        }, {});
        const componentFieldExpressions = fieldEntriesForView
            .flatMap(([k, f]) => {
                if (!isComponentField(f)) {
                    return [];
                }
                const componentViewName = getViewNameFromComponentField(f);

                const exp = makeGetExp(validationType)(
                    componentViewName,
                    !f.field ? resource : getRefEntityName(viewConfig, resource, f.field, 'TRAVERSE_PATH'),
                    viewConfig,

                    fromEither(
                        // this should take viewName
                        tryCatch(() => getViewConfiguration(viewConfig, componentViewName)).mapLeft((e) =>
                            console.log(e),
                        ),
                    ).chain(fromNullable),
                    f.field,
                ).map((c): typeof c => {
                    return Object.fromEntries(
                        Object.entries(c).map(([key, v]: [string, any]) => {
                            const newKey = [k, key].join('.');
                            return [newKey, v.map((v) => ({ ...v, fieldName: newKey }))];
                        }),
                    );
                });
                return [exp.getOrElse({})];
            })
            .reduce((prev, curr) => {
                return { ...prev, ...curr };
            }, {});

        const currentViewExpressions = viewConfiguration
            .map(parseConfig)
            .map((e) => e.map((c) => c[validationType]))
            // prints errors for the failed configs
            .map((e) =>
                e.mapLeft((error) => {
                    console.error(parsingOrValidationErrMsg(error));
                    return error;
                }),
            )
            .chain(fromEither)
            .map((v) => v || [])
            .map((v: { [f: string]: string }[]): { [fieldName: string]: string[] } =>
                // v is an array of objects (fieldname: expression)
                v
                    .map((o): { [f: string]: string[] } =>
                        Object.assign({}, ...Object.entries(o).map(([k, e]) => ({ [k]: [e] }))),
                    )
                    .reduce(
                        (prev, curr) =>
                            Object.assign(
                                {},
                                ...Object.entries(curr).map(([k, exps]) => ({
                                    ...prev,
                                    [k]: prev[k] ? [...prev[k], ...exps] : exps,
                                })),
                            ),
                        {},
                    ),
            )
            .map((v) => {
                // Either empty object or array object
                return mapOption(
                    Object.entries(v)
                        .flatMap(([f, exps]) => exps.map((e) => [f, e]))
                        .filter(([f, e]) => {
                            const fieldPath = f.endsWith('Id')
                                ? f.slice(0, -2)
                                : f.endsWith('Ids')
                                ? f.slice(0, -3)
                                : f;
                            // try to do the lookup of the fieldName in the viewConfig here,
                            // so that expressions on fields the user doesn't have access to
                            // fail and are scrapped.
                            // *references to expressionFields (non-data) starting with $ are allowed
                            const isValid =
                                fieldPath.startsWith('$') ||
                                isValidEntityFieldExpression(viewConfig, resource, fieldPath) ||
                                // fallback for widgets with custom ids.
                                fieldEntriesForView?.some(([key]) => key === fieldPath);
                            if (!isValid) {
                                console.log(
                                    f + ':' + resource + ' not found as target of ' + validationType + ' expression',
                                );
                            }

                            return isValid;
                        }),
                    ([fieldName, expression]) =>
                        fromEither(
                            tryCatch(
                                () => {
                                    const expressions = getExpressionsFromConfig(expression);
                                    const [expansionsRequired, dataPaths, valuesetLiterals, expr] = expressions
                                        .map((expressionStrings) =>
                                            expressionStrings.map(removeMethodHashes).flatMap((exp) => {
                                                const impl = getImpl();
                                                let compiled = impl.compileExpression(exp);
                                                if (compiled.type === 'parse_failure') {
                                                    throw new Error(compiled.msg);
                                                }
                                                let expr = expression;
                                                if (rebaseOntoRootPath) {
                                                    compiled = compiled.rebase
                                                        ? compiled.rebase((path) => {
                                                              // Verify path
                                                              if (
                                                                  isValidEntityFieldExpression(
                                                                      viewConfig,
                                                                      resource,
                                                                      path,
                                                                  )
                                                              ) {
                                                                  return [rebaseOntoRootPath];
                                                              }
                                                              return [];
                                                          })
                                                        : compiled;
                                                    expr = compiled.prettyPrint();
                                                }
                                                return [
                                                    compiled.getExpansionsWithAll(),
                                                    compiled.getPathsWithAll(),
                                                    compiled.getValueSetLiterals(),
                                                    expr,
                                                ] as [string[], string[], string[], string];
                                            }),
                                        )
                                        .fold(
                                            (l) => [],
                                            (r) => r,
                                        ) as [string[], string[], string[], string];
                                    return {
                                        expression: expr,
                                        fieldName,
                                        expansionsRequired,
                                        dataPaths,
                                        valuesetLiterals,
                                    };
                                },
                                (e: Error) => {
                                    // prints errors for the failed SPEL compilation
                                    console.error('Error parsing SPEL entityConfig validation expression', e);
                                    return e;
                                },
                            ).chain(setupValuesetFieldsRequired(viewConfig, resource, expression)),
                        ),
                );
            })
            .map((v): { [fieldName: string]: typeof v } =>
                v.reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.fieldName]: prev[curr.fieldName] ? [...prev[curr.fieldName], curr] : [curr],
                    }),
                    {},
                ),
            )
            .map((conf) => {
                return Object.fromEntries(
                    Object.entries(conf).flatMap(([k, v]) => {
                        if (!componentFields[k]) {
                            return [[k, v]];
                        }
                        const expandedFields = expandComponentFields(viewConfig, [[k, componentFields[k]]], resource, {
                            rebaseExpressionsWithinFields: false,
                        }).expandedFieldsByRow.flat();
                        return expandedFields.map(([fieldKey]) => [fieldKey, v]);
                    }),
                );
            });

        if (currentViewExpressions.isNone()) {
            if (Object.keys(componentFieldExpressions).length > 0) {
                return some(componentFieldExpressions);
            }
        }

        return currentViewExpressions.map((v) => ({ ...componentFieldExpressions, ...v }));
    };

export default makeGetExp;
