import React, { useState } from 'react';
import JsonSchemaFormBuilder from 'fieldFactory/input/components/JsonSchemaFormBuilder';
import { Divider, TextareaAutosize } from '@material-ui/core';

const JsonSchemaFormBuilderPage = () => {
    const [value, setValue] = useState('');
    return (
        <div>
            <JsonSchemaFormBuilder
                includeValidations
                input={{
                    value,
                    onChange: setValue,
                }}
            />
            <Divider />
            <div style={{ marginTop: '2em' }}>
                <TextareaAutosize
                    style={{ width: '100%' }}
                    rowsMin={8}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => setValue(e.target.value)}
                />
            </div>
        </div>
    );
};
export default JsonSchemaFormBuilderPage;
