import { PersonalizedReport } from 'custom-reports/types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import { getUseRelativeDatesSelector } from 'util/applicationConfig';
import useRemoteData from 'util/hooks/useRemoteData';
import produce from 'immer';

const usePersonalizedReport = (id: string, fetchKey?: unknown) => {
    const { state, fold, setSuccess, setError, setPending } = useRemoteData<PersonalizedReport, string>();
    const relativeDates = useSelector(getUseRelativeDatesSelector);
    useEffect(() => {
        setPending();
        const $ajax = services.personalizedReports.get(id);
        const subscription = $ajax.subscribe(
            (res) => {
                const personalizedReport = relativeDates
                    ? produce(res, (draft) => {
                          draft.params.forEach((param) => {
                              if (param.type?.toLowerCase() === 'date') {
                                  param.type = 'relative-date';
                              }
                          });
                      })
                    : res;
                setSuccess(personalizedReport);
            },
            (error: AjaxError) => {
                setError(error.message);
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [id, setError, setPending, setSuccess, relativeDates, fetchKey]);
    return state;
};

export default usePersonalizedReport;
