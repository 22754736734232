import { getSubExpressionsOfFilterTemplate } from 'viewConfigCalculations/filterExpressions/epic';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';

export const getTemplateDataPaths = (templateString: string): { expansionsRequired: string[]; dataPaths: string[] } => {
    if (typeof templateString === 'string') {
        const subExpressions = getSubExpressionsOfFilterTemplate(templateString);
        // this is inefficient, since we're compiling each expression to inspect it twice.
        return {
            expansionsRequired: subExpressions.flatMap((exp) => getFieldsRequiredForExpression(exp)),
            dataPaths: subExpressions.flatMap((exp) => getFieldsRequiredForExpression(exp, true)),
        };
    }
    return { expansionsRequired: [], dataPaths: [] };
};
