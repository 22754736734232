import { initial } from '@devexperts/remote-data-ts';
import type { RootState } from 'reducers/rootReducer';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';
import { createSelector } from 'reselect';
import { getStartFormIndex } from './getStartFormIndex';

export const createStartFormSelector = () => {
    return createSelector(
        (state: RootState, processDefinitionId: string, taskFormKey?: string) => {
            if (!processDefinitionId) {
                return null;
            }
            const index = getStartFormIndex(processDefinitionId, taskFormKey);
            return state.bpm.startForms[index];
        },
        (serializedRd) => (serializedRd ? deserialize(serializedRd) : initial),
    );
};
