import * as createTask from './actions';
import { CreateTaskResponse } from './types';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, flatMap, tap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { getResponseAndThrowErrorForNon200 } from 'sideEffect/crud/util/epics/CoreCrud/shared';

const createTaskFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(createTask.createTask)),
        flatMap(({ payload, successCb, errorsCbs }) =>
            services.createTask(payload).pipe(
                (r) => getResponseAndThrowErrorForNon200<CreateTaskResponse>(r),
                tap((response) => successCb && successCb(response)),
                flatMap((response) =>
                    concat(
                        of(createTask.createTaskSuccess(response)),
                        of(enqueueSnackbarAction({ message: 'Task created', options: { variant: 'success' } })),
                    ),
                ),
                catchError((err) => {
                    const forAllErrorsCb = errorsCbs['*'];
                    if (forAllErrorsCb) {
                        forAllErrorsCb();
                    }
                    const maybeErrorCb = errorsCbs[err.status];
                    if (maybeErrorCb) {
                        maybeErrorCb();
                    }
                    return concat(
                        of(enqueueSnackbarAction({ message: 'Failed to create task', options: { variant: 'error' } })),
                        of(createTask.createTaskFailure(err)),
                    );
                }),
            ),
        ),
    );
export default createTaskFlow;
