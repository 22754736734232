import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import flatten from 'lodash/flatten';
import clone from 'clone';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

export type BaseLayoutItem = {
    i: string;
    x: number;
    y: number;
    w: number;
    h?: number;
    minW?: number;
    maxW?: number;
};
export const correctLayout = <L extends BaseLayoutItem>(layout: L[]): L[] => {
    /*
        order by row,
        iterate, increasing row, each time getting largest item in row and pushing down rest of rows by the overflow amount.
    */
    const byRow = groupBy(clone(layout), 'y');
    const getEntriesSortedByRow = () => sortBy(Object.entries(byRow), 0);
    for (let i = 0; i < getEntriesSortedByRow().length; i++) {
        const [_currentRow, currentItems] = getEntriesSortedByRow()[i];
        const currentRow = parseFloat(_currentRow);
        const lowestPointInRow = currentItems.reduce((prev, curr) => {
            const currentBottom = currentRow + curr.h;
            return currentBottom > prev ? currentBottom : prev;
        }, currentRow);
        // shift all rows greater than current row down by N if there is any row < lowestPointInRow but > currentRow
        // N = row found - lowestPointInRow
        const rowsBelowCurrentRow = Object.entries(byRow)
            .filter(([row, items]) => {
                return parseFloat(row) > currentRow;
            })
            .map(([r, items]) => [parseFloat(r), items] as const);
        const rowsBetweenCurrentRowAndLowestPointInRow = Object.entries(byRow)
            .filter(([row, items]) => {
                return parseFloat(row) > currentRow && parseFloat(row) < lowestPointInRow;
            })
            .map(([r, items]) => [parseFloat(r), items] as const);
        if (rowsBetweenCurrentRowAndLowestPointInRow.length > 0) {
            const shiftEverythingDownBy = lowestPointInRow - minBy(rowsBetweenCurrentRowAndLowestPointInRow, 0)[0];
            rowsBelowCurrentRow.forEach(([r, items]) => {
                delete byRow[r];
            });
            rowsBelowCurrentRow.forEach(([r, items]) => {
                const y = r + shiftEverythingDownBy;
                byRow[`${y}`] = items.map((item) => ({ ...item, y }));
            });
        }
    }
    const correctedItems = flatten(Object.values(byRow));
    const is = layout.map((l) => l.i).map((i) => correctedItems.find((e) => e.i === i));
    return compactLeft<L>(removeSpaceBetweenRows<L>(is));
};

export const removeSpaceBetweenRows = <L extends BaseLayoutItem>(layout: L[]): L[] => {
    const _l: {
        [l: number]: L[];
    } = groupBy(clone(layout), 'y');
    const startingRow = Math.min(...Object.keys(_l).map((k) => parseFloat(k)));
    const l = Object.fromEntries(
        Object.entries(_l).map(
            ([k, items]) =>
                [parseFloat(k) - startingRow, items.map((v) => ({ ...v, y: v.y - startingRow }))] as [number, L[]],
        ),
    );
    const keys = Object.keys(l).sort((a, b) => parseFloat(a) - parseFloat(b));

    keys.forEach((k, i) => {
        const largestH = maxBy(l[k], 'h').h;
        const nextY = l[k][0].y + largestH;
        if (i + 1 < keys.length) {
            l[keys[i + 1]].forEach((e) => {
                e.y = nextY;
            });
        }
    });
    const correctedItems = flatten(Object.values(l));
    const is = layout.map((l) => l.i).map((i) => correctedItems.find((e) => e.i === i));
    return is;
};

export const compactLeft = <L extends BaseLayoutItem>(layout: L[]): L[] => {
    const l = groupBy(clone(layout), 'y');
    Object.values(l).forEach((row) => {
        const leftToRight = sortBy(row, 'x');
        leftToRight.reduce((nextXPosition, curr) => {
            curr.x = nextXPosition;
            return curr.x + curr.w;
        }, 0);
    });
    const correctedItems = flatten(Object.values(l));
    const is = layout.map((l) => l.i).map((i) => correctedItems.find((e) => e.i === i));
    return is;
};
