import React from 'react';
import { Helmet } from 'react-helmet';
import useBasicInfo from 'util/hooks/useBasicInfo';

const WindowTitle = (props) => {
    const basicInfo = useBasicInfo();
    if (basicInfo && basicInfo.title) {
        return (
            <Helmet>
                <title>{basicInfo.title}</title>
            </Helmet>
        );
    }
    return null;
};
export default WindowTitle;
