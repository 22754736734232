import React from 'react';
import { useDispatch } from 'react-redux';
import useViewConfig from 'util/hooks/useViewConfig';
import generateMenuElements from 'components/layouts/menu2/generateMenuElements';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const NavMenu = () => {
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const applyTranslations = useEvaluateFormattedMessage().evaluateFormattedMessage;
    return (
        <div>
            {generateMenuElements({ dispatch, viewConfig, ButtonProps: { color: undefined }, applyTranslations })(
                viewConfig.menus,
            )}
        </div>
    );
};

export default NavMenu;
