import React from 'react';
import { NavLink } from 'react-router-dom';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { overrideAppbarTitleSelector } from '../overrideAppbarTitleSelector';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import ThemeableToolbarTitle from 'components/SsgToolbarTitle';

const useAppTitleElem = () => {
    const { overriddenAppbarTitleHtml = '', navLink } = useSelector(overrideAppbarTitleSelector);
    const evaluate = useEvaluateTemplate(overriddenAppbarTitleHtml);
    const templatedHtml = useMemo(() => evaluate(overriddenAppbarTitleHtml), [overriddenAppbarTitleHtml, evaluate]);

    const TitleElem =
        templatedHtml && navLink ? (
            <NavLink to="/" style={{ textDecoration: 'none', color: 'unset', flex: 1 }}>
                <SafeHtmlAsReact html={templatedHtml} />
            </NavLink>
        ) : templatedHtml ? (
            <div style={{ flex: 1 }}>
                <SafeHtmlAsReact html={templatedHtml} />
            </div>
        ) : (
            <ThemeableToolbarTitle />
        );
    return TitleElem;
};

export default useAppTitleElem;
