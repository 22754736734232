// eslint-disable-next-line import/no-webpack-loader-syntax
import createWorker from 'workerize-loader?inline!./worker';
import * as SaveOfflineEncryptedDataToIDB from './worker';

const saveWorkerInstance = createWorker<typeof SaveOfflineEncryptedDataToIDB>();

// Attach an event listener to receive calculations from your worker
// saveWorkerInstance.addEventListener('message', message => {
//     console.log('New Message From Worker: ', message.data);
// });

export const { saveOfflineEncryptedDataToIDB } = saveWorkerInstance;
