import * as React from 'react';
import { Selector } from 'react-redux';
import translateViewFields from './translation/fromEntity/translateViewFields';
import translateFlowableFields from './translation/fromFlowable/translateFlowableFields';
import pipe from './util/pipe';
import { FieldFactoryContext } from './Broadcasts';
import generateInputFields from './input/generateField';
import generateDisplayFields from './display/generateField';
import mapToFormField from './mapToFormField';
import ViewConfig from '../reducers/ViewConfigType';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
import { RootState } from 'reducers/rootReducer';
import { IntlShape, useIntl } from 'react-intl';
import useViewConfig from 'util/hooks/useViewConfig';
import { DataSource } from './translation/types/DataSource';
import { Field } from './translation/types';
import { Mode } from './Mode';
import { Config, FlowableConfiguration, LiveFieldConfig, EntityLiveFieldProps } from './ConfigTypes';

const getTranslator = (dataSource: DataSource) => {
    switch (dataSource) {
        case DataSource.ENTITY:
            return translateViewFields;
        case DataSource.FLOWABLE:
            return translateFlowableFields;
        default:
            throw Error(`invalid datasource!!!: ${dataSource}`);
    }
};

const getFieldGenerator = (mode: Mode) => {
    switch (mode) {
        case Mode.DISPLAY:
            return generateDisplayFields;
        case Mode.INPUT:
        case Mode.INPUT_NOWARN:
            return generateInputFields;
        default:
            throw Error(`invalid mode! ${mode}`);
    }
};

const generateFieldFactory = (intl: IntlShape, viewConfig: ViewConfig) => (config: Config) => {
    // lets get the translation function to convert data source fields to our standard interface
    const translator = getTranslator(config.dataSource);
    const hasLinkedEntity = Object.prototype.hasOwnProperty.call(config.options, 'relatedEntityResource');
    const translateToStandardDefinition = (liveConfig) =>
        hasLinkedEntity
            ? translator(
                  intl,
                  viewConfig,
                  (config.options as FlowableConfiguration).relatedEntityResource, // should this be liveConfig?
                  config.mode,
              )
            : translator(intl, viewConfig, liveConfig.resource, config.mode);
    const generateFieldComponent = getFieldGenerator(config.mode);

    // convert the entire fieldDefinition array to the respective components with proper configuration
    const getBoundComponents = (liveProps) => (fieldDefinitions: Field[]) =>
        fieldDefinitions
            // strip validations if configuration is validate: false
            .map((fieldDefinition) => (config.validate ? fieldDefinition : { ...fieldDefinition, validate: undefined }))
            .map((fieldDefinition, i) =>
                generateFieldComponent(fieldDefinition, viewConfig, config.options, liveProps),
            );
    return (
        liveConfig: LiveFieldConfig,
        selector: Selector<RootState, any> | null = null,
        formContextSelector: (() => (fc: ReturnType<typeof evaluateContext2>, props?: {}) => {}) | null = null,
    ) => {
        const wrapComponents =
            config.connected === 'ff'
                ? mapToFormField({ ...(liveConfig as EntityLiveFieldProps), ff: true }, selector, formContextSelector)
                : config.connected
                ? mapToFormField(liveConfig as EntityLiveFieldProps, selector, formContextSelector)
                : (i) => i;
        const pipeline = (fieldDefinitions: {}[] | [string, {}][]) => {
            // entity-side, we can pass [fieldInstanceKey, ViewField] so we mark fieldInstanceIdentifier on fields that point to the same source
            // this won't really work TaskForm side.
            return pipe(
                translateToStandardDefinition(liveConfig),
                getBoundComponents(liveConfig),
                wrapComponents,
            )(fieldDefinitions);
        };

        return pipeline;
    };
};

export type GenerateFieldFactory = typeof generateFieldFactory;

const FieldFactoryProvider: React.FC<{}> = ({ children }) => {
    const viewConfig = useViewConfig();
    const intl = useIntl();
    const value = React.useMemo(() => {
        return generateFieldFactory(intl, viewConfig);
    }, [intl, viewConfig]);
    return <FieldFactoryContext.Provider value={value}>{children}</FieldFactoryContext.Provider>;
};
export default FieldFactoryProvider;
