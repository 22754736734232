import { encrypt } from 'encryption/encryption';
import { EncryptionInput } from 'encryption/encryptionUtils';
import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { offlineTaskToEntityKeyVal } from 'IndexedDB/offlineTaskToEntityKeyMap';
import sessionSecretsController from 'offline_app/sessionSecretsController';
import { Entry } from './EntitySubmitsInTaskContext/Entry';

const persistEncryptData = async (key: string, data: Entry) => {
    const encryptionInput: EncryptionInput = (() => {
        let secrets = sessionSecretsController.get();
        if (!secrets) {
            return null;
        }
        return {
            type: 'secrets' as const,
            secrets,
        };
    })();
    if (!encryptionInput) {
        // this shouldn't happen, but lets be graceful in production.
        if (process.env.NODE_ENV === 'production') {
            return;
        }
    }
    const [encryptedData] = await encrypt(data, encryptionInput);
    await offlineEntitySubmitsIdbKeyVal.set(key, encryptedData);
    // update mapping from taskIds onto encrypted data keys (submission dates)
    const existing = await offlineTaskToEntityKeyVal.get(data.taskId);
    // possible race condition here, but submitting encrypted entity saves on click is unlikely to be rapid enough
    // for that to be an issue.
    if (!existing?.[data.taskId]) {
        // add entity creation key.
        await offlineTaskToEntityKeyVal.set(data.taskId, {
            ...existing,
            [key]: true,
        });
    }
};
export default persistEncryptData;
