import React from 'react';
import FieldTitle from '../input/components/aor/FieldTitle';

const renderTextFieldLabel = (
    fieldVariant: 'standard' | 'outlined' | 'filled',
    renderLabel: boolean,
    isRequired?: boolean,
    label?: string,
) => {
    if (!renderLabel || (fieldVariant && fieldVariant !== 'standard' && !label?.trim())) {
        return '';
    }
    return <FieldTitle label={label?.trim()} isRequired={isRequired} />;
};
export default renderTextFieldLabel;
