import { getConfigProperty, simpleGetConfProperty } from './index';

interface WithViewName {
    viewName?: string;
    editViewName?: string;
    showViewName?: string;
    createViewName?: string;
    noSearch?: boolean;
    openTo?: 'show' | 'edit';
    overrideNavButtonHtml?: string;
}
export const getViewName = simpleGetConfProperty<WithViewName>('viewName', undefined);
export const getEditViewName = simpleGetConfProperty<WithViewName>('editViewName', undefined);
export const getShowViewName = simpleGetConfProperty<WithViewName>('showViewName', undefined);
export const getCreateViewName = simpleGetConfProperty<WithViewName>('createViewName', undefined);
export const gethasNoSearch = simpleGetConfProperty<WithViewName>('noSearch', false);
export const getOpenTo = simpleGetConfProperty<WithViewName>('openTo', undefined);
export const getOverrideNavButtonHtml = getConfigProperty<WithViewName>('overrideNavButtonHtml');
interface WithExpansions {
    expansions?: string[];
}
export const getExpansions = simpleGetConfProperty<WithExpansions>('expansions', []);
