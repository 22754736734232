import React, { useMemo } from 'react';
import Form from '@rjsf/material-ui/dist';
import { TextInput } from '../DebouncedTextInput';
import validator from '@rjsf/validator-ajv8';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { UiSchema } from '@rjsf/utils';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import get from 'lodash/get';
import { getRefEntityName } from 'components/generics/utils/viewConfigUtils';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import customWidgets from './customWidgets';

interface JsonSchemaFormProps {
    input: Pick<WrappedFieldInputProps, 'onChange' | 'onBlur' | 'value'>;
    meta?: WrappedFieldMetaProps;
    schema?: any;
    uiSchema?: UiSchema;
    readSchemasFromField?: string;
    disabled?: boolean;
}

export const useJsonSchemaFormSchemas = <
    Props extends {
        readSchemasFromField?: string;
        schema?: any;
        uiSchema?: UiSchema;
    },
>(
    props: Props,
) => {
    const fc = useCurrentFormContext();
    const formValues = fc.fieldValues;
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const { schema = props.schema, uiSchema = props.uiSchema } = useMemo((): { schema?: any; uiSchema?: UiSchema } => {
        if (props.readSchemasFromField) {
            let f = props.readSchemasFromField;
            const fieldPath = f.split('.');
            /**
             * field from our base record - either reaching the form definition value directly, or
             * at least reaching some dynamic reference which can then be further looked up using the remaining 'fieldFromRelationPath'
             * */

            let fieldFromUsPath: string = '';
            /**
             * if we have some dynamic reference, this field will be used to look up the form definition values from that referenced entity.
             * if it's empty, we can assume fieldFromUsPath contains the value we are after
             * */

            let fieldFromRelationPath: string = '';

            fieldPath.forEach((fieldInPath) => {
                const newPath = (fieldFromUsPath ? fieldFromUsPath + '.' : '') + fieldInPath;
                if (get(formValues, newPath) || get(formValues, newPath + 'Id')) {
                    fieldFromUsPath = newPath;
                } else {
                    fieldFromRelationPath += fieldFromRelationPath ? '.' + fieldInPath : fieldInPath;
                }
            });
            if (!fieldFromRelationPath) {
                const valueAtUsPath = get(formValues, fieldFromUsPath);
                if (!valueAtUsPath) {
                    return {};
                }
                const parsedValue = JSON.parse(valueAtUsPath);
                if (parsedValue.schema && parsedValue.uiSchema) {
                    return { schema: JSON.parse(parsedValue.schema), uiSchema: JSON.parse(parsedValue.uiSchema) };
                }
                return {};
            }
            const id = get(formValues, fieldFromUsPath + 'Id');
            try {
                const rootEntityType = viewConfig.views[fc.viewName].entity;
                const relEntityName = getRefEntityName(viewConfig, rootEntityType, fieldFromUsPath, 'TRAVERSE_PATH');
                return traverseGetData(
                    viewConfig,
                    fieldFromRelationPath,
                    { id, entityType: relEntityName },
                    entities,
                    false,
                ).fold({}, (field) => {
                    try {
                        const config = JSON.parse(field);
                        return { schema: JSON.parse(config.schema), uiSchema: JSON.parse(config.uiSchema) };
                    } catch (e) {
                        return {};
                    }
                });
            } catch (e) {
                return {};
            }
        }
        return {};
    }, [formValues, entities, viewConfig, props.readSchemasFromField, fc.viewName]);
    return { schema, uiSchema };
};
const JsonSchemaForm: React.FunctionComponent<JsonSchemaFormProps> = (props) => {
    const { schema, uiSchema: _uiSchema } = useJsonSchemaFormSchemas(props);
    const uiSchema = useMemo(
        () => ({
            ..._uiSchema,
            'ui:submitButtonOptions': {
                norender: true,
            },
        }),
        [_uiSchema],
    );

    if (!schema || !uiSchema) {
        return null;
    }
    return (
        <TextInput
            debounceTime={500}
            emptyInitialValue=""
            input={props.input}
            renderInput={(args) => {
                return (
                    <Form
                        validator={validator}
                        disabled={props.disabled}
                        schema={schema}
                        widgets={customWidgets}
                        uiSchema={uiSchema}
                        formData={JSON.parse(args.value || null) ?? ''}
                        onChange={(e) => {
                            args.onChange(JSON.stringify(e.formData));
                        }}
                        onBlur={() => {
                            args.onBlur(args.value);
                        }}
                    />
                );
            }}
        />
    );
};

export default JsonSchemaForm;
