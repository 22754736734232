import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { Entry } from 'offline_app/offline_entity_submits/EntitySubmitsInTaskContext/Entry';
import { getDecryptEntityDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeEntityData';

export const getOfflineSubmitEntries = async (taskId: string) => {
    const keys = await offlineEntitySubmitsIdbKeyVal.keys();
    const data: {
        [dateAdded: string]: Entry;
    } = {};
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const value: Entry = await getDecryptEntityDataPromptController().promptDecodeEntityData(key, false);
        if (value.taskId === taskId) {
            data[key] = value;
        }
    }
    return data;
};
