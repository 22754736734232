import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getTaskClaimedBySomeoneElsePopupRegistry } from './taskClaimedBySomeoneElsePopupRegistry';

const TaskClaimedBySomeoneElsePopup = () => {
    const [dialogOpen, setDialogOpen] = useState<{
        message: string;
        onContinue: () => void;
    }>(null);

    useEffect(() => {
        getTaskClaimedBySomeoneElsePopupRegistry().registerCallback(setDialogOpen);
        return () => {
            getTaskClaimedBySomeoneElsePopupRegistry().unregisterCallback(setDialogOpen);
        };
    }, []);
    if (!dialogOpen) {
        return null;
    }
    return (
        <Dialog open>
            ;
            <DialogContent>
                <Typography>{dialogOpen.message}</Typography>
            </DialogContent>
            <DialogActions style={{ margin: 8, display: 'flex', justifyContent: 'start' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setDialogOpen(null);
                        dialogOpen.onContinue();
                    }}
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default TaskClaimedBySomeoneElsePopup;
