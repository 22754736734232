import React from 'react';
import { WithStyles, createStyles, withStyles, Typography, Theme } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import useWidth from 'util/hooks/useWidth';

interface ErrorPageProps {
    retry?: (e?: any) => void;
    Icon: React.ComponentType<SvgIconProps>;
    headingText?: string;
    subText?: string;
}
export const styles = (theme: Theme) =>
    createStyles({
        root100: {
            width: '100%',
            height: '100%',
        },
        centerArea: {
            paddingTop: '25px',
            textAlign: 'center',
        },
        iconLg: {
            height: 200,
            width: 200,
        },
        iconSm: {
            height: 50,
            width: 50,
        },
    });
interface ErrorPageComponentProps extends ErrorPageProps, WithStyles<typeof styles> {}
const ErrorPageComponent: React.SFC<ErrorPageComponentProps> = (props) => {
    const { classes, retry, Icon, headingText, subText } = props;
    const width = useWidth();
    const isMobile = width === 'sm' || width === 'xs';
    return (
        <div className={classes.root100}>
            <div className={classes.centerArea}>
                <Icon className={isMobile ? classes.iconSm : classes.iconLg} />
                {headingText && (
                    <Typography variant={isMobile ? 'h5' : 'h3'} component="div">
                        {headingText}
                    </Typography>
                )}
                {subText && (
                    <React.Fragment>
                        <br />
                        <Typography>{subText}</Typography>
                    </React.Fragment>
                )}
                <br />
                {retry ? (
                    <button type="reset" onClick={retry}>
                        Retry
                    </button>
                ) : null}
            </div>
        </div>
    );
};
const ErrorPage: React.ComponentType<ErrorPageProps> = (withStyles as any)(styles)(ErrorPageComponent);
export default ErrorPage;
