import React from 'react';
import HtmlDisplayComponent from 'fieldFactory/display/components/HtmlDisplay';
import { createStyles, Theme, CircularProgress, CardContent, CardActions, Card, makeStyles } from '@material-ui/core';
import { Dialog, Button, DialogContent } from '@material-ui/core';
import { WorkingState } from './hooks/useWorkingState';
import useResourceDisplayName from 'util/hooks/useResourceDisplayName';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        progressContainer: {
            padding: '.5em',
            display: 'flex',
            flexDirection: 'row',
        },
    }),
);
interface WorkingDialogProps {
    resource: string;
    workingState: WorkingState;
    clearWorkingState: () => void;
}
const WorkingDialog: React.FunctionComponent<WorkingDialogProps> = (props) => {
    const classes = useStyles(props);
    const { workingState } = props;
    const resourceDisplayName = useResourceDisplayName(props.resource);
    if (!workingState) {
        return null;
    }
    const savingTaskForm = (submissionType: 'save' | 'submit') => (
        <div>
            {submissionType === 'save' ? 'Saving' : 'Completing'} Task...
            <CircularProgress style={{ width: 20, height: 20 }} />
        </div>
    );
    const taskFormFailed = (submissionType: 'save' | 'submit') => {
        const isOnline = navigator.onLine;
        if (!isOnline) {
            return <div>You are not connected to the internet. Please reconnect and retry.</div>;
        }
        return <div>Task Failed to {submissionType === 'save' ? 'Save' : 'Complete'}</div>;
    };
    const Container: React.SFC<{}> = (props) => (
        <DialogContent>
            <div className={classes.progressContainer}>{props.children}</div>
        </DialogContent>
    );
    const cancelSaveWarningDialog = () => {
        workingState.taskFormResubmit.onCancel();
        props.clearWorkingState();
    };
    return (
        <Dialog
            onClose={workingState.stage === 'TASK_SAVE_WARNING' ? cancelSaveWarningDialog : props.clearWorkingState}
            open={!!workingState}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            {!workingState ? null : workingState.stage === 'WORKING_ENTITY' ||
              workingState.stage === 'WORKING_TASKFORM' ||
              workingState.stage === 'WORKING_TASKFORM_NO_ENTITY' ? (
                <Container>{savingTaskForm(workingState.submissionType)}</Container>
            ) : workingState.stage === 'ENTITY_FAILED' ? (
                <Container>Failed to save {resourceDisplayName} data.</Container>
            ) : workingState.stage === 'TASKFORM_FAILED' || workingState.stage === 'TASKFORM_FAILED_NO_ENTITY' ? (
                <Container>{taskFormFailed(workingState.submissionType)}</Container>
            ) : workingState.stage === 'TASK_SAVE_WARNING' && workingState.taskFormResubmit ? (
                <Card>
                    <CardContent>
                        <h4>Warning</h4>
                        <HtmlDisplayComponent html={workingState.taskFormResubmit.warningMessage} />
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="primary" onClick={workingState.taskFormResubmit.resubmit}>
                            Continue
                        </Button>
                        <Button variant="contained" onClick={cancelSaveWarningDialog}>
                            Cancel
                        </Button>
                    </CardActions>
                </Card>
            ) : null}
        </Dialog>
    );
};

export default WorkingDialog;
