import { Store } from 'redux';
import { services } from 'sideEffect/services';
import { EMPTY } from 'rxjs';
import { storageController } from 'storage';
import { profileSelected } from '../actions';
import { push } from 'connected-react-router';
import { load as loadViewConfigAction } from 'viewConfig/actions';
import { AjaxError } from 'rxjs/ajax';

const loadProfile = (store: Store, profileUserId: string, redirectTo: string = '/') => {
    let curProfKey = storageController.getCurrentProfileToken();
    if (curProfKey) {
        // in order to impersonate another user, we need to use the token
        // we logged in with for the services.impersonateUser call
        storageController.clearCurrentProfileToken();
        // we will set this back on failure
    }
    const $impersonate = services.impersonateUser(profileUserId);
    const subscription = $impersonate.subscribe(
        (res) => {
            storageController.setCurrentProfileToken(res.response.id_token);

            store.dispatch(profileSelected(profileUserId));
            store.dispatch(push('/_temp'));
            store.dispatch(loadViewConfigAction(false, redirectTo));
        },
        (error: AjaxError) => {
            storageController.setCurrentProfileToken(curProfKey);
            return EMPTY;
        },
    );
    return subscription;
};
export default loadProfile;
