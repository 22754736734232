import { TOGGLE_PRINT_MODE } from '../actions/constants';

export default (previousState = false, { type, payload }) => {
    if (type === '@@router/LOCATION_CHANGE') {
        return false;
    }
    if (type === TOGGLE_PRINT_MODE) {
        return !previousState;
    }
    return previousState;
};
