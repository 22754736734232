import React from 'react';

const withRestrictions: <ViewProps extends { viewName: string }>(
    BaseComponent: React.ComponentType<ViewProps>,
) => React.FunctionComponent<ViewProps> = (BaseComponent) => (props) => {
    /*
    No current restrictions.
    If you want to 'depreciate' an entity type, you can do it like below:
    (getHideEntityViewsSelector would be util/applicationConfig/index.tsx, and read from the AppConfig configuration.)

    const viewConfig = useViewConfig();
    const [viewName] = getViewIndexAndAdditionalConfigFields(props.viewName, viewConfig, 'ALWAYS_LINKEDENTITY');
    const hideEntityViews = useSelector(getHideEntityViewsSelector);
    const resource = viewConfig.views[viewName].entity;
    if (hideEntityViews && (resource === 'EntityView' || resource === 'EntityViewItem')) {
        return (
            <RestrictedDepreciatedPanel
                text={
                    <>
                        {resource}
                        <span> editing is DEPRECIATED. Please edit view-definitions </span>
                        <LinkButton to="/view-definition-editor">HERE</LinkButton>{' '}
                    </>
                }
            />
        );
    }
    */
    return <BaseComponent {...props} />;
};

export default withRestrictions;
