import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

const useLink = (url: string) => {
    const dispatch = useDispatch();
    const linkCallback = useCallback(() => {
        dispatch(pushAction(url));
    }, [url, dispatch]);
    return linkCallback;
};

export default useLink;
