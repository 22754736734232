import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { FieldTitle } from 'fieldFactory/input/components/aor/FieldTitle';
import { FormHelperText } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import useWarningOrErrorUtils from 'fieldFactory/input/hooks/useWarningOrErrorUtils';

const styles = {
    block: {
        maxWidth: 250,
        marginTop: '.5em',
    },
};

const BooleanInput = ({ input, isRequired, label, source, elStyle, resource, meta, renderLabel, ariaInputProps }) => {
    const { textErrorClass, helperText, muiErrorProp } = useWarningOrErrorUtils(meta);
    const ariaErrormessageRef = React.useRef(uniqueId('toggleErrorMsg'));
    const InputProps: React.InputHTMLAttributes<HTMLInputElement> = {
        'aria-label': label,
        // don't set aria-invalid if warning - error only
        'aria-invalid': Boolean(meta.error),
        // use aria-describeddby for both warnings and errors
        'aria-describedby': muiErrorProp ? ariaErrormessageRef.current : undefined,
    };
    return (
        <div style={{ ...elStyle, ...styles.block, verticalAlign: 'center' }}>
            <FormControlLabel
                labelPlacement="start"
                control={
                    <Switch
                        checked={!!input.value}
                        onChange={input.onChange}
                        inputProps={{ ...ariaInputProps, ...InputProps }}
                    />
                }
                label={
                    renderLabel && (
                        <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
                    )
                }
            />
            {muiErrorProp && (
                <FormHelperText
                    classes={{ error: textErrorClass }}
                    aria-live="assertive"
                    error={muiErrorProp}
                    id={InputProps['aria-describedby']}
                >
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};

BooleanInput.defaultProps = {
    addField: true,
    options: {},
    ariaInputProps: {},
    renderLabel: true,
};

export default BooleanInput;
