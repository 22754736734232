import React from 'react';

const dgStyle: {
    header: {
        [selector: string]: React.CSSProperties;
    };
    cell: {
        [selector: string]: React.CSSProperties;
    };
    table: React.CSSProperties;
} = {
    header: {
        'th:first-child': {
            // left justify table headers
            paddingLeft: 0,
        },
        th: {
            paddingLeft: 0,
        },
    },
    cell: {
        // same as default here.
        td: {
            padding: '0 12px',
            whiteSpace: 'normal',
        },
        'td:first-child': {
            padding: '0 12px 0 16px',
            whiteSpace: 'normal',
        },
    },
    table: {
        tableLayout: 'auto',
        overflow: 'scroll',
    },
};
export default dgStyle;
