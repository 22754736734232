import React, { useContext, useMemo } from 'react';
import { ReactElement } from 'react';
import RGridInner from '../fields/display/RGrid';
import Hidden from './HiddenField';
import { FormFieldUnion } from '../../../fieldFactory/translation/fromFlowable/types/index';
import { formContext } from '../../../bpm/components/TaskDetail/TaskForm/FormContext';
import { printTemplatePrefix, printTemplatePdfPrefix } from 'fieldFactory/util/expressionConstants';
import stableStringify from 'fast-json-stable-stringify';

interface RGridWithVisProps {
    fields: ReactElement<{
        source: string;
    }>[];
    fieldsToHide?: string | null;
    printTemplatesToHide?: string | null;
    formDefinition: null | {
        id: string;
        name: string;
        key: string;
        version: number;
        fields: FormFieldUnion[];
        selectedOutcome?: string;
        outcomes: {
            name: string;
            configs?: {
                visibility?: string;
                editable?: string;
                validation?: string;
            };
        }[];
    };
}

const isNormalPrintTemplateExpressionField = (f: FormFieldUnion): boolean =>
    f.type === 'expression' && !!(f.value && typeof f.value === 'string' && f.value.startsWith(printTemplatePrefix));

const isPdfPrintTemplateExpressionField = (f: FormFieldUnion): boolean =>
    f.type === 'expression' && !!(f.value && typeof f.value === 'string' && f.value.startsWith(printTemplatePdfPrefix));

const emptyArr = [];
const RGridWithVis: React.FunctionComponent<RGridWithVisProps> = React.memo((props) => {
    const { fields = emptyArr, formDefinition } = props;
    const fieldsByPrintTemplateName = React.useMemo(() => {
        let res = {};
        (formDefinition?.fields || []).forEach((f) => {
            if (isNormalPrintTemplateExpressionField(f)) {
                res[f.value.slice(printTemplatePrefix.length)] = f;
            } else if (isPdfPrintTemplateExpressionField(f)) {
                res[f.value.slice(printTemplatePdfPrefix.length)] = f;
            }
        });
        return res;
    }, [formDefinition]);
    const fc = useContext(formContext);
    /*
        Below:
        Because regenerating fields is so expensive due to the rerenderings triggered,
        it pays off to do a thorough equality check so we only update so if 'hiddenFields' is structurally different.
        (otherwise we update for every onChange triggered in the form, even if 'hiddenFields' hasn't really changed)
    */
    const strHiddenFields = useMemo(() => stableStringify(fc.hiddenFields), [fc.hiddenFields]);
    const hiddenFields = useMemo(() => {
        return fc.hiddenFields;
    }, [strHiddenFields]); // eslint-disable-line
    const fieldElems = useMemo(() => {
        return (fields || []).map((f) => {
            const fieldIdBasedOnPrintTemplateName =
                f.props['printTemplateName'] &&
                formDefinition &&
                fieldsByPrintTemplateName[f.props['printTemplateName']].id;
            if (
                hiddenFields[f.props.source] ||
                hiddenFields[fieldIdBasedOnPrintTemplateName] ||
                f.props.hideMe === true
            ) {
                return (
                    <Hidden dontShowCol={true} {...f.props}>
                        {f}
                    </Hidden>
                );
            }
            return f;
        });
    }, [formDefinition, fields, hiddenFields, fieldsByPrintTemplateName]);
    return <RGridInner printRowMargin={'12px'} fields={fieldElems} />;
});

export default RGridWithVis;
