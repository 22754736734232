import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EditActionButton from './EditActionButton';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function DemoActions() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Start a Process with redirection handling" {...a11yProps(0)} />
                    <Tab label="Arbitrary POST request Demo" {...a11yProps(1)} />
                    <Tab label="Link Demo" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <EditActionButton
                    onSubmit={() => null}
                    disabled
                    initialValues={{
                        label: 'Create Case',
                        key: 'start-case',
                        action: {
                            type: 'EPIC/START_PROCESS',
                            payload: {
                                processDefinitionKey: 'MIISAddUsers',
                                values: {
                                    foo: 'foo',
                                },
                            },
                            handleRedirect: true,
                        },
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <EditActionButton
                    onSubmit={() => null}
                    disabled
                    initialValues={{
                        label: 'Create Case',
                        key: 'start-case',
                        action: {
                            type: 'CUSTOM_POST',
                            payload: {
                                url: 'api/bpm/process-instances',
                                body: {
                                    processDefinitionKey: 'my_case',
                                    values: {
                                        someId: '${record_id}', // eslint-disable-line
                                    },
                                },
                            },
                        },
                        displayRule: "record?.hasOpenCase == null && roles.?[#this == 'SUPER_ROLE'].size() != 0",
                        redirectOnSuccess: '/processes/${response_id}', // eslint-disable-line
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <EditActionButton
                    onSubmit={() => null}
                    disabled
                    initialValues={{
                        label: 'Go to possible-matches page',
                        key: 'go-to-pm-page',
                        url: '/SomeEntity/${record_id}/matches', // eslint-disable-line
                    }}
                />
            </TabPanel>
        </div>
    );
}
