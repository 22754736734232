import React from 'react';
import { useSelector } from 'react-redux';
import getV1InitialTheme from 'components/layouts/getMuiV1Theme';
import { getPrimaryColor, getSecondaryColor, getErrorColor } from 'components/layouts/getThemeColors';
import { RootState } from 'reducers/rootReducer';
import { themeOverrideContext } from '../ThemeOverrideProvider';
import {
    getFontFamilySelector,
    getOfflinePrimaryColorSelector,
    getOfflineSecondaryColorSelector,
    getPrimaryColorSelector,
    getSecondaryColorSelector,
} from 'util/applicationConfig';
import isOffline from 'util/isOffline';

// todo: allow injection of other colors
const useRootTheme = () => {
    const { overrides, darknessOverrides, darkMode, forceLabelShrink, fieldVariant } =
        React.useContext(themeOverrideContext);
    const adhocConfiguredPrimaryColor = useSelector(getPrimaryColorSelector);
    const adhocConfiguredSecondaryColor = useSelector(getSecondaryColorSelector);

    const adhocConfiguredOfflinePrimaryColor = useSelector(getOfflinePrimaryColorSelector);
    const adhocConfiguredOfflineSecondaryColor = useSelector(getOfflineSecondaryColorSelector);

    const fontFamily = useSelector(getFontFamilySelector);
    const printMode = useSelector((state: RootState) => state.printMode);
    const appColor = useSelector((state: RootState) => state.basicInfo && state.basicInfo.applicationColor);
    const theme = React.useMemo(() => {
        const configuredPrimaryColor = isOffline() ? adhocConfiguredOfflinePrimaryColor : adhocConfiguredPrimaryColor;
        const configuredSecondaryColor = isOffline()
            ? adhocConfiguredOfflineSecondaryColor
            : adhocConfiguredSecondaryColor;
        const theme = getV1InitialTheme(
            {
                primary: (overrides.primary as any) || getPrimaryColor(configuredPrimaryColor || appColor),
                secondary: (overrides.secondary as any) || getSecondaryColor(configuredSecondaryColor),
                error: (overrides.error as any) || getErrorColor(adhocConfiguredSecondaryColor || appColor),
                darknessOverrides,
                darkMode,
                forceLabelShrink,
                fieldVariant,
                fontFamily,
                printMode,
            },
            true, // DOM sideEffect: rebuild the stylesheet for React-Web-Tabs
        );
        // set the document background color
        return theme;
    }, [
        printMode,
        fontFamily,
        appColor,
        overrides,
        darknessOverrides,
        darkMode,
        forceLabelShrink,
        fieldVariant,
        adhocConfiguredPrimaryColor,
        adhocConfiguredSecondaryColor,
        adhocConfiguredOfflinePrimaryColor,
        adhocConfiguredOfflineSecondaryColor,
    ]);
    return theme;
};

export default useRootTheme;
