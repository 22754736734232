import React, { useMemo, useState, useContext, FunctionComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import { Entity } from 'reducers/ViewConfigType';
import useViewConfig from 'util/hooks/useViewConfig';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { TextField, Button } from '@material-ui/core';
import VirtualizedTable from 'layout-editor/build-layout/steps/components/PickViewDef/VirtualizedTable';
import { WhichSetOfViews } from './WhichSetOfViews';

export type EntityRowData = Entity & { viewTypesNeeded: string };

interface Filter {
    name?: string;
}

interface VirtualizedEntityConfigTableProps {
    filter?: Filter;
    setEntityConfigName: (name: string, data: EntityRowData) => void;
    whichSetOfViews?: WhichSetOfViews;
}

const VirtualizedEntityConfigTable: FunctionComponent<VirtualizedEntityConfigTableProps> = ({
    filter,
    setEntityConfigName,
    whichSetOfViews = 'ALL',
}) => {
    const viewConfig = useViewConfig();
    const setOfViews = useMemo(() => {
        return whichSetOfViews === 'ALL'
            ? ['LIST', 'EDIT', 'CREATE', 'MERGE', 'MATCH', 'SHOW']
            : whichSetOfViews === 'CREATE_SHOW_EDIT_LIST'
            ? ['LIST', 'EDIT', 'CREATE', 'SHOW']
            : ['MERGE', 'MATCH'];
    }, [whichSetOfViews]);
    const allRows = useMemo(() => {
        const viewTypes = setOfViews.sort();
        return Object.values(viewConfig.entities).map((e) => {
            const existingDefaultViews = Object.keys({ ...e.defaultViews, ...e.defaultViewDefs });
            let viewTypesNeeded = viewTypes.filter((vt) => !existingDefaultViews.includes(vt));
            if (e.name?.endsWith('Revision')) {
                // Revisions only should have SHOW or LIST views.
                viewTypesNeeded = viewTypesNeeded.filter((vt) => vt === 'LIST' || vt === 'SHOW');
            }
            return {
                ...e,
                viewTypesNeeded: viewTypesNeeded.join(', '),
            };
        });
    }, [viewConfig, setOfViews]);
    const rows = useMemo(() => {
        return allRows.filter((r) => {
            let keep = true;
            const isIn = (rowColData: string, filterData: string) =>
                rowColData.toLowerCase().includes(filterData.toLocaleLowerCase());
            if (filter.name) {
                keep = keep && isIn(r.name, filter.name);
            }
            return keep;
        });
    }, [allRows, filter]);
    return (
        <Paper style={{ height: 400, width: '100%' }}>
            <VirtualizedTable<EntityRowData>
                onRowClick={(e) => {
                    if (!e.rowData.viewTypesNeeded) {
                        return null;
                    }
                    setEntityConfigName(e.rowData.name, e.rowData);
                }}
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
                columns={[
                    {
                        width: 250,
                        label: 'Name',
                        dataKey: 'name',
                    },
                    {
                        width: 400,
                        label: 'Views Missing',
                        dataKey: 'viewTypesNeeded',
                    },
                ]}
            />
        </Paper>
    );
};

interface EntitySearchTableProps {
    whichSetOfViews?: WhichSetOfViews;
    setEntityConfig: (name: string, data: EntityRowData) => void;
}
const EntityConfigSearchTable: FunctionComponent<EntitySearchTableProps> = ({
    setEntityConfig,
    whichSetOfViews = 'ALL',
}) => {
    const [searchFilter, setSearchFilter] = useState<Filter>({});
    const methods = useForm<Filter>({
        defaultValues: {
            name: '',
        },
    });
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <div>
            <form onSubmit={methods.handleSubmit(setSearchFilter)}>
                <FormProvider {...methods}>
                    <div style={{ marginLeft: '.5em' }}>
                        <div style={{ display: 'inline-block', margin: '0px .5em' }}>
                            <Controller
                                InputLabelProps={getInputLabelProps({ shrink: true })}
                                label="Name"
                                as={TextField}
                                variant={fieldVariant}
                                margin="normal"
                                defaultValue=""
                                name="name"
                                control={methods.control}
                            />
                        </div>
                    </div>
                    <div style={{ margin: '1em' }}>
                        <Button color="primary" variant="contained" type="submit">
                            Search
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={(e) =>
                                methods.reset({
                                    name: '',
                                })
                            }
                        >
                            Clear
                        </Button>
                    </div>
                </FormProvider>
            </form>
            <VirtualizedEntityConfigTable
                whichSetOfViews={whichSetOfViews}
                filter={searchFilter}
                setEntityConfigName={setEntityConfig}
            />
        </div>
    );
};
export default EntityConfigSearchTable;
