import { createCustomAction } from 'typesafe-actions';
import { MARK_TASK_FOR_OFFLINE, UNMARK_TASK_FOR_OFFLINE, SET_OFFLINE_TASKS } from './constants';

export const markTaskForOffline = createCustomAction(MARK_TASK_FOR_OFFLINE, (type) => {
    return (taskId: string) => ({
        type,
        payload: {
            taskId,
        },
    });
});

export const unMarkTaskForOffline = createCustomAction(UNMARK_TASK_FOR_OFFLINE, (type) => {
    return (taskId: string) => ({
        type,
        payload: {
            taskId,
        },
    });
});

export const setOfflineTasks = createCustomAction(SET_OFFLINE_TASKS, (type) => {
    return (tasks: { [taskId: string]: true }) => ({
        type,
        payload: tasks,
    });
});
