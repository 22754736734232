import React from 'react';
import buildHeaders from 'sideEffect/buildHeaders';
import { maybeDownloadInlineContentDisposition } from './UploadWithSkipCalcsOption';
import DropzoneUpload from './DropzoneUpload';

const config = require('../../config.js');

const getLazyRequest = (resourceUrl: string) => (files: File[]) => async () => {
    const data = new FormData();
    data.append('file', files[0]);
    const request = new Request(`${config.API_URL}${resourceUrl}`, {
        method: 'POST',
        credentials: 'same-origin',
        body: data,
        headers: buildHeaders({ includeCredentials: true }),
    });

    const response = await fetch(request);
    await maybeDownloadInlineContentDisposition(response);
    return response;
};

interface UploadFileProps {
    title: string;
    dropMessage: string;
    resourceUrl: string;
}
export const UploadFile: React.FC<UploadFileProps> = ({ title, dropMessage, resourceUrl }) => {
    return (
        <DropzoneUpload
            title={title}
            getLazyRequest={getLazyRequest(resourceUrl)}
            ariaLabel={title}
            dropzoneText={`Drag ${dropMessage}`}
        />
    );
};
