import { createSelectorCreator, OutputParametricSelector, defaultMemoize } from 'reselect';
import deepEql from 'deep-eql';

const createDeepEqlSelector = <S, P, R, C>(formContextSelector: OutputParametricSelector<S, P, R, C>) => {
    const deepEqlSelectorCreator = createSelectorCreator(defaultMemoize, deepEql);
    const selector = deepEqlSelectorCreator<S, P, R, R>(
        formContextSelector,
        (state: ReturnType<typeof formContextSelector>) => {
            return state;
        },
    );
    return selector;
};
export default createDeepEqlSelector;
