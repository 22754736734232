import React, { useState } from 'react';
import { stringify } from 'query-string';
import GenericList from 'components/generics/genericList';
import useViewConfig from 'util/hooks/useViewConfig';

const useListLocation = (resource: string, filter?: {}) => {
    const [location, setLocation] = useState({
        pathname: `/${resource}`,
        search: `?${filter ? stringify({ filter: JSON.stringify(filter) }) : ''}`,
    });
    return [location, setLocation] as const;
};

const ListView: React.SFC<{
    viewName: string;
    noClick?: boolean;
    showActions?: boolean;
}> = ({ viewName, noClick, showActions = false }) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig && viewConfig.views[viewName] && viewConfig.views[viewName].entity;

    const [location, setLocation] = useListLocation(resource);
    return (
        <GenericList
            noClick={noClick}
            useCard={false}
            cancelRequestOnRouteChange={true}
            hasCreate={showActions}
            renderActions={showActions ? undefined : () => null}
            showFilters={true}
            updateUrlFromFilter={false}
            fakePush={setLocation}
            location={location}
            isPopover={false}
            title={showActions ? ' ' : null}
            resource={resource}
            viewName={viewName}
        />
    );
};
export default ListView;
