import * as React from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { RootState } from '../../reducers/rootReducer';
import DeferredSpinner from 'components/DeferredSpinner';
import PrivateGlobalAlerts from 'global-alerts/private/components/PrivateGlobalAlerts';
import ImpersonateBanner from 'impersonate/components/ImpersonateBanner';
import ConnectedIdleTimer from 'idle-timer/containers/ConnectedIdleTimer';
import CheckLastRequestRunner from 'reauth-interval-and-retries/check-last-request/components/CheckLastRequestRunner';
import OfflineAlert from 'reauth-interval-and-retries/check-last-request/components/OfflineAlert';
import DetectConnectivity from 'connectivity/components/DetectConnectivity';
import BannerSpacer from 'connectivity/components/BannerSpacer';
import DebugActionDispatcher from 'debug-action-dispatcher';
import AppFooter from 'footer';
import PreviewBanner from 'layout-editor/preview/PreviewBanner';
import useViewConfig from 'util/hooks/useViewConfig';
import useIsAnonymous from 'util/hooks/useIsAnonymous';
import useRootTheme from './hooks/useRootTheme';
import useSetupOnMount from './hooks/useAppSetupOnMount';
import AutoSavingBanner from 'bpm/components/TaskDetail/TaskForm/TaskForm/AutoSave/Banner';
import IEBannerComponent from './IEAlert';
import ViewConfigNeedsRefresh from './ViewConfigNeedsRefresh/ViewConfigNeedsRefresh';
import { CssBaseline } from '@material-ui/core';
import CurrentProfileBanner from 'auth/profiles/components/CurrentProfileBanner';
import SomeOfflineTasksBanner from 'offline_app/offline_stateful_tasks/back_online/components/AnyOfflineWorkBanner';
import isOffline from 'util/isOffline';
import GoBackOnlineBanner from 'offline_app/offline_stateful_tasks/GoBackOnlineBanner';
import isTheInstalledApp from 'util/isTheInstalledApp';
import SwitchToInstalledAppBanner from 'offline_app/offline_stateful_tasks/back_online/components/UseInstalledAppBanner';
import LoadOfflineTasksFromIDB from 'offline_app/offline_stateful_tasks/components/LoadOfflineTasksFromIDB';
import useIsTheInstalledApp from 'util/hooks/useIsTheInstalledApp';
import MinimalUIToolbarForStandaloneApp from './MinimalUIToolbarForStandaloneApp';
import Banner from 'branches/Banner/Banner';
import IssuesAlertBanner from 'temporary_registry/IssuesAlert';
import PrintSpinner from 'print/components/Spinner';

export const ThemeRoot: React.FC<{}> = (props) => {
    const theme = useRootTheme();
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </MuiThemeProvider>
    );
};

interface LayoutRootProps {}
const LayoutRoot: React.FunctionComponent<LayoutRootProps> = (props) => {
    useSetupOnMount();
    const anonUser = useIsAnonymous();
    const viewConfig = useViewConfig();
    const viewConfigIsLoading = useSelector((state: RootState) => state.viewConfigIsLoading);
    const isInstalledApp = useIsTheInstalledApp();
    const printMode = useSelector((state: RootState) => state.printMode);
    if ((!viewConfig || Object.keys(viewConfig).length === 0) && viewConfigIsLoading) {
        return <DeferredSpinner />;
    }
    return (
        <React.Fragment key={isInstalledApp ? 'app' : 'browser'}>
            {!anonUser && <CheckLastRequestRunner />}
            {!anonUser && !printMode && <OfflineAlert />}
            <LoadOfflineTasksFromIDB />
            <ConnectedIdleTimer>
                <div
                    style={{
                        display: 'table',
                        tableLayout: 'fixed',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'table-row',
                            /* height is dynamic, and will expand... */
                            height: '100%',
                            /* ...as content is added (won't scroll) */
                        }}
                    >
                        <MinimalUIToolbarForStandaloneApp />
                        {!printMode && (
                            <>
                                <Banner />
                                <IssuesAlertBanner />
                                <CurrentProfileBanner />
                                <ImpersonateBanner />
                                <DetectConnectivity />
                                <AutoSavingBanner />
                                <BannerSpacer />
                                <IEBannerComponent />
                                <PrivateGlobalAlerts />
                                <PreviewBanner />
                                {!isTheInstalledApp() ? (
                                    <SwitchToInstalledAppBanner />
                                ) : isOffline() ? (
                                    <GoBackOnlineBanner />
                                ) : (
                                    <SomeOfflineTasksBanner />
                                )}
                            </>
                        )}
                        <ViewConfigNeedsRefresh>{props.children}</ViewConfigNeedsRefresh>
                        <PrintSpinner />
                        <DebugActionDispatcher />
                    </div>
                    <div
                        style={{
                            display: 'table-row',
                        }}
                    >
                        <AppFooter />
                    </div>
                </div>
            </ConnectedIdleTimer>
        </React.Fragment>
    );
};
export default LayoutRoot;
