import {
    LOAD_VALUE_SET,
    LOAD_VALUE_SET_GROUP,
    LOAD_MULTIPLE_VALUE_SETS,
    MULTIPLE_VALUE_SETS_RECEIVED,
    VALUE_SET_CONCEPTS_RECEIVED,
    VALUE_SET_GROUP_RECEIVED,
} from './constants';
import { createCustomAction } from 'typesafe-actions';
import { ConceptInApp, ConceptFromApi, ValuesetFromApi, ValueSetFromMultipleVsEndpoint, ValuesetInApp } from './domain';
import { getTransformConcept } from './transform';

export const loadValueSet = createCustomAction(LOAD_VALUE_SET, (type) => {
    return (valueSetCode: string) => ({
        type,
        payload: valueSetCode,
    });
});

export const loadValueSetGroup = createCustomAction(LOAD_VALUE_SET_GROUP, (type) => {
    return (valueSetCode: string, group: string) => ({
        type,
        payload: {
            valueSetCode,
            group,
        },
    });
});

interface Vs {
    valueSet: string;
    group?: string;
}
export const loadValueSets = createCustomAction(LOAD_MULTIPLE_VALUE_SETS, (type) => {
    return (valuesets: Vs[]) => ({
        type,
        payload: {
            valuesets,
        },
    });
});

export const multipleValuesetsReceived = createCustomAction(MULTIPLE_VALUE_SETS_RECEIVED, (type) => {
    return (
        valueSetsFetched: { valueSet: string; group?: string }[],
        payload: {
            response: ValueSetFromMultipleVsEndpoint[];
            data: {
                entities: {
                    Concept: {
                        [id: string]: ConceptInApp;
                    };
                };
                result: string[];
            };
        },
        requestPayload: ReturnType<typeof loadValueSets>['payload'],
    ) => ({
        type,
        valueSetsFetched,
        payload,
        requestPayload,
    });
});

export const conceptsReceived = createCustomAction(VALUE_SET_CONCEPTS_RECEIVED, (type) => {
    return (valueSetId: string, valueSetCode: string, valueSet: ValuesetInApp, valueSetFromApi: ValuesetFromApi) => {
        const transformConcept = getTransformConcept(valueSetId, valueSetCode);
        return {
            type,
            payload: {
                valueSetCode,
                valueSet,
                data: {
                    entities: {
                        Concept: (() => {
                            const concepts: { [id: string]: ConceptInApp } = {};
                            valueSetFromApi.concepts.forEach((c) => {
                                concepts[c.id] = transformConcept(c);
                            });
                            return concepts;
                        })(),
                    },
                    result: valueSetFromApi.concepts.map((c) => c.id),
                },
            },
        };
    };
});

export const valuesetGroupReceived = createCustomAction(VALUE_SET_GROUP_RECEIVED, (type) => {
    return (
        response: {
            id: string;
            code: string;
            display: string;
            concepts: ConceptFromApi[];
            secure: boolean;
        },
        requestPayload: ReturnType<typeof loadValueSetGroup>['payload'],
    ) => {
        return {
            type,
            payload: {
                response,
                data: {
                    entities: {
                        Concept: Object.fromEntries<ConceptInApp>(
                            response.concepts.map((c) => [c.id, getTransformConcept(response.id, response.code)(c)]),
                        ),
                    },
                    result: response.concepts.map((c) => c.id),
                },
            },
            requestPayload,
        };
    };
});
