import { RECENT_TAB_OPEN } from './constants';

export default (resource: string, viewType: string, tabKey: string, id: string) => {
    return {
        type: RECENT_TAB_OPEN,
        payload: {
            resource,
            viewType,
            tabKey,
            id,
        },
    };
};
