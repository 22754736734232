import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import useKeyCachingEval from 'expressions/Provider/hooks/useKeyCachingEval';
import { _flowablePreprocessValues } from 'expressions/formValidation';
import React from 'react';

import { Expressions } from '../CachingEvaluator/Evaluator';
import useEntities from 'util/hooks/useEntities';
import { TaskForm } from '../../reducers/taskFormType';
type ExpressionResult<T> = {
    [k in keyof T]: T[k] extends [string] ? [any] : T[k] extends string[] ? any[] : ExpressionResult<T[k]>;
};
interface EvalExpressionInTaskContextProps<ExpressionsShape extends Expressions> {
    expressions: ExpressionsShape;
    additionalContext?: {};
    formDefinition: TaskForm;
    children: (expressions: ExpressionResult<ExpressionsShape>) => JSX.Element;
}

export default function EvalExpressionInTaskContext<ExpressionsShape extends Expressions>({
    formDefinition,
    expressions,
    children,
    additionalContext,
}: EvalExpressionInTaskContextProps<ExpressionsShape>) {
    const evaluator = useKeyCachingEval(expressions);

    const { fieldValues } = React.useContext(formContext);
    const entities = useEntities() as { Concept?: {} };
    const readyValues = React.useMemo(() => {
        return _flowablePreprocessValues(
            additionalContext ? { ...fieldValues, ...additionalContext } : fieldValues,
            formDefinition.fields,
            {
                Concept: entities.Concept,
            },
        );
    }, [formDefinition, entities.Concept, fieldValues, additionalContext]);
    const res = evaluator(readyValues);
    return children(res);
}
