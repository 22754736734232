import React, { useContext, useMemo } from 'react';
import { getLongestRefonePathInPath } from 'components/generics/utils/viewConfigUtils';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import {
    FieldSubscriberWithDelta,
    useFieldSubscribeWithDelta,
} from 'fieldFactory/display/experimental/FieldSubscriber';
import useViewConfig from 'util/hooks/useViewConfig';
import getParentFieldInChild from './getParentFieldInChild';
import { GetBackrefsToParentProps } from './GetBackrefsToParentProps';

interface WithBackrefsToParentProps extends GetBackrefsToParentProps {
    render: (
        args: null | {
            parentEntityName?: string;
            parentId?: string;
            parentFieldInChild?: string;
        },
    ) => JSX.Element | null;
}
const WithBackrefsToParent: React.FC<WithBackrefsToParentProps> = ({
    render,
    parentEntityName,
    parentId,
    source,
    linkedEntityFormat,
    endWith = 'Id',
}) => {
    const viewConfig = useViewConfig();
    const sourceUntilLastReachableValue = useMemo(() => {
        return getLongestRefonePathInPath(viewConfig, parentEntityName, source);
    }, [viewConfig, parentEntityName, source]);
    const efc = useContext(entityFormContext);
    const { parentFieldInChild, newParentEntityName } = getParentFieldInChild({
        parentEntityName,
        source,
        linkedEntityFormat,
        endWith,
        viewConfig,
    });
    return (
        <FieldSubscriberWithDelta
            id={parentId}
            source={sourceUntilLastReachableValue ? `${sourceUntilLastReachableValue}.id` : 'id'}
            resource={parentEntityName}
            delta={efc.dirtyValues}
            renderDisplayField={({ value }) => {
                if (value) {
                    return render({
                        parentEntityName: newParentEntityName,
                        parentId: value,
                        parentFieldInChild,
                    });
                }
                return render(null);
            }}
        />
    );
};

/**
 * Assumes that any ref-1s which start the path _ACTUALLY EXIST_
 *
 * In contrast to 'useInlineBackrefsToParent', which will get the nearest really existing record, regardless of if ref-1s on the way are filled in or not.
 */

export const useBackrefsToParent = ({
    parentEntityName,
    parentId,
    source,
    linkedEntityFormat,
    endWith = 'Id',
}: GetBackrefsToParentProps) => {
    const viewConfig = useViewConfig();
    const sourceUntilLastReachableValue = useMemo(() => {
        return getLongestRefonePathInPath(viewConfig, parentEntityName, source);
    }, [viewConfig, parentEntityName, source]);
    const efc = useContext(entityFormContext);
    const { parentFieldInChild, newParentEntityName } = getParentFieldInChild({
        parentEntityName,
        source,
        linkedEntityFormat,
        endWith,
        viewConfig,
    });
    const { value } = useFieldSubscribeWithDelta({
        id: parentId,
        source: sourceUntilLastReachableValue ? `${sourceUntilLastReachableValue}.id` : 'id',
        resource: parentEntityName,
        delta: efc.dirtyValues,
    });

    if (!value) {
        return null;
    }
    return {
        parentEntityName: newParentEntityName,
        parentId: value,
        parentFieldInChild,
    };
};
export default WithBackrefsToParent;
