import React from 'react';
import { Responsive } from 'react-grid-layout';
import ReactResizeDetector from 'react-resize-detector';
import { connect } from 'react-redux';
import { DashboardGrid } from 'dashboard2/dashboard-config/types';
import WidgetFactory from 'dashboard2/service/WidgetFactory';

import { ShowWidgetsContext } from 'dashboard2/widgets/widgetize';

interface GridProps {
    widgets: ReturnType<typeof WidgetFactory.createAllWidgets>;
    grid: DashboardGrid;
}

const dispatches = {};
type Dispatches = typeof dispatches;
interface GridComponentsProps extends GridProps, Dispatches {}

interface GridComponentState {
    showWidgets: boolean;
}
class GridComponent extends React.Component<GridComponentsProps, GridComponentState> {
    timer: NodeJS.Timeout;
    state = {
        showWidgets: false,
    };
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ showWidgets: true });
        }, 500);
    }
    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { layouts, breakpoints, cols, rowHeight, verticalCompact } = this.props.grid;
        const setVerticalCompact = verticalCompact === undefined ? true : verticalCompact;
        return (
            <div>
                <ShowWidgetsContext.Provider value={this.state.showWidgets}>
                    <ReactResizeDetector handleWidth>
                        {({ width }) => (
                            <Responsive
                                useCSSTransforms={false}
                                width={width || window.innerWidth}
                                rowHeight={rowHeight}
                                className="layout"
                                layouts={layouts}
                                breakpoints={breakpoints}
                                cols={cols}
                                margin={[10, 10]}
                                isDraggable={false}
                                isResizable={false}
                                verticalCompact={setVerticalCompact}
                            >
                                {this.props.widgets}
                            </Responsive>
                        )}
                    </ReactResizeDetector>
                </ShowWidgetsContext.Provider>
            </div>
        );
    }
}

const Grid: React.ComponentType<GridProps> = connect(null, dispatches)(GridComponent);
export default Grid;
