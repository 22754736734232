import React, { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getProcessDefinitions as getProcessDefinitionsAction } from 'bpm/processDefinitions/actions';
import { Helmet } from 'react-helmet';
import buildHeaders from 'sideEffect/buildHeaders';
import SsgAppBarMobile from '../../components/SsgAppBarMobile';
import DropzoneUpload from 'components/custom/DropzoneUpload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const config = require('../../config.js');

interface UploadDeploymentProps {
    replaceDefinitions: boolean;
    replaceForms: boolean;
    title: string;
}

const getLazyR =
    <T extends File>(files: T[], replaceDefinitions: boolean, replaceForms: boolean) =>
    () => {
        const data = new FormData();
        data.append('file', files[0]);
        const request = new Request(
            `${config.API_URL}bpm/deployment?replaceDefinitions=${replaceDefinitions}&replaceForms=${replaceForms}`,
            {
                method: 'POST',
                body: data,
                credentials: 'same-origin',
                headers: buildHeaders({
                    includeCredentials: true,
                }),
            },
        );
        return fetch(request);
    };

export const UploadDeployment: React.FunctionComponent<UploadDeploymentProps> = ({
    replaceDefinitions,
    replaceForms,
    title,
}) => {
    const dispatch = useDispatch();
    const lazyRequest = useMemo(
        () =>
            <T extends File>(files: T[]) => {
                return () => getLazyR(files, replaceDefinitions, replaceForms)();
            },
        [replaceDefinitions, replaceForms],
    );
    return (
        <DropzoneUpload
            getLazyRequest={lazyRequest}
            title={title}
            dropzoneText="Drag Deployment File Here"
            ariaLabel={title}
            onSuccess={() => {
                dispatch(getProcessDefinitionsAction());
            }}
        />
    );
};
const UploadDeploymentsPage = () => {
    return (
        <Card>
            <Helmet>
                <title>Upload Processes</title>
            </Helmet>
            <SsgAppBarMobile title="Upload Deployments" />
            <UploadDeployment title="Replace Deployment" replaceDefinitions={true} replaceForms={true} />
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="advanced-content" id="advanced-header">
                    <Typography>Advanced</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <UploadDeployment
                            title="Upload New Deployment"
                            replaceDefinitions={false}
                            replaceForms={false}
                        />
                        <UploadDeployment
                            title="Replace Deployment, New Forms"
                            replaceDefinitions={true}
                            replaceForms={false}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default UploadDeploymentsPage;
