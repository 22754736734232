import { connect } from 'react-redux';
import { GetHandleTitleClickProps } from 'dashboard2/widgets/GetHandleTitleClickProps';
import { push as pushAction } from 'connected-react-router';
import { RootState } from 'reducers/rootReducer';

const getHandleTitleClickMapStateToProps = (state: RootState) => {
    return {
        currentUserId: state.viewConfig && state.viewConfig.user && state.viewConfig.user.id,
    };
};
const dispatches = {
    push: pushAction,
};
type Dispatches = typeof dispatches;

const GetHandleMyActiveTasksTitleClick: React.ComponentType<GetHandleTitleClickProps> = connect(
    getHandleTitleClickMapStateToProps,
    dispatches,
)((props: GetHandleTitleClickProps & ReturnType<typeof getHandleTitleClickMapStateToProps> & Dispatches) => {
    return props.render({
        handleTitleClick: () =>
            props.push(
                `tasks?filter=%7B"assignee.id"%3A"${props.currentUserId}"%2C"endTime__NOT_EMPTY"%3Afalse%7D&order=ASC&page=1&perPage=100&sort=startTime`,
            ),
    });
});

export default GetHandleMyActiveTasksTitleClick;
