import React from 'react';
import Moment from 'moment';

export const DateFormatContext = React.createContext('MM/dd/yyyy');

export const DateFormatBroadcast = DateFormatContext.Provider;

export const DateFormatSubscriber = DateFormatContext.Consumer;

export interface DateFormatProps {
    dateFormat: string;
    formatDate: (date: Date | string | number) => string;
}
export const withDateFormat = (BaseComponent) => (props) =>
    (
        <DateFormatSubscriber>
            {(dateFormat: string) => {
                const appliedProps: DateFormatProps = {
                    dateFormat,
                    formatDate: (date: string) => date && Moment(date).format(dateFormat),
                };
                return <BaseComponent {...props} {...appliedProps} />;
            }}
        </DateFormatSubscriber>
    );
