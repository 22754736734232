import { getPublicGlobalAlertsSuccess } from 'global-alerts/public/actions';
import { getPrivateGlobalAlertsSuccess } from 'global-alerts/private/actions';
import { getType } from 'typesafe-actions';
import { LOAD_VIEW_CONFIG_SUCCESS } from 'viewConfig/constants';
import { getPrintTemplateByNameSuccess, getPrintTemplatesByEntityConfIdSuccess } from 'printTemplate/actions';
import { getStartFormSuccess } from 'bpm/start-form/get-start-form/actions';
import { loginSuccess } from 'auth/actions';
import { getProcessInstanceSuccess } from 'bpm/processInstance/actions';
import { loadAllDashboardsSuccess } from 'dashboard2/dashboard-config/load-dashboards/actions';
import { setPrimaryDashSuccess } from 'dashboard2/dashboard-config/user-primary-dashboard/set-primary-dashboard/actions';
import { getProcessDefinitionsSuccess } from 'bpm/processDefinitions/actions';
import { getPotentialUsersSuccess } from 'bpm/potentialUsers/actions';
import { getTaskPotentialUsersSuccess } from 'bpm/taskPotentialUsers/actions';
import { createTaskSuccess } from 'bpm/createTask/actions';
import { impersonateUserSuccess } from 'impersonate/actions';
import { getTaskFormSuccess } from 'bpm/task-form/actions';
import { crudCreateSuccess } from 'sideEffect/crud/create/actions';
import { crudGetOneSuccess } from 'sideEffect/crud/getOne/actions';
import { crudGetListSuccess } from 'sideEffect/crud/getList/actions';
import { crudUpdateSuccess } from 'sideEffect/crud/update/actions';
import { crudDeleteSuccess } from 'sideEffect/crud/delete/actions';
import { RootAction } from 'actions/rootAction';
import { taskFormEventType } from 'bpm/actions/taskFormEvent';
import { checkLastRequestTimeSuccess } from './check-last-request/actions';

export const successActionMap = {
    [getType(checkLastRequestTimeSuccess)]: true,
    [getType(getPublicGlobalAlertsSuccess)]: true,
    [getType(getPrivateGlobalAlertsSuccess)]: true,
    [LOAD_VIEW_CONFIG_SUCCESS]: true,
    [getType(getPrintTemplateByNameSuccess)]: true,
    [getType(getPrintTemplatesByEntityConfIdSuccess)]: true,
    [getType(getStartFormSuccess)]: true,
    [getType(loginSuccess)]: true,
    [getType(getProcessInstanceSuccess)]: true,
    [getType(loadAllDashboardsSuccess)]: true,
    [getType(setPrimaryDashSuccess)]: true,
    [getType(getProcessDefinitionsSuccess)]: true,
    [getType(getPotentialUsersSuccess)]: true,
    [getType(getTaskPotentialUsersSuccess)]: true,
    [getType(createTaskSuccess)]: true,
    [getType(impersonateUserSuccess)]: true,
    [getType(getTaskFormSuccess)]: true,
    [getType(crudCreateSuccess)]: true,
    [getType(crudUpdateSuccess)]: true,
    [getType(crudGetListSuccess)]: true,
    [getType(crudGetOneSuccess)]: true,
    [getType(crudDeleteSuccess)]: true,
    [taskFormEventType.getSuccess]: true,
    [taskFormEventType.saveSuccess]: true,
    [taskFormEventType.submitSuccess]: true,
} as const;

export const isAjaxSuccessAction = (action: RootAction): boolean => {
    return Boolean(successActionMap[action.type]);
};
