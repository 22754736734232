import { GET_TASK, GET_TASK_SUCCESS, GET_TASK_FAILURE } from './constants';
import ITask from 'bpm/types/task';
import { createCustomAction } from 'typesafe-actions';

export const getTask = createCustomAction(GET_TASK, (type) => (taskId: string, claimIfUnassigned: boolean = false) => ({
    type,
    payload: taskId,
    claimIfUnassigned,
}));
export const getTaskSuccess = createCustomAction(GET_TASK_SUCCESS, (type) => (task: ITask) => ({
    type,
    payload: task,
}));
export const getTaskFailure = createCustomAction(GET_TASK_FAILURE, (type) => (error: Error) => ({
    type,
    payload: error,
}));
