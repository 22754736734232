import React, { useCallback } from 'react';

interface ClassToggleButton {
    children: React.ReactChild;
    className?: string;
    toggleClass: string;
    toggleThisClass: string;
}
const ClassToggleButton: React.FC<ClassToggleButton> = React.memo(
    ({ children, className, toggleClass, toggleThisClass }) => {
        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                e.currentTarget.classList.toggle(toggleThisClass);
                const expansionElems = document.getElementsByClassName(toggleClass);
                for (const elem of expansionElems) {
                    elem.classList.toggle(toggleClass + '--expanded');
                }
            },
            [toggleThisClass, toggleClass],
        );
        return (
            <button onClick={handleClick} className={className}>
                {children}
            </button>
        );
    },
);

export default ClassToggleButton;
