import ViewConfig, { FieldViewField, View } from '../../../../reducers/ViewConfigType';
import { fromNullable, some, none, option, Option } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import {
    isFieldViewField,
    getAdjustedFieldSource,
    isADirectRefOneField,
    isInlineManyViewField,
    getRelatedField,
    getRefEntityName,
    expandComponentFields,
} from '../../../../components/generics/utils/viewConfigUtils';
import { getAdhocFieldsForView } from '../EntityFormContext/util/getAllFieldsExpected';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { traverse, mapOption } from 'fp-ts/lib/Array';
import { identity } from 'fp-ts/lib/function';
import uniq from 'lodash/uniq';
import getFieldPathsAlongPath from './getAllPathsAlongPath';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import fieldValuesToObj from './fieldValuesToObj/fieldValuesToObj';

const getViewTupleFromView = (v: View) => {
    const fields: View['fields'] = Object.assign({}, v.fields, ...Object.values(v.tabs || {}).map((t) => t.fields));
    return [v, fields] as [typeof v, typeof fields];
};
type ViewTuple = ReturnType<typeof getViewTupleFromView>;

/*
    In a view we need some:
    1. basic flat info
        (id, entityVersion, (entityType?))* DONE
    2. fieldsRequired by the view DONE
    3. fields Required ad-hoc by fields (Address + FileUpload) DONE

    4. validation fields required, <- lookup HOOK ADDED
    5. visibility fields required, <- lookup HOOK ADDED
    6. concept availability fields required, <- lookup HOOK ADDED

    TODO:
    use getFormInitial and pass in expression fields above. DONE
    for validation we will:
        0. see what fields we need based on 'expressions'
            (viewConfig, pathsRequired) => [
                path to update on,
                specific data path that needs to be updated based on reference change, <- can memoize based on this
            ]
            (pathToUpdateOn, updatedDataPath) => lookupData (constant time),
            insert into nested object structure, before merge with existing

    // dirty fields that are Id changes/refone changes
    // look up [path, entityData, pathToExpandOnData]

    [allPathsUsed, fieldPathsToIdsThatChange, theData] => {
        map fieldPathsToIdsThatChange to [fieldPath, get([allPathsUsed, theData[valueOfId]])]

    }
    merge(
        base expansions,
        looked up expansions (overwrite old),
        overwrite specific dirty field paths that are specific pieces of data
    )

*/

const alwaysExpandOnBase = ['id', 'entityVersion', 'entityType', 'title', 'hasPossibleMatches'];

const getPathValuePairs = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    entityType: string,
    entityId: string,
    fieldPaths: string[],
    entities: {},
): [string, unknown][] => {
    const ret = mapOption(
        fieldPaths,
        (fieldPath): Option<[string, unknown]> =>
            tryCatch(() =>
                traverseGetData(viewConfig, fieldPath, { entityType, id: entityId }, entities, false).fold(none, (v) =>
                    some<[string, unknown]>([fieldPath, v]),
                ),
            ).fold((e) => {
                return none;
            }, identity),
    );
    return ret;
};

export const getDataWithExpansion =
    <ViewConfig extends { entities: ViewConfigEntities }>(representation: 'FLAT' | 'EXPANDED') =>
    (viewConfig: ViewConfig, entityType: string, entityId: string, fieldPaths: string[], entities: {}) =>
        fieldValuesToObj(
            representation,
            viewConfig,
            entityType,
        )(getPathValuePairs(viewConfig, entityType, entityId, fieldPaths, entities));

/*
    e.g. references to objects we don't want.
    Only the data we actually need.
    for now, map reference objects to
    .entityVersion
    .entityId
    .entityType
*/
const getViewO = (viewConfig: ViewConfig, viewName: string) => {
    return fromNullable(viewConfig)
        .map((vc) => vc.views[viewName])
        .chain(fromNullable);
};

export const mapFieldPathsToFieldsNecessary = (viewConfig: ViewConfig, viewName: string, fieldPaths: string[]) => {
    return getViewO(viewConfig, viewName)
        .map(getViewTupleFromView)
        .map(([view, fields]) => {
            return fieldPaths.flatMap((fp) => {
                const refOnesAlongPaths = getFieldPathsAlongPath(fp, 'EXCLUDE_ORIGINAL').flatMap((subPath) => {
                    try {
                        if (isADirectRefOneField(viewConfig, view.entity, subPath)) {
                            return [`${subPath}.id`, `${subPath}.entityVersion`, `${subPath}.entityType`];
                        }
                    } catch (e) {
                        return [];
                    }
                    return [];
                });
                // we have to construct the correct ids an entityVersions for the path
                try {
                    if (isADirectRefOneField(viewConfig, view.entity, fp)) {
                        return refOnesAlongPaths;
                    }
                } catch (e) {
                    return [fp, ...refOnesAlongPaths];
                }
                return [fp, ...refOnesAlongPaths];
            });
        })
        .getOrElse([]);
};
const getFormInitial = (
    viewConfig: ViewConfig,
    viewName: string,
    expressionFieldsRequired: string[],
    entities: {},
    entityId: string,
    representation: 'FLAT' | 'EXPANDED' = 'EXPANDED',
) => {
    const viewO = getViewO(viewConfig, viewName);
    const recordEntry = viewO.mapNullable((v) => entities[v.entity]).mapNullable((e) => e[entityId]);
    if (recordEntry.isNone() || viewO.isNone()) {
        return undefined;
    }

    const fields: [string, unknown][] = viewO
        .map((view) => getViewTupleFromView(view))
        .map((t) => {
            const [view, flds] = t;

            const fieldPaths = expandComponentFields(
                viewConfig,
                Object.entries(flds),
                viewConfig.views[viewName].entity,
                { rebaseExpressionsWithinFields: false },
            )
                .expandedFieldsByRow.flat()
                .map((t) => t[1])
                .flatMap((f) => {
                    if (isInlineManyViewField(f)) {
                        return [];
                    }
                    if (isFieldViewField(f)) {
                        return [getAdjustedFieldSource(viewConfig)(view)(f)];
                    }
                    return [];
                });
            const allPaths = [...fieldPaths, ...expressionFieldsRequired, ...alwaysExpandOnBase];
            return [view, mapFieldPathsToFieldsNecessary(viewConfig, viewName, allPaths)] as [
                typeof view,
                typeof fieldPaths,
            ];
        })
        .map(([view, fieldPaths]) => {
            return [view, uniq([...fieldPaths, ...getAdhocFieldsForView(view)])] as [typeof view, string[]];
        })
        .map(([view, fieldPaths]) => getPathValuePairs(viewConfig, view.entity, entityId, fieldPaths, entities))
        .getOrElse([]);

    const inlineManyValues = viewO
        .map(getViewTupleFromView)
        .chain((d) => traverse(option)(d, fromNullable) as Option<ViewTuple>)
        .map(([view, flds]) => {
            const fieldPaths = Object.values(flds)
                .filter((f) => f.widgetType === 'INLINE_MANY')
                .flatMap((f: FieldViewField) => {
                    const [pathUntilLast, last] = (() => {
                        const splitPath = f.field.split('.');
                        return [splitPath.slice(0, -1).join('.'), f.field.split('.').pop()];
                    })();
                    const maybeSearchEntity = traverseGetData(
                        viewConfig,
                        pathUntilLast,
                        { entityType: view.entity, id: entityId },
                        entities,
                        false,
                    );
                    if (maybeSearchEntity.isNone()) {
                        return [];
                    }
                    const searchEntity = maybeSearchEntity.value;
                    const searchForId = searchEntity?.['id'];
                    const searchEntityType = searchEntity?.['entityType'];
                    if (!searchForId || !searchEntityType) {
                        return [];
                    }

                    try {
                        const relatedField = getRelatedField(viewConfig, searchEntityType, last, 'TRAVERSE_PATH');
                        const refEntity = getRefEntityName(viewConfig, searchEntityType, last, 'TRAVERSE_PATH');
                        const foundEntities = Object.values(entities[refEntity] ?? {}).filter((e) => {
                            return e[relatedField] === searchForId;
                        });
                        return [[f.field, foundEntities.map(({ id }) => ({ id }))] as [string, { id: string }[]]];
                    } catch (e) {
                        // this is still unexpected configuration-wise, but lets not crash the page.
                        console.error(e);
                        return [];
                    }
                });
            return fieldPaths;
        })
        .getOrElse([]);
    return fieldValuesToObj(representation, viewConfig, viewO.value.entity)([...fields, ...inlineManyValues]);
};
export default getFormInitial;
