import * as React from 'react';
import shouldUpdate from 'recompose/shouldUpdate';
import zip from '../utilities/zip';
import ThreeColumnTable from './ThreeColumnTable';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorPage from 'remoteStatus/one/components/pages/BaseErrorPage';
import { Error } from '@material-ui/icons';

interface RenderProps {
    record1id: string | number;
    triColumnFields: React.ReactElement<{}>[][];
    handleSubmit: (submit: Function) => void;
    merge: (values: {}) => void;
}
interface ZipFieldsIntoRowsComponentProps {
    primaryRecordFields: React.ReactElement<{}>[];
    secondaryRecordFields: React.ReactElement<{}>[];
    mergeFields: React.ReactElement<{}>[];
    record1id: string | number;
    handleSubmit: (submit: Function) => void;
    merge: (values: {}) => void;
    render: (props: RenderProps) => React.ReactElement<RenderProps>;
    getMergedRecordError?: string;
}
const ZipFieldsIntoRowsComponent = ({
    primaryRecordFields,
    secondaryRecordFields,
    mergeFields,
    record1id,
    handleSubmit,
    render,
    merge,
    getMergedRecordError,
}: ZipFieldsIntoRowsComponentProps) => {
    if (getMergedRecordError) {
        return <ErrorPage Icon={Error} headingText="Error" subText={getMergedRecordError} />;
    }
    if (!primaryRecordFields || !secondaryRecordFields || !mergeFields) {
        return <LinearProgress />;
    }

    const triColumnFields = zip([primaryRecordFields, secondaryRecordFields, mergeFields]);
    return (
        <div>
            {render({
                record1id,
                triColumnFields,
                handleSubmit,
                merge,
            })}
        </div>
    );
};
/* Only update if the fields have changed. */
const ZipFieldsIntoRows = shouldUpdate(
    (props, nextProps) =>
        props.getMergedRecordError !== nextProps.getMergedRecordError ||
        props.secondaryRecordFields !== nextProps.secondaryRecordFields ||
        props.primaryRecordFields !== nextProps.primaryRecordFields ||
        props.mergeFields !== nextProps.mergeFields,
)(ZipFieldsIntoRowsComponent);

const ThreeColumnView = (props) => (
    <ZipFieldsIntoRows
        {...props}
        record1id={props.match.params.id}
        render={({ record1id, triColumnFields, handleSubmit, merge }) => (
            <ThreeColumnTable
                wrapInCard={props.wrapInCard}
                topTitle={props.topTitle}
                triColumnFields={triColumnFields}
                record1id={record1id}
                handleSubmit={handleSubmit}
                merge={merge}
                primaryRecordTitle={props.primaryRecordTitle}
                altRecordTitle={props.altRecordTitle}
                renderActions={props.renderActions}
            />
        )}
    />
);

export default ThreeColumnView;
