import { RootState } from 'reducers/rootReducer';
import { getUseSpelMode } from 'util/applicationConfig';

let basicInfo: RootState['basicInfo'];

export const getSpelMode = () => {
    if (!globalThis['window']) {
        // in webworker,
        return 'ts-spel';
    }
    if (!basicInfo) {
        try {
            basicInfo = JSON.parse((window as any).CASETIVITY_BASIC_INFO);
        } catch (e) {}
    }
    const mode = getUseSpelMode(basicInfo);

    const overrideSpelMode = sessionStorage.getItem('overrideSpelMode');
    return (overrideSpelMode ?? mode) as typeof mode;
};

if (globalThis['window']) {
    globalThis['window']['overrideSpelMode'] = (mode) => {
        sessionStorage.setItem('overrideSpelMode', mode);
    };
}
