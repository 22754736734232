import exportViewConfigSaga from 'sagas/exportViewConfigSaga';
import bpmSagas from 'bpm/sagas/index';
import { customPostSaga, customGetSaga, customPutSaga } from 'sagas/customRequestEvents';
import valueSetsSaga from 'sagas/valueSetsSaga';
import valueSetMultipleSaga from 'sagas/valueSetMultifetchSaga';
import valueSetGroupSaga from 'sagas/valueSetGroupSaga';
import mergeSaga from 'sagas/mergeSaga';
import notMatchSaga from 'sagas/notMatchSaga';
import expressionTesterSaga from 'sagas/expressionTester';
import { RootState } from 'reducers/rootReducer';
import getEntityValidationsFromViewConfigSaga from 'sagas/getEntityValidationsFromViewConfig';
import getSearchValidationsFromViewConfigSaga from 'sagas/getSearchValidationsFromViewConfig';
import getEntityVisibilityExpsFromViewConfigSaga from 'sagas/getEntityVisibilityExpsFromViewConfig';
import getEntityEditabilityExpsFromViewConfigSaga from 'sagas/getEntityEditabilityExpsFromViewConfig';
import clearValueSetTableCacheOnPathChange from 'sagas/clearValuesetTableCacheOnPathChange';
import basicAppInfoSaga from 'sagas/basicAppInfoSaga';
import { all, fork, select, cancel, take } from 'redux-saga/effects';
import { TOGGLE_DEBUG_MODE } from 'actions/constants';
import { Task } from 'redux-saga';
import getViewValidationsFromViewConfig from 'sagas/getViewValidationsFromViewConfigs';

const customSagas = [
    exportViewConfigSaga,
    valueSetGroupSaga,
    valueSetsSaga,
    valueSetMultipleSaga,
    customPostSaga,
    customGetSaga,
    customPutSaga,
    mergeSaga,
    notMatchSaga,
    basicAppInfoSaga,
    getEntityValidationsFromViewConfigSaga,
    getViewValidationsFromViewConfig,
    getSearchValidationsFromViewConfigSaga,
    getEntityEditabilityExpsFromViewConfigSaga,
    expressionTesterSaga,
    getEntityVisibilityExpsFromViewConfigSaga,
    clearValueSetTableCacheOnPathChange,
    ...bpmSagas,
];

const toggleableSaga = (sagasToToggleOffInDebugMode: (() => IterableIterator<any>)[]) =>
    function* toggleSaga() {
        let tasks: null | Task[] = null;
        while (true) {
            const runSagas = yield select((state: RootState) => !state.debugMode);
            if (runSagas && !tasks) {
                const at = sagasToToggleOffInDebugMode.map(fork);
                tasks = yield all(at);
            } else {
                if (tasks) {
                    for (let i = 0; i < tasks.length; i++) {
                        yield cancel(tasks[i]);
                    }
                    tasks = null;
                }
            }
            yield take(TOGGLE_DEBUG_MODE);
        }
    };

export default function* rootSaga() {
    yield all([toggleableSaga([...customSagas])].map(fork));
}
