import React from 'react';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import renderNothing from 'recompose/renderNothing';
import {
    FormControl,
    InputLabel,
    FormHelperText,
    Theme,
    createStyles,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';
import CasetivitySelect from 'components/CasetivitySelect';
import { useIntl } from 'react-intl';
import { evaluateFormattedText, useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import formatError from 'fieldFactory/util/formatError';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';

const hideIfNoInput = branch((props) => !props.input, renderNothing);

const styles = (theme: Theme) => createStyles({});

interface ChoicesSelectProps {
    choices: { name: string }[];
    meta: WrappedFieldMetaProps;
    input: WrappedFieldInputProps;
    label: string;
    required?: boolean;
    disabled?: boolean;
    renderLabel?: boolean;
}
interface ChoicesSelectComponentProps extends ChoicesSelectProps, WithStyles<typeof styles> {}
const ChoicesSelectComponent: React.SFC<ChoicesSelectComponentProps> = ({
    classes,
    choices,
    meta: { touched, error },
    label,
    input,
    disabled,
    required,
    renderLabel = true,
}) => {
    const inputLabelRef = React.useRef(uniqueId('inputLabel'));
    const ariaErrormessageRef = React.useRef(uniqueId('toggleErrorMsg'));
    const SelectProps: React.InputHTMLAttributes<HTMLDivElement> = {
        'aria-invalid': Boolean(error),
        'aria-describedby': error ? `${inputLabelRef.current} ${ariaErrormessageRef.current}` : inputLabelRef.current,
    };
    const intl = useIntl();
    const mappingToIntName = React.useMemo(() => {
        return Object.fromEntries(choices.map(({ name }) => [name, evaluateFormattedText(intl, name)]));
    }, [choices, intl]);

    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();

    const { forceLabelShrink } = React.useContext(themeOverrideContext);
    return (
        <FormControl required={required} error={touched && error} style={{ width: '100%' }}>
            {renderLabel && (
                <InputLabel shrink={forceLabelShrink} id={inputLabelRef.current}>
                    {label}
                </InputLabel>
            )}
            <CasetivitySelect
                label={label}
                value={(input.value && input.value.name) || ''}
                SelectDisplayProps={SelectProps}
                disabled={disabled}
                onBlur={() => input.onBlur(undefined)}
                onChange={(event) => {
                    input.onBlur(choices.find(({ name }) => name === event.target.value));
                }}
            >
                {({ OptionComponent }) => {
                    if (choices.length === 0) {
                        return [
                            <OptionComponent value="" key="none-default">
                                None
                            </OptionComponent>,
                        ];
                    }
                    return choices.map((object) => (
                        <OptionComponent key={object.name} value={object.name}>
                            {mappingToIntName[object.name] ?? object.name}
                        </OptionComponent>
                    ));
                }}
            </CasetivitySelect>
            {touched && error && (
                <FormHelperText aria-live="assertive" id={SelectProps['aria-describedby']}>
                    {translate({ id: 'validate.error' })}: {evaluateFormattedMessage(formatError(error))}
                </FormHelperText>
            )}
        </FormControl>
    );
};

const ChoicesSelect = compose(withStyles(styles), hideIfNoInput)(ChoicesSelectComponent);
export default ChoicesSelect;
