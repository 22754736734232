import { getView, getFieldsFromView, isRefOneField, isFieldViewField } from '../utils/viewConfigUtils/index';
import ViewConfig from '../../../reducers/ViewConfigType';
import traversePath from '../utils/viewConfigUtils/traversePath';

const getFields = (
    viewConfig: ViewConfig,
    viewName: string,
    referencedFromEntity?: boolean,
    referencedByField?: string, // this is a BACKWARDS reference
) => {
    const view = getView(viewConfig, viewName);
    const fields = getFieldsFromView(view);
    if (
        !referencedFromEntity ||
        !referencedByField ||
        // below:
        // there is a many in the 'referencedByField' path, meaning pointing back to the original record will
        // lead to pointing to many different records,
        // not just one. So filtering on the reference back doesn't make sense - just show all columns.
        traversePath(viewConfig)((p) => (p.isManyReference ? 'stop' : 'continue'))(
            view.entity,
            referencedByField,
            false,
        )._t !== 'completed'
    ) {
        // show all fields if we don't have to hide any columns
        return fields;
    }
    return fields.filter((field) => {
        if (!isFieldViewField(field)) {
            return false; // don't show expression fields for now.
        }
        const [fieldDirectlink] = field.field.split('.');
        const isAReference = isRefOneField(viewConfig, view.entity, fieldDirectlink);
        if (!isAReference) {
            // we are not a reference - allow field
            return true;
        }

        // change e.g. 'partner.investigation.id' to 'partner.investigation'
        const referencedByFieldBackPath = referencedByField.split('.').slice(0, -1).join('.') || referencedByField;

        // split field.field by ., join to length of dots in referencedByFieldBackPath, and check equality.
        const depth = referencedByFieldBackPath.split('.').length;
        const fieldToRefDepth = field.field.split('.').slice(0, depth).join('.');
        if (fieldToRefDepth !== referencedByFieldBackPath) {
            // pointing to a different record - allow field.
            return true;
        }
        return false;
    });
};
export default getFields;
