import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, CircularProgress, TextField, useTheme, FormHelperText } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { ajax, AjaxError } from 'rxjs/ajax';
import Check from '@material-ui/icons/Check';
import { getOptions, getUrl } from 'sideEffect/services';
import ReactAuthCodeInput from 'fieldFactory/input/components/AuthCodeInput/ReactAuthCodeInput';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { login } from 'auth/actions';
import { useIntl } from 'react-intl';

export interface RegistrationConfirmationElements {
    SubmitElement: JSX.Element;
    ErrorMessageElement: JSX.Element;
    ConfirmPasswordElement: JSX.Element;
    PasswordElement: JSX.Element;
    LoginInputElement: JSX.Element;
    EmailLoginInputElement: JSX.Element;
    CodeElement: JSX.Element;
    StatusIndicatorElement: JSX.Element;
}

export interface RegistrationConfirmationFormProps {
    login?: string;
    renderForm: (props: RegistrationConfirmationElements) => JSX.Element;
}

type Inputs = {
    login: string;
    key: string;
    password: string;
    confirmPassword: string;
};

type PostData = {
    login: string;
    newPassword: string;
    key: string;
};
type SubmissionState =
    | {
          type: 'initial';
      }
    | {
          type: 'pending';
          data: PostData;
      }
    | {
          type: 'ajax-error';
          error?: AjaxError;
      }
    | {
          type: 'success';
      };
const useSubmit = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState<SubmissionState>({ type: 'initial' });
    React.useEffect(() => {
        if (state.type === 'pending') {
            const $ajax = ajax(
                getOptions(getUrl('api/account/forgot-password/finish'), 'POST', {
                    ...state.data,
                }),
            );

            const subscription = $ajax.subscribe(
                (res) => {
                    setState({ type: 'success' });
                    setTimeout(() => {
                        dispatch(
                            login(
                                {
                                    username: state.data.login,
                                    password: state.data.newPassword,
                                },
                                () => {
                                    // on error
                                    dispatch(push('/login'));
                                    return false;
                                },
                            ),
                        );
                    }, 200);
                },
                (error: AjaxError) => {
                    setState({ type: 'ajax-error', error });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [state, dispatch]);
    const submitData = React.useCallback(
        (data: PostData) => {
            setState({ type: 'pending', data });
        },
        [setState],
    );
    return [state, submitData] as [typeof state, typeof submitData];
};

const RegistrationConfirmationForm: React.FC<RegistrationConfirmationFormProps> = ({ renderForm, login = '' }) => {
    const { control, handleSubmit, watch, errors } = useForm<Inputs>();
    const [state, submitData] = useSubmit();
    const onSubmit = useCallback(
        (data: Inputs) => {
            submitData({
                login: data.login,
                key: data.key,
                newPassword: data.password,
            });
        },
        [submitData],
    );
    const passwordPresent = watch('password');
    const { fieldVariant, getInputLabelProps } = React.useContext(themeOverrideContext);
    const disabled = state.type === 'pending';
    const theme = useTheme();
    const allowedCharacters = React.useMemo(() => {
        return /^[0-9]/;
    }, []);
    const intl = useIntl();
    const CodeElement = (
        <Controller
            name="key"
            control={control}
            rules={{
                required: 'Code is required',
                validate: {
                    filled: (value) => {
                        return value && value.length === 6;
                    },
                },
            }}
            render={({ onChange, value }) => (
                <ReactAuthCodeInput
                    internalLabelPrefix={intl.formatMessage({ id: 'auth.code.authcode' })}
                    characters={6}
                    containerStyle={{
                        padding: '16px',
                    }}
                    inputStyle={{
                        backgroundColor: '#f5f5f5',
                        width: '1.4ch',
                        marginTop: '3px',
                        padding: '6px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        borderRadius: '8px',
                        fontSize: '28px',
                        textAlign: 'center',
                        marginRight: '4px',
                        border: '1px solid grey',
                        textTransform: 'uppercase',
                    }}
                    allowedCharacters={allowedCharacters}
                    onChange={(value) => {
                        onChange(value);
                    }}
                    RequiredErrorMessage={errors.key ? <FormHelperText error>Code is Required</FormHelperText> : null}
                />
            )}
        />
    );
    const LoginInputElement = (
        <Controller
            disabled={disabled}
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label="Login"
            name="login"
            fullWidth
            control={control}
            defaultValue={login}
            variant={fieldVariant}
            as={TextField}
            rules={{ required: 'Email is Required' }}
            error={Boolean(errors.login)}
            helperText={errors.login?.message}
        />
    );
    const EmailLoginInputElement = (
        <Controller
            disabled={disabled}
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label="Email"
            name="login"
            fullWidth
            control={control}
            defaultValue={login}
            variant={fieldVariant}
            as={TextField}
            rules={{ required: 'Email is Required' }}
            error={Boolean(errors.login)}
            helperText={errors.login?.message}
        />
    );
    const PasswordElement = (
        <Controller
            disabled={disabled}
            InputLabelProps={getInputLabelProps({ shrink: true })}
            label="Create Password"
            type="password"
            name="password"
            fullWidth
            control={control}
            variant={fieldVariant}
            as={TextField}
            rules={{ required: 'Password is Required' }}
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
        />
    );
    const ConfirmPasswordElement = (
        <span style={passwordPresent ? undefined : { display: 'none' }}>
            <br />
            <Controller
                disabled={disabled}
                InputLabelProps={getInputLabelProps({ shrink: true })}
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                fullWidth
                control={control}
                variant={fieldVariant}
                as={TextField}
                rules={{
                    required: 'Required',
                    validate: {
                        isPassword: (value) => value === passwordPresent,
                    },
                }}
                error={Boolean(errors.confirmPassword)}
                helperText={
                    errors.confirmPassword?.type === 'isPassword'
                        ? 'Passwords must match'
                        : errors.confirmPassword?.message
                }
            />
        </span>
    );
    const ErrorMessageElement =
        state.type === 'ajax-error' && state.error?.response ? (
            <div>
                {Object.values(state.error.response).map((entry, i) => {
                    return (
                        <p style={{ color: theme.palette.error.main }} key={i}>
                            {entry}
                        </p>
                    );
                })}
            </div>
        ) : null;

    const SubmitElement = (
        <Button disabled={disabled} variant="contained" color="primary" type="submit">
            Submit
        </Button>
    );

    const StatusIndicatorElement =
        state.type === 'pending' ? (
            <span>
                &nbsp;&nbsp;
                <CircularProgress size={25} thickness={2} />
            </span>
        ) : state.type === 'success' ? (
            <Check />
        ) : null;
    return (
        <form style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            {renderForm({
                SubmitElement,
                EmailLoginInputElement,
                ErrorMessageElement,
                ConfirmPasswordElement,
                PasswordElement,
                LoginInputElement,
                CodeElement,
                StatusIndicatorElement,
            })}
        </form>
    );
};
export default RegistrationConfirmationForm;
