import React, { useMemo, useState } from 'react';
import { makeAdhocList } from 'mui-rest-list';
import { map } from 'rxjs/operators';
import { Button, TableCell, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';

type ModelerFormsSearch = {
    size: number;
    start: number;
    total: number;
    data: {
        createdBy: string;
        description: string;
        id: string;
        key: string;
        lastUpdated: Date;
        lastUpdatedBy: string;
        latestVersion: boolean;
        name: string;
        version: number;
    }[];
};
const { AdhocList, AdhocListColumn } = makeAdhocList<ModelerFormsSearch['data'][0]>();

const getGetDataObservable =
    (url: string, filterText?: string, sort = 'modifiedDesc') =>
    (): Observable<ModelerFormsSearch['data']> => {
        return ajax({
            url: `${url}/modeler/app/rest/models?filter=forms&modelType=2&sort=${sort}${
                filterText ? '&filterText=' + filterText : ''
            }`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            responseType: 'json',
        }).pipe(map((r) => r.response?.data));
    };
interface ModelerFormsListProps {
    url: string;
    sort?: string;
}

export const ModelerFormsList: React.FC<ModelerFormsListProps> = ({ url, sort = 'modifiedDesc' }) => {
    const [searchText, setSearchText] = useState('');

    const getDataObservable = useMemo(() => getGetDataObservable(url, searchText, sort), [url, searchText, sort]);
    const { fieldVariant, getInputLabelProps } = React.useContext(themeOverrideContext);
    return (
        <div>
            <Typography id="formstitle" variant="h4">
                Forms
            </Typography>
            <div style={{ paddingTop: '1em' }}>
                <DebouncedField
                    label="Search"
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({})}
                    onChange={(e) => setSearchText(e.target.value)}
                    onBlur={(e) => setSearchText(e.target.value)}
                    value={searchText}
                />
            </div>
            <AdhocList
                key={searchText}
                type="unpaginated"
                titleOptions={{
                    type: 'aria-labelled-by',
                    id: 'formstitle',
                }}
                hasRefresh={false}
                getDataObservable={getDataObservable}
                tableCaption="Forms in modeler"
            >
                <AdhocListColumn title="name" fieldKey="name" />
                <AdhocListColumn title="description" fieldKey="description" />
                <AdhocListColumn
                    title="Link"
                    hideColTitle
                    fieldKey="id"
                    renderdata={(id: string) => {
                        return (
                            <TableCell>
                                <Button to={`/bpm-modeler/form/${id}`} component={Link}>
                                    Open
                                </Button>
                            </TableCell>
                        );
                    }}
                />
            </AdhocList>
        </div>
    );
};
