import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { addOfflineMeta, removeOfflineMeta } from './actions';
import { OfflineMeta } from './domain';
import merge from 'lodash/merge';
import { unsetCurrentlyWritingToOffline } from '../currentlyWritingToOfflineState/actions';
import { unMarkTaskForOffline } from '../offlineTasks/actions';
/**
 * The point of this is to act as a place to store our 'old initial values' when a merge is triggered after applying our offline work when we are back online, but the user leaves the popup open, and closes out.
 * In this state, the old entities are overwritten in our store, and so we never get that merge triggered again, when we try applying our offline work again.
 * The way we work around this is by storing the conflicting 'initial' values here, as long as the popup is open, and clearing it out when the merge is resolved.
 * Then when we apply our work, if there is something stored here, we apply it as a patch on to our entity store, and our form initial values,
 * causing the merge conflict to occur again.
 *
 */
const offlineMetaReducer = (state: OfflineMeta = {}, action: RootAction): OfflineMeta => {
    switch (action.type) {
        case getType(addOfflineMeta): {
            const { taskId, taskMeta } = action.payload;
            return {
                ...state,
                [taskId]: merge({}, state[taskId] ?? {}, taskMeta),
            };
        }
        case getType(unMarkTaskForOffline): {
            return Object.fromEntries(Object.entries(state).filter(([k]) => k !== action.payload.taskId));
        }
        case getType(unsetCurrentlyWritingToOffline):
        case getType(removeOfflineMeta): {
            return {};
        }
        default:
            return state;
    }
};
export default offlineMetaReducer;
