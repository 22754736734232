import React from 'react';
import { Toolbar, Card, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import useViewConfig from 'util/hooks/useViewConfig';
import MyReportsWidget from '../../dashboard2/widgets/components/MyReportsWidget';

export const MyReportsList = () => {
    const { Component } = MyReportsWidget;
    const listViewName = '_REPORT_LOG_LIST';
    const viewConfig = useViewConfig();
    const listViewConfig = viewConfig.views[listViewName]?.config && JSON.parse(viewConfig.views[listViewName].config);
    const { reportName, outputContentType, viewName, running, longRunning, startTime } = listViewConfig || {};
    return (
        <Component
            reportName={reportName}
            outputContentType={outputContentType}
            viewName={listViewName}
            running={running}
            longRunning={longRunning}
            startTime={startTime}
        />
    );
};
const MyReports = () => {
    const { Component } = MyReportsWidget;
    const listViewName = '_REPORT_LOG_LIST';
    const viewConfig = useViewConfig();
    const listViewConfig =
        (viewConfig.views[listViewName]?.config && JSON.parse(viewConfig.views[listViewName].config)) || {};
    const { reportName, outputContentType, viewName, running, longRunning, startTime } = Object.assign(
        { viewName: listViewName },
        listViewConfig,
    );
    return (
        <div style={{ height: '100%' }}>
            <Helmet>
                <title>My Reports</title>
            </Helmet>
            <Card>
                <Toolbar>
                    <Typography component="h1" variant="h5">
                        My Reports
                    </Typography>
                </Toolbar>

                <div style={{ marginLeft: '1.5em', marginTop: '1em' }}>
                    <MyReportsList />
                </div>
            </Card>
            <div style={{ height: '100%' }} />
        </div>
    );
};

export default MyReports;
