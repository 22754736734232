import React from 'react';
import BpmModelerAuthController from './Auth';
import { ModelerFormsList } from './FormsList';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Form from './Form';

interface FormRouteProps {
    url: string;
}
const FormRoute: React.FC<FormRouteProps> = (props) => {
    const match = useRouteMatch();
    return <Form id={match?.params?.id} url={props.url} />;
};

interface ModelerPortalProps {}
const ModelerPortal: React.FC<ModelerPortalProps> = (props) => {
    let match = useRouteMatch();
    return (
        <div>
            <BpmModelerAuthController>
                {(url) => (
                    <Switch>
                        <Route path={`${match.url}/form/:id`}>
                            <FormRoute url={url} />
                        </Route>
                        <Route path={match.url}>
                            <ModelerFormsList url={url} />
                        </Route>
                    </Switch>
                )}
            </BpmModelerAuthController>
        </div>
    );
};
export default ModelerPortal;
