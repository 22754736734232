import * as React from 'react';
import { SFC } from 'react';
import { Field } from 'redux-form';
import { InputLabel, FormControl, Button } from '@material-ui/core';
import LongTextInput from '../../../../../fieldFactory/input/components/LongTextInput';
import { TaskState } from '../../../../reducer/tasks/task';
import RenameableTaskName from './RenameableTaskName';
import { AlignItems } from './TaskAttributesWhenClosed';
import TaskAssignment from 'bpm/components/TaskDetail/TaskForm/TaskAssignment';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import CasetivitySelect from 'components/CasetivitySelect';
import DayUSTInput from 'fieldFactory/input/components/DayUSTInput/DayUSTInput';

export const PrioritySelect = ({ meta: { touched, error }, label, input, disabled }) => {
    const { fieldVariant, forceLabelShrink } = React.useContext(themeOverrideContext);
    return (
        <FormControl variant={fieldVariant} margin="normal" style={{ minWidth: 180 }} disabled={disabled}>
            <InputLabel shrink={forceLabelShrink} htmlFor="priority">
                {label}
            </InputLabel>
            <CasetivitySelect
                disabled={disabled}
                value={
                    input.value > 50 ? 60 : typeof input.value === 'number' && input.value < 50 ? 40 : input.value
                    // backend defaults to 0, so null = low priority.
                }
                onChange={(event) => input.onChange(event.target.value)}
                name={label}
                label={label}
                inputProps={{
                    id: 'priority',
                }}
            >
                {({ OptionComponent }) => [
                    <OptionComponent key={60} value={60}>
                        High
                    </OptionComponent>,
                    <OptionComponent key={50} value={50}>
                        Medium
                    </OptionComponent>,
                    <OptionComponent key={40} value={40}>
                        Low
                    </OptionComponent>,
                ]}
            </CasetivitySelect>
        </FormControl>
    );
};

const TaskPropertiesComponent: SFC<{
    onSubmit: Function;
    dueDateSelected: Date | string;
    description: string | null;
    name: string;
    handleSubmit: Function;
    priority: number;
    task: TaskState;
    taskId: string;
    isAdmin: boolean;
}> = ({ onSubmit, dueDateSelected, description, name, priority, handleSubmit, task, taskId, isAdmin }) => {
    const isEnded = task.endDate;
    const nameField = !isEnded ? (
        <Field
            name="name"
            options={{ fullWidth: true, margin: 'none', style: { width: '100%' } }}
            component={LongTextInput}
            label="Task Name"
            aria-label="Task Name"
        />
    ) : (
        <RenameableTaskName taskId={taskId} rootStyle={{ margin: 0 }} isAdmin={isAdmin} />
    );

    const dueDate = (
        <Field
            name="dueDate"
            options={{ margin: 'none', style: { maxWidth: 180 } }}
            component={DayUSTInput}
            label="Due Date"
            aria-label="Due Date"
            disabled={isEnded}
        />
    );
    const descriptionField = (
        <Field
            name="description"
            fullWidth={true}
            options={{ margin: 'normal', fullWidth: true }}
            component={LongTextInput as any}
            label="Description"
            aria-label="Description"
            disabled={isEnded}
        />
    );
    const outcome = (
        <Field
            name="outcome"
            options={{ margin: 'none' }}
            component={LongTextInput as any}
            label="Outcome"
            aria-label="Outcome"
            disabled={isEnded}
        />
    );
    const save = (
        <div
            style={{
                display:
                    !task ||
                    isEnded ||
                    (name === task.name &&
                        dueDateSelected === task.dueDate &&
                        description === task.description &&
                        priority === task.priority)
                        ? 'none'
                        : 'flex',
                width: '100%',
            }}
        >
            <Button
                variant="outlined"
                onClick={handleSubmit((values) => onSubmit(values))}
                disabled={
                    task &&
                    dueDateSelected === task.dueDate &&
                    name === task.name &&
                    description === task.description &&
                    name === task.name &&
                    priority === task.priority
                }
            >
                Save
            </Button>
        </div>
    );
    return (
        <div style={{ paddingLeft: 16 }}>
            <form autoComplete="off">
                <AlignItems>
                    {nameField}
                    {dueDate}
                    <React.Fragment>{outcome}</React.Fragment>
                    <TaskAssignment taskId={taskId} />
                </AlignItems>
                {/* priorityField */}
                {descriptionField}
                {save}
            </form>
        </div>
    );
};

export default TaskPropertiesComponent;
