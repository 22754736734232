import simpleTraverseAst from 'ts-spel-utils/simpleTraverseAst';
import { Ast } from 'ts-spel/lib/lib/Ast';

/**
 * Only root methods and functions
 */
const getRootMethodAndFunctionNames = (ast: Ast): Set<string> => {
    const result = new Set<string>();
    simpleTraverseAst((node, precededByIfCompound) => {
        if (precededByIfCompound?.length > 0) {
            return;
        }
        if (node.type === 'FunctionReference') {
            result.add(node.functionName);
        }
        if (node.type === 'MethodReference') {
            result.add(node.methodName);
        }
    })(ast);
    return result;
};

export default getRootMethodAndFunctionNames;
