import * as React from 'react';
import { SFC } from 'react';
import { Card } from '@material-ui/core';
import CommentList from '../Comments';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';

interface ProcessConfigConfig {
    displayNotes?: string | boolean;
}
const CommentPanel: SFC<{
    processId?: string;
    taskId?: string;
}> = ({ processId, taskId }) => {
    const caseDefinition = useSelector((state: RootState) => {
        return fromNullable(state.bpm.processInstances.byId[processId])
            .mapNullable((process) => process.businessKey)
            .mapNullable((bk) => state.bpm.processDefinitions.byId[bk])
            .getOrElse(null);
    });
    const displayExpression: string | boolean | undefined = useSelector((state: RootState) => {
        return fromNullable(state.viewConfig.processes)
            .mapNullable((pis) => caseDefinition && pis[caseDefinition.key])
            .mapNullable((pi) => pi.config)
            .chain(fromPredicate<string>(Boolean))
            .chain((configStr) => tryCatch(() => JSON.parse(configStr) as ProcessConfigConfig))
            .mapNullable((config) => config.displayNotes)
            .toUndefined();
    });
    const injectContext = React.useMemo(
        () => ({
            caseDefinition,
        }),
        [caseDefinition],
    );
    const displayNotes = useSingleKeyCachingExpression(displayExpression, injectContext, true);
    const printMode = useSelector((state: RootState) => state.printMode);
    const Comments = (
        <CommentList title={<h1>Notes</h1>} processId={processId} taskId={taskId} showFloatingButtons={false} />
    );
    if (!displayNotes) {
        return null;
    }
    if (printMode) {
        return Comments;
    }
    return (
        <Card style={{ marginBottom: '1em' }}>
            <div>{Comments}</div>
        </Card>
    );
};
export default CommentPanel;
