/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['53c9c158'] = validate10;
const schema11 = { type: 'object', properties: {}, dependencies: {}, required: [], $id: '53c9c158' };

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="53c9c158" */ let vErrors = null;
    let errors = 0;
    if (!(data && typeof data == 'object' && !Array.isArray(data))) {
        const err0 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
