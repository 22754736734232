import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { MyActiveTasks } from 'bpm/components/TaskDrawer/TaskDrawer';
import { IconButton } from '@material-ui/core';
import BallotIcon from '@material-ui/icons/Ballot';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function SwipeableTemporaryTaskDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState(open);
    };

    const list = (
        <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <MyActiveTasks open={state} />
        </div>
    );

    return (
        <React.Fragment>
            <IconButton color="inherit" onClick={toggleDrawer(true)}>
                <BallotIcon />
            </IconButton>
            <SwipeableDrawer anchor="right" open={state} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                {list}
            </SwipeableDrawer>
        </React.Fragment>
    );
}
