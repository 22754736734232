import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ThemeableToolbarTitle from 'components/SsgToolbarTitle';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getOfflineAppTitleHtmlSelector } from 'util/applicationConfig';
import { RootState } from 'reducers/rootReducer';

interface Props {
    children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
    }),
);

export default function ResponsiveDrawer(props: Props) {
    const classes = useStyles();
    const hasCustomOfflineAppTitleHtml = useSelector((state: RootState) =>
        Boolean(getOfflineAppTitleHtmlSelector(state)),
    );
    return (
        <>
            <div className={classes.root}>
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <span style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <ThemeableToolbarTitle />
                            {hasCustomOfflineAppTitleHtml ? null : (
                                <i style={{ marginLeft: '6px', marginTop: '5px' }}>
                                    <Typography>Offline</Typography>
                                </i>
                            )}
                        </span>
                    </Toolbar>
                </AppBar>
                <main id="maincontent" className={classes.content}>
                    {props.children}
                </main>
            </div>
        </>
    );
}
