const mapTableColumnValidationsToDataRows =
    (formData: {}) =>
    ([fid, validation]: [string, string]): [string, string][] => {
        if (fid.includes('_c_')) {
            const rootTableData = formData[fid.split('_c_')[0]] || [];
            if (fid.split('_c_').length === 2) {
                return rootTableData.map((_, i) => [
                    `${fid}_${i}`,
                    validation.replace(/[a-zA-Z0-9]+_c_[a-zA-Z0-9]+/g, `$&_${i}`),
                ]);
            }
            // TODO: we can make this work to arbitrary depth
            if (fid.split('_c_').length === 3) {
                const [rootTableId, nestedTableInRowId, fieldId] = fid.split('_c_');
                let validations: [string, string][] = [];
                rootTableData.forEach((rowData, i) => {
                    // we only add validations for rows that exist
                    let nestedTableData = rowData[nestedTableInRowId];
                    if (nestedTableData && Array.isArray(nestedTableData)) {
                        nestedTableData.forEach((row, j) => {
                            validations.push([
                                `${rootTableId}_c_${nestedTableInRowId}_${i}_c_${fieldId}_${j}`,
                                (() => {
                                    const tableFieldRx = /([a-zA-Z0-9]+(_c_[a-zA-Z0-9]+)+)/g;
                                    let newValidation: string = validation.replace(tableFieldRx, (match) => {
                                        let [rootTable, nestedTable, field] = match.split('_c_');
                                        return `${rootTable}_c_${nestedTable}_${i}${field ? `_c_${field}_${j}` : ''}`;
                                    });
                                    return newValidation;
                                })(),
                            ]);
                        });
                    }
                });
                return validations;
            }
        }
        return [[fid, validation]];
    };

export default mapTableColumnValidationsToDataRows;
