import { Button, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { AjaxError } from 'rxjs/ajax';
import { services } from 'sideEffect/services';
import { storageController } from 'storage';
import getBranchFromJwt from './getBranchFromJwt';
import Popup from 'components/Popup';
import Add from '@material-ui/icons/Add';
import { Formik } from 'formik';
import Clear from '@material-ui/icons/Clear';

type BranchExplorerError = {
    statusCode?: number;
    errorMessage: string;
};
type BranchExplorerState =
    | {
          _state: 'no_branch';
          prevError?: BranchExplorerError;
      }
    | {
          _state: 'working_branch';
          branchName: string;
          hasPendingConflicts?: boolean; // can split this into a substate if it needs more data.
      }
    | {
          _state: 'loading_branch';
          branchName: string;
          prevBranchState: BranchExplorerState;
      };

/**
 * This is for side-effecty stuff. Fetching branches will be done by a different hook.
 */
export const useBranchExplorer = () => {
    const [state, setState] = useState<BranchExplorerState>({ _state: 'no_branch' });

    const setBranch = useCallback((branchName: string | null) => {
        setState((state) => ({ _state: 'loading_branch', branchName, prevBranchState: state }));
        const $ajax = services.configuration_setBranch(branchName);
        const setBranchPromise = new Promise<void>((resolve, reject) => {
            $ajax.subscribe(
                (res) => {
                    if (res.status >= 200 && res.status < 300) {
                        // TODO: We should probably be able to know if it has pending conflicts at this point, right?
                        setState({ _state: 'working_branch', branchName, hasPendingConflicts: false });
                    } else {
                        setState((state) => {
                            if (state._state === 'loading_branch') {
                                console.error('failed to load branch ' + branchName);
                                if (state.prevBranchState._state === 'loading_branch') {
                                    return {
                                        ...state.prevBranchState,
                                        prevError: { errorMessage: 'status code error', statusCode: res.status },
                                    };
                                }
                                return state.prevBranchState;
                            }
                            // if it's not a loading branch, I guess return existing. This prob shouldn't happen though. Log an error?
                            return state;
                            // return ({ _state: 'no_branch', prevError: { errorMessage: 'status code error', statusCode: res.status } })
                        });
                    }
                    resolve();
                },
                (error: AjaxError) => {
                    setState((state) => {
                        if (state._state === 'loading_branch') {
                            if (state.prevBranchState._state === 'loading_branch') {
                                console.error(error);
                                return { ...state.prevBranchState, prevError: { errorMessage: error.message } };
                            }
                            return state.prevBranchState;
                        }
                        // This prob shouldn't happen though. Log an error?
                        return state;
                    });
                    reject();
                },
            );
        });
        return setBranchPromise;
    }, []);
    return [state, setBranch] as const;
};

export const usePossibleBranches = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [branches, setBranches] = useState<string[]>(null);
    const [error, setError] = useState<AjaxError>(null);

    useEffect(() => {
        if (!loading) {
            return;
        }
        const $ajax = services.configuration_getBranches();
        const subscription = $ajax.subscribe(
            (res) => {
                setError(null);
                setLoading(false);
                setBranches(res);
            },
            (error: AjaxError) => {
                setLoading(false);
                setError(error);
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [loading]);
    const fetch = useCallback(() => {
        setLoading(true);
    }, []);
    useEffect(() => {
        fetch();
    }, [fetch]);
    return {
        loading,
        branches,
        error,
        fetch,
    };
};

const useSquashBranch = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AjaxError>(null);
    const squashBranch = useCallback((message: string) => {
        setLoading(true);
        const $ajax = services.configuration_squashBranch(message);
        return $ajax
            .toPromise()
            .then((success) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err);
            });
    }, []);
    return {
        squashBranch,
        loading,
        error,
    };
};

export const useCurrentBranch = () => {
    const [currentBranch, setCurrentBranch] = useState<string | null>(getBranchFromJwt());
    useEffect(() => {
        const handleNewJwt = () => {
            const currentBranch = getBranchFromJwt();
            setCurrentBranch(currentBranch);
        };
        handleNewJwt();
        storageController.subscribeToSetToken(handleNewJwt);
        return () => storageController.unsubscribeToSetToken(handleNewJwt);
    }, []);
    return currentBranch;
};
export const SquashForm = (props: { onSuccess: () => void; Actions: JSX.Element }) => {
    const squash = useSquashBranch();
    return (
        <div style={{ margin: '1em' }}>
            <Formik<{ message: string }>
                initialValues={{
                    message: '',
                }}
                validateOnMount
                validate={(values) => {
                    if (!values.message) {
                        return { message: 'Required' };
                    }
                    return {};
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    squash.squashBranch(values.message).then(props.onSuccess);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <div
                            style={{
                                marginBottom: '1em',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <Typography variant="h6">Squash Branch</Typography>
                            </div>
                            <div>{props.Actions}</div>
                        </div>
                        <TextField
                            fullWidth
                            multiline
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            name="message"
                            label="Squash commit message"
                            error={touched.message && Boolean(errors.message)}
                            helperText={touched.message && errors.message}
                        />
                        <div style={{ marginTop: '1em' }}>
                            <Button
                                disabled={Object.keys(errors).length > 0}
                                size="small"
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Squash
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};
const BranchExplorer = (props: { onChangeBranch?: () => void }) => {
    const possibleBranches = usePossibleBranches();
    const [branchState, setBranch] = useBranchExplorer();
    const currentBranch = useCurrentBranch();
    return (
        <div>
            <Popup
                renderDialogContent={({ closeDialog }) => (
                    <div style={{ margin: '1em' }}>
                        {/* TODO simple text form here for branch name */}
                        <Formik<{ name: string }>
                            initialValues={{
                                name: '',
                            }}
                            validateOnMount
                            validate={(values) => {
                                if (!values.name) {
                                    return { name: 'Required' };
                                }
                                return {};
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                setBranch(values.name).then(() => {
                                    possibleBranches.fetch();
                                    closeDialog();
                                    props.onChangeBranch?.();
                                });
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <div
                                        style={{
                                            marginBottom: '1em',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>
                                            <Typography variant="h6">New Branch</Typography>
                                        </div>
                                        <div>
                                            <IconButton size="small" onClick={closeDialog}>
                                                <Clear />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                        name="name"
                                        label="Branch Name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <div style={{ marginTop: '1em' }}>
                                        <Button
                                            disabled={Object.keys(errors).length > 0}
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                        >
                                            Create Branch
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                )}
                renderToggler={({ openDialog }) => (
                    <Button onClick={openDialog()} variant="contained" color="primary" size="small" endIcon={<Add />}>
                        New Branch
                    </Button>
                )}
            />
            <List>
                {possibleBranches.branches?.map((branch) => {
                    return (
                        <ListItem
                            selected={branch === currentBranch}
                            onClick={() => {
                                setBranch(branch);
                                props.onChangeBranch?.();
                            }}
                            button
                            dense
                            key={branch}
                        >
                            <ListItemText>{branch}</ListItemText>
                        </ListItem>
                    );
                })}
                <ListItem
                    selected={!currentBranch}
                    dense
                    button
                    onClick={() => {
                        setBranch(null);
                        props.onChangeBranch?.();
                    }}
                >
                    <ListItemText>Main (default)</ListItemText>
                </ListItem>
            </List>
        </div>
    );
};
export default BranchExplorer;
