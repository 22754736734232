import { localsync } from 'localsync';
import { getStorageMode } from 'util/applicationConfig';
import { storeRegistry } from './storeRegistry';

export const syncedActionsController = localsync({ channel: 'action-sync-channel' })(
    (action) => action,
    (current, previous) => {
        storeRegistry['store'].dispatch(current);
    },
);

const bis = (window as any).CASETIVITY_BASIC_INFO as string;
if (bis) {
    const basicInfo = JSON.parse(bis);
    const storageMode = getStorageMode(basicInfo);
    if (storageMode !== 'sessionStorage') {
        syncedActionsController.start();
    }
}
