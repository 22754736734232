import React, { useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, InputLabel } from '@material-ui/core';
import { postFixInUrl } from 'clients/utils/translateFieldWithSearchTypeAppended';
import CasetivitySelect from 'components/CasetivitySelect';

type SearchType = keyof typeof postFixInUrl | '';
const SearchTypeField = (props) => {
    const { register, unregister, setValue, watch } = useFormContext<{ searchType: SearchType }>();
    useEffect(() => {
        register({ name: 'searchType' });
        return () => {
            unregister('searchType');
        };
    }, []); // eslint-disable-line
    const value = watch('searchType');
    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: any }>) => {
            setValue('searchType', event.target.value || '', {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [setValue],
    );
    return (
        <FormControl fullWidth>
            <InputLabel shrink={true} id="pick-searchType-label">
                Search Type
            </InputLabel>
            <CasetivitySelect
                label="Search Type"
                labelId="pick-searchType-label"
                id="pick-searchType"
                value={value}
                onChange={handleChange}
                onBlur={handleChange}
                fullWidth
                style={{ minWidth: 100 }}
            >
                {({ OptionComponent }) => [
                    <OptionComponent key="none-default" value="">
                        None
                    </OptionComponent>,
                    ...Object.keys(postFixInUrl).map((searchType) => {
                        return (
                            <OptionComponent key={searchType} value={searchType}>
                                {searchType}
                            </OptionComponent>
                        );
                    }),
                ]}
            </CasetivitySelect>
        </FormControl>
    );
};
export default SearchTypeField;
