import React, { useCallback, useContext, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RootState } from '../../src/reducers/rootReducer';
import { Dispatch, AnyAction } from 'redux';
import { ArgumentTypes } from '../../src/util/typeUtils';
import {
    WithStyles,
    createStyles,
    Theme,
    withStyles,
    IconButton,
    Tooltip,
    CardActions,
    Button,
} from '@material-ui/core';
import Print from '@material-ui/icons/Print';
import Check from '@material-ui/icons/CheckBox';
import { togglablePrintModeSelector } from 'util/applicationConfig';
import isEmbeddedViewContext from 'fieldFactory/display/components/MultiCard/embeddedViewContext';
import { getPrintController } from 'print/PrintController';
import { someDirtyFormContext } from 'formSaveNotifier/components/Provider';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        on: {
            color: palette.secondary.main,
            zIndex: 5,
        },
        off: {
            zIndex: 5,
        },
    });

export interface TogglePrintModeProps {}
const makeMapStateToProps = () => {
    // create any caches/selectors in this scope
    const mapStateToProps = (state: RootState, props: TogglePrintModeProps) => {
        return {
            togglablePrintMode: togglablePrintModeSelector(state),
            printMode: state.printMode,
        };
    };
    return mapStateToProps;
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: TogglePrintModeProps) => ({
    toggle: () => dispatch({ type: 'TOGGLE_PRINT_MODE' }),
});
type ActionCreatorProps = {
    [key in keyof ReturnType<typeof mapDispatchToProps>]: (
        ...args: ArgumentTypes<ReturnType<typeof mapDispatchToProps>[key]>
    ) => void;
};
interface TogglePrintModeComponentProps
    extends ReturnType<ReturnType<typeof makeMapStateToProps>>,
        ActionCreatorProps,
        WithStyles<typeof styles>,
        TogglePrintModeProps {}

const TogglePrintModeComponent: React.FunctionComponent<TogglePrintModeComponentProps> = (props) => {
    const { toggle, classes, printMode, togglablePrintMode } = props;
    const isDirty = React.useContext(someDirtyFormContext);
    const isEmbeddedView = useContext(isEmbeddedViewContext);
    const togglePrint = useCallback(() => {
        toggle();
        if (!printMode) {
            // turning on.
            getPrintController().startPrint();
        }
    }, [toggle, printMode]);

    const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);

    const handleClick = useCallback(() => {
        if (isDirty) {
            setUnsavedChangesDialogOpen(true);
        } else {
            togglePrint();
        }
    }, [isDirty, togglePrint]);
    if (!togglablePrintMode || isEmbeddedView) {
        return null;
    }
    const title = `Turn print-mode ${printMode ? 'off' : 'on'}`;
    return (
        <>
            {unsavedChangesDialogOpen && !printMode && (
                <Alert severity="warning">
                    <AlertTitle>You have unsaved changes on this page.</AlertTitle>
                    <div>Please save your work before printing.</div>
                    <CardActions>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                setUnsavedChangesDialogOpen(false);
                            }}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Alert>
            )}

            <span className="casetivity-hide-printing">
                <Tooltip title={title}>
                    <IconButton
                        aria-label={title}
                        className={printMode ? classes.on : classes.off}
                        onClick={handleClick}
                    >
                        <Print />
                        {printMode ? <Check /> : null}
                    </IconButton>
                </Tooltip>
            </span>
        </>
    );
};

const TogglePrintMode: React.SFC<TogglePrintModeProps> = compose(
    connect(makeMapStateToProps, mapDispatchToProps),
    withStyles(styles),
)(TogglePrintModeComponent);

export default TogglePrintMode;
