import { Typography } from '@material-ui/core';
import { getViewNameFromComponentField } from 'components/generics/utils/viewConfigUtils';
import React, { useMemo } from 'react';
import { ViewField } from 'reducers/ViewConfigType';

const ComponentPlaceholder = (props) => {
    const def = props['data-originaldefinition'];
    const viewName = useMemo(() => {
        try {
            const vf: ViewField = JSON.parse(def);
            const viewName = getViewNameFromComponentField(vf);
            return viewName;
        } catch (e) {
            console.error(e);
            return null;
        }
    }, [def]);
    return (
        <div>
            <Typography variant="subtitle1">
                <b>Component Placeholder</b>
            </Typography>
            <div>
                <b>id:</b>&nbsp;{props.fieldInstanceIdentifier}
            </div>
            <div>
                <b>view:</b>&nbsp;{viewName ?? <em>unknown</em>}
            </div>
        </div>
    );
};
export default ComponentPlaceholder;
