import { fromNullable } from 'fp-ts/lib/Option';
import { parse, stringify } from 'query-string';
import { RootState } from '../../reducers/rootReducer';
import moment from 'moment';

export const getProcDefFromProcInstId = (state: RootState, processId?: string | null) =>
    fromNullable(processId)
        .chain((pid) => fromNullable(state.bpm.processInstances.byId[pid]))
        .chain((p) => fromNullable(p.businessKey))
        .chain((businessKey) => fromNullable(state.bpm.processDefinitions.byId[businessKey]));

export const getForceDatagridNotReady = (qStr: string) => {
    return parse(qStr).delaySearch as any;
};
export const stripDelaySearchFromQueryString = (qStr: string) => {
    const { delaySearch, ...stripped } = parse(qStr);
    const res = `?${stringify(stripped)}`;
    return res;
};

export const getTodayISO = () => moment(new Date()).hour(0).minute(0).second(0).millisecond(0).toISOString();
