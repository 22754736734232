import React, { Suspense } from 'react';

const PopupCalendar = React.lazy(() => import('./PopupCalendar'));
const LazyPopupCalendar: React.FC<{
    processId?: string;
    taskId?: string;
    changeActionsStandard?: {
        date: (value: unknown) => void;
        duration: (value: unknown) => void;
        attendeesIds: (value: unknown) => void;
    };
    currentStart?: Date;
    currentDuration?: number;
    renderAttendeesField: () => JSX.Element | null;
    disabled?: boolean;
}> = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <PopupCalendar {...props} />
        </Suspense>
    );
};
export default LazyPopupCalendar;
