import moment from 'moment';

export const getTzOffset = () => new Date().getTimezoneOffset() / 60;
export const datify = (input: string | Date | null | undefined | number, mode: 'YYYYMMDD' | 'ISO' = 'YYYYMMDD') => {
    if (!input) {
        return null;
    }
    // get day saved
    if (mode === 'ISO') {
        const res = moment(input).format('YYYY-MM-DD');
        if (res === 'Invalid date') {
            console.error('invalid date', input);
            return null;
        }
        return res;
    }
    // get day if e.g. new Date(<YYYY-MM-DD>)
    if (typeof input === 'string' && input.length <= 10 && input.length >= 8) {
        const m = moment(input + 'T12:00:00Z');
        if (m.isValid()) {
            return m.toDate();
        }
        throw new Error(`Invalid date: ${input}`);
    }
    // copy date to not mutate the argument
    let date: Date = input instanceof Date ? new Date(+input) : new Date(input);
    if (isNaN(date as any)) {
        date = new Date((input as string).split('+')[0]);
        if (isNaN(date as any)) {
            throw new Error(`Invalid date: ${input}`);
        } else {
            console.log('date fixed.');
        }
    }
    if (getTzOffset() > 0) {
        date.setDate(date.getDate() + 1);
    }
    return date;
};
