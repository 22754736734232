import { AjaxError } from 'rxjs/ajax';

export class HttpError extends Error {
    status: number;
    _type: 'HTTP';
    constructor(message: string, status: number) {
        super(message);
        this._type = 'HTTP';
        this.message = message;
        this.status = status;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.stack = new Error().stack;
    }
}
export const isHttpError = (e: HttpError | Error): e is HttpError => (e as HttpError)._type === 'HTTP';
export const isAjaxError = (e: Error | AjaxError): e is AjaxError => e.name === 'AjaxError';
