import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { disableFormsaveNotifierDec, disableFormsaveNotifierInc } from './actions';

const formSaveNotifierDisabledCount = (prevState: number = 0, action: RootAction): number => {
    switch (action.type) {
        case getType(disableFormsaveNotifierDec): {
            return prevState - 1;
        }
        case getType(disableFormsaveNotifierInc): {
            return prevState + 1;
        }
        default:
            return prevState;
    }
};
export default formSaveNotifierDisabledCount;
