import * as React from 'react';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { RootState } from 'reducers/rootReducer';
import { TaskForm } from '../../../../../reducers/taskFormType';
import { fromNullable } from 'fp-ts/lib/Option';
import createGetOptionsSelector from 'bpm/form-context-utils/etc/getOptionsSelector';
import { initialAssigneeValuesSelector as taskAssignmentInitialValuesSelector } from '../../selectors';
import { isDisabled } from '../TaskForm/Form/Layout';
import { useHasOfflineWorkToApply } from 'offline_app/offline_stateful_tasks/back_online/components/OfflineWorkBanner';
interface InternalTaskFormProps {
    taskId: string;
    formDefinition: TaskForm;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}

const useTaskFormFields = (props: InternalTaskFormProps) => {
    const { formDefinition, taskId, relatedEntityResource, relatedEntityId } = props;
    const fieldFactory = React.useContext(FieldFactoryContext);
    const hasOfflineWorkToApply = useHasOfflineWorkToApply();
    const fields = React.useMemo(() => {
        const config = {
            dataSource: DataSource.FLOWABLE,
            mode: Mode.INPUT,
            validate: true,
            connected: true,
            options: {},
        };
        const preConfiguredFieldFactory = fieldFactory(config);
        const generateFields = preConfiguredFieldFactory(
            {
                replacePeriodsInFieldName: '_',
                resource: relatedEntityResource,
                record: relatedEntityResource &&
                    relatedEntityId && {
                        id: relatedEntityId,
                        entityType: relatedEntityResource,
                    },
                // we can add this here so it displays instantly,
                // but will pass a selector so it can subscribe to the
                // desired record (it will override these props).
                options: {
                    fullWidth: true,
                },
                overrideFieldValueIfDisabled: true,
                shouldFetchValueset: false,
            },
            () => {
                return (state: RootState, ownProps) => {
                    const currentUser = state.viewConfig && state.viewConfig.user && state.viewConfig.user.login;
                    const taskAssignee = taskAssignmentInitialValuesSelector(state, { taskId }).assignee;
                    return {
                        record: (state.admin.entities[relatedEntityResource] || {})[relatedEntityId],
                        disabled: state.undisableTaskForms
                            ? false
                            : hasOfflineWorkToApply ||
                              isDisabled(
                                  fromNullable(state.bpm.tasks.byId[taskId])
                                      .map((t) => t.endDate)
                                      .chain(fromNullable)
                                      .toNullable(),
                                  currentUser,
                                  taskAssignee,
                              ),
                        forceEditable: state.undisableTaskForms,
                        resource: relatedEntityResource,
                    };
                };
            },
            createGetOptionsSelector(formDefinition),
        );
        return formDefinition ? generateFields(formDefinition.fields) : [];
    }, [fieldFactory, hasOfflineWorkToApply, formDefinition, relatedEntityResource, relatedEntityId, taskId]);
    return fields;
};

export default useTaskFormFields;
