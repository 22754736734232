import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useViewWizardState from './hooks/useViewWizardState';
import useViewConfig from 'util/hooks/useViewConfig';
import { convert } from 'layout-editor/diff-viewsdefs-from-views/convertViewDefToView';

interface LoadLayoutProps {
    viewName: string;
}
const LoadLayout: FunctionComponent<LoadLayoutProps> = (props) => {
    const { action } = useViewWizardState();
    const history = useHistory();
    const viewConfig = useViewConfig();
    const viewData = (() => {
        if (viewConfig.viewDefs && viewConfig.viewDefs[props.viewName]) {
            const savedDefinition = JSON.parse(viewConfig.viewDefs[props.viewName].definition);
            return convert(savedDefinition, viewConfig);
        } else {
            return viewConfig.views[props.viewName] as any;
        }
    })();
    useEffect(() => {
        action({
            type: 'pickExisting',
            meta: {
                existingViewName: props.viewName,
            },
            viewData,
        });
        history.replace('/view-definition-editor/step1', {});
    }, []); // eslint-disable-line
    return null;
};
export default LoadLayout;
