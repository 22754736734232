import React, { FunctionComponent, useMemo, useEffect, useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { TextField, Button, Divider, IconButton, Typography, Card } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import SearchValidationsEditor from './SearchValidationsEditor';
import Popup from 'components/Popup';
import EditExpressionOnly from './ExpressionEditor/ExpressionOnly';
import OverrideSecondarySortForm from './OverrideSecondarySort';
import Clear from '@material-ui/icons/Clear';

export interface ListConfig {
    searchInstruction?: string;
    alwaysPreventInitialSearch?: boolean;
    searchValidations?: { expression: string; message: string }[];
    hideCreateButton?: string;
    overrideSecondarySorts?: { field?: string; direction?: 'ASC' | 'DESC' }[];
    showExportForNonSuper?: string;
    mapSearchToCreate?: boolean;
    mapSearchToCreateExpression?: string;
    overrideTitle?: string;
}

interface ListViewConfigControllerProps {
    viewName: string;
    entity: string;
}
const ListViewConfigController: FunctionComponent<ListViewConfigControllerProps> = (props) => {
    const methods = useFormContext<{
        config: string;
    }>();
    const { watch, setValue, register, unregister } = methods;
    useEffect(() => {
        register({ name: 'config' });
        return () => {
            unregister('config');
        };
    }, []); // eslint-disable-line
    const configStr: string = watch('config') as any;
    const config: ListConfig = useMemo(() => {
        return JSON.parse(configStr || '{}');
    }, [configStr]);

    const setOverrideSecondarySorts = useCallback(
        (overrideSecondarySorts: (typeof config)['overrideSecondarySorts']) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    overrideSecondarySorts,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );

    const setOverrideTitle = useCallback(
        (overrideTitle: string) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    overrideTitle,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );

    const setSearchInstruction = useCallback(
        (searchInstruction: string) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    searchInstruction,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setHideCreateButton = useCallback(
        (hideCreateButton: string) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    hideCreateButton,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setShowExportForNonSuper = useCallback(
        (showExportForNonSuper: string) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    showExportForNonSuper,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setAlwaysPreventInitialSearch = useCallback(
        (alwaysPreventInitialSearch: boolean) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    alwaysPreventInitialSearch,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setMapSearchToCreate = useCallback(
        (mapSearchToCreate: boolean) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    mapSearchToCreate: mapSearchToCreate || undefined, // lets just remove the key
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setMapSearchToCreateExpression = useCallback(
        (mapSearchToCreateExpression: string) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    mapSearchToCreateExpression: mapSearchToCreateExpression || undefined, // lets just remove the key
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const setSearchValidations = useCallback(
        (searchValidations: { expression: string; message: string }[]) => {
            setValue(
                'config',
                JSON.stringify({
                    ...config,
                    searchValidations,
                }) as any,
                {
                    shouldDirty: true,
                    shouldValidate: true,
                },
            );
        },
        [config, setValue],
    );
    const handleCalcAllChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setAlwaysPreventInitialSearch(event.target.checked);
        },
        [setAlwaysPreventInitialSearch],
    );
    const handleMapSearchToCreateChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setMapSearchToCreate(event.target.checked);
        },
        [setMapSearchToCreate],
    );
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <div>
            <div style={{ padding: '1em' }}>
                <h3>Prevent Initial Search</h3>
                <label htmlFor="preventInitialSearch">Always prevent initial search?</label>
                <input
                    type="checkbox"
                    id="preventInitialSearch"
                    name="preventInitialSearch"
                    checked={config.alwaysPreventInitialSearch}
                    onChange={handleCalcAllChange}
                />
                <h3>Map Search to Create</h3>
                <label htmlFor="preventInitialSearch">
                    Transfer current search values to the create view when opened?
                </label>
                <input
                    type="checkbox"
                    id="mapSearchToCreate"
                    name="mapSearchToCreate"
                    checked={config.mapSearchToCreate}
                    onChange={handleMapSearchToCreateChange}
                />
                <Card style={{ margin: '1em 0em', padding: '1em' }}>
                    <h3>Map Search To Create (Expression)</h3>
                    <p>Can be used independently, or in conjunction with 'mapSearchToCreate'.</p>
                    <p>
                        Provide a query string expression, which can be templated via SPEL using the values of the
                        current search.
                    </p>
                    <p>The function 'searchEntityData' is uniquely available here, in case ids need to be looked up.</p>
                    <p>See the example configuration below:</p>
                    <p>
                        If 'population.populationCode' is provided, it looks up the relevant 'populationId' so that
                        value is prefilled in the CREATE view we navigate to.
                    </p>
                    <pre>
                        {`populationId=$[(population && population.populationCode) == null ? '' : searchEntityData('Population', 'populationCode=' + population.populationCode)?.![#this.id]?.^[true] ?: '']`}
                    </pre>
                    <TextField
                        onChange={(e) => setMapSearchToCreateExpression(e.target.value)}
                        onBlur={(e) => setMapSearchToCreateExpression(e.target.value)}
                        value={config.mapSearchToCreateExpression ?? ''}
                        variant={fieldVariant}
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label="Map Search to Create Expression"
                        margin="normal"
                        fullWidth
                    />
                </Card>
                <h3>Search Instruction Text</h3>
                <TextField
                    onChange={(e) => setSearchInstruction(e.target.value)}
                    onBlur={(e) => setSearchInstruction(e.target.value)}
                    value={config.searchInstruction}
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Search Instruction Text"
                    margin="normal"
                    fullWidth
                />
                <div style={{ height: '1em' }} />
                <Divider style={{ margin: '1em' }} />
                <h3>Hide Create Button</h3>
                <div style={{ display: 'flex' }}>
                    <Popup
                        ComponentProps={{
                            fullWidth: true,
                        }}
                        paperStyle={{
                            maxWidth: '1024px',
                        }}
                        renderDialogContent={({ closeDialog }) => (
                            <div style={{ margin: '1em', padding: '1em' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography variant="h6">Hide Create Button</Typography>
                                    </div>
                                    <div>
                                        <IconButton
                                            size="small"
                                            style={{ float: 'right' }}
                                            aria-label="close"
                                            onClick={closeDialog}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </div>
                                </div>
                                <EditExpressionOnly
                                    viewName={props.viewName}
                                    initialValues={{ expression: config.hideCreateButton }}
                                    onSubmit={({ expression }) => {
                                        setHideCreateButton(expression || undefined);
                                        closeDialog();
                                    }}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <div style={{ margin: '1em' }}>
                                <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                                    Edit
                                </Button>
                            </div>
                        )}
                    />
                    <TextField
                        onChange={(e) => setHideCreateButton(e.target.value)}
                        onBlur={(e) => setHideCreateButton(e.target.value)}
                        value={config.hideCreateButton ?? ''}
                        variant={fieldVariant}
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label="Hide Create Button (expression)"
                        margin="normal"
                        fullWidth
                        disabled
                    />
                    {config.hideCreateButton ? (
                        <div style={{ paddingTop: 17 }}>
                            <IconButton aria-label="Hide create button" onClick={() => setHideCreateButton(undefined)}>
                                <Clear />
                            </IconButton>
                        </div>
                    ) : null}
                </div>

                <Divider style={{ margin: '1em' }} />

                <h3>Show Export Button if non-super user</h3>
                <div style={{ display: 'flex' }}>
                    <Popup
                        ComponentProps={{
                            fullWidth: true,
                        }}
                        paperStyle={{
                            maxWidth: '1024px',
                        }}
                        renderDialogContent={({ closeDialog }) => (
                            <div style={{ margin: '1em', padding: '1em' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Typography variant="h6">Show Export Button if non-super user</Typography>
                                    </div>
                                    <div>
                                        <IconButton
                                            size="small"
                                            style={{ float: 'right' }}
                                            aria-label="close"
                                            onClick={closeDialog}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </div>
                                </div>
                                <EditExpressionOnly
                                    viewName={props.viewName}
                                    initialValues={{ expression: config.showExportForNonSuper }}
                                    onSubmit={({ expression }) => {
                                        setShowExportForNonSuper(expression || undefined);
                                        closeDialog();
                                    }}
                                />
                            </div>
                        )}
                        renderToggler={({ openDialog }) => (
                            <div style={{ margin: '1em' }}>
                                <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                                    Edit
                                </Button>
                            </div>
                        )}
                    />
                    <TextField
                        onChange={(e) => setShowExportForNonSuper(e.target.value)}
                        onBlur={(e) => setShowExportForNonSuper(e.target.value)}
                        value={config.showExportForNonSuper ?? ''}
                        variant={fieldVariant}
                        InputLabelProps={getInputLabelProps({ shrink: true })}
                        label="Show Export button if not super user (expression)"
                        margin="normal"
                        fullWidth
                        disabled
                    />
                    {config.showExportForNonSuper ? (
                        <div style={{ paddingTop: 17 }}>
                            <IconButton
                                aria-label="Clear setShowExportForNonSuper"
                                onClick={() => setShowExportForNonSuper(undefined)}
                            >
                                <Clear />
                            </IconButton>
                        </div>
                    ) : null}
                </div>
                <h3>Override backup sorts</h3>
                <div style={{ display: 'flex' }}>
                    <ul>
                        {config.overrideSecondarySorts?.map((s, i) => {
                            return (
                                <li key={i}>
                                    <div style={{ display: 'flex' }}>
                                        <TextField
                                            value={s?.field ? s?.field + ': ' + s?.direction : ''}
                                            variant={fieldVariant}
                                            InputLabelProps={getInputLabelProps({ shrink: true })}
                                            label={'Backup sort ' + (i + 1)}
                                            margin="normal"
                                            disabled
                                            fullWidth
                                        />
                                        <IconButton
                                            aria-label={'Clear backup sort ' + (i + 1)}
                                            onClick={() =>
                                                setOverrideSecondarySorts([
                                                    ...config.overrideSecondarySorts.slice(0, i),
                                                    ...config.overrideSecondarySorts.slice(i + 1),
                                                ])
                                            }
                                        >
                                            <Clear />
                                        </IconButton>
                                        <Popup
                                            renderDialogContent={({ closeDialog }) => (
                                                <div style={{ minWidth: '60vw', margin: '1em', padding: '1em' }}>
                                                    <OverrideSecondarySortForm
                                                        rootEntity={props.entity}
                                                        initialValues={s}
                                                        onSubmit={(c) => {
                                                            setOverrideSecondarySorts([
                                                                ...config.overrideSecondarySorts.slice(0, i),
                                                                c,
                                                                ...config.overrideSecondarySorts.slice(i + 1),
                                                            ]);
                                                            closeDialog();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            renderToggler={({ openDialog }) => (
                                                <div style={{ margin: '1em' }}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={openDialog()}
                                                    >
                                                        Edit
                                                    </Button>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <Popup
                    renderDialogContent={({ closeDialog }) => (
                        <div style={{ minWidth: '60vw', margin: '1em', padding: '1em' }}>
                            <OverrideSecondarySortForm
                                rootEntity={props.entity}
                                onSubmit={(s) => {
                                    setOverrideSecondarySorts([...(config.overrideSecondarySorts || []), s]);
                                    closeDialog();
                                }}
                            />
                        </div>
                    )}
                    renderToggler={({ openDialog }) => (
                        <div style={{ margin: '1em' }}>
                            <Button size="small" variant="contained" color="primary" onClick={openDialog()}>
                                Add
                            </Button>
                        </div>
                    )}
                />
                <Divider style={{ margin: '1em' }} />
                <h3>Search Validations</h3>
                <SearchValidationsEditor
                    viewName={props.viewName}
                    setSearchValidations={(sv) => setSearchValidations(sv)}
                    searchValidations={config.searchValidations}
                />
                <Divider style={{ margin: '1em' }} />
                <h3>Override Title</h3>
                <TextField
                    onChange={(e) => setOverrideTitle(e.target.value)}
                    onBlur={(e) => setOverrideTitle(e.target.value)}
                    value={config.overrideTitle}
                    variant={fieldVariant}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    label="Override Title"
                    margin="normal"
                    fullWidth
                />
            </div>
        </div>
    );
};

export default ListViewConfigController;
