import splitByFirstOccurrence from 'util/splitByFirstOccurrance';

const createErrorTableFromMessages = (
    errorText: string[],
): {
    [row: string]: {
        [field: string]: string[];
    };
} => {
    // errorText is in format
    // <field>_<row>:<message>|<field>_<row>:<message> ...
    return errorText
        .flatMap((et) => {
            return et.split('|').map((rowColStr) => {
                const [path, message] = splitByFirstOccurrence(rowColStr, ':');
                const [fieldRow, remaining] = splitByFirstOccurrence(path, ',');

                const [field, row] = fieldRow.split('_', 2);
                return [row, field, remaining ? remaining + ':' + message : message];
            });
        })
        .reduce(
            (res, [row, field, message]) => ({
                ...res,
                [row]: {
                    ...res[row],
                    [field]: [...((res[row] || {})[field] || []), message],
                },
            }),
            {},
        );
};
export default createErrorTableFromMessages;
