import React from 'react';
import { Link } from 'react-router-dom';
import SwapVerticalCircle from '@material-ui/icons/SwapVerticalCircle';
import IconButton from '@material-ui/core/IconButton';

const ImportExportViewConfigBtn = ({ mobile }) => (
    <IconButton
        color="inherit"
        component={Link}
        to={'/admin/application-information'}
        aria-label="Application Information"
    >
        <SwapVerticalCircle />
    </IconButton>
);

export default ImportExportViewConfigBtn;
