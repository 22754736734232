import { createStyles, FormControl, FormHelperText, InputLabel, makeStyles, Theme } from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import React from 'react';
import ReactAuthCodeInput from 'fieldFactory/input/components/AuthCodeInput/ReactAuthCodeInput';
import { EventOrValueHandler, WrappedFieldMetaProps } from 'redux-form';
import { useEvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import formatError from 'fieldFactory/util/formatError';

interface PINInputProps {
    input: {
        onChange: EventOrValueHandler<InputEvent>;
        value?: string;
    };
    meta?: WrappedFieldMetaProps;
    label?: string;
    ariaInputProps?: {
        'aria-labelledby'?: string;
    };
    numChars?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
        container: {
            padding: '16px',
            paddingBottom: '12px',
            paddingRight: 0,
        },
        input: {
            backgroundColor: '#f5f5f5',
            width: '2.5ch',
            marginTop: '3px',
            padding: '6px',
            paddingTop: '8px',
            paddingBottom: '8px',
            borderRadius: '8px',
            fontSize: '28px',
            textAlign: 'center',
            marginRight: '4px',
            border: '1px solid grey',
            textTransform: 'uppercase',
        },
    }),
);

/*
    This is NOT a controlled component! It only writes to form data - does not read.
*/
const PINInput: React.FC<PINInputProps> = (props) => {
    const { numChars = 6 } = props;
    const classes = useStyles();
    const { fieldVariant } = React.useContext(themeOverrideContext);

    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
    return (
        <FormControl
            variant={fieldVariant}
            margin="none"
            style={{ width: '100%' }}
            error={props.meta && props.meta.touched && props.meta.error}
        >
            <InputLabel shrink={true} style={{ marginBottom: '10px' }}>
                {props.label}
            </InputLabel>
            <ReactAuthCodeInput
                internalLabelPrefix="PIN"
                characters={numChars}
                containerClassName={classes.container}
                inputClassName={classes.input}
                inputType="numeric"
                RequiredErrorMessage={
                    props.meta && props.meta.touched && props.meta.error ? (
                        <FormHelperText>
                            {translate({ id: 'validate.error' })}:{' '}
                            {evaluateFormattedMessage(formatError(props.meta.error))}
                        </FormHelperText>
                    ) : null
                }
                onChange={(value) => {
                    props.input.onChange?.(value);
                }}
            />
        </FormControl>
    );
};

export default PINInput;
