const getRwtStyle = (primaryColor: string) => `
.rwt__tabs[data-rwt-vertical="true"] {
    display: flex;
  }

  .rwt__tablist:not([aria-orientation="vertical"]) {
      border-bottom: 1px solid #ddd;
  }

  .rwt__tablist[aria-orientation="vertical"] {
    display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-grow: 0;
      border-right: 1px solid #ddd;
      margin-right: 1rem;
  }

  .rwt__tab {
      background: transparent;
      border: 0;
      font-family: inherit;
      font-size: inherit;
      padding: 1rem 2rem;
    transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  .rwt__tab[aria-selected="false"]:hover,
  .rwt__tab:focus {
    outline: 0;
    background-color: #f4f4f4;
      background-color: rgba(0,0,0,0.05);
  }

  .rwt__tab[aria-selected="true"] {
      background-color: ${primaryColor};
      color: #ffffff;
      position: relative;
  }

  .rwt__tab[aria-selected="true"]:after {
      content: '';
      position: absolute;
  }

  .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
    bottom: -1px;
      left: 0;
      width: 100%;
      border-bottom: 3px solid ${primaryColor};
  }
`;

const STYLE_SHEET_ID = '_rwt_injected_sheet';
const updateRwtStyleSheet = (primaryColor: string) => {
    const sheetToBeRemoved = document.getElementById(STYLE_SHEET_ID);
    if (sheetToBeRemoved) {
        const sheetParent = sheetToBeRemoved.parentNode;
        if (sheetParent) {
            sheetParent.removeChild(sheetToBeRemoved);
        }
    }
    const sheet = document.createElement('style');
    sheet.id = STYLE_SHEET_ID;
    sheet.innerHTML = getRwtStyle(primaryColor);
    document.body.appendChild(sheet);
};
export default updateRwtStyleSheet;
