import { useCallback, useContext, useMemo } from 'react';
import { formContext } from 'components/generics/form/EntityFormContext';
import { formContext as showFormContext } from 'components/generics/form/EntityFormContext/Show';
import useViewConfig from 'util/hooks/useViewConfig';
import { evaluateExpression } from 'expressions/evaluate';
import { entityPreprocessValuesForEval } from 'expressions/formValidation';
import {
    getAllFieldEntriesFromView,
    isFieldViewField,
    getDataTypeForFieldExpr,
    getValueSetForFieldExpr,
    getFieldSourceFromPath,
} from 'components/generics/utils/viewConfigUtils';
import ViewConfig from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';
import casetivityViewContext from 'util/casetivityViewContext';
import useEntities from 'util/hooks/useEntities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import produce from 'immer';
import formTypeContext from 'components/generics/form/formTypeContext';
import { setupGenericContext } from 'expressions/Provider/setupGenericContext';
import { useIntl } from 'react-intl';
import useCurrentFormContext from 'components/generics/form/EntityFormContext/hooks/useCurrentFormContext';
import { useBackrefProperties } from 'components/generics/form/EntityFormContext/util/createBackrefSelector';
import merge from 'lodash/merge';

// doesn't include expressions - just fields used in the view's fields directly as data sources.
const getDataFieldPathsInView = (viewConfig: ViewConfig, viewName: string) =>
    getAllFieldEntriesFromView(viewConfig, viewName).flatMap(([key, field]) => {
        if (isFieldViewField(field)) {
            const dataType = getDataTypeForFieldExpr(
                viewConfig,
                viewConfig.views[viewName].entity,
                field.field,
                'TRAVERSE_PATH',
            );
            if (dataType === 'REFONE') {
                return [field.field, `${field.field}Id`];
            }
            if (dataType === 'REFMANYMANY') {
                return [field.field, `${field.field}Ids`];
            }
            return [field.field];
        }
        return [];
    });
const getValueset1FieldsInView = (viewConfig: ViewConfig, viewName: string): { [field: string]: string } =>
    fromEntries<string>(
        getAllFieldEntriesFromView(viewConfig, viewName).flatMap(([key, field]) => {
            const rootEntity = viewConfig.views[viewName].entity;
            if (isFieldViewField(field)) {
                const dataType = getDataTypeForFieldExpr(viewConfig, rootEntity, field.field, 'TRAVERSE_PATH');
                if (dataType === 'VALUESET') {
                    return [
                        [
                            field.field,
                            getValueSetForFieldExpr(viewConfig, rootEntity, field.field, 'TRAVERSE_PATH'),
                        ] as [string, string],
                    ];
                }
            }
            return [];
        }) as [string, string][],
    );

export const useValuesInEntityContextFromExpansions = (expansionsRequired: string[]) => {
    const sfc = useContext(showFormContext);
    const efc = useContext(formContext);
    const currentFormTypeContext = useContext(formTypeContext);
    const fc = currentFormTypeContext === 'SHOW' ? sfc : efc;
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const baseEntity = viewConfig.views[fc.viewName].entity;
    const id = fc === efc ? efc.initialValues['id'] : fc.fieldValues['id'];
    const values = useMemo(
        () =>
            produce(
                denormalizeEntitiesByPaths(
                    entities,
                    expansionsRequired.map((f) => getFieldSourceFromPath(viewConfig, baseEntity, f)),
                    viewConfig,
                    baseEntity,
                    id,
                ) || {},
                (draftState) => {
                    expansionsRequired.forEach((f) => {
                        if (typeof draftState[f] === 'undefined') {
                            draftState[f] = null;
                        }
                    });
                    return draftState;
                },
            ),
        [id, baseEntity, entities, expansionsRequired, viewConfig],
    );
    return values;
};

export const useValuesInEntityContext = (expression: string) => {
    const expansionsRequired = useMemo(() => {
        if (typeof expression !== 'string') {
            return [];
        }
        return getFieldsRequiredForExpression(expression);
    }, [expression]);

    const values = useValuesInEntityContextFromExpansions(expansionsRequired);
    return values;
};

export type EvaluateInEntityContextOptions = (
    | {
          throwOnException: true;
      }
    | {
          throwOnException: false;
          defaultOnException: any;
      }
) & {
    useLiveValues?: boolean;
};

export function useEvaluatorInEntityContext(expression: string, options: EvaluateInEntityContextOptions) {
    const { throwOnException, useLiveValues = false } = options;
    const defaultOnException = options.throwOnException === false ? options.defaultOnException : undefined;

    const viewConfig = useViewConfig();
    const entities = useEntities();
    const viewContext = useContext(casetivityViewContext);
    const valueSets = useSelector((state: RootState) => state.valueSets);

    const values = useValuesInEntityContext(expression);

    const fc = useCurrentFormContext();
    const backrefProperties = useBackrefProperties(fc['initialValues'] ?? null);

    const intl = useIntl();

    const evalValues = useMemo(
        () => (useLiveValues ? merge({}, values, fc.fieldValues) : values),
        [useLiveValues, values, fc.fieldValues],
    );
    const getExpressionResult = useCallback(
        (patchValues = {}) => {
            if (typeof expression === 'boolean' || expression === null) {
                return expression;
            }

            // merge 'patchValues' before _and_ after processing, so we don't overwrite it e.g. if we are patching fieldCode, but fieldId is null.
            const nullInitializedFields = merge(
                {},
                entityPreprocessValuesForEval(
                    merge({}, evalValues, patchValues),
                    getDataFieldPathsInView(viewConfig, fc.viewName),
                    getValueset1FieldsInView(viewConfig, fc.viewName),
                    entities,
                    {
                        viewContext,
                        dateFormat: viewConfig.application.dateFormat,
                        backref: backrefProperties,
                    },
                    valueSets,
                ),
                patchValues,
            );

            const evaluate = () => {
                return evaluateExpression(expression, {
                    ...nullInitializedFields,
                    ...setupGenericContext({
                        viewConfig,
                        entities,
                        valueSets,
                        intl,
                        viewContext,
                    }),
                    _user: viewConfig.user,
                    ...fc.adhocVariablesContext,
                });
            };
            if (throwOnException) {
                return evaluate();
            }
            try {
                return evaluate();
            } catch (e) {
                console.error(e);
                return defaultOnException;
            }
        },
        [
            expression,
            fc.adhocVariablesContext,
            evalValues,
            entities,
            valueSets,
            viewContext,
            fc.viewName,
            viewConfig,
            throwOnException,
            defaultOnException,
            intl,
            backrefProperties,
        ],
    );
    return getExpressionResult;
}

function useEvaluateInEntityContext(expression: string, options: EvaluateInEntityContextOptions) {
    const getExpressionResult = useEvaluatorInEntityContext(expression, options);
    return useMemo(getExpressionResult, [getExpressionResult]);
}

export default useEvaluateInEntityContext;
