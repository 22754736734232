import { EntityBase, GenericCrudService, GenericAjaxResponse } from 'sideEffect/services';
import { concat, of } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';
import { fetchStart, fetchEnd } from 'actions/aor/fetchActions';
import { HttpError } from '../../../HttpError';
import ViewConfig from 'reducers/ViewConfigType';
import { processResponse } from 'sideEffect/crud/util/normalizeEntityResponse';
import { ListFailureActionCreators, ListSuccessActionCreators, ListInitialActions } from '../../../../actionTypes';
import { ErrorsCbs } from '../../../../errorTypes';

import { FlowContext, FlowOptions, handleErrors, getDontOverwriteList } from '../shared';
import { stripCallbacksFromPayload } from 'sideEffect/crud/util/stripCallbacks';
import { getExpensiveCalcsNotInUrlExpansion, RecursiveEntity } from 'sideEffect/crud/util/getExpensiveCalcsNotExpanded';
import buildUrl from 'sideEffect/crud/getList/buildUrl';

const getListResponseAndThrowErrorForNon200 = map((r: GenericAjaxResponse) => {
    const { status, response, xhr } = r;
    if (status < 200 || status >= 300) {
        throw new HttpError(response.message, status);
    } else {
        const xTotalCount = xhr.getResponseHeader('x-total-count');
        if (isNaN(Number(xTotalCount))) {
            throw new Error(`The X-Total-Count header is missing in the HTTP Response.
            If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?`);
        }
        return {
            response,
            total: parseInt(xTotalCount, 10),
        };
    }
});

interface CrudGetListFlowOptions<D> extends FlowOptions<D> {
    service: GenericCrudService<D>;
    failureAction: ListFailureActionCreators;
    successAction: ListSuccessActionCreators;
    errorsCbs: ErrorsCbs;
    successCb?: (args: { response: any[]; total: number }) => void;
}
interface CrudGetListFlowContext extends FlowContext {
    initialRequestPayload: ListInitialActions['payload'];
    resource: string;
    viewConfig: ViewConfig;
}
const flatMapListToSuccessActions = <D>(
    successAction: CrudGetListFlowOptions<D>['successAction'],
    context: CrudGetListFlowContext,
) =>
    flatMap(<T extends EntityBase>(r: { response: T[]; total: number }) => {
        const processedResponse = r && processResponse(context.resource, context.viewConfig, r.response);
        const url = buildUrl(context.initialRequestPayload, context.viewConfig);
        const expcniue = getExpensiveCalcsNotInUrlExpansion(
            url,
            context.viewConfig,
            r.response as unknown as RecursiveEntity[],
        );
        const dontOverwrite = getDontOverwriteList(
            context.viewConfig,
            r.response,
            processedResponse.entities,
            expcniue,
        );
        return concat(
            of(
                successAction(
                    processedResponse,
                    stripCallbacksFromPayload(context.initialRequestPayload),
                    r.total,
                    dontOverwrite,
                ),
            ),
            of(fetchEnd()),
        );
    });
const crudListFlow = <D>(
    requestArgs: {
        restUrl: string;
    },
    params: CrudGetListFlowOptions<D>,
    context: CrudGetListFlowContext,
    triggerFetchStart: boolean = true,
) => {
    const ajax$ = params.service({ restUrl: requestArgs.restUrl }).pipe(
        getListResponseAndThrowErrorForNon200,
        tap((response) => {
            if (params.successCb) {
                params.successCb(response);
            }
        }),
        flatMapListToSuccessActions(params.successAction, context),
        handleErrors(params.errorsCbs, (err) =>
            params.failureAction(err, stripCallbacksFromPayload(context.initialRequestPayload)),
        ),
    );
    if (triggerFetchStart) {
        return concat(of(fetchStart()), ajax$);
    }
    return ajax$;
};
export default crudListFlow;
