import * as idb from 'idb';
import buildHeaders from 'sideEffect/buildHeaders';

/*
    Note: 'createCB' is duplicated in public/service-worker.js
    So be careful with any changes here.
*/
export function createDB() {
    const dbPromise = idb.openDB('RetriesNeedingAuth', 1, {
        upgrade(db) {
            db.createObjectStore('requests');
        },
    });

    return {
        async get(key) {
            return (await dbPromise).get('requests', key);
        },
        async set(key, val) {
            return (await dbPromise).put('requests', val, key);
        },
        async del(key) {
            return (await dbPromise).delete('requests', key);
        },
        async clear() {
            return (await dbPromise).clear('requests');
        },
        async keys() {
            return (await dbPromise).getAllKeys('requests');
        },
    };
}
/*
    Go through our queue of retries that need authentication before they can be retried,
    and pop them off our queue as long as the server responds with a non- 401/403 status.
*/
export const tryAllRetriesNeedingAuth = async () => {
    if (!(window as any).CASETIVITY_USE_SERVICE_WORKER) {
        // we provide this flag on setup just to prevent all the extra work below
        return;
    }
    const { get, del, keys } = await createDB();
    const allKeys = await keys();
    for (let i = 0; i < allKeys.length; i++) {
        const key = allKeys[i];
        const value = await get(key);
        const request = new Request(value.url, {
            method: value.method,
            body: JSON.stringify(value.body),
            credentials: 'same-origin',
            headers: buildHeaders({
                includeCredentials: true,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }),
        });
        // if we have a non-401 server response, then lets drop it
        await fetch(request)
            .then((r) => {
                if (r.status !== 401 && r.status !== 403) {
                    return del(key);
                }
            })
            .catch((e) => {
                console.log('retry failed.');
            });
    }
};
