import * as t from 'io-ts';

const _baseFields = {
    casetivityNeighborhoodId: t.string,
    casetivityNeighborhoodCode: t.string,
    casetivityCityId: t.string,
    casetivityCityCode: t.string,
    casetivityStateId: t.string,
    casetivityStateCode: t.string,
    casetivityZip: t.string,
};
const baseFields = t.partial(_baseFields);

export const FieldMapping1 = t.exact(
    t.intersection([
        t.type({
            casetivityLine1: t.string,
            casetivityLine2: t.string,
        }),
        baseFields,
    ]),
);

export const FieldMapping2 = t.exact(
    t.intersection([
        t.partial({
            casetivityHouse: t.string,
            casetivityStreet: t.string,
            casetivityUnit: t.string,
        }),
        baseFields,
    ]),
);

/* This type is used internally, coming from the 'config' prop. */
export const FieldMapping = t.union([FieldMapping1, FieldMapping2]);

/* This type is used externally, setting the required validation for the component,
    and passing fieldMapping as 'config' prop for internal use */
export const _AddressWidgetConfig = t.exact(
    t.intersection([
        t.type({
            fieldMapping: FieldMapping,
            validationMessage: t.string,
            manualVerificationAllowed: t.union([
                t.literal('ALWAYS'),
                t.literal('ENDPOINT_REACHED'),
                t.literal('NEVER'),
            ]),
            verificationRequired: t.union([t.literal('ALWAYS'), t.literal('WHEN_NOT_EMPTY'), t.literal('NEVER')]),
            useAddressEntityValidations: t.boolean,
        }),
        t.partial({
            showVerificationStatus: t.boolean,
            fixedValues: t.dictionary(t.keyof(_baseFields), t.any),
        }),
    ]),
);

export type IFieldMapping1 = t.TypeOf<typeof FieldMapping1>;
export type IFieldMapping2 = t.TypeOf<typeof FieldMapping2>;
export type IFieldMapping = t.TypeOf<typeof FieldMapping>;

export interface IAddressWidgetConfig extends t.TypeOf<typeof _AddressWidgetConfig> {}
export const AddressWidgetConfig = t.clean<IAddressWidgetConfig, IAddressWidgetConfig>(_AddressWidgetConfig);
