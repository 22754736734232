import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import { loadSuccess } from 'viewConfig/actions';
import {
    getAllFieldEntriesFromView,
    getViewNameFromComponentField,
    isComponentField,
} from 'components/generics/utils/viewConfigUtils';
import { tryCatch } from 'fp-ts/lib/Option';
import { EXPRESSIONTEMPLATES_LOCALSTORAGE_KEY } from './constants';
import { getStorage } from 'storage/storage';
import { TemplateExpressionsGenerated } from './TemplateExpressionsGenerated';
import ViewConfig, { ViewField } from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';
import { parseTemplateString } from 'viewConfigCalculations/util/parseTemplateString';
import { templateExpressionsGenerated } from './actions';

type TemplateExpressions = {
    [field: string]: {
        valuesetFieldsRequired: {
            [field: string]: string;
        };
        expression: string;
        expressionFieldId: string;
        valuesetLiterals: string[];
        dataPaths: string[];
        expansionsRequired: string[];
    };
};

export const getTemplateExpressions = (
    viewConfig: ViewConfig,
    rootEntity: string,
    fields: [string, ViewField][],
    rebaseOntoRootPath?: string,
): TemplateExpressions => {
    const componentFieldExpressions = fields.reduce((prev, [k, f]) => {
        if (!isComponentField(f)) {
            return prev;
        }
        const componentViewName = getViewNameFromComponentField(f);
        return Object.assign(
            prev,
            Object.fromEntries(
                Object.entries(
                    getTemplateExpressions(
                        viewConfig,
                        rootEntity,
                        getAllFieldEntriesFromView(viewConfig, componentViewName),
                        f.field,
                    ),
                ).map(([fieldKey, v]) => [
                    [k, fieldKey].join('.'),
                    { ...v, expressionFieldId: [k, v.expressionFieldId].join('.') },
                ]),
            ),
        );
    }, {} as TemplateExpressions);

    const entries = fields
        .flatMap(([, f]) => {
            if (f.config && f.widgetType === 'EXPRESSION') {
                return (
                    tryCatch(() => {
                        return parseTemplateString(f.config, viewConfig, rootEntity, rebaseOntoRootPath);
                    })
                        // this is only used for getting expansions, so lets filter out unused.
                        .filter((result) => result.expansionsRequired.length > 0)
                        .map((e): TemplateExpressionsGenerated[0][0][] => [{ ...e, expressionFieldId: f.field }])
                        .getOrElse([])
                );
            }
            return [];
        })
        .map((e) => [e.expressionFieldId, e] as [string, typeof e]);
    return { ...componentFieldExpressions, ...fromEntries(entries) };
};

export const getTemplateExpressionsFromView = (viewConfig: ViewConfig, viewName: string): TemplateExpressions => {
    const fieldEntriesForView = getAllFieldEntriesFromView(viewConfig, viewName);
    const rootEntity = viewConfig.views[viewName].entity;
    return getTemplateExpressions(viewConfig, rootEntity, fieldEntriesForView);
};

const generateViewItemTemplateExpressions: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            return fromEntries(
                Object.keys(viewConfig.views).flatMap((viewName) => {
                    const entriesObj = getTemplateExpressionsFromView(viewConfig, viewName);
                    if (Object.keys(entriesObj).length > 0) {
                        return [[viewName, entriesObj]];
                    }
                    return [];
                }),
            );
        }),
        tap((vExps: TemplateExpressionsGenerated) => {
            getStorage().setItem(EXPRESSIONTEMPLATES_LOCALSTORAGE_KEY, JSON.stringify(vExps));
        }),
        map((e) => {
            return templateExpressionsGenerated(e);
        }),
    );
export default generateViewItemTemplateExpressions;
