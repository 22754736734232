import DOMPurify from 'dompurify';

export const ALLOW_DOWNLOADLINKS = {
    ADD_ATTR: ['download', 'target'],
    ADD_DATA_URI_TAGS: ['a'],
    ADD_TAGS: ['a'],
    ADD_URI_SAFE_ATTR: ['href'],
} as const;

export const purifyHtml = (dangerousMarkup: string) =>
    DOMPurify.sanitize(dangerousMarkup, {
        ...ALLOW_DOWNLOADLINKS,
    });
