import { FlowOptions, FlowContext, crudFlow, getResponseAndThrowErrorForNon200, handleErrors } from '../shared';
import {
    DeleteFailureActionCreators,
    DeleteSuccessActionCreators,
    DeleteInitialActions,
} from 'sideEffect/crud/actionTypes';
import { ErrorsCbs } from '../../../../errorTypes';

import { EntityBase } from 'sideEffect/services';
import { flatMap, tap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { stripCallbacksFromPayload } from 'sideEffect/crud/util/stripCallbacks';
import { fetchEnd, fetchStart } from 'actions/aor/fetchActions';

interface DeleteFlowOptions<D> extends FlowOptions<D> {
    failureAction: DeleteFailureActionCreators;
    successAction: DeleteSuccessActionCreators;
    errorsCbs: ErrorsCbs;
    successCb: () => void;
}

interface DeleteFlowContext extends FlowContext {
    initialRequestPayload: DeleteInitialActions['payload'];
}

const flatMapDeleteToSuccessActions = <D>(successAction: DeleteSuccessActionCreators, context: DeleteFlowContext) =>
    flatMap(<T extends EntityBase>(r: T) => {
        return concat(
            of(
                successAction(
                    context.initialRequestPayload.id,
                    stripCallbacksFromPayload(context.initialRequestPayload),
                ),
            ),
            of(fetchEnd()),
        );
    });

const callDeleteSuccessCb = (cb?: DeleteFlowOptions<any>['successCb']) =>
    tap(() => {
        if (cb) {
            cb();
        }
    });

const crudDeleteFlow: crudFlow = <D>(requestArgs, params: DeleteFlowOptions<D>, context: DeleteFlowContext) => {
    return concat(
        of(fetchStart()),
        params.service(requestArgs).pipe(
            getResponseAndThrowErrorForNon200,
            callDeleteSuccessCb(params.successCb),
            flatMapDeleteToSuccessActions(params.successAction, context),
            handleErrors(params.errorsCbs, (err) =>
                params.failureAction(err, stripCallbacksFromPayload(context.initialRequestPayload)),
            ),
        ),
    );
};
export default crudDeleteFlow;
