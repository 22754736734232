import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import EditableViewFormLayout from 'layout-editor/components/EditableViewFormLayout';
import Popup from 'components/Popup';
import { Button, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import LibraryAdd from '@material-ui/icons/LibraryAdd';
import Save from '@material-ui/icons/Save';
import Result from 'layout-editor/UniversalViewWizard/steps/Result';
import { EditReorderableActions } from 'layout-editor/build-layout/steps/components/ReorderableActions';
import BulkAddFields from './BulkAddFields';
interface LayoutEditorWrapperProps {
    viewName: string;
    children: (props: {
        configStringRef: React.MutableRefObject<string>;
        EditLayoutElem: JSX.Element;
        renderEditActionsElem: (recordId?: string) => JSX.Element;
        actionsKey: number;
    }) => JSX.Element;
}
const LayoutEditorWrapper: React.FC<LayoutEditorWrapperProps> = ({ viewName, children }) => {
    const viewConfig = useViewConfig();
    const initialView = viewConfig.views[viewName];

    /**
     * actionsKey says this:
     * "When I increment, that means that the 'entityActions' configuration has been modified from a different place"
     * (i.e. this is the element rendered in renderEditActionsElem)
     * "So: when I increment, take the value in configStringRef and load that into your internal state-
     * as it is the new source of truth for this value."
     */
    const [actionsKey, rerender] = useReducer((state) => state + 1, 1);

    const [view, setView] = React.useState(initialView);

    const configStringRef = useRef(view.config);

    const createOverrideViewConfig = useCallback(() => {
        return produce(viewConfig, (draft) => {
            draft.views[initialView.name] = {
                ...initialView,
                ...view,
                config: configStringRef.current,
            };
        });
    }, [viewConfig, view, initialView]);

    const [overrideEntityVersion, incEntityVersion] = useReducer(
        (state) => state + 1,
        viewConfig.viewDefs?.[viewName]?.entityRevision ?? 0,
    );
    const overrideViewConfig = useMemo(createOverrideViewConfig, [createOverrideViewConfig, actionsKey]);

    const EditLayoutElem = (
        <div style={{ margin: '1em 0', padding: '1em' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Popup
                        ComponentProps={{ fullScreen: true, fullWidth: true }}
                        renderDialogContent={({ closeDialog }) => {
                            return (
                                <div>
                                    <IconButton
                                        size="small"
                                        style={{ float: 'right' }}
                                        aria-label="clear"
                                        onClick={closeDialog}
                                    >
                                        <Clear />
                                    </IconButton>
                                    <EditableViewFormLayout
                                        isCreate={initialView.viewType === 'CREATE'}
                                        hasTabs={initialView.viewType !== 'CREATE'}
                                        mode={initialView.viewType === 'SHOW' ? 'SHOW' : 'EDIT'}
                                        entityType={initialView.entity}
                                        view={overrideViewConfig.views[initialView.name]}
                                        onViewChange={({ view: newView }) => {
                                            setView({
                                                ...view,
                                                tabs: newView.tabs,
                                                fields: newView.fields,
                                            });
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ float: 'right', margin: '1em' }}
                                        onClick={closeDialog}
                                    >
                                        Close
                                    </Button>
                                </div>
                            );
                        }}
                        renderToggler={({ openDialog }) => (
                            <Button
                                endIcon={<Edit />}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={openDialog()}
                            >
                                Edit Layout
                            </Button>
                        )}
                    />
                </div>
                <div>
                    <Popup
                        ComponentProps={{
                            fullWidth: true,
                        }}
                        paperStyle={{
                            maxWidth: '1024px',
                        }}
                        renderDialogContent={({ closeDialog }) => {
                            // we have to call this, to get the current value in the ref.
                            const upToDateViewConfig = createOverrideViewConfig();
                            return (
                                <BulkAddFields
                                    onClose={closeDialog}
                                    view={upToDateViewConfig.views[initialView.name]}
                                    onChange={(view) => {
                                        setView(view);
                                    }}
                                    viewConfig={upToDateViewConfig}
                                />
                            );
                        }}
                        renderToggler={({ openDialog }) => (
                            <Button
                                endIcon={<LibraryAdd />}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={openDialog()}
                            >
                                Bulk Add Fields
                            </Button>
                        )}
                    />
                </div>
                <div>
                    <Popup
                        renderDialogContent={({ closeDialog }) => {
                            // we have to call this, to get the current value in the ref.
                            const upToDateViewConfig = createOverrideViewConfig();
                            return (
                                <Result
                                    mode="PUT"
                                    initialValues={{
                                        ...upToDateViewConfig.views[viewName],
                                        entityVersion: overrideEntityVersion,
                                    }}
                                    prevViewName={viewName}
                                    overrideViewConfig={upToDateViewConfig}
                                    diffFrom="provided"
                                    old={initialView}
                                    onSuccess={() => {
                                        incEntityVersion();
                                    }}
                                />
                            );
                        }}
                        renderToggler={({ openDialog }) => (
                            <Button
                                endIcon={<Save />}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={openDialog()}
                            >
                                Review and Save Changes
                            </Button>
                        )}
                    />
                </div>
            </div>
        </div>
    );

    const renderEditActionsElem = (recordId?: string) => (
        <EditReorderableActions
            viewName={viewName}
            recordId={recordId}
            configStringRef={configStringRef}
            type={initialView.viewType === 'SHOW' ? 'SHOW' : 'EDIT'}
            overrideViewConfig={viewConfig}
            onChange={rerender}
        />
    );

    return (
        <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
            {children({ configStringRef, EditLayoutElem, renderEditActionsElem, actionsKey })}
        </OverriddenViewConfigContext.Provider>
    );
};
export default LayoutEditorWrapper;
