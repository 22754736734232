import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import DrawerMenu from './Drawer';
import ThemeableToolbarTitle from 'components/SsgToolbarTitle';
import SwipeableTemporaryTaskDrawer from './TogglableTaskDrawer';
import SwipeableTemporaryThemeDrawer from './TogglableThemeEditor';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { themeOverrideContext } from './ThemeOverrideProvider';

interface Props {
    children: React.ReactElement;
}

function HideOnScroll(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const drawerWidth = 240;

interface StyleProps {
    invertAppBar?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: (props: StyleProps) => ({
            zIndex: theme.zIndex.drawer + 1,
            color: props.invertAppBar ? theme.palette.primary.main : undefined,
            backgroundColor: props.invertAppBar ? theme.palette.background.paper : undefined,
            // below: shifts navbar so it doesn't pass the entire way.
            // [theme.breakpoints.up('sm')]: {
            //     width: `calc(100% - ${drawerWidth}px)`,
            //     marginLeft: drawerWidth,
            // },
        }),
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(2),
        },
    }),
);

export default function ResponsiveDrawer(props: Props) {
    const { invertMenu } = useContext(themeOverrideContext);
    const classes = useStyles({ invertAppBar: invertMenu });
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = <DrawerMenu />;

    return (
        <div className={classes.root}>
            <HideOnScroll>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography variant="h6" noWrap> */}
                        <ThemeableToolbarTitle />
                        <span style={{ float: 'right' }}>
                            <SwipeableTemporaryTaskDrawer />
                            <SwipeableTemporaryThemeDrawer />
                        </span>
                        {/* </Typography> */}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <nav className={classes.drawer} aria-label="navigation">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main id="maincontent" className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}
