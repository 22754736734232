import * as privateGlobalAlerts from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, catchError, flatMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

const getPrivateGlobalAlertsFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(privateGlobalAlerts.getPrivateGlobalAlerts)),
        flatMap(
            (action): Observable<RootAction> =>
                services.getPrivateGlobalAlerts().pipe(
                    map((resp) => privateGlobalAlerts.getPrivateGlobalAlertsSuccess(resp)),
                    catchError((e) => of(privateGlobalAlerts.getPrivateGlobalAlertsFailure(e))),
                ),
        ),
    );

export default getPrivateGlobalAlertsFlow;
