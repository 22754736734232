import ViewConfig from 'reducers/ViewConfigType';

export const reverseLookupAccessLevel = (viewConfig: ViewConfig, linkedEntityType: string, ourEntityType: string) => {
    const fieldPointingToUs =
        viewConfig.entities[linkedEntityType] &&
        Object.values(viewConfig.entities[linkedEntityType].fields).find((f) => {
            return f.dataType === 'REFMANY' && f.relatedEntity === ourEntityType;
        });
    if (fieldPointingToUs) {
        return fieldPointingToUs.accessLevel;
    }
    return null;
};
