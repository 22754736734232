import { EntityBase, GenericCrudArgs } from 'sideEffect/services';
import { concat, of } from 'rxjs';
import { fetchStart } from 'actions/aor/fetchActions';
import { stripCallbacksFromPayload } from '../../../stripCallbacks';
import {
    CreateUpdateFailureActionCreators,
    CreateUpdateSuccessActionCreators,
    CreateUpdateInitialActions,
} from '../../../../actionTypes';
import { ErrorsCbs } from '../../../../errorTypes';
import {
    FlowOptions,
    FlowContext,
    getResponseAndThrowErrorForNon200,
    handleErrors,
    callSuccessCb,
    flatMapResponseEntityToSuccessActions,
} from '../shared';
import { mergeMap } from 'rxjs/operators';
import { processActivityEventCreator } from 'bpm/actions/processActivityEvent';

export interface CreateUpdateFlowOptions<D> extends FlowOptions<D> {
    failureAction: CreateUpdateFailureActionCreators;
    successAction: CreateUpdateSuccessActionCreators;
    errorsCbs: ErrorsCbs;
    successCb: (id?: string, data?: EntityBase) => void;
}

export interface CreateUpdateFlowContext extends FlowContext {
    initialRequestPayload: CreateUpdateInitialActions['payload'];
    restUrl: string;
}

const maybeGetProcessIdFromPathname = (pathname: string) => {
    const [, rest] = pathname.split('/processes/');
    return rest?.split('/tasks/')?.[0] ?? rest;
};

const crudCreateUpdateFlow = <D>(
    requestArgs: GenericCrudArgs<D>,
    params: CreateUpdateFlowOptions<D>,
    context: CreateUpdateFlowContext,
) => {
    return concat(
        of(fetchStart()),
        params.service(requestArgs).pipe(
            mergeMap((response) => {
                const followUp = of(response).pipe(
                    getResponseAndThrowErrorForNon200,
                    callSuccessCb(params.successCb),
                    flatMapResponseEntityToSuccessActions(params.successAction, context),
                );
                const maybeProcessId = maybeGetProcessIdFromPathname(window?.location?.pathname);
                if (maybeProcessId) {
                    const refreshProcessData = response.xhr?.getResponseHeader?.('X-Refresh-Process-Data');
                    if (refreshProcessData) {
                        return concat(of(processActivityEventCreator.getProcessActivities(maybeProcessId)), followUp);
                    }
                }
                return concat(followUp);
            }),
            handleErrors(params.errorsCbs, (err) =>
                params.failureAction(err, stripCallbacksFromPayload(context.initialRequestPayload)),
            ),
        ),
    );
};
export default crudCreateUpdateFlow;
