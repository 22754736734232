import React from 'react';
import { Typography } from '@material-ui/core';

const getNoResultsElement = (text: string | JSX.Element) => {
    return (
        <div style={{ padding: '.25em', paddingLeft: '1em', paddingTop: '1em' }}>
            <Typography gutterBottom={true} variant="h6" component="div">
                {text}
            </Typography>
        </div>
    );
};
export default getNoResultsElement;
