import { getRelatedField } from 'components/generics/utils/viewConfigUtils';
import updateEntriesPostCreation from 'fieldFactory/offline/util/updateEntriesPostCreation';
import moment from 'moment';
import { getOfflineSubmitEntries } from 'offline_app/offline_stateful_tasks/back_online/components/getOfflineSubmitEntries';
import { getOfflineSubmits } from 'offline_app/offline_stateful_tasks/back_online/components/OfflineWorkBanner';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { Dispatch } from 'redux';
import { CreateParams, crudCreate } from 'sideEffect/crud/create/actions';
import { crudUpdate, UpdateParams } from 'sideEffect/crud/update/actions';
import useViewConfig from 'util/hooks/useViewConfig';
import { setEntitySubmitsInTaskContext } from '../EntitySubmitsInTaskContext/actions';
import { CreateEntry, EditEntry, Entry, Target } from '../EntitySubmitsInTaskContext/Entry';
import { Button, CardActions, CardContent, CardHeader, Dialog } from '@material-ui/core';
import isOffline from 'util/isOffline';
import { getDecryptEntityDataPromptController } from 'offline_app/offlinePinEntryPopup/promptDecodeEntityData';
import persistEncryptData from '../persistEncryptData';
import deleteEntitySubmitEntry from '../deleteEntitySubmitEntry';
import Alert from '@material-ui/lab/Alert';
import useIsPristine from 'offline_app/hooks/useIsPristine';

type R = 'success' | 'conflict' | 'network_error' | 'uncaught_error';
const crudUpdatePromise = (dispatch: Dispatch) => (params: UpdateParams) => {
    return new Promise<R>((resolve, reject) => {
        dispatch(
            crudUpdate({
                ...params,
                cb: (id, data) => {
                    resolve('success');
                },
                errorsCbs: {
                    409: () => {
                        resolve('conflict');
                    },
                    '*': (error) => {
                        if (!error.status) {
                            resolve('network_error');
                        } else if (error.status !== 409) {
                            resolve('uncaught_error');
                        }
                    },
                },
            }),
        );
    });
};
const crudCreatePromise =
    (dispatch: Dispatch) => (params: CreateParams, onCreate?: (id: string, data: {}) => Promise<void>) => {
        console.log({
            params,
        });
        return new Promise<R>((resolve, reject) => {
            dispatch(
                crudCreate({
                    ...params,
                    cb: async (id, data) => {
                        if (onCreate) {
                            await onCreate(id, data);
                        }
                        resolve('success');
                    },
                    errorsCbs: {
                        409: () => {
                            resolve('conflict');
                        },
                        '*': (error) => {
                            if (!error.status) {
                                resolve('network_error');
                            } else if (error.status !== 409) {
                                resolve('uncaught_error');
                            }
                        },
                    },
                }),
            );
        });
    };
export type ReturnCode = 'ok' | 'conflict' | 'network' | 'uncaught';
export const useSaveEntries = (taskId: string) => {
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    // iterate over idb entries
    // on an error, flag in entitySubmitsInTaskContext somehow
    // (e.g. 'markForMergeResolution' on the root + endnode?)
    const save = useCallback(async (): Promise<ReturnCode> => {
        let returnCode: ReturnCode = 'ok';
        const entries = await getOfflineSubmitEntries(taskId);
        const sortedByDate = Object.entries(entries).sort(([a], [b]) => {
            const ma = moment(a);
            if (ma.isBefore(b)) {
                return -1;
            }
            if (ma.isAfter(b)) {
                return 1;
            }
            return 0;
        });
        const keys = sortedByDate.map(([k]) => k);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value: Entry = await getDecryptEntityDataPromptController().promptDecodeEntityData(key, false);
            const [submissionEntry, { entityType, source, id: fromId }] = (() => {
                type PointedBy = { entityType: string; source: string; id: string };
                const r = (t: Target, pointedToBy?: PointedBy): [CreateEntry | EditEntry, PointedBy] => {
                    if (t.tag === 'create') {
                        return [t, pointedToBy];
                    }
                    if (t.tag === 'edit') {
                        return [t, pointedToBy];
                    }
                    if (t.recordType.tag === 'exists') {
                        return r(t.next, { entityType: t.fromEntity, source: t.source, id: t.recordType.id });
                    } else {
                        throw new Error('trying to submit a record where parent has not been created.');
                    }
                };
                return r(value.target);
            })();
            const submissionResult = await (submissionEntry.tag === 'edit'
                ? crudUpdatePromise(dispatch)(submissionEntry.record)
                : crudCreatePromise(dispatch)(
                      (() => {
                          const {
                              data: { id, ...dataRest },
                              ...rest
                          } = submissionEntry.record as any;
                          const backref = getRelatedField(viewConfig, entityType, source, 'TRAVERSE_PATH');
                          return { ...rest, data: { ...dataRest, [backref + 'Id']: fromId } };
                      })(),
                      (id, data) => updateEntriesPostCreation(taskId, submissionEntry.tempId, id),
                  ));

            // NOW, ON CREATE, WE HAVE TO UPDATE ALL OUR FOLLOWUPS
            if (submissionResult === 'success') {
                await deleteEntitySubmitEntry(key);
                continue;
            }
            if (submissionResult === 'conflict') {
                await persistEncryptData(key, { ...value, error: 'conflict' });
                returnCode = 'conflict';
                break;
            }
            if (submissionResult === 'network_error') {
                await persistEncryptData(key, { ...value, error: 'network' });
                returnCode = 'uncaught';
                break;
            }
            if (submissionResult === 'uncaught_error') {
                await persistEncryptData(key, { ...value, error: 'uncaught' });
                returnCode = 'uncaught';
                break;
            }
        }
        const submits = await getOfflineSubmits(taskId);
        dispatch(setEntitySubmitsInTaskContext(taskId, submits));
        return returnCode;
    }, [taskId, dispatch, viewConfig]);
    return save;
};

export const useHasOfflineEntitySubmits = (taskId: string) => {
    const offlineEntitySubmits = useSelector((state: RootState) => state.entitySubmitsInTaskContext);
    return (
        offlineEntitySubmits.type === 'loaded' &&
        offlineEntitySubmits.taskId === taskId &&
        offlineEntitySubmits.entries?.length > 0
    );
};

export const OfflineEntitySubmitsPrompt: React.FC<{
    promptState: ReturnCode;
    setPromptState: (ps: ReturnCode) => void;
}> = ({ promptState, setPromptState }) => {
    return promptState !== 'ok' ? (
        <Dialog open onClose={() => setPromptState('ok')}>
            <CardHeader title={promptState === 'conflict' ? 'A conflict has occurred' : 'A problem has occurred'} />
            <CardContent>
                {promptState === 'conflict' ? (
                    <p>
                        A conflict as occurred with work someone else has submitted. Please review the items flagged (!)
                        below.
                    </p>
                ) : promptState === 'network' ? (
                    <p>A network error has occurred. Please retry.</p>
                ) : (
                    <p>An uncaught error has occurred.</p>
                )}
            </CardContent>
            <CardActions>
                <Button color="primary" variant="contained" onClick={() => setPromptState('ok')}>
                    Ok
                </Button>
            </CardActions>
        </Dialog>
    ) : null;
};

export const useOfflineSubmitNeeded = (taskId: string) => {
    const isReviewingOfflineWork = useSelector((state: RootState) => state.taskCurrentlyWritingToOffline === taskId);
    const online = !isOffline();
    return isReviewingOfflineWork && online;
};

export const useOfflineEntitySubmitsNeeded = (taskId: string) => {
    const hasOfflineSubmits = useHasOfflineEntitySubmits(taskId);
    const offlineSubmitNeeded = useOfflineSubmitNeeded(taskId);
    return hasOfflineSubmits && offlineSubmitNeeded;
};
const SaveOrDiscard = ({ taskId, processId }: { taskId: string; processId: string }) => {
    const submitNeeded = useOfflineSubmitNeeded(taskId);
    const isPristine = useIsPristine(taskId);
    if (!submitNeeded || isPristine) {
        return null;
    }
    return (
        <Alert severity="info">
            Your offline work will be saved when submitting the task using the outcomes below.
        </Alert>
    );
};

export default SaveOrDiscard;
