import produce from 'immer';
import ViewConfig, { View } from 'reducers/ViewConfigType';
import { viewToViewDef } from 'layout-editor/hooks/useSubmitView';

export const mergeViewIntoViewConfig = (
    view: View,
    viewConfig: ViewConfig,
    overwriteViewDef: boolean = false,
): ViewConfig => {
    const newViewConfig = produce(viewConfig, (draftState) => {
        draftState.views[view.name] = view;
        const overwritingViewDef = draftState.viewDefs[view.name] && overwriteViewDef;
        if (overwriteViewDef) {
            // delete draftState.viewDefs[view.name];
            const viewDef = viewToViewDef(view);
            draftState.viewDefs[view.name] = {
                entity: view.entity,
                name: view.name,
                definition: JSON.stringify(viewDef),
            };
        }
        draftState.previewMeta = {
            viewsChanged: {
                ...(viewConfig.previewMeta && viewConfig.previewMeta.viewsChanged),
                [view.name]: {
                    old:
                        viewConfig.previewMeta && viewConfig.previewMeta.viewsChanged[view.name]
                            ? viewConfig.previewMeta.viewsChanged[view.name].old
                            : viewConfig.views[view.name],
                    viewDef: overwritingViewDef,
                },
            },
        };
        return draftState;
    });
    return newViewConfig;
};
