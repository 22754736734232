import { useCallback, useState } from 'react';
import buildFetchOptions from 'sagas/util/buildFetchOptions';
import { BACKEND_BASE_URL } from 'config';
import { storageController } from 'storage';
import FileSaver from 'file-saver';
import fromEntries from 'util/fromentries';

export type SubmissionState =
    | {
          type: 'NONE';
      }
    | {
          type: 'SUBMITTING';
      }
    | {
          type: 'SUCCESS';
          fileType: string;
      }
    | {
          type: 'FAILED';
          err: Error;
      };

const getReportUrl = (reportDefinitionId, reportType) =>
    `${BACKEND_BASE_URL}api/reports/${reportDefinitionId}?file-type=${reportType}`;
const getFileName = (reportDefinitionName, reportType) => `${reportDefinitionName}.${reportType.toLowerCase()}`;

const downloadReport = (args: {
    formData: string;
    reportDefinitionId: string;
    fileName: string;
    fileType: string;
    onSuccess: () => void;
    onError: (error: Error) => void;
}) => {
    const { formData, reportDefinitionId, fileName, fileType, onSuccess, onError } = args;
    const options = buildFetchOptions() as RequestInit;
    options.method = 'POST';
    options.body = formData;

    const token = storageController.getToken();
    if (token) {
        if (options.headers?.constructor?.name === 'Headers') {
            (options.headers as Headers).set('Authorization', `Bearer ${token}`);
            (options.headers as Headers).set('Content-Type', 'application/json');
        } else {
            options.headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
        }
    }
    const url = getReportUrl(reportDefinitionId, fileType);
    let fileNameFromContentDisposition;
    return fetch(url, options)
        .then((response) => {
            const disposition = response.headers.get('content-disposition');
            if (disposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    fileNameFromContentDisposition = matches[1].replace(/['"]/g, '');
                }
            }

            if (response.status === 202 /* Created (long running) */) {
                onSuccess();
                return null;
            }
            if (response.status >= 200 && response.status < 300) {
                return response.blob();
            }
            if (response.status) {
                throw new Error(`Request failed with with status ${response.status}`);
            }
            throw new Error(`Request failed.`);
        })
        .then((blob) => {
            if (blob && blob.size > 0) {
                if (fileNameFromContentDisposition) {
                    FileSaver.saveAs(blob, fileNameFromContentDisposition);
                } else {
                    FileSaver.saveAs(blob, fileName);
                }
            }
            onSuccess();
        })
        .catch((e) => {
            onError(e);
        });
};

const useSubmit = (args: { reportDefinitionId: string; reportDefinitionName: string }) => {
    const { reportDefinitionId, reportDefinitionName } = args;
    const [state, setState] = useState<SubmissionState>({ type: 'NONE' });
    const submit = useCallback(
        (fileType: string, values: {}) => {
            const formData = JSON.stringify(fromEntries(Object.entries(values).filter(([k, v]) => v !== null)));
            setState({ type: 'SUBMITTING' });
            downloadReport({
                formData,
                reportDefinitionId,
                fileName: getFileName(reportDefinitionName, fileType),
                fileType,
                onSuccess: () => setState({ type: 'SUCCESS', fileType }),
                onError: (err) => setState({ type: 'FAILED', err }),
            });
        },
        [setState, reportDefinitionId, reportDefinitionName],
    );
    return [state, submit] as const;
};
export default useSubmit;
