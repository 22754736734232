import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import Banner from './Banner';

interface BannerSpacerProps {}
const BannerSpacer: React.SFC<BannerSpacerProps> = (props) => {
    const isOnline = useSelector((state: RootState) => state.connectivityStatus);
    return (
        <div style={{ visibility: 'hidden' }}>
            <Banner isOnline={isOnline} />
        </div>
    );
};
export default BannerSpacer;
