import React, { FunctionComponent, Suspense } from 'react';
import { Theme, createStyles, CircularProgress, makeStyles } from '@material-ui/core';
import AttemptRequest from 'components/AttemptRequest';
import { API_URL } from 'config';
import { ReportDefinition } from 'dashboard2/dashboard-config/types';
import buildHeaders from 'sideEffect/buildHeaders';

const Content = React.lazy(() => import('./Content'));
const LazyContent = (props: { data: {}[]; definition: ReportDefinition }) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Content {...props} />
        </Suspense>
    );
};

const getLazyR = (reportName: string, parameters?: {} | string) => () => {
    const actualParameters =
        typeof parameters === 'string'
            ? (() => {
                  if (!parameters) {
                      return undefined;
                  }
                  try {
                      return JSON.parse(parameters);
                  } catch (e) {
                      console.error(e);
                      return undefined;
                  }
              })()
            : parameters;
    return fetch(`${API_URL}reports?name=${reportName}&file-type=JSON&from-dashboard=true`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
        body: actualParameters && JSON.stringify(actualParameters),
    });
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chartHolder: {
            position: 'relative',
            height: 'calc(100% - 55px)',
        },
    }),
);

interface ReportWidgetProps {
    definition: ReportDefinition;
}

const ReportWidget: FunctionComponent<ReportWidgetProps> = ({ definition }) => {
    const classes = useStyles();
    const renderData = (data?: {}[]) => {
        if (data && data.length > 0) {
            return (
                <div className={classes.chartHolder}>
                    <LazyContent data={data} definition={definition} />
                </div>
            );
        }

        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'rgba(0,0,0,0.6)',
                }}
            >
                <span style={{ flexShrink: 0 }}>No data available</span>
            </div>
        );
    };
    return (
        <AttemptRequest<{ results: {}[] } | undefined>
            type="external"
            lazyRequest={getLazyR(definition.reportName, definition.parameters)}
            requestOnMount={true}
            renderer={({ attemptAction }) =>
                (requestState) => {
                    switch (requestState._tag) {
                        case 'failure': {
                            return (
                                <div>
                                    Request failed.
                                    <button aria-label="Retry?" onClick={attemptAction}>
                                        Retry?
                                    </button>
                                </div>
                            );
                        }
                        case 'unsubmitted':
                        case 'pending': {
                            return (
                                <div style={{ height: '100%', position: 'relative' }}>
                                    <div
                                        style={{
                                            margin: 0,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <CircularProgress />
                                    </div>
                                </div>
                            );
                        }
                        case 'success': {
                            return renderData(requestState.data && requestState.data.results);
                        }
                    }
                }}
        />
    );
};

export default ReportWidget;
