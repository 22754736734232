import React, { useMemo } from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { getConceptDisplayById, isConceptActiveById } from '../../../components/generics/utils/valueSetsUtil';
import { useIntl } from 'react-intl';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';

const ValueSetFieldComponent = ({
    valueSets,
    valueSetCode,
    addLabel = true,
    elStyle,
    label,
    record,
    source,
    conceptDisplay,
    active,
}) => {
    const intl = useIntl();
    const displayText = useMemo(() => {
        return conceptDisplay && evaluateFormattedText(intl, conceptDisplay);
    }, [conceptDisplay, intl]);
    return active ? displayText : <div style={{ color: 'grey' }}> {displayText}</div>;
};

const mapStateToProps = (state, { record, source }) => ({
    conceptDisplay: getConceptDisplayById(get(record, source), state.admin.entities),
    active: isConceptActiveById(get(record, source), state.admin.entities),
});
const ValueSetField = compose(
    connect(mapStateToProps, { crudGetOne: crudGetOneAction }),
    lifecycle({
        componentDidMount() {
            if (this.props.fetchOwnData) {
                this.getConcept();
            }
        },
        componentWillReceiveProps(nextProps) {
            if (
                this.props.fetchOwnData &&
                get(this.props.record, this.props.source) !== get(nextProps.record, nextProps.source)
            ) {
                this.getConcept(nextProps);
            }
        },
        getConcept(props = this.props) {
            if (props.record && props.source) {
                const conceptId = get(props.record, props.source);
                if (conceptId) {
                    props.crudGetOne({
                        resource: 'Concept',
                        id: conceptId,
                        view: null,
                    });
                }
            }
        },
    }),
    (BaseComponent) => (props) => <BaseComponent {...props} />,
)(ValueSetFieldComponent);
export default ValueSetField;
