import * as update from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap } from 'rxjs/operators';
import crudCreateUpdateGetFlow from 'sideEffect/crud/util/epics/CoreCrud/createUpdate';
import recurseReplaceEmptyStringsWithNull from '../util/replaceEmptyStringsWithNull';
import getInsertIdsAndVersionNumbers from 'util/insertIdsAndVersionNumbers/v2';
import { viewConfigSelector } from 'util/hooks/useViewConfig';

const isObject = (value) => !!value && value.constructor === Object;
const recurseStripAmpIds = (value) => {
    if (isObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value)
                .filter((key) => key !== '@id' && key !== '@ref')
                .map((key) => ({
                    [key]: recurseStripAmpIds(value[key]),
                })),
        );
    }
    if (Array.isArray(value)) {
        return value.map((o) => recurseStripAmpIds(o));
    }
    return value;
};

const crudCreateFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(update.crudUpdate)),
        withLatestFrom(
            state$.pipe(map((state) => viewConfigSelector(state))),
            state$.pipe(map((state) => state.admin.entities)),
        ),
        flatMap(([action, viewConfig, entities]) => {
            const {
                resource,
                data,
                cb,
                restUrl: _restUrl,
                errorsCbs,
                previousData,
                visibleAndEditableFields,
            } = action.payload;
            const restUrl = _restUrl || viewConfig.entities[resource].restUrl;

            let adjustedData: null | {} = null;
            if (data && previousData) {
                adjustedData = getInsertIdsAndVersionNumbers(viewConfig, entities)(
                    previousData,
                    data,
                    resource,
                    visibleAndEditableFields,
                );
            }
            return crudCreateUpdateGetFlow(
                {
                    data: recurseReplaceEmptyStringsWithNull(recurseStripAmpIds(adjustedData || data)),
                    restUrl,
                },
                {
                    service: services.crudUpdate,
                    failureAction: update.crudUpdateFailure,
                    successAction: update.crudUpdateSuccess,
                    successCb: cb,
                    errorsCbs,
                },
                {
                    resource,
                    viewConfig,
                    initialRequestPayload: action.payload,
                    restUrl,
                },
            );
        }),
    );
export default crudCreateFlow;
