import { push } from 'connected-react-router';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseModelerUrlSelector } from 'util/applicationConfig';
import { Dialog, Button } from '@material-ui/core';
import { AuthForm } from 'bpm-modeler/Auth';
import { ajax, AjaxError } from 'rxjs/ajax';

const OpenInEditorButton: React.FC<{
    taskId: string;
    taskKey: string;
    processId: string;
}> = ({ taskId, taskKey, processId }) => {
    const dispatch = useDispatch();
    const modelerUrl = useSelector(getBaseModelerUrlSelector);
    const [promptForCreds, setPromptForCreds] = useState(false);
    const onOpen = useCallback(
        (overrideModelerUrl?: string) => {
            if (!modelerUrl && !overrideModelerUrl) {
                setPromptForCreds(true);
            }
            let root = overrideModelerUrl ?? modelerUrl;
            if (!root) {
                return;
            }
            // right now we fetch all of them, even if there are a lot.
            // because we can't search by 'key' as far as I'm aware.
            // (we need to get from 'key' to 'id')

            const $ajax = ajax({
                url:
                    root +
                    '/modeler/app/rest/models?filter=forms&modelType=2&sort=modifiedDesc' /* &filterText=' + taskKey */,
                method: 'GET',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'json',
            });
            const subscription = $ajax.subscribe(
                (res) => {
                    const response: {
                        size: number;
                        start: number;
                        total: number;
                        data: {
                            id: string;
                            key: string;
                            name: string;
                        }[];
                    } = res.response;
                    const taskForm = response?.data.find((form) => form.key === taskKey);
                    if (taskForm) {
                        const id = taskForm.id;
                        setImmediate(() => {
                            dispatch(push('/bpm-modeler/form/' + id + '?processId=' + processId + '&taskId=' + taskId));
                        });
                    }
                },
                (error: AjaxError) => {
                    console.error(error);
                    setImmediate(() => {
                        setPromptForCreds(true);
                    });
                },
            );
        },
        [setPromptForCreds, dispatch, modelerUrl, processId, taskId, taskKey],
    );
    return (
        <>
            {promptForCreds ? (
                <Dialog open={true} onClose={() => setPromptForCreds(false)}>
                    <AuthForm onSuccess={onOpen} />
                </Dialog>
            ) : null}
            <Button onClick={() => onOpen()}>Open in editor</Button>
        </>
    );
};

export default OpenInEditorButton;
