import isPlainObject from 'lodash/isPlainObject';
import { TaskForm } from '../../../reducers/taskFormType';

const del = (obj: unknown, path: string) => {
    if (isPlainObject(obj)) {
        if (!path.includes('.') && obj[path]) {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => key !== path));
        }
        if (path.includes('.')) {
            const [nextStep, ...rest] = path.split('.');
            const remainder = rest.join('.');
            return Object.fromEntries(
                Object.entries(obj).map(([k, v]) => {
                    if (k === nextStep) {
                        return [k, del(v, remainder)];
                    }
                    return [k, v];
                }),
            );
        }
    }
    if (Array.isArray(obj)) {
        return obj.map((e) => del(e, path));
    }
    return obj;
};

const removeAttributesFromTaskFrom = (taskForm: TaskForm, attributes: string[]): TaskForm => {
    return attributes.reduce((prev, curr) => {
        return del(prev, curr);
    }, taskForm);
};
export default removeAttributesFromTaskFrom;
