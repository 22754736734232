import React from 'react';
import memoizeOne from 'memoize-one';
import { formContext as taskFormContext } from '../../../bpm/components/TaskDetail/TaskForm/FormContext';
import { formContext as entityFormContext } from 'components/generics/form/EntityFormContext';
import { tableRowContext, getTableRowContext } from '../components/EditableTable/util/tableRowContext';
import { evaluateContext2 } from 'expressions/CachingEvaluator/FormContextEvaluator';
export type Input = any;

export const withNullFilteredRefoneOverride = (from: 'Flowable' | 'Entity') => (BaseComponent) => {
    return class WithNullFilteredRefoneOverride extends React.Component<{ input: Input; source: string }> {
        emptyArray = [];
        getInput = memoizeOne((overrideValue) => {
            return {
                ...this.props.input,
                value: overrideValue,
            };
        });
        render() {
            const props = this.props;
            const formContext = from === 'Flowable' ? taskFormContext : entityFormContext;
            return (
                <tableRowContext.Consumer>
                    {(c) => {
                        return (
                            <formContext.Consumer>
                                {(fc) => {
                                    const isNulled = (() => {
                                        if (c) {
                                            const currentTableRowContext = getTableRowContext(
                                                c,
                                                fc as ReturnType<typeof evaluateContext2>,
                                            );
                                            return (
                                                currentTableRowContext &&
                                                currentTableRowContext.nullFilteredRefOneFields.includes(props.source)
                                            );
                                        }
                                        return fc.nullFilteredRefOneFields.includes(props.source);
                                    })();
                                    const newInputProp = isNulled ? this.getInput(null) : props.input;
                                    return <BaseComponent {...props} input={newInputProp} key={isNulled ? '1' : '2'} />;
                                }}
                            </formContext.Consumer>
                        );
                    }}
                </tableRowContext.Consumer>
            );
        }
    };
};
