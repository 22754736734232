import jwt_decode from 'jwt-decode';
import { storageController } from 'storage';

const getBranchFromJwt = () => {
    const token = storageController.getToken();
    if (!token) {
        return null;
    }
    const decoded = jwt_decode(token) as {
        casetivityConfigBranch?: string;
    };
    return decoded.casetivityConfigBranch ?? null;
};
export default getBranchFromJwt;
