/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['-333fdfa8'] = validate10;
const schema11 = {
    type: 'object',
    title: 'Report',
    properties: {
        type: {
            enum: ['line', 'pie', 'stackedBar', 'singleValue', 'linktable', 'bar'],
            title: 'Type',
            type: 'string',
            enumNames: null,
        },
    },
    dependencies: {
        type: {
            oneOf: [
                {
                    properties: {
                        type: { enum: ['linktable'] },
                        totalConfig: {
                            title: 'Totals Configuration',
                            type: 'object',
                            properties: {
                                isRowSum: { title: 'Is Row Sum', type: 'boolean' },
                                isColumnSum: { title: 'Is Column Sum', type: 'boolean' },
                                fieldsToSum: { title: 'Fields To Sum', type: 'string' },
                            },
                            dependencies: {
                                isRowSum: {
                                    properties: { rowTotalsName: { title: 'Row Totals Name', type: 'string' } },
                                    required: [],
                                },
                                isColumnSum: {
                                    properties: { columnTotalsName: { title: 'Column Totals Name', type: 'string' } },
                                    required: [],
                                },
                            },
                            required: [],
                        },
                        wantColumnHeaders: { title: 'Want Column Headers', type: 'boolean' },
                        isFromFlowable: { title: 'Is From Flowable', type: 'boolean' },
                        isTask: { title: 'Is Task', type: 'boolean' },
                        fields: { title: 'Fields', type: 'string' },
                        noDrillDown: { title: 'No Drilldown', type: 'boolean' },
                        navTo: { title: 'Navigate to', type: 'string' },
                        reportName: { title: 'Report Name', type: 'string' },
                        parameters: {
                            title: 'Parameters',
                            type: 'string',
                            description: 'JSON object of parameters to pass in to the report',
                        },
                        titleUrl: { title: 'Title URL', type: 'string' },
                        prefetchValuesets: { items: { type: 'string' }, title: 'Prefetch Valuesets', type: 'array' },
                    },
                    required: [],
                },
                {
                    properties: {
                        type: { enum: ['bar'] },
                        wantColumnHeaders: { title: 'Want Column Headers', type: 'boolean' },
                        isFromFlowable: { title: 'Is From Flowable', type: 'boolean' },
                        backgroundColor: {
                            title: 'Background Color',
                            type: 'string',
                            description: 'e.g. rgba(255,99,132,0.2)',
                        },
                        borderColor: { title: 'Border Color', type: 'string', description: 'e.g. rgba(255,99,132,1)' },
                        borderWidth: { title: 'Border Width', type: 'integer', description: 'e.g. 1, 2' },
                        hoverBackgroundColor: {
                            title: 'Hover Background Color',
                            type: 'string',
                            description: 'e.g. rgba(255,99,132,0.4)',
                        },
                        hoverBorderColor: {
                            title: 'Hover Border Color',
                            type: 'string',
                            description: 'rgba(255,99,132,1)',
                        },
                        parameters: {
                            title: 'Parameters',
                            type: 'string',
                            description: 'JSON object of parameters to pass in to the report',
                        },
                        navTo: { title: 'Navigate to', type: 'string' },
                        reportName: { title: 'Report Name', type: 'string' },
                        prefetchValuesets: { items: { type: 'string' }, title: 'Prefetch Valuesets', type: 'array' },
                        titleUrl: { title: 'Title URL', type: 'string' },
                        label: { title: 'Data Label', type: 'string' },
                    },
                    required: [],
                },
                {
                    properties: {
                        type: { enum: ['line', 'singleValue'] },
                        parameters: {
                            title: 'Parameters',
                            type: 'string',
                            description: 'JSON object of parameters to pass in to the report',
                        },
                        navTo: { title: 'Navigate to', type: 'string' },
                        reportName: { title: 'Report Name', type: 'string' },
                        titleUrl: { title: 'Title URL', type: 'string' },
                        prefetchValuesets: { items: { type: 'string' }, title: 'Prefetch Valuesets', type: 'array' },
                    },
                    required: [],
                },
                {
                    properties: {
                        type: { enum: ['pie', 'stackedBar'] },
                        colors: { items: { type: 'string' }, title: 'Colors', type: 'array' },
                        parameters: {
                            title: 'Parameters',
                            type: 'string',
                            description: 'JSON object of parameters to pass in to the report',
                        },
                        navTo: { title: 'Navigate to', type: 'string' },
                        reportName: { title: 'Report Name', type: 'string' },
                        titleUrl: { title: 'Title URL', type: 'string' },
                        prefetchValuesets: { items: { type: 'string' }, title: 'Prefetch Valuesets', type: 'array' },
                    },
                    required: [],
                },
            ],
        },
    },
    required: [],
    $id: '-333fdfa8',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="-333fdfa8" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.type !== undefined) {
            const _errs2 = errors;
            let valid1 = false;
            let passing0 = null;
            const _errs3 = errors;
            if (data && typeof data == 'object' && !Array.isArray(data)) {
                if (data.type !== undefined) {
                    let data0 = data.type;
                    if (!(data0 === 'linktable')) {
                        const err0 = {
                            instancePath: instancePath + '/type',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/type/enum',
                            keyword: 'enum',
                            params: { allowedValues: schema11.dependencies.type.oneOf[0].properties.type.enum },
                            message: 'must be equal to one of the allowed values',
                            schema: schema11.dependencies.type.oneOf[0].properties.type.enum,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.type,
                            data: data0,
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
                if (data.totalConfig !== undefined) {
                    let data1 = data.totalConfig;
                    if (data1 && typeof data1 == 'object' && !Array.isArray(data1)) {
                        if (data1.isRowSum !== undefined) {
                            if (data1 && typeof data1 == 'object' && !Array.isArray(data1)) {
                                if (data1.rowTotalsName !== undefined) {
                                    let data2 = data1.rowTotalsName;
                                    if (typeof data2 !== 'string') {
                                        const err1 = {
                                            instancePath: instancePath + '/totalConfig/rowTotalsName',
                                            schemaPath:
                                                '#/dependencies/type/oneOf/0/properties/totalConfig/dependencies/isRowSum/properties/rowTotalsName/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                            schema: schema11.dependencies.type.oneOf[0].properties.totalConfig
                                                .dependencies.isRowSum.properties.rowTotalsName.type,
                                            parentSchema:
                                                schema11.dependencies.type.oneOf[0].properties.totalConfig.dependencies
                                                    .isRowSum.properties.rowTotalsName,
                                            data: data2,
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err1];
                                        } else {
                                            vErrors.push(err1);
                                        }
                                        errors++;
                                    }
                                }
                            }
                        }
                        if (data1.isColumnSum !== undefined) {
                            if (data1 && typeof data1 == 'object' && !Array.isArray(data1)) {
                                if (data1.columnTotalsName !== undefined) {
                                    let data3 = data1.columnTotalsName;
                                    if (typeof data3 !== 'string') {
                                        const err2 = {
                                            instancePath: instancePath + '/totalConfig/columnTotalsName',
                                            schemaPath:
                                                '#/dependencies/type/oneOf/0/properties/totalConfig/dependencies/isColumnSum/properties/columnTotalsName/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                            schema: schema11.dependencies.type.oneOf[0].properties.totalConfig
                                                .dependencies.isColumnSum.properties.columnTotalsName.type,
                                            parentSchema:
                                                schema11.dependencies.type.oneOf[0].properties.totalConfig.dependencies
                                                    .isColumnSum.properties.columnTotalsName,
                                            data: data3,
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err2];
                                        } else {
                                            vErrors.push(err2);
                                        }
                                        errors++;
                                    }
                                }
                            }
                        }
                        if (data1.isRowSum !== undefined) {
                            let data4 = data1.isRowSum;
                            if (typeof data4 !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/totalConfig/isRowSum',
                                    schemaPath:
                                        '#/dependencies/type/oneOf/0/properties/totalConfig/properties/isRowSum/type',
                                    keyword: 'type',
                                    params: { type: 'boolean' },
                                    message: 'must be boolean',
                                    schema: schema11.dependencies.type.oneOf[0].properties.totalConfig.properties
                                        .isRowSum.type,
                                    parentSchema:
                                        schema11.dependencies.type.oneOf[0].properties.totalConfig.properties.isRowSum,
                                    data: data4,
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                        }
                        if (data1.isColumnSum !== undefined) {
                            let data5 = data1.isColumnSum;
                            if (typeof data5 !== 'boolean') {
                                const err4 = {
                                    instancePath: instancePath + '/totalConfig/isColumnSum',
                                    schemaPath:
                                        '#/dependencies/type/oneOf/0/properties/totalConfig/properties/isColumnSum/type',
                                    keyword: 'type',
                                    params: { type: 'boolean' },
                                    message: 'must be boolean',
                                    schema: schema11.dependencies.type.oneOf[0].properties.totalConfig.properties
                                        .isColumnSum.type,
                                    parentSchema:
                                        schema11.dependencies.type.oneOf[0].properties.totalConfig.properties
                                            .isColumnSum,
                                    data: data5,
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                        }
                        if (data1.fieldsToSum !== undefined) {
                            let data6 = data1.fieldsToSum;
                            if (typeof data6 !== 'string') {
                                const err5 = {
                                    instancePath: instancePath + '/totalConfig/fieldsToSum',
                                    schemaPath:
                                        '#/dependencies/type/oneOf/0/properties/totalConfig/properties/fieldsToSum/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[0].properties.totalConfig.properties
                                        .fieldsToSum.type,
                                    parentSchema:
                                        schema11.dependencies.type.oneOf[0].properties.totalConfig.properties
                                            .fieldsToSum,
                                    data: data6,
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                            }
                        }
                    } else {
                        const err6 = {
                            instancePath: instancePath + '/totalConfig',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/totalConfig/type',
                            keyword: 'type',
                            params: { type: 'object' },
                            message: 'must be object',
                            schema: schema11.dependencies.type.oneOf[0].properties.totalConfig.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.totalConfig,
                            data: data1,
                        };
                        if (vErrors === null) {
                            vErrors = [err6];
                        } else {
                            vErrors.push(err6);
                        }
                        errors++;
                    }
                }
                if (data.wantColumnHeaders !== undefined) {
                    let data7 = data.wantColumnHeaders;
                    if (typeof data7 !== 'boolean') {
                        const err7 = {
                            instancePath: instancePath + '/wantColumnHeaders',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/wantColumnHeaders/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[0].properties.wantColumnHeaders.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.wantColumnHeaders,
                            data: data7,
                        };
                        if (vErrors === null) {
                            vErrors = [err7];
                        } else {
                            vErrors.push(err7);
                        }
                        errors++;
                    }
                }
                if (data.isFromFlowable !== undefined) {
                    let data8 = data.isFromFlowable;
                    if (typeof data8 !== 'boolean') {
                        const err8 = {
                            instancePath: instancePath + '/isFromFlowable',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/isFromFlowable/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[0].properties.isFromFlowable.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.isFromFlowable,
                            data: data8,
                        };
                        if (vErrors === null) {
                            vErrors = [err8];
                        } else {
                            vErrors.push(err8);
                        }
                        errors++;
                    }
                }
                if (data.isTask !== undefined) {
                    let data9 = data.isTask;
                    if (typeof data9 !== 'boolean') {
                        const err9 = {
                            instancePath: instancePath + '/isTask',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/isTask/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[0].properties.isTask.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.isTask,
                            data: data9,
                        };
                        if (vErrors === null) {
                            vErrors = [err9];
                        } else {
                            vErrors.push(err9);
                        }
                        errors++;
                    }
                }
                if (data.fields !== undefined) {
                    let data10 = data.fields;
                    if (typeof data10 !== 'string') {
                        const err10 = {
                            instancePath: instancePath + '/fields',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/fields/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[0].properties.fields.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.fields,
                            data: data10,
                        };
                        if (vErrors === null) {
                            vErrors = [err10];
                        } else {
                            vErrors.push(err10);
                        }
                        errors++;
                    }
                }
                if (data.noDrillDown !== undefined) {
                    let data11 = data.noDrillDown;
                    if (typeof data11 !== 'boolean') {
                        const err11 = {
                            instancePath: instancePath + '/noDrillDown',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/noDrillDown/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[0].properties.noDrillDown.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.noDrillDown,
                            data: data11,
                        };
                        if (vErrors === null) {
                            vErrors = [err11];
                        } else {
                            vErrors.push(err11);
                        }
                        errors++;
                    }
                }
                if (data.navTo !== undefined) {
                    let data12 = data.navTo;
                    if (typeof data12 !== 'string') {
                        const err12 = {
                            instancePath: instancePath + '/navTo',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/navTo/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[0].properties.navTo.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.navTo,
                            data: data12,
                        };
                        if (vErrors === null) {
                            vErrors = [err12];
                        } else {
                            vErrors.push(err12);
                        }
                        errors++;
                    }
                }
                if (data.reportName !== undefined) {
                    let data13 = data.reportName;
                    if (typeof data13 !== 'string') {
                        const err13 = {
                            instancePath: instancePath + '/reportName',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/reportName/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[0].properties.reportName.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.reportName,
                            data: data13,
                        };
                        if (vErrors === null) {
                            vErrors = [err13];
                        } else {
                            vErrors.push(err13);
                        }
                        errors++;
                    }
                }
                if (data.parameters !== undefined) {
                    let data14 = data.parameters;
                    if (typeof data14 !== 'string') {
                        const err14 = {
                            instancePath: instancePath + '/parameters',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/parameters/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[0].properties.parameters.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.parameters,
                            data: data14,
                        };
                        if (vErrors === null) {
                            vErrors = [err14];
                        } else {
                            vErrors.push(err14);
                        }
                        errors++;
                    }
                }
                if (data.titleUrl !== undefined) {
                    let data15 = data.titleUrl;
                    if (typeof data15 !== 'string') {
                        const err15 = {
                            instancePath: instancePath + '/titleUrl',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/titleUrl/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[0].properties.titleUrl.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.titleUrl,
                            data: data15,
                        };
                        if (vErrors === null) {
                            vErrors = [err15];
                        } else {
                            vErrors.push(err15);
                        }
                        errors++;
                    }
                }
                if (data.prefetchValuesets !== undefined) {
                    let data16 = data.prefetchValuesets;
                    if (Array.isArray(data16)) {
                        const len0 = data16.length;
                        for (let i0 = 0; i0 < len0; i0++) {
                            let data17 = data16[i0];
                            if (typeof data17 !== 'string') {
                                const err16 = {
                                    instancePath: instancePath + '/prefetchValuesets/' + i0,
                                    schemaPath: '#/dependencies/type/oneOf/0/properties/prefetchValuesets/items/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[0].properties.prefetchValuesets.items.type,
                                    parentSchema:
                                        schema11.dependencies.type.oneOf[0].properties.prefetchValuesets.items,
                                    data: data17,
                                };
                                if (vErrors === null) {
                                    vErrors = [err16];
                                } else {
                                    vErrors.push(err16);
                                }
                                errors++;
                            }
                        }
                    } else {
                        const err17 = {
                            instancePath: instancePath + '/prefetchValuesets',
                            schemaPath: '#/dependencies/type/oneOf/0/properties/prefetchValuesets/type',
                            keyword: 'type',
                            params: { type: 'array' },
                            message: 'must be array',
                            schema: schema11.dependencies.type.oneOf[0].properties.prefetchValuesets.type,
                            parentSchema: schema11.dependencies.type.oneOf[0].properties.prefetchValuesets,
                            data: data16,
                        };
                        if (vErrors === null) {
                            vErrors = [err17];
                        } else {
                            vErrors.push(err17);
                        }
                        errors++;
                    }
                }
            }
            var _valid0 = _errs3 === errors;
            if (_valid0) {
                valid1 = true;
                passing0 = 0;
            }
            const _errs41 = errors;
            if (data && typeof data == 'object' && !Array.isArray(data)) {
                if (data.type !== undefined) {
                    let data18 = data.type;
                    if (!(data18 === 'bar')) {
                        const err18 = {
                            instancePath: instancePath + '/type',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/type/enum',
                            keyword: 'enum',
                            params: { allowedValues: schema11.dependencies.type.oneOf[1].properties.type.enum },
                            message: 'must be equal to one of the allowed values',
                            schema: schema11.dependencies.type.oneOf[1].properties.type.enum,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.type,
                            data: data18,
                        };
                        if (vErrors === null) {
                            vErrors = [err18];
                        } else {
                            vErrors.push(err18);
                        }
                        errors++;
                    }
                }
                if (data.wantColumnHeaders !== undefined) {
                    let data19 = data.wantColumnHeaders;
                    if (typeof data19 !== 'boolean') {
                        const err19 = {
                            instancePath: instancePath + '/wantColumnHeaders',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/wantColumnHeaders/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[1].properties.wantColumnHeaders.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.wantColumnHeaders,
                            data: data19,
                        };
                        if (vErrors === null) {
                            vErrors = [err19];
                        } else {
                            vErrors.push(err19);
                        }
                        errors++;
                    }
                }
                if (data.isFromFlowable !== undefined) {
                    let data20 = data.isFromFlowable;
                    if (typeof data20 !== 'boolean') {
                        const err20 = {
                            instancePath: instancePath + '/isFromFlowable',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/isFromFlowable/type',
                            keyword: 'type',
                            params: { type: 'boolean' },
                            message: 'must be boolean',
                            schema: schema11.dependencies.type.oneOf[1].properties.isFromFlowable.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.isFromFlowable,
                            data: data20,
                        };
                        if (vErrors === null) {
                            vErrors = [err20];
                        } else {
                            vErrors.push(err20);
                        }
                        errors++;
                    }
                }
                if (data.backgroundColor !== undefined) {
                    let data21 = data.backgroundColor;
                    if (typeof data21 !== 'string') {
                        const err21 = {
                            instancePath: instancePath + '/backgroundColor',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/backgroundColor/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.backgroundColor.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.backgroundColor,
                            data: data21,
                        };
                        if (vErrors === null) {
                            vErrors = [err21];
                        } else {
                            vErrors.push(err21);
                        }
                        errors++;
                    }
                }
                if (data.borderColor !== undefined) {
                    let data22 = data.borderColor;
                    if (typeof data22 !== 'string') {
                        const err22 = {
                            instancePath: instancePath + '/borderColor',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/borderColor/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.borderColor.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.borderColor,
                            data: data22,
                        };
                        if (vErrors === null) {
                            vErrors = [err22];
                        } else {
                            vErrors.push(err22);
                        }
                        errors++;
                    }
                }
                if (data.borderWidth !== undefined) {
                    let data23 = data.borderWidth;
                    if (!(typeof data23 == 'number' && !(data23 % 1) && !isNaN(data23))) {
                        const err23 = {
                            instancePath: instancePath + '/borderWidth',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/borderWidth/type',
                            keyword: 'type',
                            params: { type: 'integer' },
                            message: 'must be integer',
                            schema: schema11.dependencies.type.oneOf[1].properties.borderWidth.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.borderWidth,
                            data: data23,
                        };
                        if (vErrors === null) {
                            vErrors = [err23];
                        } else {
                            vErrors.push(err23);
                        }
                        errors++;
                    }
                }
                if (data.hoverBackgroundColor !== undefined) {
                    let data24 = data.hoverBackgroundColor;
                    if (typeof data24 !== 'string') {
                        const err24 = {
                            instancePath: instancePath + '/hoverBackgroundColor',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/hoverBackgroundColor/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.hoverBackgroundColor.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.hoverBackgroundColor,
                            data: data24,
                        };
                        if (vErrors === null) {
                            vErrors = [err24];
                        } else {
                            vErrors.push(err24);
                        }
                        errors++;
                    }
                }
                if (data.hoverBorderColor !== undefined) {
                    let data25 = data.hoverBorderColor;
                    if (typeof data25 !== 'string') {
                        const err25 = {
                            instancePath: instancePath + '/hoverBorderColor',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/hoverBorderColor/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.hoverBorderColor.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.hoverBorderColor,
                            data: data25,
                        };
                        if (vErrors === null) {
                            vErrors = [err25];
                        } else {
                            vErrors.push(err25);
                        }
                        errors++;
                    }
                }
                if (data.parameters !== undefined) {
                    let data26 = data.parameters;
                    if (typeof data26 !== 'string') {
                        const err26 = {
                            instancePath: instancePath + '/parameters',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/parameters/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.parameters.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.parameters,
                            data: data26,
                        };
                        if (vErrors === null) {
                            vErrors = [err26];
                        } else {
                            vErrors.push(err26);
                        }
                        errors++;
                    }
                }
                if (data.navTo !== undefined) {
                    let data27 = data.navTo;
                    if (typeof data27 !== 'string') {
                        const err27 = {
                            instancePath: instancePath + '/navTo',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/navTo/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.navTo.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.navTo,
                            data: data27,
                        };
                        if (vErrors === null) {
                            vErrors = [err27];
                        } else {
                            vErrors.push(err27);
                        }
                        errors++;
                    }
                }
                if (data.reportName !== undefined) {
                    let data28 = data.reportName;
                    if (typeof data28 !== 'string') {
                        const err28 = {
                            instancePath: instancePath + '/reportName',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/reportName/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.reportName.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.reportName,
                            data: data28,
                        };
                        if (vErrors === null) {
                            vErrors = [err28];
                        } else {
                            vErrors.push(err28);
                        }
                        errors++;
                    }
                }
                if (data.prefetchValuesets !== undefined) {
                    let data29 = data.prefetchValuesets;
                    if (Array.isArray(data29)) {
                        const len1 = data29.length;
                        for (let i1 = 0; i1 < len1; i1++) {
                            let data30 = data29[i1];
                            if (typeof data30 !== 'string') {
                                const err29 = {
                                    instancePath: instancePath + '/prefetchValuesets/' + i1,
                                    schemaPath: '#/dependencies/type/oneOf/1/properties/prefetchValuesets/items/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[1].properties.prefetchValuesets.items.type,
                                    parentSchema:
                                        schema11.dependencies.type.oneOf[1].properties.prefetchValuesets.items,
                                    data: data30,
                                };
                                if (vErrors === null) {
                                    vErrors = [err29];
                                } else {
                                    vErrors.push(err29);
                                }
                                errors++;
                            }
                        }
                    } else {
                        const err30 = {
                            instancePath: instancePath + '/prefetchValuesets',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/prefetchValuesets/type',
                            keyword: 'type',
                            params: { type: 'array' },
                            message: 'must be array',
                            schema: schema11.dependencies.type.oneOf[1].properties.prefetchValuesets.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.prefetchValuesets,
                            data: data29,
                        };
                        if (vErrors === null) {
                            vErrors = [err30];
                        } else {
                            vErrors.push(err30);
                        }
                        errors++;
                    }
                }
                if (data.titleUrl !== undefined) {
                    let data31 = data.titleUrl;
                    if (typeof data31 !== 'string') {
                        const err31 = {
                            instancePath: instancePath + '/titleUrl',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/titleUrl/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.titleUrl.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.titleUrl,
                            data: data31,
                        };
                        if (vErrors === null) {
                            vErrors = [err31];
                        } else {
                            vErrors.push(err31);
                        }
                        errors++;
                    }
                }
                if (data.label !== undefined) {
                    let data32 = data.label;
                    if (typeof data32 !== 'string') {
                        const err32 = {
                            instancePath: instancePath + '/label',
                            schemaPath: '#/dependencies/type/oneOf/1/properties/label/type',
                            keyword: 'type',
                            params: { type: 'string' },
                            message: 'must be string',
                            schema: schema11.dependencies.type.oneOf[1].properties.label.type,
                            parentSchema: schema11.dependencies.type.oneOf[1].properties.label,
                            data: data32,
                        };
                        if (vErrors === null) {
                            vErrors = [err32];
                        } else {
                            vErrors.push(err32);
                        }
                        errors++;
                    }
                }
            }
            var _valid0 = _errs41 === errors;
            if (_valid0 && valid1) {
                valid1 = false;
                passing0 = [passing0, 1];
            } else {
                if (_valid0) {
                    valid1 = true;
                    passing0 = 1;
                }
                const _errs71 = errors;
                if (data && typeof data == 'object' && !Array.isArray(data)) {
                    if (data.type !== undefined) {
                        let data33 = data.type;
                        if (!(data33 === 'line' || data33 === 'singleValue')) {
                            const err33 = {
                                instancePath: instancePath + '/type',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/type/enum',
                                keyword: 'enum',
                                params: { allowedValues: schema11.dependencies.type.oneOf[2].properties.type.enum },
                                message: 'must be equal to one of the allowed values',
                                schema: schema11.dependencies.type.oneOf[2].properties.type.enum,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.type,
                                data: data33,
                            };
                            if (vErrors === null) {
                                vErrors = [err33];
                            } else {
                                vErrors.push(err33);
                            }
                            errors++;
                        }
                    }
                    if (data.parameters !== undefined) {
                        let data34 = data.parameters;
                        if (typeof data34 !== 'string') {
                            const err34 = {
                                instancePath: instancePath + '/parameters',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/parameters/type',
                                keyword: 'type',
                                params: { type: 'string' },
                                message: 'must be string',
                                schema: schema11.dependencies.type.oneOf[2].properties.parameters.type,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.parameters,
                                data: data34,
                            };
                            if (vErrors === null) {
                                vErrors = [err34];
                            } else {
                                vErrors.push(err34);
                            }
                            errors++;
                        }
                    }
                    if (data.navTo !== undefined) {
                        let data35 = data.navTo;
                        if (typeof data35 !== 'string') {
                            const err35 = {
                                instancePath: instancePath + '/navTo',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/navTo/type',
                                keyword: 'type',
                                params: { type: 'string' },
                                message: 'must be string',
                                schema: schema11.dependencies.type.oneOf[2].properties.navTo.type,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.navTo,
                                data: data35,
                            };
                            if (vErrors === null) {
                                vErrors = [err35];
                            } else {
                                vErrors.push(err35);
                            }
                            errors++;
                        }
                    }
                    if (data.reportName !== undefined) {
                        let data36 = data.reportName;
                        if (typeof data36 !== 'string') {
                            const err36 = {
                                instancePath: instancePath + '/reportName',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/reportName/type',
                                keyword: 'type',
                                params: { type: 'string' },
                                message: 'must be string',
                                schema: schema11.dependencies.type.oneOf[2].properties.reportName.type,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.reportName,
                                data: data36,
                            };
                            if (vErrors === null) {
                                vErrors = [err36];
                            } else {
                                vErrors.push(err36);
                            }
                            errors++;
                        }
                    }
                    if (data.titleUrl !== undefined) {
                        let data37 = data.titleUrl;
                        if (typeof data37 !== 'string') {
                            const err37 = {
                                instancePath: instancePath + '/titleUrl',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/titleUrl/type',
                                keyword: 'type',
                                params: { type: 'string' },
                                message: 'must be string',
                                schema: schema11.dependencies.type.oneOf[2].properties.titleUrl.type,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.titleUrl,
                                data: data37,
                            };
                            if (vErrors === null) {
                                vErrors = [err37];
                            } else {
                                vErrors.push(err37);
                            }
                            errors++;
                        }
                    }
                    if (data.prefetchValuesets !== undefined) {
                        let data38 = data.prefetchValuesets;
                        if (Array.isArray(data38)) {
                            const len2 = data38.length;
                            for (let i2 = 0; i2 < len2; i2++) {
                                let data39 = data38[i2];
                                if (typeof data39 !== 'string') {
                                    const err38 = {
                                        instancePath: instancePath + '/prefetchValuesets/' + i2,
                                        schemaPath:
                                            '#/dependencies/type/oneOf/2/properties/prefetchValuesets/items/type',
                                        keyword: 'type',
                                        params: { type: 'string' },
                                        message: 'must be string',
                                        schema: schema11.dependencies.type.oneOf[2].properties.prefetchValuesets.items
                                            .type,
                                        parentSchema:
                                            schema11.dependencies.type.oneOf[2].properties.prefetchValuesets.items,
                                        data: data39,
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err38];
                                    } else {
                                        vErrors.push(err38);
                                    }
                                    errors++;
                                }
                            }
                        } else {
                            const err39 = {
                                instancePath: instancePath + '/prefetchValuesets',
                                schemaPath: '#/dependencies/type/oneOf/2/properties/prefetchValuesets/type',
                                keyword: 'type',
                                params: { type: 'array' },
                                message: 'must be array',
                                schema: schema11.dependencies.type.oneOf[2].properties.prefetchValuesets.type,
                                parentSchema: schema11.dependencies.type.oneOf[2].properties.prefetchValuesets,
                                data: data38,
                            };
                            if (vErrors === null) {
                                vErrors = [err39];
                            } else {
                                vErrors.push(err39);
                            }
                            errors++;
                        }
                    }
                }
                var _valid0 = _errs71 === errors;
                if (_valid0 && valid1) {
                    valid1 = false;
                    passing0 = [passing0, 2];
                } else {
                    if (_valid0) {
                        valid1 = true;
                        passing0 = 2;
                    }
                    const _errs85 = errors;
                    if (data && typeof data == 'object' && !Array.isArray(data)) {
                        if (data.type !== undefined) {
                            let data40 = data.type;
                            if (!(data40 === 'pie' || data40 === 'stackedBar')) {
                                const err40 = {
                                    instancePath: instancePath + '/type',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/type/enum',
                                    keyword: 'enum',
                                    params: { allowedValues: schema11.dependencies.type.oneOf[3].properties.type.enum },
                                    message: 'must be equal to one of the allowed values',
                                    schema: schema11.dependencies.type.oneOf[3].properties.type.enum,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.type,
                                    data: data40,
                                };
                                if (vErrors === null) {
                                    vErrors = [err40];
                                } else {
                                    vErrors.push(err40);
                                }
                                errors++;
                            }
                        }
                        if (data.colors !== undefined) {
                            let data41 = data.colors;
                            if (Array.isArray(data41)) {
                                const len3 = data41.length;
                                for (let i3 = 0; i3 < len3; i3++) {
                                    let data42 = data41[i3];
                                    if (typeof data42 !== 'string') {
                                        const err41 = {
                                            instancePath: instancePath + '/colors/' + i3,
                                            schemaPath: '#/dependencies/type/oneOf/3/properties/colors/items/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                            schema: schema11.dependencies.type.oneOf[3].properties.colors.items.type,
                                            parentSchema: schema11.dependencies.type.oneOf[3].properties.colors.items,
                                            data: data42,
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err41];
                                        } else {
                                            vErrors.push(err41);
                                        }
                                        errors++;
                                    }
                                }
                            } else {
                                const err42 = {
                                    instancePath: instancePath + '/colors',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/colors/type',
                                    keyword: 'type',
                                    params: { type: 'array' },
                                    message: 'must be array',
                                    schema: schema11.dependencies.type.oneOf[3].properties.colors.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.colors,
                                    data: data41,
                                };
                                if (vErrors === null) {
                                    vErrors = [err42];
                                } else {
                                    vErrors.push(err42);
                                }
                                errors++;
                            }
                        }
                        if (data.parameters !== undefined) {
                            let data43 = data.parameters;
                            if (typeof data43 !== 'string') {
                                const err43 = {
                                    instancePath: instancePath + '/parameters',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/parameters/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[3].properties.parameters.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.parameters,
                                    data: data43,
                                };
                                if (vErrors === null) {
                                    vErrors = [err43];
                                } else {
                                    vErrors.push(err43);
                                }
                                errors++;
                            }
                        }
                        if (data.navTo !== undefined) {
                            let data44 = data.navTo;
                            if (typeof data44 !== 'string') {
                                const err44 = {
                                    instancePath: instancePath + '/navTo',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/navTo/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[3].properties.navTo.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.navTo,
                                    data: data44,
                                };
                                if (vErrors === null) {
                                    vErrors = [err44];
                                } else {
                                    vErrors.push(err44);
                                }
                                errors++;
                            }
                        }
                        if (data.reportName !== undefined) {
                            let data45 = data.reportName;
                            if (typeof data45 !== 'string') {
                                const err45 = {
                                    instancePath: instancePath + '/reportName',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/reportName/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[3].properties.reportName.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.reportName,
                                    data: data45,
                                };
                                if (vErrors === null) {
                                    vErrors = [err45];
                                } else {
                                    vErrors.push(err45);
                                }
                                errors++;
                            }
                        }
                        if (data.titleUrl !== undefined) {
                            let data46 = data.titleUrl;
                            if (typeof data46 !== 'string') {
                                const err46 = {
                                    instancePath: instancePath + '/titleUrl',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/titleUrl/type',
                                    keyword: 'type',
                                    params: { type: 'string' },
                                    message: 'must be string',
                                    schema: schema11.dependencies.type.oneOf[3].properties.titleUrl.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.titleUrl,
                                    data: data46,
                                };
                                if (vErrors === null) {
                                    vErrors = [err46];
                                } else {
                                    vErrors.push(err46);
                                }
                                errors++;
                            }
                        }
                        if (data.prefetchValuesets !== undefined) {
                            let data47 = data.prefetchValuesets;
                            if (Array.isArray(data47)) {
                                const len4 = data47.length;
                                for (let i4 = 0; i4 < len4; i4++) {
                                    let data48 = data47[i4];
                                    if (typeof data48 !== 'string') {
                                        const err47 = {
                                            instancePath: instancePath + '/prefetchValuesets/' + i4,
                                            schemaPath:
                                                '#/dependencies/type/oneOf/3/properties/prefetchValuesets/items/type',
                                            keyword: 'type',
                                            params: { type: 'string' },
                                            message: 'must be string',
                                            schema: schema11.dependencies.type.oneOf[3].properties.prefetchValuesets
                                                .items.type,
                                            parentSchema:
                                                schema11.dependencies.type.oneOf[3].properties.prefetchValuesets.items,
                                            data: data48,
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err47];
                                        } else {
                                            vErrors.push(err47);
                                        }
                                        errors++;
                                    }
                                }
                            } else {
                                const err48 = {
                                    instancePath: instancePath + '/prefetchValuesets',
                                    schemaPath: '#/dependencies/type/oneOf/3/properties/prefetchValuesets/type',
                                    keyword: 'type',
                                    params: { type: 'array' },
                                    message: 'must be array',
                                    schema: schema11.dependencies.type.oneOf[3].properties.prefetchValuesets.type,
                                    parentSchema: schema11.dependencies.type.oneOf[3].properties.prefetchValuesets,
                                    data: data47,
                                };
                                if (vErrors === null) {
                                    vErrors = [err48];
                                } else {
                                    vErrors.push(err48);
                                }
                                errors++;
                            }
                        }
                    }
                    var _valid0 = _errs85 === errors;
                    if (_valid0 && valid1) {
                        valid1 = false;
                        passing0 = [passing0, 3];
                    } else {
                        if (_valid0) {
                            valid1 = true;
                            passing0 = 3;
                        }
                    }
                }
            }
            if (!valid1) {
                const err49 = {
                    instancePath,
                    schemaPath: '#/dependencies/type/oneOf',
                    keyword: 'oneOf',
                    params: { passingSchemas: passing0 },
                    message: 'must match exactly one schema in oneOf',
                    schema: schema11.dependencies.type.oneOf,
                    parentSchema: schema11.dependencies.type,
                    data,
                };
                if (vErrors === null) {
                    vErrors = [err49];
                } else {
                    vErrors.push(err49);
                }
                errors++;
            } else {
                errors = _errs2;
                if (vErrors !== null) {
                    if (_errs2) {
                        vErrors.length = _errs2;
                    } else {
                        vErrors = null;
                    }
                }
            }
        }
        if (data.type !== undefined) {
            let data49 = data.type;
            if (typeof data49 !== 'string') {
                const err50 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/type',
                    keyword: 'type',
                    params: { type: 'string' },
                    message: 'must be string',
                    schema: schema11.properties.type.type,
                    parentSchema: schema11.properties.type,
                    data: data49,
                };
                if (vErrors === null) {
                    vErrors = [err50];
                } else {
                    vErrors.push(err50);
                }
                errors++;
            }
            if (
                !(
                    data49 === 'line' ||
                    data49 === 'pie' ||
                    data49 === 'stackedBar' ||
                    data49 === 'singleValue' ||
                    data49 === 'linktable' ||
                    data49 === 'bar'
                )
            ) {
                const err51 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema11.properties.type.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema11.properties.type.enum,
                    parentSchema: schema11.properties.type,
                    data: data49,
                };
                if (vErrors === null) {
                    vErrors = [err51];
                } else {
                    vErrors.push(err51);
                }
                errors++;
            }
        }
    } else {
        const err52 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err52];
        } else {
            vErrors.push(err52);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}

exports['8e41c97'] = validate11;
const schema12 = { type: 'object', properties: { type: { enum: ['linktable'] } }, $id: '8e41c97' };

function validate11(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="8e41c97" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.type !== undefined) {
            let data0 = data.type;
            if (!(data0 === 'linktable')) {
                const err0 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema12.properties.type.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema12.properties.type.enum,
                    parentSchema: schema12.properties.type,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema12.type,
            parentSchema: schema12,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate11.errors = vErrors;
    return errors === 0;
}

exports['-53558488'] = validate12;
const schema13 = { type: 'object', properties: { type: { enum: ['bar'] } }, $id: '-53558488' };

function validate12(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="-53558488" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.type !== undefined) {
            let data0 = data.type;
            if (!(data0 === 'bar')) {
                const err0 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema13.properties.type.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema13.properties.type.enum,
                    parentSchema: schema13.properties.type,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema13.type,
            parentSchema: schema13,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate12.errors = vErrors;
    return errors === 0;
}

exports['74e838c2'] = validate13;
const schema14 = { type: 'object', properties: { type: { enum: ['line', 'singleValue'] } }, $id: '74e838c2' };

function validate13(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="74e838c2" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.type !== undefined) {
            let data0 = data.type;
            if (!(data0 === 'line' || data0 === 'singleValue')) {
                const err0 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema14.properties.type.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema14.properties.type.enum,
                    parentSchema: schema14.properties.type,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema14.type,
            parentSchema: schema14,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate13.errors = vErrors;
    return errors === 0;
}

exports['119d4e8f'] = validate14;
const schema15 = { type: 'object', properties: { type: { enum: ['pie', 'stackedBar'] } }, $id: '119d4e8f' };

function validate14(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="119d4e8f" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.type !== undefined) {
            let data0 = data.type;
            if (!(data0 === 'pie' || data0 === 'stackedBar')) {
                const err0 = {
                    instancePath: instancePath + '/type',
                    schemaPath: '#/properties/type/enum',
                    keyword: 'enum',
                    params: { allowedValues: schema15.properties.type.enum },
                    message: 'must be equal to one of the allowed values',
                    schema: schema15.properties.type.enum,
                    parentSchema: schema15.properties.type,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
    } else {
        const err1 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema15.type,
            parentSchema: schema15,
            data,
        };
        if (vErrors === null) {
            vErrors = [err1];
        } else {
            vErrors.push(err1);
        }
        errors++;
    }
    validate14.errors = vErrors;
    return errors === 0;
}
