import ITask from '../../../../../types/task';
import { ProcessInstanceFromAppTasks } from './processInstance';
import moment from 'moment';

export interface TaskFromAppTasks {
    id: string;
    assignee?: string | null;
    taskKey: string;
    formKey?: string;
    name: string;
    outcome: string;
    priority: number;
    description?: string;
    processInstanceId?: string;
    processInstance?: ProcessInstanceFromAppTasks;
    startTime: string | null;
    endTime?: string;
    dueDate?: string;
    readOnly: boolean;
    taskDefinitionKey?: string;
    linkedEntityId?: string;
    linkedEntityType?: string;
    linkedEntity?: {};
    taskPermissions: {
        canClaimTask: boolean;
        canAssignTask: boolean;
        potentialUsers: {
            id: string;
            login: string;
            title: string;
        }[];
    };
    schedDate?: string;
    schedDuration?: number;
    attendeesIds?: string[];
}

export const mapToAppType = ({
    id,
    assignee,
    formKey,
    name,
    outcome,
    dueDate,
    description,
    priority,
    startTime,
    endTime,
    processInstanceId,
    linkedEntityId,
    linkedEntityType,
    linkedEntity,
    taskPermissions,
    readOnly,
    schedDate,
    schedDuration,
    attendeesIds,
    taskKey,
}: TaskFromAppTasks): ITask => ({
    id,
    name,
    outcome,
    priority,
    assignee: assignee || null,
    dueDate: dueDate || undefined,
    description,
    formKey,
    taskKey,
    processInstanceId,
    created: startTime || null,
    endDate: endTime || undefined,
    linkedEntityId,
    linkedEntityType,
    linkedEntity,
    taskPermissions,
    readOnly,
    schedDate: schedDate || undefined,
    schedDuration,
    attendeesIds,
});

export const mapToApiType = ({
    endDate,
    formKey,
    created,
    comments,
    dueDate,
    schedDate,
    ...rest
}: Partial<ITask>): Partial<TaskFromAppTasks> => ({
    dueDate: dueDate && moment(dueDate).format('YYYY-MM-DD'),
    startTime: created && moment(created).format('YYYY-MM-DD'),
    endTime: endDate && moment(endDate).format('YYYY-MM-DD'),
    schedDate: schedDate && moment(schedDate).toISOString(false),
    ...rest,
});
