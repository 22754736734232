import { getType } from 'typesafe-actions';
import * as viewConfig from 'viewConfig/actions';
import { RootAction } from 'actions/rootAction';

export default (previousState = false, action: RootAction) => {
    switch (action.type) {
        case getType(viewConfig.loading):
            return true;
        case getType(viewConfig.loadFailure):
        case getType(viewConfig.loadSuccess):
            return false;
        default:
            return previousState;
    }
};
