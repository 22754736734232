import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { impersonateUser, impersonateUserFailure, impersonateUserSuccess, clearImpersonation } from './actions';
import { getImpersonating } from './storage';

type ImpersonatingState =
    | {
          type: 'none';
      }
    | {
          type: 'pending';
          userId: string;
      }
    | {
          type: 'error';
          userId: string;
          error: {
              status: number;
          };
      }
    | {
          type: 'success';
          userId: string;
          previousUserTitle: string;
          currentLocation: string;
      };
const getInitialState = (): ImpersonatingState => {
    const impersonating = getImpersonating();
    if (impersonating) {
        return {
            type: 'success',
            ...impersonating,
        } as const;
    }
    return { type: 'none' } as const;
};
const impersonatingReducer = (
    state: ImpersonatingState = getInitialState(),
    action: RootAction,
): ImpersonatingState => {
    switch (action.type) {
        case getType(impersonateUser): {
            return {
                type: 'pending',
                userId: action.payload.userId,
            };
        }
        case getType(impersonateUserFailure): {
            return {
                type: 'error',
                userId: action.requestPayload.userId,
                error: action.error,
            };
        }
        case getType(impersonateUserSuccess): {
            return {
                type: 'success',
                userId: action.payload.userId,
                previousUserTitle: action.payload.previousUserTitle,
                currentLocation: action.payload.currentLocation,
            };
        }
        case getType(clearImpersonation): {
            return {
                type: 'none',
            };
        }
        default:
            return state;
    }
};
export default impersonatingReducer;
