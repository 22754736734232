import { ExpressionEvaluatorContext } from 'expressions/Provider/expressionEvaluatorContext';
import { tryCatch } from 'fp-ts/lib/Either';
import { convertFieldsRequiredToUseableFields } from 'clients/utils/getFieldsRequiredForExpression';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import { getEvaluator, parse } from 'ts-spel';
import flatten from 'flat';
import getFieldPathsFromTsSpelAST from 'ace-editor/util/getFieldPathsFromTsSpelAST';
import getRootMethodAndFunctionNames from 'expressions/getMethodsAndFunctions/v2';
import getValuesetCodeLiterals from 'expressions/getFieldsInAst/getValuesetCodeLiterals/v2';
import { Ast } from 'ts-spel/lib/lib/Ast';
import prettyPrint from 'ts-spel/lib/lib/prettyPrint';

import rebaseProperties from 'ace-editor/util/rebaseProperties';

const compiledExpressionUtils = (
    ast: Ast,
): ReturnType<ExpressionEvaluatorContext['compileExpression']> & { type: 'parse_success' } => {
    // 'expansions' is what is needed to fetch data, e.g. for foo.?[#this.bar] this is foo.bar
    // but 'paths' is what is needed to initialize or traverse data: above would be foo._ALL_.bar
    const getExpansionsWithAll = () => {
        const expansionTree = getFieldPathsFromTsSpelAST(ast);
        const flattened = flatten(expansionTree);
        return Object.keys(flattened);
    };
    const getPathsWithAll = () => {
        const expansionTree = getFieldPathsFromTsSpelAST(ast, true);
        const flattened = flatten(expansionTree);
        return Object.keys(flattened);
    };
    const getExpansions = () => convertFieldsRequiredToUseableFields(getExpansionsWithAll(), true);
    const getExpansionsWithoutArrayDescendants = () => convertFieldsRequiredToUseableFields(getPathsWithAll(), true);
    const methodsAndFunctions = Array.from(getRootMethodAndFunctionNames(ast));
    const getValueSetLiterals = () => {
        return Array.from(getValuesetCodeLiterals(ast));
    };
    return {
        type: 'parse_success',
        evaluate: (context, functionsAndVariables, options = { disableBoolOpChecks: true }) => {
            try {
                const result = getEvaluator(context, functionsAndVariables, options)(ast);
                return {
                    type: 'evaluation_success',
                    result,
                };
            } catch (e) {
                console.error(e);
                return {
                    type: 'evaluation_failure',
                    msg: e.message,
                };
            }
        },
        getExpansions,
        getPathsWithAll,
        getExpansionsWithoutArrayDescendants,
        getExpansionsWithAll,
        methodsAndFunctions,
        getValueSetLiterals,
        rebase: (mapping) => {
            const newAst = rebaseProperties(ast, mapping);
            return compiledExpressionUtils(newAst);
        },
        prettyPrint: () => prettyPrint(ast),
    };
};

const tsImpl: ExpressionEvaluatorContext = {
    compileExpression: (expression: string) => {
        try {
            const ast = parse(expression);
            return compiledExpressionUtils(ast);
        } catch (e) {
            // lets send stack to console so it's usable for debugging.
            console.error(e);
            return {
                type: 'parse_failure',
                i: 0,
                msg: e.message,
            };
        }
    },
    evaluateTemplate: (template, sanitizeResult) => {
        return (context, functionsAndVariables) =>
            tryCatch(() =>
                evaluateFilterString(
                    template,
                    {
                        ...functionsAndVariables,
                        ...context,
                    },
                    sanitizeResult,
                ),
            ).mapLeft((e) => [e.message]);
    },
};

export default tsImpl;
