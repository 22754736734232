import React, { useMemo, FunctionComponent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@material-ui/core';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import MergeViewRowsController from 'layout-editor/mergeview-builder/MergeViewEditor';
import NavigateNext from '@material-ui/icons/NavigateNext';

interface Step2MergeProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step2Merge: FunctionComponent<Step2MergeProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
    });
    const { handleSubmit, register, unregister, watch } = methods;
    const view = watch() as View;
    React.useEffect(() => {
        register({ name: 'fields' });
        return () => {
            unregister('fields');
        };
    }, []); // eslint-disable-line
    const onSubmit = (data) => {
        _onSubmit({ type: 'write', payload: { ...initialValues, ...data } });
    };
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        return produce(viewConfig, (draft) => {
            draft.views[initialValues.name] = {
                ...initialValues,
                ...view,
            };
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    if (!initialValues.viewType || !initialValues.entity || !initialValues.name) {
        return <div>Please fill out step 1.</div>;
    }
    if (initialValues.viewType !== 'MERGE') {
        return <div>Wrong View Type {initialValues.viewType}</div>;
    }
    return (
        <div style={{ padding: '1em', margin: '1em' }}>
            <h2>Step 2: Edit Columns</h2>
            <FormProvider {...methods}>
                <div>
                    <NavWarning />
                    <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                        <MergeViewRowsController rootEntity={initialValues.entity} />
                    </OverriddenViewConfigContext.Provider>
                    <div style={{ marginTop: '1em', paddingTop: '1em', textAlign: 'right' }}>
                        <Button id="step2-submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </div>
    );
};

export default Step2Merge;
