import { setLocale } from 'i18n/store/actions';
import { Locale } from 'i18n/store/domain';
import { useAvailableLocales, useLocale } from 'i18n/useMessages';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStorage } from 'storage/storage';

const languages = require('language-list')();

const SelectLocale = () => {
    const dispatch = useDispatch();
    const currentLocale = useLocale();
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            dispatch(setLocale(event.target.value as Locale));
        },
        [dispatch],
    );
    useEffect(() => {
        getStorage().setItem('locale', currentLocale);
    }, [currentLocale]);

    const allLocales = useAvailableLocales();
    return (
        <select className="casetivity-selectlocale" aria-label="language" value={currentLocale} onChange={handleChange}>
            {allLocales.map((localeKey) => (
                <option value={localeKey} key={localeKey}>
                    {languages.getLanguageName(localeKey)}
                </option>
            ))}
        </select>
    );
};
export default SelectLocale;
