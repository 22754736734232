import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import getImpl from 'expressions/Provider/implementations/getImpl';

const evaluateExpression = (expression: string, context: {}, functionsAndVariables?: {}) => {
    const impl = getImpl();
    const compiled = impl.compileExpression(expression);
    if (compiled.type === 'parse_failure') {
        throw new Error(compiled.msg);
    }
    const filledRootContext = createRootContext(context);
    const result = compiled.evaluate(filledRootContext, functionsAndVariables);
    if (result.type === 'evaluation_failure') {
        throw new Error(result.msg);
    }
    return result.result;
};
export default evaluateExpression;
