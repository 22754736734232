import React from 'react';
import { useSelector } from 'react-redux';
import { getUseOldManyWidgetImplSelector } from 'util/applicationConfig';
import VS, { valueSetsManyHoc } from './ValuesetManySelectDownshift';
import VS2 from './VS2';
export { valueSetsManyHoc };

const VSX = (props) => {
    const useOld = useSelector(getUseOldManyWidgetImplSelector);
    if (useOld) {
        return <VS {...props} />;
    }
    return <VS2 {...props} />;
};
export default VSX;
