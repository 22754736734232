import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button } from '@material-ui/core';
import { DebouncedField } from 'fieldFactory/input/components/DebouncedTextInput';
import useViewConfig from 'util/hooks/useViewConfig';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import {
    useValidationResolver,
    useStyles,
    UpdateMeta,
} from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';

/*
    expression
    fieldsRequired
    compileSucess
*/

interface ExpressionData {
    expression: string;
    message: string;
    fieldsRequired?: string[];
    methodsAndFunctions: string[];
    compileSuccess?: boolean;
}

interface EditExpressionProps {
    viewName?: string;
    initialValues?: Partial<ExpressionData>;
    onSubmit: (data: ExpressionData) => void;
}
const EditExpression: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();

    const expressionResolver = useValidationResolver(viewName, viewConfig);
    const resolver = useCallback(
        (data) => {
            return expressionResolver(data).then((res) => {
                if (!data.message) {
                    res.errors['message'] = 'A message is required';
                }
                return res;
            });
        },
        [expressionResolver],
    );
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: props.initialValues,
        mode: 'onChange',
    });
    const { errors } = methods;
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Controller
                    rules={{ required: 'Provide an expression' }}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    variant={fieldVariant}
                    label="Expression *"
                    as={DebouncedField}
                    margin="normal"
                    error={
                        (Array.isArray(errors.expression)
                            ? errors.expression.length > 0
                            : Boolean(errors.expression)) ||
                        (Array.isArray(errors.fieldsRequired)
                            ? errors.fieldsRequired.length > 0
                            : Boolean(errors.fieldsRequired)) ||
                        (Array.isArray(errors.methodsAndFunctions)
                            ? errors.methodsAndFunctions.length > 0
                            : Boolean(errors.methodsAndFunctions))
                    }
                    helperText={<ErrorMessage errors={errors} name="expression" />}
                    defaultValue={props.initialValues && props.initialValues['expression']}
                    fullWidth
                    name="expression"
                    control={methods.control as any}
                />
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <Controller
                    rules={{ required: 'Provide a message' }}
                    InputLabelProps={getInputLabelProps({ shrink: true })}
                    variant={fieldVariant}
                    label="Message *"
                    as={DebouncedField}
                    margin="normal"
                    error={Array.isArray(errors.message) ? errors.message.length > 0 : Boolean(errors.message)}
                    helperText={<ErrorMessage errors={errors} name="message" />}
                    defaultValue={props.initialValues && props.initialValues['message']}
                    fullWidth
                    name="message"
                    control={methods.control as any}
                />
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </FormProvider>
    );
};
export default EditExpression;
