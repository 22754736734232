import React, { FunctionComponent, useState, useRef, useCallback, useMemo } from 'react';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import { MenuItem } from '@material-ui/core';
import focusNextSiblingElement from 'util/focusNextSiblingElement';
import uniqueId from 'lodash/uniqueId';
import PopupState, { bindHover } from 'material-ui-popup-state';
import { CascadingMenu } from './mui-popup-state';

type MenuItemProps = React.ComponentProps<typeof MenuItem>;
interface NestedMenuItemProps {
    label: string; // The MenuItem text content
    mainMenuOpen: boolean; // The same variable assigned to main menu 'open' prop
    expandIcon?: JSX.Element; // usually left or right arrow icon
    highlightColor?: string; // highlight background color when item is focused
    left?: boolean | 'auto'; // expand nested menu to the left?
    MenuItemProps?: MenuItemProps;
    onTab?: () => void;
    onClick?: () => void;
}

const getExpandIconSize = (MenuItemProps?: MenuItemProps) => (MenuItemProps?.dense ? 'small' : 'medium');

const NestedMenuItem: FunctionComponent<NestedMenuItemProps> = (props) => {
    const subMenuRef = useRef<HTMLDivElement>(null);
    const nestedMenuRef = useRef<HTMLLIElement>(null);
    const { onClick, highlightColor = '#dddddd', MenuItemProps, label, mainMenuOpen, onTab } = props;

    const [left, setLeft] = useState(props.left === 'auto' ? false : props.left);
    React.useEffect(() => {
        if (props.left === 'auto') {
            const to = setTimeout(() => {
                const rect = nestedMenuRef.current?.getBoundingClientRect();
                if (rect && rect.right && rect.right + rect.width > window.innerWidth - 75) {
                    setLeft(true);
                } else setLeft(false);
            });
            return () => clearTimeout(to);
        }
    }, [props.left]);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const expandIconSize = getExpandIconSize(MenuItemProps);
    const {
        expandIcon = left ? (
            <ArrowLeft fontSize={expandIconSize} />
        ) : (
            <ArrowRight style={{ right: 0, position: 'absolute' }} fontSize={expandIconSize} />
        ),
    } = props;
    const isSubmenuFocused = useCallback(() => {
        const active = nestedMenuRef.current?.ownerDocument?.activeElement;
        for (const child of subMenuRef.current?.children ?? []) {
            if (child === active) {
                return true;
            }
        }
        return false;
    }, []);

    // open the subMenu, and highlight ourselves
    const handleMouseEnter = useCallback(
        (e) => {
            e.stopPropagation();
            setSubMenuOpen(true);
            nestedMenuRef.current.style.backgroundColor = highlightColor;
        },
        [highlightColor, setSubMenuOpen],
    );
    // close our submenu and un-highlight ourselves
    const handleMouseLeave = useCallback(
        (e) => {
            setSubMenuOpen(false);
            nestedMenuRef.current.style.backgroundColor = 'white';
        },
        [setSubMenuOpen],
    );
    // toggle opening of our submenu
    const handleClick = useCallback(
        (e) => {
            onClick?.();
            e.stopPropagation();
            setSubMenuOpen(!subMenuOpen);
        },
        [setSubMenuOpen, subMenuOpen, onClick],
    );
    // open submenu on focus, and highlight ourselves
    const handleFocus = useCallback(
        (evt) => {
            if (evt.target === nestedMenuRef.current) {
                setSubMenuOpen(true);
                nestedMenuRef.current.style.backgroundColor = highlightColor;
            }
        },
        [highlightColor, setSubMenuOpen],
    );
    // do something with icons
    //
    // handle focus of elements through up and down arrows.
    // handle focusing (and highlighting) into child submenu using left and right arrows
    const handleKeyDown = useCallback(
        (evt) => {
            // check for Tab key
            if (evt.keyCode === 9) {
                // the menu will now close and the tab will take us to the root button.
                // However, we want to select the next item in the menu.
                // therefore, we will delay, and after control returns we will increment our focus to the next item.
                // (assuming focus returns to our parent trigger button/element)
                // console.log('tab handled in nestedmenuitem');
                // setSubMenuOpen(false);
                if (document.activeElement !== nestedMenuRef.current) {
                    onTab?.();
                    setTimeout(() => focusNextSiblingElement(), 10);
                }
                return;
            }
            // unsure about this
            const arrowRight = left ? 'ArrowLeft' : 'ArrowRight';
            const arrowLeft = left ? 'ArrowRight' : 'ArrowLeft';
            const length = subMenuRef.current?.children.length;
            if (length && length > 0) {
                // When keyboard nav goes out of bounds, wrap around the current menu
                // and prevent parent menu from receiving the key input
                if (evt.target === subMenuRef.current?.children[length - 1] && evt.key === 'ArrowDown') {
                    evt.stopPropagation();
                    (subMenuRef.current?.children[0] as HTMLElement)?.focus();
                } else if (evt.target === subMenuRef.current?.children[0] && evt.key === 'ArrowUp') {
                    evt.stopPropagation();
                    (subMenuRef.current?.children[length - 1] as HTMLElement)?.focus();
                } else if (isSubmenuFocused()) {
                    evt.stopPropagation();
                }
            }
            // Handle arrow key directions behaviour
            if (evt.key === arrowRight && !isSubmenuFocused()) {
                if (!subMenuOpen) {
                    setSubMenuOpen(true);
                }
                (subMenuRef.current?.children[0] as HTMLElement)?.focus();
                evt.stopPropagation();
            } else if ((evt.key === 'ArrowDown' || evt.key === 'ArrowUp') && evt.target === nestedMenuRef.current) {
                setSubMenuOpen(false);
                nestedMenuRef.current.style.backgroundColor = 'white';
            } else if (evt.key === arrowLeft) {
                nestedMenuRef.current?.focus();
                setSubMenuOpen(false);
            }
        },

        [left, isSubmenuFocused, setSubMenuOpen, subMenuOpen, onTab],
    );
    const popupId = useMemo(() => uniqueId('hovermenu-' + label), [label]);
    return (
        <>
            <PopupState variant="popover" popupId={popupId} disableAutoFocus>
                {(popupState) => (
                    <>
                        <MenuItem {...bindHover(popupState)}>
                            {' '}
                            {left ? expandIcon : null}
                            {label}
                            {!left ? expandIcon : null}
                        </MenuItem>
                        <CascadingMenu
                            popupState={popupState}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: left ? 'left' : 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: left ? 'right' : 'left',
                            }}
                        >
                            {props.children}
                        </CascadingMenu>
                    </>
                )}
            </PopupState>
        </>
    );
};
export default NestedMenuItem;
