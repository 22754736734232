import React, { FunctionComponent, useEffect, useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { ajax, AjaxError } from 'rxjs/ajax';
import { EntityBase, getUrl, getHeaders } from 'sideEffect/services';
import { push as pushAction } from 'connected-react-router';
import { useDispatch } from 'react-redux';

interface LinkToExampleViewProps {
    viewName: string;
}

type Going =
    | {
          type: 'initial';
      }
    | {
          type: 'failed';
          message: string;
      }
    | {
          type: 'success';
          id: string;
      }
    | {
          type: 'loading';
      };
const LinkToExampleView: FunctionComponent<LinkToExampleViewProps> = ({ viewName }) => {
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const view = viewConfig.views[viewName];
    const [going, setGoing] = useState<Going>({
        type: 'initial',
    });

    useEffect(() => {
        if (going.type === 'loading') {
            const $ajax = ajax.getJSON<EntityBase[]>(
                getUrl(`${viewConfig.entities[view.entity].restUrl}?size=${1}`),
                getHeaders(),
            );
            const subscription = $ajax.subscribe(
                (response) => {
                    if (response.length === 0) {
                        setGoing({
                            type: 'failed',
                            message: 'No example entities',
                        });
                    } else {
                        const [record] = response;
                        setGoing({
                            type: 'success',
                            id: record.id,
                        });
                    }
                },
                (error: AjaxError) => {
                    setGoing({
                        type: 'failed',
                        message: error.message,
                    });
                },
            );
            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [going, view, viewConfig]);

    useEffect(() => {
        if (going.type === 'success') {
            dispatch(pushAction((view.route.startsWith('/') ? view.route : `/${view.route}`).replace(':id', going.id)));
        }
    }, [going, dispatch, view]);
    return !view || view.viewType === 'MATCH' || view.viewType === 'MERGE' ? null : view.route &&
      view.route.includes(':id') ? ( // no good way of linking to these
        <button
            onClick={() =>
                setGoing({
                    type: 'loading',
                })
            }
        >
            Go to Example
        </button>
    ) : view.route ? (
        <button onClick={() => dispatch(pushAction(view.route.startsWith('/') ? view.route : `/${view.route}`))}>
            Go to View
        </button>
    ) : null;
};

export default LinkToExampleView;
