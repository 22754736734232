import { createCustomAction } from 'typesafe-actions';
import { IMPERSONATE_USER, CLEAR_IMPERSONATION, IMPERSONATE_USER_SUCCESS, IMPERSONATE_USER_FAILURE } from './constants';

export const impersonateUser = createCustomAction(IMPERSONATE_USER, (type) => {
    return (userId: string) => ({
        type,
        payload: {
            userId,
        },
    });
});

export const impersonateUserSuccess = createCustomAction(IMPERSONATE_USER_SUCCESS, (type) => {
    return (userId: string, previousUserTitle: string, currentLocation: string) => ({
        type,
        payload: {
            userId,
            previousUserTitle,
            currentLocation,
        },
    });
});

export const impersonateUserFailure = createCustomAction(IMPERSONATE_USER_FAILURE, (type) => {
    return (userId: string, status?: number) => ({
        type,
        requestPayload: {
            userId,
        },
        error: {
            status,
        },
    });
});

export const clearImpersonation = createCustomAction(CLEAR_IMPERSONATION, (type) => {
    return () => ({
        type,
    });
});
