import { useGetProcessConf } from './useGetProcessConfItem';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';

const useHasMinimalHeader = () => {
    const conf = useGetProcessConf();
    const res = useSingleKeyCachingExpression(
        conf.mapNullable((c) => c.minimalTaskHeader).toUndefined(),
        undefined,
        false,
    );
    return res;
};
export default useHasMinimalHeader;
