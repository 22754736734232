import { put } from 'redux-saga/effects';
import { taskEventType as taskEvent } from '../../actions/taskEvent';
import sagaFactory, { configType, HttpVerb } from '../../../sagas/util/sagaFactory';
import { getTaskForm } from 'bpm/task-form/actions';

const config: configType = {
    actionType: taskEvent.claim,
    verb: HttpVerb.PUT,
    url: (payload: string) => `api/bpm/tasks/${payload}/action/claim`,
    success: function* (payload: string, responseBody: {}) {
        yield put({ type: taskEvent.claimSuccess, payload });
        yield put({ type: taskEvent.get, payload }); // read in task again to get the assignee
        yield put(getTaskForm(payload));
    },
    failure: taskEvent.claimFailure,
};

const saga = sagaFactory(config);
export default saga;
