import { createCustomAction } from 'typesafe-actions';
import { CREATE_TASK, CREATE_TASK_FAILURE, CREATE_TASK_SUCCESS } from './constants';
import { ErrorsCbs } from 'sideEffect/crud/errorTypes';
import { AjaxError } from 'rxjs/ajax';
import { CreateTaskPayload, CreateTaskResponse } from './types';

export const createTask = createCustomAction(CREATE_TASK, (type) => {
    return (payload: CreateTaskPayload, successCb: (response: CreateTaskResponse) => void, errorsCbs: ErrorsCbs) => ({
        type,
        payload,
        successCb,
        errorsCbs,
    });
});

export const createTaskFailure = createCustomAction(CREATE_TASK_FAILURE, (type) => {
    return (error: AjaxError) => ({
        type,
        error,
    });
});

export const createTaskSuccess = createCustomAction(CREATE_TASK_SUCCESS, (type) => {
    return (response: CreateTaskResponse) => ({
        type,
        payload: response,
    });
});
