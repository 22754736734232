import React, { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import useLogout from 'auth/hooks/useLogout';
import { getLoginButtonSelector } from 'util/applicationConfig';
import { useSelector } from 'react-redux';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';

interface LoginButtonProps {}
const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
    const logoutFn = useLogout();
    const loginHtml = useSelector(getLoginButtonSelector);
    return loginHtml ? (
        <SafeHtmlAsReact html={loginHtml} />
    ) : (
        <Button variant="outlined" color="inherit" onClick={logoutFn}>
            Log in
        </Button>
    );
};
export default LoginButton;
