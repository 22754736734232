import { GET_START_FORM, GET_START_FORM_FAILURE, GET_START_FORM_SUCCESS } from './constants';
import { createCustomAction } from 'typesafe-actions';
import { TaskForm } from 'reducers/taskFormType';
import { AjaxError } from 'rxjs/ajax';

export const getStartForm = createCustomAction(
    GET_START_FORM,
    (type) => (processDefinitionId: string, taskFormKey?: string) => ({
        type,
        payload: {
            processDefinitionId,
            taskFormKey,
        },
    }),
);

export const getStartFormSuccess = createCustomAction(
    GET_START_FORM_SUCCESS,
    (type) => (requestPayload: ReturnType<typeof getStartForm>['payload'], form: TaskForm | null) => ({
        type,
        payload: {
            form,
        },
        requestPayload,
    }),
);
export const getStartFormFailure = createCustomAction(
    GET_START_FORM_FAILURE,
    (type) => (requestPayload: ReturnType<typeof getStartForm>['payload'], error: AjaxError) => ({
        type,
        error,
        requestPayload,
    }),
);
