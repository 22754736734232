import React from 'react';

export interface VideoWatchedProps {
    // video source
    src: string;
    // video mime type
    type?: string;

    labelledBy?: string;
    id?: string;
    record?: {};
    input: any;
    meta: any;
    ariaInputProps?: {};
    renderLabel?: boolean;
}
const VideoWatched: React.FunctionComponent<VideoWatchedProps> = ({ src, type = 'video/mp4', id, input }) => {
    const videoRef = React.useRef<HTMLVideoElement>();
    const isFinished = Boolean(input.value);
    const onBlur = input.onBlur;

    const onFinished = React.useCallback(() => {
        onBlur(true);
    }, [onBlur]);

    React.useEffect(() => {
        // Modified from https://januszhou.github.io/2017/03/21/Prevent-HTML5-Video-Seeking/
        const video = videoRef.current;
        // disable changing the playback rate
        (video as any).controlsList = 'noplaybackrate';
        // disable rate change in case they right click and select 'show all controls' which brings back the playback rate options.
        video.onratechange = function () {
            video.playbackRate = 1;
        };

        let supposedCurrentTime = 0;

        function handleTimeUpdate() {
            if (!video.seeking) {
                supposedCurrentTime = video.currentTime;
            }
        }
        // prevent user from seeking forward
        function handleSeeking() {
            // guard agains infinite recursion:
            // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
            const delta = video.currentTime - supposedCurrentTime;
            // delta = Math.abs(delta); // disable seeking previous content if you want
            if (delta > 0.01) {
                video.currentTime = supposedCurrentTime;
            }
        }
        function handleEnded() {
            supposedCurrentTime = 0;
            onFinished();
        }

        // if the video was finished, you can do anything you want.
        if (!isFinished) {
            video.addEventListener('timeupdate', handleTimeUpdate);
            video.addEventListener('seeking', handleSeeking);
            video.addEventListener('ended', handleEnded);
        }

        return () => {
            video.removeEventListener('timeupdate', handleTimeUpdate);
            video.removeEventListener('seeking', handleSeeking);
            video.removeEventListener('ended', handleEnded);
        };
    }, [onFinished, isFinished]);
    return (
        <div>
            <video ref={videoRef} id={id} controls playsInline style={{ width: '100%' }}>
                <source src={src} type={type} />
            </video>
        </div>
    );
};
export default VideoWatched;
