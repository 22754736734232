/* eslint-disable */
/* This is an autogenerated file from a corresponding react-jsonschema-form schema. See scripts/compileSchemas.js */
'use strict';
exports['-5860d8a8'] = validate10;
const schema11 = {
    type: 'object',
    title: 'My Active Tasks',
    properties: {
        useTaskListView: {
            title: 'Use Task List View',
            type: 'boolean',
            description: 'Render as the default task list table',
        },
        drillToMyTasks: {
            title: 'Title links to My Tasks',
            type: 'boolean',
            description: 'Navigate to My Task route on title click',
        },
    },
    dependencies: {},
    required: [],
    $id: '-5860d8a8',
};

function validate10(data, { instancePath = '', parentData, parentDataProperty, rootData = data } = {}) {
    /*# sourceURL="-5860d8a8" */ let vErrors = null;
    let errors = 0;
    if (data && typeof data == 'object' && !Array.isArray(data)) {
        if (data.useTaskListView !== undefined) {
            let data0 = data.useTaskListView;
            if (typeof data0 !== 'boolean') {
                const err0 = {
                    instancePath: instancePath + '/useTaskListView',
                    schemaPath: '#/properties/useTaskListView/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.useTaskListView.type,
                    parentSchema: schema11.properties.useTaskListView,
                    data: data0,
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            }
        }
        if (data.drillToMyTasks !== undefined) {
            let data1 = data.drillToMyTasks;
            if (typeof data1 !== 'boolean') {
                const err1 = {
                    instancePath: instancePath + '/drillToMyTasks',
                    schemaPath: '#/properties/drillToMyTasks/type',
                    keyword: 'type',
                    params: { type: 'boolean' },
                    message: 'must be boolean',
                    schema: schema11.properties.drillToMyTasks.type,
                    parentSchema: schema11.properties.drillToMyTasks,
                    data: data1,
                };
                if (vErrors === null) {
                    vErrors = [err1];
                } else {
                    vErrors.push(err1);
                }
                errors++;
            }
        }
    } else {
        const err2 = {
            instancePath,
            schemaPath: '#/type',
            keyword: 'type',
            params: { type: 'object' },
            message: 'must be object',
            schema: schema11.type,
            parentSchema: schema11,
            data,
        };
        if (vErrors === null) {
            vErrors = [err2];
        } else {
            vErrors.push(err2);
        }
        errors++;
    }
    validate10.errors = vErrors;
    return errors === 0;
}
