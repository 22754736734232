import React from 'react';

const CenterIcon: React.FunctionComponent<{ children: JSX.Element }> = ({ children }) => (
    <div
        style={{
            display: 'inline-flex',
            flex: '0 0 auto',
            textAlign: 'center',
            alignItems: 'center',
            verticalAlign: 'middle',
        }}
    >
        {children}
    </div>
);
export default CenterIcon;
