import { getLocation } from 'connected-react-router';
import React from 'react';
import { useSelector } from 'react-redux';

const HideOnRoute: React.FC<{
    routeRegex: string;
}> = (props) => {
    const location = useSelector(getLocation);
    const rx = new RegExp(props.routeRegex);
    if (rx.test(location.pathname)) {
        return null;
    }
    return <>{props.children}</>;
};

export default HideOnRoute;
