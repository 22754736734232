import { createCustomAction } from 'typesafe-actions';
import {
    GET_PRINT_TEMPLATE_BY_ENTITYCONFID,
    GET_PRINT_TEMPLATE_BY_ENTITYCONFID_FAILURE,
    GET_PRINT_TEMPLATE_BY_ENTITYCONFID_SUCCESS,
} from './constants';
import { PrintTemplate } from '../definitions';

export const getPrintTemplatesByEntityConfId = createCustomAction(GET_PRINT_TEMPLATE_BY_ENTITYCONFID, (type) => {
    return (entityConfId: string) => ({
        type,
        payload: {
            entityConfId,
        },
    });
});

export const getPrintTemplatesByEntityConfIdSuccess = createCustomAction(
    GET_PRINT_TEMPLATE_BY_ENTITYCONFID_SUCCESS,
    (type) => {
        return (
            data: PrintTemplate[],
            requestPayload: ReturnType<typeof getPrintTemplatesByEntityConfId>['payload'],
        ) => ({
            type,
            payload: data,
            requestPayload,
        });
    },
);

export const getPrintTemplatesByEntityConfIdFailure = createCustomAction(
    GET_PRINT_TEMPLATE_BY_ENTITYCONFID_FAILURE,
    (type) => {
        return (error: Error, requestPayload: ReturnType<typeof getPrintTemplatesByEntityConfId>['payload']) => ({
            type,
            error,
            requestPayload,
        });
    },
);
