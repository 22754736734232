import { RootState } from '../../../reducers/rootReducer';
import { Concept } from 'fieldFactory/input/components/Concept';
import { fromNullable } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';
import uniq from 'lodash/uniq';

const emptyArr = [];
export const getConceptsByValueSet = (valueSet?: { conceptIds: string[] }, concepts?: { [key: string]: Concept }) => {
    if (valueSet && concepts) {
        return valueSet.conceptIds.map((id) => concepts[id]);
    }
    return emptyArr;
};

export const getConceptsByValueSetAndGroup = (
    valueSet?: { conceptIds: string[]; groups: { [group: string]: { ids: string[] } } },
    concepts?: { [key: string]: Concept },
    group?: string | null,
) => {
    if (valueSet && concepts && group) {
        const ids = uniq(
            group.split(',').flatMap((g) => {
                return fromNullable(valueSet.groups[g])
                    .map((vsg) => vsg.ids)
                    .chain(fromNullable)
                    .getOrElse([]);
            }),
        );
        return mapOption(ids, (id) => fromNullable(concepts[id]));
    }
    if (valueSet && concepts) {
        return valueSet.conceptIds.map((id) => concepts[id]).filter((c) => c);
    }
    return emptyArr;
};

export const getConceptsByValueSetCode = (valueSets, valueSetCode, concepts: {}, group?: string | null) => {
    const vs = valueSets[valueSetCode];
    if (vs) {
        return getConceptsByValueSetAndGroup(vs, concepts, group);
    }
    return emptyArr;
};

export const getConceptDisplayById = (conceptId, entities: RootState['admin']['entities']) => {
    if (entities.Concept && conceptId) {
        return (
            (
                (entities.Concept[conceptId] as { id: string; entityType: string; display: string } | undefined) || {
                    display: '',
                }
            ).display || ''
        );
    }
    return '';
};

export const isConceptActiveById = (conceptId, entities: RootState['admin']['entities']) => {
    if (entities.Concept && conceptId && entities.Concept[conceptId]) {
        return entities.Concept[conceptId].active;
    }
    return null;
};
