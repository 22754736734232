import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';

const useConcepts = () => {
    const getEntities = React.useMemo(createGetEntities, []);
    const getConcepts = React.useMemo(() => {
        const emptyObj = {};
        return createSelector(getEntities, (entities) => (entities as { Concept?: {} }).Concept || emptyObj);
    }, [getEntities]);
    return useSelector(getConcepts);
};
export default useConcepts;
