export interface Filter {
    'processInstance.businessKey'?: string;
    'processInstance.endTime__NOT_EMPTY'?: boolean | null;
    'processInstance.tasks.assignee.id'?: string | null;
    'processInstance.tasks.assignee.id__NOT_EMPTY'?: boolean;
    assignee_ANY?: true;
}

export enum ProcessStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    ALL = 'ALL',
}
