import difference from './difference';
import flatten from 'flat';
import uniq from 'lodash/uniq';

const getKeysOfNotUndefinedValues = (obj: {}) => {
    return Object.entries(obj).flatMap(([k, v]) => {
        if (typeof v === 'undefined') {
            return [];
        }
        return [k];
    });
};
const getDirtyPaths = (left, right, ignoreBlanks = false) => {
    const { left: leftDiff, right: rightDiff } = difference(left, right, ignoreBlanks);
    const paths = uniq([
        ...getKeysOfNotUndefinedValues(flatten(leftDiff)),
        ...getKeysOfNotUndefinedValues(flatten(rightDiff)),
    ]);
    return paths;
};
export default getDirtyPaths;
