import isPlainObject from 'lodash/isPlainObject';
import defaultMessages from './defaultMessages';

type Messages2<M, EntryType> = {
    [key in keyof M]: M[key] extends string ? EntryType : Messages2<M[key], EntryType>;
};

export type Messages<EntryType> = Messages2<typeof defaultMessages, EntryType>;

export type LangLeaf = {
    [langCode: string]: string;
};
const isLangLeaf = (node: {}): node is LangLeaf => {
    return Object.values(node).every((v) => typeof v === 'string');
};

type ProvidedMessages = {
    [langCode: string]: {
        [dottedPath: string]: string;
    };
};

const fillWithDefaults = (
    messages: ProvidedMessages,
    defaultLangCode: string,
    hardCodedDefaults: { [path: string]: string } = {},
) => {
    const defaultFill = Object.assign({}, hardCodedDefaults, messages[defaultLangCode]);
    return Object.assign(
        {
            [defaultLangCode]: defaultFill,
        },
        Object.fromEntries(
            Object.entries(messages).map(([langCode, msgs]) => [langCode, Object.assign({}, defaultFill, msgs)]),
        ),
    );
};

function getFlatMessagesByLang(messages: Messages<LangLeaf>): ProvidedMessages {
    const paths: ProvidedMessages = {};
    const traverseMessages = (node: {} | LangLeaf, currPath = '') => {
        if (isLangLeaf(node)) {
            Object.entries(node).forEach(([langCode, message]) => {
                if (!paths[langCode]) {
                    paths[langCode] = {};
                }
                paths[langCode][currPath] = message;
            });
        } else if (!isPlainObject(node)) {
            throw new Error('plain object not found in messages at ' + currPath + ', node ' + JSON.stringify(node));
        } else {
            Object.entries(node).forEach(([subKey, subNode]) => {
                traverseMessages(subNode, currPath ? `${currPath}.${subKey}` : subKey);
            });
        }
    };
    traverseMessages(messages);
    return paths;
}

function getFlatMessagesForLang(messages: Messages<string>): {
    [dotSeperatedPath: string]: string;
} {
    const paths: {
        [dotSeperatedPath: string]: string;
    } = {};
    const traverseMessages = (node: {} | string, currPath = '') => {
        if (typeof node === 'string') {
            paths[currPath] = node;
            return;
        }
        Object.entries(node).forEach(([subKey, subNode]) => {
            traverseMessages(subNode, currPath ? `${currPath}.${subKey}` : subKey);
        });
    };
    traverseMessages(messages);
    return paths;
}
const getProvidedMessages = (messages: Messages<LangLeaf>, hardCodedDefaults: Messages<string>): ProvidedMessages => {
    const configuredMessages = getFlatMessagesByLang(messages);
    const flatDefaults = getFlatMessagesForLang(hardCodedDefaults);
    return fillWithDefaults(configuredMessages, 'en', flatDefaults);
};
export default getProvidedMessages;
