import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskForm as getTaskFormAction } from 'bpm/task-form/actions';
import { RootState } from 'reducers/rootReducer';

export const useTaskFormFetchStatus = (taskId: string) => {
    const formFetchStatus = useSelector((state: RootState) => state.taskFormStatuses[taskId]);
    return formFetchStatus;
};
const useFetchTaskForm = (taskId: string) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getTaskFormAction(taskId));
    }, [taskId, dispatch]);
};

export default useFetchTaskForm;
