import { taskFormEventCreator } from 'bpm/actions/taskFormEvent';
import adjustValues from 'bpm/components/TaskDetail/TaskForm/adjustSubmissionValues';
import { TaskForm } from '../../../../../../../../../reducers/taskFormType';

// dispatch related
export const submitAction = (
    _values: {
        overrideWarning?: boolean;
        values: {
            _outcome?: string;
            _outcomeDisplay?: string;
            submissionType?: 'submit' | 'save';
        };
        entityFieldValues?: {};
        fieldVisibility?: {
            [field: string]: boolean;
        };
    },
    taskId: string,
    formDefinition: TaskForm,
    cb: (response?: { warning?: string; nextTaskId?: string; processComplete: boolean; error: string }) => void = () =>
        null,
    errorCb: () => void = () => null,
    isAutoSave: boolean = false,
    skipSuccessAction: boolean = false,
) => {
    const { _outcome, _outcomeDisplay, ...originalValues } = _values.values;

    const values = adjustValues(formDefinition, originalValues);
    const taskFormId = formDefinition.id;
    const { submissionType, ...formValues } = values as typeof _values.values; // eslint-disable-line no-unused-vars
    // const submissionValues = formValues.overrideValues || formValues;
    const submissionValues = { ..._values, values: formValues };
    if (submissionType === 'submit') {
        return taskFormEventCreator.submitTaskForm(
            taskId,
            taskFormId,
            submissionValues,
            _outcome,
            _outcomeDisplay,
            cb,
            errorCb,
            skipSuccessAction,
        );
    }
    return taskFormEventCreator.saveTaskForm(taskId, taskFormId, submissionValues, cb, errorCb, isAutoSave);
};
