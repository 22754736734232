import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    CardHeader,
    CardActions,
    useTheme,
    CardContent,
    IconButton,
    Tooltip,
    CircularProgress,
    DialogTitle,
    DialogContent,
    Typography,
    ButtonProps,
} from '@material-ui/core';
import { formContext } from 'components/generics/form/EntityFormContext';
import { constructOfflineListsAtCurrentLevel, usePrefetchLists } from 'components/generics/form/prefetchLists';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import { getAllowOfflineTasksSelector } from 'util/applicationConfig';
import useViewConfig from 'util/hooks/useViewConfig';
import { markTaskForOffline, unMarkTaskForOffline } from '../../offlineTasks/actions';
import useFetchDataForReachableCreateViewsOffline from '../hooks/useFetchDataForReachableViewsOffline';
import useFetchTaskFormOfflineData from '../hooks/useFetchTaskFormOfflineData';
import { setDownloadedListViews, unsetDownloadedListViews } from '../downloadedListViews/actions';
import { getAllFieldsFromView, getRefEntityName, isFieldViewField } from 'components/generics/utils/viewConfigUtils';
import merge from 'lodash/merge';
import { FieldViewField } from '@mkanai/casetivity-shared-js/lib/view-config/views';
import PinForm from './PinForm';
import constructOfflineRef1sAtCurrentLevel from '../downloadedRef1Views/constructOfflineRef1sAtCurrentLevel';
import { setDownloadedRefOneViews } from '../downloadedRef1Views/actions';
import { DownloadedRef1Views } from '../downloadedRef1Views/data';
import ViewConfig from 'reducers/ViewConfigType';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import GetApp from '@material-ui/icons/GetApp';
import Undo from '@material-ui/icons/Undo';
import sessionSecretsController from 'offline_app/sessionSecretsController';
import validatePinAndReencryptAllDataWithNewSecrets from '../util/validatePinAndReencryptAllDataWithNewSecrets';
import Alert from '@material-ui/lab/Alert';
import useIsPristine from 'offline_app/hooks/useIsPristine';
import { FormattedMessage } from 'react-intl';
import { setCurrentlyWritingToOffline } from 'offline_app/offline_stateful_tasks/currentlyWritingToOfflineState/actions';
import Message from 'i18n/components/Message';

const getNextShowAndEditViews = (viewConfig: ViewConfig, viewName: string, fieldName: string) => {
    const entityType = viewConfig.views[viewName]?.entity;
    const field = getAllFieldsFromView(viewConfig, viewName).find(
        (f) => isFieldViewField(f) && f.field === fieldName,
    ) as FieldViewField;
    const parsedConfig = JSON.parse(field.config || null);
    const relEntity = getRefEntityName(viewConfig, entityType, field.field, 'TRAVERSE_PATH');
    const nextEditViewName =
        parsedConfig?.editViewName ||
        parsedConfig?.viewOverride?.edit ||
        viewConfig.entities[relEntity].defaultViews.EDIT?.name;
    const nextShowViewName =
        parsedConfig?.showViewName ||
        parsedConfig?.viewOverride?.show ||
        viewConfig.entities[relEntity].defaultViews.SHOW?.name;
    return { nextEditViewName, nextShowViewName };
};

const DownloadOfflineButton: React.FC<{ taskId: string; taskKey: string; processKey: string }> = ({
    taskId,
    taskKey,
    processKey,
}) => {
    const viewConfig = useViewConfig();
    const store = useStore();

    const taskAllowedOfflineSelector = useMemo(() => {
        return createSelector(getAllowOfflineTasksSelector, (processes) => {
            const entry = processes?.[processKey]?.tasks?.[taskKey];
            if (Array.isArray(entry)) {
                return viewConfig?.user?.roles?.some((role) => {
                    return entry.includes(role);
                });
            }
            return Boolean(entry);
        });
    }, [viewConfig, taskKey, processKey]);

    const taskAllowedOffline = useSelector(taskAllowedOfflineSelector);
    const taskIsOffline = useSelector((state: RootState) => state.offlineTasks?.[taskId]);
    const dispatch = useDispatch();
    const { viewName, initialValues } = useContext(formContext);
    const entityType = viewConfig.views[viewName]?.entity;
    const prefetchLists = usePrefetchLists();
    const linkedEntityId = initialValues['id'];

    const fetchData = useFetchDataForReachableCreateViewsOffline(viewName, linkedEntityId);
    const [fetchingState, setFetchingState] = useState<'wait' | 'done' | 'initial'>('initial');

    const fetchTaskFormData = useFetchTaskFormOfflineData(taskId);
    const handleMakeOffline = useCallback(() => {
        let getOfflineRef1s = () => {
            /*
                Calculate and mark which 'x-1' reference fields we can show offline, because we know the data is loaded for them.                
            */
            const rootRecord = store.getState().admin.entities[entityType]?.[linkedEntityId];
            if (!rootRecord) {
                return;
            }
            let acc = constructOfflineRef1sAtCurrentLevel(viewConfig, viewName, linkedEntityId);

            const ref1FieldKeysInView = Object.keys(acc[entityType]?.[linkedEntityId] ?? {});
            // now we need to look up the actual views pointed to, so we can drill into it with constructOfflineRef1sAtCurrentLevel passing the next viewName and id.
            let acced: DownloadedRef1Views = ref1FieldKeysInView.reduce((prev, fieldName) => {
                const { nextEditViewName, nextShowViewName } = getNextShowAndEditViews(viewConfig, viewName, fieldName);
                const outerEntityName = viewConfig.views[viewName]?.entity;
                return [nextEditViewName, nextShowViewName].filter(Boolean).reduce((prevAcced, currVN) => {
                    const fieldInData = fieldName.endsWith('Id') ? fieldName : fieldName + 'Id';
                    const currId = store.getState().admin.entities[outerEntityName]?.[linkedEntityId]?.[fieldInData];
                    let accedFromNextView = constructOfflineRef1sAtCurrentLevel(viewConfig, currVN, currId);
                    if (currId) {
                        return merge(prevAcced, accedFromNextView);
                    }
                    return prevAcced;
                }, prev);
            }, acc);
            return acced;
        };

        // Download list data
        if (linkedEntityId) {
            prefetchLists(viewName, linkedEntityId, undefined, { perPage: 100 }, (results) => {
                /*
                    Once all top-level list data is received in this cb,
                    iterate through each entry, and make sure list data is marked as available for levels 1-deep.
                    i.e. for the Edit and Show views for each item in every x-many in the linked-entity.
                    This is done through the downloadedListViews state, where if it is present we hide all lists not marked.
                */
                const { offlineLists, offlineRef1s } = results.reduce(
                    (prev, [fieldName, results]) => {
                        const { nextEditViewName, nextShowViewName } = getNextShowAndEditViews(
                            viewConfig,
                            viewName,
                            fieldName,
                        );
                        const nextViewNames = [nextEditViewName, nextShowViewName].filter(Boolean);
                        return results.reduce(
                            ({ offlineRef1s: prevOfflineRef1s, offlineLists: prevOfflineLists }, curr) => {
                                const currId = curr['id'];
                                let offlineLists = nextViewNames.reduce((prev, vn) => {
                                    return merge(prev, constructOfflineListsAtCurrentLevel(viewConfig, vn, currId));
                                }, prevOfflineLists);
                                let offlineRef1s = nextViewNames.reduce((prev, vn) => {
                                    return merge(prev, constructOfflineRef1sAtCurrentLevel(viewConfig, vn, currId));
                                }, prevOfflineRef1s);
                                return {
                                    offlineRef1s,
                                    offlineLists,
                                };
                            },
                            prev,
                        );
                    },
                    {
                        offlineRef1s: getOfflineRef1s(),
                        offlineLists: constructOfflineListsAtCurrentLevel(viewConfig, viewName, linkedEntityId),
                    },
                );
                dispatch(setDownloadedListViews(offlineLists));
                dispatch(setDownloadedRefOneViews(offlineRef1s));
            });
            fetchData();
        }
        fetchTaskFormData();
        // admin.loading should be > 0 at this point due to actions dispatched to start fetching.
        setFetchingState('wait');
    }, [
        setFetchingState,
        fetchTaskFormData,
        viewName,
        entityType,
        linkedEntityId,
        viewConfig,
        dispatch,
        prefetchLists,
        fetchData,
        store,
    ]);
    useEffect(() => {
        if (fetchingState === 'wait') {
            const whenReady = () => {
                setFetchingState('done');
                store.dispatch(markTaskForOffline(taskId));
                store.dispatch(setCurrentlyWritingToOffline(taskId));
            };
            if ((store.getState() as RootState).admin.loading === 0) {
                // no data needed to be fetched.
                whenReady();
            } else {
                const unsubscribe = store.subscribe(() => {
                    const state = store.getState() as RootState;
                    const fetching = Boolean(state.admin.loading);
                    if (!fetching && !state.offlineTasks?.[taskId]) {
                        whenReady();
                    }
                });
                return () => {
                    unsubscribe();
                };
            }
        }
    }, [fetchingState, store, taskId]);

    const handleRemoveOffline = useCallback(() => {
        /*
            In the future, we can clear the PIN if there are no tasks left.
        */
        dispatch(unMarkTaskForOffline(taskId));
        dispatch(unsetDownloadedListViews());
        setFetchingState('initial');
    }, [dispatch, taskId]);

    const [pinPromptOpen, setPinPromptOpen] = useState(false);
    const [pinPromptErrorMessage, setPinPromptErrorMessage] = useState<{
        id: string;
        defaultMessage: string;
        description?: string;
    }>(null);
    // track if download was triggered, so we only show 'undo' button the first time around (after download was clicked.)
    const [wasMadeOffline, setWasMadeOffline] = useState(false);
    const handleInitMakeOffline = useCallback(() => {
        const existingSessionSecrets = sessionSecretsController.get();
        if (!existingSessionSecrets) {
            setPinPromptOpen(true);
            // this will be called again when we set the pin in storageController after submitting the pin prompt.
        } else {
            setPinPromptOpen(false);
            setPinPromptErrorMessage(null);
            handleMakeOffline();
        }
        setWasMadeOffline(true);
    }, [handleMakeOffline, setPinPromptOpen]);
    const theme = useTheme();
    const [hasExistingOfflineEntry, setHasExistingOfflineEntry] = useState(false);
    useEffect(() => {
        (async () => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            const keys = await idbKeyval.keys();
            if (keys.length > 0) {
                setHasExistingOfflineEntry(true);
            }
        })();
    }, []);

    const isPristine = useIsPristine(taskId);
    const [pending, setPending] = useState(false);
    if (!taskAllowedOffline || isPristine === 'pending') {
        return null;
    }
    if (taskIsOffline) {
        if (wasMadeOffline || isPristine === true) {
            return (
                <div style={{ display: 'flex' }}>
                    <Alert style={{ flex: 1 }} severity={wasMadeOffline ? 'success' : 'info'}>
                        <Message
                            id="offline.task.changesBeingBackedUp"
                            dm="Your changes will be backed up as you work."
                        />
                        {/* Removing the below by request but I still think it's handy to have... */}
                        {/* Additionally,&nbsp;
                        <button
                            onClick={() => {
                                window.location.href = window.location.pathname + '?offline=1';
                            }}
                            className="casetivity-linkbutton"
                        >
                            an offline page is available
                        </button> */}
                    </Alert>
                    {wasMadeOffline ? (
                        <div>
                            <Tooltip title="Undo offline download">
                                <IconButton color="primary" onClick={handleRemoveOffline}>
                                    <Undo />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : null}
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <Dialog open={fetchingState === 'wait'}>
                <DialogTitle>
                    <Message id="offline.task.downloading" dm="Downloading Task Data" />
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'grid', placeItems: 'center', padding: '1em' }}>
                        <CircularProgress />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Typography>
                            <Message id="loading.pleaseWait" dm="Please wait..." />
                        </Typography>
                    </div>
                </DialogContent>
            </Dialog>
            <Button
                endIcon={fetchingState === 'wait' ? <CircularProgress size={24} /> : <GetApp />}
                variant="contained"
                color="primary"
                onClick={handleInitMakeOffline}
                disabled={fetchingState === 'wait'}
            >
                <Message id="offline.task.download" description="This button downloads task data" dm="Download Task" />
            </Button>
            <Dialog open={pinPromptOpen}>
                <CardHeader
                    title={
                        hasExistingOfflineEntry ? (
                            <Message id="offline.pin.enterPin" dm="Enter your security PIN" />
                        ) : (
                            <Message id="offline.pin.choosePin" dm="Choose a security PIN" />
                        )
                    }
                />
                {pinPromptErrorMessage ? (
                    <CardContent style={{ color: theme.palette.error.main, whiteSpace: 'pre-wrap' }}>
                        <FormattedMessage {...pinPromptErrorMessage} />
                    </CardContent>
                ) : null}
                <PinForm
                    onSubmit={(pin) => {
                        (async () => {
                            setPending(true);
                            await new Promise((resolve) => setTimeout(resolve, 300));
                            let successSecrets = await validatePinAndReencryptAllDataWithNewSecrets(pin);
                            if (!successSecrets) {
                                setPinPromptOpen(true);
                                setPinPromptErrorMessage({
                                    id: 'offline.pin.pinMustMatchExistingError',
                                    defaultMessage: 'The PIN used must match the PIN for existing offline data.',
                                });
                                setPending(false);
                                return;
                            }
                            sessionSecretsController.set(successSecrets);
                            setPending(false);
                            // now retry
                            setPinPromptOpen(false);
                            handleInitMakeOffline();
                        })();
                    }}
                    renderActions={({ submitBtn }) => (
                        <div>
                            {pending ? <div style={{ textAlign: 'center' }}>Please wait...</div> : null}
                            <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    onClick={() => {
                                        setPinPromptOpen(false);
                                        setPinPromptErrorMessage(null);
                                    }}
                                >
                                    <Message id="dialog.cancel" dm="Cancel" />
                                </Button>
                                <span>
                                    {pending
                                        ? React.cloneElement<ButtonProps>(submitBtn, {
                                              endIcon: <CircularProgress size="1em" />,
                                              disabled: true,
                                          })
                                        : submitBtn}
                                </span>
                            </CardActions>
                        </div>
                    )}
                />
            </Dialog>
        </>
    );
};

export default DownloadOfflineButton;
