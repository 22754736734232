import React from 'react';
import { createSelector } from 'reselect';
import { getOverrideAppbarTitleForRouteSelector } from 'util/applicationConfig';
import { RootState } from 'reducers/rootReducer';

// there's only one appbar, so we only need a single caching selecto
//  [string | null, boolean, React.CSSProperties | undefined, boolean]

export const overrideAppbarTitleSelector = createSelector(
    getOverrideAppbarTitleForRouteSelector,
    (state: RootState): string => state.router?.location?.pathname,
    (
        overrides,
        location,
    ): {
        overriddenAppbarTitleHtml?: string;
        overrideMobileAppbarTitleHtml?: string;
        hideAllMenuItems?: boolean;
        toolbarStyles?: React.CSSProperties;
        mobileToolbarStyles?: React.CSSProperties;
        navLink?: boolean;
    } => {
        const foundOverride = overrides?.find(({ route }) =>
            (Array.isArray(route) ? route : [route]).some((_route) => new RegExp(_route).test(location)),
        );
        if (foundOverride) {
            return {
                overrideMobileAppbarTitleHtml: foundOverride.mobileHtml,
                overriddenAppbarTitleHtml: foundOverride.html,
                hideAllMenuItems: Boolean(foundOverride.hideAllMenuItems),
                toolbarStyles: foundOverride.toolbarStyles ?? {},
                mobileToolbarStyles: foundOverride.mobileToolbarStyles ?? {},
                navLink: Boolean(foundOverride.navLink ?? true),
            };
        }
        return {
            hideAllMenuItems: false,
            toolbarStyles: {},
            mobileToolbarStyles: {},
            navLink: true,
        };
    },
);
