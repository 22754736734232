import { createSelector } from 'reselect';
import { RootState } from '../../../../reducers/rootReducer';
import getAdhocFieldsExpected, { getAdhocFieldsForView, EXTERNALGISID } from './util/getAllFieldsExpected';
import createRecordSelector from './util/recordSelector';
import forceBooleanFieldsBoolean from './util/enforceBoolAndArrayValues';
import { getAllValuesetFields, getExpressions } from './util/entityVisExp';
import createFormContext from './util/createFormContext';
import { createGetEntities, createGetValueSets } from './util/getEntities';
import createDeepEqlSelector from './util/createDeepEqlSelector';
import uniq from 'lodash/uniq';
import { EntityFieldConceptExps } from 'viewConfigCalculations/ConceptAvailabilityExpressions/EntityFieldConceptExps';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import {
    getValueSetFieldsRequiredForEntity,
    isFieldViewField,
    isExpressionViewField,
    getAdjustedFieldSource,
    expandComponentFields,
    getAllFieldEntriesFromView,
} from 'components/generics/utils/viewConfigUtils';
import mapFieldsToWidgets from './util/mapFieldsToWidgets';
import { CasetivityViewContext } from 'util/casetivityViewContext';
import getProviderAndHocWithViewContext from './util/getProviderAndHocWithViewContext';
import { ViewEditableExps } from 'reducers/entityEditabilityReducer';
import fromEntries from 'util/fromentries';
import { ViewItemFilterExpressionsGeneratedType } from 'viewConfigCalculations/filterExpressions/ViewItemFilterExpressionsGeneratedType';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import ViewConfig from 'reducers/ViewConfigType';
import getFieldsWithConfigProperty from './util/getfieldsWithConfigProperty';
import createBackrefSelector from './util/createBackrefSelector';
import getAdhocVariablesContextSelector, { nullAdhocVariablesContext } from './util/getVariablesContextSelector';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';

export interface FCPProps {
    viewName: string;
    formId?: string;
    record: {
        // because we use this for create, might be empty
        id?: string;
        entityType?: string;
    };
    overrideViewConfig?: ViewConfig;
    overrides?: {
        visibilityExps?: EntityVisibilityExps[0];
        editableExps?: ViewEditableExps[0];
        conceptExps?: EntityFieldConceptExps[0];
        filterExps?: ViewItemFilterExpressionsGeneratedType[0];
    };
    viewContext: CasetivityViewContext;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    entityFormContextRef?: EntityFormContextRef;
}

const getExpressionsSelector = () => {
    return createSelector(
        (state: RootState, props: FCPProps): EntityVisibilityExps[0] =>
            (props.overrides && props.overrides.visibilityExps) || state.entityVisibility[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps): EntityFieldConceptExps[0] =>
            (props.overrides && props.overrides.conceptExps) || state.entityConceptExps[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps): ViewEditableExps[0] =>
            (props.overrides && props.overrides.editableExps) || state.entityEditability[props.viewName] || emptyObj,
        (state: RootState, props: FCPProps): ViewItemFilterExpressionsGeneratedType[0] =>
            (props.overrides && props.overrides.filterExps) || state.viewItemFilterExps[props.viewName] || emptyObj,
        (visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps) => {
            return {
                visibilityConfig,
                entityConceptExps,
                viewFieldEditability,
                viewItemFilterExps,
            };
        },
    );
};

/**
 *
 * The point of this is to produce a new value ONLY when a new field appears we MUST respond to.
 * i.e. produce a new value when a field is added which we _don't get from the viewconfig_.
 * This prevents the app from hanging when we remount, and ALL fields are being unregistered/registered (which could be hundreds)
 * - This was occurring when deleting fields from layout editor when editing inline.
 */
const createUndocumentedFieldsSelector = () => {
    const documentedFieldsSelector = createSelector(
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) => state.printMode,
        (viewConfig, viewName, printMode) => {
            const getSource = getAdjustedFieldSource(viewConfig)(viewConfig.views[viewName]);
            return expandComponentFields(
                viewConfig,
                getAllFieldEntriesFromView(viewConfig, viewName),
                viewConfig.views[viewName].entity,
                { rebaseExpressionsWithinFields: false, replaceXmanyWithMultiCard: printMode },
            )
                .expandedFieldsByRow.flat()
                .reduce((prev, [, curr]) => {
                    if (isFieldViewField(curr)) {
                        prev[getSource(curr)] = true;
                    }
                    if (isExpressionViewField(curr) && curr.field) {
                        prev[curr.field] = true;
                    }
                    return prev;
                }, {});
        },
    );
    const undocumentedFieldsDeepEqlSelector = createDeepEqlSelector(
        createSelector(
            (state: RootState, props: FCPProps) =>
                (state.form![props.formId || 'record-form'] || {}).registeredFields || emptyObj,
            documentedFieldsSelector,
            (registeredFields, documentedFields) => {
                return Object.keys(registeredFields).filter((k) => {
                    return !documentedFields[k];
                });
            },
        ),
    );
    return createSelector(undocumentedFieldsDeepEqlSelector, (fieldKeys) => {
        return fieldKeys.reduce((prev, curr) => {
            // it doesn't matter what we assign - we're only after the keys.
            prev[curr] = true;
            return prev;
        }, {});
    });
};

const getFormContextEvaluatorSelector = () => {
    const expressionsSelector = getExpressionsSelector();
    const backrefSelector = createBackrefSelector<FCPProps>();

    const undocumentedFieldsSelector = createUndocumentedFieldsSelector();
    return createSelector(
        expressionsSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        undocumentedFieldsSelector,
        (state: RootState, props: FCPProps) => state.entityValidations,
        (state: RootState, props: FCPProps) => props.viewContext,
        (state: RootState, props: FCPProps) => state.printMode,
        backrefSelector,
        (expressions, viewConfig, viewName, undocumentedFields, entityValidations, viewContext, printMode, backref) => {
            const { visibilityConfig, entityConceptExps, viewFieldEditability, viewItemFilterExps } = expressions;
            const adhocFieldsExpected = getAdhocFieldsExpected(
                viewConfig,
                viewName,
                undocumentedFields,
                getAdhocFieldsForView,
            );

            const fieldsToWidgets = mapFieldsToWidgets(viewConfig, viewName, adhocFieldsExpected, printMode);

            const allValueset1Fields = {
                ...getValueSetFieldsRequiredForEntity(viewConfig, viewName, 'ONES'),
                ...getAllValuesetFields(visibilityConfig, viewFieldEditability, entityConceptExps, viewItemFilterExps),
            };
            const visibilityExpressions = getExpressions(visibilityConfig);
            const editabilityExpressions = getExpressions(viewFieldEditability);

            const basedOnEntity = viewConfig.views[viewName].entity;
            const fieldsUsedInExpressions = uniq([
                // added '|| cc.fieldsRequired' so old tests still pass
                ...Object.values(visibilityConfig).flatMap((c) =>
                    c.flatMap((cc) => cc.dataPaths || (cc as any).fieldsRequired),
                ),
                ...Object.values(viewFieldEditability).flatMap((c) =>
                    c.flatMap((cc) => cc.dataPaths || (cc as any).fieldsRequired),
                ),
                ...Object.values(entityValidations[basedOnEntity] || {}).flatMap(
                    (c) => c.dataPaths || (c as any).fieldsRequired,
                ),
                ...Object.values(viewItemFilterExps || {}).flatMap((c) => c.dataPaths || (c as any).fieldsRequired),
                ...Object.values(entityConceptExps || {}).flatMap((c) => c.dataPaths || (c as any).fieldsRequired),
            ]);
            const nullWhenHidden = getFieldsWithConfigProperty(viewConfig, viewName, 'nullIfHidden');
            const nullWhenDisabled = getFieldsWithConfigProperty(viewConfig, viewName, 'nullIfDisabled');
            const bypassFilterConsistency = getFieldsWithConfigProperty(
                viewConfig,
                viewName,
                'bypassFilterConsistency',
            );

            const reference1EntityFilterExpressions =
                viewItemFilterExps &&
                fromEntries(
                    Object.entries(viewItemFilterExps)
                        .filter(([field]) => {
                            const adjustedFieldName = field.endsWith('Id') ? field.slice(0, -2) : field;
                            return !bypassFilterConsistency[adjustedFieldName];
                        })
                        .map(([field, e]) => {
                            return [
                                field,
                                {
                                    entityType: e.searchEntity,
                                    expression: e.expression,
                                },
                            ] as [string, { entityType: string; expression: string }];
                        }),
                );

            return new FormContextEvaluator({
                basedOnEntityOptions: {
                    basedOnEntity,
                    fieldsUsedInExpressions,
                },
                evaluationFactors: {
                    fieldWidgets: fieldsToWidgets,
                    dropdownAvailableOptionsExpressions: {},
                    valueset1AvailableConceptsExpressions: Object.assign(
                        {},
                        ...Object.values(entityConceptExps).map((ca) => ({ [ca.fieldName]: ca.expression })),
                    ),
                    valueset1Fields: allValueset1Fields,
                    visibilityExpressions,
                    editabilityExpressions,
                    tableExpressions: {},
                    reference1EntityFilterExpressions,
                    useBackingValuesRegardlessOfDisplayStatus: {
                        [EXTERNALGISID]: true,
                    },
                    nullWhenHidden,
                    nullWhenDisabled,
                },
                options: {
                    viewContext,
                    dateFormat: (viewConfig && viewConfig.application && viewConfig.application.dateFormat) || '',
                    backref,
                },
                viewConfig,
            });
        },
    );
};
const emptyObj = {};
const createFormContextSelector = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    const recordSelector = createRecordSelector();
    const formContextEvaluatorSelector = getFormContextEvaluatorSelector();
    const adhocVariablesContextSelector = getAdhocVariablesContextSelector();
    const formContextSelector = createSelector(
        formContextEvaluatorSelector,
        (state: RootState, props: FCPProps) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: FCPProps) => props.viewName,
        (state: RootState, props: FCPProps) =>
            state.form![props.formId || 'record-form']?.initial ?? recordSelector(state, props),
        (state: RootState, props: FCPProps) =>
            state.form![props.formId || 'record-form']?.values ?? recordSelector(state, props),
        getEntities,
        getValueSets,
        (state: RootState, props: FCPProps) => adhocVariablesContextSelector(props.evaluatedAdhocSPELVariables),
        (state: RootState, props: FCPProps) => props.entityFormContextRef,
        (
            formContextEvaluator,
            viewConfig,
            viewName,
            initial: {},
            values: {},
            entities: {},
            valueSets,
            adhocVariablesContext,
            entityFormContextRef,
        ) => {
            const result = formContextEvaluator.evaluate(
                forceBooleanFieldsBoolean(viewConfig, viewName)(values),
                valueSets,
                forceBooleanFieldsBoolean(viewConfig, viewName)(initial),
                entities,
                adhocVariablesContext,
            );
            const { availableOptions, tableRowContexts, ...rest } = result;
            const res = { ...rest, viewName, adhocVariablesContext };
            if (entityFormContextRef) entityFormContextRef.current = res;
            return res;
        },
    );

    return createDeepEqlSelector(formContextSelector);
};

export type EntityFormContext = ReturnType<ReturnType<typeof createFormContextSelector>>;
export const defaultFormContext: EntityFormContext = {
    variables: {},
    hiddenFields: {},
    disabledFields: {},
    fieldValues: {},
    registeredValues: {},
    visibleAndEditableFields: [],
    isDirty: false,
    dirtyValues: {},
    initialValues: {},
    nullFilteredRefOneFields: [],
    valuesetFieldAvailableConceptIds: {},
    viewName: undefined,
    initialFormContext: undefined,
    adhocVariablesContext: nullAdhocVariablesContext,
};

const { formContext, FormContextProvider: _EntityFormContextProvider } = createFormContext(
    createFormContextSelector,
    defaultFormContext,
);

const { FormContextProvider: EntityFormContextProvider, formContextHoc } =
    getProviderAndHocWithViewContext(_EntityFormContextProvider);
export { formContext, formContextHoc, EntityFormContextProvider };
