import { storeRegistry } from 'configureStore/storeRegistry';
import { RootState } from 'reducers/rootReducer';
import { Store, Unsubscribe } from 'redux';

export type HandlePrintStatusChange = (state: 'pending' | 'complete' | 'error') => void;

class PrintController {
    private subscriptions: HandlePrintStatusChange[] = [];
    private store: Store<RootState>;
    private to: NodeJS.Timeout;
    private storeUnSub: Unsubscribe;
    public constructor(store: Store<RootState>) {
        this.store = store;
        this.subscribeToPrint = this.subscribeToPrint.bind(this);
        this.unsubscribeToPrint = this.unsubscribeToPrint.bind(this);
        this.startPrint = this.startPrint.bind(this);
    }

    public startPrint() {
        this.subscriptions.forEach((cb) => cb('pending'));

        const maxTries = 180;
        let tries = 0;
        this.to = setInterval(() => {
            tries += 1;
            if (tries >= maxTries) {
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('error'));
            }
            if (!this.store.getState().admin.loading) {
                // finally.
                clearInterval(this.to);
                this.storeUnSub?.();
                this.subscriptions.forEach((cb) => cb('complete'));
                setImmediate(() => {
                    window.print();
                });
            }
        }, 500);
        const getCurrPath = () => this.store.getState().router.location.pathname;
        const initialPath = getCurrPath();
        this.storeUnSub = this.store.subscribe(() => {
            const currPath = getCurrPath();
            if (initialPath !== currPath) {
                clearInterval(this.to);
            }
        });
    }

    public subscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions.push(cb);
    }
    public unsubscribeToPrint(cb: HandlePrintStatusChange) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
}

let printController: PrintController;
export const getPrintController = () => {
    if (!printController) {
        printController = new PrintController(storeRegistry['store']);
    }
    return printController;
};
