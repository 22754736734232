import React, { Suspense } from 'react';
const DynamicFetchingTestArea = React.lazy(() => import('./DynamicFetchingTestArea'));
const LazyDynamicFetchingTestArea = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <DynamicFetchingTestArea {...props} />
        </Suspense>
    );
};
export default LazyDynamicFetchingTestArea;
