import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { useDispatch } from 'react-redux';
import { touch, stopSubmit } from 'redux-form';
import validate, { ValidateArgs } from 'bpm/components/TaskDetail/TaskForm/validate/validate';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import { startFormContext } from 'bpm/start-form/components/startFormContext';
import useViewConfig from 'util/hooks/useViewConfig';

interface StartFormValidateContext {
    validate: () => {
        [key: string]: string[];
    };
}
export const startFormValidateContext = React.createContext<StartFormValidateContext>({
    validate: () => ({}),
});

export const StartFormValidateContextProvider: React.FC<{ fields: React.ReactElement<any>[]; formId?: string }> = ({
    fields,
    formId,
    children,
}) => {
    const fc = useContext(formContext);
    const startFormCtxt = useContext(startFormContext);
    const entities = useEntities();
    const viewConfig = useViewConfig();
    const valueSets = useValueSets();
    const dispatch = useDispatch();
    const makeValidateArgs = () => ({
        formDefinition: startFormCtxt,
        entities,
        valuesAfterExpressionsApplied: fc.fieldValues,
        visibleAndEditableFields: fc.visibleAndEditableFields,
        viewConfig,
        fields,
        valueSets,
    });
    const validateArgs = makeValidateArgs();
    const params = useRef<ValidateArgs>(validateArgs);
    params.current = validateArgs;

    const validateCb = useCallback(() => {
        let res: any = Object.fromEntries(
            Object.entries(validate(params.current)).filter(([k, e]) =>
                params.current.visibleAndEditableFields.includes(k),
            ),
        );
        dispatch(touch(formId ?? 'current-task-form', ...Object.keys(res)));
        dispatch(stopSubmit(formId ?? 'current-task-form', res));
        return res;
    }, [dispatch, formId]);
    const value = useMemo(
        () => ({
            validate: validateCb,
        }),
        [validateCb],
    );
    return <startFormValidateContext.Provider value={value}>{children}</startFormValidateContext.Provider>;
};

export default startFormValidateContext;
