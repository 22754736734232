import FormData from 'form-data';

export class HttpError extends Error {
    constructor(message, status) {
        super(message);
        this.message = message;
        this.status = status;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.stack = new Error().stack;
    }
}

export const fetchJson = (url, options = {}) => {
    const requestHeaders =
        options.headers ||
        new Headers({
            Accept: 'application/json',
        });
    if (!requestHeaders.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
        requestHeaders.set('Content-Type', 'application/json');
    }
    if (options.user && options.user.authenticated && options.user.token) {
        requestHeaders.set('Authorization', options.user.token);
    }
    requestHeaders.set('clientTzOffset', new Date().getTimezoneOffset());
    return fetch(url, { ...options, headers: requestHeaders })
        .then((response) =>
            response.text().then((text) => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            })),
        )
        .then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError((json && json.description) || (json && json.message) || statusText, status),
                );
            }
            return { status, headers, body, json };
        });
};

export const queryParameters = (data) =>
    Object.keys(data)
        .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
        .join('&');
