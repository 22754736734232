import React, { Suspense } from 'react';
const JsonEditor = React.lazy(() => import('./Editor'));
const Editor = (props) => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <JsonEditor {...props} />
        </Suspense>
    );
};
export default Editor;
