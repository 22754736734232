import { RootState } from 'reducers/rootReducer';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';

interface ProcessConfig {
    hideLinkedEntity?: boolean;
}

export const createHideLinkedEntitySelector = <Props extends { processId: string }>() =>
    createSelector(
        (state: RootState, props: Props) => state.bpm.processInstances.byId[props.processId],
        (state: RootState, props: Props) => state.bpm.processDefinitions.byId,
        (state: RootState, props: Props) => state.viewConfig.processes,
        (processInstance, definitionsById, processConfigs) => {
            return fromNullable(processInstance)
                .mapNullable((process) => process.businessKey)
                .mapNullable((bk) => definitionsById[bk]?.key)
                .mapNullable((key) => processConfigs[key]?.config)
                .chain(fromPredicate<string>(Boolean))
                .chain((configStr) => tryCatch(() => JSON.parse(configStr) as ProcessConfig))
                .mapNullable((c) => c.hideLinkedEntity)
                .getOrElse(false);
        },
    );
