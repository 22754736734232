import React from 'react';
import { ListItem, ListItemText, Collapse, IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { useDispatch } from 'react-redux';

interface ExpandableListItemProps {
    children: (props: { close: () => void }) => JSX.Element;
    inset?: boolean;
    label: React.ReactNode;
    onClick?: () => void;
}
const ExpandableListItem: React.FC<ExpandableListItemProps> = ({ onClick, children, label, inset }) => {
    const [open, setOpen] = React.useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    };
    const close = () => setOpen(false);
    const dispatch = useDispatch();

    const icon = open ? <ExpandLess /> : <ExpandMore />;
    return (
        <div>
            <ListItem button onClick={onClick ? onClick : toggleOpen}>
                <ListItemText
                    inset={inset}
                    primary={
                        <span
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {label}
                        </span>
                    }
                />
                {onClick ? (
                    <IconButton
                        onClick={toggleOpen}
                        style={{
                            width: 24,
                            height: 24,
                            padding: 0,
                        }}
                    >
                        {icon}
                    </IconButton>
                ) : (
                    icon
                )}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
                {children({ close })}
                {/* <List component="menu">
                    
                </List> */}
            </Collapse>
        </div>
    );
};

export default ExpandableListItem;
