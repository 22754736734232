import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';
import { useContext, useMemo } from 'react';
import { extractValidationMetadata } from 'sagas/util/getValidation';
import useViewConfig from 'util/hooks/useViewConfig';

const useOverriddenViewValidations = (viewName: string) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;

    const overrideConfiguredContext = useContext(expressionTesterProvidedViewConfigurationContext);
    const overriddenValidations = overrideConfiguredContext?.config?.[viewName]?.validations;
    return useMemo(() => {
        if (!overriddenValidations) {
            return undefined;
        }
        return extractValidationMetadata(overriddenValidations, viewConfig, resource);
    }, [overriddenValidations, viewConfig, resource]);
};

export default useOverriddenViewValidations;
