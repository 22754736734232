import React from 'react';
import { withTheme, WithTheme, Theme } from '@material-ui/core';

interface ThemedProps {
    children: (args: { theme: Theme }) => JSX.Element | null;
}
const Themed: React.ComponentType<ThemedProps> = withTheme(({ theme, children }: WithTheme & ThemedProps) =>
    children({ theme }),
);
export default Themed;
