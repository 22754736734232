import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createIsPopoverSelector } from '../hoc/popoverLock';
import { RootState } from '../../../reducers/rootReducer';

export const useIsPopover = (formId: string) => {
    const isPopoverSelector = useMemo(createIsPopoverSelector, []);
    const isPopover = useSelector((state: RootState) => isPopoverSelector(state, { formId }));
    return isPopover;
};
