import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from 'reducers/ViewConfigType';
import { createSelector } from 'reselect';
import getViewConf from 'util/getViewConf';
import useViewConfig from 'util/hooks/useViewConfig';

export const getHidePopupNav = (viewConfig: ViewConfig, viewName?: string): string | boolean => {
    return getViewConf(viewConfig, viewName)
        .mapNullable((pc: EntityViewConfig) => pc.hidePopupNavigationArrow)
        .getOrElse(false);
};

const getHidePopupNavSelector = <T extends { viewName: string; viewConfig?: ViewConfig }>() => {
    const hidePopupNavSelector = createSelector(
        (state: RootState, props: T) => props.viewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        getHidePopupNav,
    );
    return hidePopupNavSelector;
};
export const useHidePopupNav = (viewName: string) => {
    const viewConfig = useViewConfig();
    const hidePopupNavSelector = useMemo(getHidePopupNavSelector, []);
    const hidePopupNav = useSelector((state: RootState) => hidePopupNavSelector(state, { viewName, viewConfig }));
    const res = useSingleKeyCachingExpression(hidePopupNav, undefined, false);
    return res;
};

export const HidePopupNavController: React.FC<{ children: JSX.Element; viewName: string }> = ({
    children,
    viewName,
}) => {
    const hide = useHidePopupNav(viewName);
    if (hide) {
        return null;
    }
    return children;
};
