import { combineReducers } from 'redux';
import * as pt from '../actions';
import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import { PrintTemplate } from 'printTemplate/definitions';

export interface EntityConfList {
    [entityConfId: string]: string[];
}
const entityConfList = (previousState: EntityConfList = {}, action: RootAction): EntityConfList => {
    switch (action.type) {
        case getType(pt.getPrintTemplatesByEntityConfIdSuccess): {
            const { entityConfId } = action.requestPayload;
            return {
                ...previousState,
                [entityConfId]: action.payload.map((pt) => pt.id),
            };
        }
        default:
            return previousState;
    }
};

interface ByProperty {
    [key: string]: PrintTemplate;
}
const byProperty =
    (property: keyof PrintTemplate) =>
    (previousState: ByProperty = {}, action: RootAction): ByProperty => {
        switch (action.type) {
            case getType(pt.getPrintTemplateByNameSuccess):
            case getType(pt.getPrintTemplatesByEntityConfIdSuccess): {
                return Object.assign(
                    {},
                    previousState,
                    ...action.payload.map((pt): ByProperty => ({ [pt[property]]: pt })),
                );
            }
            default:
                return previousState;
        }
    };

const byId = byProperty('id');
const byName = byProperty('name');

export default combineReducers({
    entityConfList,
    byId,
    byName,
});
