import * as fetchPotentialUsers from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, exhaustMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { deserialize } from 'reducers/lists/list/serializeDeserialize';

const fetchPotentialUsersFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(fetchPotentialUsers.getPotentialUsers)),
        withLatestFrom(state$.pipe(map((state) => deserialize(state.bpm.potentialUsers).isSuccess()))),
        filter(([action, someSuccess]) => !someSuccess),
        exhaustMap(([action]) =>
            services.getAllPotentialUsers().pipe(
                map((resp) => fetchPotentialUsers.getPotentialUsersSuccess(resp)),
                catchError((e) => of(fetchPotentialUsers.getPotentialUsersFailure(e))),
            ),
        ),
    );
export default fetchPotentialUsersFlow;
