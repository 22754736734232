import buildHeaders from 'sideEffect/buildHeaders';
const config = require('../config.js');

export function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => {
        binary += String.fromCharCode(b);
    });
    return window.btoa(binary);
}

const getContent = (url: string) =>
    fetch(`${config.BACKEND_BASE_URL}${url}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
        }),
    });
export default getContent;
