import React, { Ref } from 'react';
import Downshift, { StateChangeOptions } from 'downshift';
import { ControllerStateAndHelpers } from 'downshift';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { lifecycle, compose } from 'recompose';
import { RootState } from '../../../reducers/rootReducer';
import { getValueSetForFieldExpr } from '../../../components/generics/utils/viewConfigUtils/index';
import { getConceptsByValueSetAndGroup } from '../../../components/generics/utils/valueSetsUtil';
import { loadValueSet as loadValueSetAction, loadValueSetGroup as loadValueSetGroupAction } from 'valueSets/actions';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import Clear from '@material-ui/icons/Clear';
import { TextField, MenuItem, Paper, IconButton, Tooltip } from '@material-ui/core';
import FieldTitle from './aor/FieldTitle';
import { ValuesetKeeperArounder } from './ValuesetKeeperArounder';
import classnames from 'classnames';
import { WithStyles, createStyles, Theme } from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { IntlShape, useIntl } from 'react-intl';
import { EvaluateFormattedMessage, evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import sortBy from 'lodash/sortBy';
import { TextFieldUtils } from '../hooks/useTextFieldUtils';
import { Concept } from './Concept';

type Input = any;
type Meta = any;

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'auto',
            maxHeight: 300,
            left: 0,
            minWidth: '100%',
        },
        popoverPaper: {
            maxHeight: 150,
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            maxHeight: 150,
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        inputRoot: {
            flexWrap: 'wrap',
            // maxWidth: '95%',
            paddingRight: '15px',
        },
        iconButtonWithLabelSpacing: {
            position: 'absolute',
            right: 0,
            top: theme.spacing(2),
            height: 30,
            width: 30,
            padding: 0,
        },
        iconButtonWithoutLabelSpacing: {
            position: 'absolute',
            right: 0,
            top: 0,
            height: 30,
            width: 30,
            padding: 0,
        },
    });

export function renderInput(inputProps: {
    fullWidth: boolean;
    InputProps: Input;
    classes: { inputRoot: string; iconButton: string };
    ref?: Ref<any>;
    clearSelection: () => void;
    label?: string;
    meta: Meta;
    isRequired?: boolean;
    disabled?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    readOnlyIfDisabled?: boolean;
    InputLabelProps: {};
}) {
    const {
        InputProps,
        classes,
        ref,
        clearSelection,
        meta,
        isRequired = false,
        label,
        disabled = false,
        ariaInputProps,
        renderLabel,
        readOnlyIfDisabled,
        InputLabelProps,
        ...other
    } = inputProps;
    const { touched, error } = meta;
    return (
        <div style={{ position: 'relative' }}>
            <TextFieldUtils meta={meta}>
                {({
                    createInputLabelProps,
                    fieldVariant,
                    muiErrorProp,
                    InputPropsClasses,
                    helperText,
                    createFormHelperTextProps,
                    classnames,
                }) => (
                    <EvaluateFormattedMessage>
                        {({ evaluateFormattedMessage, translate }) => (
                            <TextField
                                margin="none"
                                InputLabelProps={createInputLabelProps({
                                    shrink: true,
                                    ...InputLabelProps,
                                })}
                                variant={fieldVariant}
                                label={
                                    renderLabel && (
                                        <FieldTitle
                                            label={label}
                                            isRequired={isRequired}
                                            appendScreenreaderText=" (Type to filter results)"
                                        />
                                    )
                                }
                                InputProps={{
                                    inputRef: ref,
                                    classes: {
                                        ...InputPropsClasses,
                                        root: classnames(InputPropsClasses.root, classes.inputRoot),
                                    },
                                    inputProps: {
                                        ...InputProps,
                                        disabled: disabled && !readOnlyIfDisabled,
                                    },
                                    readOnly: disabled && readOnlyIfDisabled,
                                }}
                                error={muiErrorProp}
                                helperText={helperText}
                                FormHelperTextProps={createFormHelperTextProps(InputProps)}
                                disabled={disabled}
                                // multiline={true}
                                // downshift requires an input and not textarea
                                {...other}
                            />
                        )}
                    </EvaluateFormattedMessage>
                )}
            </TextFieldUtils>
        </div>
    );
}

interface DownshiftValuesetSelectProps {
    emptyText?: string;
    options?: {
        id: string;
    };
    label?: string;
    input: Input;
    meta: Meta;
    isRequired?: boolean;
    disabled?: boolean;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    ariaInputProps?: {};
    renderLabel?: boolean;
    valueSet?: string;
    resource?: string;
    source: string;
    group?: string | null;
    conceptIds?: string[];
    shouldFetchValueset?: boolean;
    isPopover?: boolean; // used to determine size of dropdown
    overrideAriaLabel?: string;
    isForShow?: boolean;
    readOnlyIfDisabled?: boolean;
    record?: {};
}
type ConnectedProps = DownshiftValuesetSelectProps & {
    intl: IntlShape;
};

const makeMapStateToProps = () => {
    const emptyObj = {};
    const getEntities = createGetEntities();
    const getConcepts = createSelector(
        getEntities,
        (entities) => ((entities as any).Concept || emptyObj) as { [id: string]: Concept },
    );
    const conceptListSelector = createSelector(
        (state: RootState, props: ConnectedProps) => props.conceptIds,
        getValueSetCode,
        getValueSetGroup,
        (state: RootState, props: ConnectedProps) => {
            const valueSets = state.valueSets;
            const valueSetCode = getValueSetCode(state, props);
            return valueSets && valueSetCode && valueSets[valueSetCode];
        },
        getConcepts,
        (state: RootState, props: ConnectedProps) => {
            if (props.isForShow && props.record) {
                return get(props.record, props.source);
            }
            return props.meta && props.meta.initial;
        },
        (state: RootState, props: ConnectedProps) => props.intl,
        (
            conceptIds,
            valueSetCode,
            valueSetGroup,
            valueSet,
            concepts: { [id: string]: Concept },
            initial,
            intl: IntlShape,
        ) => {
            const applyIntlToConcept = (concept: Concept) => {
                if (concept?.display?.includes('%{')) {
                    return {
                        ...concept,
                        display: evaluateFormattedText(intl, concept.display),
                    };
                }
                return concept;
            };
            const conceptsList: Concept[] = sortBy(
                (conceptIds
                    ? conceptIds.map((id) => concepts[id]).filter((c) => c)
                    : valueSet
                    ? // return this + initial value even if not in the group
                      (() => {
                          const _conceptsList = getConceptsByValueSetAndGroup(valueSet, concepts, valueSetGroup);
                          if (initial && concepts[initial] && !_conceptsList.some((c) => c.id === initial)) {
                              return [..._conceptsList, concepts[initial]];
                          }
                          return _conceptsList;
                      })()
                    : []
                ).map((concept) => applyIntlToConcept(concept)),
                'sortOrder',
            );

            const dataTableById: { [id: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.id] = o));
                return dt;
            })();
            const dataTableByDisplay: { [display: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.display.toLowerCase()] = o));
                return dt;
            })();
            return {
                dataSource: conceptsList,
                dataTableById,
                dataTableByDisplay,
                valueSetCode,
            };
        },
    );
    return (state: RootState, props: ConnectedProps) => conceptListSelector(state, props);
};

interface DownshiftValuesetSelectComponentProps
    extends DownshiftValuesetSelectProps,
        ReturnType<ReturnType<typeof makeMapStateToProps>>,
        WithStyles<typeof styles> {}
interface DownshiftValuesetSelectState {
    inputValue: string;
    selectedId: string | null;
}
class DownshiftValuesetSelect extends React.Component<
    DownshiftValuesetSelectComponentProps,
    DownshiftValuesetSelectState
> {
    private inputRef: React.RefObject<HTMLInputElement>;
    public valuesetKeeperArounder: ValuesetKeeperArounder = new ValuesetKeeperArounder();
    private errorMessageId = uniqueId('errorMessage');
    private overrideLabelId = uniqueId('overrideLabel');
    private uniqueNameToThrowOffChromeAutoFill = new Date().toISOString();
    constructor(props: DownshiftValuesetSelectComponentProps) {
        super(props);
        this.state = {
            inputValue: this.props.input ? this.getDisplayFromId(this.props.input.value) : '',
            selectedId: this.props.input ? this.props.input.value : null,
        };
        if (this.state.selectedId !== null) {
            this.valuesetKeeperArounder.addAll([this.state.selectedId]);
        }
        this.inputRef = React.createRef();
    }
    componentWillReceiveProps({ input: { value = null } = {}, dataTableById }: DownshiftValuesetSelectComponentProps) {
        if (
            this.state.selectedId !== value ||
            (this.props.input && this.props.input.value !== value) ||
            this.props.dataTableById !== dataTableById
        ) {
            if (value !== null) {
                this.valuesetKeeperArounder.addAll([value]);
            }
            this.setState({
                selectedId: value,
                inputValue: this.getDisplayFromId(value),
            });
        }
    }

    getDisplayFromId = (id) => ((this.props.dataTableById || {})[id] || { display: '' }).display;

    getSuggestions = (inputValue: string) => {
        let count = 0;

        return this.props.dataSource
            .filter(
                (suggestion) =>
                    suggestion.active ||
                    // only keep encountered concepts IF we aren't explicitly filtering conceptIds
                    (!this.props.conceptIds && this.valuesetKeeperArounder.encountered(suggestion.id)),
            )
            .filter((suggestion) => {
                const keep =
                    (!inputValue || suggestion.display.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
                    count < 1000;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    renderSuggestion = ({
        suggestion,
        index,
        itemProps,
        highlightedIndex,
        selectedItem,
    }: {
        suggestion: Concept;
        index?: number;
        itemProps?: {};
        highlightedIndex: number | null;
        selectedItem?: string;
    }) => {
        const isHighlighted = highlightedIndex === index;
        const isSelected =
            ((selectedItem && this.props.dataSource[selectedItem]) || '').indexOf(suggestion.display) > -1;
        return (
            <MenuItem
                data-value={suggestion.code}
                {...itemProps}
                key={suggestion.id || 'nullitem'}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {suggestion.display}
            </MenuItem>
        );
    };

    handleInputChange = (event) => {
        const inputValue = event.target.value;
        this.setState({ inputValue });
    };
    handleChange = (item: Concept | null) => {
        this.setState(
            {
                inputValue: item ? item.display : '',
                selectedId: item ? item.id : null,
            },
            () => {
                this.props.input.onChange?.(item ? item.id : item);
                this.props.input.onBlur(item ? item.id : item);
            },
        );
    };

    handleDelete = () => {
        this.handleChange(null);
    };
    handleStateChange = (changes: StateChangeOptions<Concept>) => {
        const { dataTableByDisplay } = this.props;
        if (changes.hasOwnProperty('selectedItem')) {
            this.handleChange(changes.selectedItem as Concept | null);
        } else if (changes.hasOwnProperty('inputValue')) {
            const text = typeof changes.inputValue === 'string' ? changes.inputValue.toLowerCase() : null;
            if (text && dataTableByDisplay[text] && dataTableByDisplay[text].active) {
                this.handleChange(dataTableByDisplay[text]);
            }
        }
    };
    handleKeyDown = (closeMenu: () => void) => (e: React.KeyboardEvent) => {
        if (e.key === 'Tab' && !e.shiftKey) {
            closeMenu();
            this.setState((state) => ({ ...state, inputValue: this.getDisplayFromId(state.selectedId) }));
        }
    };
    render() {
        const {
            classes,
            emptyText = 'None selected',
            disabled = false,
            // options: { id: inputId } = { id: 'valueset-single' }, <- ids should be unique...
            dropdownPosition = 'below',
            ariaInputProps = {},
            renderLabel = true,
            isPopover = false,
            tooltipText,
            overrideAriaLabel,
        } = this.props;
        const { inputValue, selectedId } = this.state;
        const paperClass = dropdownPosition === 'below' ? classes.paper : classes.paperTop;
        const El = (
            <div className={classes.root} style={{ position: 'relative' }}>
                {overrideAriaLabel ? (
                    <div id={this.overrideLabelId} className="casetivity-off-screen">
                        {overrideAriaLabel}
                    </div>
                ) : null}
                <Downshift
                    labelId={overrideAriaLabel ? this.overrideLabelId : undefined}
                    itemToString={(item) => item && item.display}
                    inputValue={inputValue}
                    // onChange={this.handleChange}
                    onStateChange={this.handleStateChange}
                    selectedItem={(selectedId && this.props.dataTableById[selectedId]) || ''}
                    onSelect={(selectedItem: Concept, stateAndHelpers: ControllerStateAndHelpers<Concept>) => {
                        this.setState({
                            inputValue: (stateAndHelpers.selectedItem || { display: '' }).display,
                        });
                    }}
                    onOuterClick={() => {
                        if (selectedId) {
                            const conc = this.props.dataTableById[selectedId];
                            const selectedDisplay = conc && conc.display;
                            if (selectedDisplay && selectedDisplay !== inputValue) {
                                this.setState({ inputValue: selectedDisplay });
                            }
                        } else {
                            this.setState({ inputValue: '' });
                        }
                    }}
                >
                    {({
                        getInputProps,
                        getItemProps,
                        getMenuProps,
                        getLabelProps,
                        isOpen,
                        inputValue: inputValue2,
                        selectedItem: selectedItem2,
                        highlightedIndex,
                        clearSelection,
                        openMenu,
                        closeMenu,
                    }) => (
                        <div className={classes.container}>
                            {renderInput({
                                readOnlyIfDisabled: this.props.readOnlyIfDisabled,
                                ref: this.inputRef,
                                fullWidth: true,
                                classes: {
                                    inputRoot: classes.inputRoot,
                                    iconButton: renderLabel
                                        ? classes.iconButtonWithLabelSpacing
                                        : classes.iconButtonWithoutLabelSpacing,
                                },
                                InputProps: getInputProps({
                                    'aria-haspopup': true,
                                    autoComplete: 'never',
                                    name: this.uniqueNameToThrowOffChromeAutoFill,
                                    placeholder: selectedItem2 ? selectedItem2.display : emptyText,
                                    onChange: this.handleInputChange,
                                    onKeyDown: this.handleKeyDown(closeMenu),
                                    onClick: () => !disabled && openMenu(),
                                    onBlur: () => {
                                        this.props.input.onBlur();
                                    },
                                    onFocus: () => openMenu(),
                                    disabled,
                                    'aria-errormessage':
                                        this.props.meta.touched && this.props.meta.error
                                            ? this.errorMessageId
                                            : undefined,
                                }),
                                InputLabelProps: (() => {
                                    const labelProps = getLabelProps();
                                    if (!overrideAriaLabel) {
                                        return labelProps;
                                    }
                                    // if using overrideAriaLabel, we have our own hidden label we are rendering using the id.
                                    const { id, ...rest } = labelProps;
                                    return rest;
                                })(),
                                clearSelection,
                                label: this.props.label,
                                meta: this.props.meta,
                                ariaInputProps,
                                renderLabel,
                                isRequired: this.props.isRequired,
                                disabled,
                            })}
                            {!isOpen ? <div {...getMenuProps()} /> : null}
                            {isOpen ? (
                                <Paper
                                    {...getMenuProps()}
                                    className={isPopover ? classnames(paperClass, classes.popoverPaper) : paperClass}
                                    square={true}
                                >
                                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                                        this.renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem: selectedItem2,
                                        }),
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    )}
                </Downshift>
                {this.props.input.value && !disabled && (
                    <IconButton
                        color="inherit"
                        classes={{
                            root: renderLabel
                                ? classes.iconButtonWithLabelSpacing
                                : classes.iconButtonWithoutLabelSpacing,
                        }}
                        onClick={() => {
                            this.props.input.onChange?.({ target: { value: null } });
                            this.props.input.onBlur(null);
                            this.inputRef.current.focus();
                        }}
                        aria-label={`Clear value for "${this.props.overrideAriaLabel ?? this.props.label}"`}
                    >
                        <Clear />
                    </IconButton>
                )}
            </div>
        );
        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                    <div>{El}</div>
                </Tooltip>
            );
        }
        return El;
    }
}

const getValueSetCode = (state: RootState, props: DownshiftValuesetSelectProps) =>
    props.valueSet ||
    /* REMOVE AFTER */ (props as any).singleSelectValueSet ||
    /* REMOVE BEFORE */ getValueSetForFieldExpr(
        state.viewConfig,
        props.resource,
        props.source.endsWith('Id') ? props.source.slice(0, -2) : props.source, // pop off 'Id'
        'TRAVERSE_PATH',
    );
const getValueSetGroup = (state: RootState, props: DownshiftValuesetSelectProps) => props.group || null;

const dispatches = {
    loadValueSet: loadValueSetAction,
    loadValueSetGroup: loadValueSetGroupAction,
};
export type ValuesetHocInjectedProps = ReturnType<ReturnType<typeof makeMapStateToProps>> & typeof dispatches;
type LifecycleProps = DownshiftValuesetSelectProps & ValuesetHocInjectedProps;
export const valuesetOneHoc = compose(
    (BaseComponent) => (props: DownshiftValuesetSelectProps) => {
        const intl = useIntl();
        return <BaseComponent {...props} intl={intl} />;
    },
    connect(makeMapStateToProps, dispatches),
    lifecycle({
        componentDidMount() {
            const {
                valueSetCode,
                loadValueSet,
                loadValueSetGroup,
                shouldFetchValueset = true,
                group,
            } = this.props as LifecycleProps;
            if (valueSetCode && shouldFetchValueset) {
                if (group) {
                    group.split(',').forEach((g) => {
                        loadValueSetGroup(valueSetCode, g);
                    });
                } else {
                    loadValueSet(valueSetCode);
                }
            }
        },
        componentWillReceiveProps(nextProps: LifecycleProps) {
            const { valueSetCode, group, loadValueSetGroup, loadValueSet } = this.props as LifecycleProps;
            if ((valueSetCode !== nextProps.valueSetCode || group !== nextProps.group) && nextProps.valueSetCode) {
                if (nextProps.group) {
                    nextProps.group.split(',').forEach((g) => {
                        loadValueSetGroup(nextProps.valueSetCode, g);
                    });
                } else {
                    loadValueSet(nextProps.valueSetCode);
                }
            }
        },
    }),
    // need this to work around recompose bug
    (BaseComponent) => (props) => <BaseComponent {...props} />,
    // We were rerendering because 'style' and 'meta' changed.
    // even though there was no reason to.
    // This would be fine, except for some reason JAWS was reading the value of the input when this would happen.
    // See VEA-2405
    // If we refactor the main component to use hooks, and useMemo/useCallback the props passed to <Downshift />
    // I think that would probably be a better way to handle this.
    // So the below can be removed on refactor.
    (BaseComponent) =>
        ({ style, meta: _meta, ...props }) => {
            const meta = React.useMemo(() => {
                return _meta;
            }, [_meta?.touched, _meta?.error, _meta?.warning]); // eslint-disable-line
            return <BaseComponent meta={meta} {...props} />;
        },
    (BaseComponent) => React.memo((props) => <BaseComponent {...props} />),
);

const ValuesetSelect: React.FC<DownshiftValuesetSelectProps> = valuesetOneHoc(
    withStyles(styles)(DownshiftValuesetSelect),
);
export default ValuesetSelect;
