import React from 'react';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const TranslatedText: React.FC<{ value: string; elStyle?: React.CSSProperties }> = (props) => {
    const { elStyle, value } = props;
    const result = useEvaluatedFormattedMessage(value);
    return <span style={elStyle}>{result}</span>;
};

interface TextFieldProps {
    addLabel?: boolean;
    elStyle?: {};
    label?: string;
    record?: {};
    source: string;
    translate?: boolean;
}
const TextField: React.SFC<TextFieldProps> = ({ source, record = {}, elStyle, translate = false }) => {
    const value = get(record, source);
    if (translate) {
        return <TranslatedText value={value ?? ''} />;
    }
    return <span style={elStyle}>{value}</span>;
};

const PureTextField = pure(TextField);

PureTextField.defaultProps = {
    addLabel: true,
};

export default PureTextField;
