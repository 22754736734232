import React from 'react';
import { fromNullable } from 'fp-ts/lib/Option';
import { getLabelForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { createStyles, Theme, CardContent, CardActions, Card, makeStyles } from '@material-ui/core';
import { Dialog, Button } from '@material-ui/core';
import { ViewField } from 'reducers/ViewConfigType';
import { tryCatch } from 'fp-ts/lib/Either';
import flatten from 'flat';
import { identity } from 'fp-ts/lib/function';
import { OpenDialogData } from '../';
import useLinkedEntitySyncErrors from './hooks/useLinkedEntitySyncErrors';
import useResourceDisplayName from 'util/hooks/useResourceDisplayName';
import useViewConfig from 'util/hooks/useViewConfig';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import uniqBy from 'lodash/uniqBy';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import ErrorsListItems from 'bpm/components/TaskDetail/TaskForm/ErrorsList/ErrorsList';
import { EntityFormContextRef } from '../../../../types';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
    createStyles({
        error: {
            color: palette.error.main,
        },
    }),
);

interface ErrorDialogProps {
    resource: string;
    errorDialogOpen: OpenDialogData | false;
    closeDialog: () => void;
    entityFormContextRef?: EntityFormContextRef;
}
const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = (props) => {
    const { errorDialogOpen } = props;
    const classes = useStyles(props);
    const formSyncErrors = useLinkedEntitySyncErrors();
    const resourceDisplayName = useResourceDisplayName(props.resource);
    const viewConfig = useViewConfig();
    const viewName = props.entityFormContextRef?.current?.viewName;
    return (
        <Dialog
            onClose={props.closeDialog}
            open={!!errorDialogOpen}
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
        >
            <Card>
                <CardContent>
                    <div role="alert" style={{ marginBottom: '-1em' }}>
                        {Object.values(flatten(formSyncErrors)).length > 0 ? (
                            <Alert role={undefined} severity="error" style={{ marginBottom: '1em' }}>
                                <AlertTitle id="linked-entity-errors-title">{resourceDisplayName} Errors</AlertTitle>
                                <ul aria-labelledby="linked-entity-errors-title">
                                    {uniqBy(
                                        Object.entries(flatten(formSyncErrors)).map(([k, e]) => {
                                            let label: string | null | undefined;
                                            try {
                                                label = fromNullable(viewConfig)
                                                    .mapNullable((vc) => vc.views[viewName])
                                                    .map((v) => {
                                                        const fields: {
                                                            [fieldId: string]: ViewField;
                                                        } = fromNullable(v.tabs)
                                                            .map((tabs) => Object.values(tabs).map((t) => t.fields))
                                                            .fold(v.fields, (tabFields) =>
                                                                Object.assign({}, v.fields, ...tabFields),
                                                            );
                                                        return fields;
                                                    })
                                                    .map((f) => {
                                                        return (
                                                            f[k] ||
                                                            (k.endsWith('Code')
                                                                ? f[k.slice(0, -4)]
                                                                : k.endsWith('Id')
                                                                ? f[k.slice(0, -2)]
                                                                : k.endsWith('Ids')
                                                                ? f[k.slice(0, -3)]
                                                                : f[k])
                                                        );
                                                    })
                                                    .chain(fromNullable)
                                                    .map((f) => f.label)
                                                    .chain(fromNullable)
                                                    .foldL(
                                                        () => {
                                                            return tryCatch(() =>
                                                                getLabelForFieldExpr(
                                                                    viewConfig,
                                                                    props.resource,
                                                                    k,
                                                                    'TRAVERSE_PATH',
                                                                ),
                                                            ).fold((l) => {
                                                                if (k.endsWith('Code')) {
                                                                    return tryCatch(() =>
                                                                        getLabelForFieldExpr(
                                                                            viewConfig,
                                                                            props.resource,
                                                                            k.slice(0, -4),
                                                                            'TRAVERSE_PATH',
                                                                        ),
                                                                    ).getOrElse(k);
                                                                } else if (k.endsWith('Id')) {
                                                                    return tryCatch(() =>
                                                                        getLabelForFieldExpr(
                                                                            viewConfig,
                                                                            props.resource,
                                                                            k.slice(0, -2),
                                                                            'TRAVERSE_PATH',
                                                                        ),
                                                                    ).getOrElse(k);
                                                                } else if (k.endsWith('Ids')) {
                                                                    return tryCatch(() =>
                                                                        getLabelForFieldExpr(
                                                                            viewConfig,
                                                                            props.resource,
                                                                            k.slice(0, -3),
                                                                            'TRAVERSE_PATH',
                                                                        ),
                                                                    ).getOrElse(k);
                                                                }
                                                                return k;
                                                            }, identity);
                                                        },
                                                        (l) => l,
                                                    );
                                            } catch (e) {
                                                console.error(e);
                                            }
                                            return [label || k, e] as [string, unknown];
                                        }),
                                        ([label, message]) => label + ':' + message,
                                    ).map(([label, message]) => {
                                        return (
                                            <EvaluateFormattedMessage key={label + ':' + message}>
                                                {({ evaluateFormattedMessage }) => (
                                                    <li className={classes.error}>{`${label}: ${
                                                        typeof message === 'string'
                                                            ? evaluateFormattedMessage(message)
                                                            : message
                                                    }`}</li>
                                                )}
                                            </EvaluateFormattedMessage>
                                        );
                                    })}
                                </ul>
                            </Alert>
                        ) : null}
                        {errorDialogOpen &&
                        errorDialogOpen.submissionType !== 'save' &&
                        Object.keys(errorDialogOpen.getTaskFormErrors()).length > 0 ? (
                            <div>
                                <Alert
                                    role="alertdialog"
                                    aria-live="assertive"
                                    severity="error"
                                    style={{ marginBottom: '1em' }}
                                >
                                    <AlertTitle id="task-errors-title">Task Errors</AlertTitle>
                                    <ul aria-labelledby="task-errors-title" style={{ paddingLeft: '0px' }}>
                                        <ErrorsListItems
                                            classes={{
                                                li: classes.error,
                                            }}
                                            fields={errorDialogOpen.formDefinition?.fields}
                                            formErrors={errorDialogOpen.getTaskFormErrors()}
                                        />
                                    </ul>
                                </Alert>
                            </div>
                        ) : null}
                    </div>
                </CardContent>
                <CardActions>
                    <Button style={{ margin: '2px' }} variant="contained" color="primary" onClick={props.closeDialog}>
                        Close
                    </Button>
                </CardActions>
            </Card>
        </Dialog>
    );
};

export default ErrorDialog;
