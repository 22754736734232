interface DialogInfo {
    message: string;
    onContinue: () => void;
}

class TaskClaimedBySomeoneElsePopupRegistry {
    private subscriptions: ((dialogInfo: DialogInfo) => void)[] = [];
    public registerCallback(cb: (dialogInfo: DialogInfo) => void) {
        this.subscriptions.push(cb);
    }
    public unregisterCallback(cb: (dialogInfo: DialogInfo) => void) {
        this.subscriptions = this.subscriptions.filter((_cb) => _cb !== cb);
    }
    public dispatchTaskClaimedBySomeoneElsePopupRegistry(dialogInfo: DialogInfo) {
        this.subscriptions.forEach((cb) => {
            cb(dialogInfo);
        });
    }
}
export const getTaskClaimedBySomeoneElsePopupRegistry = (() => {
    let taskClaimedBySomeoneElsePopupRegistry: TaskClaimedBySomeoneElsePopupRegistry;
    return () => {
        if (!taskClaimedBySomeoneElsePopupRegistry) {
            taskClaimedBySomeoneElsePopupRegistry = new TaskClaimedBySomeoneElsePopupRegistry();
        }
        return taskClaimedBySomeoneElsePopupRegistry;
    };
})();
