import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import {
    getLongestRefonePathInPath,
    getPathBackFromFieldPath,
    getRefEntityName,
} from 'components/generics/utils/viewConfigUtils';

interface GetParentFieldInChildProps<ViewConfig extends { entities: ViewConfigEntities }> {
    /*
        Id is necessary to pass to the PopoverCreateButton so it sets the data on create
        .id matches the way our list references are set, so use that to create List props
    */
    endWith?: '.id' | 'Id';
    /*
        linkedEntityFormat describes how we should name linked-entity fields in "parentFieldInChild" field-path
        linked<entityType> is used for query parameters,
        e.g. ?linkedStaff.userId=<foo>&...
        linkedEntity is used to set the reference on Create.
        e.g. { "linkedEntityId": "<foo>"}
    */
    linkedEntityFormat: 'linkedEntity' | 'linked<entityType>';
    parentEntityName: string;
    source: string;
    viewConfig: ViewConfig;
}
const getParentFieldInChild = <ViewConfig extends { entities: ViewConfigEntities }>({
    parentEntityName,
    source,
    linkedEntityFormat,
    endWith = 'Id',
    viewConfig,
}: GetParentFieldInChildProps<ViewConfig>): {
    parentFieldInChild: string;
    newParentEntityName: string;
} => {
    const sourceUntilLastReachableValue = getLongestRefonePathInPath(viewConfig, parentEntityName, source);
    const newParentEntityName = sourceUntilLastReachableValue
        ? getRefEntityName(viewConfig, parentEntityName, sourceUntilLastReachableValue, 'TRAVERSE_PATH')
        : parentEntityName;

    const parentFieldInChild =
        getPathBackFromFieldPath(
            viewConfig,
            newParentEntityName,
            (() => {
                if (!sourceUntilLastReachableValue) {
                    return source;
                }
                if (!source.startsWith(sourceUntilLastReachableValue)) {
                    throw new Error(
                        `source "${source}" doesnt start with sourceUntilLastReachableValue "${sourceUntilLastReachableValue}"`,
                    );
                }
                const newFieldPointedToInChild = source.slice(sourceUntilLastReachableValue.length);
                return newFieldPointedToInChild.startsWith('.')
                    ? newFieldPointedToInChild.slice(1)
                    : newFieldPointedToInChild;
            })(),
            linkedEntityFormat,
        ) + endWith;
    return {
        parentFieldInChild,
        newParentEntityName,
    };
};

export default getParentFieldInChild;
