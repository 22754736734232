import { BACKEND_BASE_URL } from 'config';
import { currentUserProperties } from 'expressions/contextUtils/currentUser';
import { evaluateFilterString } from 'fieldFactory/popovers/PopoverRefInput/evaluteFilterString';
import ViewConfig from 'reducers/ViewConfigType';
import splitByRegex from './util/splitByRegex';

// regular expressions map onto functions for the desired transformation.
let urlRewriteRegistry: [
    string,
    (args: { url: string; match: string; beforeMatch: string; afterMatch: string }) => string,
][] = [];

// useful for debugging and checking what's in the registry
(window as any).getUrlRewriteRegistry = () => urlRewriteRegistry;

export const getMappedUrl = (url: string) => {
    const matchingMappers = urlRewriteRegistry.flatMap(([regexp, mapUrl]) => {
        const matchFound = splitByRegex(regexp)(url);
        if (matchFound) {
            return [
                {
                    matchFound,
                    mapUrl,
                },
            ];
        }
        return [];
    });
    if (matchingMappers && matchingMappers.length > 0) {
        const {
            mapUrl,
            matchFound: { matching, beforeMatch, afterMatch },
        } = matchingMappers[0];
        return mapUrl({
            url,
            match: matching,
            beforeMatch,
            afterMatch,
        });
    }
    return url;
};

const getRegisterUrlRewrite =
    () =>
    <VC extends { user: ViewConfig['user'] }>(userContext: VC) =>
    (args: { regexp: string; rewrite: string }) => {
        const evaluateRewriteOnMatch = ({
            url,
            match,
            beforeMatch,
            afterMatch,
        }: {
            url: string;
            match: string;
            beforeMatch: string;
            afterMatch: string;
        }): string => {
            return evaluateFilterString(args.rewrite, {
                url,
                match,
                beforeMatch,
                afterMatch,
                user: userContext.user,
                BACKEND_BASE_URL,
                ...currentUserProperties({ user: userContext.user }),
            });
        };
        urlRewriteRegistry.push([args.regexp, evaluateRewriteOnMatch]);
    };
/*
    When called,
    This requires the current basicInfo (which contains rewrite configuration)
    as well as the current viewConfig (which contains user properties we might want access to)
*/
export const registerUrlRewrite = getRegisterUrlRewrite();

export const clearRegistry = () => {
    urlRewriteRegistry = [];
};
