import { RootState } from 'reducers/rootReducer';
import { ValueSets } from 'valueSets/reducer';

interface EntityState {
    lastIsLoading: boolean | null; // null means 'initial'
    entities: {};
}
export const createGetEntities = () => {
    const cachedEntities: EntityState = {
        lastIsLoading: null,
        entities: {},
    };
    const getEntities = (state: RootState) => {
        const isLoading = state.admin.loading > 0;
        if (cachedEntities.lastIsLoading === null) {
            cachedEntities.lastIsLoading = isLoading;
            cachedEntities.entities = state.admin.entities;
        }

        if (cachedEntities.lastIsLoading !== isLoading) {
            if (!isLoading) {
                cachedEntities.entities = state.admin.entities;
            }
            cachedEntities.lastIsLoading = isLoading;
        }
        return cachedEntities.entities;
    };
    return getEntities;
};

interface ValueSetsState {
    lastIsLoading: boolean | null; // null means 'initial'
    valuesets: ValueSets;
}
export const createGetValueSets = () => {
    const cachedEntities: ValueSetsState = {
        lastIsLoading: null,
        valuesets: {},
    };
    const getValueSets = (state: RootState) => {
        const isLoading = state.admin.loading > 0;
        if (cachedEntities.lastIsLoading === null) {
            cachedEntities.lastIsLoading = isLoading;
            cachedEntities.valuesets = state.valueSets;
        }

        if (cachedEntities.lastIsLoading !== isLoading) {
            if (!isLoading) {
                cachedEntities.valuesets = state.valueSets;
            }
            cachedEntities.lastIsLoading = isLoading;
        }
        return cachedEntities.valuesets;
    };
    return getValueSets;
};
