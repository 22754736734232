import { useDispatch } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { crudGetOne as crudGetOneAction } from 'sideEffect/crud/getOne/actions';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { submitAction } from '../submitAction';
import useViewConfig from 'util/hooks/useViewConfig';
import { useContext, useCallback } from 'react';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/processPageRefreshContext';
import useWorkingState from './useWorkingState';
import ViewConfig from 'reducers/ViewConfigType';
import deepSubmit from 'util/deepSubmit';
import { isRefManyManyField } from 'components/generics/utils/viewConfigUtils';
import { useGetCasetivityRemovedFields } from 'components/generics/form/EnhancedRGridTabbable';
import { taskFormEventType } from 'bpm/actions/taskFormEvent';
import modifySubmissionValues from 'components/generics/genericEdit/modifySubmissionValues';
import { EntityFormContextRef } from 'bpm/components/TaskDetail/TaskForm/TaskForm/types';

const adjustForSubmit = (
    viewConfig: ViewConfig,
    dirtyValues: {}, // <- we can assume this contains only edited fields
    initialValues: {
        id: string;
        entityType: string;
        entityVersion: number;
    },
) => {
    const values = Object.assign(
        {
            id: initialValues.id,
            entityVersion: initialValues.entityVersion,
            entityType: initialValues.entityType,
        },
        dirtyValues,
    );

    Object.entries(values).forEach((t) => {
        const [key /* value */] = t;
        /*
        Remove many-manys for now
        (onEdits we don't need the Ids anymore if the components are in 'commitChanges' mode).
        */
        if (
            key.endsWith('Ids') &&
            isRefManyManyField(viewConfig, initialValues.entityType, key.slice(0, -3), 'TRAVERSE_PATH')
        ) {
            delete values[key];
        }
    });
    return deepSubmit(values);
};

const useCascadingSave = (
    resolveBeforeSuccess?: (submissionType: 'save' | 'submit') => Promise<void>,
    relatedEntityResource?: string,
    relatedEntityId?: string,
    entityFormContextRef?: EntityFormContextRef,
) => {
    const { clearWorkingState, setWorkingState, workingState } = useWorkingState();
    const dispatch = useDispatch();
    const viewConfig = useViewConfig();
    const casetivityRemovedFields = useGetCasetivityRemovedFields({
        fieldValues: { entityType: relatedEntityResource, id: relatedEntityId },
    });
    const { refresh: refreshProcessPage } = useContext(processPageRefreshContext);

    const cascadingSave = useCallback(
        (
            submissionType: 'save' | 'submit',
            _outcome: string | undefined,
            _outcomeDisplay: string | undefined,
            taskId: string,
            formDefinition: RootState['taskForms'][0],
            taskFormValues: {
                _outcome?: string;
                submissionType?: 'submit' | 'save';
            },
            fieldVisibility: {
                [field: string]: boolean;
            },
            entitySaveErrorCbs: {
                // code can be a number or '*'.
                '*': () => void;
                [code: number]: () => void;
            },
            taskFormErrorCb: () => void,
            onSuccess: (response?: {
                warning?: string;
                nextTaskId?: string;
                processComplete: boolean;
                error?: string;
            }) => void,
        ) => {
            const dirtyEntityFormValues = entityFormContextRef.current?.dirtyValues;
            const initialHiddenFields = entityFormContextRef.current?.initialFormContext?.hiddenFields;
            const entityInitialValues = entityFormContextRef.current?.initialValues as {
                id: string;
                entityVersion: number;
                entityType: string;
            };

            const getDispatchFormAction =
                (postEntitySave: boolean, refreshPageAfter: boolean = false) =>
                () => {
                    setWorkingState({
                        stage: postEntitySave ? 'WORKING_TASKFORM' : 'WORKING_TASKFORM_NO_ENTITY',
                        submissionType,
                    });
                    const taskValues = {
                        values: {
                            ...taskFormValues,
                            submissionType,
                            _outcome,
                            _outcomeDisplay,
                        },
                        fieldVisibility,
                    };
                    const handleError = () => {
                        setWorkingState({
                            stage: postEntitySave ? 'TASKFORM_FAILED' : 'TASKFORM_FAILED_NO_ENTITY',
                            submissionType,
                        });
                        setImmediate(() => {
                            taskFormErrorCb();
                            if (refreshPageAfter) {
                                refreshProcessPage();
                            }
                        });
                    };
                    dispatch(
                        submitAction(
                            taskValues,
                            taskId,
                            formDefinition,
                            (response) => {
                                const completeSuccess = async (_response) => {
                                    if (resolveBeforeSuccess && !_response?.error) {
                                        // by now, we have bypassed warnings, and have no errors.
                                        // therefore, I think we can assume safely that the task will be closed.
                                        // So lets delete offline data here, conditional on user input.
                                        await resolveBeforeSuccess(submissionType);
                                    }

                                    clearWorkingState();
                                    onSuccess(_response);
                                    if (refreshPageAfter) {
                                        refreshProcessPage();
                                    }
                                };
                                if (response && response.warning) {
                                    setWorkingState({
                                        stage: 'TASK_SAVE_WARNING',
                                        submissionType,
                                        taskFormResubmit: {
                                            warningMessage: response.warning,
                                            onCancel: () => {
                                                dispatch({
                                                    type: taskFormEventType.cancel,
                                                    payload: {
                                                        taskId,
                                                    },
                                                });
                                                taskFormErrorCb();
                                            },
                                            resubmit: () => {
                                                setWorkingState({
                                                    stage: postEntitySave
                                                        ? 'WORKING_TASKFORM'
                                                        : 'WORKING_TASKFORM_NO_ENTITY',
                                                    submissionType,
                                                });
                                                setImmediate(() => {
                                                    dispatch(
                                                        submitAction(
                                                            { ...taskValues, overrideWarning: true },
                                                            taskId,
                                                            formDefinition,
                                                            (response) =>
                                                                completeSuccess(response).finally(() => {
                                                                    dispatch({
                                                                        type: taskFormEventType.submitSuccess,
                                                                        payload: {
                                                                            taskId,
                                                                        },
                                                                    });
                                                                }),
                                                            handleError,
                                                            false,
                                                            // skip success action - dispatch it ourselves after completeSuccess
                                                            true,
                                                        ),
                                                    );
                                                });
                                            },
                                        },
                                    });
                                } else {
                                    // dispatch submitSuccess after async stuff completes so that formSaveNotifier is bypassed for all of completeSuccess
                                    completeSuccess(response).finally(() => {
                                        dispatch({
                                            type: taskFormEventType.submitSuccess,
                                            payload: {
                                                taskId,
                                            },
                                        });
                                    });
                                }
                            },
                            handleError,
                            false,
                            // skip success action - dispatch it ourselves after completeSuccess
                            true,
                        ),
                    );
                };
            if (dirtyEntityFormValues && Object.keys(dirtyEntityFormValues).length > 0 && entityInitialValues) {
                const { entityType, id } = entityInitialValues;
                setWorkingState({ stage: 'WORKING_ENTITY', submissionType });
                const adjustedSaveValues = adjustForSubmit(viewConfig, dirtyEntityFormValues, entityInitialValues);

                const finalValues = modifySubmissionValues({
                    hiddenFields: entityFormContextRef.current.hiddenFields,
                    viewConfig,
                    viewName: entityFormContextRef.current.viewName,
                    values: adjustedSaveValues,
                    casetivityRemovedFields,
                    initialHiddenFields,
                });

                dispatch(
                    crudUpdateAction({
                        resource: entityType,
                        data: { id, ...finalValues, partialUpdate: true },
                        previousData: entityInitialValues,
                        cb: (responseId) => {
                            if (responseId !== id) {
                                getDispatchFormAction(true, true)();
                            } else {
                                getDispatchFormAction(true)();
                            }
                        },
                        errorsCbs: {
                            409: () => {
                                dispatch(
                                    crudGetOneAction({
                                        resource: entityType,
                                        id,
                                        view: `${entityType}Edit`,
                                        cb: (responseId, responseData) => {
                                            if (`${id}` !== `${responseId}`) {
                                                refreshProcessPage();
                                                // Dedupe happened.
                                                // Need to referesh the AppCase/Task/Process, to figure out the new linkedEntity.
                                            }
                                        },
                                    }),
                                );
                            },
                            ...entitySaveErrorCbs,
                            '*': () => {
                                setWorkingState({ stage: 'ENTITY_FAILED', submissionType });
                                const providedCb = entitySaveErrorCbs['*'];
                                if (providedCb) {
                                    setImmediate(() => {
                                        providedCb();
                                    });
                                }
                            },
                        },
                        formId: 'record-form',
                    }),
                );
            } else {
                getDispatchFormAction(false)();
            }
        },
        [
            clearWorkingState,
            casetivityRemovedFields,
            dispatch,
            viewConfig,
            refreshProcessPage,
            setWorkingState,
            resolveBeforeSuccess,
            entityFormContextRef,
        ],
    );
    return [workingState, clearWorkingState, cascadingSave] as const;
};
export default useCascadingSave;
