import React from 'react';
import ViewConfig from 'reducers/ViewConfigType';
import { List, ListItem } from '@material-ui/core';
import { RootAction } from 'actions/rootAction';
import getHandleMenuClick from '../util/getHandleMenuClick';
import ExpandableListItem from './ExpandableItem';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';

const generateMobileMenu = ({
    viewConfig,
    dispatch,
}: {
    viewConfig: ViewConfig;
    dispatch: (action: RootAction) => void;
}) => {
    const generateMenuElements = (menus: ViewConfig['menus'], isRoot = true, callBeforeNav?: () => void) => {
        return menus?.map((m, i) => {
            const handleClick = getHandleMenuClick(viewConfig, dispatch)(m, callBeforeNav);
            if (!m.children) {
                const key = m.name + ':' + i;
                if (isRoot) {
                    return (
                        <ListItem button id={m.name} onClick={handleClick} key={key} color="inherit">
                            <EvaluatedFormattedMessage message={m.label} />
                        </ListItem>
                    );
                }
                return (
                    <ListItem button key={key} onClick={handleClick}>
                        {m.label}
                    </ListItem>
                );
            }
            const key = m.label + ':' + i;
            return (
                <ExpandableListItem key={key} onClick={handleClick} label={m.label} inset={!isRoot}>
                    {({ close }) => <List component="menu">{generateMenuElements(m.children, false, close)}</List>}
                </ExpandableListItem>
            );
        });
    };
    return generateMenuElements;
};
export default generateMobileMenu;
