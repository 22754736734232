import * as create from './actions';
import { isActionOf } from 'typesafe-actions';
import { Services } from 'sideEffect/services';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, withLatestFrom, map, flatMap } from 'rxjs/operators';
import crudCreateUpdateGetFlow from 'sideEffect/crud/util/epics/CoreCrud/createUpdate';
import recurseReplaceEmptyStringsWithNull from '../util/replaceEmptyStringsWithNull';
import { viewConfigSelector } from 'util/hooks/useViewConfig';

/**
 * the object is empty when JSON-stringified, (contains only undefined)
 */
const isEmptyishObject = (obj: Record<string, unknown>) => !Object.values(obj).find((v) => typeof v !== 'undefined');

const cleanData = (data: {}) => {
    let cleanedData = Object.assign({}, data); // set up new object to delete object references from safely
    Object.keys(cleanedData).forEach((key) => {
        const value = cleanedData[key];
        if (typeof value === 'object' && value) {
            if (isEmptyishObject(value)) {
                delete cleanedData[key];
                return;
            }
            if (!value['id']) {
                // there's no nested Id in the object
                const idFromReferenceField = cleanedData[`${key}Id`];
                if (idFromReferenceField) {
                    cleanedData[key] = {
                        ...cleanedData,
                        id: idFromReferenceField,
                    };
                }
                // otherwise, it's probably a create. Leave it be
                return;
            }

            const { id, ...rest } = value;

            if (id && isEmptyishObject(rest)) {
                // it's an object like { id: x }

                // delete the object and set the {objectName}Id field with the id from above
                Object.assign(cleanedData, { [`${key}Id`]: id });
                delete cleanedData[key];
            }
        }
    });
    cleanedData = recurseReplaceEmptyStringsWithNull(cleanedData);
    return cleanedData;
};

const crudCreateFlow: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(create.crudCreate)),
        withLatestFrom(state$.pipe(map((state) => viewConfigSelector(state)))),
        flatMap(([action, viewConfig]) => {
            const { resource, data, cb, restUrl: _restUrl, errorsCbs } = action.payload;
            const restUrl = _restUrl || viewConfig.entities[resource].restUrl;
            return crudCreateUpdateGetFlow(
                {
                    data: cleanData(data),
                    restUrl,
                },
                {
                    service: services.crudCreate,
                    failureAction: create.crudCreateFailure,
                    successAction: create.crudCreateSuccess,
                    successCb: cb,
                    errorsCbs,
                },
                {
                    resource,
                    viewConfig,
                    initialRequestPayload: action.payload,
                    restUrl,
                },
            );
        }),
    );
export default crudCreateFlow;
