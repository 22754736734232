import { EncryptedData } from './domain';

/* eslint-disable no-restricted-globals */
const getKeyMaterialFromPIN = async (pin: string): Promise<CryptoKey> => {
    let enc = new TextEncoder();
    return self.crypto.subtle.importKey('raw', enc.encode(pin), { name: 'PBKDF2' }, false, ['deriveBits', 'deriveKey']);
};
// for encrypting user's private key
const getKey = async (keyMaterial: CryptoKey, salt: Uint8Array): Promise<CryptoKey> => {
    return self.crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: salt,
            iterations: 500000,
            hash: 'SHA-256',
        },
        keyMaterial,
        { name: 'AES-GCM', length: 256 },
        true,
        ['wrapKey', 'unwrapKey'],
    );
};
const wrapCryptoKey = async (
    keyToWrap: CryptoKey,
    wrappingKey: CryptoKey,
    ivForWrapping: any,
): Promise<ArrayBuffer> => {
    return self.crypto.subtle.wrapKey('pkcs8', keyToWrap, wrappingKey, {
        name: 'AES-GCM',
        iv: ivForWrapping,
    });
};

const unwrapCryptoKey = async (wrappedKey: any, unwrappingKey: CryptoKey, ivForWrapping: any): Promise<CryptoKey> => {
    return crypto.subtle.unwrapKey(
        'pkcs8',
        wrappedKey,
        unwrappingKey,
        {
            name: 'AES-GCM',
            iv: ivForWrapping,
        },
        {
            name: 'ECDH',
            namedCurve: 'P-384',
        },
        false,
        ['deriveKey'],
    );
};

const deriveSecretKey = (privateKey: CryptoKey, publicKey: CryptoKey): PromiseLike<CryptoKey> => {
    return self.crypto.subtle.deriveKey(
        {
            name: 'ECDH',
            public: publicKey,
        },
        privateKey,
        {
            name: 'AES-GCM',
            length: 256,
        },
        false,
        ['encrypt', 'decrypt'],
    );
};

export type EncryptionInput =
    | {
          type: 'pin';
          pin: string;
      }
    | {
          type: 'secrets';
          secrets: SessionLocalSecrets;
      };

export type SessionLocalSecrets = {
    saltString: string; // decode this to Uint8Array
    userPrivateJwk: JsonWebKey;
    userPublicJwk: JsonWebKey;
    ivForUserPrivateKeyAESString: string; // decode this to Uint8Array;
    encryptedUserPrivateKeyString: string; // decode this to ArrayBuffer;
};

const decode = (arrayBuff: ArrayBuffer) => String.fromCharCode(...new Uint8Array(arrayBuff));

const encode = (decoded: string) => Uint8Array.from([...decoded].map((ch) => ch.charCodeAt(0)));

const restoreSessionLocalSecrets = async (secrets: SessionLocalSecrets) => {
    const { saltString, userPrivateJwk, userPublicJwk, ivForUserPrivateKeyAESString, encryptedUserPrivateKeyString } =
        secrets;
    const salt = encode(saltString);

    const userPrivateKey = await self.crypto.subtle.importKey(
        'jwk',
        userPrivateJwk,
        {
            name: 'ECDH',
            namedCurve: 'P-384',
        },
        true,
        ['deriveKey'],
    );

    const userPublicKey = await self.crypto.subtle.importKey(
        'jwk',
        userPublicJwk,
        {
            name: 'ECDH',
            namedCurve: 'P-384',
        },
        true,
        [],
    );
    const ivForUserPrivateKeyAES = encode(ivForUserPrivateKeyAESString);
    const encryptedUserPrivateKey = encode(encryptedUserPrivateKeyString);
    return {
        salt,
        userPrivateKey,
        userPublicKey,
        ivForUserPrivateKeyAES,
        encryptedUserPrivateKey,
    };
};
export const _encrypt = async (
    data: string,
    encryptionInput: EncryptionInput,
): Promise<[EncryptedData, SessionLocalSecrets]> => {
    let [userPrivateKey, userPublicKey, salt, encryptedUserPrivateKey, ivForUserPrivateKeyAES] =
        await (async (): Promise<[CryptoKey, CryptoKey, Uint8Array, ArrayBuffer, Uint8Array]> => {
            if (encryptionInput.type === 'pin') {
                const keyMaterialFromUser = await getKeyMaterialFromPIN(encryptionInput.pin);
                const salt: Uint8Array = self.crypto.getRandomValues(new Uint8Array(16));
                const aesKeyForEncryptingUserPrivateKey: CryptoKey = await getKey(keyMaterialFromUser, salt);
                const ivForUserPrivateKeyAES: Uint8Array = self.crypto.getRandomValues(new Uint8Array(12));

                let userKeyPair: CryptoKeyPair = await self.crypto.subtle.generateKey(
                    {
                        name: 'ECDH',
                        namedCurve: 'P-384',
                    },
                    true,
                    ['deriveKey'],
                );
                const encryptedUserPrivateKey: ArrayBuffer = await wrapCryptoKey(
                    userKeyPair.privateKey,
                    aesKeyForEncryptingUserPrivateKey,
                    ivForUserPrivateKeyAES,
                );
                return [
                    userKeyPair.privateKey,
                    userKeyPair.publicKey,
                    salt,
                    encryptedUserPrivateKey,
                    ivForUserPrivateKeyAES,
                ];
            }
            const { userPrivateKey, userPublicKey, salt, encryptedUserPrivateKey, ivForUserPrivateKeyAES } =
                await restoreSessionLocalSecrets(encryptionInput.secrets);
            return [userPrivateKey, userPublicKey, salt, encryptedUserPrivateKey, ivForUserPrivateKeyAES];
        })();

    //Create key pair that will be used to perform diffiehelman with users key to derive a AES key to encrypt the data
    let dataKeyPair: CryptoKeyPair = await self.crypto.subtle.generateKey(
        {
            name: 'ECDH',
            namedCurve: 'P-384',
        },
        false,
        ['deriveKey'],
    );
    const sharedSecretKeyWhenEncrypting: CryptoKey = await deriveSecretKey(dataKeyPair.privateKey, userPublicKey);

    const initializationVector: BufferSource = self.crypto.getRandomValues(new Uint8Array(12));

    // encrypt data
    let encodedData: Uint8Array = new TextEncoder().encode(data);
    const encryptedData: ArrayBuffer = await self.crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: initializationVector as any,
        },
        sharedSecretKeyWhenEncrypting,
        encodedData,
    );

    const userPrivateJwk: JsonWebKey = await self.crypto.subtle.exportKey('jwk', userPrivateKey);
    const userPublicJwk: JsonWebKey = await self.crypto.subtle.exportKey('jwk', userPublicKey);

    const dataPublicKey = dataKeyPair.publicKey;

    const sessionLocalSecrets: SessionLocalSecrets = {
        saltString: decode(salt),
        userPrivateJwk,
        userPublicJwk,
        ivForUserPrivateKeyAESString: decode(ivForUserPrivateKeyAES),
        encryptedUserPrivateKeyString: decode(encryptedUserPrivateKey),
    };

    return [
        {
            encryptedData,
            initializationVector,
            salt,
            encryptedUserPrivateKey,
            ivForUserPrivateKeyAES,
            dataPublicKey,
        },
        sessionLocalSecrets,
    ];
};

export const _decrypt = async (storedData: EncryptedData, encryptionInput: EncryptionInput): Promise<string> => {
    const {
        encryptedData,
        initializationVector,
        salt,
        encryptedUserPrivateKey,
        ivForUserPrivateKeyAES,
        dataPublicKey,
    } = storedData;
    let restoredUserPrivateKey = await (async () => {
        if (encryptionInput.type === 'pin') {
            const renewedKeyMaterialFromUser = await getKeyMaterialFromPIN(encryptionInput.pin);
            const renewedAesKeyForEncryptingUserPrivateKey: CryptoKey = await getKey(renewedKeyMaterialFromUser, salt);
            const restoredUserPrivateKey: CryptoKey = await unwrapCryptoKey(
                encryptedUserPrivateKey,
                renewedAesKeyForEncryptingUserPrivateKey,
                ivForUserPrivateKeyAES,
            );
            return restoredUserPrivateKey;
        }
        const restored = await restoreSessionLocalSecrets(encryptionInput.secrets);
        return restored.userPrivateKey;
    })();
    // Should be same result as when encrypting
    const sharedSecretKeyWhenDecrypting: CryptoKey = await deriveSecretKey(restoredUserPrivateKey, dataPublicKey);

    let decrypted = await self.crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: initializationVector as any,
        },
        sharedSecretKeyWhenDecrypting,
        encryptedData,
    );

    let dec = new TextDecoder();
    return dec.decode(decrypted);
};
