import React from 'react';
import DOMPurify from 'dompurify';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { ALLOW_DOWNLOADLINKS } from './purifyHtml';

// from https://github.com/cure53/DOMPurify/blob/master/demos/hooks-target-blank-demo.html
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        let href = node.getAttribute('href');
        if (href && href.startsWith('#')) {
            // link is on same page, don't change target.
            return;
        }

        if (node.getAttribute('target') !== '_self') {
            node.setAttribute('target', '_blank');
            // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
            node.setAttribute('rel', 'noopener noreferrer');
        }
    }
});

// we run the below (more strict) on 'templated segments' read in from unsafe sources.
// So things read-in from form values, for example.
export const purifyHtmlMoreStrict = (dangerousMarkup: string) =>
    DOMPurify.sanitize(dangerousMarkup, {
        ...ALLOW_DOWNLOADLINKS,
    });

export const HtmlDisplayComponentAsReact: React.FunctionComponent<{
    elStyle?: React.CSSProperties;
    noMargin?: boolean;
    id?: string;
    html: string;
}> = React.memo(({ elStyle, html, noMargin, id }) => {
    return (
        <div style={{ ...elStyle }} id={id}>
            <SafeHtmlAsReact html={html} />
        </div>
    );
});

export default HtmlDisplayComponentAsReact;
