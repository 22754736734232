import { decrypt, encrypt } from 'encryption/encryption';
import { SessionLocalSecrets } from 'encryption/encryptionUtils';
import { offlineEntitySubmitsIdbKeyVal } from 'IndexedDB/offlineEntitySubmits';
import { idbKeyval } from 'IndexedDB/offlineTasksDb';
import { deleteOfflineTaskData } from 'offline_app/expiration/deleteExpiredTasks';

/**
 *
 * If there are no existing entries, generate new SessionSecrets and return.
 *
 * If there are some existing entries, try to decrypt each of them with the given PIN:
 *   If any decryption succeeds
 *     delete any that failed from IDB
 *     generate new sessionsecrets
 *     encrypt all of the decrypted data using the new secret (so that they are all available in our session)
 *     and store in IDB
 *
 *   otherwise (if no decryption succeeds), treat it as failure, returning false;
 * @param pin user inputted PIN to test against saved data.
 * @returns SessionLocalSecrets if successful, null if not.
 */

const validatePinAndReencryptAllDataWithNewSecrets = async (pin: string): Promise<SessionLocalSecrets | false> => {
    const allTaskKeys = await idbKeyval.keys();

    // accumulate all encrypted data
    const accDecryptedData: {
        tasks: {
            [key: string]: any;
        };
        entitySubmits: {
            [key: string]: any;
        };
    } = {
        tasks: {},
        entitySubmits: {},
    };

    let someTaskDecryptSucceeded = false;
    for (let i = 0; i < allTaskKeys.length; i++) {
        const key = allTaskKeys[i];
        try {
            const data = await idbKeyval.get(key);
            const decryptedTaskData = await decrypt(data, { type: 'pin', pin });
            accDecryptedData.tasks[key] = decryptedTaskData;
            someTaskDecryptSucceeded = true;
        } catch (e) {
            // dropping anything that fails.
        }
    }

    if (allTaskKeys.length === 0) {
        // no data to decrypt - create a new sessionSecrets object for all further encryptions.

        // this shouldn't happen, but just in case:
        // lets also delete all entitysubmit data just in case there's some lingering - we're starting from scratch here.
        const allEntityDataKeys = await offlineEntitySubmitsIdbKeyVal.keys();
        for (let i = 0; i < allEntityDataKeys.length; i++) {
            const key = allEntityDataKeys[i];
            await offlineEntitySubmitsIdbKeyVal.delete(key);
        }

        const [, sessionSecrets] = await encrypt({}, { type: 'pin', pin });
        return sessionSecrets;
    }
    // at this point we know there is some encrypted data here.
    if (!someTaskDecryptSucceeded) {
        // some encrypted data, and none having succeeded means a wrong PIN was entered.
        return false;
    }

    // delete all that failed to decrypt using the successful PIN, including related data.
    for (let i = 0; i < allTaskKeys.length; i++) {
        const key = allTaskKeys[i];
        if (!accDecryptedData.tasks[key]) {
            await deleteOfflineTaskData(key);
        }
    }
    // now lets decrypt all entitySubmits, and add to accDecryptedData
    const allEntityDataKeys = await offlineEntitySubmitsIdbKeyVal.keys();
    for (let i = 0; i < allEntityDataKeys.length; i++) {
        const key = allEntityDataKeys[i];
        try {
            const data = await offlineEntitySubmitsIdbKeyVal.get(key);
            const decryptedEntityData = await decrypt(data, { type: 'pin', pin });
            accDecryptedData.entitySubmits[key] = decryptedEntityData;
        } catch (e) {
            // dropping anything that fails.
        }
    }
    // delete any entityDataKeys that failed to decrypt using the successful PIN.
    for (let i = 0; i < allEntityDataKeys.length; i++) {
        const key = allEntityDataKeys[i];
        if (!accDecryptedData.entitySubmits[key]) {
            await offlineEntitySubmitsIdbKeyVal.delete(key);
        }
    }

    // TODO: maybe delete any parentless entity submits, just in case.

    // generate new SessionSecrets: we will use this to re-encrypt everything.
    const [, sessionSecrets] = await encrypt({}, { type: 'pin', pin });
    // now re-encrypt and save everything.

    // re-encrypt and save tasks.
    let toWriteTaskEntries = Object.entries(accDecryptedData.tasks);
    for (let i = 0; i < toWriteTaskEntries.length; i++) {
        const [key, data] = toWriteTaskEntries[i];
        let [encryptedData] = await encrypt(data, { type: 'secrets', secrets: sessionSecrets });
        await idbKeyval.set(key, encryptedData);
    }

    // re-encrypt and save entity submits.
    let toWriteEntitySubmitEntries = Object.entries(accDecryptedData.entitySubmits);
    for (let i = 0; i < toWriteEntitySubmitEntries.length; i++) {
        const [key, data] = toWriteEntitySubmitEntries[i];
        let [encryptedData] = await encrypt(data, { type: 'secrets', secrets: sessionSecrets });
        await offlineEntitySubmitsIdbKeyVal.set(key, encryptedData);
    }

    // return new sessionSecrets which now can be used to decrypt all the offline data..
    return sessionSecrets;
};

export default validatePinAndReencryptAllDataWithNewSecrets;
