import { put } from 'redux-saga/effects';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { taskFormEventType as taskFormEvent, TaskFormSavePayload } from '../actions/taskFormEvent';
import sagaFactory, { configType, HttpVerb } from '../../sagas/util/sagaFactory';
import { getFailureHandler } from './submitTaskFormSaga';

const config: configType = {
    actionType: taskFormEvent.save,
    verb: HttpVerb.POST,
    url: ([taskId, _]: TaskFormSavePayload) => `api/bpm/task-forms/${taskId}/save-form`,
    body: ([_, payload]: TaskFormSavePayload) => payload,
    success: function* ([taskId, payload]: TaskFormSavePayload, responseBody: {}) {
        yield put({ type: taskFormEvent.saveSuccess, payload: [taskId, payload] });
        yield put(enqueueSnackbarAction({ message: 'Form saved successfully', options: { variant: 'success' } }));
    },
    failure: getFailureHandler((payload: TaskFormSavePayload, errorDetails: {}) => {
        return { type: taskFormEvent.saveFailure, payload: [...payload, errorDetails] };
    }),
    callCb: (payload, responseBody, cb) => {
        cb();
    },
    callErrorCb: (cb) => {
        cb();
    },
};

const saga = sagaFactory(config);
export default saga;
