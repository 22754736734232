import React from 'react';
import useLink from './hooks/linkHook';
interface LinkButtonProps {
    to: string;
    className?: string;
}
const LinkButton: React.SFC<LinkButtonProps> = ({ to, children, className }) => {
    const link = useLink(to);
    return (
        <button className={className || 'casetivity-linkbutton'} onClick={link}>
            {children}
        </button>
    );
};
export default LinkButton;
