import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { getGATrackingIdSelector } from 'util/applicationConfig';
import analytics from './analytics';

export default function useGoogleAnalytics() {
    const location = useSelector((state: RootState) => state.router.location);

    const gaTrackingId = useSelector(getGATrackingIdSelector);
    React.useEffect(() => {
        if (gaTrackingId) {
            analytics.init(gaTrackingId);
        }
    }, []); // eslint-disable-line

    React.useEffect(() => {
        const currentPath = location.pathname + location.search;
        analytics.sendPageview(currentPath);
    }, [location]);
}
