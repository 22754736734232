import React, { useState, useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';

const getRunDeletion = () => {
    let cancel = false;
    const startDeletionRun = (whenDone?: () => void) => {
        const buttons = document.getElementsByClassName('deleteViewDef');

        const forLoop = async () => {
            for (let index = 0; index < buttons.length; index++) {
                if (cancel) {
                    return;
                }
                const deleteButton = buttons[index] as HTMLButtonElement;
                deleteButton.click();
                if (index === buttons.length - 1) {
                    whenDone();
                }
            }
        };
        forLoop();
    };
    const cancelDeletionRun = () => {
        cancel = true;
    };
    return [startDeletionRun, cancelDeletionRun] as const;
};
const AutoDelete = (props) => {
    const [deleting, setDeleting] = useState(false);
    const setDeletionRun = useCallback(() => {
        setDeleting(true);
    }, [setDeleting]);
    const pauseDeletionRun = useCallback(() => {
        setDeleting(false);
    }, [setDeleting]);
    useEffect(() => {
        const [startDeletionRun, cancelDeletionRun] = getRunDeletion();
        if (deleting) {
            startDeletionRun(cancelDeletionRun);
        }
        return () => {
            cancelDeletionRun();
        };
    }, [deleting, pauseDeletionRun]);
    return (
        <div>
            {!deleting ? (
                <Button variant="contained" onClick={setDeletionRun}>
                    <Warning />
                    &nbsp; Delete all
                </Button>
            ) : (
                <Button variant="contained" onClick={pauseDeletionRun}>
                    Pause Deletion
                </Button>
            )}
        </div>
    );
};
export default AutoDelete;
