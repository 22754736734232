import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import DebouncedTextInput from './DebouncedTextInput';
import MaskedInput, { conformToMask } from 'react-text-mask';
import uniqueId from 'lodash/uniqueId';
import memoizeOne from 'memoize-one';
import useTextFieldUtils from '../hooks/useTextFieldUtils';
import renderTextFieldLabel from 'fieldFactory/util/renderTextFieldLabel';

type Input = any;
type Meta = any;

interface TextInputProps {
    randomizeNameAsBrowserAutocompleteHack?: boolean;
    noDebounce?: boolean;
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    input?: Input;
    meta: Meta;
    label?: string;
    addField?: boolean;
    elStyle?: {};
    validate: Function | Function[];
    disabled?: boolean;
    fullWidth?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    mask?: string;
    type?: string;
    normalizeState?: (value: any) => any;
    overrideAriaLabel?: string;
}

function TextMaskCustom(props) {
    const { inputRef, mask, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={'\u2000'}
            showMask={true}
        />
    );
}

interface MaskableTextInputProps {
    value: string;
    mask?: string;
    children: (
        args:
            | {
                  useMaskedInput: false;
                  textInputRef: React.RefObject<HTMLInputElement>;
              }
            | {
                  useMaskedInput: true;
                  isEmptyMaskedValue: (value: string) => boolean;
                  mask: (string | RegExp)[];
                  textInputRef: React.RefObject<HTMLInputElement>;
              },
    ) => JSX.Element | null;
}
export class MaskableTextInput extends React.Component<MaskableTextInputProps> {
    textInput = React.createRef<HTMLInputElement>();
    _getMaskArray = memoizeOne((mask) => {
        if (mask) {
            return [
                ...mask.split('').map((c) => {
                    switch (c.toLowerCase()) {
                        case 'a':
                            return /[a-zA-Z]/;
                        case '9':
                            return /\d/;
                        case '*':
                            return /./;
                        default:
                            return c;
                    }
                }),
            ];
        }
        return undefined;
    });
    _getEmptyMaskedValue = memoizeOne((mask) =>
        conformToMask('', this._getMaskArray(mask), {
            placeholderChar: '\u2000',
            guide: true,
        }),
    );
    isEmptyMaskedValue = (value: string) => {
        return value === this._getEmptyMaskedValue(this.props.mask).conformedValue;
    };
    useMask = (value = this.props.value) => {
        const { mask } = this.props;
        if (!mask) {
            return false;
        }
        const {
            // conformedValue,
            meta: { someCharsRejected },
        } = conformToMask(value || '', this._getMaskArray(mask), {
            placeholderChar: '\u2000',
            guide: true,
        });
        const res = mask && !someCharsRejected;
        return res;
    };
    getSnapshotBeforeUpdate(prevProps: MaskableTextInputProps) {
        const inputIsFocused = this.textInput.current === document.activeElement;
        return inputIsFocused;
    }
    componentDidUpdate(prevProps: MaskableTextInputProps, prevState, inputWasFocused) {
        if (inputWasFocused && this.useMask(prevProps.value) !== this.useMask()) {
            // because we had to swap out inner components, we need to return focus.
            // we have to swap inputComponents to make masks change because react-text-mask is buggy and unmaintained.
            // :(
            this.textInput.current.focus();
        }
    }
    render() {
        const useMaskedInput = this.useMask();
        if (useMaskedInput) {
            return this.props.children({
                mask: this._getMaskArray(this.props.mask),
                useMaskedInput,
                textInputRef: this.textInput,
                isEmptyMaskedValue: this.isEmptyMaskedValue,
            });
        }
        return this.props.children({
            useMaskedInput: false,
            textInputRef: this.textInput,
        });
    }
}

const TextInput: React.FC<TextInputProps> = ({
    className,
    input,
    meta,
    isRequired,
    label,
    source,
    resource,
    type,
    noDebounce,
    mask,
    normalizeState,
    randomizeNameAsBrowserAutocompleteHack = true,
    options = {},
    fullWidth = true,
    addField = true,
    disabled = false,
    ariaInputProps = {},
    renderLabel = true,
    overrideAriaLabel,
}) => {
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
    } = useTextFieldUtils(meta);
    const errorMessageId: string = useMemo(() => uniqueId('textinput'), []);
    const uniqueNameToThrowOffChromeAutoFill = useMemo(() => new Date().toISOString(), []);

    if (typeof meta === 'undefined') {
        throw new Error(
            "The LongTextInput component wasn't called within a redux-form <Field>. " +
                'Did you decorate it and forget to add the addField prop to your component? ' +
                'See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component' +
                ' for details.',
        );
    }
    const { touched, error } = meta;
    const _inputProps: {} = {
        'aria-label': label,
        autoComplete: 'never',
        ...ariaInputProps,
    };
    if (overrideAriaLabel) {
        _inputProps['aria-label'] = overrideAriaLabel;
    }
    if (randomizeNameAsBrowserAutocompleteHack) {
        _inputProps['name'] = uniqueNameToThrowOffChromeAutoFill;
    }
    if (touched && error) {
        _inputProps['aria-errormessage'] = errorMessageId;
    }
    const renderInput = ({ value, onChange, onBlur }) => (
        <MaskableTextInput value={value} mask={mask}>
            {(maskable) => {
                const valueIsEmptyMask = (value) => maskable.useMaskedInput && maskable.isEmptyMaskedValue(value);
                const inputProps = maskable.useMaskedInput ? { ..._inputProps, mask: maskable.mask } : _inputProps;
                const callChangeHandler = (handleEvent: (e) => void) => (e) => {
                    const valueWasDisplayedWithoutFittingMask = !maskable.useMaskedInput && e.target.value !== value;
                    if (valueWasDisplayedWithoutFittingMask || valueIsEmptyMask(e.target.value)) {
                        handleEvent('');
                    } else {
                        handleEvent(e);
                    }
                };
                const selectStart = (e) => {
                    if (e.which === 13) {
                        // enter key
                        !mask ? onBlur(e) : callChangeHandler(onBlur)(e);
                    }
                    if (valueIsEmptyMask(e.target.value)) {
                        e.target.setSelectionRange(0, 0);
                    }
                };
                return (
                    <TextField
                        inputRef={maskable.textInputRef}
                        type={type}
                        InputProps={{
                            inputComponent: maskable.useMaskedInput ? TextMaskCustom : undefined,
                            inputProps,
                            classes: InputPropsClasses,
                        }}
                        {...input}
                        onFocus={selectStart}
                        onKeyUp={selectStart}
                        onMouseUp={selectStart}
                        onKeyDown={selectStart}
                        onBlur={!mask ? onBlur : callChangeHandler(onBlur)}
                        onChange={!mask ? onChange : callChangeHandler(onChange)}
                        fullWidth={fullWidth}
                        value={value || ''}
                        className={className}
                        margin="none"
                        label={renderTextFieldLabel(fieldVariant, renderLabel, isRequired, label)}
                        keepCharPositions={true}
                        error={muiErrorProp}
                        helperText={helperText}
                        disabled={disabled}
                        FormHelperTextProps={createFormHelperTextProps(inputProps)}
                        {...options}
                        InputLabelProps={createInputLabelProps()}
                        variant={fieldVariant}
                    />
                );
            }}
        </MaskableTextInput>
    );
    if (noDebounce) {
        return renderInput(input);
    }
    return (
        <DebouncedTextInput
            emptyInitialValue=""
            input={input}
            renderInput={renderInput}
            normalizeState={normalizeState}
        />
    );
};

export default TextInput;
