import * as t from 'io-ts';
import * as io from './runtime';
import { Subtract } from 'utility-types';
export * from './runtime';

export type IFieldMapping1 = t.TypeOf<typeof io.FieldMapping1>;
export type IFieldMapping2 = t.TypeOf<typeof io.FieldMapping2>;
export type IFieldMapping = t.TypeOf<typeof io.FieldMapping>;

export interface IAddressWidgetConfig extends t.TypeOf<typeof io._AddressWidgetConfig> {}
export const AddressWidgetConfig = t.clean<IAddressWidgetConfig, IAddressWidgetConfig>(io._AddressWidgetConfig);

/*
 */

interface BaseSuggestion {
    casetivityNeighborhoodCode: string;
    casetivityCityCode: string;
    casetivityStateCode: string;
    casetivityZip?: string;
    confidence: number;
    externalGISId?: string;
}
interface SuggestionL1L2 extends BaseSuggestion {
    casetivityLine1: string;
    casetivityLine2: string;

    casetivityHouse: undefined;
    casetivityStreet: undefined;
    casetivityUnit: undefined;
}
interface SuggestionStrN extends BaseSuggestion {
    casetivityHouse: string;
    casetivityStreet: string;
    casetivityUnit: string;

    casetivityLine1: undefined;
    casetivityLine2: undefined;
}
export type Suggestion = SuggestionL1L2 | SuggestionStrN;
export interface VerifyResponse {
    // suggestions: Suggestion[];
    recommendations: Suggestion[];
    current: Suggestion;
    errMessage?: string;
    exact: boolean;
}
export type InputSuggestion = Partial<
    Subtract<BaseSuggestion, Pick<BaseSuggestion, 'casetivityCityCode'>> & {
        casetivityLine1?: string;
        casetivityLine2?: string;
        casetivityHouse?: string;
        casetivityStreet?: string;
        casetivityUnit?: string;
    }
>;

export type mappedActions<T> = {
    [P in keyof T]: (value: any) => void;
};
type mappedValues<T> = {
    [P in keyof T]: string;
};
export type Values = mappedValues<IFieldMapping>;
export type ValuesL1L2 = mappedValues<t.TypeOf<typeof io.FieldMapping1>>;
export type ValuesIndivL1L2Fields = mappedValues<t.TypeOf<typeof io.FieldMapping2>>;

// value type of the component
// represent the 'verification state'.
export type AddressPopoverValue =
    | 'MANUAL_OVERRIDE' // manual entry
    | 'FOUND_ADDRESS'
    | 'NO_MATCH'
    | 'ERROR';
