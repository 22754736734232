import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import Labeled from './Labeled';

export const ShowField = ({
    field,
    record,
    resource,
    basePath,
    row,
    span,
    column,
    source,
    alignSelf,
    fieldInstanceIdentifier,
}: {
    field: ReactElement<{
        label?: string;
        source?: string;
        style?: React.CSSProperties;
        addLabel?: boolean;
        fieldInstanceIdentifier?: string;
    }>;
    record?: {};
    resource?: string;
    basePath?: string;
    row?: number;
    span?: number;
    column?: number;
    source?: string;
    alignSelf?: boolean;
    fieldInstanceIdentifier?: string;
    'data-originaldefinition'?: string;
}) => (
    <div
        key={`${field.props.label}-${field.props.source}`}
        style={
            field.props.addLabel
                ? {
                      ...field.props.style,
                      // border: '1px solid gray',
                      paddingBottom: 'calc(.5em)',
                      paddingLeft: 'calc(.5em + 4px)',
                      paddingRight: 'calc(.5em + 4px)',
                  }
                : {
                      ...field.props.style,
                      paddingLeft: 'calc(.5em + 4px)',
                      paddingRight: 'calc(.5em + 4px)',
                  }
        }
        className={'aor-field'}
    >
        {
            /* eslint-disable no-nested-ternary */
            field.props.addLabel ? (
                <Labeled
                    record={record}
                    resource={resource}
                    basePath={basePath}
                    label={field.props.label}
                    source={field.props.source}
                    fieldInstanceIdentifier={field.props.fieldInstanceIdentifier}
                    //                    disabled={false}
                    fullWidth={true}
                >
                    {field}
                </Labeled>
            ) : typeof field.type === 'string' ? (
                field
            ) : (
                React.cloneElement(
                    field as ReactElement<{
                        label: string;
                        source: string;
                        style?: {};
                        addLabel?: boolean;
                        type: string;
                        record: {};
                        resource: string;
                        basePath: string;
                    }>,
                    {
                        record,
                        resource,
                        basePath,
                    },
                )
            )
            /* eslint-enable no-nested-ternary */
        }
    </div>
);
export interface FieldDefinition {
    label: string;
    source: string;
    style?: {};
    addLabel?: boolean;
    type: string;
    row: number;
    column: number;
    span: number;
    alignSelf?: boolean;
    fieldInstanceIdentifier?: string;
    'data-originaldefinition'?: string;
}
const mapField = (
    field: ReactElement<FieldDefinition> | ReactElement<FieldDefinition>[],
    record: {},
    resource: string,
    basePath: string,
    source = null,
    fieldInstanceIdentifier = source,
): ReactNode => {
    // const { record, resource, basePath } = this.props;
    if (Array.isArray(field)) {
        return field.map((f) => mapField(f, record, resource, basePath, source, fieldInstanceIdentifier)); // used to ignore last 3 args,
        // lets see if anything changes
    }
    return (
        <ShowField
            data-originaldefinition={field.props['data-originaldefinition']}
            field={field}
            record={record}
            resource={resource}
            basePath={basePath}
            row={field.props.row}
            column={field.props.column}
            span={field.props.span}
            source={source}
            alignSelf={field.props.alignSelf}
            fieldInstanceIdentifier={field.props.fieldInstanceIdentifier}
        />
    );
};

export default mapField;
