import lastAjaxSuccessReducer from './lastAjaxSuccessReducer';
import { combineReducers } from 'redux';
import successiveFailureCountReducer from './successiveFailureCount';
import a401OccurredOnReauthReducer from './401Occurred';

const reauthReducer = combineReducers({
    lastAjaxSuccess: lastAjaxSuccessReducer,
    successiveFailureCount: successiveFailureCountReducer,
    a401Occurred: a401OccurredOnReauthReducer,
});
export default reauthReducer;
