import React from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'redux-form';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import LongTextInput from 'fieldFactory/input/components/LongTextInput';
import { TaskState } from '../../../../reducer/tasks/task';
import TaskAssignment from './../TaskAssignment';
// import Event from 'fieldFactory/input/components/Event/Event';
import deepEql from 'deep-eql';
import { WithStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import moment from 'moment';
import { getCanClaimTask } from 'bpm/taskPotentialUsers/selectors';
import { RootState } from 'reducers/rootReducer';
import { connect, useSelector } from 'react-redux';
import { getDueDateEditableFromProcess } from '../../selectors';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import DateTimeInput from 'fieldFactory/input/components/DateTimePicker';
import { getHideTaskScheduledDateSelector } from 'util/applicationConfig';
import isOffline from 'util/isOffline';
import DayUSTInput from 'fieldFactory/input/components/DayUSTInput/DayUSTInput';

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        mimicFieldSpacing: {
            marginLeft: spacing(2),
        },
        flexStart: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            width: '100%',
            '& > div': {
                marginTop: 16,
            },
        },
        flexEnd: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            width: '100%',
        },
    });

const OpenTaskPropertiesComponent: FunctionComponent<
    {
        onSubmit: Function;
        dueDateSelected: Date | string;
        handleSubmit: Function;
        task: TaskState;
        name: string;
        isAdmin: boolean;
        schedDuration?: number;
        schedDate?: Date;
        attendeesIds?: string[];
        readOnly: boolean;
        reset: () => void;
        dueDateEditable?: boolean;
        dirty: boolean;
    } & WithStyles<typeof styles>
> = ({
    onSubmit,
    reset,
    dueDateSelected,
    handleSubmit,
    task,
    name,
    isAdmin,
    schedDuration,
    schedDate,
    attendeesIds = [],
    classes,
    readOnly,
    dueDateEditable,
    dirty,
}) => {
    const printMode = useSelector((state: RootState) => state.printMode);
    const hideTaskScheduledDate = useSelector(getHideTaskScheduledDateSelector);
    const dataEqual = () => {
        return (
            task &&
            (dueDateSelected && task.dueDate
                ? moment(dueDateSelected).isSame(task.dueDate, 'day')
                : !!dueDateSelected === !!task.dueDate) &&
            name === task.name &&
            moment(schedDate).isSame(task.schedDate) &&
            schedDuration === task.schedDuration &&
            deepEql(attendeesIds, task.attendeesIds || [])
        );
    };

    const SomeValueChangedButtonDisplayCss = !task || task.readOnly || dataEqual() ? 'none' : undefined;
    return (
        <div style={{ width: '100%' }}>
            <form autoComplete="off" style={{ width: '100%' }}>
                <div className={classes.flexEnd}>
                    {/*
                    <div className={classes.mimicFieldSpacing}>
                        {task.schedDate && task.schedDuration && <Field
                            component={Event}
                            disabled={task.readOnly}
                            config={{
                                fieldMapping: {
                                    duration: 'schedDuration',
                                    date: 'schedDate',
                                    attendeesIds: 'attendeesIds',
                                },
                                requiredFields: [],
                                displayInputsOnBase: {
                                    attendees: true
                                },
                            }}
                        />}
                    </div>
                        */}
                    <div className={classnames(classes.flexEnd)}>
                        {isAdmin && (
                            <div style={{ minWidth: '45%', marginRight: '.5em' }}>
                                <Field
                                    name="name"
                                    component={LongTextInput}
                                    customStyles={{ width: '100%' }}
                                    label="Task Name"
                                    aria-label="Task Name"
                                    disabled={task.readOnly || readOnly || isOffline()}
                                />
                            </div>
                        )}
                        <div style={{ marginRight: '.5em', verticalAlign: 'top' }}>
                            <Field
                                name="dueDate"
                                component={DayUSTInput}
                                mode="ISO"
                                label="Due Date"
                                aria-label="Due Date"
                                disabled={task.readOnly || readOnly || !dueDateEditable || isOffline()}
                            />
                        </div>
                        {isAdmin && !hideTaskScheduledDate && (
                            <div style={{ marginRight: '.5em', verticalAlign: 'top', marginTop: 16 }}>
                                <Field
                                    name="schedDate"
                                    component={DateTimeInput}
                                    label="Scheduled Date"
                                    aria-label="Scheduled Date"
                                    disabled={!task || isOffline() || printMode}
                                />
                            </div>
                        )}
                        <div>
                            <Button
                                onClick={handleSubmit((values) => onSubmit(values))}
                                disabled={task && dataEqual()}
                                variant="outlined"
                                style={{
                                    display: SomeValueChangedButtonDisplayCss,
                                    marginTop: 10,
                                    marginRight: '0.5em',
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={reset}
                                disabled={task && dataEqual()}
                                variant="outlined"
                                style={{
                                    display: SomeValueChangedButtonDisplayCss,
                                    marginTop: 10,
                                    marginRight: '0.5em',
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                        {isOffline() ? null : <TaskAssignment taskId={task.id} />}
                    </div>
                </div>
            </form>
            <FormSaveNotifierTrigger when={dirty} />
        </div>
    );
};

const makeMapStateToProps = () => {
    const canClaim = getCanClaimTask();
    return (state: RootState, props) => {
        return {
            readOnly: !props.task || !canClaim(state, { taskId: props.task.id }) || state.printMode,
            dueDateEditable: getDueDateEditableFromProcess(state, props.processId),
        };
    };
};
const TaskAttributesWhenOpen = compose(connect(makeMapStateToProps), withStyles(styles))(OpenTaskPropertiesComponent);
export default TaskAttributesWhenOpen;
