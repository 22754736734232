import * as React from 'react';
import RenameableTaskName from './RenameableTaskName';
import EditTaskEndDate from './EditableTaskEndDate';
import EditTaskSchedDate from './EditableTaskSchedDate';
import TaskAssignment from './../TaskAssignment';
import TaskOutcome from './TaskOutcome';
import { useSelector } from 'react-redux';
import { getHideTaskScheduledDateSelector } from 'util/applicationConfig';

export const AlignItems = ({ children }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                alignItems: 'flex-end',
            }}
        >
            {React.Children.map(children, (child, i) => {
                if (i === 0) {
                    return <div style={{ minWidth: '40%', paddingRight: '1em' }}>{child}</div>;
                }
                return (
                    <React.Fragment>
                        {child}
                        <div style={{ paddingRight: '1em' }} />
                    </React.Fragment>
                );
            })}
        </div>
    );
};
const ClosedTaskPropertiesComponent = (props) => {
    const hideTaskScheduledDate = useSelector(getHideTaskScheduledDateSelector);
    return (
        <AlignItems>
            <RenameableTaskName {...props} />
            <EditTaskEndDate {...props} />
            {!hideTaskScheduledDate && <EditTaskSchedDate {...props} />}
            <TaskOutcome outcome={props.task && props.task.outcome} />
            <TaskAssignment taskId={props.taskId} />
        </AlignItems>
    );
};

export default ClosedTaskPropertiesComponent;
