import React, { useCallback, useMemo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import DebouncedTextInput from './DebouncedTextInput';
import FieldTitle from './aor/FieldTitle';
import { NumericFormat } from 'react-number-format';
import uniqueId from 'lodash/uniqueId';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import useTextFieldUtils from '../hooks/useTextFieldUtils';

export interface CurrencyProps {
    label: String;
    input?: WrappedFieldInputProps;
    disabled?: boolean;
    meta: WrappedFieldMetaProps;
    className?: string;
    isRequired?: boolean;
    options?: {};
    source: string;
    resource: string;
    ariaInputProps?: {};
    renderLabel?: boolean;
    id?: string;
    overrideAriaLabel?: string;
}
const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            thousandSeparator={true}
            onValueChange={(values) => null}
            fixedDecimalScale={true}
            getInputRef={inputRef}
            decimalScale={2}
        />
    );
};

const Currency: React.FC<CurrencyProps> = ({
    className,
    input,
    meta,
    isRequired,
    label,
    options,
    source,
    resource,
    disabled,
    id,
    renderLabel = true,
    ariaInputProps = {},
    input: { onBlur, onChange },
    overrideAriaLabel,
}) => {
    const {
        InputPropsClasses,
        createInputLabelProps,
        createFormHelperTextProps,
        muiErrorProp,
        helperText,
        fieldVariant,
    } = useTextFieldUtils(meta);
    const fallBackInputId = useMemo(() => uniqueId('currency-'), []);
    const errorMessageId = useMemo(() => uniqueId('currencyErrorMsg-'), []);
    const getValue = useCallback((e: React.ChangeEvent<HTMLInputElement>): number => {
        let targetValue = e.target.value;
        if (targetValue === '') {
            return null;
        }
        if (typeof targetValue === 'string' && targetValue.includes(',')) {
            targetValue = targetValue.split(',').join('');
        }
        const value = parseFloat(targetValue);
        return isNaN(value) ? null : value;
    }, []);
    const handleChange = useCallback(
        (e) => {
            onChange(getValue(e));
        },
        [getValue, onChange],
    );
    const handleBlur = useCallback(
        (e) => {
            onBlur(getValue(e));
        },
        [getValue, onBlur],
    );

    const touched = meta && meta.touched;
    const error = meta && meta.error;
    const currencyInputId = id || fallBackInputId;
    const inputProps = useMemo(() => {
        const _inputProps = {
            ...ariaInputProps,
        };
        if (touched && error) {
            _inputProps['aria-errormessage'] = errorMessageId;
        }
        if (overrideAriaLabel) {
            _inputProps['aria-label'] = overrideAriaLabel;
        }
        return _inputProps;
    }, [errorMessageId, error, touched, overrideAriaLabel, ariaInputProps]);
    return (
        <DebouncedTextInput
            input={{
                value: input.value,
                onBlur: handleBlur,
                onChange: handleChange,
            }}
            emptyInitialValue=""
            renderInput={({ value, onChange, onBlur }) => (
                <TextField
                    InputLabelProps={createInputLabelProps({ disabled: false })}
                    variant={fieldVariant as any}
                    {...input}
                    className={className}
                    value={value}
                    onChange={onChange}
                    margin="none"
                    onBlur={onBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <p style={{ color: 'rgba(0, 0, 0, 0.87)' }}>$</p>
                            </InputAdornment>
                        ),
                        inputComponent: NumberFormatCustom,
                        id: currencyInputId,
                        classes: InputPropsClasses,
                    }}
                    label={
                        renderLabel && (
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                                htmlFor={currencyInputId}
                            />
                        )
                    }
                    inputProps={inputProps}
                    error={muiErrorProp}
                    helperText={helperText}
                    FormHelperTextProps={createFormHelperTextProps(inputProps)}
                    disabled={disabled}
                    {...options}
                    style={{ minHeight: '15px' }}
                />
            )}
        />
    );
};

export default Currency;
