import ViewConfig, { ViewField, Entity, FieldViewField } from '../../../../reducers/ViewConfigType';
import { getView } from '../../utils/viewConfigUtils';

/*
    Returns an array of fields from the corresponding MrgList view of a resource
        that can be used to display the base resource by itself
    E.g.  if personMrgList had fields with names "person.firstName", "score", we would get a list
        [{ field: "firstName", ...}] since "score" is not based out of "person".
    Instead of throwing an error, returns an empty list and logs to console, if there is no related field.
*/
export default (viewConfig: ViewConfig, resource: string): ViewField[] => {
    const mrgView = getView(viewConfig, `${resource}MrgList`);
    const mrgEntity: Entity = viewConfig.entities[`${resource}Mrg`];

    /* entity field names that relate to the resource */
    const fieldsRelatingToResource: string[] = Object.values(mrgEntity.fields)
        .filter((f) => f.relatedEntity === resource)
        .map((f) => f.name);

    /* deal with unexpected numbers of results (expect length of 1) */
    if (fieldsRelatingToResource.length > 1) {
        console.warn(
            `Entity ${resource}Mrg has ${fieldsRelatingToResource.length} fields relating to entity ${resource}.
            By default, picking the one with the shortest length, however this does indicate a configuration error.`,
        );
    } else if (fieldsRelatingToResource.length === 0) {
        console.error(
            `Entity ${resource}Mrg has ${fieldsRelatingToResource.length} fields relating to entity ${resource}.`,
        );
        return [];
    }
    /* pick the name with the shortest length (usually this will be the only result) */
    const relatedEntityField: string = fieldsRelatingToResource.sort((a, b) => a.length - b.length)[0];

    /* filter fields to those that have our resource as the root */
    const relatedFields = (
        Object.values(mrgView.fields).filter((f) => f.widgetType !== 'EXPRESSION') as FieldViewField[]
    ).filter((f) => f.field.startsWith(`${relatedEntityField}.`));

    /* adjust the field name, so we are based out of the root entity */
    return relatedFields.map((f) => ({ ...f, field: f.field.slice(`${relatedEntityField}.`.length) }));
};
