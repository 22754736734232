import isPlainObject from 'lodash/isPlainObject';

const removeUndefineds = (obj: {}) => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([k, v]) => typeof v !== 'undefined')
            .map(([k, v]) => {
                if (isPlainObject(v)) {
                    return [k, removeUndefineds(v)];
                }
                if (Array.isArray(v)) {
                    return [
                        k,
                        v.map((e) => {
                            if (isPlainObject(e)) {
                                return removeUndefineds(e);
                            }
                            return e;
                        }),
                    ];
                }
                return [k, v];
            }),
    );
};
export default removeUndefineds;
