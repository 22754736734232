import React from 'react';
import { CompileExpression } from 'expressions/Provider/implementations/CompileExpression';
import { Either } from 'fp-ts/lib/Either';

/*
    lets define a standard interface so we can swap out different spel implementations
    this may include various 'helpers'
*/
export interface ExpressionEvaluatorContext {
    compileExpression: CompileExpression;
    // cacheEval: (expression: string) => (context: {}, functionsAndVariables: {}) => any;
    evaluateTemplate: (
        templateString: string,
        sanitizeResult?: (result: string) => string,
    ) => (context: {}, functionsAndVariables: {}) => Either<string[], string>;
}

const defaultContext = {
    compileExpression: () => {
        throw new Error('expression eval context not provided.');
    },
    evaluateTemplate: () => {
        throw new Error('expression eval context not provided.');
    },
} as ExpressionEvaluatorContext;

export interface ExpressionEvaluatorContexts {
    impl: ExpressionEvaluatorContext;
}
const expressionEvaluatorContext = React.createContext<ExpressionEvaluatorContexts>({
    impl: defaultContext,
});

export default expressionEvaluatorContext;
