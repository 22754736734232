import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import AttemptRequest from '../../../../../components/AttemptRequest';
import * as config from '../../../../../config';
import moment from 'moment';
import buildHeaders from 'sideEffect/buildHeaders';

const getLazyR = (taskId: string) => (newEndDate: string | Date) => () =>
    fetch(`${config.BACKEND_BASE_URL}api/bpm/tasks/${taskId}/change-enddate`, {
        method: 'PUT',
        body: JSON.stringify({
            newTaskEndDate: moment(newEndDate).toISOString(),
        }),
        credentials: 'same-origin',
        headers: buildHeaders({
            includeCredentials: true,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }),
    });
/*
    This is just a button that encapsulates the request + state of the fetch by changing its styles.
*/
export const getChangeTaskEndDateButton = (taskId: string, onSuccess: () => void) => (newEndDate) =>
    (
        <AttemptRequest
            type="external"
            renderer={({ attemptAction }) =>
                (state) =>
                    state._tag === 'failure' ? (
                        <div>
                            Action failed.
                            <a // eslint-disable-line
                                href="javascript:;" // eslint-disable-line
                                onClick={attemptAction}
                            >
                                try again?
                            </a>
                        </div>
                    ) : state._tag === 'pending' ? (
                        <Button style={{ marginLeft: 'auto' }} variant="contained" color="default" disabled={true}>
                            Save
                            <CircularProgress style={{ marginLeft: '1em', height: 20, width: 20 }} />
                        </Button>
                    ) : state._tag === 'unsubmitted' ? (
                        <Button
                            style={{ marginLeft: 'auto' }}
                            variant="contained"
                            color="primary"
                            onClick={attemptAction}
                        >
                            Save
                        </Button>
                    ) : (
                        <span style={{ marginLeft: 'auto' }}>Success!</span>
                    )}
            lazyRequest={getLazyR(taskId)(newEndDate)}
            onSuccess={onSuccess}
        />
    );
