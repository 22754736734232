import { RootState } from 'reducers/rootReducer';

const getEmptyRequiredOptionFormFields = (formDefinition: RootState['taskForms'][0]) => (values: {}) => {
    if (!formDefinition) {
        return [];
    }
    return (formDefinition.fields || []).flatMap((field) => {
        if (
            field.required &&
            field.hasEmptyValue &&
            field.type === 'dropdown' &&
            // when there's an empty value, 'Empty' is the first option.
            (!values[field.id] ||
                (field.options && values[field.id].name === field.options[0].name) ||
                (field.options && values[field.id] === field.options[0].name))
        ) {
            return [field.id];
        }
        if (
            field.required &&
            field.type === 'radio-buttons' &&
            // when there's an empty value, 'Empty' is the first option.
            (values[field.id] === undefined || values[field.id] === '')
        ) {
            return [field.id];
        }
        return [];
    });
};
export default getEmptyRequiredOptionFormFields;
