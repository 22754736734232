import React from 'react';
import keycode from 'keycode';
import Downshift from 'downshift';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles';
import { createSelector } from 'reselect';
import {
    MenuItem,
    Chip,
    Paper,
    FormControl,
    InputLabel,
    Input as StandardInput,
    FormHelperText,
    OutlinedInput,
    FilledInput,
    Tooltip,
} from '@material-ui/core';
import { lifecycle, compose } from 'recompose';
import { RootState } from '../../../../reducers/rootReducer';
import classnames from 'classnames';
import { getValueSetForFieldExpr } from '../../../../components/generics/utils/viewConfigUtils/index';
import { getConceptsByValueSetCode } from '../../../../components/generics/utils/valueSetsUtil';
import { loadValueSet as loadValueSetAction, loadValueSetGroup as loadValueSetGroupAction } from 'valueSets/actions';
import FieldTitle from '../aor/FieldTitle';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import uniqueId from 'lodash/uniqueId';
import { grey } from '@material-ui/core/colors';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useIntl } from 'react-intl';
import { evaluateFormattedText } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { TextFieldUtils } from '../../hooks/useTextFieldUtils';

type Input = WrappedFieldInputProps;
type Meta = WrappedFieldMetaProps;

const styles = (theme: Theme) =>
    createStyles({
        chipArea: {
            marginTop: 16,
        },
        chipAreaGrey: {
            marginTop: 16,
            backgroundColor: theme.palette.type === 'dark' ? undefined : grey[100],
        },
        root: {
            flexGrow: 1,
        },
        container: {
            flexGrow: 1,
            position: 'relative',
        },
        smallPaper: {
            maxHeight: 150,
        },
        inputUnderline: {
            // not sure why we hid underlines here?
            // turning them back on.
            // '&::after': {
            //     borderBottom: 0,
            // },
        },
        largePaper: {
            maxHeight: 300,
        },
        paper: {
            position: 'absolute',
            zIndex: 5000,
            marginTop: theme.spacing(-1),
            overflowY: 'scroll',
            left: 0,
            minWidth: '100%',
        },
        paperTop: {
            position: 'absolute',
            zIndex: 5000,
            overflowY: 'scroll',
            left: 0,
            minWidth: '100%',
            bottom: `calc(100% - ${theme.spacing(4)}px)`, // fixed disance from top
        },
        chip: {
            margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
        },
        inputRoot: {
            flexWrap: 'wrap',
        },
    });

interface Concept {
    code?: string;
    description?: string;
    display: string;
    id: string;
    sortOrder?: number;
    subtitle?: string;
    title?: string;
    valueSetId?: number;
    active?: boolean;
}

function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
}: {
    suggestion: Concept;
    index?: number;
    itemProps?: {};
    highlightedIndex: number | null;
    selectedItem?: string;
}) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.display) > -1;

    return (
        <MenuItem
            data-value={suggestion.code}
            {...itemProps}
            key={suggestion.id}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.display}
        </MenuItem>
    );
}

interface DownshiftMultipleProps extends WithStyles<typeof styles> {
    isPopover?: boolean;
    options?: {
        id: string;
    };
    disabled?: boolean;
    label?: string;
    input: Input;
    dataSource: Concept[];
    dataTableById: { [id: string]: Concept };
    dataTableByDisplay: { [display: string]: Concept };
    meta: Meta;
    tooltipText?: string;
    dropdownPosition?: 'above' | 'below';
    ariaInputProps: {};
    renderLabel: boolean;
    conceptIds?: string[]; // overriding what concepts are available due to live changes in the form.;
}
interface DownshiftMultipleState {
    inputValue: string;
}

class DownshiftMultiple extends React.Component<DownshiftMultipleProps, DownshiftMultipleState> {
    emptyArray = [];
    private initialValue: string[];
    private errorMessageId = uniqueId('valuesetmany-errormsg');
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    constructor(props: DownshiftMultipleProps) {
        super(props);
        this.initialValue = props.input?.value ?? this.emptyArray;
        this.state = {
            inputValue: '',
        };
    }
    conceptAllowedByExpression = (conceptId: string) => {
        const { conceptIds } = this.props;
        return conceptIds ? conceptIds.indexOf(conceptId) !== -1 : true;
    };
    getSelectedIds = (): string[] => {
        const { input, conceptIds, dataTableById } = this.props;
        const currentlySelectedInForm = (input && input.value) || this.emptyArray;
        // sort initial values, otherwise items are pushed onto the end (latest last)
        const sort = currentlySelectedInForm === this.initialValue;

        let ids = conceptIds
            ? currentlySelectedInForm.filter(this.conceptAllowedByExpression)
            : currentlySelectedInForm;

        if (sort) {
            return ids.slice().sort((id1, id2) => {
                const order1 = dataTableById[id1]?.sortOrder;
                const order2 = dataTableById[id2]?.sortOrder;
                return order1 < order2 ? -1 : order1 > order2 ? 1 : 0;
            });
        }
        return ids;
    };

    getSuggestions = (inputValue: null | string) => {
        let count = 0;
        return this.props.dataSource
            .filter((suggestion) => this.conceptAllowedByExpression(suggestion.id))
            .filter((suggestion) => {
                const matchesInputValue =
                    !inputValue || suggestion.display.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
                const notIncludedAlready = this.getSelectedIds().indexOf(suggestion.id) === -1;
                const keep = matchesInputValue && notIncludedAlready && suggestion.active && count < 1000;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    };

    handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { inputValue } = this.state;
        const selectedIds = this.getSelectedIds();
        if (selectedIds.length && !inputValue.length && keycode(event as any) === 'backspace') {
            const newSelectedIds = selectedIds.slice(0, selectedIds.length - 1);
            this.props.input.onChange(newSelectedIds);
            this.props.input.onBlur(newSelectedIds);
        }
        if (event.key === 'Tab' && !event.shiftKey) {
            this.setState({ inputValue: '' });
        }
    };

    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    };

    handleChange = (item: Concept) => {
        let selectedIds = this.getSelectedIds();
        if (selectedIds.indexOf(item.id) === -1) {
            selectedIds = [...selectedIds, item.id];
        }

        this.setState(
            {
                inputValue: '',
            },
            () => {
                this.props.input.onChange(selectedIds);
                this.props.input.onBlur(selectedIds);
            },
        );
    };

    handleDelete = (id: string) => () => {
        const selectedIds = [...this.getSelectedIds()];
        selectedIds.splice(selectedIds.indexOf(id), 1);
        this.props.input.onChange(selectedIds);
        this.props.input.onBlur(selectedIds);
    };
    render() {
        const {
            classes,
            disabled = false,
            options: { id: inputId } = { id: 'valueset-multiple' },
            dropdownPosition = 'below',
            renderLabel,
            isPopover = false,
            label,
            meta,
            tooltipText,
        } = this.props;
        const { inputValue } = this.state;

        const selectedIds = this.getSelectedIds();
        const paperClass = classnames(
            dropdownPosition === 'below' ? classes.paper : classes.paperTop,
            isPopover || dropdownPosition === 'above' ? classes.smallPaper : classes.largePaper,
        );

        const El = (
            <themeOverrideContext.Consumer>
                {({ fieldVariant, useGreyInputBackground }) => {
                    const Input =
                        fieldVariant === 'outlined'
                            ? OutlinedInput
                            : fieldVariant === 'filled'
                            ? FilledInput
                            : StandardInput;
                    return (
                        <TextFieldUtils meta={meta}>
                            {({
                                createInputLabelProps,
                                muiErrorProp,
                                InputPropsClasses,
                                helperText,
                                createFormHelperTextProps,
                            }) => (
                                <Downshift
                                    itemToString={(item) => (item ? item.display : '')}
                                    inputValue={inputValue}
                                    onChange={this.handleChange}
                                    selectedItem={selectedIds.map((id) => this.props.dataTableById[id]) as any}
                                    onOuterClick={() => {
                                        this.setState({ inputValue: '' });
                                    }}
                                >
                                    {({
                                        getInputProps,
                                        getItemProps,
                                        isOpen,
                                        inputValue: inputValue2,
                                        selectedItem: selectedItem2,
                                        highlightedIndex,
                                        getMenuProps,
                                        openMenu,
                                        getLabelProps,
                                        getRootProps,
                                    }) => {
                                        const InputProps = getInputProps({
                                            label: this.props.label,
                                            onClick: () => !disabled && openMenu(),
                                            onBlur: () => {
                                                this.props.input.onBlur(undefined);
                                            },
                                            onChange: this.handleInputChange,
                                            onKeyDown: this.handleKeyDown,
                                            placeholder: disabled ? '' : 'Select values',
                                            id: inputId,
                                            disabled,
                                            'aria-haspopup': true,
                                            'aria-roledescription': 'Type to filter results',
                                            'aria-errormessage':
                                                this.props.meta.touched && this.props.meta.error
                                                    ? this.errorMessageId
                                                    : undefined,
                                        });
                                        return (
                                            <FormControl
                                                variant={fieldVariant}
                                                fullWidth={true}
                                                margin="none"
                                                style={{ zIndex: 'unset' }}
                                                error={muiErrorProp}
                                                disabled={disabled}
                                            >
                                                {renderLabel && (
                                                    <InputLabel
                                                        {...createInputLabelProps()}
                                                        focused={false}
                                                        shrink={true}
                                                        {...getLabelProps()}
                                                    >
                                                        <FieldTitle label={label} isRequired={false} />
                                                    </InputLabel>
                                                )}
                                                {fieldVariant === 'filled' ? <div style={{ height: '6px' }} /> : null}
                                                <div
                                                    className={
                                                        useGreyInputBackground ? classes.chipAreaGrey : classes.chipArea
                                                    }
                                                >
                                                    {selectedIds.map((id) => (
                                                        <Chip
                                                            key={id}
                                                            tabIndex={0}
                                                            aria-roledescription="Button Press Delete key to delete"
                                                            label={
                                                                this.props.dataTableById[id] &&
                                                                this.props.dataTableById[id].display
                                                            }
                                                            className={classes.chip}
                                                            onDelete={() => !disabled && this.handleDelete(id)()}
                                                            deleteIcon={
                                                                disabled ? (
                                                                    <span style={{ width: '.5em' }} />
                                                                ) : undefined
                                                            }
                                                        />
                                                    ))}
                                                </div>
                                                <div {...getRootProps()} className={classes.container}>
                                                    <Input
                                                        margin={fieldVariant === 'filled' ? 'dense' : undefined}
                                                        fullWidth={true}
                                                        classes={{
                                                            root: classnames(InputPropsClasses.root, classes.inputRoot),
                                                            notchedOutline: InputPropsClasses.notchedOutline,
                                                            underline: classnames(
                                                                InputPropsClasses.underline,
                                                                classes.inputUnderline,
                                                            ),
                                                        }}
                                                        inputProps={{
                                                            ...InputProps,
                                                            style:
                                                                fieldVariant === 'filled'
                                                                    ? { paddingTop: '14px' }
                                                                    : undefined,
                                                            'aria-label': label,
                                                        }}
                                                        label={undefined}
                                                    />
                                                    {muiErrorProp && (
                                                        <FormHelperText
                                                            {...createFormHelperTextProps(InputProps as any)}
                                                        >
                                                            {helperText}
                                                        </FormHelperText>
                                                    )}
                                                    <div {...getMenuProps()}>
                                                        {isOpen ? (
                                                            <Paper className={paperClass} square={true}>
                                                                {this.getSuggestions(inputValue2).map(
                                                                    (suggestion, index) =>
                                                                        renderSuggestion({
                                                                            suggestion,
                                                                            index,
                                                                            itemProps: getItemProps({
                                                                                item: suggestion,
                                                                            }),
                                                                            highlightedIndex,
                                                                            selectedItem: selectedItem2 as any,
                                                                        }),
                                                                )}
                                                            </Paper>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </FormControl>
                                        );
                                    }}
                                </Downshift>
                            )}
                        </TextFieldUtils>
                    );
                }}
            </themeOverrideContext.Consumer>
        );
        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} placement={dropdownPosition === 'below' ? 'top' : 'bottom'}>
                    <div>{El}</div>
                </Tooltip>
            );
        }
        return El;
    }
}

const getValueSetGroup = (state, props) => props.group || null;

export const makeMapStateToProps = () => {
    const emptyObj = {};
    const getEntities = createGetEntities();
    const getConcepts = createSelector(
        getEntities,
        (entities) => ((entities as any).Concept || emptyObj) as { [id: string]: Concept },
    );
    return createSelector(
        (state: RootState) => state.valueSets,
        getValueSetGroup,
        (state: RootState, props) =>
            props.valueSet ||
            getValueSetForFieldExpr(
                state.viewConfig,
                props.resource,
                props.source.slice(0, -3), // pop off 'Ids'
                'TRAVERSE_PATH',
            ),
        getConcepts,
        (state: RootState, props) => props.intl,
        (valueSets, group, valueSetCode, concepts, intl) => {
            const applyIntlToConcept = (concept) => {
                if (concept?.display?.includes('%{')) {
                    return {
                        ...concept,
                        display: evaluateFormattedText(intl, concept.display),
                    };
                }
                return concept;
            };
            const conceptsList = getConceptsByValueSetCode(valueSets, valueSetCode, concepts, group).map((concept) =>
                applyIntlToConcept(concept),
            );
            const dataTableById: { [id: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.id] = o));
                return dt;
            })();
            const dataTableByDisplay: { [display: string]: Concept } = (() => {
                const dt = {};
                conceptsList.forEach((o) => (dt[o.display.toLowerCase()] = o));
                return dt;
            })();
            return {
                dataSource: conceptsList,
                dataTableById,
                dataTableByDisplay,
                valueSetCode,
            };
        },
    );
};
export const valueSetsManyHoc = compose(
    (BaseComponent) => (props) => {
        const intl = useIntl();
        return <BaseComponent {...props} intl={intl} />;
    },
    connect(makeMapStateToProps, {
        loadValueSet: loadValueSetAction,
        loadValueSetGroup: loadValueSetGroupAction,
    }),
    lifecycle({
        componentDidMount() {
            const {
                loadValueSet,
                loadValueSetGroup,
                valueSet,
                valueSetCode,
                group,
                shouldFetchValueset = true,
            } = this.props;
            if ((valueSet || valueSetCode) && shouldFetchValueset) {
                if (group) {
                    group.split(',').forEach((g) => {
                        loadValueSetGroup(valueSet || valueSetCode, g);
                    });
                } else {
                    loadValueSet(valueSet || valueSetCode);
                }
            }
        },
        componentWillReceiveProps(nextProps: { valueSet?: string; valueSetCode?: string; group?: string }) {
            if (
                (this.props.valueSet !== nextProps.valueSet && nextProps.valueSet) ||
                (this.props.group !== nextProps.group && nextProps.group) ||
                (this.props.valueSetCode !== nextProps.valueSetCode && nextProps.valueSetCode)
            ) {
                if (nextProps.group) {
                    nextProps.group.split(',').forEach((g) => {
                        this.props.loadValueSetGroup(nextProps.valueSet || nextProps.valueSetCode, g);
                    });
                } else {
                    this.props.loadValueSet(nextProps.valueSet || nextProps.valueSetCode);
                }
            }
        },
    }),
    (BaseComponent) => (props) => <BaseComponent {...props} />,
);
const ValuesetMany = compose(valueSetsManyHoc, withStyles(styles))(DownshiftMultiple);

export default ValuesetMany;
