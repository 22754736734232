import { RootAction } from 'actions/rootAction';
import { getType } from 'typesafe-actions';
import * as listActions from 'sideEffect/crud/getList/actions';
import * as deleteActions from 'sideEffect/crud/delete/actions';

const getResource = (action: RootAction) => {
    switch (action.type) {
        case getType(listActions.crudGetList):
            return action.payload.resource;
        case getType(listActions.crudGetListFailure):
        case getType(listActions.crudGetListSuccess):
        case getType(deleteActions.crudDeleteSuccess):
        case getType(listActions.crudGetListCancel):
            return action.requestPayload.resource;
        default:
            return null;
    }
};
export default getResource;
