import React, { ReactNode } from 'react';
import { CardHeader, CircularProgress } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { dropzoneStyle } from 'components/custom/BulkUpload';
import AttemptRequest from 'components/AttemptRequest';
import Alert from '@material-ui/lab/Alert';
import CenterIcon from 'components/CenterIcon';

interface DropzoneUploadProps {
    getLazyRequest: (files: File[]) => () => Promise<Response>;
    title: string;
    dropzoneText: string | ReactNode;
    ariaLabel?: string;
    onSuccess?: () => void;
    accept?: (files: File[]) => boolean;
}

const DropzoneUpload: React.FunctionComponent<DropzoneUploadProps> = ({
    getLazyRequest,
    title,
    dropzoneText,
    ariaLabel = 'Upload File',
    onSuccess,
    accept,
}) => {
    return (
        <AttemptRequest
            type="internal"
            renderer={({ attemptAction }) =>
                (state) => {
                    return (
                        <div>
                            <div style={{ display: 'flex' }}>
                                <CardHeader title={title} />
                                {state._tag === 'pending' ? (
                                    <CenterIcon>
                                        <CircularProgress />
                                    </CenterIcon>
                                ) : state._tag === 'failure' ? (
                                    (() => {
                                        if (state.status === 504) {
                                            return (
                                                <Alert style={{ flex: 1 }} severity="info">
                                                    The connection has timed out - check later to make sure your changes
                                                    were applied successfully.
                                                </Alert>
                                            );
                                        }
                                        return (
                                            <Alert style={{ flex: 1 }} severity="error">
                                                File upload failed{state.body?.['description'] ? ':' : ''}{' '}
                                                {state.body?.['description']}. See console for more details.
                                            </Alert>
                                        );
                                    })()
                                ) : state._tag === 'success' ? (
                                    <Alert style={{ flex: 1 }} severity="success">
                                        File upload successful.
                                    </Alert>
                                ) : null}
                            </div>
                            <div style={{ padding: '15px' }}>
                                <Dropzone
                                    multiple={false}
                                    onDrop={(files) => {
                                        if (accept?.(files) === false) {
                                            return;
                                        }
                                        attemptAction({
                                            lazyRequest: getLazyRequest(files),
                                            onSuccess,
                                        });
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <section>
                                            <div {...getRootProps()} style={dropzoneStyle as React.CSSProperties}>
                                                <input {...getInputProps({ 'aria-label': ariaLabel })} />
                                                <p>{dropzoneText}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    );
                }}
        />
    );
};

export default DropzoneUpload;
