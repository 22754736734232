/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */
import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Card, CardHeader, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Refresh from '@material-ui/icons/Refresh';
import Save from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import { initial, pending, success, failure } from '@devexperts/remote-data-ts';
import { services } from 'sideEffect/services';
import UploadFileWithSkipCalcsOption from '../components/custom/UploadWithSkipCalcsOption';
import ExportViewConfigButton from './exportViewConfig';
import { ExportViewConfigEventCreator } from '../actions/exportViewConfig';
import { Typography, CircularProgress } from '@material-ui/core';
import AttemptRequest from './AttemptRequest';
import { Helmet } from 'react-helmet';
import ViewDefinitionManagement from 'layout-editor/ViewDefinitionManagement';
import buildHeaders from 'sideEffect/buildHeaders';
import DownloadConfigButton from './ConfigPackDownload/DownloadConfigButton';
import { PushAllButton } from './PushAll/PushAllButton';

const config = require('../config.js');

const refreshCaches = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/refresh-caches`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({ includeCredentials: true }),
    });
    return fetch(request);
};

const saveViewConfig = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/config-pack/save`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({ includeCredentials: true }),
    });
    return fetch(request);
};

const refreshFrontendIndex = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/refresh-frontend-index`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({ includeCredentials: true }),
    });
    return fetch(request);
};

const overrideViewDefDefaultViews = () => {
    const request = new Request(`${config.BACKEND_BASE_URL}api/override-default-views`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: buildHeaders({ includeCredentials: true }),
    });
    return fetch(request);
};

const useCacheInfo = () => {
    const [refreshKey, setRefreshKey] = useState(1);
    const refresh = useCallback(() => {
        setRefreshKey(refreshKey + 1);
    }, [refreshKey, setRefreshKey]);
    const [state, setState] = useState(initial);
    const fetchCacheInfo = useCallback(() => {
        const $ajax = services.getCacheInfo();
        setState(pending);
        const subscription = $ajax.subscribe(
            (res) => {
                setState(success(res));
            },
            (error) => {
                console.error(error);
                setState(failure(error));
            },
        );
        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [setState]);
    useEffect(() => {
        const cleanup = fetchCacheInfo();
        return cleanup;
    }, [refreshKey, fetchCacheInfo]);
    return [state, refresh];
};

const ViewConfigManager = (props) => {
    const frontendBundleBaseUrl = useSelector((state) => state.basicInfo?.frontendBundleBaseUrl);
    const [feCommit, setFeCommit] = useState('');
    useEffect(() => {
        try {
            const _feCommit = document.querySelector("meta[name='casetivity:fe-commit']").getAttribute('content');
            setFeCommit(_feCommit);
        } catch (e) {
            setFeCommit('<not found in document meta tags>');
        }
    }, []); // eslint-disable-line
    const [cacheInfoState, cacheInfoSRefresh] = useCacheInfo();
    return (
        <div>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <Card style={{ marginBottom: '1em' }}>
                <CardHeader title={'App Info'} />
                <div style={{ padding: '1em 3em', maxHeight: '600px', overflowY: 'auto' }}>
                    <p>
                        Front-end commit:{' '}
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`https://src.casetivity.com/ssg/casetivity-front-end/-/commit/${feCommit}`}
                        >
                            {feCommit}
                        </a>
                    </p>
                    <p>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${frontendBundleBaseUrl ?? process.env.PUBLIC_URL ?? ''}/static/docs/index.html`}
                        >
                            SPEL function docs
                        </a>
                    </p>
                    <pre style={{ whiteSpace: 'pre-wrap' }}>
                        {props.application && JSON.stringify(props.application, null, 4)}
                    </pre>
                </div>
            </Card>
            <Card style={{ marginBottom: '1em', padding: '1em', textAlign: 'center' }}>
                <AttemptRequest
                    type="external"
                    lazyRequest={refreshFrontendIndex}
                    renderer={({ attemptAction }) =>
                        (state) => {
                            if (state._tag === 'failure') {
                                return (
                                    <span>
                                        <br />
                                        Save failed.<button onClick={attemptAction}>Retry?</button>
                                    </span>
                                );
                            }
                            if (state._tag === 'pending') {
                                return <CircularProgress style={{ height: 20, width: 20 }} />;
                            }
                            return (
                                <>
                                    {state._tag === 'success' ? ' Success!' : ''}
                                    <Button variant="contained" color="primary" onClick={attemptAction}>
                                        Refresh Front End Index
                                    </Button>
                                </>
                            );
                        }}
                />
            </Card>
            <ViewDefinitionManagement />
            <Card style={{ marginBottom: '1em', padding: '1em' }}>
                <CardHeader title={'Print Templates'} />
                <div style={{ margin: '1em' }}>
                    <Button variant="contained" color="primary" component={Link} to="/print-template-tester">
                        Test Print Templates
                    </Button>
                </div>
            </Card>
            <Card style={{ marginBottom: '1em' }}>
                <div style={{ margin: '1em', paddingBottom: '1em' }}>
                    <AttemptRequest
                        type="external"
                        lazyRequest={overrideViewDefDefaultViews}
                        renderer={({ attemptAction }) =>
                            (state) => {
                                if (state._tag === 'failure') {
                                    return (
                                        <span>
                                            <br />
                                            Request failed.<button onClick={attemptAction}>Retry?</button>
                                        </span>
                                    );
                                }
                                if (state._tag === 'pending') {
                                    return <CircularProgress style={{ height: 20, width: 20 }} />;
                                }
                                return (
                                    <>
                                        {state._tag === 'success' ? ' Success!' : ''}
                                        <Button variant="contained" color="primary" onClick={attemptAction}>
                                            Override Default Views using Entity Views
                                        </Button>
                                    </>
                                );
                            }}
                    />
                </div>
            </Card>
            <div>
                <UploadFileWithSkipCalcsOption
                    title="Import ConfigPack"
                    dropMessage="ConfigPack File Here"
                    resourceUrl="config-pack/import"
                />
            </div>
            <Card style={{ marginBottom: '1em' }}>
                <CardHeader title={'Caches'} />
                {cacheInfoState && cacheInfoState.value && (
                    <div style={{ padding: '1em 3em' }}>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(cacheInfoState.value, null, 4)}</pre>
                    </div>
                )}
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}
                >
                    <Typography> Refresh Caches: &nbsp;</Typography>
                    <AttemptRequest
                        type="external"
                        lazyRequest={refreshCaches}
                        onSuccess={cacheInfoSRefresh}
                        renderer={({ attemptAction }) =>
                            (state) => {
                                if (state._tag === 'failure') {
                                    return (
                                        <span>
                                            <br />
                                            Refresh Caches failed.
                                            <a href="javascript:;" onClick={attemptAction}>
                                                Retry?
                                            </a>
                                        </span>
                                    );
                                }
                                if (state._tag === 'pending') {
                                    return <CircularProgress style={{ height: 20, width: 20 }} />;
                                }
                                return (
                                    <React.Fragment>
                                        {state._tag === 'success' ? ' Success!' : ''}
                                        <IconButton color="inherit" onClick={attemptAction} aria-label="Refresh Caches">
                                            <Refresh />
                                        </IconButton>
                                    </React.Fragment>
                                );
                            }}
                    />
                </div>
            </Card>
            <Card style={{ marginBottom: '1em' }}>
                <CardHeader title={'Export ConfigPack'} />
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}
                >
                    <Typography> Export Full ConfigPack: </Typography>
                    <ExportViewConfigButton key="exportviewconfigbtn" mobile={false} onClick={props.exportConfigFull} />
                    <Typography> Export ConfigPack Without Users: </Typography>
                    <ExportViewConfigButton
                        key="exportviewconfiguserexcludedbtn"
                        mobile={false}
                        onClick={props.exportConfigUserExcluded}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                        }}
                    >
                        <Typography>Save ConfigPack: &nbsp;</Typography>
                        <AttemptRequest
                            type="external"
                            lazyRequest={saveViewConfig}
                            renderer={({ attemptAction }) =>
                                (state) => {
                                    if (state._tag === 'failure') {
                                        return (
                                            <span>
                                                <br />
                                                Save failed.
                                                <a href="javascript:;" onClick={attemptAction}>
                                                    Retry?
                                                </a>
                                            </span>
                                        );
                                    }
                                    if (state._tag === 'pending') {
                                        return <CircularProgress style={{ height: 20, width: 20 }} />;
                                    }
                                    return (
                                        <React.Fragment>
                                            {state._tag === 'success' ? ' Success!' : ''}
                                            <IconButton
                                                color="inherit"
                                                onClick={attemptAction}
                                                aria-label="Save ConfigPack"
                                            >
                                                <Save />
                                            </IconButton>
                                        </React.Fragment>
                                    );
                                }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        padding: '0 20px',
                    }}
                >
                    <DownloadConfigButton />
                    <PushAllButton />
                </div>
            </Card>
            <Card style={{ marginBottom: '1em' }}>
                <CardHeader title={'Export Processes'} />
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}
                >
                    <Typography> Export Process: </Typography>
                    <ExportViewConfigButton key="exportviewconfigbtn" mobile={false} onClick={props.exportProcesses} />
                </div>
            </Card>
        </div>
    );
};

const mapDispatchToProps = {
    exportConfigFull: ExportViewConfigEventCreator.exportViewConfigFull,
    exportConfigCore: ExportViewConfigEventCreator.exportViewConfigCore,
    exportConfigUserExcluded: ExportViewConfigEventCreator.exportViewConfigUserExcluded,
    exportConfigCoreUserExcluded: ExportViewConfigEventCreator.exportViewConfigCoreUserExcluded,
    exportProcesses: ExportViewConfigEventCreator.exportProcesses,
};

const enhance = compose(
    connect((state) => {
        return {
            application: state.viewConfig && state.viewConfig.application,
        };
    }, mapDispatchToProps),
);
export default enhance(ViewConfigManager);
