import { getAllFields } from 'components/generics/form/prefetchLists';
import { getDataTypeForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import ViewConfig, { FieldViewField } from 'reducers/ViewConfigType';
import { DownloadedRef1Views } from './data';

const getRefOneFields = (viewConfig: ViewConfig, allFields: FieldViewField[]) =>
    allFields.filter((f) => getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFONE');

const constructOfflineRef1sAtCurrentLevel = (viewConfig: ViewConfig, viewName: string, id: string) => {
    const fields = getAllFields(viewConfig, viewName);
    const refOneFields = getRefOneFields(viewConfig, fields);
    const resource = viewConfig.views[viewName]?.entity;
    const downloadedRef1ViewsAtCurrentLevel = refOneFields.reduce((prev, curr) => {
        if (!prev[resource]) {
            prev[resource] = {};
        }
        if (!prev[resource][id]) {
            prev[resource][id] = {};
        }
        if (!prev[resource][id][curr.field]) {
            prev[resource][id][curr.field] = {};
        }
        if (!prev[resource][id][curr.field][curr.config]) {
            prev[resource][id][curr.field][curr.config] = true;
        }
        return prev;
    }, {} as DownloadedRef1Views);
    return downloadedRef1ViewsAtCurrentLevel;
};
export default constructOfflineRef1sAtCurrentLevel;
