import React from 'react';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import ViewConfig from '../reducers/ViewConfigType';
import { viewFieldErrors } from './validationRules';
import { RootState } from 'reducers/rootReducer';

const colors = ['#9400D3', '#4B0082', '#0000FF', '#00FF00', '#FFFF00', '#FF7F00', '#FF0000', 'blue', 'red'];

interface ValidationComponentProps {
    viewConfig: ViewConfig;
}
interface ValidationComponentState {
    viewErrors: {
        [entityName: string]: {
            [field: string]: string[];
        };
    };
}
const generateViewErrors = (viewConfig: ViewConfig) =>
    Object.assign(
        {},
        ...sortBy(Object.values(viewConfig.views), 'name')
            .map((view) => ({ [view.name]: viewFieldErrors(view, viewConfig) }))
            .filter(
                (vObj) =>
                    ([] as string[]).concat(
                        ...Object.values(vObj).map((fObj) => ([] as string[]).concat(...Object.values(fObj))),
                    ).length > 0,
            ),
    );

class ValidationComponent extends React.Component<ValidationComponentProps, ValidationComponentState> {
    state = {
        viewErrors: generateViewErrors(this.props.viewConfig),
    };
    componentWillReceiveProps(nextProps: ValidationComponentProps) {
        if (this.props.viewConfig !== nextProps.viewConfig && nextProps.viewConfig) {
            this.setState({
                viewErrors: generateViewErrors(nextProps.viewConfig),
            });
        }
    }
    render() {
        return (
            <div>
                <ul>
                    {Object.entries(this.state.viewErrors).map(([entityName, errors]) => (
                        <li key={entityName}>
                            <div>
                                <h4>{entityName}</h4>
                                <div style={{ marginLeft: '1em' }}>
                                    <ul>
                                        {Object.entries(errors).map(([fieldName, fErrs]) => (
                                            <li key={fieldName}>
                                                {fieldName}
                                                <div style={{ marginLeft: '2em' }}>
                                                    <ul>
                                                        {fErrs.map((e) => (
                                                            <li key={e}>
                                                                <span
                                                                    style={{
                                                                        color: colors[
                                                                            parseInt(e.slice(1, e.indexOf('] ')), 10)
                                                                        ],
                                                                    }}
                                                                >
                                                                    {e.slice(1, e.indexOf('] '))}
                                                                </span>
                                                                {e.slice(e.indexOf('] ') + 1)}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default connect(({ viewConfig }: RootState) => ({ viewConfig }))(ValidationComponent);
