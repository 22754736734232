import React, { useMemo } from 'react';
import { getNextBracketedExpression } from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/getSelectionExpression';
import { accumulateExpressions } from 'expressions/Provider/hooks/accumulateExpressions';
import memoizeOne from 'memoize-one';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import { useFormatMessage } from 'react-intl-hooks';

const parse = getNextBracketedExpression({
    bracketClosesOn: '}',
    bracketNaivelyOpensOn: '{',
    bracketOpensOn: '%{',
});
const accumulate = (expression: string) => accumulateExpressions(expression, parse);

const getValuesFromParams = (params: string[]) => {
    return Object.fromEntries(params.map((param, i) => ['' + i, param]));
    /*
        returns e.g. {
            '0': 'param1',
            '1': 'param2',
            ...
        }
    */
};

/*
    Note: DOES NOT DO SANITIZATION.
*/
// do not use this directly! Always pass results returned from here through santitization,
// because values may come from user input.

export const evaluateFormattedText = (intl: IntlShape, expression: string) => {
    if (!expression || !expression?.includes('%{')) {
        return expression;
    }
    const messages = accumulate(expression);
    const messagesToResults = Object.fromEntries(
        messages.map((message) => {
            const [messageId, ...params] = message.split(',').map((sm) => sm.trim());
            if (!messageId) {
                return [message, ''];
            }
            const formattedMessage = intl.formatMessage(
                {
                    id: messageId,
                },
                getValuesFromParams(params),
            );
            return [message, formattedMessage];
        }),
    );
    const evalString = (str: string): string =>
        parse(str).fold(str, ({ before, inner, after }) => {
            const result = messagesToResults[inner];
            return `${before}${result}${evalString(after)}`;
        });
    const evaluatedWithMessages = evalString(expression);
    return evaluatedWithMessages;
};

type Translate = (message: MessageDescriptor, values?: Record<string, string | number | boolean>) => string;
export const EvaluateFormattedMessage: React.FC<{
    children: (props: { translate: Translate; evaluateFormattedMessage: (message: string) => string }) => JSX.Element;
}> = ({ children }) => {
    const { evaluateFormattedMessage, translate } = useEvaluateFormattedMessage();
    return children({ evaluateFormattedMessage, translate: translate as Translate }) ?? null;
};

export const useEvaluateFormattedMessage = () => {
    const intl = useIntl();
    const evaluateFormattedMessage = useMemo(
        () =>
            memoizeOne((message: string) => {
                return evaluateFormattedText(intl, message);
            }),
        [intl],
    );
    const translate = useFormatMessage() as Translate;
    return { evaluateFormattedMessage, translate };
};
export const useEvaluatedFormattedMessage = (expression: string) => {
    const intl = useIntl();
    return useMemo(() => evaluateFormattedText(intl, expression), [expression, intl]);
};

export const EvaluatedFormattedMessage: React.FC<{
    message: string;
}> = ({ message }) => {
    const msg = useEvaluatedFormattedMessage(message);
    return <>{msg}</>;
};
